import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Dimmer, Loader, Form, Button, Message } from 'semantic-ui-react'
import { setSession, authenticateSession, fetchCohorts } from '../../../redux/actions'
import { claimKickstarterPromoCode } from '../../../redux/actions/user'
import { setAuth } from '../../../utilities/api'
import Cookies from 'js-cookie'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../../strings'
import './styles.scss'


const mapStateToProps = state => {
    return {
        language: state.language,
        session: state.session,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: session => dispatch(setSession(session)),
        authenticateSession: (credentials) => dispatch(authenticateSession(credentials)),
        claimKickstarterPromoCode: (code) => dispatch(claimKickstarterPromoCode(code)),
        fetchCohorts: () => dispatch(fetchCohorts()),
    }
}

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            email: '',
            password: '',
            otp: '',
            referralCode: '',

            errorMessage: '',
            error: false,
            success: false,
            captcha: false,
            captchaValue: false,
            clientId: null,
            returnPath: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const clientId = window.grecaptcha ? window.grecaptcha.ready(() => {
            window.grecaptcha.render('Captcha_container', {
              'sitekey': '6Lfgq4oUAAAAAM21EiBBZeM7OHgrhBShUyiavb7H',
              'size': 'invisible',
            })
          }) : null
      
          this.setState({
            clientId: clientId
          })
      
          if (clientId) this.performCaptcha()

          if (this.props.codeFieldInput) this.setState({ referralCode: this.props.codeFieldInput })
    }

    componentDidUpdate(prevProps) {
      const { codeFieldInput: prevCode } = prevProps
      const { codeFieldInput: currCode } = this.props
      if (!_.isEqual(prevCode, currCode)) this.setState({ referralCode: currCode })
    }

    handleChange(e, { name, value }) {
        this.setState({ [name]: value })
    }

    async handleSubmit() {
        this.setState({ loading: true })
        const { email, password, otp, captchaValue, referralCode, clientId } = this.state
        const { onSuccess, login, } = this.props

        let session = {
          status: 0,
        }
        try {
          session = await this.props.authenticateSession({
            username: email,
            password: password,
            totp: (otp && otp.length > 0) ? otp : null,
            captcha: captchaValue,
          })

          localStorage.setItem('userData', JSON.stringify(session))
          setAuth(_.result(session, 'token'))
          this.setState({ loading: false })
    
    
          Cookies.set('crowfallInfo', {
            token: _.result(session, 'token'),
            firstName: _.result(session, 'user.first_name'),
            lastName: _.result(session, 'user.last_name'),
            email: _.result(session, 'user.email'),
            username: _.result(session, 'user.account_name'),
            user_id: _.result(session, 'user.user_id'),
            date_expires: _.result(session, 'date_expires'),
          }, { domain: '.crowfall.com', expires: 30, sameSite: 'lax' })
    
          if (onSuccess) onSuccess()
          await login(session)
          if (!_.isEmpty(referralCode)) this.handleReferralCodeSubmit(referralCode)
        } catch (err) {
          if (clientId) this.performCaptcha()
          if (err.response.status === 400) {
            const errCode = _.result(err, 'response.data.code')
            if (errCode === 'captcha_required') {
              this.setState({
                error: true,
                loading: false,
                captcha: true,
                errorMessage: strings.getString('LOGIN_ERROR_CAPTCHA', 'Captcha is required to authenticate'),
              })
            }
          } else if (err.response.status === 401 && err.response.data.status === 'totp required') {
            this.setState({
              errorMessage: strings.getString('LOGIN_ERROR_2FA', '2FA device code is required for this account.'),
              error: true,
              loading: false,
            })
          } else if (err.response.status === 401 && err.response.data.status === "totp invalid") {
            this.setState({
              errorMessage: strings.getString('LOGIN_ERROR_2FA_INVALID', '2FA device code invalid.'),
              error: true,
              loading: false,
            })
          } else if (err.response.status === 401 && err.response.data.message === "no totp associated with account") {
            this.setState({
              errorMessage: strings.getString('LOGIN_ERROR_NO_2FA', 'There is no 2FA associated with this account.'),
              error: true,
              loading: false,
            })
          } else if (err.response.status === 401 && err.response.data.message === "The user is currently not allowed to use the service") {
            this.setState({
              errorMessage: (<div dangerouslySetInnerHTML={{ __html: `${strings.getString("BANNED_ACCOUNT", "Your account is not currently eligible to access the Crowfall website.")}<br/>${strings.getString("CONTACT_US_WITH_QUESTIONS", "Please contact support@crowfall.com if you have any questions or concerns.")}` }}/>),
              error: true,
              loading: false,
            })
          } else if (err.response.status === 401) {
            this.setState({
              errorMessage: strings.getString("USERNAME_PASSWORD_INVALID", "Username or password invalid"),
              error: true,
              loading: false,
            })
          } else if (err.response.status === 429) {
            this.setState({
              errorMessage: strings.getString('LOGIN_ERROR_TOO_MANY_ATTEMPTS', 'Too many failed attempts.  Please wait and try again later.  To prevent being forced to wait in the future, please add a 2FA Device to your account.  Please contact support@crowfall.com for further assistance.'),
              error: true,
              loading: false,
            })
          } else {
            this.setState({
              errorMessage: `${strings.getString('LOGIN_UNABLE_TO_LOGIN', 'Unable to authenticate at this time')}: ${_.result(err.response, 'data.message')}`,
              error: true,
              loading: false,
            })
          }
    
        }
    }

    async handleReferralCodeSubmit(code) {
      const { claimKickstarterPromoCode, fetchCohorts } = this.props
      try {
        await claimKickstarterPromoCode(code)
        await fetchCohorts()
        swal(`${strings.getString("ACCOUNT_REDEEM_CODE_SUCCESS", "Successfully Redeemed Code")}`, {
            icon: 'success'
        })
      } catch (err) {
        swal(`${_.result(err, 'response.data.message', strings.getString('ACCOUNT_REDEEM_CODE_ERROR', 'Uh Oh! Unable to Redeem Code'))}`, {
          icon: 'error',
        })
      }
    }

    performCaptcha = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(this.state.clientId, {
                action: 'login',
            }).then((token) => {
                this.setState({
                    captchaValue: token,
                })
            })
        })
    }

    render() {

        const { loading, email, password, otp, referralCode, success, error, errorMessage } = this.state
        const { addCodeField, codeFieldLabel } = this.props

        return (
            <div className="LoginForm">
                <Dimmer active={loading} inverted>
                    <Loader />
                </Dimmer>

                <Form onSubmit={this.handleSubmit} success={success} error={error}>
                    {addCodeField && <Form.Input autoComplete="off"
                            label={codeFieldLabel}
                            placeholder={codeFieldLabel}
                            name="referralCode" value={referralCode}
                            onChange={this.handleChange}/>}

                    <Form.Input required autoComplete="off" type="email"
                            label={strings.getString('EMAIL', 'Email')}
                            placeholder={strings.getString('EMAIL', 'Email')}
                            name='email' value={email}
                            onChange={this.handleChange}/>
                    <Form.Input required minLength="8" autoComplete="off"
                            label={strings.getString('PASSWORD', 'Password')}
                            placeholder={strings.getString('PASSWORD', 'Password')}
                            type='password'
                            name='password' value={password}
                            onChange={this.handleChange}/>
                    <Form.Input minLength="6" autoComplete="off"
                            label={strings.getString("2-FACTOR_AUTHENTICATION", "2-Factor Authentication")}
                            placeholder={strings.getString("2FA_LEAVE_BLANK_IF_NOT_APPLICABLE", "Leave this blank unless you have 2FA on your account")}
                            name='otp' value={otp}
                            onChange={this.handleChange}
                            type="number"/>

                    <div className="LoginForm__buttons">
                        <Button className="Button__ACE" primary type='submit'>
                            {strings.getString('SIGN_IN', 'Sign In')}
                        </Button>
                    </div>

                    <Message success header={strings.getString('LOGIN_SUCCESS', 'Successfully Signed In')}
                             content={strings.getString('LOGIN_SUCCESS_INFO', 'You\'re all set!  You can close this dialog safely.')}/>
                    <Message error header={strings.getString('UH_OH', 'Uh oh!')}
                             content={errorMessage}/>
                </Form>

                <div id="Captcha_container"/>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm))