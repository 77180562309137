import React from 'react'
import _ from 'lodash'
import ContentCard from '../../Elements/ContentCard'
import Button from '../../Fragments/Button'
import LocLink from '../../Elements/LocLink'
import StaticString from '../../Fragments/StaticString'
import Tag from '../../Fragments/Tag'
import './styles.scss'


const BlogCard = (props) => {

    const { blog, history } = props
    const { author, publishDate, shortDescription, tags, thumbUrl, title, uri } = blog
    const ctaButton = ( <LocLink to={`/blog/${uri}`}><Button><StaticString stringName="GET_THE_FULL_STORY"/></Button></LocLink> )
    
    const tagComponents = tags ? tags.map(tag => {
        const {contentful_id: id, name} = tag 
        const onClick = () => {
            if (history.location.search === "") {
                history.push(`${history.location.pathname}?tags=${_.kebabCase(name)}`)
            } else {
                history.push(`${history.location.pathname}${history.location.search},${_.kebabCase(name)}`)
            }
        }
        const isSelected = history.location.search.includes(_.kebabCase(name))
        return { 
            id,
            content: ( <Tag label={name} onClick={onClick} selectable={!isSelected}/> )
        }
    }) : []

    return (
        <div className='BlogCard'>
            <ContentCard 
                isHorizontal
                img={thumbUrl}
                title={`${title} - ${author}`}
                date={publishDate}
                tags={tagComponents}
                description={shortDescription}
                imgLink={`/blog/${uri}`}
                additionalContent={ctaButton}
            />
        </div>
    )
}

export default BlogCard