/*

FieldInput: MODELS
provides uniform styling and functionality for single line inputs and text areas.

PROPERTY     DEFAULT  TYPE      REQUIREMENT  DESCRIPTION
onChange              Function  required     Executed on any change made to the field.
label                 String    optional     default placement is above the field.
sublabel              String    optional     Conditionally displayed based on styling selection; 
                                             DEFAULT: sits below the label. INLINE: NOT displayed. COLUMNAL: sits below the input box.
placeholder           String    optional     Placeholder text set inside the field. Will be overridden if value is also provided.
value                 String    optional     sets initial value for field.
area         false    Boolean   optional     Changes single line input(default) into a text area
name                  String    optional     returned as the second parameter to the onChange function. 
                                             Allows for reusability of onChange functions in the parent.                                      
type         'text'   String    optional     Sets validation parameters for the input value; n/a for text areas.
minChar               Integer   optional     Sets a limit for characters in the input value.
maxChar               Integer   optional     Sets a limit for characters in the input value.
disabled     false    Boolean   optional     If True, field will not be editable.
autofocus    false    Boolean   optional     Auto selects input on component mount.

STYLING PROPS
type: Boolean
requirement: optional
default: false
  PROPERTY   DESCRIPTION
  short      Only applicable for textareas. Sets height for all viewport widths.
  tall       Only applicable for textareas. Sets height for all viewport widths.
  xTall      Only applicable for textareas. Sets height for all viewport widths.
  inline     Label sits inline and directly to the left of the field. Field width set for 600px+
  columnal   Label and field placed into two columns intended to take up 100% width. Label column provided with set width.
             Styling only applicable for 900px+

*/


import React from 'react'
import validator from 'validator'
import _ from 'lodash'
import './styles.scss'


class FieldInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: null,
            htmlType: 'text',
        }
    }

    componentDidMount() {
        const { type, value } = this.props
        this.setState({ value })
        let htmlType = type
        if (htmlType === "username") htmlType = "text"
        if (htmlType === "number") htmlType = "number"
        !_.isEmpty(htmlType) && this.setState({ htmlType })
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue } = prevProps
        const { value: currValue } = this.props
        if (!_.isEqual(currValue, prevValue)) {
            this.setState({ value: currValue })
        }
    }

    handleKeyInput(event) {
        const value = event.target.value
        const isValid = this.checkInputValidity(value)
        this.updateInput(value, isValid)
    }

    checkInputValidity(value) {
        const { type, minChar, maxChar } = this.props
        let isValid = true
        if (!_.isEmpty(type)) {
            switch (type) {
                case 'email':
                    isValid = validator.isEmail(value)
                    break
                case 'phone':
                    isValid = validator.isMobilePhone(value)
                    break
                case 'username':
                    const usernameCheck = new RegExp(`^([a-zA-Z\\d]((?!(\\.|\\-|_)(\\.|\\-|_))[\\w\\d\\-\\.]){1,128}[a-zA-Z\\d])$`, 'i')
                    isValid = usernameCheck.test(value)
                    break
                default:
                    isValid = true
            }
        } else if (maxChar && maxChar < value.length) {
            isValid = false
        } else if (minChar && minChar > value.length) {
            isValid = false
        }
        return isValid
    }

    updateInput(value, isValid) {
        const { onChange, name } = this.props
        this.setState({
            value
        }, () => onChange( value, name, isValid ))
    }

    render() {

        const { label, sublabel, area, placeholder, short, tall, xTall, inline, columnal, disabled, autofocus } = this.props
        const { value, htmlType } = this.state
        let className = "FieldInput"
        if (area) className += " FieldInput--area"
        if (area && short) className += " FieldInput--areaShort"
        if (area && tall) className += " FieldInput--areaTall"
        if (area && xTall) className += " FieldInput--areaXTall"
        if (inline) className += " FieldInput--inline"
        if (columnal) className += " FieldInput--columnal"
        if (disabled) className += " FieldInput--disabled"

        return (
            <div className={className}>
                {label && <div className="FieldInput__label" dangerouslySetInnerHTML={{ __html: label }}/>}


                <div className="FieldInput__mainContent">

                    {sublabel && !inline && <div className="FieldInput__sublabel" dangerouslySetInnerHTML={{ __html: sublabel }}/>}

                    {area && 
                        <textarea
                            className="FieldInput__box"
                            placeholder={placeholder}
                            onChange={(event) => this.handleKeyInput(event)}
                            value={value ? value : ''}
                            disabled={disabled}
                        />
                    }

                    {!area &&
                        <input 
                            className="FieldInput__box"
                            placeholder={placeholder}
                            onChange={(event) => this.handleKeyInput(event)}
                            value={value ? value : ''}
                            type={htmlType}
                            disabled={disabled}
                            autoFocus={!!autofocus}
                        />
                    }
                </div>
                
            </div>
        )
    }
}

export default FieldInput