import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'semantic-ui-react'
import swal from '@sweetalert/with-react'
import Button from '../../../../componentLibrary/Fragments/Button'
import _ from 'lodash'
import strings from '../../../../strings'
import { clearModalContent } from '../../../../redux/actions'
import { resetPassword } from '../../../../redux/actions/user'
import './styles.scss'


const mapDispatchToProps = dispatch => {
  return {
    clearModalContent: () => dispatch(clearModalContent()),
    resetPassword: (email) => dispatch(resetPassword(email)),
  }
}

class ResetPassword extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      loading: false,
    }
    this.clearModal = this.clearModal.bind(this)
    this.submitPasswordReset = this.submitPasswordReset.bind(this)
  }


  handleEmail = (e, { value }) => {
    this.setState({
      email: value,
    })
  }

  clearModal() {
    this.props.clearModalContent()
    this.setState({ email: '' })
  }

  async submitPasswordReset() {
    if (_.isEmpty(this.state.email)) {
      swal(`${strings.getString("ERROR_INVALID_EMAIL", "Please enter a valid email address.")}`, {
        icon: 'error',
      })
      return
    }
    this.setState({ loading: true })
    try {
      await this.props.resetPassword(this.state.email)
      swal(`${strings.getString("CHECK_EMAIL_FOR_NEXT_STEPS", "Please check your email for next steps")}`, {
        icon: "success",
      })
      this.clearModal()
    } catch (err) {
      swal(`${strings.getString("RESET_PASSWORD_ERROR", "Uh Oh! Unable to Recover Email Address.")}`, {
        icon: "error",
      })
    }
    this.setState({ loading: false })
  }


  render() {
    const { loading } = this.state
    
    return (
      <div className="ResetPassword">
        <div className="ResetPassword__header">{strings.getString('RESET_PASSWORD_TITLE', 'I need to reset my Password')}</div>

        <p>
          {strings.getString('RESET_PASSWORD_INFO', 'Enter your Email Address and we will send a password reset link for your account.')}
        </p>

        <Form>
          <Form.Input autoComplete="off" type="text" placeholder={'Email'} name='email' onChange={this.handleEmail}/>
        </Form>

        <div className="ResetPassword__buttons">
          <div className="ResetPassword__button"><Button medium gray onclick={this.clearModal} loading={loading} label={strings.getString("CANCEL", "Cancel")}/></div>
          <div className="ResetPassword__button"><Button medium onclick={this.submitPasswordReset} loading={loading} label={strings.getString("SEND", "send")}/></div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(ResetPassword)