import React from 'react'
import { Dimmer, Loader, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'
import {api} from '../utilities/api';
import _ from 'lodash';


const mapStateToProps = state => {
  return {
    discounts: state.user.accountDiscounts,
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

class AccountDetailsCodes extends React.Component {

  state = {
    loading: false,
    codes: [],
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    const codes = await api.get(`/user/codes`)
    this.setState({ codes: codes.data })
  }

  render() {

    const { loading, codes } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>Available Codes for Friends</h1>

        <p>Here you can see codes that you can give to friends and family for special rewards.</p>


        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('CODE', 'Code')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('EXPIRATION', 'Expiration')}</Table.HeaderCell>
              <Table.HeaderCell>Program</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('DESCRIPTION', 'Description')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('REMAINING_USES', 'Remaining Uses')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('TIMES_USED', 'Times Used')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {codes.map(r => (
              <Table.Row key={r.code_id}>
                <Table.Cell singleLine>{r.code}</Table.Cell>
                <Table.Cell singleLine>{!_.isNil(r.date_expired) ? (new Date(r.date_expired)).toLocaleString(): 'Never'}</Table.Cell>
                <Table.Cell>{r.display_name}</Table.Cell>
                <Table.Cell>{r.description}</Table.Cell>
                <Table.Cell>{!_.isNil(r.max_usages) ? r.max_usages - r.used_count : 'Unlimited'}</Table.Cell>
                <Table.Cell>{r.used_count}</Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsCodes)
