import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const Stepper = props => {

    const { children, activeStep, stepLabels } = props

    return (
        <div className="Stepper">
            {stepLabels && stepLabels.map((step, index) => {

                const isActive = Math.min(stepLabels.length, activeStep) === index + 1
                const isCompleted = Math.min(stepLabels.length, activeStep) > index + 1
                let stepClassName = "Stepper__step"
                if (isActive) stepClassName += " Stepper__step--active"
                if (isCompleted) stepClassName += " Stepper__step--complete"
                const countLabel = activeStep > index + 1 ? <FontAwesomeIcon icon="check"/> : index + 1
                return (
                    <div className={stepClassName} key={`Stepper__step${step}--${index}`}>
                        <div className="Stepper__connectorLine"/>
                        <div className="Stepper__stepCount">{countLabel}</div>
                        <div className="Stepper__stepBody">
                            <div className="Stepper__stepLabel">{step}</div>
                            {isActive && <div className="Stepper__stepContent">{children}</div>}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Stepper
