import React from 'react'
import queryString from 'query-string'
import _ from 'lodash'
import Page from '../../../componentLibrary/Models/Page'
import SearchBar from '../../../componentLibrary/Elements/Searchbar'
import SearchResultsDisplay from '../../../componentLibrary/Fragments/SearchResultsDisplay'
import Pagination from '../../../componentLibrary/Elements/Pagination'
import MarketingCTA from '../../../componentLibrary/Elements/MarketingCTA'
import BlogCard from '../../../componentLibrary/Models/BlogCard'
import Pill from '../../../componentLibrary/Fragments/Pill'
import contentful from '../../../utilities/contentful'
import { connect } from 'react-redux'
import {
    fetchBlogs, filterBlogs, searchBlogs, clearBlogsSearchCriteria, paginateBlogs, clearBlogs
} from '../../../redux/actions/blogs'
import assets from '../../../assets'
import strings from '../../../strings'
import './styles.scss'


const mapDispatchToProps = dispatch => {
    return {
        fetchBlogs: () => dispatch(fetchBlogs()),
        filterBlogs: (criteria) => dispatch(filterBlogs(criteria)),
        searchBlogs: (criteria) => dispatch(searchBlogs(criteria)),
        clearBlogsSearchCriteria: () => dispatch(clearBlogsSearchCriteria()),
        paginateBlogs: (pagination) => dispatch(paginateBlogs(pagination)),
        clearBlogs: () => dispatch(clearBlogs())
    }
}


const mapStateToProps = (state) => {
    const { session, blogs } = state
    const { searched, fetched, searchCriteria, paginated, paginationCriteria } = blogs
    return {
        isLoggedIn: !!session,
        fetched,
        searched,
        searchCriteria,
        paginated,
        paginationCriteria,
    }
}


class BlogsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: null,
            banner: null,
            callout: null,
        }
    }

    async componentDidMount() {
        const content = await contentful.getEntry(assets.contentfulIds.BLOGS_PAGE)
        const callout = await contentful.getEntry(assets.contentfulIds.BLOGS_MKTG_CALLOUT)
        this.setState({
          title: content.title,
          banner: _.result(content, 'banner.assetUrl'),
          callout: callout.richText,
        })

        await this.props.fetchBlogs()
        this.filterBlogsByTags()
    }

    componentWillUnmount() {
        this.props.clearBlogs()
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.filterBlogsByTags()
        }
    }

    filterBlogsByTags() {
        let tags = this.determineTagsFromSearch()
        this.props.filterBlogs(tags)
    }

    determineTagsFromSearch() {
        let selectedTags = []
        const tagsQuery = queryString.parse(_.result(this.props, "location.search")).tags
        if (tagsQuery) selectedTags = tagsQuery.split(",")
        return selectedTags
    }

    removeTag = (name) => {
        let tags = this.determineTagsFromSearch()

        if (tags.length === 1 && name === tags[0]) {
            this.props.history.push(`/blog`)
            return
        }

        let updatedTags = [...tags]
        const indexOfTag = tags.indexOf(name)
        if (indexOfTag !== -1) updatedTags.splice(indexOfTag, 1)
        const newSearch = updatedTags.join()
        this.props.history.push(`/blog?tags=${newSearch}`)
    }

    render() {

        const { fetched, searched, isLoggedIn, searchCriteria, paginated, searchBlogs, clearBlogsSearchCriteria, paginateBlogs, paginationCriteria } = this.props
        const { title, banner, callout } = this.state
        const { limit, page } = paginationCriteria
        const disableResultsDisplay = !!searchCriteria && fetched ? false : true

        const tags = this.determineTagsFromSearch()

        return (
            <Page header={{banner, title}}>
                <div className='BlogsPage'>
                    {!isLoggedIn &&
                        <MarketingCTA to='/register?t=sign_up&p=blogs' buttonLabel={strings.getString("SIGN_UP", "Sign UP")}>
                            <span dangerouslySetInnerHTML={{ __html: callout }}/>
                        </MarketingCTA>
                    }

                    {tags.length > 0 &&
                        <div className='BlogsPage__tags'>
                            {tags.map((tag, index) => (
                                <div className='BlogsPage__tagPill' key={`${tag}-${index}`}><Pill large label={_.startCase(tag)} onClick={() => this.removeTag(tag)}/></div>
                            ))}
                        </div>
                    }

                    <div className='BlogsPage__searchbar'>
                        <div className='BlogsPage__searchField'>
                            <SearchBar search={searchBlogs} clear={clearBlogsSearchCriteria}/>
                        </div>
                    </div>
                    <SearchResultsDisplay resultsCount={searched.length} disabled={disableResultsDisplay} noResultsText={strings.getString("NO_MATCHING_BLOGS", "I'm sorry, there are no Blogs matching your criteria. Please refine your search")}/>

                    <div className='BlogsPage__blogs'>
                        {paginated.map(blog => {
                            const { contentful_id } = blog
                            return (
                                <BlogCard blog={blog} history={this.props.history} key={contentful_id}/>
                            )
                        })}
                    </div>

                    {searched.length > 0 && <Pagination paginate={paginateBlogs} list={searched} limit={limit} page={page}/>}
                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogsPage)
