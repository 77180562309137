import React from 'react'
import Page from '../componentLibrary/Models/Page'
import connect from 'react-redux/es/connect/connect'
import _ from 'lodash'
import queryString from 'query-string'
import { api } from '../utilities/api'
import { Button, Container, Header } from 'semantic-ui-react'
import swal from '@sweetalert/with-react'

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

class OAuthVerify extends React.Component {

  state = {
    clientId: null,
    externalId: null,
    affiliateName: '',
    authorizationCode: '',
    redirectUrl: '',
    scopes: [],
  }

  componentDidMount = async () => {
    const q = _.result(this.props, 'location.search')
    const query = queryString.parse(q) || {}

    this.setState({
      clientId: query.client_id,
      externalId: query.ext_id,
    })

    try {
      const res = await api.post(`/oauth/authorize`, {
        client_id: query.client_id,
        scopes: ['user_identity_write:transactions'],
      })

      this.setState({
        affiliateName: res.data.affiliate_name,
        authorizationCode: res.data.authorization_code,
        redirectUrl: res.data.redirect_url,
        scopes: res.data.scopes,
      })

      this.setState({})
    } catch (err) {
      swal({
        icon: 'error',
        title: 'Invalid Link',
        content: 'The external affiliate link is invalid.',
      })
    }
  }

  accept = () => {
    window.location = `${this.state.redirectUrl}?ext_id=${this.state.externalId}&authorization_code=${this.state.authorizationCode}&action=success`
  }

  decline = () => {
    window.location = `${this.state.redirectUrl}?ext_id=${this.state.externalId}&action=cancel`
  }

  render() {

    const { affiliateName, scopes } = this.state

    return (
      <div>
        <Page>
          <Container>
            <Header as='h1'>Link Crowfall Account to {affiliateName}</Header>
            <p>Do you want to allow {affiliateName} to access your account information. By allowing {affiliateName} to
              access your account they will gain the following permissions: </p>
            <ul>
              {scopes.map(s => (
                <li key={s}>{_.startCase(s)}</li>
              ))}
            </ul>

            <div style={{textAlign: 'right'}}>
              <Button className="Button__ACE Button_ACE-secondary" onClick={this.decline}>Decline</Button>
              <Button className="Button__ACE" onClick={this.accept}>Accept</Button>
            </div>
          </Container>
        </Page>
      </div>
    )
  }
}

export default connect(mapStateToProps)(OAuthVerify)
