import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const EuRightOfWithdrawal = () => (
    <Page contentId={assets.contentfulIds.EU_RIGHT_OF_WITHDRAWAL}>
    </Page>
)

export default EuRightOfWithdrawal
