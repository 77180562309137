import React from 'react'
import { Icon } from 'semantic-ui-react'
import './styles.scss'

const SocialLink = (props) => {
  const { twitter, facebook, twitch, instagram, youtube, discord } = props
  let type = null
  let href = null
  let className = 'SocialLink'
  if (twitter) {
    type = 'twitter'
    href = 'https://twitter.com/CrowfallGame'
  } else if (facebook) {
    type = 'facebook f'
    href = 'http://www.facebook.com/CrowfallGame'
  } else if (twitch) {
    type = 'twitch'
    href = 'https://www.twitch.tv/crowfallgame'
  } else if (instagram) {
    type = 'instagram'
    href = 'https://www.instagram.com/crowfallgame/'
  } else if (youtube) {
    type = 'youtube'
    href = 'https://www.youtube.com/crowfall'
  } else if (discord) {
    type = 'discord'
    href = 'https://discord.gg/crowfall'
  }

  className += ` SocialLink--${type.split(' ')[0]}`

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div className={className}>
        {type && <Icon name={type} size="large" />}
      </div>
    </a>
  )
}

export default SocialLink
