/*

RadioButtons: Element
creates a display of radio buttons in which only one can be selected at a time

PROPERTY  DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

label     ''       Required     String    descriptive label positioned above the options.
options   []       Required     Array     array of objects with the properties 'label' and 'value'.
value     null     optional     Varies    Optionally sets the initial selected radio button.
select    no-op    Required     Function  enacted when a new selection is made. Passes the value of that selection as the function's input.
name               String       optional  returned as the second parameter to the Select method. 

*/


import React from 'react'
import _ from 'lodash'
import Radio from '../../Fragments/Radio'
import './styles.scss'


class RadioButtons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: null,
            options: []
        }
    }

    componentDidMount() {
        const { options, value } = this.props
        this.setState({ selected: value, options: [...options] })
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue, options: prevOptions } = prevProps
        const { value: currValue, options: currOptions } = this.props
        if ( !_.isEqual(prevValue, currValue) || !_.isEqual(prevOptions, currOptions)) this.setState({ selected: currValue, options: currOptions })
    }

    toggle(value) {
        const { selected } = this.state
        const { select, name } = this.props
        if ( _.isEqual(value, selected) === false ) {
            this.setState({ selected: value }, select(value, name))
        }
    }

    render() {

        const { label } = this.props
        const { options, selected } = this.state

        return (
            <div className="RadioButtons">
                {label && <div className="RadioButtons__label" dangerouslySetInnerHTML={{ __html: label }}/>}

                <div className="RadioButtons__buttons">
                    {options && options.length > 0 && options.map((radio, index) => {
                        const { label, value } = radio
                        const isSelected = _.isEqual(value, selected) ? true : false
                        return (
                            <Radio key={`radioButton--${index}`} label={label} toggle={() => this.toggle(value)} isSelected={isSelected}/>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default RadioButtons