/*

FRAGMENT: Grid
provided formatting for a list of assets/cards/etc.

PROPERTY          DEFAULT  REQUIREMENT  TYPE     DESCRIPTION
list              []       Required     Array    list of DOM elements to be iterated over and formatted appropriately
gridGap30         false    Optional     Boolean  Increases Grid Gap to 30px;
featuredTwo       false    Optional     Boolean  Desktop view of elements ordered into two columns on the top row, and 3 columns every subsequent row.
fillBottomRow     false    Optional     Boolean  Expands content on final row to match width of the entire grid. Also works in conjunction with 'featuredTwo' prop.
irregularColumns  false    Optional     Boolean  Content fills into two columns with varying widths depending on the row.

*/

import React from 'react'
import './styles.scss'


const Grid = props => {

    const list = props.list || []
    let className = "Grid"
    const { gridGap30, featuredTwo, fillBottomRow, irregularColumns } = props
    if (gridGap30) className += " Grid--gridGap30"
    if (featuredTwo) className += " Grid--featuredTwo"
    if (fillBottomRow && !featuredTwo) {
        if (list.length % 3 === 1) className += " Grid--fillBottomRow1"
        if (list.length % 3 === 2) className += " Grid--fillBottomRow2"
    } else if (fillBottomRow && featuredTwo) {
        if ((list.length + 1) % 3 === 1) className += " Grid--featuredAndFillBottom1"
        if ((list.length + 1) % 3 === 2) className += " Grid--featuredAndFillBottom2"
    }
    if (irregularColumns) className += " Grid--irregularColumns"

    return (
        <div className={className}>
            {list.map((el, index) => (
                <div className="Grid__element" key={`Grids-Element-${index}`}>{el}</div>
            ))}
        </div>
    )
}

export default Grid