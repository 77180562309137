import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import strings from'../../../strings'
import TitleBar from '../../../componentLibrary/Fragments/TitleBar'
import TitleBlur from '../../../componentLibrary/Fragments/TitleBlur'
import ProductGrid from '../ProductGrid'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        regions: state.regions,
        accountRegions: state.user.accountRegions,
        catalogSearched: state.crowfallStore.catalogSearched,
        storeCategories: state.crowfallStore.categories,
        selectedCategory: state.crowfallStore.selectedCategory,
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


class GiftingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modeledCatalog: {},
            initialScrollToChecked: false,
            userCannotGift: false,
        }
    }

    componentDidMount() {
        this.modelCatalog()
        this.determineUsersGiftingRestrictions()
    }

    determineUsersGiftingRestrictions() {
        const { session, regions, accountRegions } = this.props
        let userCannotGift
        if (session) {
            userCannotGift = !_.isEmpty(_.find(accountRegions, { region_name: "Brazil" }))
        } else {
            userCannotGift = !_.isEmpty(_.find(regions, { region_name: "Brazil" }))
        }
        this.setState({ userCannotGift })
    }

    componentDidUpdate(prevProps) {
        const { selectedCategory: prevSelected, catalogSearched: prevCatalog } = prevProps
        const { selectedCategory: currSelected, catalogSearched: currCatalog } = this.props
        if (!_.isEqual(prevCatalog, currCatalog)) this.modelCatalog()
        if ((!this.state.initialScrollToChecked && currSelected.subcategoryName) || prevSelected.subcategoryName !== currSelected.subcategoryName) this.scrollToSubcategory()
    }

    modelCatalog() {
        const { catalogSearched, storeCategories } = this.props
        
        let modeledCatalog = {}
        Object.keys(storeCategories).forEach(category => {
            let storeCategory = _.result(storeCategories[category], "display_strings.name")
            modeledCatalog[storeCategory] = catalogSearched.filter(item => !_.isEmpty(_.find(item.primary_categories, { inventory_category_definition_id: category })) && _.result(item, "external_ids.is_subscription") !== true )
        })
        this.setState({ modeledCatalog })
    }

    scrollToSubcategory() {
        const { selectedCategory } = this.props
        const element = document.getElementById(`section--${selectedCategory.subcategoryName}`)
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        if (element && this.state.initialScrollToChecked === false) this.setState({initialScrollToChecked: true})
    }


    render() {

        const { modeledCatalog, userCannotGift } = this.state
        const { storeCategories } = this.props
        const catalogSections = Object.keys(modeledCatalog).sort()

        return (
            <div className="GiftingPage">

                {userCannotGift && <Redirect to="/store"/>}

                <TitleBar title={strings.getString("BUY_FOR_A_FRIEND", "Buy for a Friend")} small/>
                <div className="GiftingPage__headerText">
                    <p dangerouslySetInnerHTML={{ __html: strings.getString("GIFTING_STORE_HEADER_LINE_1", "Crowfall Game offers players the option to purchase any of our products and services as a gift to give another player.")}}/>
                    <p dangerouslySetInnerHTML={{ __html: strings.getString("GIFTING_STORE_HEADER_LINE_2", "To purchase and send a gift to another player, you must include the &#34;Display&#34; Name of the person to whom you are sending the gift.") }}/>
                </div>
                
                {catalogSections.map((section, index) => {
                    const storeCategoryName = _.result(storeCategories[section], "display_strings.name")
                    return (
                        <div className="GiftingPage__section" key={`section-${storeCategoryName}-${index}`}>
                            <div className="GiftingPage__subheader" id={`section--${section}`}><TitleBlur title={section} h3/></div>
                            <ProductGrid products={modeledCatalog[section]}/>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftingPage)