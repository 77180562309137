import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import strings from '../../../../strings'
import OutsideClickCheck from '../../../../componentLibrary/Fragments/OutsideClickCheck'
import './styles.scss'

const mapStateToProps = state => {
    return {
        language: state.language,
    }
}


const LanguageSelector = (props) => {
    const [ showOptions, toggle ] = useState(false)

    const languageCode = _.upperFirst(props.language.split('-')[0])
    const languageOptions = [ 
        {label: strings.getString("ENGLISH"), value: "en-US"}, 
        {label: strings.getString("FRENCH"), value: "fr-FR"}, 
        {label: strings.getString("GERMAN"), value: "de-DE"}, 
        {label: strings.getString("SPANISH"), value: "es-ES"}, 
        {label: strings.getString("PORTUGUESE"), value: "pt-PT"}, 
    ]

    const getUrl = (language) => {
        let location = props.location.pathname.split(/[\s/]/).slice(2)
        const search = props.location.search
        location = location.join('/')
        if (!_.isEmpty(search)) location += search
        return `/${language}/${location}`
    }

    const handleOutsideClick = () => {
        toggle(false)
    }

    let className = "LanguageSelector"
    if (showOptions) className += " LanguageSelector--open"

    return (
        <OutsideClickCheck onClickOutside={handleOutsideClick}>
            <div className={className}>

                <div className="LanguageSelector__content" onClick={() => toggle(!showOptions)}>
                    <FontAwesomeIcon icon="globe"/>
                    <div className="LanguageSelector__language">{languageCode}</div>
                    <FontAwesomeIcon icon="chevron-down" style={{ fontSize: "14px" }}/>
                </div>

                <div className="LanguageSelector__options">
                    {languageOptions.map(option => (
                        <a key={`option--${option.value}`} href={getUrl(option.value)}>
                            <div className="LanguageSelector__option"  onClick={() => toggle(false)}>{option.label}</div>
                        </a>
                    ))}
                </div>

            </div>
        </OutsideClickCheck>
    )
}

export default withRouter(connect(mapStateToProps)(LanguageSelector))