import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import OrderSummary from '../OrderSummary'
import PaymentMethods from '../PaymentMethods'
import OrderDetails from '../OrderDetails'
import ReviewItems from '../ReviewItems'
import CheckoutPromoCodeForm from '../CheckoutPromoCodeForm'
import strings from '../../../strings'
import RegionBlock from '../../../componentLibrary/Models/RegionBlock'
import FormMessage from '../../../componentLibrary/Fragments/FormMessage'
import Button from '../../../componentLibrary/Fragments/Button'
import CompleteButton from '../CompletePurchase'

import { fetchCohorts } from '../../../redux/actions'
import { fetchGuildDetails } from '../../../redux/actions/guild-management'
import {
    getSingleStoreProduct, generateDeferredCheckout, generateBuyNowCheckout, generateCheckoutFromCart,
    clearUserSolutions, initiateShippingAddressEdit, fetchUserCart,
} from '../../../redux/actions/crowfall-store'
import { getSingleTransaction } from '../../../redux/actions/user'
import './styles.scss'


const mapStateToProps = state => {
    return {
        language: state.language,
        acceptedRegionalAgreements: state.acceptedRegionalAgreements,
        shippingAddress: state.crowfallStore.shippingAddress,
        solutionCart: state.crowfallStore.solutionCart,
        solutionIdCart: state.crowfallStore.solutionIdCart,
        guild: state.guildManagement.guild,
        guildWallet: state.guildManagement.guildWallet,
        guildRank: state.guildManagement.guildRank,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCohorts: () => dispatch(fetchCohorts()),
        fetchGuildDetails: () => dispatch(fetchGuildDetails()),
        getSingleStoreProduct: (id, language) => dispatch(getSingleStoreProduct(id, language)),
        generateDeferredCheckout: (deferredId, amount) => dispatch(generateDeferredCheckout(deferredId, amount)),
        generateBuyNowCheckout: (itemId, language, upgradeId, targetUsername, walletId, address, guildId) => dispatch(generateBuyNowCheckout(itemId, language, upgradeId, targetUsername, walletId, address, guildId)),
        generateCheckoutFromCart: () => dispatch(generateCheckoutFromCart()),
        clearUserSolutions: () => dispatch(clearUserSolutions()),
        initiateShippingAddressEdit: () => dispatch(initiateShippingAddressEdit()),
        getSingleTransaction: (id) => dispatch(getSingleTransaction(id)),
        fetchUserCart: () => dispatch(fetchUserCart()),
    }
}

class CheckoutSolutionDisplay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            success: false,
            warning: false,
            error: false,
            redirectToStore: false,
            redirectToReceipt: false,
            solutionDisplayStrings: null,

            item: {},

            layaway: false,
            orderId: null,
            order: null,

            solution: {},
            itemIsDiscountable: false,
            itemGrantsGuildCreation: false,
            availablePaymentMethods: null,

            cartItems: [],
            cartIsDiscountable: false,
            cartGrantsGuildCreation: false,

            walletSelected: false,
        }
        this.setInitialState = this.setInitialState.bind(this)
        this.handleWarningTxn = this.handleWarningTxn.bind(this)
        this.handleSuccessTxn = this.handleSuccessTxn.bind(this)
        this.handleFailedTxn = this.handleFailedTxn.bind(this)
    }

    componentDidMount() {
        this.setInitialState()
    }

    setInitialState() {
        const { isACart, itemId, item, isLayaway, layawayAmount, upgradeId, targetUsername,
                walletId, guildId, shippingAddress} = this.props

        if (itemId) {
            if (isLayaway) {
                this.generateSolutionDeferred(itemId, layawayAmount)
            } else if (!_.isEmpty(item)) {
                this.setState({ item })
                this.generateSolutionBuyNow(itemId, upgradeId, targetUsername, walletId, shippingAddress, guildId)
            }
        } else if (isACart) {
            this.generateSolutionCart()
        }
    }

    async generateSolutionDeferred(deferredId, amount) {
        try {
            const solution = await this.props.generateDeferredCheckout(deferredId, amount)
            this.handleSolution(solution)
            this.setState({
                layaway: true,
                orderId: deferredId,
                item: solution.item_details,
            })
        } catch (err) {
            this.catchSolutionError(err)
        }
    }

    async generateSolutionBuyNow(itemId, upgradeId, targetUsername, walletId, shippingAddress, guildId) {
        try {
            const { language } = this.props
            const solution = await this.props.generateBuyNowCheckout(itemId, language, upgradeId, targetUsername, walletId, shippingAddress, guildId)
            this.recordBuyNowCheckoutToGoogle(solution.item_details)
            this.handleSolution(solution)
        } catch (err) {
            this.catchSolutionError(err)
        }
    }

    recordBuyNowCheckoutToGoogle(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "begin_checkout", {
            items: [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                  }
            ]
        })
    }

    async generateSolutionCart() {
        try {
            await this.props.generateCheckoutFromCart()
            const { solutionCart } = this.props
            this.recordCartCheckoutToGoogle(solutionCart)
            this.handleCartSolution(solutionCart)
        } catch (err) {
            this.catchSolutionError(err)
        }
    }

    recordCartCheckoutToGoogle(solutionCart) {
        const solutions = _.result(solutionCart, "cart_details.solutions")
        window.gtag("event", "begin_checkout", {
            items: solutions ? solutions.map(solution => {
                return {
                    "id": solution.item_id,
                    "name": solution.item_slug,
                    "list_name": solution.item_name,
                    "brand": "Crowfall",
                    // "category": category,
                    "quantity": solution.quantity || 1,
                    "price": solution.unit_amount
                }
            }) : []
        })
    }

    handleSolution(solution) {
        const itemGrants = _.result(solution, 'item_details.grant_details')
        const itemGuildCreationGrants = !_.isEmpty(itemGrants) ? itemGrants.filter(grant => grant && grant.id === "KS_43") : null
        const methods = _.result(solution, 'allowed_methods', []).reduce((acc, curr) => {
            acc[curr] = true
            return acc
        }, {})
        this.setState({
            solution: solution,
            solutionDisplayStrings: _.result(solution, 'display_strings'),
            availablePaymentMethods: methods,
            itemIsDiscountable: _.result(solution, "item_details.discountable", false),
            itemGrantsGuildCreation: !_.isEmpty(itemGuildCreationGrants)
        })
    }

    handleCartSolution = async (solution) => {
        const methods = _.result(solution, 'allowed_methods', []).reduce((acc, curr) => {
          acc[curr] = true
          return acc
        }, {})

        const fetchCartItemDetails = async (list) => {
          return Promise.all(list.map(item => {
            return this.props.getSingleStoreProduct(item.item_id, this.props.language)
          }))
        }
        const cartSolutions = _.result(solution, 'cart_details.solutions', [])
        const cartItems = await fetchCartItemDetails(cartSolutions)
        const cartIsDiscountable = !_.isEmpty(cartItems.filter(item => item.discountable === true))
        const itemsWithGuildCreationGrant = cartItems ? cartItems.map(item => {
            const itemGrants = _.result(item, 'grant_details')
            const itemGuildCreationGrants = itemGrants ? itemGrants.filter(grant => grant.id === "KS_43") : null
            return !_.isEmpty(itemGuildCreationGrants)
        }).filter(item => item === true) : []
        this.setState({
            solutionDisplayStrings: _.result(solution, 'cart_details.display_strings'),
            availablePaymentMethods: methods,
            cartItems,
            cartIsDiscountable,
            cartGrantsGuildCreation: !_.isEmpty(itemsWithGuildCreationGrant),
        })
    }

    catchSolutionError(err) {
        console.log("ERR", err)
        if (_.result(err, 'response.data.message', '').startsWith('Checkout solution cannot be generated with virtual currency due to insufficient funds in wallet')) {
          swal({
            icon: 'error',
            title: strings.getString('UH_OH', 'Uh Oh'),
            text: strings.getString('CHECKOUT_INVALID_CROWNS', 'You don’t own enough crowns to purchase this item. You can buy additional crowns in our store.'),
          }).then(() => {
            this.setState({
              redirectToStore: true,
            })
          })
        } else {
          swal({
            icon: 'error',
            title: strings.getString('UH_OH', 'Uh Oh'),
            text: strings.getString('CHECKOUT_ERROR_GENERATED', 'There was an error when attempting to generate a checkout.  Please try again later.'),
          }).then(() => {
            this.setState({
              redirectToStore: true,
            })
          })
        }
    }

    handleLoadTransactionDetails = async (txnId, forceStatus) => {
        if (!forceStatus) {
            this.setState({
                loading: true,
            })

            try {
                const txn = await this.props.getSingleTransaction(txnId)
                const related = _.result(txn, 'related_transactions')

                this.setState({
                    order: txn,
                    orderId: txnId,
                })

                if (_.isNil(related)) {
                    if (txn.status === 'paid') {
                        this.handleSuccessTxn(txnId)
                    } else {
                        this.handleWarningTxn()
                    }

                } else {
                    const failedTransaction = _.find(related, {
                        status: 'failed',
                    })

                    const successTransaction = _.find(related, {
                        status: 'paid',
                    })

                    if (failedTransaction) {
                        this.handleFailedTxn()
                    } else if (successTransaction) {
                        this.handleSuccessTxn(txnId)
                    }
                }
            } catch (err) {
                console.log('Error: ', err)
            }
        } else {
            if (forceStatus.success) {
                this.handleSuccessTxn(txnId)
            } else if (forceStatus.error) {
                this.handleFailedTxn()
            } else if (forceStatus.fraud) {
                this.handleFailedTxn(forceStatus.message)
            }
        }
    }

    handleWarningTxn = () => {
        this.setState({
            warning: true,
            loading: false,
        })

        swal({
            icon: 'warning',
            title: strings.getString('UH_OH', 'Uh Oh'),
            text: strings.getString('CHECKOUT_ORDER_TAKING_TOO_LONG', 'The order is taking longer to process than expected.  If you aborted your order you can safely ignore this message, otherwise please check your order history in a few minutes.'),
        })
    }

    handleFailedTxn = (messageOverride) => {
        this.setState({
            error: true,
            loading: false,
        })

        swal({
            icon: 'error',
            title: strings.getString('UH_OH', 'Uh Oh'),
            text: messageOverride || strings.getString('STORE_PAYMENT_ISSUE_ERROR', 'There was an issue with the payment! Please check your payment information and try again.'),
        })
    }

    handleSuccessTxn = (txnId) => {
        const { fetchCohorts, fetchGuildDetails, fetchUserCart } = this.props
        this.setState({
            success: true,
            loading: false,
            orderId: txnId,
        })

        fetchCohorts()
        fetchGuildDetails()
        fetchUserCart()

        this.setState({ redirectToReceipt: true })
    }


    walletSelectionValidation = (status) => {
        this.setState({
            walletSelected: status,
        })
    }

    componentWillUnmount() {
        this.props.clearUserSolutions()
    }

  render() {

        const {
            language, guild, guildRank, guildWallet, acceptedRegionalAgreements, solutionCart,
            isACart, shippingAddress, initiateShippingAddressEdit, countryOptions, targetUsername, isSubscribe, cartSolution, walletSelected
        } = this.props

        const { first_name, last_name, street_address, extended_address, city, region, country, postal_code } = shippingAddress
        const shippingCountry =  _.find(countryOptions, ["value", country])

        const {
            item, loading, cartItems, solution, availablePaymentMethods, cartIsDiscountable, itemIsDiscountable, orderId,
            redirectToStore, redirectToReceipt, success, warning, error, order, cartGrantsGuildCreation, itemGrantsGuildCreation,done
        } = this.state
        const discountable = isACart ? cartIsDiscountable : itemIsDiscountable
        const grantsGuildCreation = cartGrantsGuildCreation || itemGrantsGuildCreation


        return (
            <div className="CheckoutSolutionDisplay">

                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>
                {redirectToStore ? (
                    <Redirect to={{ pathname: `/${language}/store`, search: 'category=ab40dfa4-c3a4-4442-a0fe-a44ebb2f277c' }}/>) : null
                }
                {redirectToReceipt &&
                    <Redirect to={{ pathname: `/${language}/checkout-receipt/${orderId}`, search: isSubscribe ? 'subscribe=true' : null }}/>
                }


                <ReviewItems isACart={isACart} cartItems={cartItems} item={item} targetUsername={targetUsername} grantsGuildCreation={grantsGuildCreation}/>


                {!_.isEmpty(shippingAddress) && <div className="CheckoutSolutionDisplay__reviewShipping">
                    <div className="CheckoutSolutionDisplay__header">{strings.getString("REVIEW_SHIPPING", "Review Shipping Information")}</div>

                    <div className="CheckoutSolutionDisplay__shippingAddress">
                        <p>{first_name} {last_name}</p>
                        <p>{street_address}</p>
                        {!_.isEmpty(extended_address) && <p>{street_address}</p>}
                        <p>{city}, {region} {postal_code}</p>
                        {!_.isEmpty(shippingCountry) && <p>{shippingCountry.countryName}</p>}
                    </div>

                    <div className="CheckoutSolutionDisplay__backButton">
                        <Button medium label={strings.getString("EDIT_SHIPPING", "Edit Shipping")} onclick={initiateShippingAddressEdit}/>
                    </div>
                </div>}


                {!success && !error && !warning &&
                    <PaymentMethods
                        item={item}
                        guildRank={guildRank}
                        guild={guild}
                        guildWallet={guildWallet}
                        availablePaymentMethods={availablePaymentMethods}
                        solution={solution}
                        cartSolution={solutionCart}
                        handleTxn={this.handleLoadTransactionDetails}
                        agreements={acceptedRegionalAgreements}
                        isSubscribe={isSubscribe}
                        walletSelectionValidation={this.walletSelectionValidation}
                    />
                }


                <CheckoutPromoCodeForm isDiscountable={discountable} onSuccess={this.setInitialState}/>


                <div className="CheckoutSolutionDisplay__orderSummary">
                    <OrderSummary solution={solution} solutionCart={solutionCart} isSubscribe={isSubscribe}/>
                </div>

                {solution.currency !== "USD" &&
                    <div>
                        <CompleteButton
                            item={item}
                            guildRank={guildRank}
                            guild={guild}
                            guildWallet={guildWallet}
                            availablePaymentMethods={availablePaymentMethods}
                            solution={solution}
                            cartSolution={solutionCart}
                            handleTxn={this.handleLoadTransactionDetails}
                            agreements={acceptedRegionalAgreements}
                            isSubscribe={isSubscribe}
                            walletSelected={this.state.walletSelected}
                            targetUsername={targetUsername}
                        ></CompleteButton>
                    </div>
                }


                {success && <FormMessage
                    title={strings.getString('PURCHASE_SUCCESSFUL', 'Purchase Successful!')}
                    content={strings.getString('CHECKOUT_PURCHASE_SUCCESSFUL_INFO', 'The purchase was successful! It may take a few moments before the purchased Items and Currency appear on your account.')}
                />}
                {warning && <FormMessage
                    title={strings.getString('STATUS_UNKNOWN', 'Status Unknown!')} warning
                    content={strings.getString('CHECKOUT_PURCHASE_STATUS_UNKNOWN', 'The purchase status is currently unknown. If you canceled the checkout process you can safely ignore this message and chose another payment method, otherwise please check your order history in a few minutes.')}
                />}
                {error && <FormMessage
                    title={strings.getString('UH_OH', 'Uh Oh!')} error
                    content={strings.getString('STORE_PAYMENT_ISSUE_ERROR', 'There was an issue with the payment! Please check your payment information and try again.')}
                />}


                {order && <OrderDetails order={order} />}


                <RegionBlock artcraft>
                    * {strings.getString('STORE_TAX_DISCLAIMER', 'All prices are in USD unless otherwise stated and do not include regional tax.')}
                </RegionBlock>
                <RegionBlock eu>
                    * {strings.getString('STORE_TRAVIAN_TAX_DISCLAIMER', 'Order Price VAT is included, if applicable.')}
                </RegionBlock>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSolutionDisplay)
