import React from 'react'
import { Button, Dimmer, Form, Loader, Pagination, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'
import {
  fetchUserGifts, searchGiftsReceived, sortGiftsReceived, paginateGiftsReceived, declineReceivedGift, acceptReceivedGift, clearUserGifts,
} from '../redux/actions/user'

const mapStateToProps = state => {
  return {
    sortCriteria: state.user.giftsReceivedSortCriteria,
    sorted: state.user.giftsReceivedSorted,
    paginationCriteria: state.user.giftsReceivedPaginationCriteria,
    paginated: state.user.giftsReceivedPaginated,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGifts: () => dispatch(fetchUserGifts()),
    searchGifts: (criteria) => dispatch(searchGiftsReceived(criteria)),
    sortGifts: (criteria) => dispatch(sortGiftsReceived(criteria)),
    paginateGifts: (page) => dispatch(paginateGiftsReceived(page)),
    declineGift: (id) => dispatch(declineReceivedGift(id)),
    acceptGift: (id) => dispatch(acceptReceivedGift(id)),
    clearGifts: () => dispatch(clearUserGifts())
  }
}

class AccountDetailsGiftsReceived extends React.Component {

  state = {
    loading: false,
    searchValue: '',
  }
  handleSearch = this.handleSearch.bind(this)
  handlePageChange = this.handlePageChange.bind(this)

  async componentDidMount() {
    this.setState({ loading: true })

    await this.props.fetchGifts()
    
    this.setState({ loading: false })
  }

  handleSearch(e, { name, value }) {
    this.setState({ [name]: value })
    const { searchGifts } = this.props
    searchGifts(this.criteria(value))
  }

  criteria(value) {
    const lowerCaseValue = _.lowerCase(value)
    return (item) => {
      if (
        _.lowerCase(item.name).includes(lowerCaseValue) || 
        _.lowerCase(item.description).includes(lowerCaseValue) || 
        _.lowerCase(item.source_username).includes(lowerCaseValue) ||
        _.lowerCase(item.status).includes(lowerCaseValue)
      ) {
        return true
      } else {
        return false
      }
    }
  }

  handleSort(columnName) {
    const { sortGifts, sortCriteria } = this.props
    const { value, direction } = sortCriteria

    if (value !== columnName) {
      sortGifts({ value: columnName, direction: 'ascending' })
    } else if (value === columnName) {
      const newDirection = direction === 'ascending' ? 'descending' : 'ascending'
      sortGifts({ value, direction: newDirection })
    }
  }

  handlePageChange(e, { activePage }) {
    this.props.paginateGifts(activePage)
  }


  handleDeclineGift = (r) => {
    return async () => {
      const val = await swal({
        title: strings.getString('DECLINE_GIFT', 'Decline Gift'),
        buttons: {
          cancel: strings.getString('CANCEL', 'Cancel'),
          consume: {
            text: strings.getString('DECLINE', 'Decline'),
            value: 'decline',
            closeModal: false
          },
        },
        content: (
          <div style={{ textAlign: 'left' }}>
            <p>
              {strings.getString('ACCOUNT_GIFTS_DECLINE_INFO', 'Declining a gift will return an unclaimed gift to the sender.')}
            </p>
          </div>
        ),
      })

      if (val === 'decline') {
        try {
          await this.props.declineGift(r.request_id)
          await swal(strings.getString('DECLINE_SUCCESS', 'Successfully Declined ' + r.name, {item: r.name} ), {
            icon: 'success',
          })
          await this.props.fetchGifts()
        } catch (err) {
          swal(strings.getString('ACCOUNT_DECLINE_ERROR', 'Uh Oh! Unable to decline ' + r.name, {item: r.name} ), {
            icon: 'error',
          })
        }
      }
    }
  }


  handleAcceptGift = (r) => {
    return async () => {
      const val = await swal({
        title: strings.getString('ACCEPT_GIFT', 'Accept Gift.'),
        buttons: {
          cancel: strings.getString('CANCEL', 'Cancel'),
          consume: {
            text: strings.getString('ACCEPT', 'Accept'),
            value: 'accept',
            closeModal: false
          },
        },
        content: (
          <div style={{ textAlign: 'left' }}>
            <p>
              {strings.getString('ACCOUNT_ACCEPT_GIFT_REWARD', 'Accepting a gift will add the reward to your account.')}
            </p>
          </div>
        ),
      })

      if (val === 'accept') {
        try {
          await this.props.acceptGift(r.request_id)
          await swal(`${strings.getString('ACCEPT_SUCCESS', 'Successfully Accepted')} ${r.name}.`, {
            icon: 'success',
          })
          await this.props.fetchGifts()
        } catch (err) {

          if (err.response.data.message === 'otp required') {
            swal(`${strings.getString('ACCOUNT_ACCEPT_GIFT_2FA_REQUIRED', 'Uh Oh! Unable to accept gift until you add a 2-Step Verification to your account.')}`, {
              icon: 'error',
            })
          } else {
            swal(`${strings.getString('ACCEPT_ERROR', 'Uh Oh! Unable to accept')} ${r.name}.`, {
              icon: 'error',
            })
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearGifts()
  }

  render() {

    const { loading, searchValue } = this.state
    const { sortCriteria, sorted, paginationCriteria, paginated } = this.props
    const totalPages = Math.ceil(sorted.length / paginationCriteria.limit)

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString("GIFTS_RECEIVED", "Gifts Received")}</h1>

        <Form>
          <Form.Input 
            autoComplete="off" type="text"
            placeholder={strings.getString('SEARCH_GIFTS', 'Search Gifts')}
            name='searchValue'
            value={searchValue}
            onChange={this.handleSearch}/>
        </Form>

        <FixedTable padded sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={sortCriteria.value === "source_username" ? sortCriteria.direction : null} onClick={() => this.handleSort("source_username")}>
                {strings.getString('SENDER', 'Sender')}
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sortCriteria.value === "name" ? sortCriteria.direction : null} onClick={() => this.handleSort("name")}>
                {strings.getString('SENT', 'Sent')}
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sortCriteria.value === "quantity" ? sortCriteria.direction : null} onClick={() => this.handleSort("quantity")}textAlign="center">
                {strings.getString('QUANTITY', 'Quantity')}
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sortCriteria.value === "status" ? sortCriteria.direction : null} onClick={() => this.handleSort("status")}textAlign="center">
                {strings.getString('STATUS', 'Status')}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center"></Table.HeaderCell>
              <Table.HeaderCell textAlign="center"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {paginated.map(gift => (
              <Table.Row key={gift.request_id}>
                <Table.Cell>
                  <span style={{ fontSize: '16px' }}>{gift.source_username}</span>
                  <br/>
                  <span style={{ color: 'rgb(150, 150,150)' }}>{(new Date(gift.date_created)).toLocaleString()}</span>
                </Table.Cell>
                <Table.Cell singleLine>{gift.name}</Table.Cell>
                <Table.Cell singleLine collapsing textAlign="center">{gift.quantity}</Table.Cell>
                <Table.Cell singleLine collapsing textAlign="center">{_.startCase(gift.status)}</Table.Cell>
                <Table.Cell collapsing textAlign="center">
                  <Button size='mini' compact content={strings.getString('DECLINE', 'Decline')} onClick={this.handleDeclineGift(gift)} disabled={gift.status !== 'pending'}/>
                </Table.Cell>
                <Table.Cell collapsing textAlign="center">
                    <Button size='mini' compact primary={gift.status === 'pending'} onClick={this.handleAcceptGift(gift)} content={strings.getString('ACCEPT', 'Accept')} disabled={gift.status !== 'pending'}/>
                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination activePage={paginationCriteria.page} totalPages={totalPages} onPageChange={this.handlePageChange}/>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsGiftsReceived)
