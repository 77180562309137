import {
    SET_USER,
    SET_USER_VIRTUAL_WALLET,
    SET_USER_GUILD_APPS,
    SET_USER_GUILD_INVITES,
    SET_USER_GIFTS_AVAILABLE,
    SET_ACCOUNT_DISCOUNTS,
    SET_USER_GIFTS,
    SEARCH_GIFTS_GIVEN,
    SORT_GIFTS_GIVEN,
    PAGINATE_GIFTS_GIVEN,
    SEARCH_GIFTS_RECEIVED,
    SORT_GIFTS_RECEIVED,
    PAGINATE_GIFTS_RECEIVED,
    SET_USER_ENTITLEMENTS,
    SEARCH_GIFTABLE_ENTITLEMENTS,
    SORT_GIFTABLE_ENTITLEMENTS,
    PAGINATE_GIFTABLE_ENTITLEMENTS,
    CLEAR_USER_ENTITLEMENTS,
    CLEAR_USER_GIFTS,
    CLEAR_USER,
} from '../actionTypes'

import { api, guildApi } from '../../utilities/api'
import _ from 'lodash'
import {camelCaseToSnakeCase} from "../../utilities/stringUtilities";


// API CALLS

export const fetchUser = () => {
    return async function(dispatch) {
        const user = await api.get('/user')
        dispatch(setUser(user.data))
    }
}

export const fetchVirtualWallet = () => {
    return async function(dispatch) {
        const wallet = await api.get("/user/wallet-balance/cro")
        dispatch(setVirtualWallet(wallet.data))
    }
}

export const fetchUserGuildApplications = () => {
    return async function(dispatch) {
        const records = await guildApi.get(`/guild/user-join-requests`)
        const modeled = modelRequests(records.data)
        dispatch(setGuildApplications(modeled))
    }
}

export const fetchUserGuildInvites = () => {
    return async function(dispatch) {
        const records = await guildApi.get(`/guild/user-invites`)
        const modeled = modelRequests(records.data)
        dispatch(setGuildInvites(modeled))
    }
}

export const acceptUserGuildInvite = (guildInviteId) => {
    return async function() {
        await guildApi.post(`/guild/user-invites/${guildInviteId}/accept`)
    }
}

export const declineUserGuildInvite = (guildInviteId) => {
    return async function() {
        await guildApi.post(`/guild/user-invites/${guildInviteId}/decline`)
    }
}

export const withdrawGuildApplicationRequest = (guildInviteRequestId) => {
    return async function() {
        await guildApi.delete(`/guild/user-join-requests/${guildInviteRequestId}`)
    }
}

export const fetchGiftsAvailable = () => {
    return async function(dispatch) {
      const gifts = await api.get('/user/gift/pending')
      dispatch(setGiftsAvailable(gifts.data))
    }
  }

export const getSingleTransaction = (transactionId) => {
    return async function() {
        const transaction = await api.get(`/user/transaction-history/${transactionId}`)
        return transaction.data
    }
}

export const getWalletBalance = (currencyCode) => {
    return async function() {
        const wallet = await api.get(`/user/wallet-balance/${currencyCode}`)
        const modelled = modelWallet(wallet.data)
        return modelled
    }
}

export const getPromoCodeStatus = (code) => {
    return async function() {
        const promoCode = await api.get(`/user/code/${code}/status`)
        return promoCode.data || {}
    }
}

export const claimKickstarterPromoCode = (code) => {
    return async function() {
        await api.post(`/ks/${code}`)
    }
}

export const updateUserProfile = (user) => {
    return async function() {
        await api.put('/user', user)
    }
}

export const updateUserLocation = (zip, country, state) => {
    return async function() {
        await api.put('/user/location', {
            postal_code: zip,
            country,
            state,
        })
    }
}

export const fetchAccountDiscounts = () => {
    return async function(dispatch) {
        const discounts = await api.get('/store/discount')
        const modelled = modelDiscounts(discounts.data)
        dispatch(setAccountDiscounts(modelled))
    }
}

export const fetchUserGifts = () => {
    return async function(dispatch) {
        const gifts = await api.get('/user/gift')
        const modelled = modelGifts(gifts.data)
        dispatch(setUserGifts(modelled))
    }
}

export const cancelGiftGiven = (id) => {
    return async function() {
        await api.post(`user/gift/${id}/cancel`)
    }
}

export const declineReceivedGift = (id) => {
    return async function() {
        await api.post(`user/gift/${id}/decline`)
    }
}

export const acceptReceivedGift = (id) => {
    return async function() {
        await api.post(`user/gift/${id}/accept`)
    }
}

export const cancelRequestToJoinGuild = (requestId) => {
    return async function() {
        await guildApi.delete(`/guild/user-join-requests/${requestId}`)
    }
}

export const fetchUserEntitlements = () => {
    return async function(dispatch) {
        const entitlements = await api.get("/user/entitlement")
        const modeledEntitlements = modelEntitlements(entitlements.data)
        dispatch(setUserEntitlements(modeledEntitlements))
    }
}

export const sendGiftToUser = (giftValues) => {
    return async function() {
        await api.post(`/user/entitlement/gift`, { ...giftValues })
    }
}

export const updateEmail = (emailParameters) => {
    return async function() {
        await api.post('/user/email', { ...emailParameters })
    }
}

export const recoverEmail = (username) => {
    return async function() {
        await api.post('/user/self-help/recover-email', { username })
    }
}

export const resetPassword = (email) => {
    return async function() {
        await api.post('/user/self-help/password-reset', { email })
    }
}

export const resendEmailVerification = (email) => {
    return async function() {
      await api.post("/user/validate/resend", { email })
    }
}

export const requestStepUpCode = (streamtype) => {
    return async function() {
        await api.post("/user/stepup", { streamtype })
    }
}

export const validateStepUpCode = (code) => {
    return async function() {
        await api.post("/user/stepup/validate", { code })
    }
}

export const clearStepUpCodes = () => {
    return async function() {
        await api.delete("/user/stepup", {})
    }
}

export const sendInfluencerApplication = (app) => {
    return async function() {
        await api.post('/user/agreement/influencer-application', { metadata: app })
    }
}

export const send2faEmail = () => {
    return async function() {
        await api.post('/user/prepare-totp')
    }
}

export const getValidationTokenStatus = (token) => {
    return async function() {
        const res = await api.get(`/user/validation/${token}`)
        return res.data
    }
}

export const validateAccount = (token) => {
    return async function() {
        const res = await api.post(`/user/validate?token=${token}`)
        return res.data
    }
}

export const fetchUserCodes = () => {
    return async function() {
        const res = await api.get('/user/codes')
        return res.data
    }
}


// DISPATCH ACTION METHODS

export const setUser = user => {
    return (dispatch) => {
        dispatch({
            type: SET_USER,
            payload: {
                user,
                userId: user.user_id,
                accountRegions: user.regions,
            }
        })
        if (_.isEmpty(user) === false) {
            // dispatch(fetchUserGuildApplications())
            // dispatch(fetchUserGuildInvites())
            dispatch(fetchGiftsAvailable())
        }
    }
}

export const setVirtualWallet = wallet => ({
    type: SET_USER_VIRTUAL_WALLET,
    payload: wallet,
})

export const setGuildApplications = records => ({
    type: SET_USER_GUILD_APPS,
    payload: records
})

export const setGiftsAvailable = gifts => ({
    type: SET_USER_GIFTS_AVAILABLE,
    payload:  gifts,
  })

export const setGuildInvites = records => ({
    type: SET_USER_GUILD_INVITES,
    payload: records
})

export const setAccountDiscounts = discounts => ({
    type: SET_ACCOUNT_DISCOUNTS,
    payload: discounts
})

export const setUserGifts = gifts => {
    return (dispatch) => {
        dispatch({
            type: SET_USER_GIFTS,
            payload: gifts
        })
        dispatch(searchGiftsGiven())
        dispatch(searchGiftsReceived())
    }
}

export const searchGiftsGiven = criteria => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_GIFTS_GIVEN,
            payload: criteria
        })
        dispatch(sortGiftsGiven())
    }
}

export const sortGiftsGiven = criteria => {
    return (dispatch) => {
        dispatch({
            type: SORT_GIFTS_GIVEN,
            payload: criteria
        })
        dispatch(paginateGiftsGiven(1))
    }
}

export const paginateGiftsGiven = page => ({
    type: PAGINATE_GIFTS_GIVEN,
    payload: page
})

export const searchGiftsReceived = criteria => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_GIFTS_RECEIVED,
            payload: criteria
        })
        dispatch(sortGiftsReceived())
    }
}

export const sortGiftsReceived = criteria => {
    return (dispatch) => {
        dispatch({
            type: SORT_GIFTS_RECEIVED,
            payload: criteria
        })
        dispatch(paginateGiftsReceived(1))
    }
}

export const paginateGiftsReceived = page => ({
    type: PAGINATE_GIFTS_RECEIVED,
    payload: page
})

export const setUserEntitlements = entitlements => {
    return (dispatch) => {
        dispatch({
            type: SET_USER_ENTITLEMENTS,
            payload: {...entitlements}
        })
        dispatch(searchGiftableEntitlements())
    }
}

export const searchGiftableEntitlements = criteria => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_GIFTABLE_ENTITLEMENTS,
            payload: criteria
        })
        dispatch(sortGiftableEntitlements())
    }
}

export const sortGiftableEntitlements = criteria => {
    return (dispatch) => {
        dispatch({
            type: SORT_GIFTABLE_ENTITLEMENTS,
            payload: criteria
        })
        dispatch(paginateGiftableEntitlements())
    }
}

export const paginateGiftableEntitlements = page => ({
    type: PAGINATE_GIFTABLE_ENTITLEMENTS,
    payload: page,
})

export const clearUserEntitlements = () => ({
    type: CLEAR_USER_ENTITLEMENTS,
})

export const clearUserGifts = () => ({
    type: CLEAR_USER_GIFTS
})

export const clearUser = () => ({
    type: CLEAR_USER
})


// HELPER FUNCTIONS

const modelRequests = (records) => {
    return _.reverse(_.sortBy(camelCaseToSnakeCase(records), 'date_created'))
}

const modelWallet = (wallet) => {
    return {
        walletBalance: _.result(wallet, 'display_strings.total_funds', 0),
        hasWalletBalance: _.result(wallet, 'total_funds', 0) > 0,
        currentWalletBalance: _.result(wallet, 'total_funds', 0),
    }
}

const modelDiscounts = (discounts) => {
    return _.orderBy(discounts, ['percentage'], ['desc'])
}

const modelGifts = (gifts) => {
    const orderedGifts = _.reverse(_.sortBy(gifts, 'date_created'))
    return {
        gifts: orderedGifts,
        giftsGiven: orderedGifts.filter(gift => gift.direction === 'sent'),
        giftsReceived: orderedGifts.filter(gift => gift.direction === 'received')
    }
}

const modelEntitlements = (entitlements) => {
    const sorted = _.sortBy(entitlements, "name")
    return {
        entitlements: sorted,
        giftableEntitlements: sorted.filter(entitlement => entitlement.giftable && entitlement.usable_count > 0),
    }
}
