import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment-timezone'
import strings from '../../../strings'

import DataTable from '../../../componentLibrary/Models/DataTable'
import ProductTile from '../../../componentLibrary/Elements/ProductTile'
import CrownAmount from '../../../componentLibrary/Fragments/CrownAmount'
import SectionHeader from '../../../componentLibrary/Fragments/SectionHeader'

import { fetchStoreDetails, clearStoreDetails } from '../../../redux/actions/crowfall-store'
import { fetchVirtualWallet } from '../../../redux/actions/user'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        language: state.language,
        cohorts: state.cohorts,
        storeCatalog: state.crowfallStore.catalog,
        guildId: state.guildManagement.guildId,
        wallet: state.guildManagement.guildWallet,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchStoreDetails: (session, language, cohorts) => dispatch(fetchStoreDetails(session, language, cohorts)),
        clearStoreDetails: () => dispatch(clearStoreDetails()),
        fetchVirtualWallet: () => dispatch(fetchVirtualWallet()),
    }
}


class GuildBankPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            crownsCatalog: [],
        }
    }

    async componentDidMount() {
        const { session, language, cohorts, fetchStoreDetails, fetchVirtualWallet } = this.props
        await fetchStoreDetails(!!session, language, cohorts)
        await fetchVirtualWallet()
        this.modelCrownsCatalog(this.props.storeCatalog)
    }

    modelCrownsCatalog(catalog) {
        let crownsCatalog = catalog.filter(item => {
            return _.includes(item.categorySet, "Crowns")
        }).filter(item => {
            return item.giftable_to_guilds
        })
        this.setState({ crownsCatalog })
    }

    modelTransactions(transactions) {
        return transactions.map(transaction => {
            const { wallet_ledger_id, date_created, generated_by_username, balance_change, display_strings } = transaction
            let descriptionString = null
            if (balance_change > 0) {
                descriptionString = strings.getString("DEPOSIT", "Deposit")
            } else {
                const name = _.result(transaction, 'related_id_details.line_items[0].name')
                descriptionString = `${strings.getString("PURCHASE", "Purchase")}: ${name ? name : ""}`
            }
            return {
                id: wallet_ledger_id,
                elements: [
                    <div>{moment(date_created).format("MMM Do YYYY")}</div>,
                    <div>{generated_by_username}</div>,
                    <div>{descriptionString}</div>,
                    <CrownAmount amount={display_strings.balance_change} isNegative={balance_change < 0}/>,
                    <div>{display_strings.resulting_balance}</div>,
                ]
            }
        })
    }

    componentWillUnmount() {
        this.props.clearStoreDetails()
    }

    render() {

        const { wallet, guildId } = this.props
        const { balance } = wallet
        const { crownsCatalog } = this.state
        const transactionTableLabels = [
            {label: strings.getString("DATE_MODIFIED", "Date Modified")},
            {label: strings.getString("USERNAME", "Username")},
            {label: strings.getString("NOTE", "Note")},
            {label: strings.getString("BALANCE_CHANGE", "Balance Change")},
            {label: strings.getString("REMAINING_BALANCE", "Remaining Balance")},
        ]

        return (
            <div className="GuildBankPage">

                <SectionHeader title={strings.getString("GUILD_BANK", "Guild Bank")}/>
                <div className="GuildBankPage__balance">
                    {strings.getString("CURRENT_GUILD_FUNDS", `Current Guild Funds`)}:
                    <b style={{ paddingLeft: "8px" }}><CrownAmount amount={balance}/></b>
                </div>

                <div className="GuildBankPage__walletOptions">
                    <SectionHeader h3 title={strings.getString("ADD_TO_GUILD_WALLET", "Add to Guild Wallet")} />
                    <div className="GuildBankPage__productCards">
                        {crownsCatalog && crownsCatalog.length > 0 && crownsCatalog.map(item => {
                            const { item_id, heroImage, name, display_strings } = item
                            return (
                                <div className="GuildBankPage__card" key={`product-card-${item_id}`}>
                                    <ProductTile 
                                        img={heroImage} title={name} subtitle={display_strings.purchase_amount}
                                        link={`/checkout/${item_id}?guildId=${guildId}`}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

                <SectionHeader title={strings.getString("PURCHASE_AND_DEPOSIT_HISTORY", "Purchase and Deposit History")}/>
                <div className="GuildBankPage__transactionTable">
                    <DataTable labels={transactionTableLabels} rows={this.modelTransactions(wallet.ledger)} />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildBankPage)