import React from 'react'
import _ from 'lodash'
import { api } from '../../../utilities/api'
import contentful from '../../../utilities/contentful'
import strings from '../../../strings'
import Page from '../../../componentLibrary/Models/Page'
import './styles.scss'


class CrowmasPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            promo: {},
            sacrificeDestroy: []
        }
    }

    componentDidMount() {
        this.getContent()
    }

    async getContent() {
        const promos = await contentful.getEntries('promoCrowmas')
        const promo = promos[0] ? promos[0] : {}
        promo.bannerAssetUrl = _.result(promo, 'bannerAsset.assetUrl')
        promo.sunBannerUrl = _.result(promo, 'sunBanner.assetUrl')
        promo.sunSkullUrl = _.result(promo, 'sunSkull.assetUrl')
        promo.earthBannerUrl = _.result(promo, 'earthBanner.assetUrl')
        promo.earthSkullUrl = _.result(promo, 'earthSkull.assetUrl')
        promo.moonBannerUrl = _.result(promo, 'moonBanner.assetUrl')
        promo.moonSkullUrl = _.result(promo, 'moonSkull.assetUrl')
        const holidayStats = await api.get(
            '/events/holiday/sacrifice_destroy/aggregate'
        )
        const sacrificeDestroy = holidayStats.data
        this.setState({ promo, sacrificeDestroy })
    }

    render() {

        const { promo, sacrificeDestroy } = this.state
        const { pageTitle, promoTitle, bannerAssetUrl, tagline, pageContent, sunBannerUrl, sunSkullUrl, earthBannerUrl, earthSkullUrl, moonBannerUrl, moonSkullUrl } = promo
        const title = pageTitle ? pageTitle : promoTitle

        const sacrifice = sacrificeDestroy.length > 0 ? _.isNull(_.find(sacrificeDestroy, ['eventType', 'Sacrifice']).count) ? 0 : _.find(sacrificeDestroy, ['eventType', 'Sacrifice']).count.toLocaleString() : 0
        const destroy = sacrificeDestroy.length > 0 ? _.isNull(_.find(sacrificeDestroy, ['eventType', 'ItemDestroy']).count) ? 0 : _.find(sacrificeDestroy, ['eventType', 'ItemDestroy']).count.toLocaleString() : 0

        return (
            <Page header={{ title, banner: bannerAssetUrl }}>
                <div className='HolidayPage'>
                    {tagline && <div className="HolidayPage__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>}

                    <div className="HolidayPage__banners">
                        <div>
                            <div className='HolidayPage__banner__left' style={{ backgroundImage: `url(${sunBannerUrl})`}}>
                            </div>
                            <div className='HolidayPage__sacrifice__left'>{sacrifice}%</div>
                        </div>
                        <div>
                            <div className='HolidayPage__banner__right' style={{ backgroundImage: `url(${earthBannerUrl})`}}>
                            </div>
                            <div className='HolidayPage__sacrifice__right'>{destroy}%</div>
                        </div>
                    </div>

                    {pageContent && <div className="HolidayPage__content" dangerouslySetInnerHTML={{ __html: pageContent }}/>}
                </div>
            </Page>
        )
    }
}

export default CrowmasPage
