
export const camelCaseToSnakeCase = (objectToConvert) => {
    let jsonFields = JSON.parse(JSON.stringify(objectToConvert));

    for (let key in jsonFields) {
        if (jsonFields[key] instanceof Object) {
            // we need to go deeper!
            jsonFields[key] = camelCaseToSnakeCase(jsonFields[key]);
        }

        let snakeKey = key.replace(/\.?([A-Z]+)/g, function(x, y) {return '_' + y.toLowerCase();}).replace(/^_/, '');
        jsonFields[snakeKey] = jsonFields[key];
        // remove the unwanted camelCase key
        if (snakeKey !== key) {
            delete jsonFields[key];
        }
    }
    return jsonFields;
}