import React from 'react'
import _ from 'lodash'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import Page from '../../../componentLibrary/Models/Page'
import ContentCard from '../../../componentLibrary/Elements/ContentCard'
import Grid from '../../../componentLibrary/Fragments/Grid'
import ECSFooter from '../ECSFooter'
import './styles.scss'


class ECSLandingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            banner: null,
            title: null,
            tagline: null,
            body: null,
            footer: null,
            pageList: [],
        }
    }

    componentDidMount() {
        this.getContent()
    }

    async getContent() {
        const pageContent = await contentful.getEntry(assets.contentfulIds.ECS_LANDING_PAGE)
        const content = _.orderBy(await contentful.getEntries("eternalChampionsPage"), "displayWeight").map(entry => {
            entry.thumbUrl = _.result(entry, "cardAsset.assetUrl")
            entry.id = entry.contentful_id
            return entry
        }).filter(entry => entry.id !== assets.contentfulIds.ECS_APPLICATION_PAGE)
        this.setState({
            banner: _.result(pageContent, "banner.assetUrl"),
            title: pageContent.pageTitle,
            tagline: pageContent.tagline,
            body: pageContent.pageHeader,
            footer: pageContent.pageFooter,
            pageList: content,
        })
    }

    modelCards(list) {
        return list.map(entry => {
            const { thumbUrl, title, slug } = entry
            return (
                <ContentCard 
                    img={thumbUrl} title={title}
                    cardLink={`/eternal-champions/${slug}`}
                />
            )
        })
    }


    render() {
        const { banner, title, tagline, body, footer, pageList } = this.state

        return (
            <Page pageFooter={<ECSFooter/>} header={{ banner, title }}>
                <div className="ECSLandingPage">
                    {!_.isEmpty(tagline) && <div className="ECSLandingPage__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>}

                    {!_.isEmpty(body) && <div className="ECSLandingPage__body" dangerouslySetInnerHTML={{  __html: body }}/>}

                    <Grid list={this.modelCards(pageList)} featuredTwo fillBottomRow/>

                    {!_.isEmpty(footer) && <div className="ECSLandingPage__footer" dangerouslySetInnerHTML={{ __html: footer }}/>}
                </div>
            </Page>
        )
    }
}

export default ECSLandingPage