import React from 'react';
import {Button, Dimmer, Form, Loader} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {api} from '../utilities/api';
import strings from '../strings';
import queryString from 'query-string';
import {Redirect} from 'react-router-dom';
import {isEmpty} from 'lodash';
import swal from '@sweetalert/with-react';

const mapStateToProps = state => {
    return {
        connected: state.externalAccounts.twitchConnected
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

class AccountDiscordManagement extends React.Component {

    state = {
        connected: false,
        hasParams: false,
        loginName: '',
        loading: false
    };

    async componentDidMount() {
        this.setState({loading: true});
        let params = queryString.parse(this.props.location.search);
        if (!isEmpty(params) && params.code) {
            try {
                await api.post(`/user/external/discord/authorize/${params.code}`);
                swal({
                    content: <p className="external__message">Successfully connected Discord account</p>,
                    icon: 'success'
                });
            } catch (err) {
                swal({
                    content: <p className="external__message">Uh Oh! We were unable to connect your Discord account</p>,
                    icon: 'error'
                });
            }
        }
        await this.isDiscordAccountConnected();
    }

    async isDiscordAccountConnected() {
        const res = await api.get('/user/external/discord/is-account-connected');
        let {id, discord_name, discriminator} = res.data;
        this.setState({
            connected: id !== null,
            loginName: `${discord_name}#${discriminator}`,
            loading: false
        });
    }

    async disconnectDiscordAccount() {
        try {
            let result = await api.post('/user/external/discord/disconnect-account');
            let loginName = this.state.loginName;
            let loginNameStyled = <span className="external__alert_info">{loginName}</span>;
            if (result.data === true) {
                this.setState({
                    connected: false,
                    loginName: ''
                });
            }
            swal({
                content:
                    <div>
                        <p className="external__alert_title">Successfully disconnected Discord
                            account {loginNameStyled}</p>
                    </div>,
                icon: 'success'
            });
        } catch (exception) {
            swal({
                content: <p>Uh Oh! Unable to disconnect Discord account</p>,
                icon: 'error'
            });
        }
    }

    accountConnectAction = async () => {
        let redirectUrl = await api.post('/user/external/discord/get-url-code');
        const {authorize_url} = redirectUrl.data;
        window.location.replace(authorize_url);
    };

    accountDisconnectAction = async () => {
        if (this.state.connected) {
            await this.disconnectDiscordAccount();
            await this.isDiscordAccountConnected();
        }
    };

    render() {
        // if(this.state.hasParams) {
        //     return <Redirect to={'/discord-management'}/>
        // }

        const {connected, loginName, loading} = this.state;
        let loginNameStyled = <span className="external__alert_info"><strong>{loginName}</strong></span>;
        return (
            <div>
                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>
                <h1>Discord Management</h1>
                {
                    connected &&
                    <React.Fragment>
                        <p className="external__message">Your Crowfall account is connected to your Discord
                            account {loginNameStyled} </p>
                        <Form onSubmit={this.accountDisconnectAction.bind(this)}>
                            <Button className="ui secondary button" type="submit" secondary>Unlink Discord
                                Account</Button>
                        </Form>
                    </React.Fragment>
                }
                {
                    !connected &&
                    <React.Fragment>
                        <p className="external__message">Link your Crowfall and Discord Accounts in order to
                            automatically sync your Crowfall status with your Discord user in the Offical Crowfall
                            Discord Server.</p>
                        <p className="external__message">After you click the Link button you will be taken to the
                            Discord page where you will need to confirm you account is linked. Then you will be brought
                            back here in order to confirm the link was successful.</p>

                        <Form onSubmit={this.accountConnectAction.bind(this)}>
                            <Button className="ui primary button Button__ACE" type="submit" primary>Link Discord
                                Account</Button>
                        </Form>

                    </React.Fragment>
                }


            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDiscordManagement);
