import React from 'react'
import { Button, Card, Grid, Image } from 'semantic-ui-react'
import _ from 'lodash'
import strings from '../../strings'


class WalletMethods extends React.Component {

  state = {
    walletSelected: false,
  }


  selectWallet = async () => {
    this.setState(prevState =>({
      walletSelected: !prevState.walletSelected,
    }), () => {
      this.props.walletSelectionValidation(this.state.walletSelected);
    })
  }

  maintainUserCart() {
    this.props.cart.forEach(async (item) => {
      await this.props.addItemToUserCart(item.itemId)
    })
  }

  render() {

    const { loading, done } = this.state

    const { solution, cartSolution, guild, guildWallet, guildRank } = this.props
    const userSolution = !_.isEmpty(cartSolution) ? cartSolution : solution
    const walletDescription = _.result(userSolution, 'wallet.currency_details.description')
    const walletBalance = _.result(userSolution, 'display_strings.current_wallet_balance')
    const guildWalletDescription = _.result(guildWallet, 'description')
    const guildName = _.result(guild, 'displayName')
    const guildWalletBalance = _.result(guildWallet, 'balance')

    const showGuildWallet = _.result(userSolution, 'currency') === 'CRO' && !_.isEmpty(guild) && guildRank === 1
    const enableGuildWallet = !_.isEmpty(guildWallet) && guildWallet.amount >= userSolution.wallet_amount
    const isGuildWalletCheckout = _.result(userSolution, 'wallet.entity_type') === 'guild'

        return (
      <Grid>
        <Grid.Row centered>
              <Card.Group>

              {!isGuildWalletCheckout ? (
                <Card raised className={this.state.walletSelected ? 'selected' : ''} onClick={this.selectWallet}>
                  <Image style={{ background: 'none', padding: '20px' }} centered
                        src="https://web-cdn.crowfall.com/wallet-icon.png"/>
                  <Card.Content>
                    <Card.Header>{walletDescription}</Card.Header>
                    <Card.Description>{strings.getString("CURRENT_BALANCE", "Current Balance")}: {walletBalance}</Card.Description>
                    <Card.Meta>{strings.getString('PROVIDER', 'Provider')}: Crowfall</Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                  </Card.Content>
                </Card>
                ) : null}

                {isGuildWalletCheckout && showGuildWallet ? (
                  <Card raised disabled={!enableGuildWallet || loading || done} className={this.state.walletSelected ? 'selected' : ''} onClick={this.selectWallet}>
                    <Image style={{ background: 'none', padding: '20px' }} centered
                      src="https://web-cdn.crowfall.com/wallet-icon.png" />
                    <Card.Content>
                      <Card.Header>Guild {guildWalletDescription}</Card.Header>
                      <Card.Description>{strings.getString("CURRENT_BALANCE", "Current Balance")}: {guildWalletBalance}</Card.Description>
                      <Card.Meta>{strings.getString('PROVIDER', 'Provider')}: {guildName}</Card.Meta>
                    </Card.Content>
                    <Card.Content extra>

                    </Card.Content>
                  </Card>
                ) : null}

          </Card.Group>
        </Grid.Row>
      </Grid>
    )
  }
}

export default WalletMethods
