import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../../strings'

import DataTable from '../../../componentLibrary/Models/DataTable'
// import AccordionItem from '../../../componentLibrary/Elements/AccordionItem'
import Button from '../../../componentLibrary/Fragments/Button'
import SectionHeader from '../../../componentLibrary/Fragments/SectionHeader'

import { fetchGuildApplications, fetchGuildDetails, acceptApplication, declineApplication } from '../../../redux/actions/guild-management'
import './styles.scss'

const mapStateToProps = state => {
    return {
        guildId: state.guildManagement.guildId,
        guild: state.guildManagement.guild,
        guildConfiguration: state.guildManagement.guildConfiguration,
        guildRank: state.guildManagement.guildRank,
        applications: state.guildManagement.guildApplications,
        bannedUsers: state.guildManagement.bannedUsers,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchGuildApplications: (guildId) => dispatch(fetchGuildApplications(guildId)),
        fetchGuildDetails: () => dispatch(fetchGuildDetails()),
        acceptApplication: (guildId, appId) => dispatch(acceptApplication(guildId, appId)),
        declineApplication: (guildId, appId) => dispatch(declineApplication(guildId, appId)),
    }
}

class GuildApplicationsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedApp: null,
            showBannedUsers: false,
        }
        this.selectApplication = this.selectApplication.bind(this)
        this.acceptApplication = this.acceptApplication.bind(this)
        this.denyApplication = this.denyApplication.bind(this)
        this.toggleBannedUsers = this.toggleBannedUsers.bind(this)
    }

    modelApplications(applications) {
        return applications.map(app => ({
            id: app.guild_invite_request_id,
            elements: [
                <div>{app.username}</div>,
                <div>{moment(app.date_created).format("MMM Do, YYYY")}</div>,
            ],
            accordionElement: (
                <div className="GuildApplicationsPage__application">
                    <div className="GuildApplicationsPage__applicationHeader">{strings.getString("APPLICANTS_MESSAGE", "Applicant's request to join:")}</div>
                    {!_.isEmpty(app.message) && <div className="GuildApplicationsPage__applicationMessage" dangerouslySetInnerHTML={{ __html: app.message }}/>}
                    {_.isEmpty(app.message) && <div className="GuildApplicationsPage__applicationEmptyMessage">{strings.getString("NONE_PROVIDED", "None Provided.")}</div>}
                    <div className="GuildApplicationsPage__applicationButtons">
                        <Button label={strings.getString("ACCEPT", "Accept")} medium onclick={() => this.acceptApplication(app)}/>
                        <Button label={strings.getString("DENY", "Deny")} medium onclick={() => this.denyApplication(app)}/>
                        {/* <Button label={strings.getString("BAN_USER", "Ban User")} medium onclick={() => this.banUser()}/> */}
                    </div>
                </div>
            )
        }))        
    }

    modelBannedUsers(users) {
        return []
    }

    selectApplication(id) {
        this.setState({ selectedApp: id })
    }

    async acceptApplication(application) {
        const { guildId, acceptApplication, fetchGuildDetails, fetchGuildApplications } = this.props
        const { guild_invite_request_id: applicationId, username } = application
        const val = await swal({
            title: strings.getString("ACCEPT_USER_APPLICATION", `Accept application from ${username}`, { username }),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                accept: {
                    text: strings.getString("ACCEPT", "Accept"),
                    value: 'accept', closeModal: false,
                }
            }
        })

        if (val === 'accept') {
            try {
                await acceptApplication(guildId, applicationId)
                fetchGuildDetails()
                swal(`${strings.getString("GUILD_ACCEPT_INVITE_SUCCESS", "Successfully accepted invite request from " + username, {username})}.`, {
                    icon: 'success',
                })
            } catch (err) {
                const responseMessage = _.result(err.response.data, 'Message')
                await swal(`${responseMessage}`, {
                    icon: 'error',
                })
                await fetchGuildApplications(guildId)
            }
        }
    }

    async denyApplication(app) {
        const { declineApplication, fetchGuildApplications, guildId } = this.props
        const { guild_invite_request_id: applicationId, username } = app
        const val = await swal({
            title: strings.getString("DECLINE_USER_APPLICATION", `Decline application from ${username}`, { username }),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                decline: {
                    text: strings.getString("DECLINE", "Decline"),
                    value: 'decline', closeModal: false,
                }
            }
        })

        if (val === 'decline') {
            try {
                await declineApplication(guildId, applicationId)
                await fetchGuildApplications(guildId)
                swal(`${strings.getString("GUILD_DECLINE_INVITE_SUCCESS", "Successfully declined invite request from " + username, {username})}.`, {
                    icon: 'success',
                })
            } catch (err) {
                const responseMessage = _.result(err.response.data, 'Message')
                await swal(`${responseMessage}`, {
                    icon: 'error'
                })
                await fetchGuildApplications(guildId)
            }
        }
    }

    banUser(id) {

    }

    toggleBannedUsers() {
        this.setState({ showBannedUsers: !this.state.showBannedUsers })
    }

    render() {

        const { applications, guild, guildConfiguration, guildRank,
            // bannedUsers 
        } = this.props
        const recruitingStatus = guild.recruitingStatus
        const rankToModifyGuild = _.result(_.find(guildConfiguration.permission_display, { "value": "modify_guild" }), "required_rank")
        const canManageSettings = guildRank && guildRank <= rankToModifyGuild ? true : false
        const notAcceptingApplications = recruitingStatus === "closed" || recruitingStatus === "open" ? true : false
        // const { showBannedUsers } = this.state
        // const bannedUsersCount = bannedUsers ? bannedUsers.length : 0
        const applicationLabels = [
            {label: strings.getString("USERNAME", "Username")}, 
            {label: strings.getString("DATE_APPLIED", "Date Applied")}, 
            {},
        ]
        // const bannedUsersLabels = [
        //     {label: strings.getString("NAME", "Name")},
        //     {label: strings.getString("DATE_BANNED", "Date Banned")},
        //     {label: strings.getString("REASONING", "Reasoning")},
        //     {},
        // ]

        return (
            <div className="GuildApplicationsPage">
                <SectionHeader title={strings.getString("PENDING_APPLICATIONS", "Pending Applications")} />
                <div className="GuildApplicationsPage__pendingApplications">
                    {applications && applications.length === 0 && <div className="GuildApplicationsPage__noApplicationsText">
                        <p>{strings.getString("GUILD_NO_PENDING_APPS", "There are no current pending applications.")}</p>
                    </div>}
                    {applications && applications.length > 0 && <div className="GuildApplicationsPage__applicationsTable">
                        <DataTable labels={applicationLabels} rows={this.modelApplications(applications)} select={this.selectApplication}/>
                    </div>}
                    {notAcceptingApplications && _.isEmpty(applications) && <div className="GuildApplicationsPage__notAcceptingText">
                        {recruitingStatus === "open" &&
                            <p>{strings.getString("GUILD_OPEN_NOT_ACCEPTING", "Your Guild is in open recruitment, and therefore not currently accepting new applications.")}</p>
                        }
                        {recruitingStatus === "closed" &&
                            <p>{strings.getString("GUILD_CLOSED_NOT_ACCEPTING", "Your Guild is closed, and therefore not currently accepting new applications.")}</p>
                        }
                        {canManageSettings && <span>{strings.getString("GUILD_HOW_TO_UPDATE_RECRUITMENT", "To manage Recruitment Status, visit the Guild Overview page.")}</span>}
                    </div>}
                </div>

                {/* <SectionHeader title={strings.getString("BANNED_USERS", "Banned Users")}/>
                <div className="GuildApplicationsPage__bannedUsers">
                    <AccordionItem gray shouldShow={showBannedUsers} select={this.toggleBannedUsers} label={strings.getString("BANNED_USERS_TOTAL", `Total Banned Users: ${ bannedUsersCount }`, { count: bannedUsersCount })}>
                        <div className="GuildApplicationsPage__bannedUsersTable">
                            <Table labels={bannedUsersLabels} rows={this.modelBannedUsers(bannedUsers)}/>
                        </div>
                    </AccordionItem>
                </div> */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildApplicationsPage)