/*

Fragment: SectionHeader
Provides a stylized name, and an interactive icon.

PROPERTY  DEFAULT  REQUIREMENT  TYPE      DESCRIPTION
title     ""       Required     String    Descriptive label. Left-aligned.
icon      null     Optional     String    used to set the Font Awesome Icon to be displayed
click     null     Optional     Function  Called when Icon is clicked. Requires 'icon' prop
h3        false    Optional     Boolean   Styling sizes decreased to be used as a subsection header
h5        false    Optional     Boolean   Styling sized decreased to be used as a minor section header

*/

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'


const SectionHeader = (props) => {

    const { title, icon, click, h3, h5 } = props
    let className = "SectionHeader"
    if (h3) className += " SectionHeader--h3"
    if (h5) className += " SectionHeader--h5"
    if (icon) className += " SectionHeader--hasIcon"

    return (
        <div className={className}>
            <div className="SectionHeader__title">{title}</div>
            {icon && <div className="SectionHeader__icon" onClick={click}><FontAwesomeIcon icon={icon}/></div>}
        </div>
    )
}

export default SectionHeader