import React from 'react'
import SocialButton from '../SocialButton'
import './styles.scss'
import strings from '../../../strings';

const SocialButtons = (props) => {
  const { facebook, twitter, mail, title } = props
  return (
    <div className='SocialButtons'>
      <div className='SocialButtons__text'>{strings.getString('SHARE', 'Share:')}</div>
      <div className="SocialButtons__buttons">
        { facebook && <div className="SocialButtons__button"><SocialButton facebook /></div> }
        { twitter && <div className="SocialButtons__button"><SocialButton twitter title={title}/></div> }
        { mail && <div className="SocialButtons__button"><SocialButton mail title={title}/></div> }
      </div>
    </div>
  )
}

export default SocialButtons