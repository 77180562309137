import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'


const mapStateToProps = state => {
    return {
        configuration: state.guildManagement.guildConfiguration,
        guildRank: state.guildManagement.guildRank,
    }
}

const VerifyUserPermissions = (props) => {

    const { 
        configuration, guildRank, children,
        canModifyGuild, canUseGuildBank, canInviteToGuild, canViewGuildBank, 
        canAddToGuildBank, canEditMotd, canViewPendingInvites, canKickMemberFromGuild,
    } = props
    const { permission_display } = configuration
    let show = false

    if (canModifyGuild) show = modifyGuild()
    if (canInviteToGuild) show = inviteToGuild()
    if (canViewGuildBank) show = viewGuildBank()
    if (canUseGuildBank) show = consumeFromGuildBank()
    if (canAddToGuildBank) show = addToGuildBank()
    if (canEditMotd) show = editMotd()
    if (canViewPendingInvites) show = viewPendingInvites()
    if (canKickMemberFromGuild) show = kickMemberFromGuild()

    
    return (
        <React.Fragment>
            { (show === true) ? children : null }
        </React.Fragment>
    )

    function modifyGuild() {
        const rank = _.find(permission_display, { "value": "modify_guild" }) || {}
        const rankRequired = rank.required_rank
        return rankRequired && guildRank && guildRank <= rankRequired ? true : false
    }

    function viewGuildBank() {
        const rank = _.find(permission_display, { "value": "view_guild_bank" }) || {}
        const rankRequired = rank.required_rank
        return rankRequired && guildRank && guildRank >= rankRequired ? true : false        
    }

    function consumeFromGuildBank() {
        const rank = _.find(permission_display, { "value": "use_guild_bank" }) || {}
        const rankRequired = rank.required_rank
        return rankRequired && guildRank && guildRank <= rankRequired ? true : false        
    }

    function inviteToGuild() {
        const rank = _.find(permission_display, { "value": "invite_to_guild" }) || {}
        const rankRequired = rank.required_rank
        return rankRequired && guildRank && guildRank <= rankRequired ? true : false        
    }

    function addToGuildBank() {
        const rank = _.find(permission_display, { "value": "add_to_guild_bank" }) || {}
        const rankRequired = rank.required_rank
        return rankRequired && guildRank && guildRank <= rankRequired ? true : false        
    }

    function editMotd() {
        const rank = _.find(permission_display, { "value": "message_of_the_day" }) || {}
        const rankRequired = rank.required_rank
        return rankRequired && guildRank && guildRank <= rankRequired ? true : false        
    }

    function viewPendingInvites() {
        const rank = _.find(permission_display, { "value": "view_pending_invites" }) || {}
        const rankRequired = rank.required_rank
        return rankRequired && guildRank && guildRank <= rankRequired ? true : false        
    }

    function kickMemberFromGuild() {
        const rank = _.find(permission_display, { "value": "kick_member_from_guild" }) || {}
        const rankRequired = rank.required_rank
        return rankRequired && guildRank && guildRank <= rankRequired ? true : false        
    }
}

export default connect(mapStateToProps)(VerifyUserPermissions)