import React from 'react'
import { connect } from 'react-redux'
import { Button, Grid } from 'semantic-ui-react'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import { api } from '../../../utilities/api'
import strings from '../../../strings'
import { addItemToUserCart, chargeWithSolution } from '../../../redux/actions/crowfall-store'
import './styles.scss'

const mapStateToProps = state => {
  return {
    cart: state.crowfallStore.cartSolutions,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addItemToUserCart: (id) => dispatch(addItemToUserCart(id)),
    chargeWithSolution: (id) => dispatch(chargeWithSolution(id))
  }
}

class CompleteButton extends React.Component {

  state = {
    checkoutSolutionId: null,
    loading: false,
    handleTxn: null,
    keepCart: false,
    done: false,
    transactionType: '',
    walletSelected: this.props.walletSelected,
  }


  static getDerivedStateFromProps(props, state) {
    if (!_.isEmpty(props.solution)) {
      state.checkoutSolutionId = _.result(props.solution, 'checkout_solution_id')
      if (!_.isEmpty(props.cart)) state.keepCart = true
    } else if (!_.isEmpty(props.cartSolution)) {
      state.checkoutSolutionId = _.result(props.cartSolution, 'checkout_solution_id')
    }
    state.handleTxn = props.handleTxn
    state.walletSelected = props.walletSelected
    return state
  }


  handleWallet = async () => {
    this.setState({
      loading: true,
    })

    const solution = await this.props.chargeWithSolution(this.state.checkoutSolutionId)

    const transactionId = _.result( solution,'transaction.transaction_id')
    this.state.handleTxn(transactionId).catch(console.error)
    if (this.state.keepCart) this.maintainUserCart()

    this.setState({
      loading: false,
      done: true
    })

  }


  walletValidation = async () => {
    const { solution, cartSolution, guild, guildWallet, guildRank } = this.props
    const userSolution = !_.isEmpty(cartSolution) ? cartSolution : solution

    const showGuildWallet = _.result(userSolution, 'currency') === 'CRO' && !_.isEmpty(guild) && guildRank === 1
    const isGuildWalletCheckout = _.result(userSolution, 'wallet.entity_type') === 'guild'

    if (this.state.walletSelected) {
      this.handleWallet();
    } else {
      await swal({
        icon: 'error',
        title: strings.getString("UH_OH", "Uh Oh!"),
        content: (
            <p>{strings.getString("SELECT_PAYMENT_METHOD_COMPLETE_PURCHASE", "Please select a payment method before completing your purchase.")}</p>
        )
      })
    }
  }


  render() {


    const { solution, cartSolution, guild, guildWallet, guildRank } = this.props
    const userSolution = !_.isEmpty(cartSolution) ? cartSolution : solution

    const showGuildWallet = _.result(userSolution, 'currency') === 'CRO' && !_.isEmpty(guild) && guildRank === 1
    const enableGuildWallet = !_.isEmpty(guildWallet) && guildWallet.amount >= userSolution.wallet_amount
    const isGuildWalletCheckout = _.result(userSolution, 'wallet.entity_type') === 'guild'

    return (
      <Grid>
        <Grid.Row centered>
          <Button raised onClick={this.walletValidation} className="CompletePurchase">
            Complete Purchase
          </Button>
        </Grid.Row>
      </Grid>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteButton)
