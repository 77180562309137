import{
    SET_SUBSCRIPTION_DETAILS,
    SET_SUBSCRIPTION_PAYMENT_METHOD,
    CLEAR_SUBSCRIPTION_DETAILS,
 } from '../actionTypes'

import { api } from '../../utilities/api'
import _ from 'lodash'


// API CALLS

export const fetchAllSubscriptionDefinitions = () => {
    return async function(){
        const subDefinitions = await api.get('/subscription/definition')
        return _.result(subDefinitions.data[0], "subscriptionDefinitions")
    }
}

export const fetchVIPSubscriptionDetails = () => {
    return async function(dispatch){
        const subscriptionDetails = await api.get('/subscription/user')
        dispatch(setSubscriptionDetails(subscriptionDetails.data))
    }
}

export const createUsersVIPSubscription = (itemId) => {
    return async function(dispatch){
        const subscriptionDetails = await api.post('/subscription/user', { item_id: itemId })
        dispatch(setSubscriptionDetails(subscriptionDetails.data))
    }
}

export const updateUsersSubscription = (newSubscription) => {
    return async function(dispatch){
        const subscriptionDetails = await api.post('/subscription/user/update', { item_id: newSubscription })
        dispatch(setSubscriptionDetails(subscriptionDetails.data))
    }
}

export const cancelUsersSubscription = () => {
    return async function(){
        await api.post('/subscription/user/cancel')
    }
}

export const fetchSubscriptionPaymentMethod = () => {
    return async function(dispatch){
        const usersPaymentMethod = await api.get('/external/xsolla/payment-methods')
        dispatch(setSubscriptionPaymentMethod(usersPaymentMethod))
    }
}

export const pauseVIPConsumption = () => {
    return async function(dispatch) {
        const res = await api.post('/subscription/user/pause')
        dispatch(setSubscriptionDetails(res.data))
    }
}

export const resumeVipConsumption = () => {
    return async function(dispatch) {
        const res = await api.post('/subscription/user/enable')
        dispatch(setSubscriptionDetails(res.data))
    }
}


// ACTION METHODS

export const setSubscriptionDetails = subscriptionDetails => ({
    type: SET_SUBSCRIPTION_DETAILS,
    payload: subscriptionDetails
})

export const setSubscriptionPaymentMethod =  paymentMethod => ({
    type: SET_SUBSCRIPTION_PAYMENT_METHOD,
    payload: paymentMethod
})

export const clearSubscriptionDetails = () => ({
    type: CLEAR_SUBSCRIPTION_DETAILS,
})