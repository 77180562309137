import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  fetchAcceptedRegionalAgreements,
  fetchCohorts,
  toggleInitialFetch,
} from '../redux/actions'
import { fetchGuildConfiguration } from '../redux/actions/guilds'
import { fetchUser } from '../redux/actions/user'
import { fetchUserCart } from '../redux/actions/crowfall-store' 
import { fetchGuildDetails } from '../redux/actions/guild-management' 
import { fetchAffiliateDetails } from '../redux/actions/affiliates'

const mapStateToProps = state => {
  return {
    session: state.session,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCohorts: () => dispatch(fetchCohorts()),
    fetchUser: () => dispatch(fetchUser()),
    // fetchUserCart: () => dispatch(fetchUserCart()),
    // fetchAffiliateDetails: () => dispatch(fetchAffiliateDetails()),
    fetchAcceptedAccountAgreements: () => dispatch(fetchAcceptedRegionalAgreements()),
    // fetchGuildConfiguration: () => dispatch(fetchGuildConfiguration()),
    // fetchGuildDetails: () => dispatch(fetchGuildDetails()),
    toggleInitialFetch: (status) => dispatch(toggleInitialFetch(status)),
  }
}

class SetupContext extends React.Component {

  componentDidMount() {
    this.setupContext()
  }

  componentDidUpdate(prevProps) {
    const { session: prevSession } = prevProps
    const { session: currSession } = this.props
    if (prevSession !== currSession) this.setupContext()
  }

  async setupContext() {
    const { session, fetchUser, fetchUserCart, fetchCohorts, fetchAcceptedAccountAgreements, fetchGuildDetails, toggleInitialFetch, fetchAffiliateDetails } = this.props
    if (session) {
      try {
        // fetchGuildDetails()
        await fetchUser()
        // await fetchUserCart()
        await fetchCohorts()
        await fetchAcceptedAccountAgreements()
        // await fetchAffiliateDetails()
        toggleInitialFetch(true)
      } catch (err) {
        console.log("ERROR:", _.result(err, "response.data.message"))
      }
    }
    toggleInitialFetch(true)
  }

  render() {
    return (
      <React.Fragment/>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupContext)
