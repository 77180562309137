import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import queryString from 'query-string'
import PromoCodeForm from '../../PromoCodeForm'
import HelperLinks from '../../HelperLinks'
import RegistrationForm from '../../RegistrationForm'
import LoginForm from '../../LoginForm'
import assets from '../../../../assets'
import strings from '../../../../strings'
import contentful from '../../../../utilities/contentful'
import Page from '../../../../componentLibrary/Models/Page'
import Button from '../../../../componentLibrary/Fragments/Button'
import TextLink from '../../../../componentLibrary/Fragments/TextLink'
import CrowfallPartnerRibbon from '../../../../componentLibrary/Fragments/CrowfallPartnerRibbon'
import { verifyValidationToken, validateAccount } from '../../../../redux/actions'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        config: state.config,
        cohorts: state.cohorts,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        verifyValidationToken: (token) => dispatch(verifyValidationToken(token)),
        validateAccount: (token) => dispatch(validateAccount(token)),
    }
}

class IntelLander extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initialFetch: false,
            bannerLogo: null,
            featuredAsset: null,
            tagline: null,
            callout: null,
            body: null,
        
            intelCode: null,
            showLoginForm: false,
            redirectToHome: false,
        }
        this.getQueryParameters = this.getQueryParameters.bind(this)
        this.getPageContent = this.getPageContent.bind(this)
        this.handleRegistrationSuccess = this.handleRegistrationSuccess.bind(this)
        this.handleValidationError = this.handleValidationError.bind(this)
    }

    async componentDidMount() {
        this.getQueryParameters()
        await this.getPageContent()
        this.setState({ initialFetch: true })
    }

    componentDidUpdate(prevProps) {
        const { location: prevLocation } = prevProps
        const { location: currLocation } = this.props
        if (!_.isEqual(prevLocation.search, currLocation.search)) this.getQueryParameters()
    }

    async getPageContent() {
        const content = await contentful.getEntry(assets.contentfulIds.INTEL_LANDER)
        this.setState({
            bannerLogo: _.result(content, "bannerLogo.assetUrl"),
            featuredAsset: _.result(content, "footerBannerAsset.assetUrl"),
            tagline: content.tagline,
            callout: content.pageHeader,
            body: content.pageFooter
        })
    }

    getQueryParameters() {
        const { location } = this.props
        const query = queryString.parse(location.search)
        if (query.token) {
            this.validateAccountWithToken(query.token)
        } else {
            this.setState({
                intelCode: query.code,
                showLoginForm: !!query.login,
            })
        }
    }

    async validateAccountWithToken(token) {
        const { verifyValidationToken, validateAccount } = this.props
        try {
            const status = await verifyValidationToken(token)
            const { validation_token_active: validationTokenActive, validation_set: validationTokenSet, username_set: usernameSet } = status
            if (validationTokenActive && !validationTokenSet && usernameSet) {
                try {
                    await validateAccount(token)
                    swal({
                        icon: "success",
                        title: strings.getString("VALIDATION_ACCOUNT_SUCCESS_MESSAGE", "Your email address has been successfully validated! You are now Signed In.")
                    })
                } catch (err) {
                    this.handleValidationError()
                }
            } else if (!validationTokenActive && !validationTokenSet) {
                this.handleValidationError()
            }
        } catch (err) {
            this.handleValidationError()
        }
    }

    handleValidationError() {
        swal({
            title: strings.getString("UH_OH", "Uh Oh!"),
            icon: 'error',
            content: ( <span>{strings.getString("VALIDATION_ACCOUNT_ERROR", "Unable to validate account")}</span> )
        })
        this.setState({ redirectToHome: true })
    }

    handleRegistrationSuccess() {
        this.setState({ redirectToHome: true })
    }


    render() {

        const { initialFetch, bannerLogo, featuredAsset, tagline, callout, body, 
                intelCode, showLoginForm, redirectToHome } = this.state
        const { session, cohorts, config } = this.props
        const { can_play_crowfall: canDownloadGame, consumed_intel_code: hasIntelCode } = cohorts
        const downloadLink = canDownloadGame ? `${_.result(config, "base_api_host")}/user/asset-download/CFInstaller_Live.exe?token=${_.result(session, "token")}` : null

        return (
            <Page bannerRibbon={ <CrowfallPartnerRibbon partnerLogo={bannerLogo}/> }>

                {redirectToHome && <Redirect to="/"/>}

                <div className="IntelLander">

                    {!_.isEmpty(tagline) && <div className="IntelLander__tagline" dangerouslySetInnerHTML={{  __html: tagline }}/>}

                    <div className="IntelLander__body">

                        {initialFetch && session && hasIntelCode &&
                            <div className="IntelLander__box">
                                <div className="IntelLander__header">{strings.getString("CONGRATULATIONS", "Congratulations!")}</div>
                                <div className="IntelLander__text">{strings.getString("WELCOME_TO_INTEL", "Welcome to the Intel x Crowfall Closed Beta!")}</div>
                                <div className="IntelLander__text">{strings.getString("DOWNLOAD_BETA_CLICK_BELOW", "Download the Beta Now - Click the Button Below!")}</div>
                                <br/><br/>

                                <div className="IntelLander__title">{strings.getString("DOWNLOAD_BETA_NOW", "Download Beta Now")}</div>
                                <div className="IntelLander__gameLinks">
                                    <a href={downloadLink}>
                                        <Button green medium disabled={!canDownloadGame} label={strings.getString("DOWNLOAD_BETA_NOW", "Download Beta Now")}/>
                                    </a>
                                    {!canDownloadGame && <TextLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString("BUY_CROWFALL", "Buy Crowfall")}</TextLink>}
                                </div>
                            </div>
                        }

                        {initialFetch && session && !hasIntelCode &&
                            <div className="IntelLander__box">
                                <PromoCodeForm 
                                    codeFieldLabel={strings.getString('ENTER_YOUR_BETA_CODE', 'Enter Your Beta Code')}
                                    codeFieldInput={intelCode}/>
                                <div className="IntelLander__title">{strings.getString("DOWNLOAD_BETA_NOW", "Download Beta Now")}</div>
                                <div className="IntelLander__gameLinks">
                                    <a href={downloadLink}>
                                        <Button green medium disabled={!canDownloadGame} label={strings.getString("DOWNLOAD_BETA_NOW", "Download Beta Now")}/>
                                    </a>
                                    {!canDownloadGame && <TextLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString("BUY_CROWFALL", "Buy Crowfall")}</TextLink>}
                                </div>
                            </div>
                        }

                        {initialFetch && !session && !showLoginForm &&
                            <div className="IntelLander__box">
                                <HelperLinks useIntelRedirects code={intelCode} showSignInHelp/>
                                <br/>
                                {/*<RegistrationForm */}
                                {/*    onSuccess={this.handleRegistrationSuccess} */}
                                {/*    codeFieldLabel={strings.getString('ENTER_BETA_CODE_FOR_REGISTRATION', 'Enter Your Beta Code to Create Your Crowfall Account')} */}
                                {/*    codeFieldInput={intelCode} orderCodeFieldFirst*/}
                                {/*    registrationSource="intel-lander"/>*/}
                            </div>
                        }

                        {initialFetch && !session && showLoginForm &&
                            <div className="IntelLander__box">
                                <HelperLinks useIntelRedirects code={intelCode} showSignupHelp/>
                                <br/>
                                <LoginForm 
                                    addCodeField
                                    codeFieldLabel={strings.getString('ENTER_BETA_CODE_FOR_REGISTRATION', 'Enter Your Beta Code to Create Your Crowfall Account')} 
                                    codeFieldInput={intelCode}/>
                                <br/>
                                <HelperLinks showAccountHelp/>                                
                            </div>
                        }

                        <div className="IntelLander__box">
                            <img src={featuredAsset} className="IntelLander__asset" alt="Intel Asset"/>
                            <div className="IntelLander__callout" dangerouslySetInnerHTML={{ __html: callout }}/>
                            <div className="IntelLander__header">{strings.getString("REDEEM_CODE_CREATE_ACCOUNT", "Redeem *Code / Create Your Crowfall Account")}</div>
                            <div dangerouslySetInnerHTML={{ __html: body }}/>
                        </div>

                    </div>

                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntelLander)