import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import strings from '../../strings'
import RegistrationPage from './RegistrationPage'
import ValidateEmailPage from './ValidateEmailPage'
import ChooseAPackPage from './ChooseAPackPage'
import RegistrationCheckoutPage from './RegistrationCheckoutPage'
import DownloadPackPage from './DownloadPackPage'
import Page from '../../componentLibrary/Models/Page'
import Stepper from '../../componentLibrary/Elements/Stepper'
import './styles.scss'
import _ from 'lodash'


const mapStateToProps = state => {
    return {
        session: state.session,
        cohorts: state.cohorts,
    }
}

class Registration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currStep: 0,
        }
    }

    componentDidMount() {
        this.deriveStepFromParams()
    }

    componentDidUpdate(prevProps) {
        const { search: prevSearch } = prevProps.location
        const { search: currSearch } = this.props.location
        const prevCohorts = prevProps.cohorts
        const currCohorts = this.props.cohorts

        if (prevSearch !== currSearch) this.deriveStepFromParams()
        if (prevCohorts !== currCohorts) this.deriveStepFromParams()
    }

    deriveStepFromParams() {
        const { location, session, cohorts } = this.props
        const query = queryString.parse(location.search)
        if (session) {
            if (cohorts.can_play_crowfall && !query.download) {
                this.setState({ currStep: 5 })
                return
            } else if (_.isEmpty(query) || query.validate) {
                this.setState({ currStep: 3 })
                return
            }
        }

        if (query.validate) {
            this.setState({ currStep: 2 })
        } else if (query.packs) {
            this.setState({ currStep: 3 })
        } else if (query.checkout) {
            this.setState({ currStep: 4 })
        } else if (query.download && query.orderId) {
            this.setState({ currStep: 5 })
        } else {
            this.setState({ currStep: 1 })
        }
    }

    render() {

        const { currStep } = this.state
        const { location } = this.props
        const query = queryString.parse(location.search)

        const stepLabels = [
            strings.getString("CREATE_AN_ACCOUNT", "Create an Account"),
            strings.getString("VALIDATE_YOUR_EMAIL_ADDRESS", "Validate Your Email Address")
        ]
        // if (!query.promo) {
        //     stepLabels.push(strings.getString("CHOOSE_A_GAME_PACK", "Choose a Game Pack"), strings.getString("CHECKOUT", "Checkout"));
        // }
        stepLabels.push(strings.getString("PLAY_THE_GAME", "Play the Game"));

        return (
            <Page>
                <div className="Registration">
                    <div className="Registration__content">
                        <Stepper activeStep={currStep} stepLabels={stepLabels}>
                            {currStep === 1 && <RegistrationPage />}
                            {currStep === 2 && <ValidateEmailPage />}
                            {/*{currStep === 3 && <ChooseAPackPage />}*/}
                            {/*{currStep === 4 && <RegistrationCheckoutPage />}*/}
                            {(currStep === 5 || (query.promo && query.download)) && <DownloadPackPage />}
                        </Stepper>
                    </div>
                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps)(Registration)
