import React from 'react'
import SocialLink from '../../../componentLibrary/Elements/SocialLink'
import RegionBlock from '../../../componentLibrary/Models/RegionBlock'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import contentful from '../../../utilities/contentful'
import assets from '../../../assets.js'
import './styles.scss'
import strings from '../../../strings'
import LanguageSelector from './LanguageSelector'


class Footer extends React.Component {
  state = {
    mntlLogo: {},
  }

  async componentDidMount() {
    const mntlLogo = await contentful.getImage(assets.staticImageIds.MNTL_LOGO_REVERSE)
    this.setState({ mntlLogo: mntlLogo })
  }

  render() {
    return (
      <div className="Footer">

        <LanguageSelector />

        <RegionBlock artcraft>
          <div className="Footer__copywrite">© 2022 Monumental, LLC</div>

          <div className="Footer__logos">
            <div className="Footer__logo Footer__logo--artcraft">
              <a href="https://monumental.io/" target="_blank" rel="noopener noreferrer"><img src={this.state.mntlLogo.assetUrl} alt={this.state.mntlLogo.title} /></a>
            </div>
          </div>

          <div className="Footer__links">
            <LocLink to="/monumental/fair-use-policy/">
              <div className="Footer__link">{strings.getString('FAIR_USE_POLICY', 'Fair Use Policy')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/monumental/privacy-policy/">
              <div className="Footer__link">{strings.getString('PRIVACY_POLICY', 'Privacy Policy')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/monumental/rules-of-conduct/">
              <div className="Footer__link">{strings.getString('RULES_OF_CONDUCT', 'Rules of Conduct')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/monumental/shipping-policy/">
              <div className="Footer__link">{strings.getString('SHIPPING_POLICY', 'Shipping Policy')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/monumental/terms-and-conditions/">
              <div className="Footer__link">{strings.getString('TERMS_AND_CONDITIONS', 'Terms and Conditions')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/monumental/contact-corporate/">
              <div className="Footer__link">{strings.getString('CONTACT_CORPORATE', 'Contact/Corporate')}</div>
            </LocLink>

            {/*<div className="Footer__spacer">|</div>*/}

            {/*<LocLink to="/support">*/}
            {/*  <div className="Footer__link">{strings.getString('CUSTOMER_SUPPORT', 'Customer Support')}</div>*/}
            {/*</LocLink>*/}
          </div>
        </RegionBlock>

        <RegionBlock eu>
          <div className="Footer__copywrite">© 2022 Monumental, LLC</div>

          <div className="Footer__logos">
            <div className="Footer__logo Footer__logo--artcraft">
              <a href="https://monumental.io/" target="_blank" rel="noopener noreferrer"><img src={this.state.mntlLogo.assetUrl} alt={this.state.mntlLogo.title} /></a>
            </div>
          </div>

          <div className="Footer__links">
            <LocLink to="/crowfall-eu/fair-use-policy/">
              <div className="Footer__link">{strings.getString('FAIR_USE_POLICY', 'Fair Use Policy')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/crowfall-eu/privacy-policy/">
              <div className="Footer__link">{strings.getString('PRIVACY_POLICY', 'Privacy Policy')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/crowfall-eu/rules-of-conduct/">
              <div className="Footer__link">{strings.getString('RULES_OF_CONDUCT', 'Rules of Conduct')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/monumental/shipping-policy/">
              <div className="Footer__link">{strings.getString('SHIPPING_POLICY', 'Shipping Policy')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/crowfall-eu/terms-and-conditions/">
              <div className="Footer__link">{strings.getString('TERMS_AND_CONDITIONS', 'Terms and Conditions')}</div>
            </LocLink>

            <div className="Footer__spacer">|</div>

            <LocLink to="/crowfall-eu/imprint/">
              <div className="Footer__link">{strings.getString('IMPRINT', 'Imprint')}</div>
            </LocLink>

            {/*<div className="Footer__spacer">|</div>*/}
            
            {/*<LocLink to="/support">*/}
            {/*  <div className="Footer__link">{strings.getString('CUSTOMER_SUPPORT', 'Customer Support')}</div>*/}
            {/*</LocLink>*/}
          </div>
        </RegionBlock>

        <div className="Footer__social">
          <div className="Footer__socialLink"><SocialLink discord/></div>
          <div className="Footer__socialLink"><SocialLink facebook/></div>
          <div className="Footer__socialLink"><SocialLink twitter/></div>
          <div className="Footer__socialLink"><SocialLink instagram/></div>
          <div className="Footer__socialLink"><SocialLink youtube/></div>
          <div className="Footer__socialLink"><SocialLink twitch/></div>
        </div>

      </div>
    )
  }
}

export default Footer
