import React from 'react'
import {removeAuth} from '../../utilities/api'
import { connect } from 'react-redux'
import { removeSession} from '../../redux/actions'
import { clearGuildDetails, setHasGuild } from '../../redux/actions/guild-management'
import { clearUser } from '../../redux/actions/user'
import { clearSubscriptionDetails } from '../../redux/actions/subscription'
import {Redirect, withRouter} from 'react-router-dom'

const mapStateToProps = state => {
  return {
    language: state.language,
    user: state.user.userProfile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeSession: () => dispatch(removeSession()),
    clearGuildDetails: () => dispatch(clearGuildDetails()),
    setHasGuild: (status) => dispatch(setHasGuild(status)),
    clearUser: () => dispatch(clearUser()),
    clearSubscriptionDetails: () => dispatch(clearSubscriptionDetails())
  }
}

class Logout extends React.Component {

  componentDidMount = () => {
    localStorage.removeItem('userData')
    removeAuth()
    this.props.removeSession()
    this.props.clearGuildDetails()
    this.props.clearUser()
    this.props.setHasGuild(false)
    this.props.clearSubscriptionDetails()
    this.props.history.push('/')
  }

  render() {
    return (
      <Redirect to={`/${this.props.language}`}/>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout))
