import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import FieldDropdown from '../FieldDropdown'
import Button from '../../Fragments/Button'
import { clearModalContent } from '../../../redux/actions'
import { fetchAllSubscriptionDefinitions, updateUsersSubscription, fetchVIPSubscriptionDetails } from '../../../redux/actions/subscription'
import './styles.scss'


const mapStateToProps = state => {
    return {
        subscriptionDetails: state.subscription.subscriptionDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearModalContent: () => dispatch(clearModalContent()),
        fetchAllSubscriptionDefinitions: () => dispatch(fetchAllSubscriptionDefinitions()),
        updateUsersSubscription: (newSubscriptionType) => dispatch(updateUsersSubscription(newSubscriptionType)),
        fetchVIPSubscriptionDetails: () => dispatch(fetchVIPSubscriptionDetails()),
    }
}

class UpdateSubscriptionTypeModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedType: null,
            subscriptionOptions: [],
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
        const { fetchAllSubscriptionDefinitions, subscriptionDetails } = this.props
        const definitions = await fetchAllSubscriptionDefinitions()
        const currentSubscriptionId = _.result(subscriptionDetails, "subscription.subscription_definition.item_id")
        const subscriptionOptions = _.orderBy(definitions.map(def => {
            const { name, itemId: id, duration } = def
            return { label: name, value: id, selected: id === currentSubscriptionId, duration }
        }), ["duration"], ["desc"])
        this.setState({ selectedType: currentSubscriptionId, subscriptionOptions })
    }

    handleFieldChange(value, name) {
        this.setState({ [name]: value })
    }

    async handleSubmit() {
        try {
            await this.props.updateUsersSubscription(this.state.selectedType)
            swal({
                icon: "success",
                title: strings.getString("SUCCESSFULLY_UPDATED_MEMBERSHIP", "Membership Successfully Updated")
            })
            await this.props.fetchVIPSubscriptionDetails()
            this.props.clearModalContent()
        } catch (err) {
            swal({
                icon: "error",
                title: strings.getString("UH_OH", "Uh Oh!"),
                content: (<div>{strings.getString("ERROR_GENERIC_MESSAGE", "There was an error processing your request. Please try again.")}</div>),
            })
        }
    }

    render() {
        const { subscriptionDetails, clearModalContent } = this.props
        const { subscriptionOptions } = this.state
        const currType = _.result(subscriptionDetails, "subscription.subscription_definition.name")

        return (
            <div className="UpdateSubscriptionTypeModal">
                <div className="UpdateSubscriptionTypeModal__header">{strings.getString("UPDATE_MEMBERSHIP", "Update Membership")}</div>
                <p>{strings.getString("WARNING_UPDATE_TO_MEMBERSHIP_TYPE", "Please note that changes to your membership type will not come into effect until after your next membership billing date.")}</p>
                <p>{strings.getString("CURRENT_MEMBERSHIP_TYPE", `Current membership type: ${currType}`, { type: currType })}</p>
                <div className="UpdateSubscriptionTypeModal__field">
                    <FieldDropdown
                        label={strings.getString("MEMBERSHIP_TYPE", "Membership Type")} name="selectedType"
                        selectOne={this.handleFieldChange} options={subscriptionOptions} removeDeselect
                    />
                </div>
                <div className="UpdateSubscriptionTypeModal__buttons">
                    <div className="UpdateSubscriptionTypeModal__button"><Button onclick={clearModalContent} gray medium label={strings.getString("CANCEL", "Cancel")} /></div>
                    <div className="UpdateSubscriptionTypeModal__button"><Button onclick={this.handleSubmit} medium label={strings.getString("UPDATE_MEMBERSHIP", "Update Membership")} /></div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSubscriptionTypeModal)