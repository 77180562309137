import React from 'react'
import ProductCard from '../ProductCard'
import strings from '../../../strings'
import './styles.scss'


const ProductGrid = (props) => {

    const { products, hideLtoCapsule, hideBestValueCapsule, hideOnSaleCapsule, useRegisterRedirects, noItemsFoundString } = props

    return (
        <div className="ProductGrid">
            {products && products.length > 0 ? products.map((product, index) => {
                return (
                    <div className="ProductGrid__item" key={`product-grid-item-${product.item_id}-${index}`}>
                        <ProductCard 
                            product={product} 
                            hideLtoCapsule={hideLtoCapsule} 
                            hideBestValueCapsule={hideBestValueCapsule} 
                            hideOnSaleCapsule={hideOnSaleCapsule}
                            useRegisterRedirects={useRegisterRedirects}
                            noItemsFoundString={noItemsFoundString}
                        />
                    </div>
                )
            }) : (
                <div className="ProductGrid__emptyText">
                    {!noItemsFoundString && strings.getString("STORE_NO_ITEMS_FOUND", "No items found. Please adjust your search criteria to view available products.")}
                    {noItemsFoundString && noItemsFoundString}
                </div>
            )
            }
        </div>
    )
}

export default ProductGrid