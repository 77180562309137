import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { api } from '../utilities/api'
import _ from 'lodash'

import { fetchRegions, setAvailableLanguages, setCookieNotice, fetchLanguages } from '../redux/actions'

import PrivateRoute from '../componentLibrary/Elements/PrivateRoute'

import SiteFrame from './SiteFrame'
import CookieNotice from './CookieNotice'
import HomePage from '../Site/HomePage'
import ValidateEmail from '../Account/ValidateEmail'
import AccountDetails from '../Account'
import Checkout from '../Store/checkout/index.js'
import ShopPage from '../Store/ShopPage'
import ClassesPage from '../Site/GameContent/ClassesPage'
import RacesPage from '../Site/GameContent/RacesPage'
import BestiaryPage from '../Site/GameContent/BestiaryPage'
import PantheonPage from '../Site/GameContent/PantheonPage'
import FaqPage from '../Site/GameContent/FaqPage'
import FaqQuestionPage from '../Site/GameContent/FaqQuestionPage'
import HowToPlayPage from '../Site/GameContent/HowToPlayPage'
import TroubleshootingPage from '../Site/GameContent/TroubleshootingPage'
import AboutTeamPage from '../Site/About/AboutTeamPage'
import PartnersPage from '../Site/About/PartnersPage'
import PressPage from '../Site/About/PressPage'
import JobsPage from '../Site/About/JobsPage'
import MediaPage from '../Site/Media'
import ScreensPage from '../Site/Media/ScreensPage'
import ConceptArtPage from '../Site/Media/ConceptArtPage'
import VideosPage from '../Site/Media/VideosPage'
import InfluencersPage from '../Site/Community/InfluencersPage'
import ClassDetailPage from '../Site/GameContent/ClassDetailPage'
import RaceDetailPage from '../Site/GameContent/RaceDetailPage'
import PantheonDetailPage from '../Site/GameContent/PantheonDetailPage'
import BestiaryDetailPage from '../Site/GameContent/BestiaryDetailPage'

import ECSLandingPage from '../Site/EternalChampions/ECSLandingPage'
import ECSEventsPage from '../Site/EternalChampions/ECSEventsPage'
import ECSTeamsPage from '../Site/EternalChampions/ECSTeamsPage'
import ECSSponsorsPage from '../Site/EternalChampions/ECSSponsorsPage'
import ECSContentPage from '../Site/EternalChampions/ECSContentPage'
import WarStoriesPage from '../Site/Community/WarStoriesPage'
import WarStoryPage from '../Site/Community/WarStoryPage'
import BlogsPage from '../Site/Community/BlogsPage'
import BlogPage from '../Site/Community/BlogPage'
import GatesOfGallooPage from '../Site/Marketing/GatesOfGallooPage'
import ArticlesPage from '../Site/News/ArticlesPage'
import ArticlePage from '../Site/News/ArticlePage'
import CampaignsPage from '../Site/Marketing/CampaignsPage'
import DecapathonPage from '../Site/Marketing/DecapathonPage'
import CrowmasPage from '../Site/Marketing/CrowmasPage'
import TrialsPage from '../Site/Marketing/TrialsPage'
import PromotionsPage from '../Site/Marketing/PromotionsPage'
import PromotionPage from '../Site/Marketing/PromotionPage'
import VipPage from '../Site/Marketing/VipPage'

import Login from '../Site/Registration/Login'
import Logout from '../Site/Registration/Logout'
import RazerLander from '../Site/Registration/MarketingLanders/RazerLander'
import IntelLander from '../Site/Registration/MarketingLanders/IntelLander'
import TwitchLander from '../Site/Registration/MarketingLanders/TwitchLander'
import AlienwareLander from '../Site/Registration/MarketingLanders/AlienwareLander'
import CrowCodesLander from '../Site/Registration/MarketingLanders/CrowCodesLander'
import Registration from '../Site/Registration'
import ConfirmPasswordReset from '../Account/ConfirmPasswordReset'
import PossessAccount from '../Account/PossessAccount'
import FairUsePage from '../Site/Legal/monumental/FairUsePage'
import PrivacyPolicy from '../Site/Legal/monumental/PrivacyPolicyPage'
import RulesOfConduct from '../Site/Legal/monumental/RulesOfConductPage'
import RulesOfConduct21 from '../Site/Legal/artcraft/RulesOfConduct-2-1'
import RulesOfConduct20 from '../Site/Legal/artcraft/RulesOfConduct-2-0'
import ShippingPolicy from '../Site/Legal/monumental/ShippingPolicyPage'
import TermsAndConditions from '../Site/Legal/monumental/TermsAndConidtionsPage'
import TermsAndConditions16 from '../Site/Legal/artcraft/TermsAndConditions-1-6'
import TermsAndConditions15 from '../Site/Legal/artcraft/TermsAndConditions-1-5'
import TermsAndConditions14 from '../Site/Legal/artcraft/TermsAndConditions-1-4'
import TermsAndConditions13 from '../Site/Legal/artcraft/TermsAndConditions-1-3'
import TermsAndConditions12 from '../Site/Legal/artcraft/TermsAndConditions-1-2'
import TermsAndConditions11 from '../Site/Legal/artcraft/TermsAndConditions-1-1'
import TermsAndConditions10 from '../Site/Legal/artcraft/TermsAndConditions-1-0'
import DataTransferPage from '../Site/Legal/monumental/DataTransferPage'
import SupportPage from '../Site/Support'
import ValidateEmailChange from '../Account/ValidateEmailChange'
import EuFairUsePage from '../Site/Legal/eu/EuFairUsePage'
import EuDataTransferPage from '../Site/Legal/eu/EuDataTransferPage'
import EuPrivacyPolicy from '../Site/Legal/eu/EuPrivacyPolicyPage'
import EuRulesOfConduct from '../Site/Legal/eu/EuRulesOfConductPage'
import EuImprintPolicy from '../Site/Legal/eu/EuImprintPolicyPage'
import EuTermsAndConditions from '../Site/Legal/eu/EuTermsAndConidtionsPage'
import CorporatePage from '../Site/About/CorporatePage'
import Combinations from '../Site/GameContent/CombinationsPage'
import AcceptTermsPage from './AcceptTermsPage'
import SetupContext from './SetupContext'
import InnovaRedirect from './InnovaRedirect'
import Error404Page from './Error404Page'
import OAuthVerify from '../Account/OAuthVerify'
import StringTranslations from '../utilities/StringTranslations'
import EuRightOfWithdrawal from '../Site/Legal/eu/EuRightOfWithdrawal'
import CheckoutReceipt from '../Store/checkout/CheckoutReceipt'


import GuildPage from '../Guilds'
import SearchGuildsPage from '../Guilds/SearchGuildsPage'

import './styles.scss'


const mapStateToProps = state => {
  return {
    cookieNotice: state.cookieNotice,
    session: state.session,
    acceptedRegionalAgreements: state.acceptedRegionalAgreements,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchLanguages: () => dispatch(fetchLanguages()),
    setAvailableLanguages: (languages) => dispatch(setAvailableLanguages(languages)),
    fetchRegions: () => dispatch(fetchRegions()),
    setCookieNotice: (cookieNotice) => dispatch(setCookieNotice(cookieNotice)),
  }
}

class SiteContainer extends React.Component {

  componentDidMount() {
    this.props.fetchLanguages()

    api.get('/public/language').then(res => {
      this.props.setAvailableLanguages(res.data)
    })

    if (_.isNil(this.props.session)) {
      this.props.fetchRegions()
    }
  }

  render() {

    const { session, acceptedRegionalAgreements, location } = this.props
    const path = location.pathname.toLowerCase()
    const isMissingRequiredAgreements = !_.isEmpty(acceptedRegionalAgreements.filter( agreement => agreement.required_if_present && agreement.accepted !== true ))
    const requiresAgreements = !!session && isMissingRequiredAgreements && !path.includes("/accept-terms") && !path.includes("/logout") && !path.includes("/terms-and-conditions")

    return (
      <div className='MainWindow'>
        <SiteFrame>
          <Switch>
            <Redirect from='/:lang/account/(tab-registration)' to='/:lang/register'/>
            {requiresAgreements && <Redirect to="/accept-terms"/>}
            <PrivateRoute exact path='/:lang/accept-terms' component={AcceptTermsPage}/>

            <Route exact path='/:lang/news/articles' component={ArticlesPage}/>
            <Route exact path='/:lang/news/articles/:article' component={ArticlePage}/>

            <Route exact path='/:lang/classes' component={ClassesPage}/>
            <Route exact path='/:lang/classes/:className' component={ClassDetailPage}/>

            <Route exact path='/:lang/races' component={RacesPage}/>
            <Route exact path='/:lang/races/:raceName' component={RaceDetailPage}/>
            <Route exact path='/:lang/combinations' component={Combinations}/>

            <Route exact path='/:lang/bestiary' component={BestiaryPage}/>
            <Route exact path='/:lang/bestiary/:bestiaryName' component={BestiaryDetailPage}/>

            <Route exact path='/:lang/pantheon' component={PantheonPage}/>
            <Route exact path='/:lang/pantheon/:pantheonName' component={PantheonDetailPage}/>

            <Route exact path='/:lang/warstories' component={WarStoriesPage}/>
            <Route exact path='/:lang/warstories/:uri' component={WarStoryPage}/>

            <Route exact path='/:lang/blog' component={BlogsPage}/>
            <Route exact path='/:lang/blog/:uri' component={BlogPage}/>

            <Route exact path='/:lang/eternal-champions' component={ECSLandingPage}/>
            <Route exact path='/:lang/eternal-champions/event-dates' component={ECSEventsPage}/>
            <Route exact path='/:lang/eternal-champions/brackets' component={ECSTeamsPage}/>
            <Route exact path='/:lang/eternal-champions/sponsors' component={ECSSponsorsPage}/>
            <Route exact path='/:lang/eternal-champions/:slug' component={ECSContentPage}/>

            <Route exact path='/:lang/gatesofgalloo' component={GatesOfGallooPage}/>
            <Route exact path='/:lang/campaigns' component={CampaignsPage}/>
            <Route exact path='/:lang/decapathon' component={DecapathonPage}/>
            {/*<Route exact path='/:lang/trials' component={TrialsPage}/>*/}
            <Route exact path='/:lang/vip' component={VipPage}/>
            {/*<Route exact path='/:lang/crowcodes' component={CrowCodesLander}/>*/}

            <Route exact path='/:lang/promotions' component={PromotionsPage}/>
            <Route exact path='/:lang/promotions/crowmas' component={CrowmasPage}/>
            <Route exact path='/:lang/crowmas' component={CrowmasPage}/>
            <Route exact path='/:lang/promotions/:uri' component={PromotionPage}/>

            {/*<Route exact path='/:lang/faq' component={FaqPage}/>*/}
            {/*<Route exact path='/:lang/faq/:categoryId/:questionId' component={FaqQuestionPage}/>*/}
            {/*<Route exact path='/:lang/how-to-play' component={HowToPlayPage}/>*/}
            {/*<Route exact path='/:lang/troubleshooting' component={TroubleshootingPage}/>*/}

            {/*<Route exact path='/:lang/team' component={AboutTeamPage}/>*/}
            {/*<Route exact path='/:lang/partners' component={PartnersPage}/>*/}
            <Route exact path='/:lang/press' component={PressPage}/>
            {/* <Route exact path='/:lang/jobs' component={JobsPage}/> */}

            <Route exact path='/:lang/media' component={MediaPage}/>
            <Route exact path='/:lang/media/screens' component={ScreensPage}/>
            <Route exact path='/:lang/media/screens/:media' component={ScreensPage}/>
            <Route exact path='/:lang/media/conceptarts' component={ConceptArtPage}/>
            <Route exact path='/:lang/media/conceptarts/:media' component={ConceptArtPage}/>
            <Route exact path='/:lang/media/videos' component={VideosPage}/>
            <Route exact path='/:lang/media/videos/:media' component={VideosPage}/>

            {/*<PrivateRoute exact path='/:lang/influencer' component={InfluencersPage}/>*/}

            {/*<Route path='/:lang/store' component={ShopPage}/>*/}

            <Route exact path='/:lang/email-validation' component={ValidateEmail}/>
            <Route exact path='/:lang/validate-email-change' component={ValidateEmailChange}/>

            <Route exact path='/:lang/possess-account' component={PossessAccount}/>
            <Route exact path='/:lang/reset-password' component={ConfirmPasswordReset}/>
            {/*<Route exact path='/:lang/support' component={SupportPage}/>*/}

            <PrivateRoute exact path='/:lang/checkout/:id' component={Checkout}/>
            <PrivateRoute exact path='/:lang/checkout-cart' component={Checkout}/>
            <PrivateRoute exact path='/:lang/checkout-receipt/:id' component={CheckoutReceipt}/>

            <PrivateRoute path='/:lang/account' component={AccountDetails}/>
            <Route exact path='/:lang/login' component={Login}/>
            <Route exact path='/:lang/logout' component={Logout}/>
            <PrivateRoute exact path='/:lang/link-external' component={OAuthVerify}/>

            <Route exact path='/:lang/monumental/contact-corporate' component={CorporatePage}/>


            <Route exact path='/:lang/monumental/fair-use-policy/' component={FairUsePage}/>
            <Route exact path='/:lang/monumental/data-sharing/' component={DataTransferPage}/>
            <Route exact path='/:lang/monumental/privacy-policy/' component={PrivacyPolicy}/>
            <Route exact path='/:lang/monumental/rules-of-conduct/' component={RulesOfConduct}/>
            <Route exact path='/:lang/artcraft/rules-of-conduct-2-1' component={RulesOfConduct21}/>
            <Route exact path='/:lang/artcraft/rules-of-conduct-2-0' component={RulesOfConduct20}/>
            <Route exact path='/:lang/monumental/shipping-policy/' component={ShippingPolicy}/>
            <Route exact path='/:lang/monumental/terms-and-conditions/' component={TermsAndConditions}/>
            <Route exact path='/:lang/artcraft/terms-and-conditions-1-6/' component={TermsAndConditions16}/>
            <Route exact path='/:lang/artcraft/terms-and-conditions-1-5/' component={TermsAndConditions15}/>
            <Route exact path='/:lang/artcraft/terms-and-conditions-1-4/' component={TermsAndConditions14}/>
            <Route exact path='/:lang/artcraft/terms-and-conditions-1-3/' component={TermsAndConditions13}/>
            <Route exact path='/:lang/artcraft/terms-and-conditions-1-2/' component={TermsAndConditions12}/>
            <Route exact path='/:lang/artcraft/terms-and-conditions-1-1/' component={TermsAndConditions11}/>
            <Route exact path='/:lang/artcraft/terms-and-conditions-1-0/' component={TermsAndConditions10}/>

            <Route exact path='/:lang/crowfall-eu/fair-use-policy/' component={EuFairUsePage}/>
            <Route exact path='/:lang/crowfall-eu/data-sharing/' component={EuDataTransferPage}/>
            <Route exact path='/:lang/crowfall-eu/privacy-policy/' component={EuPrivacyPolicy}/>
            <Route exact path='/:lang/crowfall-eu/rules-of-conduct/' component={EuRulesOfConduct}/>
            <Route exact path='/:lang/crowfall-eu/imprint/' component={EuImprintPolicy}/>
            <Route exact path='/:lang/crowfall-eu/terms-and-conditions/' component={EuTermsAndConditions}/>
            <Route exact path='/:lang/crowfall-eu/right-of-withdrawal/' component={EuRightOfWithdrawal}/>

            {/*<Route exact path='/:lang/register' component={Registration}/>*/}
            {/*<Route exact path='/:lang/twitch' component={TwitchLander}/>*/}
            <Route exact path='/:lang/eternal-heroes-wanted' component={IntelLander}/>
            <Route exact path='/:lang/beta-key-giveaway' component={RazerLander}/>
            <Route exact path='/:lang/join-the-battle' component={AlienwareLander}/>

            {/*<Route path='/:lang/guild' component={GuildPage}/>*/}
            {/*<Route exact path='/:lang/guilds/search' component={SearchGuildsPage}/>*/}

            <Route exact path='/:lang/utils/string-translations' component={StringTranslations} />

            <Route exact path="/:lang/cepack" component={() => {
              window.location.href = "https://cepack.crowfall.com"
              return null
            }}/>

            <Route exact path='/:lang' component={HomePage}/>

            <Route path="*" component={Error404Page}/>

          </Switch>
        </SiteFrame>
      </div>
    )
  }
}

class HomepageLayout extends React.Component {
  handleCookieNoticeDismiss = () => {
    localStorage.setItem('cookieNotice', JSON.stringify({accepted: true}))
    this.props.setCookieNotice({ accepted: true })
  }

  render() {
    const { cookieNotice, ...rest } = this.props
    return (
      <div>

        <Helmet>
          <meta charSet="utf-8"/>
          <title>Crowfall - Throne War PC MMO by Monumental, LLC</title>
          <meta name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
          <meta name="description"
                content="Get Early Access today to build large kingdoms and conquer unique worlds. Master crafting, trading and strategy in a new breed of MMO."/>
          <meta name="keywords" content="crowfall throne war mmo, Monumental - Kick-Starter Game, Guild versus Guild (GvG) / Realm versus Realm (RvR), Player versus Player - Competitive Gaming, Campaign Worlds and Siege Strategy, Open World PvP MMORPG, Crafting and Merchant Based-Economy"/>
          <link rel="canonical" href="http://mysite.com/example"/>
          <meta property="og:title" content="Throne War PC MMO by Monumental, LLC - Crowfall"/>
          <meta property="og:description"
                content="Get Early Access today to build large kingdoms and conquer unique worlds. Master crafting, trading and strategy in a new breed of MMO."/>
          <meta property="og:image" content="/public/images/meta_img.jpg"/>
          <meta property="og:keywords" content="crowfall throne war mmo"/>
          <meta property="og:url" content="https://crowfall.com"/>
        </Helmet>
        <SiteContainer {...rest}/>
        <SetupContext/>
        <InnovaRedirect/>
        {!cookieNotice || !cookieNotice.accepted ? (
          <CookieNotice onAccept={this.handleCookieNoticeDismiss}/>
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomepageLayout)
