import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import strings from '../../../../strings'
import Capsule from '../../../../componentLibrary/Fragments/Capsule'
import GiftToButton from '../GiftToButton'
import BuyNowButton from '../BuyNowButton'
import RecurringPurchaseButton from '../RecurringPurchaseButton'
import UpgradeButton from '../UpgradeButton'
import AddToCartButton from '../AddToCartButton'
import GuildBuyNowButton from '../GuildBuyNowButton'
import { clearModalContent } from '../../../../redux/actions'
import './styles.scss'
import InstantBuyNowButton from "../InstantBuyNowButton";


const mapDispatchToProps = dispatch => {
    return {
        clearModalContent: () => dispatch(clearModalContent()),
    }
}


class ProductModal extends React.Component {
    render() {
        const { 
            product, heroImage, name, baseAmt, purchaseAmt, displayStrings, limitedTime, bestValue, salePercent, description, 
            isGiftable, isPurchasable, isUpgradeable, isEligibleForCart, isEligibleForGuildPurchase, clearModalContent, isRecurringPurchase
        } = this.props

        return (
            <div className="ProductModal">
                {(limitedTime || bestValue || salePercent > 0) && <div className="ProductModal__capsules">
                    {limitedTime && <div className="ProductModal__capsule"><Capsule orange label={strings.getString("LIMITED_TIME_OFFER", "Limited Time Offer")}/></div>}    
                    {bestValue && <div className="ProductModal__capsule"><Capsule red label={strings.getString("BEST_VALUE", "Best Value")}/></div>}
                    {salePercent > 0 && <div className="ProductModal__capsule"><Capsule yellow label={strings.getString("ON_SALE", "On Sale")}/></div>}
                </div>}
                <div className="ProductModal__img" style={{ backgroundImage: `url(${heroImage})` }}/>
                
                <div className="ProductModal__body">
                    <div className="ProductModal__itemContent">
                        <div className="ProductModal__name">{name}</div>
                        <div className="ProductModal__price">
                            {baseAmt !== purchaseAmt &&
                                <span className="ProductModal__basePrice">{_.result(displayStrings, "base_amount")}</span>
                            }
                            {_.result(displayStrings, "purchase_amount")}*
                        </div>

                        <div className="ProductModal__scroll" dangerouslySetInnerHTML={{ __html: description }}/>
                    </div>

                    <div className="ProductModal__buttons">
                        {isGiftable && <div className="ProductModal__button" onClick={clearModalContent}><GiftToButton item={product} medium/></div>}
                        {isPurchasable && <div className="ProductModal__button" onClick={clearModalContent}><InstantBuyNowButton item={product} medium/></div>}
                        {isRecurringPurchase && <div className="ProductModal__button" onClick={clearModalContent}><RecurringPurchaseButton item={product} medium/></div>}
                        {isUpgradeable && <div className="ProductModal__button" onClick={clearModalContent}><UpgradeButton item={product} medium/></div>}
                        {isEligibleForGuildPurchase && <div className="ProductModal__button" onClick={clearModalContent}><GuildBuyNowButton item={product} medium/></div>}
                        {/*{isEligibleForCart && <div className="ProductModal__button" onClick={clearModalContent}><AddToCartButton item={product} medium/></div>}*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(ProductModal)