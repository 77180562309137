import React from 'react'
import { api } from '../../utilities/api'
import { Button, Checkbox, Dimmer, Form, Header, Loader, Select } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../strings'
import { clearModalContent, setModalContent, fetchAcceptedRegionalAgreements } from '../../redux/actions'
import {updateEmail, requestStepUpCode, validateStepUpCode, clearStepUpCodes} from '../../redux/actions/user'
import ChangeEmailModal from './ChangeEmailModal'


const mapStateToProps = state => {
  return {
    availableLanguages: state.availableLanguages,
    user: state.user.userProfile,
    accountAgreements: state.acceptedRegionalAgreements
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearModalContent: () => dispatch(clearModalContent()),
    setModalContent: (content) => dispatch(setModalContent(content)),
    fetchAcceptedAccountAgreements: () => dispatch(fetchAcceptedRegionalAgreements()),
    updateEmail: (emailParameters) => dispatch(updateEmail(emailParameters)),
    requestStepUpCode: (streamtype) => dispatch(requestStepUpCode(streamtype)),
    validateStepUpCode: (code) => dispatch(validateStepUpCode(code)),
    clearStepUpCodes: () => dispatch(clearStepUpCodes())
  }
}

class AccountProfilePage extends React.Component {

constructor(props) {
  super(props)    
  this.state = {
    loading: false,
    agreementList: [],
    languageOptions: [],
    email: '',
    password: '',
    otp: '',
    user: {
      username: '',
      first_name: '',
      last_name: '',
      preferred_language: '',
      email: ''
    },
    emailFormFocused: false,
    stepUpCode: '',
  }
  this.handleEmailChange = this.handleEmailChange.bind(this)
  this.clearEmailModal = this.clearEmailModal.bind(this)
  this.submitEmailChange = this.submitEmailChange.bind(this)
}

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    const userRes = await api.get(`/user`)

    let { username, first_name, last_name, preferred_language, email, registration_geo } = userRes.data
    this.setState({ user: { username, first_name, last_name, preferred_language, email, registration_geo } })

    const languageOptions = this.props.availableLanguages.map(l => ({
      key: l.culture_code,
      value: l.culture_code,
      text: l.display_name,
    }))

    this.setState({
      languageOptions: languageOptions
    })
  }

  handleUserChange = (e, { name, value } = {}) => {
    this.setState({ user: _.merge(this.state.user, { [name]: value }) })
  }

  saveUser = async () => {
    this.setState({ loading: true })
    try {
      await api.put(`/user`, this.state.user)
      swal(`Successfully Updated Account Profile`, {
        icon: 'success',
      })
    } catch {
      swal(`Uh Oh! Unable to Update Account Profile`, {
        icon: 'error',
      })
    }

    await this.getContent()
    this.setState({ loading: false })
  }

  handleAgreementChange = async (event) => {
    this.setState({ loading: true })
    const accepted = event.target.checked
    const externalId = event.target.value
    await api.post(`/user/agreement/${externalId}/${accepted ? 'accept' : 'decline'}`)
    await this.props.fetchAcceptedAccountAgreements()
    this.setState({ loading: false })
  }

  handleChange = (e, { name, value } = {}) => {
    this.setState({
      [name]: value
    })
  }

  handleEmailChange() {
    const has2FA = this.props.user.has_otp
    if (!has2FA) {
      this.getStepUpCode()
    }
    this.props.setModalContent(<ChangeEmailModal updateField={this.handleChange} has2FA={has2FA}
                                                 cancel={this.clearEmailModal} submit={this.submitEmailChange}/>)
  }
  
  async getStepUpCode() {
    try {
      await this.props.requestStepUpCode('old_email_verify_required')
    } catch (err) {
      console.log("Could not generate step up code with: ", err)
    }
  }

  clearEmailModal() {
    this.setState({ email: '', password: '', otp: '' })
    this.props.clearModalContent()
  }

  async submitEmailChange() {
    const { email, password, otp, stepUpCode } = this.state
    const has2FA = this.props.user.has_otp
    
    //Verify StepUp if exists:
    if (!has2FA) {
      try {
        await this.props.validateStepUpCode(stepUpCode)
      } catch (err) {
        if (err) {
          swal(`Uh Oh! Your step up code was incorrect.  Please check your old email address for the valid step up code.`, {
            icon: 'error',
          })
        }
        this.setState({ loading: false })
        //this.clearEmailModal()
        //Added return to break from updating email
        return;
      }
    }
    
    //Update Email
    try {
      await this.props.updateEmail({
        new_email: email,
        password,
        totp: _.isEmpty(otp) ? null : otp
      })
      await this.props.clearStepUpCodes();
      
      swal(`Successfully requested Email Change.  Please check your email for next steps.`, {
        icon: 'success',
      })
      this.clearEmailModal()
      await this.getContent()

    } catch (err) {
      if (_.result(err, 'response.data.message') === 'password did not match') {
        swal(`Uh Oh! Your password was incorrect.  Please correct your password and try again.`, {
          icon: 'error',
        })
      } else {
        swal(`Uh Oh! Unable to request Email Change.`, {
          icon: 'error',
        })
      }
    }
  }
  

  render() {

    const { languageOptions, loading, user } = this.state
    const registrationCountry = _.result(user, 'registration_geo.country_name', 'Unknown')

    const agreementList = this.props.accountAgreements.map(a=> {
      return {
        requiredIfPresent: a.required_if_present,
        label: _.result(a, `display_strings.acceptance_string`, a.agreement_definition_name) || `I accept the ${a.name} agreement. `,
        accepted: a.accepted,
        externalId: a.external_id,
        immutable: a.immutable === true,
        disabled: a.immutable && a.accepted
      }
    });

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('ACCOUNT_PROFILE', 'Account Profile')}</h1>

        <Form onSubmit={this.saveUser.bind(this)}>
          <Form.Field>
            <label>{strings.getString("DISPLAY_NAME", "Display Name")}</label>
            <input type="text" disabled={true} value={this.state.user.username || ''} style={{opacity: 1, color: 'rgb(196, 196, 196)'}}/>
          </Form.Field>

          <Form.Field>
            <Form.Input action autoComplete="off" type="text" label={strings.getString('EMAIL_ADDRESS', 'Email Address')} value={this.state.user.email || ''}>
              <input disabled={true} style={{opacity: 1, color: 'rgb(196, 196, 196)'}}/>
              <Button type='button' onClick={this.handleEmailChange}>{strings.getString('CHANGE_EMAIL', 'Change Email')}</Button>
            </Form.Input>
          </Form.Field>

          <Form.Field>
            <Form.Input autoComplete="off" type="text"
                        label={strings.getString('FIRST_NAME', 'First Name')}
                        name='first_name' value={this.state.user.first_name || ''}
                        onChange={this.handleUserChange.bind(this)}/>
          </Form.Field>
          <Form.Field>
            <Form.Input autoComplete="off" type="text"
                        label={strings.getString('LAST_NAME', 'Last Name')}
                        name='last_name' value={this.state.user.last_name || ''}
                        onChange={this.handleUserChange.bind(this)}/>
          </Form.Field>
          <Form.Field>
            <label>{strings.getString('COMMUNICATION_LANGUAGE_PREFERENCE', 'Communication Language Preference')}</label>
            <Select placeholder={strings.getString('LANGUAGE_PREFERENCE', 'Language Preference')} value={this.state.user.preferred_language || ''}
                    name="preferred_language"
                    options={languageOptions} onChange={this.handleUserChange.bind(this)}/>
          </Form.Field>
          <Form.Field>
            <label>{strings.getString('REGISTRATION_COUNTRY', 'Registration Country')}</label>
            <input type="text" value={registrationCountry}
                   disabled={true}/>
          </Form.Field>


          <Button className="Button__ACE" type='submit' primary>{strings.getString('UPDATE_PROFILE', 'update profile')}</Button>
        </Form>


        <h1>{strings.getString('ACCOUNT_COMMUNICATION_PREFERENCES', 'Agreements and Communication Preferences')}</h1>

        <Form>


          <Header as='h3'>{strings.getString('REQUIRED_AGREEMENTS', 'Required Agreements')}</Header>

          {agreementList.filter(a => a.requiredIfPresent).map(a => (
            <Form.Field key={a.externalId}>
              <Checkbox label={<label dangerouslySetInnerHTML={{ __html: a.label }}></label>} checked={a.accepted} disabled={a.disabled} value={a.externalId}
                        id={a.externalId}
                        onChange={this.handleAgreementChange.bind(this)}/>
            </Form.Field>
          ))}

          <Header as='h4'>{strings.getString('ACCOUNT_ADDITIONAL_PREFERENCES', 'Additional Agreements and Communication Preferences')}</Header>

          {agreementList.filter(a => !a.requiredIfPresent).map(a => (
            <Form.Field key={a.externalId}>
              <Checkbox label={<label dangerouslySetInnerHTML={{ __html: a.label }}></label>} checked={a.accepted} disabled={a.disabled} value={a.externalId}
                        id={a.externalId}
                        onChange={this.handleAgreementChange.bind(this)}/>
            </Form.Field>
          ))}
        </Form>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfilePage)
