import {
    SET_TWITCH_ACCOUNT_DETAILS,
    CLEAR_TWITCH_ACCOUNT_DETAILS,
} from '../actionTypes'

const initialState = {
    hasTwitchConnected: false,
    twitchAccountName: null,
}

const externalAccounts = (state = initialState, action) => {
    switch(action.type) {
        case SET_TWITCH_ACCOUNT_DETAILS:
            return {
                ...state,
                hasTwitchConnected: !!action.payload,
                twitchAccountName: action.payload,
            }
        case CLEAR_TWITCH_ACCOUNT_DETAILS:
            return {
                ...state,
                hasTwitchConnected: false,
                twitchAccountName: null,
            }
        default:
            return state
    }
}

export default externalAccounts