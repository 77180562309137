import React from 'react'
import { api } from '../utilities/api'
import { Dimmer, Header, Loader, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsOrderHistory extends React.Component {

  state = {
    loading: false,
    orderId: '',
    wallet: {}
  }

  async componentDidMount() {
    const orderId = _.result(this.props, 'match.params.id')
    this.setState({
      orderId: orderId,
    })
    await this.getContent()

  }

  async getContent() {
    this.setState({ loading: true })
    const wallet = await api.get(`/user/wallet-ledger/${_.result(this.props, 'match.params.id')}`)
    this.setState({
      wallet: wallet.data
    })

    this.setState({ loading: false })
  }

  render() {

    const { loading, wallet } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('WALLET_HISTORY', 'Wallet History')}</h1>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2}>{strings.getString('WALLET_DETAILS', 'Wallet Details')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{strings.getString('CURRENCY', 'Currency')}</Table.Cell>
              <Table.Cell>{wallet.currency_code}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('DESCRIPTION', 'Description')}</Table.Cell>
              <Table.Cell>{wallet.description}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('BALANCE', 'Balance')}</Table.Cell>
              <Table.Cell>{_.result(wallet, 'display_strings.balance')}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </FixedTable>


        <Header as='h3'>{strings.getString('LEDGER', 'Ledger')}</Header>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('DATE', 'Date')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('USER', 'User')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('BALANCE_CHANGE', 'Balance Change')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('NOTE', 'Note')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(wallet.wallet_ledger || []).map((w, i) => (
              <Table.Row key={i}>
                <Table.Cell singleLine>{(new Date(w.date_created)).toLocaleString()}</Table.Cell>
                <Table.Cell singleLine>{w.generated_by_username}</Table.Cell>
                <Table.Cell singleLine>{_.result(w, 'display_strings.balance_change')}</Table.Cell>
                <Table.Cell>{w.note}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </FixedTable>
      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsOrderHistory)
