/*

FRAGMENT: DisplayField
Provides styling for a single label and value. Intended to display read only values.

PROPERTY   DEFAULT  REQUIREMENT  TYPE        DESCRIPTION
label               Required     String      
value               Required     Str || Int  
inline     false    Optional     Boolean     places the value inline and just to the right of the label
columnal   false    Optional     Boolean     Label and field placed into two columns. Label column provided with set width.
maxHeight           Optional     Integer     Sets the maxHeight for the field's value. Any overflow will be scrollable.

*/

import React from 'react'
import './styles.scss'


const DisplayField = (props) => {

    const { label, value, inline, columnal, maxHeight } = props
    let className = "DisplayField"
    if (inline) className += " DisplayField--inline"
    if (columnal) className += " DisplayField--columnal"
    return (
        <div className={className}>
            <div className="DisplayField__label">{label}</div>
            <div style={{ maxHeight: maxHeight ? `${maxHeight}px` : 'auto' }} className="DisplayField__value" dangerouslySetInnerHTML={{ __html: value }} />
        </div>
    )
}

export default DisplayField