import {
    ADD_BLOGS,
    FILTER_BLOGS,
    SEARCH_BLOGS,
    PAGINATE_BLOGS,
    CLEAR_BLOGS_SEARCH_CRITERIA,
    CLEAR_BLOGS
} from '../actionTypes'

import contentful from '../../utilities/contentful'
import _ from 'lodash'


export const fetchBlogs = () => {
    return async function (dispatch) {
        const add = (blogs) => {
            const newBlogs = blogs.map(blog => {
                blog.thumbUrl = _.result(blog, 'thumbnail.assetUrl')
                return blog
            })
            dispatch(addBlogs(newBlogs))
        }
        const options = {
            contentType: 'blogPost',
            match: null,
            language: undefined,
            extra: 'order=-fields.publishDate',
            skip: 0,
            limit: 50,
        }
        contentful.getPaginatedEntries(options, add)
    }
}

export const addBlogs = blogs => {
    return (dispatch) => {
        dispatch({
            type: ADD_BLOGS,
            payload: blogs
        })
        dispatch(filterBlogs())
    }
}

export const filterBlogs = criteria => {
    return (dispatch) => {
        dispatch({
            type: FILTER_BLOGS,
            payload: criteria
        })
        dispatch(searchBlogs())
    }
}

export const searchBlogs = criteria => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_BLOGS,
            payload: criteria
        })
        dispatch(paginateBlogs(1))
    }
}

export const clearBlogsSearchCriteria = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_BLOGS_SEARCH_CRITERIA,
        })
        dispatch(paginateBlogs(1))
    }
}

export const paginateBlogs = page => ({
    type: PAGINATE_BLOGS,
    payload: page
})

export const clearBlogs = () => ({
    type: CLEAR_BLOGS
})