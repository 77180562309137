export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const SET_SESSION = 'SET_SESSION'
export const REMOVE_SESSION = 'REMOVE_SESSION'
export const TOGGLE_INITIAL_FETCH = 'TOGGLE_INITIAL_FETCH'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_ACCOUNT_AGREEMENTS = 'SET_ACCOUNT_AGREEMENTS'
export const SET_AVAILABLE_AGREEMENT_DEFINITIONS = 'SET_AVAILABLE_AGREEMENT_DEFINITIONS'
export const SET_LANGUAGES = 'SET_LANGUAGES'
export const SET_LANGUAGE_MAP = 'SET_LANGUAGE_MAP'
export const SET_AVAILABLE_LANGUAGES = 'SET_AVAILABLE_LANGUAGES'
export const SET_COHORTS = 'SET_COHORTS'
export const SET_USER_GIFTS_AVAILABLE = 'SET_USER_GIFTS_AVAILABLE'
export const SET_GUILD_MEMBERSHIP = 'SET_GUILD_MEMBERSHIP'
export const SET_GUILD = 'SET_GUILD'
export const SET_GUILD_WALLET = 'SET_GUILD_WALLET'
export const SET_GUILD_NOTIFICATIONS = 'SET_GUILD_NOTIFICATIONS'
export const SET_GUILD_CONFIGURATION = 'SET_GUILD_CONFIGURATION'
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT'
export const CLEAR_MODAL_CONTENT = 'CLEAR_MODAL_CONTENT'
export const OPEN_MOBILE_NAV = 'OPEN_MOBILE_NAV'
export const CLOSE_MOBILE_NAV = 'CLOSE_MOBILE_NAV'
export const SET_REGIONS = 'SET_REGIONS'
export const SET_COOKIE_NOTICE = 'SET_COOKIE_NOTICE'
export const SET_ACCEPTED_REGIONAL_AGREEMENTS = 'SET_ACCEPTED_REGIONAL_AGREEMENTS'
export const SET_MISSING_REGIONAL_AGREEMENTS = 'SET_MISSING_REGIONAL_AGREEMENTS'

export const SET_USER = 'SET_USER'
export const SET_USER_VIRTUAL_WALLET = 'SET_USER_VIRTUAL_WALLET'
export const SET_USER_GUILD_APPS = 'SET_USER_GUILD_APPS'
export const SET_USER_GUILD_INVITES = 'SET_USER_GUILD_INVITES'
export const SET_ACCOUNT_DISCOUNTS = 'SET_ACCOUNT_DISCOUNTS'
export const SET_USER_GIFTS = "SET_USER_GIFTS"
export const SEARCH_GIFTS_GIVEN = "SEARCH_GIFTS_GIVEN"
export const SORT_GIFTS_GIVEN = "SORT_GIFTS_GIVEN"
export const PAGINATE_GIFTS_GIVEN = "PAGINATE_GIFTS_GIVEN"
export const SEARCH_GIFTS_RECEIVED = "SEARCH_GIFTS_RECEIVED"
export const SORT_GIFTS_RECEIVED = "SORT_GIFTS_RECEIVED"
export const PAGINATE_GIFTS_RECEIVED = "PAGINATE_GIFTS_RECEIVED"
export const CLEAR_USER_GIFTS = "CLEAR_USER_GIFTS"
export const SET_USER_ENTITLEMENTS = "SET_USER_ENTITLEMENTS"
export const SEARCH_GIFTABLE_ENTITLEMENTS = "SEARCH_GIFTABLE_ENTITLEMENTS"
export const SORT_GIFTABLE_ENTITLEMENTS = "SORT_GIFTABLE_ENTITLEMENTS"
export const PAGINATE_GIFTABLE_ENTITLEMENTS = "PAGINATE_GIFTABLE_ENTITLEMENTS"
export const CLEAR_USER_ENTITLEMENTS = "CLEAR_USER_ENTITLEMENTS"
export const CLEAR_USER = 'CLEAR_USER'

export const FETCH_GUILDS = 'FETCH_GUILDS'
export const SORT_GUILDS = 'SORT_GUILDS'
export const PAGINATE_GUILDS = 'PAGINATE_GUILDS'
export const CLEAR_GUILDS_SORT_CRITERIA = 'CLEAR_GUILDS_SORT_CRITERIA'
export const CLEAR_GUILDS = 'CLEAR_GUILDS'
export const FETCH_GUILD_CONFIGURATION = 'FETCH_GUILD_CONFIGURATION'
export const SET_SELECTED_GUILD = "SET_SELECTED_GUILD"
export const CLEAR_SELECTED_GUILD = "CLEAR_SELECTED_GUILD"

export const SET_GUILD_MEMBERSHIP_RECORD_INFO = "SET_GUILD_LATEST_MEMBERSHIP_INFO"
export const SET_GUILD_MANAGEMENT_DETAILS = "SET_GUILD_MANAGEMENT_DETAILS"
export const SET_GUILD_FETCHED = "SET_GUILD_FETCHED"
export const SET_GUILD_MEMBERS = "SET_GUILD_MEMBERS"
export const SET_GUILD_APPLICATIONS = "SET_GUILD_APPLICATIONS"
export const SEARCH_GUILD_MEMBERS = "SEARCH_GUILD_MEMBERS"
export const CLEAR_MEMBERS_SEARCH_CRITERIA = "CLEAR_MEMBERS_SEARCH_CRITERIA"
export const PAGINATE_GUILD_MEMBERS = "PAGINATE_GUILD_MEMBERS"
export const SET_HAS_GUILD = 'SET_HAS_GUILD'
export const CLEAR_GUILD_DETAILS = 'CLEAR_GUILD_DETAILS'
export const SET_GUILD_INVITES = 'SET_GUILD_INVITES'
export const SET_GUILD_CHAT = "SET_GUILD_CHAT"
export const SEARCH_GUILD_CHAT = "SEARCH_GUILD_CHAT"
export const CLEAR_GUILD_CHAT_SEARCH_CRITERIA = "CLEAR_GUILD_CHAT_SEARCH_CRITERIA"
export const SET_GUILD_LOG = "SET_GUILD_LOG"
export const SEARCH_GUILD_LOG = "SEARCH_GUILD_LOG"
export const CLEAR_GUILD_LOG_SEARCH_CRITERIA = "CLEAR_GUILD_LOG_SEARCH_CRITERIA"
export const PAGINATE_GUILD_LOG = "PAGINATE_GUILD_LOG"

export const ADD_ARTICLES = 'ADD_ARTICLES'
export const SEARCH_ARTICLES = 'SEARCH_ARTICLES'
export const CLEAR_ARTICLES_SEARCH_CRITERIA = 'CLEAR_ARTICLES_SEARCH_CRITERIA'
export const CLEAR_ARTICLES = 'CLEAR_ARTICLES'
export const PAGINATE_ARTICLES = 'PAGINATE_ARTICLES'

export const ADD_WAR_STORIES = 'ADD_WAR_STORIES'
export const SEARCH_WAR_STORIES = 'SEARCH_WAR_STORIES'
export const CLEAR_WAR_STORIES_SEARCH_CRITERIA = 'CLEAR_WAR_STORIES_SEARCH_CRITERIA'
export const PAGINATE_WAR_STORIES = 'PAGINATE_WAR_STORIES'
export const CLEAR_WAR_STORIES = 'CLEAR_WAR_STORIES'

export const ADD_BLOGS = 'ADD_BLOGS'
export const FILTER_BLOGS = 'FILTER_BLOGS'
export const SEARCH_BLOGS = 'SEARCH_BLOGS'
export const PAGINATE_BLOGS = 'PAGINATE_BLOGS'
export const CLEAR_BLOGS_SEARCH_CRITERIA = 'CLEAR_BLOGS_SEARCH_CRITERIA'
export const CLEAR_BLOGS = 'CLEAR_BLOGS'

export const ADD_PROMOTIONS = 'ADD_PROMOTIONS'
export const PAGINATE_PROMOTIONS = 'PAGINATE_PROMOTIONS'
export const CLEAR_PROMOTIONS = 'CLEAR_PROMOTIONS'
export const SET_SELECTED_PROMOTION = 'SET_SELECTED_PROMOTION'
export const CLEAR_SELECTED_PROMOTION = 'CLEAR_SELECTED_PROMOTION'

export const SET_STORE_DETAILS = 'SET_STORE_DETAILS'
export const SET_SELECTED_STORE_CATEGORY = 'SET_SELECTED_STORE_CATEGORY'
export const SEARCH_CATALOG = 'SEARCH_CATALOG'
export const CLEAR_CATALOG_SEARCH_CRITERIA = 'CLEAR_CATALOG_SEARCH_CRITERIA'
export const CLEAR_CROWFALL_STORE = 'CLEAR_CROWFALL_STORE'
export const SET_USER_CART = 'SET_USER_CART'
export const EMPTY_USER_CART = 'EMPTY_USER_CART'
export const SET_CART_SOLUTION = 'SET_CART_SOLUTION'
export const SET_SHIPPING_DATA = 'SET_SHIPPING_DATA'
export const EDIT_SHIPPING_DATA = "EDIT_SHIPPING_DATA"
export const CLEAR_USER_SOLUTIONS = 'CLEAR_USER_SOLUTIONS'

export const FETCH_AFFILIATE_DETAILS = 'FETCH_AFFILIATE_DETAILS'

export const SET_TWITCH_ACCOUNT_DETAILS = 'SET_TWITCH_ACCOUNT_DETAILS'
export const CLEAR_TWITCH_ACCOUNT_DETAILS = 'CLEAR_TWITCH_ACCOUNT_DETAILS'

export const SET_SUBSCRIPTION_DETAILS = 'SET_SUBSCRIPTION_DETAILS' 
export const SET_SUBSCRIPTION_PAYMENT_METHOD = 'SET_SUBSCRIPTION_PAYMENT_METHOD'
export const CLEAR_SUBSCRIPTION_DETAILS = 'CLEAR_SUBSCRIPTION_DETAILS'