import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import strings from '../../../strings'

import PublicGuildOverview from '../PublicGuildOverview'
import FieldDropdown from '../../../componentLibrary/Models/FieldDropdown'
import Pagination from '../../../componentLibrary/Elements/Pagination'
import GuildCrest from '../../../componentLibrary/Elements/GuildCrest'
import RadioButtons from '../../../componentLibrary/Elements/RadioButtons'
import Button from '../../../componentLibrary/Fragments/Button'
import SearchResultsDisplay from '../../../componentLibrary/Fragments/SearchResultsDisplay'
import GoldBar from '../../../componentLibrary/Fragments/GoldBar'

import { paginateGuilds, setSelectedGuild, clearSelectedGuild, sortGuilds, clearGuildsSortCriteria } from '../../../redux/actions/guilds'
import './styles.scss'


const mapStateToProps = state => {
    return {
        isFetched: state.guilds.isFetched,
        sorted: state.guilds.sorted,
        paginated: state.guilds.paginated,
        paginationCriteria: state.guilds.paginationCriteria,
        configuration: state.guilds.configuration,
        selectedGuild: state.guilds.selectedGuild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        paginateGuilds: (criteria) => dispatch(paginateGuilds(criteria)),
        setSelectedGuild: (name) => dispatch(setSelectedGuild(name)),
        clearSelectedGuild: () => dispatch(clearSelectedGuild()),
        sortGuilds: (criteria) => dispatch(sortGuilds(criteria)),
        clearGuildsSortCriteria: () => dispatch(clearGuildsSortCriteria()),
    }
}

class GuildsTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sort: {
                sortType: null,
                sortMethod: null,
            },
            updatedSort: false
        }
        this.selectSortType = this.selectSortType.bind(this)
        this.selectSortMethod = this.selectSortMethod.bind(this)
        this.handleSortClick = this.handleSortClick.bind(this)
    }

    handleRowClick(guild) {
        const { location, history } = this.props
        let newUrl = `${location.pathname}${location.search}`
        if (guild) {
            newUrl += `#${guild}`
            this.props.setSelectedGuild(guild)
        } else {
            this.props.clearSelectedGuild()
        }
        history.replace(newUrl)
    }

    selectSortType(value, name) {
        const { sortMethod } = this.state.sort
        let newMethod = null
        if (value !== null && sortMethod === null) newMethod = "descending"
        if (value !== null && sortMethod !== null) newMethod = sortMethod
        if (value === null && sortMethod !== null) newMethod = null

        let newSort = { ...this.state.sort, sortType: value, sortMethod: newMethod }
        this.setState({ sort: newSort, updatedSort: true })
    }

    selectSortMethod(value, name) {
        const { sort } = this.state
        let newSort = { ...sort, sortMethod: value }
        this.setState({ sort: newSort, updatedSort: true })
    }

    handleSortClick() {
        const { sort } = this.state
        const { sortType, sortMethod } = sort
        const sortCriteria = !_.isEmpty(sortType) && !_.isEmpty(sortMethod) ? { type: sortType, method: sortMethod } : null
        if (sortCriteria !== null) {
            this.props.sortGuilds(sortCriteria)
        } else {
            this.props.clearGuildsSortCriteria()
        }
        this.props.sortGuilds(sortCriteria)
        this.setState({ updatedSort: false })
    }


    render() {
        
        const { sort, updatedSort } = this.state
        const { sortType, sortMethod } = sort
        const sortTypes = [
            { label: strings.getString("GUILD_MEMBERS", "Guild Members"), value: "member_count", selected: false }, 
            { label: strings.getString("DATE_CREATED", "Date Created"), value: "date_created", selected: false },
            { label: strings.getString("RECRUITMENT_STATUS", "Recruitment Status"), value: "recruiting_status", selected: false }
        ]
        let sortMethods = []
        if (sortType === "member_count" || sortType === "recruiting_status") sortMethods = [
            { label: strings.getString("ASCENDING", "Ascending") , value: "ascending" },
            { label: strings.getString("DESCENDING", "Descending"), value: "descending" }
        ]
        if (sortType === "recruiting_status") sortMethods = [
            { label: (<span dangerouslySetInnerHTML={{ __html: "A &#8594; Z" }}/>) , value: "ascending" },
            { label: (<span dangerouslySetInnerHTML={{ __html: "Z &#8594; A" }}/>), value: "descending" }
        ]
        if (sortType === "date_created") sortMethods = [
            { label: strings.getString("MOST_RECENT_FIRST", "Most Recent First"), value: "descending" },
            { label: strings.getString("MOST_RECENT_LAST", "Most Recent Last"), value: "ascending" }
        ]

        const { isFetched, sorted, paginated, paginationCriteria, paginateGuilds, configuration, selectedGuild } = this.props
        const { limit, page } = paginationCriteria
        const { max_guild_members: maxMembers } = configuration

        return (
            <div className="GuildsTable">

                <div className="GuildsTable__searchResults">
                    <SearchResultsDisplay 
                        disabled={!isFetched} resultsCount={sorted ? sorted.length : null} 
                        disabledText={strings.getString("SERVER_SIDE_SEARCH_HELPER_GUILDS", "Please provide search criteria to view a list of relevant Guilds.")} 
                        noResultsText={strings.getString("NO_MATCHING_GUILDS", "There are no Guilds matching your criteria. Please refine your search.")}
                    />
                </div>

                {paginated.length > 0 && <div className="GuildsTable__sortBox">
                    <div className="GuildsTable__sortFields">
                        <div className="GuildsTable__field">
                            <FieldDropdown
                                label={strings.getString("SORT_BY", "Sort By")} inline
                                placeholder={strings.getString("SELECT_ONE", "Select One")}
                                above selectOne={this.selectSortType} options={sortTypes} />
                        </div>
                        {sortMethods.length > 0 && <div className="GuildsTable__radioButtons">
                            <RadioButtons
                                name='sortMethod' options={sortMethods} value={sortMethod} select={this.selectSortMethod}/>
                        </div>}
                        <div className="GuildsTable__button">
                            <Button label={strings.getString("UPDATE", "Update")} onclick={this.handleSortClick} medium disabled={!updatedSort}/>
                        </div>
                    </div>
                </div>}

                {isFetched && paginated.length > 0 &&
                    <div className="GuildsTable__rows">
                        {paginated.map((guild, index) => {
                            const { guild_id: id, display_name, name, member_count, custom_fields, recruiting_status } = guild
                            const recruitingStatusStr = recruiting_status === "accepting_applications" ? "Accepting Applications" : _.capitalize(recruiting_status)
                            const crest = _.result(custom_fields, '[0].value')
                            const isSelected = selectedGuild === name ? true : false
                            return (
                                <React.Fragment key={`Guild${id}Row`} >
                                    <GoldBar h3/>

                                    {!isSelected && <div className="GuildsTable__row" onClick={() => this.handleRowClick(name)}>
                                        <div className="GuildsTable__rowGeneral">
                                            <div className="GuildsTable__guildCrest">
                                                {crest && <GuildCrest small crest={crest}/>}
                                            </div>
                                            <div className="GuildsTable__guildNames">
                                                <div className="GuildsTable__displayName">{display_name}</div>
                                                <div className="GuildsTable__name">{`// ${name}`}</div>
                                            </div>
                                        </div>

                                        <div className="GuildsTable__rowMembers">
                                            {member_count} / {maxMembers} {strings.getString("MEMBERS", "Members")}
                                        </div>

                                        <div className="GuildsTable__rowEnd">
                                            <div className="GuildsTable__rowStatus">
                                                {recruitingStatusStr}
                                            </div>
                                            <div className="GuildsTable__arrow">
                                                <FontAwesomeIcon icon="chevron-right"/>
                                            </div>
                                        </div>
                                    </div>}

                                    {isSelected && <div className="GuildsTable__row--open" id={`GuildsTable__row${name}`}>
                                        <div className="GuildsTable__openRowHeader" onClick={() => this.handleRowClick()}>
                                            <div className="GuildsTable__arrow"><FontAwesomeIcon icon="chevron-down"/></div>
                                        </div>
                                        <PublicGuildOverview id={id}/>
                                    </div>}

                                    {index === paginated.length - 1 && <GoldBar h3/>}
                                </React.Fragment>
                            )
                        })}
                    </div>
                }

                {sorted && sorted.length > 0 && <Pagination paginate={paginateGuilds} list={sorted} limit={limit} page={page}/>}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildsTable)