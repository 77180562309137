import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import querystring from 'query-string'
import assets from '../../assets'
import strings from '../../strings'

import GuildsTable from './GuildsTable'
import Page from '../../componentLibrary/Models/Page'
import FieldDropdown from '../../componentLibrary/Models/FieldDropdown'
import Checkbox from '../../componentLibrary/Elements/Checkbox'
import LocLink from '../../componentLibrary/Elements/LocLink'
import AccordionItem from '../../componentLibrary/Elements/AccordionItem'
import Searchbar from '../../componentLibrary/Elements/Searchbar'
import Button from '../../componentLibrary/Fragments/Button'

import {
    fetchGuildConfiguration, fetchGuildsSubset,
    paginateGuilds, clearGuilds,
    setSelectedGuild, clearSelectedGuild,
} from '../../redux/actions/guilds'

import './styles.scss'
import {getLatestGuildMembership} from "../../redux/actions/guild-management";
import moment from "moment-timezone";
import {GuildCannotCreateJoinMsg} from "../GuildCannotCreateJoinMsg";


const mapStateToProps = state => {
    return {
        hasGuild: state.guildManagement.hasGuild,
        languages: state.languages,
        session: state.session,
        configuration: state.guilds.configuration,
        allegiances: state.guilds.allegiances,
        sorted: state.guilds.sorted,
        paginationCriteria: state.guilds.paginationCriteria,
        selectedGuild: state.guilds.selectedGuild,
        latestGuildMembership: state.guildManagement.latestGuildMembership
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchGuildConfiguration: () => dispatch(fetchGuildConfiguration()),
        fetchGuildsSubset: (uri)  => dispatch(fetchGuildsSubset(uri)),
        paginateGuilds: (page) => dispatch(paginateGuilds(page)),
        clearGuilds: () => dispatch(clearGuilds()),
        setSelectedGuild: (name) => dispatch(setSelectedGuild(name)),
        clearSelectedGuild: () => dispatch(clearSelectedGuild()),
        getLatestGuildMembership: () => dispatch(getLatestGuildMembership())
    }
}

class SearchGuildsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            languageOptions: [],
            allegiance: [],
            statusOptions: [],

            criteria: {
                name: '',
                status: null,
                languages: [],
                isNotFull: false,
                allegiance: null,
            },

            loadingGuilds: false,
            advancedOpen: false,
        }
        this.setConfigs = this.setConfigs.bind(this)
        this.applySearchParameters = this.applySearchParameters.bind(this)
        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.search = this.search.bind(this)
        this.checkCriteriaStatus = this.checkCriteriaStatus.bind(this)
        this.getSearchFromCriteria = this.getSearchFromCriteria.bind(this)
        this.clearGuildsCriteria = this.clearGuildsCriteria.bind(this)
        this.updateUrlParameters = this.updateUrlParameters.bind(this)
        this.updateInput = this.updateInput.bind(this)
        this.toggleAdvancedSearch = this.toggleAdvancedSearch.bind(this)
        this.toggleCheckbox = this.toggleCheckbox.bind(this)
        this.selectOne = this.selectOne.bind(this)
        this.selectMany = this.selectMany.bind(this)
        this.deselect = this.deselect.bind(this)
    }

    async componentDidMount() {
        const { session, location, setSelectedGuild, fetchGuildConfiguration, getLatestGuildMembership } = this.props
        const selected = location.hash
        if (selected) setSelectedGuild(selected.replace('#', ""))
        const search = querystring.parse(location.search);
        this.applySearchParameters(search)
        await fetchGuildConfiguration()
        if(session) await getLatestGuildMembership()
        this.setConfigs()
    }

    componentDidUpdate(prevProps) {
        const { languages: prevLanguages } = prevProps
        const { languages: currLanguages } = this.props
        if ( !_.isEqual(prevLanguages, currLanguages) ) this.setConfigs()
    }

    setConfigs() {
        const { languages, configuration } = this.props
        const { criteria } = this.state
        const { languages: selectedLanguages, allegiance: selectedAllegiance, status: selectedStatus } = criteria
        const languageOptions = languages ? languages.map(language => {
            return Object.assign({}, null, {
                label: language.display_name,
                value: language.culture_code,
                selected: selectedLanguages.includes(language.culture_code) ? true : false,
            })
        }) : []
        const allegiance = configuration.guild_allegiances ? configuration.guild_allegiances.map(allegiance => {
            return Object.assign({}, null, {
                label: allegiance.display_name,
                value: allegiance.guild_allegiance_id,
                selected: _.isEqual(allegiance.guild_allegiance_id, selectedAllegiance),
            })
        }) : []
        const statusOptions = configuration.recruiting_status ? configuration.recruiting_status.map(status => {
            return Object.assign({}, null, {
                label: status === "accepting_applications" ? "Accepting Applications" : _.capitalize(status),
                value: status,
                selected: _.isEqual(status, selectedStatus)
            })
        }) : []
        this.setState({ languageOptions, allegiance, statusOptions })
    }

    applySearchParameters(params) {
        this.setState({
            criteria: {
                name: params.name ? params.name : "",
                status: params.recruiting_status ? params.recruiting_status : null,
                languages: params.language ? params.language.split(",") : [],
                isNotFull: params.is_full === 'true' ? true : false,
                allegiance: params.allegiance ? params.allegiance : null,
            },
        }, () => this.search())
    }

    openSelectedGuild(name) {
        const { sorted, paginationCriteria, paginateGuilds } = this.props
        const { limit, page } = paginationCriteria
        const indexOfGuild = _.findIndex(sorted, ["name", name])
        const guildPageNumber = Math.ceil((indexOfGuild + 1) / limit)
        if (guildPageNumber !== page) {
            paginateGuilds(guildPageNumber)
        }
        const selectedGuildRow = document.getElementById(`GuildsTable__row${name}`)
        if (selectedGuildRow) {
            const selectedRowBounds = selectedGuildRow.getBoundingClientRect()
            if (selectedRowBounds.top > window.innerHeight) {
                selectedGuildRow.scrollIntoView({ behavior: "smooth", block: "start" })
            }
        }
    }

    async handleSearchClick() {
        if (this.props.selectedGuild) await this.props.clearSelectedGuild()
        this.search()
    }

    async search() {
        this.setState({ loadingGuilds: true })
        const status = this.checkCriteriaStatus()
        const { selectedGuild } = this.props

        if (status === "empty") {
            this.props.clearGuilds()
        } else if (status === "valid") {
            const search = this.getSearchFromCriteria()
            await this.props.fetchGuildsSubset(search)
            this.updateUrlParameters(search, selectedGuild)
            if (selectedGuild) this.openSelectedGuild(selectedGuild)
        }
        this.setState({ loadingGuilds: false })
    }

    updateUrlParameters(search, hash) {
        const { history, location } = this.props
        let updatedUrl = `${location.pathname}`
        if (!_.isEmpty(search)) updatedUrl += `?${search}`
        if (!_.isEmpty(hash)) updatedUrl += `#${hash}`
        history.replace(updatedUrl)
    }

    checkCriteriaStatus() {
        const { criteria } = this.state
        let baseCriteria = {
            name: '',
            status: null,
            languages: [],
            isNotFull: false,
            allegiance: null,
        }

        let status = null
        if ( _.isEqual(baseCriteria, criteria) === true ) {
            status = "empty"
        } else {
            status = "valid"
        }
        return status
    }

    getSearchFromCriteria() {
        const { name, languages, allegiance, isNotFull, status } = this.state.criteria

        let queries = []
        if (name !== '') queries.push(`name=${name}`)
        if (status) queries.push(`recruiting_status=${status}`)
        if (isNotFull) queries.push(`is_not_full=true`)
        if (languages.length > 0) queries.push(`language=${languages.join(',')}`)
        if (allegiance) queries.push(`allegiance=${allegiance}`)
        queries = queries.join('&')
        return queries
    }

    clearGuildsCriteria() {
        this.props.clearGuilds()
        this.setState({
            criteria: {
                name: '',
                status: null,
                languages: [],
                isNotFull: false,
                allegiance: null,
            },
            advancedOpen: false
        }, () => this.setConfigs())
        this.updateUrlParameters()
    }

    toggleAdvancedSearch() {
        const { advancedOpen } = this.state
        this.setState({ advancedOpen: !advancedOpen })
    }

    updateInput(value, initSearch) {
        const { criteria } = this.state
        let newCriteria = _.isEmpty(value) ? { ...criteria, name: '' } : { ...criteria, name: value }
        if (initSearch) {
            this.setState({ criteria: newCriteria })
            this.search()
        } else {
            this.setState({ criteria: newCriteria })
        }
    }

    selectOne(value, name) {
        const { criteria } = this.state
        let newCriteria = { ...criteria, [name]: value}
        this.setState({ criteria: newCriteria })
    }

    selectMany(value, name) {
        const { criteria } = this.state
        const newCriteria = { ...criteria, [name]: [ ...criteria[name], value] }
        this.setState({ criteria: newCriteria })
    }

    deselect(value, name) {
        const { criteria } = this.state
        const newCriteria = { ...criteria, [name]: criteria[name].filter(item => !_.isEqual(item, value)) }
        this.setState({ criteria: newCriteria })
    }

    toggleCheckbox(value, name) {
        let criteria = { ...this.state.criteria, [name]: value }
        this.setState({ criteria })
    }

    componentWillUnmount() {
        this.props.clearGuilds()
    }


    render() {

        const { hasGuild, session, latestGuildMembership } = this.props

        const { advancedOpen, languageOptions, allegiance, statusOptions, criteria, loadingGuilds } = this.state
        const { name, isNotFull } = criteria

        let ctaString = null
        let ctaLink = null
        if (session && hasGuild) {
            ctaString = strings.getString("VIEW_MY_GUILD", "View My Guild")
            ctaLink = '/guild/overview'
        } else if (session && !hasGuild) {
            ctaString = strings.getString("CREATE_GUILD", "Create Guild")
            ctaLink = '/guild/create'
        } 
        // else if (!session) {
        //     ctaString = strings.getString("SIGN_UP", "Sign Up")
        //     ctaLink = '/register'
        // }
        let displayJoinCreateMsg = (latestGuildMembership && latestGuildMembership.denyGuildCreate)

        return (
            <Page header={{ title: strings.getString("SEARCH_GUILDS", "Search Guilds"), banner: assets.staticImageUrls.GUILD_NON_MEMBER_BANNER }}>
                <div className="SearchGuildsPage">
                    {displayJoinCreateMsg &&
                        <div className="SearchGuildsPage__cannot_create_display">
                            <GuildCannotCreateJoinMsg latestGuildMembership={latestGuildMembership} createGuild />
                        </div>
                    }

                    {session && session.account_status.type !== 'trial' &&
                        <div className="SearchGuildsPage__ctaButton">
                            {displayJoinCreateMsg
                                ?
                                <Button disabled={displayJoinCreateMsg}>{ctaString}</Button>
                                :
                                <LocLink to={ctaLink}><Button>{ctaString}</Button></LocLink>
                            }
                        </div>
                    }

                    <div className="SearchGuildsPage__searchbar">
                        <div className="SearchGuildsPage__searchInput"><Searchbar update={this.updateInput} value={name}/></div>
                        <div className="SearchGuildsPage__searchbarButtons">
                            <div className="SearchGuildsPage__button"><Button medium loading={loadingGuilds} gray label={strings.getString("CLEAR", "Clear")} onclick={this.clearGuildsCriteria}/></div>
                            <div className="SearchGuildsPage__button"><Button medium loading={loadingGuilds} label={strings.getString("SEARCH", "Search")} onclick={this.handleSearchClick}/></div>
                        </div>
                    </div>

                    <AccordionItem arrowLeft shouldShow={advancedOpen} select={this.toggleAdvancedSearch} label={strings.getString("ADVANCED_SEARCH", "Advanced Search")}>
                        <div className="SearchGuildsPage__advancedSearch">
                            <div className="SearchGuildsPage__searchSection">
                                {languageOptions && <div className="SearchGuildsPage__field">
                                    <FieldDropdown
                                        placeholder={strings.getString("LANGUAGES", "Languages")} inline
                                        name="languages" above options={languageOptions} selectMany={this.selectMany} deselect={this.deselect}
                                    />
                                </div>}
                            </div>
                            <div className="SearchGuildsPage__searchSection">
                                <div className="SearchGuildsPage__field">
                                    <Checkbox
                                        label={strings.getString("IS_NOT_FULL", "Is Not Full")} isChecked={isNotFull}
                                        toggle={this.toggleCheckbox} flipLabel name="isNotFull"
                                    />
                                </div>
                                {statusOptions && <div className="SearchGuildsPage__field">
                                    <FieldDropdown
                                        label={strings.getString("RECRUITMENT_STATUS", "Recruitment Status")} inline
                                        placeholder={strings.getString("SELECT_ONE", "Select One")}
                                        name="status" above options={statusOptions} selectOne={this.selectOne}
                                    />
                                </div>}
                                {allegiance && <div className="SearchGuildsPage__field">
                                    <FieldDropdown
                                        label={strings.getString("PANTHEON", "Pantheon")} inline
                                        placeholder={strings.getString("SELECT_ONE", "Select One")}
                                        name='allegiance' above options={allegiance} selectOne={this.selectOne}
                                    />
                                </div>}
                            </div>
                        </div>
                    </AccordionItem>

                    <GuildsTable history={this.props.history} location={this.props.location}/>

                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchGuildsPage)
