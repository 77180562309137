import React from 'react';
import {Button, Form} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { identityApi } from '../utilities/api'
import strings from "../strings"
import queryString from 'query-string'
import {Redirect} from "react-router-dom"
import {isEmpty} from 'lodash'
import swal from '@sweetalert/with-react'

const mapStateToProps = state => {
    return {
        connected: state.externalAccounts.twitchConnected
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

class AccountTwitchManagement extends React.Component {

    state = {
        connected: false,
        hasParams: false,
        loginName: ''
    }
    
    async componentDidMount() {
        await this.isTwitchAccountConnected();
        let params = queryString.parse(this.props.location.search);
        if(!isEmpty(params) && params.code && params.state) {
            try {
                await identityApi.post(`/user/external/twitch/authorize/${params.code}/${params.state}`)
                this.setState({
                    hasParams: true
                });
                swal({
                    content: <p className="external__message">Successfully connected to Twitch account</p>, 
                    icon: 'success'
                });
            } catch(err) {
                if(err.response.data.Message.includes('403')){
                    let messageArray = err.response.data.Message.split(',');
                    let twitchLogin = messageArray[0];
                    let loginNameStyled = <span className="external__alert_login">{twitchLogin}</span>
                    swal({
                        content: <div>
                            <p className="external__alert_title">Uh Oh! We were unable to connect your account to Twitch.</p>
                            <p className="external__alert_info">A Crowfall account is already connected to the Twitch account {loginNameStyled}.</p>
                            <p className="external__alert_info">Only ONE Crowfall account is allowed per Twitch account.</p>
                        </div>,
                        icon: 'error'
                    })
                } else {
                    swal({
                        content: <p className="external__message">Uh Oh! We were unable to connect your Twitch account</p>, 
                        icon: 'error'
                    })
                }
            }
        } 
    }

    async isTwitchAccountConnected () {
        const res = await identityApi.get('/user/external/twitch/is-account-connected')
            let userLoginName = res.data
            this.setState({
                connected: userLoginName !== '',
                loginName: userLoginName
            })
        }

    async disconnectTwitchAccount () {
        try {
            let result = await identityApi.post('/user/external/twitch/disconnect-account')
            let loginName = this.state.loginName
            let loginNameStyled = <span className="external__alert_info">{loginName}</span>
            if (result.data === true) {
                this.setState({
                    connected: false,
                    loginName: ''
                })
            }
            const twitchUrl = <a href="http://twitch.tv" target="_blank" rel="noopener noreferrer">twitch.tv</a>
            swal({
                content:
                    <div>
                        <p className="external__alert_title">Successfully disconnected Twitch account {loginNameStyled}</p>
                        <p className="external__alert_info"> You will have to manually disconnect your twitch account from {twitchUrl} in the settings/connections section of your account.</p>
                    </div>,
                icon: 'success'
            })
        } catch (exception) {
            swal({
                content: <p>Uh Oh! Unable to disconnect Twitch account</p>,
                icon: 'error'
            })
        }
    }
    
    accountConnectAction = async () => {
        if(this.state.connected) {
            await this.disconnectTwitchAccount()
        } else {
            let redirectUrl = await identityApi.post('/user/external/twitch/get-url-code', { redirectUrl: "https://crowfall.com/account/twitch-management" })
            window.location.replace(redirectUrl.data)
        }
    }
    
    render() {
        if(this.state.hasParams) {
            return <Redirect to={'/twitch-management'}/>
        }
        
        const { connected, loginName } = this.state
        let stringValue = connected ? strings.getString('UNLINK_EXTERNAL_ACCOUNT', 'Unlink twitch') : strings.getString('LINK_EXTERNAL_ACCOUNT', 'Link twitch')
        let loginNameStyled = <span className="external__alert_info">{loginName}</span>
        return (
            <div>
                <h1>Twitch Management</h1>
                {
                    connected &&
                    <p className="external__message">Your Crowfall account is connected to your Twitch account {loginNameStyled} </p>
                }
                {
                    !connected &&
                    <p className="external__message">Link your Crowfall and Twitch Accounts</p>
                }
                
                <Form onSubmit={this.accountConnectAction.bind(this)}>
                    <Button className="ui primary button Button__ACE" type='submit' primary>{stringValue}</Button>
                </Form>
            </div>  
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountTwitchManagement);