import React from 'react'
import './styles.scss'

const PageColumn = (props) => {
	const { children } = props
	return (
		<div className="PageColumn">{children}</div>
	)
}

export default PageColumn