const contentfulIds = {
  HOME: '4YS8q8NN6g0IQygUG82oUq',
  ACCOUNT_PROFILE: '5vKQBU3zUs0eQCku8E0cGK',
  STORE_PAGE: '1bjUQxLXVWow2SoOWCo0Gu',
  EMAIL_VALIDATION: '67zT4v6xwWWuci0WA6OwIc',
  CHECKOUT_PAGE: '5rN4uUpUWcCcg4Yy0omCMo',
  RAZER_REGISTRATION_PAGE: '5pENVpg1Z8hvRua2UYTR3K',
  TWITCH_REGISTRATION_PAGE: 'BsIcQLYiBcjJkYCYBmQob',
  INTEL_LANDER: '6NegDl6BEanNLQ19sJtgbX',
  ALIENWARE_LANDER: '5dCkDxId3yvU9qjrjOaofp',

  ARTICLES_PAGE: '1PEzkOzinGkCkui2GgKWc',
  CLASSES_PAGE: '57EMfw2cGQUeKUGOoIWkyG',
  CLASSES_DETAILS_PAGE: '3kv2GYPcQ8GEKugimk4OUO',
  RACES_PAGE: '3dkGMB7nLWoWAswwIyQwWC',
  RACE_DETAILS_PAGE: '1mxP1b1HrmEeeY8seYcQcC',

  BESTIARY_PAGE: '16IbuQnDhSKckoWqG0CMOs',
  BESTIARY_DETAILS_PAGE: 'TUS4ePc66y8YIQmU00yIQ',

  PANTHEON_PAGE: '65lst5M8ykMmOwwW0kM2Oo',
  PANTHEON_DETAILS_PAGE: '7GFlipQ0gg62eo0kME28Ki',

  ECS_MASTHEAD_CALLOUT: '7N0y877ODU9awQQhVflMfv',
  ECS_LANDING_PAGE: '1SbyIevJaRZk6UC20BJMQB',
  ECS_APPLICATION_PAGE: '77clWugPxzWKcreEkS2uWp',
  ECS_EVENTS_AND_DATES_PAGE: '59HEEbnwdZqSWECmYDsIf3',
  ECS_SPONSORS_PAGE: '1jtNPWLiRoDlRvJI99pwWx',
  ECS_TEAMS_PAGE: '79fwcUivLCdnWDBWBp6jlD',
  ECS_APPLICATION_NOTES: '2pSjDwcBSBlUSioG1WdWKF',
  ECS_APPLICATION_PENDING_CONTENT: '5CzmEjTrkbkblPVWQ0LrLy',
  ECS_APPLY_NOW_CONTENT: '3CyK2oCRTCJrymnGTXjPHl',
  ECS_SPONSOR_LOGOS: '6Wd4rmXwuTZFayU2llT0uy',

  BETA_PAGE: "4Gu73CCaZIKsuNzIZFIYy1",
  GATES_OF_GALLOO_PAGE: '4RxXBuTxc1xlwWvl5OJmcw',
  WARSTORIES_PAGE: '7eynHzStzIZ12lXmVhdgZB',
  BLOGS_PAGE: '3VZL63MpDH0OqDBfmpsxdg',
  GUILD_REWARDS_PAGE: 'jEBfSiBgZfEn7dUq0J0YK',
  VIP_LANDER: '74MFUB17NHg5gxUsfg8dJP',
  CROW_REWARDS_PAGE: '5jUgKVH1Xw2qC2FMolXwZj',

  CORPORATE_CONTACT: '6tb7pq6rc0MUfsVThhoZ6t',
  COMBINATIONS_PAGE: '33Y8b2hTMI4TGn9GRobAy9',
  FAQ_PAGE: 'aCRnh6R6YEUeqAUEmIsOK',
  HOW_TO_PLAY_PAGE: '4oiK1nDeiskK84Us4U2ges',
  TROUBLESHOOTING_PAGE: 'YlgTnRurugeS6UgAggG2e',
  ABOUT_TEAM_PAGE: '57OhYZi9YQYOUUCi6OWoQU',
  PARTNERS_PAGE: '4DPZGcmVsA6OUm2M6yMWAw',
  PRESS_PAGE: '5UOx08rwzeKS0KKoMYgwUO',
  JOBS_PAGE: '3rVPwNKyEgiSOIuuwuYY4E',
  FUNDING_PAGE: '6P2MFZjyPSy6yK0qsI4qiq',
  NEWS_PAGE: '3U15QSz2sM8OiyCgQqcmeK',
  MEDIA_PAGE: '4nXDP1MiyI2QCkUaIUmU2E',
  GUILDS_PAGE: '56qiqlOGpyISgueKGWY6aU',
  INFLUENCERS_PAGE: '6c4h9IJgDCkwQwmkGmgaQa',

  DOWNLOAD_CLIENT_PAGE: '3DzQtyboqtA59RgRq7BfQS',

  FAIR_USE_POLICY: '3pQs9myUhVsRGUGaBUyVjX',
  PRIVACY_POLICY: '20kWrZyjDeZtwly8eXEahN',
  SHIPPING_POLICY: 'dbQyZ891CyDoiV0QEKW3s',

  RULES_OF_CONDUCT: '6WiNSdK0NThZvJ9cCEyr79',
  RULES_OF_CONDUCT_2_0: '2KNFjPKIUijrRen9CocDj0',
  RULES_OF_CONDUCT_2_1: '2wcOEbQCHzj4afvpkiATwV',

  TERMS_AND_CONDITIONS: '2yPvbTqih5NyWoktbMx74H',
  TERMS_AND_CONDITIONS_1_6: '6nNyiA6D6TiRiPLYUH5iW6',
  TERMS_AND_CONDITIONS_1_5: '242qc80hlBy0A5yDe9p3LC',
  TERMS_AND_CONDITIONS_1_4: '1DDgyJB6A6PAlSHErDCLjm',
  TERMS_AND_CONDITIONS_1_3: '6wTb0E10w4SSl121CTOQHh',
  TERMS_AND_CONDITIONS_1_2: '61IZnw9XRHyBIvwrQGluy3',
  TERMS_AND_CONDITIONS_1_1: '6gVmlkcA7lizWB1NiCVAVm',
  TERMS_AND_CONDITIONS_1_0: '3AyAdE4e2vLuy68n5lZagr',

  DATA_TRANSFER: '5kyyWB6sRJ7CR5zqY9EdeF',
  CUSTOMER_SUPPORT: '2zLAYpr6FaHpOGVzLDZVFt',

  EU_FAIR_USE_POLICY: '3pQs9myUhVsRGUGaBUyVjX',
  EU_PRIVACY_POLICY: '35nm5YGuukGMKG1X8NOPKs',
  EU_RULES_OF_CONDUCT: '2KNFjPKIUijrRen9CocDj0',
  EU_TERMS_AND_CONDITIONS: '2AADwE0PvgTlxlQ8dwzoaO',
  EU_RIGHT_OF_WITHDRAWAL: '5E9M9TnGy0Kb1d4dI6ASDC',
  EU_DATA_TRANSFER: '5mN3x9oh183elsvdZGqNO',
  EU_IMPRINT: '6o9cB6okQnYq9nCYUY5gNT',

  WIP: '6oDl5QjS9O0mkSiaOMC4CQ',
  VIP_TERMS_AND_CONDITIONS: '5JH3Q13mOnbNahqW4Ke1At',
  NEWS_MKTG_CALLOUT: 'EJjZlr7fIDqqaIt33n4gs',
  BLOGS_MKTG_CALLOUT: '4rTglpIgpl1hqi50xX0crw',
}

const staticImageIds = {
  ICON_CLASS_CHAMPION: '5EIeTW2Vs4ek8e2gecUYYc',
  ICON_CLASS_ASSASSIN: '3IA7V31ntYiC4qEkgCmgSU',
  ICON_CLASS_DRUID: '382BQ4zM52YYWSA6oWsWkK',
  ICON_CLASS_DUELIST: '6Gj6kowFnqk8QYyaMEQCeG',
  ICON_CLASS_CLERIC: '2RIsI7ILvO0uukwkWeW8eI',
  ICON_CLASS_MYRMIDON: '5KNgo2VqwgwuIKEICsSSQ0',
  ICON_CLASS_FROSTWEAVER: '4M1sCfMtqwwui8I2kWEgmM',
  ICON_CLASS_RANGER: '29h4tSmZ3qIOYs4mcOKc0',
  ICON_CLASS_CONFESSOR: '5AexevvPFKQs6kCy4IkEaQ',
  ICON_CLASS_KNIGHT: 'vitDqSLWxMskYGYwGWm4c',
  ICON_CLASS_TEMPLAR: '3AGjDCXZ60sYem042IUyYu',

  ICON_RACE_GUINECEAN: '49KbpUXI5yAOKKoK48aWs2',
  ICON_RACE_FAE: '4ECZPqCTqUqgQYGEMuCU0Y',
  ICON_RACE_MINOTAUR: '3Ut5RpIr9YeCOe8EAOy2Kq',
  ICON_RACE_HALFGIANT: '2YF7N4ffR60MecyMS0qiyE',
  ICON_RACE_WOODELF: '254OnsPRPCUIQIaE0sCW4w',
  ICON_RACE_HALFELF: '7Jfy8TIDtusGEGg20cQWc0',
  ICON_RACE_NETHARI: '5dKDSVsL5KsgS2oUWikOcY',
  ICON_RACE_STONEBORN: '4DDS9CGfgAyQIAO00UeQSE',
  ICON_RACE_CENTAUR: 'wgok65lqMggm2oyA0CwiK',
  ICON_RACE_ELKIN: '6gULYYAW64OykqmoqUicik',
  ICON_RACE_HIGHELF: '2pjAV47mlaY6Oqs8e6kYuY',
  ICON_RACE_HUMAN: '2e2OSjiMp6Ga4o0gO6CuE',

  ACCOUNT_FORUM_BADGE_POSITION: '4D6eaE4sKQmECkoiemKUoc',

  GUILD_MAIN_SEARCH: '1az3QlX3wUSE0yUumQgoE0',
  GUILD_MAIN_MEMBERS: '60kSpCyhziSCUKwOkeO4W6',
  GUILD_MAIN_BANK: '38hqtinHTW0CgIA4Iuki8k',
  GUILD_MAIN_SETTINGS: '1F9viU9L9qWQ2cgouAKsuw',
  GUILD_MAIN_NOTIFICATIONS: '4qoqNOoO0wagGkiGo8wcmc',

  MNTL_LOGO: '2gajaKqSq6cWX0fjiycfH1',
  MNTL_LOGO_REVERSE: '3GYDStUf0nqIwz2F9nlhhz',
}

const staticImageUrls = {
  HERO_BANNER_ASSET: '//web-cdn.crowfall.com/page_headers/Articles_Header-Hero.jpg',
  CLASSES_PAGE_HEADER: '//web-cdn.crowfall.com/page_headers/race_class_page_header.jpg',
  RACES_PAGE_HEADER: '//web-cdn.crowfall.com/page_headers/race_class_page_header.jpg',
  FAQ_PAGE_HEADER: '//web-cdn.crowfall.com/page_headers/race_class_page_header.jpg',
  BESTIARY_PAGE_HEADER: '//web-cdn.crowfall.com/page_headers/race_class_page_header.jpg',
  VALIDATE_EMAIL_PAGE_HEADER: '//web-cdn.crowfall.com/page_headers/Snowy+Siege+art.png',
  HORSE_MOUNTS_RACING_BANNER: '//web-cdn.crowfall.com/page_headers/horse-mounts-racing-banner.jpg',

  ECS_2021_LOGO: '//web-cdn.crowfall.com/marketing_assets/ECS_Logo_2021.png',
  LOGO_CROWFALL: '//web-cdn.crowfall.com/logos/logo-crowfall.png',
  LOGO_RAZER_WHITE: '//web-cdn.crowfall.com/logos/logo_razer_white.png',
  LOGO_RAZER_X_CROWFALL: '//web-cdn.crowfall.com/logos/razer-x-crowfall-logo.png',
  LOGO_TRAVIAN: '//web-cdn.crowfall.com/logos/logo-travian-small.png',
  MASTHEAD_VIDEO: '//web-cdn.crowfall.com/Masthead.mp4',
  MASTHEAD_LOOPED_VIDEO: '//web-cdn.crowfall.com/MastHead-Looped.mp4',
  MASTHEAD_IMG: '//web-cdn.crowfall.com/masthead-img.jpg',
  FOOTER_BANNER: '//web-cdn.crowfall.com/Footer_Banner.png',
  AWARD_GAME_INFORMER: '//web-cdn.crowfall.com/award-gameinformer.png',
  AWARD_IGN: '//web-cdn.crowfall.com/award-ign.png',
  AWARD_MMORPG: '//web-cdn.crowfall.com/award-mmorpg.png',
  GUILD_NON_MEMBER_BANNER: '//web-cdn.crowfall.com/guild/guild_non_member_banner.jpg',
  WE_ARE_RECRUITING: '//web-cdn.crowfall.com/guild/recruiting-label.png',
  VALIDATE_EMAIL_THUMB_ONE: '//web-cdn.crowfall.com/validate_email_page/ArticleThumb395x222-Validation2.jpg',
  VALIDATE_EMAIL_THUMB_TWO: '//web-cdn.crowfall.com/validate_email_page/ArticleThumb395x222-Validation3.jpg',
  CHECKOUT_MARKETING_CTA: '//web-cdn.crowfall.com/marketing_assets/front_entry_mural.jpeg',
  CHECKOUT_MARKETING_THUMB_ONE: '//web-cdn.crowfall.com/marketing_assets/treasure_the_dark.jpg',
  CHECKOUT_MARKETING_THUMB_TWO: '//web-cdn.crowfall.com/marketing_assets/ice_still.jpg',
  BLACK_BANNER: '//web-cdn.crowfall.com/black-banner.png',

  STAR_LEFT: '//web-cdn.crowfall.com/home/Web_TrialBanner_Header_Leftedge_01.png',
  STAR_RIGHT: '//web-cdn.crowfall.com/home/Web_TrialBanner_Header_Rightedge_01.png',
  STAR_MIDDLE: '//web-cdn.crowfall.com/home/Web_TrialBanner_Header_Center_01.png',

  STAR_BODY_LEFT: '//web-cdn.crowfall.com/home/Web_TrialBanner_Body_Leftedge_01.png',
  STAR_BODY_RIGHT: '//web-cdn.crowfall.com/home/Web_TrialBanner_Body_Rightedge_01.png',
  STAR_BODY_MIDDLE: '//web-cdn.crowfall.com/home/Web_TrialBanner_Body_Center_01.png',

  STAR_FOOTER_LEFT: '//web-cdn.crowfall.com/home/Web_TrialBanner_Footer_Leftedge_01.png',
  STAR_FOOTER_RIGHT: '//web-cdn.crowfall.com/home/Web_TrialBanner_Footer_Rightedge_01.png',
  STAR_FOOTER_MIDDLE: '//web-cdn.crowfall.com/home/Web_TrialBanner_Footer_Center_01.png',

  STAR_MOBILE_HEADER: '//web-cdn.crowfall.com/home/Web_TrialBanner_Header_Leftedge_02.png',

  SITE_BACKGROUND_ACCENT: '//web-cdn.crowfall.com/general_web_layout/HeaderFooterRadialAccent.png',
  SITE_BACKGROUND_ACCENT_FOOTER: '//web-cdn.crowfall.com/general_web_layout/StonebornFooterAccent_1500X80.png',
  HEADER_ACCENT: '//web-cdn.crowfall.com/general_web_layout/BannerAccent_Stoneborn.png',
  CONTENT_BOX_TOP_ACCENT: '//web-cdn.crowfall.com/general_web_layout/Content_Box_Header(1333X50).png',
  CONTENT_BOX_SECTION_ACCENT: '//web-cdn.crowfall.com/general_web_layout/Content_Box_Section_Header(1333X133).png',
  TITLE_BAR_ACCENT: '//web-cdn.crowfall.com/general_web_layout/HeaderBarAccent_Stoneborn_01.png',
  TITLE_BAR_LEFT: '//web-cdn.crowfall.com/general_web_layout/TitleBar_left.png',
  TITLE_BAR_LEFT_VERTICAL: '//web-cdn.crowfall.com/general_web_layout/TitleBar_left_vertical.png',
  TITLE_BAR_RIGHT: '//web-cdn.crowfall.com/general_web_layout/TitleBar_right.png',
  TITLE_BAR_RIGHT_VERTICAL: '//web-cdn.crowfall.com/general_web_layout/TitleBar_right_vertical.png',
  TITLE_BAR_CENTER: '//web-cdn.crowfall.com/general_web_layout/TitleBar_center.png',
  TITLE_BAR_CENTER_120X10: '//web-cdn.crowfall.com/general_web_layout/TitleBar_center_120X10.png',
  TITLE_BAR_CENTER_VERTICAL: '//web-cdn.crowfall.com/general_web_layout/TitleBar_center_vertical.png',
  TITLE_BAR_CENTER_VERTICAL120X10: '//web-cdn.crowfall.com/general_web_layout/TitleBar_center_vertical120X10.png',
  PORTRAIT_CARD_FRAME: '//web-cdn.crowfall.com/general_web_layout/PortraitCard_Frame.png',
  VIP_ICON_GOLD: '//web-cdn.crowfall.com/general_web_layout/VIP-Icon.png',
  VIP_LOGO: '//web-cdn.crowfall.com/general_web_layout/VIP-Logo.png',

  PARTNER_IMAGE_SPACER_X: '//web-cdn.crowfall.com/marketing_assets/partner-image-spacer-x.png',

  VIP_1_MONTH_USD: '//web-cdn.crowfall.com/VIP-pack-banners/1-month-USD.png',
  VIP_1_MONTH_EUR: '//web-cdn.crowfall.com/VIP-pack-banners/1-month-EUR.png',
  VIP_1_MONTH_GBP: '//web-cdn.crowfall.com/VIP-pack-banners/1-month-GBP.png',
  VIP_1_MONTH_BRL: '//web-cdn.crowfall.com/VIP-pack-banners/1-month-BRL.png',
  VIP_3_MONTH_USD: '//web-cdn.crowfall.com/VIP-pack-banners/3-month-USD.png',
  VIP_3_MONTH_EUR: '//web-cdn.crowfall.com/VIP-pack-banners/3-month-EUR.png',
  VIP_3_MONTH_GBP: '//web-cdn.crowfall.com/VIP-pack-banners/3-month-GBP.png',
  VIP_3_MONTH_BRL: '//web-cdn.crowfall.com/VIP-pack-banners/3-month-BRL.png',
  VIP_6_MONTH_USD: '//web-cdn.crowfall.com/VIP-pack-banners/6-month-USD.png',
  VIP_6_MONTH_EUR: '//web-cdn.crowfall.com/VIP-pack-banners/6-month-EUR.png',
  VIP_6_MONTH_GBP: '//web-cdn.crowfall.com/VIP-pack-banners/6-month-GBP.png',
  VIP_6_MONTH_BRL: '//web-cdn.crowfall.com/VIP-pack-banners/6-month-BRL.png',
  VIP_12_MONTH_USD: '//web-cdn.crowfall.com/VIP-pack-banners/12-month-USD.png',
  VIP_12_MONTH_EUR: '//web-cdn.crowfall.com/VIP-pack-banners/12-month-EUR.png',
  VIP_12_MONTH_GBP: '//web-cdn.crowfall.com/VIP-pack-banners/12-month-GBP.png',
  VIP_12_MONTH_BRL: '//web-cdn.crowfall.com/VIP-pack-banners/12-month-BRL.png',

  DECAPATHON_RIBBON_SUN: '//web-cdn.crowfall.com/decapathon/sun-banner.png',
  DECAPATHON_RIBBON_EARTH: '//web-cdn.crowfall.com/decapathon/earth-banner.png',
  DECAPATHON_RIBBON_MOON: '//web-cdn.crowfall.com/decapathon/moon-banner.png',
  DECAPATHON_SKULL_SUN: '//web-cdn.crowfall.com/decapathon/sun-skull.png',
  DECAPATHON_SKULL_EARTH: '//web-cdn.crowfall.com/decapathon/earth-skull.png',
  DECAPATHON_SKULL_MOON: '//web-cdn.crowfall.com/decapathon/moon-skull.png',

  ICON_DISCIPLINE_LEFT_BLUE: '//web-cdn.crowfall.com/race_class_icons/discipline_left_blue.png',
  ICON_DISCIPLINE_RIGHT_BLUE: '//web-cdn.crowfall.com/race_class_icons/discipline_right_blue.png',
  ICON_DISCIPLINE_TARGET: '//web-cdn.crowfall.com/race_class_icons/discipline_target.png',
  ICON_DISCIPLINE_TRIANGLE: '//web-cdn.crowfall.com/race_class_icons/discipline_triangle.png',
  ICON_DISCIPLINE_SILVER: '//web-cdn.crowfall.com/race_class_icons/discipline_silver.png',

  ICON_CLASS_CHAMPION: '//web-cdn.crowfall.com/race_class_icons/CF_Class_champion.png',
  ICON_CLASS_ASSASSIN: '//web-cdn.crowfall.com/race_class_icons/CF_Class_assassin.png',
  ICON_CLASS_DRUID: '//web-cdn.crowfall.com/race_class_icons/CF_Class_druid.png',
  ICON_CLASS_DUELIST: '//web-cdn.crowfall.com/race_class_icons/CF_Class_duelist.png',
  ICON_CLASS_CLERIC: '//web-cdn.crowfall.com/race_class_icons/CF_Class_cleric.png',
  ICON_CLASS_MYRMIDON: '//web-cdn.crowfall.com/race_class_icons/CF_Class_myrmidon.png',
  ICON_CLASS_FROSTWEAVER: '//web-cdn.crowfall.com/race_class_icons/CF_Class_frostweaver.png',
  ICON_CLASS_RANGER: '//web-cdn.crowfall.com/race_class_icons/CF_Class_ranger.png',
  ICON_CLASS_CONFESSOR: '//web-cdn.crowfall.com/race_class_icons/CF_Class_confessor.png',
  ICON_CLASS_KNIGHT: '//web-cdn.crowfall.com/race_class_icons/CF_Class_knight.png',
  ICON_CLASS_TEMPLAR: '//web-cdn.crowfall.com/race_class_icons/CF_Class_templar.png',

  ICON_RACE_GUINECEAN: '//web-cdn.crowfall.com/race_class_icons/CF_Race_guinecean.png',
  ICON_RACE_FAE: '//web-cdn.crowfall.com/race_class_icons/CF_Race_fae.png',
  ICON_RACE_MINOTAUR: '//web-cdn.crowfall.com/race_class_icons/CF_Race_minotaur.png',
  ICON_RACE_HALFGIANT: '//web-cdn.crowfall.com/race_class_icons/CF_Race_halfgiant.png',
  ICON_RACE_WOODELF: '//web-cdn.crowfall.com/race_class_icons/CF_Race_woodelf.png',
  ICON_RACE_HALFELF: '//web-cdn.crowfall.com/race_class_icons/CF_Race_halfelf.png',
  ICON_RACE_NETHARI: '//web-cdn.crowfall.com/race_class_icons/CF_Race_nethari.png',
  ICON_RACE_STONEBORN: '//web-cdn.crowfall.com/race_class_icons/CF_Race_stoneborn.png',
  ICON_RACE_CENTAUR: '//web-cdn.crowfall.com/race_class_icons/CF_Race_centaur.png',
  ICON_RACE_ELKIN: '//web-cdn.crowfall.com/race_class_icons/CF_Race_elkin.png',
  ICON_RACE_HIGHELF: '//web-cdn.crowfall.com/race_class_icons/CF_Race_highelf.png',
  ICON_RACE_HUMAN: '//web-cdn.crowfall.com/race_class_icons/CF_Race_human.png',
}

const staticVideoIds = {
  HOME_PAGE_VIDEO: '2DF97saiJKqw8ya28UGuuq',
}

const assets = {
  contentfulIds,
  staticImageIds,
  staticImageUrls,
  staticVideoIds,
}

export default assets
