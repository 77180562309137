import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../../../strings'
import LocLink from '../../../../componentLibrary/Elements/LocLink'
import Button from '../../../../componentLibrary/Fragments/Button'
import './styles.scss'
import {fetchGuildDetails} from "../../../../redux/actions/guild-management";
import {chargeWithSolution, generateBuyNowWithOverrideCheckout} from "../../../../redux/actions/crowfall-store";


const mapStateToProps = state => {
    return {
        language: state.language,
        user: state.user.userProfile,
        guildWallet: state.guildManagement.guildWallet,
        guildInfo: state.guildManagement.guild,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchGuildDetails: () => dispatch(fetchGuildDetails()),
        generateBuyNowWithOverrideCheckout: (itemId, language, upgradeId, targetUsername, walletId, address, guildId) => dispatch(generateBuyNowWithOverrideCheckout(itemId, language, upgradeId, targetUsername, walletId, address, guildId)),
        chargeWithSolution: (id) => dispatch(chargeWithSolution(id))
    }
}

class GuildBuyNowButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            goToCheckout: false,
            goToGuildBank: false,
            selectValue: this.props.user.username,
        }
        this.handleBuyNow = this.handleBuyNow.bind(this)
    }


    handleSelectChange = (event) => {
        this.setState({
            selectValue: event.target.value
        }, () => {
            console.log(this.state.selectValue)
        });
    }

    async handleCheckout() {
        const { item, generateBuyNowWithOverrideCheckout, chargeWithSolution, guildWallet, user } = this.props
        const solution = await generateBuyNowWithOverrideCheckout(item.item_id, 'en-US', null, this.state.selectValue, guildWallet.walletId, null, null)
        await chargeWithSolution(solution.checkout_solution_id)
        let successString = this.state.selectValue === user.username ? 'Claimed using guild funds' : 'Gifted to user ' + this.state.selectValue
        swal({
            content: <div className="external__message">
                {successString}
            </div>,
            icon: 'success'
        });
    }

    async handleBuyNow() {
        const { item, guildWallet, guildInfo } = this.props
        const { amount, balance } = guildWallet
        const canBuy = item.purchase_amount <= amount
        const guildMembers = guildInfo.members.map(b => b.username)
        const memberOptions = x => {
            return <option key={x} value={x}>{x}</option>
        }

        // Modal content that includes the guild member gift select element needs to be created outside of the SweetAlert plugin and inserted in order to handle onChange events.
        let modalContent = document.createElement('div')
        ReactDOM.render(<div>{strings.getString("CURRENT_BALANCE_AMOUNT", `Current Balance: ${balance}`, { balance })} <div className="swal-custom--gift-to-guild-member"><div className="FieldInput__label">{strings.getString("SELECT_GUILD_MEMBER_FOR_GIFT", "Select guild member to purchase item for:")}</div><select name="userSelect" id="userSelect" onChange={this.handleSelectChange.bind(this)}>{guildMembers.map(memberOptions)}</select></div></div>, modalContent)

        if (canBuy) {
            const val = await swal({
                title: strings.getString("BUY_WITH_GUILD_WALLET", "Buy with Guild Wallet"),
                content: modalContent,
                buttons: {
                    cancel: strings.getString("CANCEL", "Cancel"),
                    buy: {
                        text: 'Get Now',
                        value: 'buy',
                    }
                }
            })
            if (val === 'buy') {
                this.recordProductClick(item)
                // this.setState({ goToCheckout: true })
                await this.handleCheckout()
                
            }
        } else {
            const val = await swal ({
                title: strings.getString("INSUFFICIENT_FUNDS", "Insufficient Funds"),
                content: (
                    <div>{strings.getString("CURRENT_BALANCE_AMOUNT", `Current Balance: ${balance}`, { balance })}</div>
                ),
                buttons: {
                    cancel: strings.getString("CANCEL", "Cancel"),
                    buy: {
                        text: strings.getString("PURCHASE_CROWNS", "Purchase Crowns"),
                        value: 'buy',
                    }
                }
            })
            if (val === 'buy') {
                this.recordProductClick(item)
                this.setState({ goToGuildBank: true })
            }
        }
    }

    recordProductClick(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "select_content",{
            "content_type": "product",
            "items": [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                }
            ]
        })
    }

    render() {

        const { goToCheckout, goToGuildBank } = this.state
        const { medium, large, item, user, guildWallet, language, location } = this.props
        const returnPath = btoa(`${location.pathname}${location.search}`)
        const guildPurchasePath = user.username === this.state.selectValue ? `/${language}/checkout/${item.item_id}?walletId=${guildWallet.walletId}` :  `/${language}/checkout/${item.item_id}?walletId=${guildWallet.walletId}&gift=true&targetUsername=${this.state.selectValue}`

        return (
            <React.Fragment>

                {/*{goToCheckout &&<Redirect to={guildPurchasePath}/>}*/}
                {/*{goToGuildBank && <Redirect to={`/${language}/guild/bank`}/>}*/}

                {user && user.username ? (
                    <>
                        {medium && <Button medium blue onclick={this.handleBuyNow} label='Guild Get Now' />}
                        {large && <Button fill blue onclick={this.handleBuyNow} label='Guild Get Now' />}
                    </>
                ) : (
                    <LocLink to={`/login?r=${returnPath}`}>
                        {medium && <Button blue label='Guild Get Now' />}
                        {large && <Button fill blue label='Guild Get Now' />}
                    </LocLink>
                )}


            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(GuildBuyNowButton))
