import {
    SET_STORE_DETAILS,
    SET_SELECTED_STORE_CATEGORY,
    SEARCH_CATALOG,
    CLEAR_CATALOG_SEARCH_CRITERIA,
    CLEAR_CROWFALL_STORE,
    SET_USER_CART,
    SET_CART_SOLUTION,
    SET_SHIPPING_DATA,
    EDIT_SHIPPING_DATA,
    CLEAR_USER_SOLUTIONS,
} from '../actionTypes'

import _ from 'lodash'
import { api } from '../../utilities/api'


// API CALLS

export const fetchStoreDetails = (session, language, skusToFilter) => {
    return async function(dispatch) {
        let details = []
        if (session) {
            const res = await api.get(`/store/catalog-summary?lang=${language}`)
            if (res.status === 200) details = res.data
        } else {
            const res = await api.get(`/public/store/catalog-summary?lang=${language}`)
            if (res.status === 200) details = res.data
        }

        let modeledDetails = modelStoreDetails(details, skusToFilter)
        dispatch(setStoreDetails(modeledDetails))
    }
}

export const getSingleStoreProduct = (id, language) => {
    return async function() {
        const item = await api.get(`/store/catalog-item/${id}?lang=${language}`)
        return item.data
    }
}

export const fetchUserCart = () => {
    return async function(dispatch) {
        const solution = await api.get('/store/solution-cart')
        const modelledCart = modelUserCart(solution.data)
        dispatch(setUserCart(modelledCart))
    }
}

export const addItemToUserCart = (itemId) => {
    return async function(dispatch) {
        const newSolution = await api.post(`/store/solution-cart-product/${itemId}`)
        const modelledCart = modelUserCart(newSolution.data)
        dispatch(setUserCart(modelledCart))
    }
}

export const removeItemFromUserCart = (solutionId, cart) => {
    return async function(dispatch) {
        await api.delete(`/store/solution-cart/${solutionId}`)
        dispatch(fetchUserCart())
    }
}

export const generateDeferredCheckout = (deferredId, amount) => {
    return async function() {
        const solution = await api.post(`/store/deferred-purchase/${deferredId}/payment`, {
            deferred_amount: amount,
        })
        return solution.data
    }
}

export const generateBuyNowCheckout = (itemId, language, upgradeId, targetUsername, walletId, shippingAddress, guildId) => {
    return async function() {
        let path = `/store/checkout/${itemId}?lang=${language}`
        if (upgradeId) path += `&upgrade_id=${upgradeId}`
        if (guildId) path += `&target_guild_id=${guildId}`
        if (walletId) path += `&wallet=${walletId}`
        let payload = !_.isEmpty(shippingAddress) ? { shipping_address: { ...shippingAddress } } : {}
        if (targetUsername) payload.target_user = { username: targetUsername }
        const solution = await api.post(path, payload)
        return solution.data
    }
}

export const generateBuyNowWithOverrideCheckout = (itemId, language, upgradeId, targetUsername, walletId, shippingAddress, guildId) => {
    return async function() {
        let path = `/store/checkout/${itemId}?lang=${language}&override_entitlement_check=true`
        if (upgradeId) path += `&upgrade_id=${upgradeId}`
        if (guildId) path += `&target_guild_id=${guildId}`
        if (walletId) path += `&wallet=${walletId}`
        let payload = !_.isEmpty(shippingAddress) ? { shipping_address: { ...shippingAddress } } : {}
        if (targetUsername) payload.target_user = { username: targetUsername }
        const solution = await api.post(path, payload)
        return solution.data
    }
}

export const generateCheckoutFromCart = () => {
    return async function(dispatch) {
        const solution = await api.post('/store/checkout-cart')
        dispatch(setCartSolution(solution.data))
    }
}

export const chargeWithSolution = (solutionId) => {
    return async function() {
        const res = await api.post(`/store/charge-with-solution/${solutionId}`, {
            wallet: {},
        })
        return res.data
    }
}

export const generatePaymentTokenVars = (solutionId, isRecurring) => {
    return async function() {
        const res = await api.post(`/store/charge-with-solution/${solutionId}`, { xsolla: { subscribe: isRecurring } })
        return {
            token: _.result(res.data, 'transaction.paywall.access_token'),
            sandbox: _.result(res.data, 'transaction.paywall.sandbox')
        }
    }
}


// DISPATCH ACTION TYPE METHODS

export const setStoreDetails = details => {
    return (dispatch) => {
        dispatch({
            type: SET_STORE_DETAILS,
            payload: { ...details }
        })
        dispatch(searchCatalog())
    }
}

export const setSelectedCategory = category => ({
    type: SET_SELECTED_STORE_CATEGORY,
    payload: category,
})

export const searchCatalog = criteria => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_CATALOG,
            payload: criteria,
        })
    }
}

export const clearCatalogSearchCriteria = () => ({
    type: CLEAR_CATALOG_SEARCH_CRITERIA
})

export const clearStoreDetails = () => ({
    type: CLEAR_CROWFALL_STORE
})

export const setUserCart = cart => ({
    type: SET_USER_CART,
    payload: { ...cart }
})

export const setCartSolution = cart => ({
    type: SET_CART_SOLUTION,
    payload: cart
})

export const setShippingAddress = data => ({
    type: SET_SHIPPING_DATA,
    payload: data,
})

export const initiateShippingAddressEdit = () => ({
    type: EDIT_SHIPPING_DATA,
})

export const clearUserSolutions = () => ({
    type: CLEAR_USER_SOLUTIONS,
})


// HELPER FUNCTIONS

const modelStoreDetails = (items, skusToFilter) => {
    const catalog = _.orderBy((items || []).map(item => {
        item.heroImage = _.result(item, 'images.0') || '/images/no_item.png'
        item.hasUpgrade = !_.isEmpty(item.upgrades)
        item.limitedTime = _.includes(item.tags, "limited_time")

        let categories = _.result(item, 'primary_categories', []).map(category => {
            return category.category_key
        })
        let subcategories = _.result(item, 'secondary_categories', []).map(subcategory => {
            return subcategory.category_key
        })
        item.categorySet = _.uniq(categories)
        item.subcategorySet = _.uniq(subcategories)
        return item
    }).filter(item => {
        const skusToFilterIncludesItem = _.isArray(skusToFilter) && skusToFilter.includes(item.item_id)
        return item.purchasable && !skusToFilterIncludesItem
    }), ['featured', 'weight', 'purchase_amount'], ['desc', 'desc', 'asc'])

    let featuredCatalog = []
    let ltoCatalog = []
    let bestValueCatalog = []
    let onSaleCatalog = []

    let shopCategories = {}

    catalog.forEach(item => {
        const { primary_categories, secondary_categories, featured, limitedTime, best_value, sale_percent } = item
        if (_.isEmpty(primary_categories)) return
        const itemSubcategories = secondary_categories ? secondary_categories.map (sub => {
            return {
                ...sub,
                slug: _.kebabCase(sub.category_key),
            }
        }) : []
        primary_categories.forEach(category => {
            const { category_key: key, display_strings, inventory_category_definition_id: id } = category
            if (_.isEmpty(shopCategories[id])) {
                shopCategories[id] = {
                    id,
                    key,
                    slug: id,
                    display_strings: { ...display_strings },
                    subcategories: itemSubcategories ? [ ...itemSubcategories ] : []
                }
            } else {
                const missingSubcategories = itemSubcategories.filter(subcategory => {
                    return _.isEmpty(_.find(shopCategories[id].subcategories, { "category_key": subcategory.category_key }))
                })
                shopCategories[id].subcategories = [ ...shopCategories[id].subcategories, ...missingSubcategories ]
            }
        })

        if (featured) featuredCatalog.push(item)
        if (limitedTime) ltoCatalog.push(item)
        if (best_value) bestValueCatalog.push(item)
        if (sale_percent > 0) onSaleCatalog.push(item)
    })

    const tags = _.uniq(_.flatten([...catalog.map(i => {
        if (!_.isEmpty(i.tags)) {
          return i.tags
        }
        return null
    })]))

    const currencies = _.uniqBy(catalog.map(item => {
        return { currency: item.purchase_currency, description: item.currency_description }
    }), 'currency')
    const modeledDetails = {
        catalog,
        featuredCatalog,
        ltoCatalog,
        bestValueCatalog,
        onSaleCatalog,
        categories: shopCategories,
        tags,
        currencies
    }
    return modeledDetails
}

const modelUserCart = (cartSolution) => {
    const { solutions, display_strings, subtotal, currency } = cartSolution
    const cartSubtotal = { amount: subtotal, string: _.result(display_strings, "subtotal") }
    const cartCurrency = currency ? currency : null
    const cartSolutions = solutions && solutions.length > 0 ? solutions.map(solution => {
        const { checkout_solution_id, item_id, item_name, display_strings, images, discountable } = solution
        const priceString = display_strings ? display_strings.amount : null
        const imgUrl = images && images.length > 0 ? images[0] : null
        return {
            ...solution,
            solutionId: checkout_solution_id,
            itemId: item_id,
            name: item_name,
            price: priceString,
            imgUrl,
            discountable,
        }
    }) : []
    return { cartSolutions, cartSubtotal, cartCurrency }
}
