import {
    ADD_ARTICLES,
    SEARCH_ARTICLES,
    CLEAR_ARTICLES,
    PAGINATE_ARTICLES,
    CLEAR_ARTICLES_SEARCH_CRITERIA
} from '../actionTypes'

import contentful from '../../utilities/contentful'
import _ from 'lodash'
import moment from 'moment'


export const fetchArticles = () => {
    return async function (dispatch) {
        const add = (articles) => {
            const newArticles = articles.map(article => {
                article.bannerAssetUrl = _.result(article, 'bannerAsset.assetUrl')
                article.thumbUrl = _.result(article, 'thumb.assetUrl')
                article.formattedDate = moment(article.date).format('MMMM Do, YYYY')
                article.monthYear = moment(article.date).format('MMMM YYYY')
                return article
            })
            dispatch(addArticles(newArticles))
        }
        const options = {
            contentType: 'article',
            match: null,
            language: undefined,
            extra: 'order=-fields.date',
            skip: 0,
            limit: 50,
        }
        contentful.getPaginatedEntries(options, add)
    }
}

const addArticles = articles => {
    return (dispatch) => {
        dispatch({
            type: ADD_ARTICLES,
            payload: articles
        })
        dispatch(searchArticles())
    }
}

export const searchArticles = criteria => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_ARTICLES,
            payload: criteria
        })
        dispatch(paginateArticles())
    }
}

export const clearArticlesSearchCriteria = () => ({
    type: CLEAR_ARTICLES_SEARCH_CRITERIA
})

export const clearArticles = () => ({
    type: CLEAR_ARTICLES
})

export const paginateArticles = pagination => ({
    type: PAGINATE_ARTICLES,
    pagination
})