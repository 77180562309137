import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../../strings'

import FieldDropdown from '../../../componentLibrary/Models/FieldDropdown'
import FieldInput from '../../../componentLibrary/Models/FieldInput'
import Button from '../../../componentLibrary/Fragments/Button'

import { setShippingAddress } from '../../../redux/actions/crowfall-store'
import './styles.scss'


const mapStateToProps = state => {
    return {
        shippingAddress: state.crowfallStore.shippingAddress,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShippingAddress: (data) => dispatch(setShippingAddress(data))
    }   
}

class ShippingCollectionForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            first_name: null,
            last_name: null,
            street_address: null,
            extended_address: null,
            city: null,
            region: null,
            country: null,
            postal_code: null,
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.submitShippingAddress = this.submitShippingAddress.bind(this)
    }

    componentDidMount() {
        const { shippingAddress } = this.props
        if (!_.isEmpty(shippingAddress)) this.setState({
            first_name: shippingAddress.first_name || null,
            last_name: shippingAddress.last_name || null,
            street_address: shippingAddress.street_address || null,
            extended_address: shippingAddress.extended_address || null,
            city: shippingAddress.city || null,
            region: shippingAddress.region || null,
            country: shippingAddress.country || null,
            postal_code: shippingAddress.postal_code || null,
        }) 
    }

    handleFieldChange(value, name) {
        this.setState({ [name]: value })
    }

    async submitShippingAddress() {
        this.setState({ loading: true })

        const { first_name, last_name, street_address, extended_address, city, region, country, postal_code } = this.state
        await this.props.setShippingAddress({
            first_name, last_name, street_address, extended_address, city, region, country, postal_code
        })
        this.setState({ loading: false })
    }

    generateFormErrorModal() {
        swal({
            title: strings.getString("UH_OH", "Uh Oh!"),
            icon: "error",
            content: (
                <div dangerouslySetInnerHTML={{ __html: strings.getString("ERROR_SUBMITTING_FORM", "There was an error when attempting to submit.<br/>Please address the issue(s) noted on your form and try again.") }} />
            )
        })
    }

    validateFormInputs() {
        const { first_name, last_name, street_address, city, region, country, postal_code } = this.state
        const isNotValid = 
            _.isEmpty(first_name) || _.isEmpty(last_name) || _.isEmpty(street_address) ||
            _.isEmpty(city) || _.isEmpty(region) || _.isEmpty(country) || _.isEmpty(postal_code)
        return isNotValid
    }

    render() {

        const { loading, first_name, last_name, street_address, extended_address, city, region, country, postal_code } = this.state
        const { countryOptions } = this.props

        return (
            <div className="ShippingCollectionForm">

                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>

                <div className="ShippingCollectionForm__form">
                    <div className="UserDataCollectionForm__header">{strings.getString("SHIPPING_ADDRESS", "Shipping Address")}</div>

                    <div className="ShippingCollectionForm__field">
                        <FieldInput label={strings.getString("FIRST_NAME", "First Name")} name="first_name" onChange={this.handleFieldChange} value={first_name} />
                    </div>
                    <div className="ShippingCollectionForm__field">
                        <FieldInput label={strings.getString("LAST_NAME", "Last Name")} name="last_name" onChange={this.handleFieldChange} value={last_name} />
                    </div>
                    <div className="ShippingCollectionForm__field">
                        <FieldInput 
                            label={strings.getString("ADDRESS_LINE_1", "Address Line 1")}
                            sublabel={strings.getString("ADDRESS_LINE_1_SUB", "Street address, P.O. box, company name, c/o")}
                            name="street_address" onChange={this.handleFieldChange} value={street_address} 
                        />
                    </div>
                    <div className="ShippingCollectionForm__field">
                        <FieldInput 
                            label={strings.getString("ADDRESS_LINE_2_OPTIONAL", "Address Line 2 (optional)")}
                            sublabel={strings.getString("ADDRESS_LINE_2_SUB", "Apartment, suite, unit, building, floor, etc.")}
                            name="extended_address" onChange={this.handleFieldChange} value={extended_address} 
                        />
                    </div>
                    <div className="ShippingCollectionForm__field">
                        <FieldInput label={strings.getString("CITY", "City")} name="city" onChange={this.handleFieldChange} value={city} />
                    </div>
                    <div className="ShippingCollectionForm__field">
                        <FieldInput label={strings.getString("STATE_REGION", "State/Region")} name="region" above onChange={this.handleFieldChange} value={region} />
                    </div>
                    <div className="ShippingCollectionForm__field">
                        <FieldDropdown
                            label={strings.getString("COUNTRY", "Country")} name="country" above
                            selectOne={this.handleFieldChange} value={country} options={countryOptions}
                        />
                    </div>
                    <div className="UserDataCollectionForm__field">
                        <FieldInput
                            label={("POSTAL_CODE", "Postal Code")} name="postal_code"
                            type="number" onChange={this.handleFieldChange} value={postal_code}
                        />
                    </div>

                    <div className="ShippingCollectionForm__button">
                        <Button label={strings.getString("ADD_SHIPPING_ADDRESS", "Add Shipping Address")} disabled={this.validateFormInputs()} onclick={this.submitShippingAddress}/>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingCollectionForm)