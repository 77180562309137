import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import assets from '../../assets'
import contentful from '../../utilities/contentful'
import strings from '../../strings'
import { setModalContent } from '../../redux/actions'
import { fetchStoreDetails } from '../../redux/actions/crowfall-store'
import {
    fetchVIPSubscriptionDetails, fetchSubscriptionPaymentMethod, cancelUsersSubscription, clearSubscriptionDetails, pauseVIPConsumption, resumeVipConsumption,
} from '../../redux/actions/subscription'
import ProductGrid from '../../Store/ShopPage/ProductGrid/index'
import UpdateSubscriptionTypeModal from '../../componentLibrary/Models/UpdateSubscriptionTypeModal'
import DisplayField from '../../componentLibrary/Fragments/DisplayField'
import Button from '../../componentLibrary/Fragments/Button'
import moment from 'moment-timezone'
import './styles.scss'



const mapStateToProps = (state) => {
    return {
        session: state.session,
        language: state.language,
        catalog: state.crowfallStore.catalog,
        subscriptionInitialFetch: state.subscription.subscriptionInitialFetch,
        paymentInitialFetch: state.subscription.paymentInitialFetch,
        paymentMethod: state.subscription.subscriptionPaymentDetails,
        subscriptionDetails: state.subscription.subscriptionDetails,
        hasActiveRecurringPurchase: state.subscription.hasActiveRecurringPurchase,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setModalContent: (content) => dispatch(setModalContent(content)),
        fetchStoreDetails: (session, language) => dispatch(fetchStoreDetails(session, language)),
        fetchVIPSubscriptionDetails: () => dispatch(fetchVIPSubscriptionDetails()),
        fetchSubscriptionPaymentMethod: (session) => dispatch(fetchSubscriptionPaymentMethod(session)),
        cancelUsersSubscription: () => dispatch(cancelUsersSubscription()),
        clearSubscriptionDetails: () => dispatch(clearSubscriptionDetails()),
        pauseVIPConsumption: () => dispatch(pauseVIPConsumption()),
        resumeVipConsumption: () => dispatch(resumeVipConsumption()),
    }
}

class AccountVipPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            vipCatalog: [],
            termsAndConditions: null,
        }
        this.getVipPacks = this.getVipPacks.bind(this)
        this.filterVipPacks = this.filterVipPacks.bind(this)
        this.handleUpdateSubscription = this.handleUpdateSubscription.bind(this)
        this.handleCancelSubscription = this.handleCancelSubscription.bind(this)
        this.handlePauseConsumption = this.handlePauseConsumption.bind(this)
        this.handleResumeConsumption = this.handleResumeConsumption.bind(this)
    }


    async componentDidMount() {
        this.setState({ loading: true })
        await this.props.fetchVIPSubscriptionDetails()
        await this.getVipPacks()
        await this.props.fetchSubscriptionPaymentMethod()
        const vipTerms = await contentful.getEntry(assets.contentfulIds.VIP_TERMS_AND_CONDITIONS)
        this.setState({ loading: false, termsAndConditions: vipTerms.richText })
    }

    componentDidUpdate(prevProps) {
        const { subscriptionDetails: prevDetials } = prevProps
        const { subscriptionDetails: currDetails } = this.props
        if (!_.isEqual(prevDetials.subscription, currDetails.subscription)) this.filterVipPacks()
    }

    async getVipPacks() {
        await this.props.fetchStoreDetails(this.props.session, this.props.language)
        this.filterVipPacks()
    }

    filterVipPacks() {
        const currSubscriptionId = _.result(this.props.subscriptionDetails, "subscription.subscription_definition.item_id")
        const vipCatalog = this.props.catalog.filter(product => product.categorySet.includes("VIP") && _.result(product, "external_ids.is_subscription") && product.item_id !== currSubscriptionId)
        this.setState({ vipCatalog })
    }

    async handleCancelSubscription() {
        const val = await swal({
            icon: "warning",
            title: strings.getString("WARNING_CANCEL_VIP", "You are about to cancel your VIP Membership."),
            content: (
                <div>{strings.getString("VIP_MEMBERSHIP_CANCELLATION_EXPLANATION", "Cancelling your membership will not affect your current VIP end date; and any remaining VIP service months on your account will be used until there are none left.")}</div>
            ),
            buttons: {
                cancel: strings.getString("CLOSE", "Close"),
                continue: strings.getString("CANCEL_MEMBERSHIP", "Cancel Membership"),
            }
        })
        if (val === "continue") this.cancelSubscription()
    }

    async cancelSubscription(){
        try {
            await this.props.cancelUsersSubscription()
            swal({
                icon: 'success',
                title: strings.getString("SUCCESSFULLY_CANCELLED_MEMBERSHIP", "Successfully cancelled membership."),
            })
            await this.props.fetchVIPSubscriptionDetails()
        } catch (err){
            swal({
                icon: 'error',
                title: strings.getString("UH_OH", "Uh Oh!"),
                content: (<div>{strings.getString("VIP_ERROR_MESSAGE_SUB_CANCEL", "There was an error canceling your current membership. Please try again.")}</div>),
            })
        }

    }

    async handlePauseConsumption() {
        const val = await swal({
            icon: 'warning',
            title: strings.getString("VIP_PAUSE_IN_PROGRESS", "You are about to pause your VIP Service."),
            content: (
                <div style={{ "textAlign": "left" }}>
                    {strings.getString("VIP_PAUSE_EXPLANATION", "Pausing your service will stop future consumption of any VIP Services on your account; it will not affect the VIP service active on your account.")}
                    <br/>
                    <br/>
                    {strings.getString("VIP_PAUSE_CANCELS_SUBSCRIPTION", "Once your service is paused, any recurring payments for VIP will be cancelled.")}
                </div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                pause: strings.getString("PAUSE_SERVICE", "Pause Service"),
            }
        })

        if (val === "pause") this.pauseConsumption()
    }

    async pauseConsumption() {
        try {
            await this.props.pauseVIPConsumption()
            swal({
                icon: 'success',
                title: strings.getString("SUCCESSFULLY_PAUSED_VIP", "Successfully paused VIP service.")
            })
        } catch (err) {
            swal({
                icon: 'error',
                title: strings.getString("UH_OH", "Uh Oh!"),
                content: (<div>{strings.getString("VIP_PAUSE_ERROR", "There was an error pausing your service. Please try again.")}</div>)
            })
        }
    }

    async handleResumeConsumption() {
        const val = await swal({
            icon: "warning",
            title: strings.getString("VIP_RESUME_IN_PROGRESS", "You are about to resume your VIP Service."),
            content: (
                <div>
                    {strings.getString("VIP_RESUME_EXPLANATION", "Please note that resuming your VIP service will not resume your recurring payments.")}
                    <br/>
                    {strings.getString("VIP_RESUME_THEN_SUBSCRIBE", "To re-establish your recurring payments, select a VIP membership option from our store.")}
                </div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                resume: strings.getString("RESUME_SERVICE", "Resume Service"),
            }
        })

        if (val === "resume") this.resumeConsumption()
    }

    async resumeConsumption() {
        try {
            await this.props.resumeVipConsumption()
            swal({
                icon: 'success',
                title: strings.getString("SUCCESSFULLY_RESUMED_VIP", "Successfully resumed VIP service.")
            })
        } catch (err) {
            swal({
                icon: 'error',
                title: strings.getString("UH_OH", "Uh Oh!"),
                content: (<div>{strings.getString("VIP_RESUME_ERROR", "There was an error resuming your service. Please try again.")}</div>)
            })
        }
    }

    async handleUpdateSubscription() {
        this.props.setModalContent(<UpdateSubscriptionTypeModal />)
    }

    componentWillUnmount() {
        this.props.clearSubscriptionDetails()
    }

    render() {
        const { loading, vipCatalog, termsAndConditions }  = this.state
        const { subscriptionDetails } = this.props
        const isChargePending = _.result(subscriptionDetails, 'consumption_status.status') === "ChargePending"
        const hasVipAccess = !_.isEmpty(_.result(subscriptionDetails, 'current_vip'))
        const hasVipSubscription = !_.isEmpty(_.result(subscriptionDetails, 'subscription'))
        const hasVipCredits = !_.isEmpty(_.result(subscriptionDetails, 'entitlements'))
        const modeledVIPCredits = hasVipCredits ? _.orderBy(subscriptionDetails.entitlements, ["entitlement_duration"], ["desc"]) : null
        const currVIPEndDate = moment(_.result(subscriptionDetails, "current_vip.expire_date")).format("MMMM Do, YYYY")
        const vipIsPaused = _.result(subscriptionDetails, 'consumption_status.status') === "Paused"
        const pauseStartDate = vipIsPaused ? moment(_.result(subscriptionDetails, "consumption_status.pause_start_date")).format("MMMM Do, YYYY") : null
        const canPauseEnable = hasVipCredits || vipIsPaused || hasVipSubscription
        let nextVIPToBeConsumed = _.result(subscriptionDetails, "current_vip.next_vip")
        if(_.isEmpty(nextVIPToBeConsumed)) {
            nextVIPToBeConsumed = _.result(subscriptionDetails, 'subscription.subscription_definition.name')
        }
        let bannerStatus
        let bannerStatusString
        if(hasVipAccess && !isChargePending) {
            bannerStatus = 'AccountVipPage__banner AccountVipPage__banner-active'
            bannerStatusString = 'VIP_STATUS_BANNER_ACTIVE'
        } else if(!hasVipAccess && vipIsPaused && !isChargePending) {
            bannerStatus = 'AccountVipPage__banner AccountVipPage__banner-paused'
            bannerStatusString = 'VIP_STATUS_BANNER_PAUSED'
        } else if(isChargePending) {
            bannerStatus = 'AccountVipPage__banner AccountVipPage__banner-chargepending'
            bannerStatusString = 'VIP_STATUS_BANNER_CHARGE_PENDING'
        } else {
            bannerStatus = 'AccountVipPage__banner AccountVipPage__banner-inactive'
            bannerStatusString = 'VIP_STATUS_BANNER_INACTIVE'
        }

        let mktgString
        if (hasVipAccess && !hasVipSubscription) {
            mktgString = strings.getString("MAKE_THE_MOVE_TO_MEMBERSHIP", "Make the Move to Membership")
        } else if (hasVipAccess && hasVipSubscription) {
            mktgString = strings.getString("RENEW_VIP", "Renew VIP")
        } else {
            mktgString = strings.getString("PURCHASE_VIP", "Purchase VIP")
        }

        const noGameString = <span dangerouslySetInnerHTML={{ __html:strings.getString("VIP_NO_GAME_OWNED", "You currently do not own the CrowFall game. The great benefits of VIP can only be used with the game. Purchase <a href='https://crowfall.com/store?category=064b6196-98fe-46c7-94d4-9485dad599de'>here</a> now")}}/>
        return(
            <div>
                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>

                <h1 className={bannerStatus}>{strings.getString(bannerStatusString, 'VIP Status')}</h1>

                {/*<div style={{marginLeft: 15}}>*/}
                {/*    <div>The VIP membership grants you rewards and a number of game play bonuses. <span dangerouslySetInnerHTML={{ __html: strings.getString("VIP_STATUS_CLICK_HERE", "Click here to learn more about VIP benefits") }}/></div>*/}
                {/*    <div>VIP tokens are used first while on the account before charging any recurring memberships.</div>*/}
                {/*    <div>When no tokens are present and a recurring membership is charged, a token will be granted which will be used immediately.</div>*/}
                {/*    <div>You will only be charged when your VIP expires and no VIP tokens are present on the account.</div>*/}
                {/*</div>*/}

                {/*<br/>*/}

                {/*<div>*/}
                {/*    <p>{<span dangerouslySetInnerHTML={{ __html: strings.getString("VIP_THIS_MONTHS_REWARDS", "Click here to see this months rewards!") }}/>}</p>*/}
                {/*</div>*/}

                <br/>

                {hasVipAccess && !isChargePending && 
                    <div className="AccountVipPage__block">
                        <div className="AccountVipPage__field"><DisplayField columnal
                            label={strings.getString("VIP_STATUS_CURRENT", "Current VIP Status")}
                            value={strings.getString("VIP_ACTIVE_SINCE", "", { date: moment(_.result(subscriptionDetails, "current_vip.create_date", null)).format("MMMM Do, YYYY") })}
                        /></div>
                        <div className="AccountVipPage__field"><DisplayField columnal
                            label={strings.getString("VIP_CURRENT", "Current VIP")}
                            value={_.result(subscriptionDetails, "current_vip.vip_definition.name")}
                        /></div>
                        {(hasVipSubscription || (hasVipCredits && !vipIsPaused)) && <div className="AccountVipPage__field"><DisplayField columnal
                            label={strings.getString("CURRENT_VIP_RENEWS", "VIP Renews")}
                            value={strings.getString("MEMBERSHIP_RENEWS_DATE", `Membership Renews ${currVIPEndDate} using: ${nextVIPToBeConsumed} token`, { date: currVIPEndDate, value: nextVIPToBeConsumed})}
                        /></div>}
                        {!hasVipSubscription && (!hasVipCredits || vipIsPaused) && <div className="AccountVipPage__field"><DisplayField columnal
                            label={strings.getString("CURRENT_VIP_ENDS", "Current VIP Ends")}
                            value={strings.getString("VIP_SERVICE_ENDS_DATE", `VIP Service Ends ${currVIPEndDate}`, { date: currVIPEndDate })}
                        /></div>}
                    </div>
                }

                <div>
                    <h4>{strings.getString("VIP_TOKENS", "VIP Tokens")}
                    </h4>
                    {!hasVipSubscription && vipIsPaused && <>
                        <div className="AccountVipPage__field">
                            <DisplayField columnal
                                          label={strings.getString('VIP_PAUSED_ON', 'VIP Paused on')}
                                          value={pauseStartDate}
                            /></div>
                    </>}

                    <p>{hasVipCredits && !vipIsPaused && strings.getString("VIP_STATUS_P4")}</p>
                    <p>{hasVipCredits && vipIsPaused && strings.getString("VIP_STATUS_NOT_CONSUME")}</p>
                    <p>{!hasVipCredits && strings.getString("VIP_STATUS_NO_CREDITS")}</p>

                    {hasVipCredits && modeledVIPCredits.map((item) => {
                        return (
                            <div key={item.name} className="AccountVipPage__field AccountVipPage__vip-token-display">
                                <DisplayField columnal label={item.name} value={item.count}/>
                            </div>
                        )
                    })}

                    <div className="Spacer__vertical10px"/>
                </div>

                {/*<hr/>*/}

                {/*<div>*/}
                {/*    <h4>{strings.getString('VIP_MANAGE_MY', 'Manage My VIP')}</h4>*/}
                {/*    {!hasVipSubscription && !vipCatalog &&*/}
                {/*        <p>Select a membership from below to maximize your VIP benefits.</p>*/}
                {/*    }*/}
                {/*    {hasVipAccess && hasVipSubscription && <>*/}
                {/*        <p>{strings.getString('VIP_STATUS_MANAGE', "Using the buttons below you can change your service or membership, or cancel.")}</p>*/}
                
                {/*        <div className="AccountVipPage__field"><DisplayField columnal*/}
                {/*            label={strings.getString('YOUR_MEMBERSHIP', 'Your Membership')}*/}
                {/*            value={_.result(subscriptionDetails, 'subscription.subscription_definition.name')}*/}
                {/*        /></div>*/}
                {/*        {!vipIsPaused && <div className="AccountVipPage__field"><DisplayField columnal*/}
                {/*            label={strings.getString('NEXT_BILLING_DATE', 'Next Recurring Billing Date')}*/}
                {/*            value={moment(_.result(subscriptionDetails, "subscription.next_billing_date")).format("MMMM Do, YYYY")}*/}
                {/*        /></div>}*/}
                {/*    </>}*/}
                
                {/*    <div className="Spacer__vertical10px"/>*/}

                    {/*<div>*/}
                    {/*    {isChargePending ?*/}
                    {/*        <div className="AccountVipPage__buttons">*/}
                    {/*            <div className="AccountVipPage__button"><Button className="Button--disabled" medium disabled>{strings.getString('CHANGE_MEMBERSHIP', 'Change Membership')}</Button></div>*/}
                    {/*            <div className="AccountVipPage__button"><Button className="Button--disabled" medium disabled>{strings.getString('PAUSE_SERVICE', 'Pause Service')}</Button></div>*/}
                    {/*            <div className="AccountVipPage__button"><Button className="Button--disabled" medium disabled>{strings.getString('CANCEL_SERVICE', 'Cancel Service')}</Button></div>*/}
                    {/*        </div>*/}
                    {/*        :*/}
                    {/*        <div className="AccountVipPage__buttons">*/}
                    {/*            {hasVipSubscription && <div className="AccountVipPage__button"><Button green onclick={this.handleUpdateSubscription} className="Button__ACE" medium>{strings.getString('CHANGE_MEMBERSHIP', 'Change Membership')}</Button></div>}*/}
                    {/*            {!hasVipSubscription && <div className="AccountVipPage__button"><Button className="Button--disabled" medium disabled>{strings.getString('CHANGE_MEMBERSHIP', 'Change Membership')}</Button></div>}*/}
                    
                    {/*            {hasVipCredits && !vipIsPaused && hasVipAccess && <div className="AccountVipPage__button"><Button onclick={this.handlePauseConsumption} className="Button__ACE" medium>{strings.getString('PAUSE_SERVICE', 'Pause Service')}</Button></div>}*/}
                    {/*            {!vipIsPaused && !hasVipCredits && <div className="AccountVipPage__button"><Button className="Button--disabled" medium disabled>{strings.getString('PAUSE_SERVICE', 'Pause Service')}</Button></div>}*/}
                    
                    {/*            {vipIsPaused && <div className="AccountVipPage__button"><Button onclick={this.handleResumeConsumption} className="Button__ACE" medium>{strings.getString('RESUME_SERVICE', 'Resume Service')}</Button></div>}*/}
                    
                    {/*            {hasVipSubscription && <div className="AccountVipPage__button"><Button gray onclick={this.handleCancelSubscription} className="Button__ACE" medium>{strings.getString('CANCEL_SERVICE', 'Cancel Service')}</Button></div>}*/}
                    {/*            {!hasVipSubscription && <div className="AccountVipPage__button"><Button className="Button--disabled" medium disabled>{strings.getString('CANCEL_SERVICE', 'Cancel Service')}</Button></div>}*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*</div>*/}

                    {/*<div className="Spacer__vertical10px" />*/}
                    {/*<hr/>*/}
                {/*</div>*/}

                {/*<h3>{mktgString}</h3>*/}

                {/*<ProductGrid products={vipCatalog} noItemsFoundString={noGameString}/>*/}

                {/*<div className="AccountVipPage__terms" dangerouslySetInnerHTML={{ __html: termsAndConditions }}/>*/}
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountVipPage)
