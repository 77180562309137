import React from 'react'
import { connect } from 'react-redux'

import Page from '../../componentLibrary/Models/Page'
import LocLink from '../../componentLibrary/Elements/LocLink'
import Button from '../../componentLibrary/Fragments/Button'

import assets from '../../assets.js'
import strings from '../../strings'
import './styles.scss'
import moment from 'moment-timezone'
import {GuildCannotCreateJoinMsg} from "../GuildCannotCreateJoinMsg";

const mapStateToProps = state => {
    return {
        session: state.session,
        latestGuildMembership: state.guildManagement.latestGuildMembership
    }
}

const GuildNonMembersPage = props => {

    const { session, latestGuildMembership } = props
    const header = { title: strings.getString("GUILD_LOOKING", "Looking for a Guild"), banner: assets.staticImageUrls.GUILD_NON_MEMBER_BANNER }
    let displayJoinCreateMsg = (latestGuildMembership && latestGuildMembership.denyGuildCreate || latestGuildMembership.denyGuildJoin)
    return (
        <Page header={header}>
            <div className="GuildNonMembersPage">
                {session &&
                    <div>
                        {displayJoinCreateMsg &&
                            <div className="GuildNonMembersPage__cannot_create_display">
                                <GuildCannotCreateJoinMsg latestGuildMembership={latestGuildMembership} />
                            </div>
                        }
                        <div className="GuildNonMembersPage__buttons">
                            <div className="GuildNonMembersPage__button">
                                <LocLink to='/guilds/search'><Button large fill label={strings.getString("SEARCH_GUILDS", "Search Guilds")}/></LocLink>
                            </div>
                            {session.account_status.type !== 'trial' &&
                                <div className="GuildNonMembersPage__button">
                                    {displayJoinCreateMsg
                                        ?
                                        <Button disabled={displayJoinCreateMsg} large fill label={strings.getString("CREATE_GUILD", "Create Guild")}/>
                                        :
                                        <LocLink to="/guild/create"><Button large fill label={strings.getString("CREATE_GUILD", "Create Guild")}/></LocLink>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
                {!session &&
                    <div className="GuildNonMembersPage__buttons">
                        <div className="GuildNonMembersPage__button">
                            <LocLink to={`/login?r=${btoa('/guild')}`}><Button large fill label={strings.getString("SIGN_IN", "Sign In")}/></LocLink>
                        </div>
                        {/*<div className="GuildNonMembersPage__button">*/}
                        {/*    <LocLink to='/register'><Button large fill label={strings.getString("SIGN_UP", "Sign Up")}/></LocLink>*/}
                        {/*</div>*/}
                        <div className="GuildNonMembersPage__button--centered"><div className="GuildNonMembersPage__button">
                            <LocLink to='/guilds/search'><Button large fill label={strings.getString("SEARCH_GUILDS", "Search Guilds")}/></LocLink>
                        </div></div>
                    </div>
                }

                <div className="GuildNonMembersPage__content">
                    <div className="GuildNonMembersPage__header">{strings.getString("GUILDS_DESCRIPTION", "")}</div>
                    <div className="GuildNonMembersPage__description">{strings.getString("GUILDS_EXPLAINED", "")}</div>
                    <div className="GuildNonMembersPage__description">{strings.getString("GUILDS_CTA", "")}</div>
                    <div className="GuildNonMembersPage__cta">{strings.getString("CHOICE_IS_UP_TO_YOU", "")}</div>
                </div>
            </div>
        </Page>
    )
}

export default connect(mapStateToProps)(GuildNonMembersPage)
