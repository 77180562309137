
import React from 'react'
import { Container } from 'semantic-ui-react'
import queryString from 'query-string'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import connect from 'react-redux/es/connect/connect'
import _ from 'lodash'
import InnovaPage from './InnovaPage'
import CheckoutSolutionDisplay from './CheckoutSolutionDisplay'
import ShippingCollectionForm from './ShippingCollectionForm'

import { fetchCountryMap } from '../../redux/actions'
import { getSingleStoreProduct } from '../../redux/actions/crowfall-store'
import './styles.scss'


const mapStateToProps = state => {
  return {
    user: state.user.userProfile,
    accountRegions: state.user.accountRegions,
    shippingAddress: state.crowfallStore.shippingAddress,
    editingAddress: state.crowfallStore.editingAddress,
    language: state.language,
    session: state.session,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCountryMap: () => dispatch(fetchCountryMap()),
    getSingleStoreProduct: (id, language) => dispatch(getSingleStoreProduct(id, language)),
  }
}

class Checkout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isACart: false,
      isLayaway: false,
      isInnova: false,
      isSubscribe: false,

      itemId: null,
      layawayAmount: null,
      upgradeId: null,
      quantity: null,
      targetUsername: null,
      walletId: null,
      guildId: null,

      item: {},
      isPhysicalGood: false,
      countryOptions: [],

      checkoutPage: null
    }
  }

  recordToLiquidAdvertising() {
    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'session_id': Date.now() + Math.random().toString(36),
      'send_to': 'DC-11063492/crowfall/d_cro005+per_session'
    })
  }

  async componentDidMount() {
    this.recordToLiquidAdvertising();
    const countryMap = await this.props.fetchCountryMap()
    const countryOptions = !_.isEmpty(countryMap) ? Object.keys(countryMap).map(country => {
      return {
          value: countryMap[country].country_iso_code,
          label: `${countryMap[country].country_name} (${countryMap[country].country_iso_code})`,
          selected: false,
          countryName: countryMap[country].country_name,
      }
    }) : null

    const path = _.result(this.props, "location.pathname", {})
    const route = path ? path.split("/")[2] : null
    const isACart = route === "checkout-cart"

    const itemId = _.result(this.props, "match.params.id")
    const search = _.result(this.props, "location.search")
    const query = queryString.parse(search) || {}
    const { layaway, amount, upgradeId, targetUsername, walletId, subscribe, guildId } = query
    const isLayaway = !!layaway
    const isSubscribe = !!subscribe
    const layawayAmount = amount * 100
    if (itemId && !isLayaway) await this.getItemDetails(itemId)

    this.setState({ countryOptions, isACart, itemId, isLayaway, layawayAmount, upgradeId, targetUsername,
                    walletId, isSubscribe, guildId }, () =>
      this.determineUserStatus()
    )
  }

  async getItemDetails(itemId) {
    const item = await this.props.getSingleStoreProduct(itemId, this.props.language)
    const isPhysicalGood = !_.isEmpty(item) ? item.inventory_type === "physical" : false
    this.setState ({ item, isPhysicalGood })
  }

  componentDidUpdate(prevProps) {
    const { user: prevUser, shippingAddress: prevAddress, editingAddress: prevEditing } = prevProps
    const { user: currUser, shippingAddress: currAddress, editingAddress: currEditing } = this.props
    if (!_.isEqual(prevUser, currUser)) {
      this.determineUserStatus()
    } else if (!_.isEqual(prevAddress, currAddress) || !_.isEqual(prevEditing, currEditing)) this.setCheckoutPage()
  }

  async determineUserStatus() {
    const { accountRegions } = this.props
    const isInnova = _.isEmpty(_.find(accountRegions, { region_name: 'Innova' })) ? false : true
    this.setState({ isInnova }, () => this.setCheckoutPage())
  }


  setCheckoutPage() {
    const {
      isInnova, isACart, isLayaway, itemId, layawayAmount, guildId,
      upgradeId, quantity, targetUsername, walletId, item, isPhysicalGood, countryOptions, isSubscribe
    } = this.state
    const { shippingAddress, editingAddress, session } = this.props
    let checkoutPage = null
    if (session) {
      if (isInnova) {
        checkoutPage = ( <InnovaPage/> )
      } else if (isPhysicalGood &&( _.isEmpty(shippingAddress) || editingAddress)) {
        checkoutPage = ( <ShippingCollectionForm countryOptions={countryOptions}/> )
      } else if ( itemId || isACart ) {
        checkoutPage = ( <CheckoutSolutionDisplay countryOptions={countryOptions} itemId={itemId} item={item} isACart={isACart} isLayaway={isLayaway}
                                                  isSubscribe={isSubscribe} layawayAmount={layawayAmount} upgradeId={upgradeId} quantity={quantity}
                                                  targetUsername={targetUsername} walletId={walletId} guildId={guildId}/>
        )
      }
    }

    this.setState({ checkoutPage })
  }


  render() {

    const { checkoutPage } = this.state

    return (
      <Page contentId={assets.contentfulIds.CHECKOUT_PAGE}>
        <Container>
          {checkoutPage}
        </Container>
      </Page>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
