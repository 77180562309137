import React from 'react'
import _ from 'lodash'
import contentful from '../../../utilities/contentful'
import Page from '../../../componentLibrary/Models/Page'
import './styles.scss'


class CampaignsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entry: {}
        }
        this.getContent = this.getContent.bind(this)
    }

    componentDidMount() {
        this.getContent()
    }

    async getContent() {
        const entries = await contentful.getEntries('promoCampaigns')
        const entry = entries[0]
        entry.bannerAssetUrl = _.result(entry, 'bannerAsset.assetUrl')
        this.setState({ entry })
    }

    render() {

        const { entry } = this.state
        const { pageTitle, promoTitle, pageSubtitle, promoSubtitle, bannerAssetUrl, tagline, pageContent } = entry
        const title = pageTitle ? pageTitle : promoTitle
        const subtitle = pageSubtitle ? pageSubtitle : promoSubtitle

        return (
            <Page isMarketing header={{ title, subtitle, banner: bannerAssetUrl}}>
                <div className="CampaignsPage">
                    {tagline && 
                        <div className="CampaignsPage__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>
                    }
                    {pageContent &&
                        <div className="CampaignsPage__content" dangerouslySetInnerHTML={{ __html: pageContent }}/>
                    }
                </div>
            </Page>
        )
    }
}

export default CampaignsPage