import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const TravianPrivacyPolicy = () => (
  <Page contentId={assets.contentfulIds.EU_PRIVACY_POLICY}>
  </Page>
)

export default TravianPrivacyPolicy

