import React from 'react'
import { api } from '../utilities/api'
import { Dimmer, Header, Label, Loader, Table, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'
import LocLink from '../componentLibrary/Elements/LocLink'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsOrderHistory extends React.Component {

  state = {
    loading: false,
    orderId: '',
    order: {},
    fulfill: {},
  }

  async componentDidMount() {
    const orderId = _.result(this.props, 'match.params.id')
    this.setState({
      orderId: orderId,
    })
    await this.getContent()

  }

  async getContent() {
    this.setState({ loading: true })
    const order = await api.get(`/user/transaction-history/${_.result(this.props, 'match.params.id')}`)
    const fulfillmentItems = _.result(order.data, 'fulfillment.line_items', []).map(l => {
      const item = l.name
      return _.result(l, 'items', []).map((g, i) => {
        return {
          length: l.items.length,
          first: i === 0,
          name: item,
          grant: g.name,
          grantQuantity: g.quantity,
        }
      })
    }).reduce((cur, acc) => {
      return [...cur, ...acc]
    }, [])

    const fulfillmentWallet = _.result(order.data, 'fulfillment.line_items', []).map(l => {
      const item = l.name
      return _.result(l, 'wallets', []).map((g, i) => {
        return {
          length: l.wallets.length,
          first: i === 0,
          name: item,
          changeAmount: _.result(g, 'display_strings.change_amount'),
          balance: _.result(g, 'display_strings.balance'),
          currency: g.currency,
        }
      })
    }).reduce((cur, acc) => {
      return [...cur, ...acc]
    }, [])

    this.setState({
      order: order.data,
      fulfill: {
        fulfillmentItems,
        fulfillmentWallet,
      },
    })

    this.setState({ loading: false })
  }

  render() {

    const { loading, order, fulfill } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('ORDER_HISTORY', 'Order History')}</h1>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2}><span style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}>{strings.getString('ORDER_DETAILS', 'Order Details')} {order.deferred_purchase_id ? (
                <LocLink to={`/account/layaway/${order.deferred_purchase_id}`}><Label
                  color="blue">{strings.getString('LAYAWAY_DETAILS', 'Layaway Details')}</Label></LocLink>) : null}</span></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{strings.getString('STATUS', 'Status')}</Table.Cell>
              <Table.Cell>{_.startCase(order.status)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('BILLING_DESCRIPTOR', 'Billing Descriptor')}</Table.Cell>
              <Table.Cell>{order.billing_descriptor}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('ORDER_ID', 'Order ID')}</Table.Cell>
              <Table.Cell>{order.transaction_id}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('BILLING_DATE', 'Billing Date')}</Table.Cell>
              <Table.Cell>{(new Date(order.date_created)).toLocaleString()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('CHARGE_AMOUNT', 'Charge Amount')}</Table.Cell>
              <Table.Cell>{_.result(order, 'display_strings.total')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('WALLET_AMOUNT', 'Wallet Amount')}</Table.Cell>
              <Table.Cell>{_.result(order, 'display_strings.wallet_total')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('RECIPIENT', 'Recipient')}</Table.Cell>
              <Table.Cell>{_.result(order, 'gift_details.username')}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </FixedTable>


        <Header as='h3'>{strings.getString('LINE_ITEMS', 'Line Items')}</Header>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('NAME', 'Name')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('QUANTITY', 'Quantity')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('AMOUNT', 'Amount')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('UPGRADE_USED', 'Upgrade Used')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(order.line_items || []).map((l, i) => (
              <Table.Row key={i}>
                <Table.Cell>{_.result(l, 'item_details.name')}</Table.Cell>
                <Table.Cell>{l.quantity}</Table.Cell>
                <Table.Cell>{_.result(l, 'display_strings.purchase_amount')}</Table.Cell>
                <Table.Cell>{_.result(l, 'upgrade_details.name', 'No Upgrade')}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </FixedTable>


        <Header
          as='h3'>{strings.getString('ORDER_RECEIPT_BREAKDOWN', 'Order Receipt and Price Breakdown')}</Header>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('DESCRIPTION', 'Description')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('COST', 'Cost')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('CREDITS', 'Credits')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('NOTE', 'Note')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{strings.getString('SUBTOTAL_EXCL_TAX', 'Subtotal excl. TAX')}</Table.Cell>
              <Table.Cell>{_.result(order, 'display_strings.net_total')}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('PAYMENT_FEE', 'Processing Fee')}</Table.Cell>
              <Table.Cell>{_.result(order, 'display_strings.fee_total')}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            {/* <Table.Row>
              <Table.Cell>{order.tax_percent}% {strings.getString('TAX', 'TAX')}</Table.Cell>
              <Table.Cell>{_.result(order, 'display_strings.tax_total')}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row> */}
            <Table.Row>
              <Table.Cell>{strings.getString('SHIPPING_COST', 'Shipping Cost')}</Table.Cell>
              <Table.Cell>{_.result(order, 'display_strings.shipping_total')}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('UPGRADE_CREDITS', 'Upgrade Credits')}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>{_.result(order, 'display_strings.upgrade_total')}</Table.Cell>
              <Table.Cell>{_.result(order, 'line_items.0.upgrade_details.name')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('ACCOUNT_DISCOUNT_CREDIT', 'Account Discount Credit')}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>{_.result(order, 'display_strings.discount_total')}</Table.Cell>
              <Table.Cell>{order.discount_name ? `${order.discount_name} (${order.discount_percent}%)` : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('WALLET_AMOUNT', 'Wallet Amount Applied')}</Table.Cell>
              <Table.Cell><strong>{_.result(order, 'display_strings.wallet_total')}</strong></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('CHARGE_AMOUNT', 'Charge Amount')}</Table.Cell>
              <Table.Cell><strong>{_.result(order, 'display_strings.subtotal')}</strong></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>
        </FixedTable>


        <Header
          as='h3'>{strings.getString('REWARDS_OBTAINED', 'Rewards Obtained')}</Header>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('PURCHASED', 'Purchased')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('GRANTED', 'Granted')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('QUANTITY', 'Quantity')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {!_.isEmpty(fulfill.fulfillmentItems) ? (
            <Table.Body>
              {_.result(fulfill, 'fulfillmentItems', []).map((l, i) => (
                <Table.Row key={i}>
                  {l.first ? (<Table.Cell rowSpan={l.length}>{l.name}</Table.Cell>) : null}
                  <Table.Cell>{l.grant}</Table.Cell>
                  <Table.Cell>{l.grantQuantity}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell
                  colSpan={3}>{strings.getString('NO_REWARDS_OBTAINED', 'No Rewards Obtained')}</Table.Cell>
              </Table.Row>
            </Table.Body>
          )}

        </FixedTable>

        <Header
          as='h3'>{strings.getString('CURRENCY_GRANTED', 'Currency Granted')}</Header>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('PURCHASED', 'Purchased')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('CURRENCY', 'Currency')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('GRANTED', 'Granted')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('NEW_BALANCE', 'New Balance')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {!_.isEmpty(fulfill.fulfillmentWallet) ? (
            <Table.Body>
              {_.result(fulfill, 'fulfillmentWallet', []).map((l, i) => (
                <Table.Row key={i}>
                  {l.first ? (<Table.Cell rowSpan={l.length}>{l.name}</Table.Cell>) : null}
                  <Table.Cell>{l.currency}</Table.Cell>
                  <Table.Cell>{l.changeAmount}</Table.Cell>
                  <Table.Cell>{l.balance}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell
                  colSpan={3}>{strings.getString('NO_CURRENCY_GRANTED', 'No Currency Granted')}</Table.Cell>
              </Table.Row>
            </Table.Body>
          )}

        </FixedTable>

        <Header
          as='h3'>{strings.getString('MERCHANT_DETAILS', 'Merchant Details')}</Header>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                colSpan={2}>{strings.getString('MERCHANT_DETAILS', 'Merchant Details')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{strings.getString('MERCHANT', 'Merchant')}</Table.Cell>
              <Table.Cell>{_.startCase(order.merchant)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('PAYMENT_METHOD', 'Payment Method')}</Table.Cell>
              <Table.Cell>{_.startCase(order.payment_method)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('PAYMENT_PROVIDER', 'Payment Provider')}</Table.Cell>
              <Table.Cell>{_.startCase(order.payment_provider)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{strings.getString('PAYMENT_METHOD_LABEL', 'Payment Method Label')}</Table.Cell>
              <Table.Cell>{_.startCase(order.payment_method_display)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </FixedTable>

        <LocLink to="/account/order-history"><Button className="Button__ACE">{strings.getString("BACK_TO_ORDER_HISTORY", "Back to Order History")}</Button></LocLink>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsOrderHistory)
