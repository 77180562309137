/*

FieldDropdown: MODELS
provides uniform styling and functionality for multi and single select dropdowns.
Singleselect requires 'selectOne' function.
Multiselect requires 'selectMany' && 'deselect' functions

PROPERTY      DEFAULT    TYPE      REQUIREMENT  DESCRIPTION
label         null       String    optional     Label to sit outside of the Field's selection box; default styling is above the box.
placeholder   null       String    optional     Label inside of the field's box. For Single select, will be replaced with the selected option
name          null       String    optional     Allows for reusability of select methods in the parent
options       []         Array     required     Array of Objects with the properties "label", "value" && "selected"(used to set initial selected values)
selectOne                Function  optional     Returns the selected Object's value && the 'name' prop.
selectMany               Function  optional     Returns an Array of the selected Objects' values
deselect                 Function  optional     Returns the value of the deselected Object
disabled        false    Boolean   optional     If True, field will not be editable.
removeDeselect  false    Boolean   optional     If true, "No selection" option will not be added to the dropdown's options.

STYLING PROPS
type: Boolean
requirement: optional
default: false
  PROPERTY   DESCRIPTION
  above      When opened, dropdown options will sit on top of sibling/parent content.
  inline     Label sits inline and directly to the left of the field. Field width set for 600px+
  columnal   Label and field placed into two columns intended to take up 100% width. Label column provided with set width.
             Styling only applicable for 900px+

*/

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import swal from '@sweetalert/with-react'
import _ from 'lodash'

import strings from '../../../strings'
import Pill from '../../Fragments/Pill'
import './styles.scss'


class FieldDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.dropdownBox = React.createRef()
        this.options = React.createRef()
        this.state = {
            isExpanded: false,
            activeOption: null,
            options: [],
            innerLabel: '',
        }
        this.handleAppEvent = this.handleAppEvent.bind(this)
        this.toggle = this.toggle.bind(this)
    }

    componentDidMount() {
        document.getElementById('root').addEventListener('mousedown', this.handleAppEvent, true)
        document.getElementById('root').addEventListener('keydown', this.handleAppEvent, true)
        
		this.setInitialState()
    }

    componentDidUpdate(prevProps) {
        const { options: prevOptions } = prevProps
        const { options: currOptions } = this.props
        if (!_.isEqual(currOptions, prevOptions)) this.setInitialState()
    }

	handleAppEvent(event) {
        const path = event.path || event.composedPath()
        const includesDropdownBox = path.indexOf(this.dropdownBox.current) >= 0
        const includesOptions = path.indexOf(this.options.current) >= 0
        if (event.type === 'mousedown') {
            if (!includesDropdownBox && !includesOptions) {
                this.toggle(false)
            } else if (includesDropdownBox && !includesOptions) {
                this.toggle()
            }
        } else if (event.type === 'keydown') {
            if (includesDropdownBox) {
                if (event.keyCode === 9) {
                    this.toggle(false)
                } else if (event.keyCode === 32) {
                    event.preventDefault()
                    this.toggle()
                }
            }
        }
    }
    
    setInitialState() {
        const { options: propsOptions, selectOne, placeholder, removeDeselect } = this.props
        const options = [ ...propsOptions ]
        let innerLabel = placeholder ? placeholder : ''
        if (selectOne) {
            const selectedOption = _.find(options, ['selected', true])
            innerLabel = selectedOption && selectedOption ? selectedOption.label : innerLabel
            if (!removeDeselect) {
                options.unshift({
                    value: null,
                    label: strings.getString("NO_SELECTION", "No Selection"),
                    selected: false
                })
            }
        }
        
        this.setState({
            options,
            innerLabel,
        })
    }

    handleSelect(value, label, isSelected) {
        const { selectMany, selectOne } = this.props
        if (selectMany) {
            if (isSelected === false) {
                this.multiSelect(value)
            } else {
                this.deselect(value)
            }
        } else if (selectOne && isSelected !== true) {
            this.singleSelect(value, label)
        }
    }

    singleSelect(value, label) {
        const { selectOne, name, placeholder } = this.props
        const { options } = this.state
        let updatedOptions = [ ...options ]
        updatedOptions.forEach(option => {
            if (option.selected === true) option.selected = false
            if (_.isEqual(value, option.value)) option.selected = true
        })
        let innerLabel = ""
        if ( value === null ) {
            if ( !_.isEmpty(placeholder) ) {
                innerLabel = placeholder
            }
        } else {
            innerLabel = label
        }
        this.setState({
            isExpanded: false,
            options: updatedOptions,
            innerLabel,
        }, selectOne(value, name))
    }

    multiSelect(value) {
        const { selectMany, name } = this.props
        const { options } = this.state
        let updatedOptions = [ ...options ]
        let selectedOption = _.find(updatedOptions, ['value', value])
        if (selectedOption) {
            selectedOption.selected = true
            this.setState({
                options: updatedOptions,
            }, selectMany(value, name))
        } else {
            this.generateErrorModal()
        }
    }

    deselect(value) {
        const { name } = this.props
        const { options } = this.state
        let updatedOptions = [ ...options ]
        let deselectedOption = _.find(updatedOptions, ['value', value])
        if (deselectedOption) {
            deselectedOption.selected = false
            this.setState({
                options: updatedOptions,
            }, this.props.deselect(value, name))
        } else {
            this.generateErrorModal()
        }
    }

    toggle(expanded) {
        const { isExpanded: currExpanded } = this.state
        const isExpanded = expanded === undefined || expanded === null ? !currExpanded : expanded
        this.setState({    
            isExpanded,
        })
    }

    async generateErrorModal() {
        await swal({
            icon: 'error',
            title: strings.getString("UH_OH", "Uh Oh!"),
            content: strings.getString("ERROR_SELECTING_OPTION", "There was an error when attempting to make a selection. Please try again.")
        })
    }

    componentWillUnmount() {
        document.getElementById('root').removeEventListener('mousedown', this.handleAppEvent, true)
        document.getElementById('root').removeEventListener('keydown', this.handleAppEvent, true)
	}

    render() {

        const { isExpanded, innerLabel, options } = this.state
        const { label, above, inline, selectMany, selectOne, columnal, disabled } = this.props
        const selectedOptions = selectMany && options ? options.filter(option => option.selected === true) : []
        let className = "FieldDropdown"
        if (isExpanded && !disabled) className += " FieldDropdown--expanded"
        if (disabled && selectOne) className += " FieldDropdown--disabledSingleSelect"
        if (disabled && selectMany) className += " FieldDropdown--disabledMultiSelect"
        if (above) className += " FieldDropdown--above"
        if (inline) className += " FieldDropdown--inline"
        if (columnal) className += " FieldDropdown--columnal"

        return (
            <div className={className}>
                {label && <div className="FieldDropdown__label" dangerouslySetInnerHTML={{ __html: label }}/>}

                <div className="FieldDropdown__content">
                    {!_.isEmpty(selectedOptions) && selectedOptions.length > 0 && <div className="FieldDropdown__pills">
                        {selectedOptions.map((option, index) => {
                            const { label, value } = option
                            return (
                                <div className="FieldDropdown__pill" key={`FieldDropdownPill--${index}`}>
                                    <Pill label={label} small onClick={() => this.deselect(value)} disabled={disabled}/>
                                </div>
                            )
                        })}
                    </div>}

                    <div className="FieldDropdown__box" ref={this.dropdownBox}>
                        <div className="FieldDropdown__innerLabel" tabIndex="0" >
                            <div className="FieldDropdown__value">{ innerLabel }</div>
                            <div className="FieldDropdown__arrow"><FontAwesomeIcon icon="sort-down"/></div>
                        </div>

                        <div className="FieldDropdown__options" ref={this.options}>
                            {!_.isEmpty(options) && options.map((option, index) => {
                                const { label, value, selected } = option
                                const optionClassName = "FieldDropdown__option" + (selected ? " FieldDropdown__option--selected" : "")
                                return (
                                    <div 
                                      className={optionClassName} 
                                      key={`FieldDropdownOption--${index}`} 
                                      onClick={!disabled ? () => this.handleSelect(value, label, selected) : null}
                                    >
                                        <div className="FieldDropdown__optionLabel">{ label }</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FieldDropdown