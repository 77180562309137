import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import strings from '../../../strings'
import Button from '../Button'
import TextLink from '../TextLink'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        config: state.config,
        cohorts: state.cohorts,
    }
}

const DownloadClientButton = (props) => {

    const { config, session, cohorts } = props
    const { can_play_crowfall: canDownloadLive } = cohorts
    const downloadLink = canDownloadLive ? `${_.result(config, "base_api_host")}/user/asset-download/CFInstaller_Live.exe?token=${_.result(session, "token")}` : null

    return (
        <div className="DownloadClientButton">
            <a href={downloadLink}>
                <Button green label={strings.getString("PLAY_NOW", "Play Now")} disabled={!canDownloadLive}/>
            </a>

            {!canDownloadLive && <div className="DownloadClientButton__purchaseLink">
                <TextLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString("BUY_CROWFALL", "Buy Crowfall")}</TextLink>
            </div>}
        </div>
    )
}

export default connect(mapStateToProps)(DownloadClientButton)