import Mustache from 'mustache'
const _ = require('lodash')

const defaultLang = 'en-US'

const strings = require('./strings/strings_en-us')
const stringsFr = require('./strings/strings_fr-fr')
const stringsDe = require('./strings/strings_de-de')
const stringsES = require('./strings/strings_es-es')
const stringsPT = require('./strings/strings_pt-br')

const stringsTable = Object.keys(strings).map(s => {
  return {
    id: s,
    'en-US': strings[s],
    'fr-FR': stringsFr[s],
    'de-DE': stringsDe[s],
    'pt-PT': stringsPT[s],
    'es-ES': stringsES[s],
  }
}).reduce((acc, cur) => {
  acc[cur.id] = cur
  return acc
}, {})

const languageMap = {
  'en-US': 'en-US',
  'fr-FR': 'fr-FR',
  'de-DE': 'de-DE',
  'pt-PT': 'pt-PT',
  'es-ES': 'es-ES',
}

window.myStringMap = {}

function getString(key, val, params) {

  const v = strings[key]
  if (_.isNil(v)) {
    console.log(`broken key: ${key}=${val}`)

    if (!window.myStringMap[key]) {
      window.myStringMap[key] = val
    }

    if (window.myStringMap[key] !== val) {
      console.error('mismatch key, value', key, val)
    }
  }

  // return strings[key] ? `${strings[key] || ''}` : val
  const lang = currentLanguage()
  
  return Mustache.render(_.result(stringsTable, `${key}.${lang}`) || _.result(stringsTable, `${key}.en-US`) || val, params)
}

function currentLanguage() {
  return localStorage.getItem('site_language') || defaultLang
}

function convertLanguage(input) {
  const fullLang = {
    'en': 'en-US',
    'fr': 'fr-FR',
  }[input] || input
  return languageMap[fullLang] || currentLanguage() || 'en-US'
}

function setLanguage(language) {
  localStorage.setItem('site_language', language)
}

function getStringsTable() {
  return stringsTable
}

const stringsMethods = {
  getString,
  defaultLang,
  currentLanguage,
  convertLanguage,
  setLanguage,
  getStringsTable,
}

export default stringsMethods
