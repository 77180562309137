import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import { Container } from 'semantic-ui-react'

const MediaPage = () => (
  <Page contentId={assets.contentfulIds.MEDIA_PAGE}>
    <Container>
      <h1>Media</h1>
    </Container>
  </Page>
)

export default MediaPage

