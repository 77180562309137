/*

Button: Component

PROPERTY         DEFAULT  REQUIREMENT  TYPE      DESCRIPTION
children                  required     Function  JSX elements that need to be conditionally displayed based off of user case
artcraft                  optional     Boolean   user with account created in Artcraft region || non-logged in user with Artcraft browser
eu                        optional     Boolean   user with account created in EU region || non-logged in user with EU browser
brazil                    optional     Boolean   user with account created in Brazil || non-logged in user with browser located in Brazil
uk                        optional     Boolean   user with account created in UK "" non-logged in user with browser located in UK
canada                    optional     Boolean   user with account created in Canada || non-logged in user with Canada browser
notCanada                 optional     Boolean   user with account created anywhere except Canada || non-logged in user with a browser that isn't located in Canada
marketable                optional     Boolean   Any Artcraft user (w/ or w/out account) || EU account opted in to receiving newsletter
notMarketable             optional     Boolean   EU user account not opted in to receive newsletter
innova                    optional     Boolean   user with account created in Innova regions || non-logged in user with browser located in Innova Regions
isArtcraft                             Function  checks account/browser for region, returns true if "Artcraft" (session overrides browser)
isEU                                   Function  checks account/browser for region, returns true if "EU" (session overrides browser)
isCanadian                             Function  checks account/browser for region, returns true if "Canada" (session overrides browser)
isMarketable                           Function  checks account browser for marketing requirements above, return true if marketable
isNotMarketable                        Function  checks account/browser for marketing requirements above, return true if user does not meet requirements
isInnova                               Function  checks account/browser for region, returns true if "Innova" (session overrides browser)

*/

import React from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import _ from 'lodash'

const mapStateToProps = state => {
  return {
    regions: state.regions,
    accountRegions: state.user.accountRegions,
    session: state.session,
    accountAgreements: state.acceptedRegionalAgreements,
  }
}

const RegionBlock = (props) => {

  const { session, accountRegions, regions, children, artcraft, eu, brazil, uk, canada, notCanada, marketable, notMarketable, innova, accountAgreements } = props
  let show = false

  if (eu) show = isEU()
  if (brazil) show = isBrazil()
  if (uk) show = isUK()
  if (canada) show = isCanadian()
  if (notCanada) show = isNotCanadian()
  if (marketable) show = isMarketable()
  if (notMarketable) show = isNotMarketable()
  if (innova) show = isInnova()
  if (artcraft && !notCanada) show = isArtcraft()
  if (artcraft && notCanada) show = isArtcraft() === true && isNotCanadian() === true ? true : false

  return (
    <>
      { (show === true) ? children : null }
    </>
  )

  function isArtcraft() {
    let foundArtcraft = false
    if (session) { 
      foundArtcraft = !_.isEmpty(_.find(accountRegions, { region_name: "Artcraft" }))
    } else {
      foundArtcraft = !_.isEmpty(_.find(regions, { region_name: "Artcraft" }))
    }
    return foundArtcraft
  }

  function isEU() {
    let foundEU = null
    if (session) { 
      foundEU = !_.isEmpty(_.find(accountRegions, { region_name: "Crowfall EU" }))
    } else {
      foundEU = !_.isEmpty(_.find(regions, { region_name: "Crowfall EU" }))
    }
    return foundEU
  }

  function isBrazil() {
    let foundEU = null
    if (session) { 
      foundEU = !_.isEmpty(_.find(accountRegions, { region_name: "Brazil" }))
    } else {
      foundEU = !_.isEmpty(_.find(regions, { region_name: "Brazil" }))
    }
    return foundEU
  }

  function isUK() {
    let foundEU = null
    if (session) { 
      foundEU = !_.isEmpty(_.find(accountRegions, { region_name: "United Kingdom" }))
    } else {
      foundEU = !_.isEmpty(_.find(regions, { region_name: "United Kingdom" }))
    }
    return foundEU
  }

  function isCanadian() {
    let foundCanada = false
    if (session) {
      foundCanada = !_.isEmpty(_.find(accountRegions, { region_name: "Canada Merchandise" }))
    } else {
      foundCanada = !_.isEmpty(_.find(regions, { region_name: "Canada Merchandise" }))
    }
    return foundCanada
  }

  function isNotCanadian() {
    let notCanadian = false
    if (session) {
      notCanadian = _.isEmpty(_.find(accountRegions, { region_name: "Canada Merchandise" }))
    } else {
      notCanadian = _.isEmpty(_.find(regions, { region_name: "Canada Merchandise" }))
    }
    return notCanadian
  }

  function isMarketable() {
    let marketable = false
    let foundEUAccount = session ? !_.isEmpty(_.find(accountRegions, { region_name: "Crowfall EU" })) : false
    if (foundEUAccount) {
      marketable = !_.isEmpty(_.find(accountAgreements, { accepted: true, external_id: "crowfall-eu-newsletter" }))
    } else { 
      marketable = true
    }
    return marketable
  }

  function isNotMarketable() {
    let notMarketable = false
    let foundEUAccount = session ? !_.isEmpty(_.find(accountRegions, { region_name: "Crowfall EU" })) : false
    if (foundEUAccount) {
      notMarketable = _.isEmpty(_.find(accountAgreements, { accepted: true, external_id: "crowfall-eu-newsletter" }))
    } else {
      notMarketable = false
    }
    return notMarketable
  }

  function isInnova() {
    let innova = false
    if (session) {
      innova = !_.isEmpty(_.find(accountRegions, { region_name: 'Innova' }))
    } else {
      innova = !_.isEmpty(_.find(regions, { region_name: 'Innova' }))
    }
    return innova
  }
}

export default connect(mapStateToProps)(RegionBlock)
