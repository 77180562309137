import {
  CHANGE_LANGUAGE,
  CLEAR_MODAL_CONTENT,
  CLOSE_MOBILE_NAV,
  OPEN_MOBILE_NAV,
  REMOVE_SESSION,
  TOGGLE_INITIAL_FETCH,
  SET_ACCEPTED_REGIONAL_AGREEMENTS,
  SET_ACCOUNT_AGREEMENTS,
  SET_AVAILABLE_AGREEMENT_DEFINITIONS,
  SET_AVAILABLE_LANGUAGES,
  SET_LANGUAGE_MAP,
  SET_COHORTS,
  SET_COOKIE_NOTICE,
  SET_GUILD,
  SET_GUILD_MEMBERSHIP,
  SET_GUILD_NOTIFICATIONS,
  SET_GUILD_WALLET,
  SET_LANGUAGES,
  SET_LOCATION,
  SET_MODAL_CONTENT,
  SET_REGIONS,
  SET_SESSION, 
} from '../actionTypes'

import articles from './articles'
import warStories from './war-stories'
import blogs from './blogs'
import crowfallStore from './crowfall-store'
import guilds from './guilds'
import guildManagement from './guild-management'
import user from './user'
import promotions from './promotions'
import affiliateDetails from './affiliates';
import externalAccounts from "./external-accounts";
import subscription from "./subscription";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        ...action.payload,
      }
    case SET_SESSION:
      return {
        ...state,
        ...action.payload,
      }
    case REMOVE_SESSION:
      return {
        ...state,
        session: null,
        availableAgreementDefinitions: [],
        acceptedRegionalAgreements: [],
        location: {},
        languages: [],
        cohorts: {},
      }
    case TOGGLE_INITIAL_FETCH:
      return {
        ...state,
        ...action.payload,
      }
    case SET_AVAILABLE_AGREEMENT_DEFINITIONS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_ACCOUNT_AGREEMENTS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_LOCATION:
      return {
        ...state,
        ...action.payload,
      }
    case SET_LANGUAGES:
      return {
        ...state,
        ...action.payload,
      }
    case SET_AVAILABLE_LANGUAGES:
      return {
        ...state,
        ...action.payload,
      }
    case SET_LANGUAGE_MAP:
      return {
        ...state,
        ...action.payload,
      }
    case SET_COHORTS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_REGIONS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_COOKIE_NOTICE:
      return {
        ...state,
        ...action.payload
      }
    case SET_GUILD_MEMBERSHIP:
      return {
        ...state,
        ...action.payload,
      }
    case SET_GUILD:
      return {
        ...state,
        ...action.payload,
      }
    case SET_GUILD_WALLET:
      return {
        ...state,
        ...action.payload,
      }
    case SET_GUILD_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_MODAL_CONTENT:
      return {
        ...state,
        ...action.payload,
      }
    case CLEAR_MODAL_CONTENT:
      return {
        ...state,
        ...action.payload,
      }
    case SET_ACCEPTED_REGIONAL_AGREEMENTS:
      return {
        ...state,
        ...action.payload
      }
    case OPEN_MOBILE_NAV:
      return {
        ...state,
        ...action.payload,
      }
    case CLOSE_MOBILE_NAV:
      return {
        ...state,
        ...action.payload,
      }
    default:
      let combinedState = state
      combinedState = {
        ...combinedState,
        articles: articles(state.articles, action),
        warStories: warStories(state.warStories, action),
        blogs: blogs(state.blogs, action),
        crowfallStore: crowfallStore(state.crowfallStore, action),
        guilds: guilds(state.guilds, action),
        guildManagement: guildManagement(state.guildManagement, action),
        user: user(state.user, action),
        promotions: promotions(state.promotions, action),
        affiliateDetails: affiliateDetails(state.affiliateDetails, action),
        externalAccounts: externalAccounts(state.externalAccounts, action),
        subscription: subscription(state.subscription, action)
      }
      return combinedState
  }
}

export default reducer
