import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../../strings'

import VerifyUserPermissions from '../VerifyUserPermissions'
import ComparisonTable from '../../../componentLibrary/Models/ComparisonTable'
import DataTable from '../../../componentLibrary/Models/DataTable'
import Searchbar from '../../../componentLibrary/Elements/Searchbar'
import Pagination from '../../../componentLibrary/Elements/Pagination'
import SectionHeader from '../../../componentLibrary/Fragments/SectionHeader'
import Button from '../../../componentLibrary/Fragments/Button'
import Label from '../../../componentLibrary/Fragments/Label'
import GuildInvitePlayerModal from './GuildInvitePlayerModal'

import { clearModalContent, setModalContent } from '../../../redux/actions'
import {
    fetchGuildDetails, searchGuildMembers, paginateGuildMembers, clearMembersSearchCriteria, inviteUserToGuild,
    fetchGuildInvites, revokeInviteToGuild, modifyMemberRank, kickMemberFromGuild,
} from '../../../redux/actions/guild-management'
import './styles.scss'


const mapStateToProps = state => {
    return {
        user: state.user.userProfile,
        guildId: state.guildManagement.guildId,
        guild: state.guildManagement.guild,
        guildConfiguration: state.guildManagement.guildConfiguration,
        pendingInvites: state.guildManagement.guildInvites,
        membersSearched: state.guildManagement.membersSearched,
        membersPaginated: state.guildManagement.membersPaginated,
        paginationCriteria: state.guildManagement.membersPaginationCriteria,
        usersRank: state.guildManagement.guildRank,
    }
}

const mapDispatchToProps = dispatch => {
    return  {
        clearModalContent: () => dispatch(clearModalContent()),
        setModalContent: (content) => dispatch(setModalContent(content)),
        fetchGuildDetails: () => dispatch(fetchGuildDetails()),
        searchMembers: (criteria) => dispatch(searchGuildMembers(criteria)),
        paginateMembers: (page) => dispatch(paginateGuildMembers(page)),
        clearSearchCriteria: () => dispatch(clearMembersSearchCriteria()),
        fetchGuildInvites: (guildId) => dispatch(fetchGuildInvites(guildId)),
        inviteToGuild: (guildId, username) => dispatch(inviteUserToGuild(guildId, username)),
        revokeInviteToGuild: (inviteId) => dispatch(revokeInviteToGuild(inviteId)),
        modifyMemberRank: (membershipId, rankId) => dispatch(modifyMemberRank(membershipId, rankId)),
        kickMemberFromGuild: (guildId, membershipId) => dispatch(kickMemberFromGuild(guildId, membershipId)),
    }
}

class GuildMembersPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            canInviteMembers: false,
            canKickMembers: false,
            inviteInput: null,
            ranksColumns: [],
            ranksRows: [],
        }
        this.verifyUserPermissions = this.verifyUserPermissions.bind(this)
        this.handleRevokeInvite = this.handleRevokeInvite.bind(this)
        this.updateInput = this.updateInput.bind(this)
        this.generateInviteModal = this.generateInviteModal.bind(this)
        this.clearInviteModal = this.clearInviteModal.bind(this)
        this.handleInvitePlayer = this.handleInvitePlayer.bind(this)
    }

    componentDidMount() {
        this.verifyUserPermissions()
        this.modelRanksTable()
    }

    verifyUserPermissions() {
        const { guildConfiguration, usersRank } = this.props
        const inviteMemberRankRequirement = _.result(_.find(guildConfiguration.permission_display, { "value": "invite_to_guild" }), "required_rank")
        const kickMemberRankRequirement = _.result(_.find(guildConfiguration.permission_display, { "value": "kick_member_from_guild" }), "required_rank")
        this.setState({
            canInviteMembers: usersRank && usersRank <= inviteMemberRankRequirement ? true : false,
            canKickMembers: usersRank && usersRank <= kickMemberRankRequirement ? true : false,
        })
    }

    modelRanksTable() {
        const { guildConfiguration } = this.props
        const orderedPermissions = _.orderBy(_.result(guildConfiguration, "permission_display", []), ["required_rank"], ["asc"])
        const orderedRanks = _.orderBy(_.result(guildConfiguration, "default_guild_ranks", []), ["rank"], ["asc"])
        const ranksColumns = orderedPermissions.map(permission => {
            return {
                label: permission.display,
                compare: (value) => value <= permission.required_rank,
            }
        })
        const ranksRows = orderedRanks.map(rank => {
            return {
                label: rank.display_name,
                value: rank.rank,
            }
        })
        this.setState({ ranksColumns, ranksRows })
    }

    async handleRevokeInvite(invite) {
        const { guild_invite_id: id, username } = invite
        const { guildId, revokeInviteToGuild, fetchGuildInvites } = this.props
        const val = await swal({
            title: strings.getString("REVOKE_INVITE_TO", `Revoke Invite to ${username}?`, { username }),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                revoke: {
                    text: strings.getString("REVOKE_INVITE", "Revoke Invite"),
                    value: 'revoke', closeModal: false
                },
            },
        })

        if (val === 'revoke') {
            try {
                await revokeInviteToGuild(id)
                await fetchGuildInvites(guildId)
                await swal({
                    icon: 'success',
                    title: strings.getString("GUILD_REVOKE_SUCCESS", "Successfully revoked invite to" + username, { username })
                })
            } catch (err) {
                const responseMessage = _.result(err.response.data, 'Message')
                swal(`${responseMessage}`, {
                    icon: 'error',
                })
                await fetchGuildInvites(guildId)
            }
        }
    }

    updateInput(value, name) {
        this.setState({ [name]: value })
    }

    async generateInviteModal() {
        const { setModalContent } = this.props

        setModalContent(
            <GuildInvitePlayerModal
                updateInput={this.updateInput}
                clearInviteModal={this.clearInviteModal}
                handleInvitePlayer={this.handleInvitePlayer}/>
        )
    }

    clearInviteModal() {
        this.setState({ inviteInput: null })
        this.props.clearModalContent()
    }

    async handleInvitePlayer() {
        const { guildId, inviteToGuild, fetchGuildInvites } = this.props
        const { inviteInput: username } = this.state
        const invalidUsernameMessage = {
            title: strings.getString("UH_OH", "Uh Oh!"),
            icon: 'error',
            content: (
                <div className="GuildMembersPage__modal">
                    {strings.getString("GUILD_ERROR_INVITE_INVALID", "Please input a valid username and try again.")}
                </div>
            )
        }

        if (!username) {
            await swal(invalidUsernameMessage)
            return
        } else {
            try {
                await inviteToGuild(guildId, username)
                fetchGuildInvites(guildId)
                await swal(strings.getString("SUCCESSFULLY_INVITED_USER", "Successfully invited " + username + ".", {username}), {
                icon: 'success',
                })
                this.clearInviteModal()
            } catch (err) {
                const responseMessage = _.result(err.response.data, 'Message')
                if (responseMessage === "Username does not exist") {
                    await swal(invalidUsernameMessage)
                } else {
                    await swal(`${responseMessage}`, {
                        icon: 'error',
                    })
                }
            }
        }
    }

    modelMembers(members) {
        const { canKickMembers } = this.state
        const { user, usersRank, guildConfiguration, guild } = this.props
        const username = _.result(user, "username")
        const userRankRequred = _.result(_.find(guildConfiguration.permission_display, { "value": "kick_member_from_guild" }), "required_rank")
        const userCanModifyMembers = usersRank === 1
        const guildRanks = _.orderBy(guild.guildRanks, ['rank'], ['asc'])
        const promoteButton = (member) => {
            const { rank } = member
            const currRankIndex = _.findIndex(guildRanks, ['rank', rank])
            const newRank = guildRanks[currRankIndex - 1]
            const canBePromoted = !_.isEmpty(newRank) && (newRank.rank !== 1 ? true : false)

            if (!canBePromoted || !userCanModifyMembers || (!member.paid_user && newRank.rank < 4)) return null;

            return <Button medium label={strings.getString("PROMOTE", "Promote")} onclick={() => this.modifyRank(newRank, member, true)}/>
          }
        const demoteButton = (member) => {
            const { rank } = member
            const currRankIndex = _.findIndex(guildRanks, ['rank', rank])
            const newRank = guildRanks[currRankIndex + 1]
            const canBeDemoted = rank !== 1 && !_.isEmpty(newRank) && newRank.rank !== 6 ? true : false
            return !canBeDemoted || !userCanModifyMembers ? null : <Button medium label={strings.getString("DEMOTE", "Demote")} onclick={() => this.modifyRank(newRank, member, false)}/>
          }
          const removeButton = (member) => userCanModifyMembers && member.rank !==1 && member.username !== username ? <Button medium label={strings.getString("REMOVE", "Remove")} onclick={() => this.kickMember(member)}/> : null
          const buttons = (member) => {
            if (_.isEmpty(removeButton(member)) && _.isEmpty(promoteButton(member)) && _.isEmpty(demoteButton(member))) return null
            return (
              <div className="GuildMembersPage__buttons">
                <div className="GuildMembersPage__button">{promoteButton(member)}</div>
                <div className="GuildMembersPage__button">{demoteButton(member)}</div>
                <div className="GuildMembersPage__button">{removeButton(member)}</div>
              </div>
            )
          }
        return members.map(member => {
            const buttonElements = buttons(member)
            return {
                id: member.guild_membership_id,
                elements: [
                    <div className="GuildMembersPage__memberLabel">
                        {member.paid_user ? <span className="GuildMembersPage__asterisk">*</span> : null}
                        <Label label={member.username}/>
                    </div>,
                    <Label label={member.display_name}/>,
                    <Label label={member.join_date} date/>,
                    <Label label={member.last_activity_date} monthDayYear/>,
                ],
                accordionElement: canKickMembers && !_.isEmpty(buttonElements) ? (
                    <div className="GuildMembersPage__memberActionButtons">
                        {buttonElements}
                    </div>
                ) : null
            }
        })
    }

    async modifyRank(newRank, member, isAPromotion) {
        const { fetchGuildDetails, modifyMemberRank } = this.props
        const { username, guild_membership_id:guildMembershipId } = member
        if (_.isEmpty(newRank)) {
            swal({
                title: strings.getString("UH_OH", "Uh Oh!"),
                icon: "error",
                content: (
                    <div>{strings.getString("DEFAULT_ERROR_MESSAGE", "There was an issue when try to process your request. Please refresh the page and try again.")}</div>
                )
            })
            return
        }

        const val = await swal({
            title: isAPromotion ?
                strings.getString('PROMOTE_MEMBER_TO_RANK', `Promote ${username} to ${newRank.display_name}`, { username, rankTitle: newRank.display_name }) :
                strings.getString("DEMOTE_MEMBER_TO_RANK", `Demote ${username} to ${newRank.display_name}`, { username, rankTitle: newRank.display_name }),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                modify: {
                    text: isAPromotion ?
                    strings.getString("PROMOTE", "Promote") :
                    strings.getString("DEMOTE", "Demote"),
                    value: "modify", closeModal: false
                }
            }
        })

        if (val === "modify") {
            try {
                await modifyMemberRank(guildMembershipId, newRank.guild_rank_id)
                fetchGuildDetails()
                await swal({
                    title: isAPromotion ?
                        strings.getString("GUILD_PROMOTION_SUCCESS", `Successfully promoted ${username} to ${newRank.display_name}`, {username, rankTitle: newRank.display_name}) :
                        strings.getString("GUILD_DEMOTION_SUCCESS", `Successfully demoted ${username} to ${newRank.display_name}`, {username, rankTitle: newRank.display_name}),
                    icon: "success"
                })
            } catch (err) {
                swal(`${err.response.data.message}`, {
                    icon: "error",
                })
            }
        }
    }

    async kickMember(member) {
        const { kickMemberFromGuild, fetchGuildDetails, guildId } = this.props
        const { username, guild_membership_id: membershipId } = member
        const val = await swal({
            title: strings.getString("REMOVE_USER", `Remove ${username} from the Guild`, { username }),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                kick: {
                    text: strings.getString("REMOVE_MEMBER", 'Remove Member'),
                    value: 'kick',
                    closeModal: false
                }
            }
        })

        if ( val === "kick" ) {
            try {
                await kickMemberFromGuild(guildId, membershipId)
                await fetchGuildDetails()
                await swal(strings.getString("GUILD_MEMBER_KICKED_SUCCESS", `Successfully removed ${username}`, { username }), {
                    icon: "success"
                })
            } catch (err) {
                swal(`${err.response.data.message}`, {
                    icon: "error",
                })
            }
        }
    }


    render() {

        const {
            pendingInvites, membersSearched, membersPaginated, paginationCriteria, paginateMembers,
            searchMembers, clearSearchCriteria
        } = this.props
        const { canInviteMembers, ranksColumns, ranksRows } = this.state
        const inviteMembersIcon = canInviteMembers ? "plus-circle" : null
        const memberRows = this.modelMembers(membersPaginated)
        const membersTableLabels = [
            {label: strings.getString("NAME", "Name")},
            {label: strings.getString("RANK", "Rank")},
            {label: strings.getString("JOIN_DATE", "Join Date")},
            {label: strings.getString("LAST_ACTIVITY", 'Last Activity')},
            {}
        ]

        return (
            <div className="GuildMembersPage">

                <VerifyUserPermissions canViewPendingInvites>
                    <SectionHeader title={strings.getString("PENDING_INVITES", "Pending Invites")}/>
                    <div className="GuildMembersPage__pendingInvites">
                        {pendingInvites && pendingInvites.length === 0 &&
                            <div className="GuildMembersPage__noInvitesText">
                                <p>{strings.getString("GUILD_NO_PENDING_INVITES", "There are no current Pending Invites.")}</p>
                                <p>{strings.getString("GUILD_INVITE_HOW_TO", "Invite new members to your Guild using the section below.")}</p>
                            </div>
                        }
                        {pendingInvites && pendingInvites.length > 0 && <div className="GuildMembersPage__invitesList">
                            {pendingInvites.map(invite => {
                                const { guild_invite_id: id, username, invited_by, date_created } = invite
                                const formattedDate = moment(date_created).format('MMMM Do, YYYY')
                                return (
                                    <div className="GuildMembersPage__invite" key={`pending-invite-${id}`}>
                                        <div className="GuildMembersPage__inviteDate">{formattedDate}</div>
                                        <div className="GuildMembersPage__inviteDescription"
                                            dangerouslySetInnerHTML={{ __html: strings.getString("GUILD_INVITE_DESCRIPTION", `<b>${username}</b> was invited to the guild by <b>${invited_by}</b>`, { username, invitedBy: invited_by }) }}
                                        />
                                        <div className="GuildMembersPage__inviteRevokeButton">
                                            <Button label={strings.getString("REVOKE", "Revoke")} medium onclick={() => this.handleRevokeInvite(invite)} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                </VerifyUserPermissions>

                <SectionHeader title={strings.getString("MEMBERS", "Members")} icon={inviteMembersIcon} click={this.generateInviteModal}/>
                <div className="GuildMembersPage__membersSection">
                    <div className="GuildMembersPage__searchbar">
                        <Searchbar search={searchMembers} clear={clearSearchCriteria}/>
                    </div>

                    <div className="GuildMembersPage__table">
                        <DataTable labels={membersTableLabels} rows={memberRows}/>
                    </div>

                    <Pagination paginate={paginateMembers} list={membersSearched} page={paginationCriteria.page} limit={paginationCriteria.limit}/>

                    <div className="GuildMembersPage__tableCallout">
                        <span className="GuildMembersPage__asterisk">* </span>
                        {strings.getString("GUILD_MEMBER_IS_BACKER", "Guild Member is an active backer for Crowfall.")}
                    </div>

                    <div className="GuildMembersPage__memberRanks">
                        <SectionHeader h3 title={strings.getString("MEMBER_RANK_PERMISSIONS", "Member Rank Permissions")}/>
                        <div className="GuildMembersPage__subsectionSpacer"/>
                        <ComparisonTable columns={ranksColumns} rows={ranksRows}/>
                    </div>

                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildMembersPage)
