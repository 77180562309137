import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import strings from '../../../strings'
// import Stepper from '../../../componentLibrary/Models/Stepper'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
import { clearModalContent } from '../../../redux/actions'
import { removeItemFromUserCart } from '../../../redux/actions/crowfall-store'
import './styles.scss'


const mapStateToProps = state => {
    return {
        cart: state.crowfallStore.cartSolutions,
        cartSubtotal: state.crowfallStore.cartSubtotal,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearModalContent: () => dispatch(clearModalContent()),
        removeItemFromUserCart: (solutionId, cart) => dispatch(removeItemFromUserCart(solutionId, cart)),
    }
}

const gtagItemObj = (solution) => {
    // const category = _.isArray(solution.categories) ? solution.categories[0].category_key : null
    return {
        "id": solution.itemId,
        "name": solution.item_slug,
        "list_name": solution.name,
        "brand": "Crowfall",
        // "category": category,
        "quantity": solution.quantity || 1,
        "price": solution.unit_amount
    }
}

class CartView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectToStore: false,
        }
        this.handleContinueShopping = this.handleContinueShopping.bind(this)
        this.removeItemSolution = this.removeItemSolution.bind(this)
    }

    handleContinueShopping() {
        const { location, clearModalContent } = this.props
        if (location.pathname.includes("/store")) {
            clearModalContent()
        } else {
            this.setState({ redirectToStore: true }, () => clearModalContent())
        }
    }

    async removeItemSolution(solution) {
        const { cart, removeItemFromUserCart } = this.props
        await removeItemFromUserCart(solution.solutionId, cart)
        this.recordItemRemovalFromCart(solution)
    }

    updateQuantity(qty, solutionId) {
        // awaiting route to address updating QTY.
        console.log("UPDATING", qty, solutionId)
    }

    recordItemRemovalFromCart(solution) {
        window.gtag("event", "remove_from_cart", {
            "items": gtagItemObj(solution)
        })
    }

    render () {
        const { cart, cartSubtotal, clearModalContent } = this.props
        const subtotal = _.result(cartSubtotal, "string")
        const { redirectToStore } = this.state

        return (
            <div className="CartView">

                {redirectToStore && <Redirect to="/store"/>}

                <div className="CartView__header">{strings.getString("SHOPPING_CART", "Shopping Cart")}</div>

                <div className="CartView__list">
                    {!_.isEmpty(cart) && cart.map((item, index) => {
                        const { itemId, imgUrl, name, price } = item
                        // PLACEHOLDER: Price needs to be updated to check for quantity and difference in list price vs. discounted price

                        return (
                            <div className="CartView__item" key={`CartItem--${itemId}${index}`}>
                                <div className="CartView__cancel" onClick={() => this.removeItemSolution(item)}><FontAwesomeIcon icon="times" /></div>
                                <div className="CartView__asset" style={{ backgroundImage: `url(${imgUrl})` }} />
                                <div className="CartView__name">{name}</div>
                                <div className="CartView__unitPrice">{price}</div>
                                {/* <div className="CartView__quantity">
                                    <Stepper min={1} value={quantity} onStep={(qty) => this.updateQuantity(qty, solutionId)}/>
                                </div> */}
                                <div className="CartView__itemTotal">{price}</div>
                            </div>
                        )
                    })}

                    {_.isEmpty(cart) && <div className="CartView__emptyCart">
                        <p>{strings.getString("SHOPPING_CART_IS_EMPTY", "Your shopping cart is empty.")}</p>
                    </div>}
                </div>
                
                {!_.isEmpty(cart) && <div className="CartView__subtotal">{strings.getString("SUBTOTAL_WITH_CURRENCY", `Subtotal: ${subtotal}`, { subtotal })}</div>}

                <div className="CartView__buttons">
                    <div className="CartView__button">
                        <Button medium fill label={strings.getString("CONTINUE_SHOPPING", "Continue Shopping")} onclick={this.handleContinueShopping}/>
                    </div>
                    <div className="CartView__button">
                        {_.isEmpty(cart) && <Button medium fill green label={strings.getString("CANCEL", "Cancel")} onclick={clearModalContent}/>}
                        {!_.isEmpty(cart) && <LocLink to="/checkout-cart">
                                <Button onclick={this.props.clearModalContent} medium fill green label={strings.getString("PROCEED_TO_CHECKOUT", "Proceed to Checkout")}/>
                            </LocLink>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartView))