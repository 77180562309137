import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import Button from '../../../componentLibrary/Fragments/Button'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import './styles.scss'
import strings from '../../../strings'

class JobsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      kit: {},
      positions: [],
      mntlLogo: {},
    }
  }

  async componentDidMount() {
    const kit = Array.from(await contentful.getEntries('contentBlock', 'fields.location[match]=job-listing'))[0]
    const positions = await contentful.getEntries('jobPosting')
    const mntlLogo = await contentful.getImage(assets.staticImageIds.MNTL_LOGO)

    this.setState({
      positions,
      kit,
      mntlLogo,
    })
  }

  render() {
    const { kit, positions } = this.state
    return (

      <Page contentId={assets.contentfulIds.JOBS_PAGE}>
        <div className="JobsPage">

          <div className="JobsPage__kit">
            <h2 className="JobsPage__subtitle">{strings.getString("INTEREST_IN_CROWFALL_ACE", "Thank you for your interest in Crowfall and Monumental")}</h2>
            <div className="JobsPage__kitBody">
              <div className="JobsPage__download">
                <img src={this.state.mntlLogo.assetUrl} alt={this.state.mntlLogo.title} />
              </div>
              <div className="JobsPage__kitContent" dangerouslySetInnerHTML={{ __html: kit.content }}/>
            </div>
          </div>

          <div className="JobsPage__releases">
            <h1 className="JobsPage__title">{strings.getString("CURRENT_OPENINGS", "Current Openings")}</h1>
            {positions.map((release, index) => {
              return (
                <div className="JobsPage__release" key={index}>
                  <h3>{release.position}</h3>
                  <div dangerouslySetInnerHTML={{ __html: release.description }}/>
                  <div className="PressPage__downloadButton"><a
                    href={`mailto:jobs@artcraftent.com?subject=Position: ${release.position}`}><Button blue
                    label="Apply Now"/></a>
                  </div>
                </div>
              )
            })}
          </div>

        </div>
      </Page>

    )
  }
}

export default JobsPage
