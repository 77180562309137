/*

Searchbar: Element
Combination of 'search' & 'clear' to be used for client-side search.
'update' to be used for server side search.


PROPERTY     DEFAULT      REQUIRED  TYPE      DESCRIPTION

search                    optional  Function  returns the criteria Function using input's value to loop through array and return true or false.
update                    optional  Function  returns the current value of the input, and a Boolean to initiate a search on enter.
clear                     optional  Function  Enacted when the value inside the input is null.
value        ""           optional  String    Updates state for server side search to allow for external clear search functionality.
placeholder  "Search..."  optional  String    Default string when no criteria is inputted in the search field.

*/

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import './styles.scss';
import strings from '../../../strings';

class Searchbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
		}
		this.criteria = this.criteria.bind(this);
		this.search = this.search.bind(this);
	}

	criteria(criterion) {
		const lowercaseCriterion = criterion.toLowerCase();
		return (item) => {
			const categories = Object.keys(item);

			for (let category of categories) {
				const value = item[category] + "";
				const lowercaseValue = value.toLowerCase();
				if (lowercaseValue.indexOf(lowercaseCriterion) !== -1) {
					return true;
				}
				
			}
			return false;
		}
	}

	clearSearch() {
		this.setState({ value: '' }, this.props.clear)
	}

	search(event) {
		const value = event ? event.target.value : ''
		const { update, search, clear } = this.props
		if (update) {
			this.setState({ value }, this.props.update(value))
		} else if (search && clear) {
			if (value === '') { 
				this.clearSearch()
			} else {
				this.setState({ 
					value 
				}, this.props.search(this.criteria(value)))
			}
		}
	}

	handleKeyUp(event) {
		if (event.keyCode === 13) {
			const value = event ? event.target.value : ''
			this.props.update(value, true)
		}
	}

	componentDidUpdate(prevProps) {
		const { value: prevValue } = prevProps
		const { value } = this.props
		const { value: currValue } = this.state
		if (value === '' && currValue !== value) this.setState({ value })
		if (!_.isEmpty(value) && _.isEmpty(prevValue)) this.setState({ value })
	}

	render() {

		const { value } = this.state
		const { clear, placeholder } = this.props
		const searchPlaceholder = !_.isEmpty(placeholder) ? placeholder : strings.getString("SEARCH_DOT_DOT_DOT", "Search...")

		return (
			<div className="Searchbar">
				<FontAwesomeIcon className='Searchbar__searchIcon' icon='search' />
				<input 
					className="Searchbar__input" 
					value={value} 
					placeholder={searchPlaceholder} 
					onChange={ (event) => this.search(event) } 
					onKeyUp={ (event) => this.handleKeyUp(event) } 
				/>
				{clear && <FontAwesomeIcon className='Searchbar__clearIcon' icon='times-circle' onClick={ () => this.search() } />}
			</div>
		);	
	}
}

export default Searchbar