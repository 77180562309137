import React from 'react'
import './styles.scss'

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      img: null,
      title: null,
      description: null,
      selectedIndex: 0
    }

    this.select = this.select.bind(this)
  }

  select(item, index) {
    this.setState({...item, selectedIndex: index})
  }

  componentDidUpdate(prevProps) {
    const { items: prevItems } = prevProps
    const { items: currItems } = this.props

    if (prevItems !== currItems) this.setState({...currItems[0]})
  }

  componentDidMount() {
    const { items } = this.props
    this.setState({...items[0]})
  }

  render() {
    const { img, title, description, selectedIndex } = this.state
    const { header, items, right } = this.props
    let className = 'Carousel'
    if (right) className += ' Carousel--right'
    return (
      <div className={className}>
        <div className="Carousel__img" style={{ backgroundImage: `url(${img})` }}/>
        <div className="Carousel__overlay">
          <div className="Carousel__overlayTop">
            <div className="Carousel__header">{header}</div>
            <div className="Carousel__title">{title}</div>
            <div className="Carousel__description">{description}</div>
          </div>
          <div className="Carousel__icons">
            {items.map((item, index) => {
              const { icon } = item
              let className="Carousel__icon"
              if (selectedIndex === index) className += ' Carousel__icon--selected'
              return (
                <div key={index} className={className} onClick={() => this.select(item, index)}>
                  <img alt="icon" src={icon}/>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Carousel
