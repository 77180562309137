import React from 'react'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../../../strings'
import FieldInput from '../../../../componentLibrary/Models/FieldInput'
import Button from '../../../../componentLibrary/Fragments/Button'
import { clearModalContent } from '../../../../redux/actions'
import { resendEmailVerification } from '../../../../redux/actions/user'
import './styles.scss'


const mapDispatchToProps = dispatch => {
    return {
        resendEmailVerification: (email) => dispatch(resendEmailVerification(email)),
        clearModalContent: () => dispatch(clearModalContent()),
    }
}

class ResendEmail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            loading: false,
        }
        this.updateEmail = this.updateEmail.bind(this)
        this.clearModal = this.clearModal.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    updateEmail(value, name) {
        this.setState({ [name]: value })
    }

    clearModal() {
        this.props.clearModalContent()
        this.setState({ email: '' })
    }

    async handleFormSubmit() {
        const { email } = this.state
        if (_.isEmpty(email)) {
            this.generateErrorModal()
            return
        }
        this.setState({ loading: true })
        try {
            await this.props.resendEmailVerification(email)
            swal(`${strings.getString("CHECK_EMAIL_FOR_NEXT_STEPS", "Please check your email for next steps")}`, {
                icon: "success",
            })
            this.clearModal()
        } catch (err) {
            this.generateErrorModal()
        }
        this.setState({ loading: false })
    }

    generateErrorModal() {
        swal({
            icon: "error",
            title: strings.getString("UH_OH", "Uh Oh!"),
            content: (
                <div className="ResendEmail__modal">
                    <p>{strings.getString("WARNING_EMAIL_INVALID", "It looks like you haven't entered a valid email address")}.</p>
                    <p>{strings.getString("INPUT_ANOTHER", "Please input another and try again.")}</p>
                </div>
            )
        })
    }


    render() {
        const { loading } = this.state

        return (
            <div className="ResendEmail">
                <div className="ResendEmail__header">{strings.getString("RESEND_EMAIL_TITLE", "I need to to validate my Account")}</div>

                <p>{strings.getString("RESEND_EMAIL_INFO", "Enter your Email Address and we will send another email to validate your account with.")}</p>
                <p>{strings.getString("RESEND_EMAIL_WHITELISTED", "Please also ensure that your email settings are not preventing you from receiving emails from crowfall.com.")}</p>


                <FieldInput name="email" onChange={this.updateEmail} placeholder={strings.getString("EMAIL_ADDRESS", "Email Address")} />

                <div className="ResendEmail__buttons">
                    <div className="ResendEmail__button"><Button medium gray onclick={this.clearModal} loading={loading} label={strings.getString("CANCEL", "Cancel")}/></div>
                    <div className="ResendEmail__button"><Button medium onclick={this.handleFormSubmit} loading={loading} label={strings.getString("RESEND_EMAIL", "Resend Email")}/></div>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(ResendEmail)