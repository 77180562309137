import React from 'react'
import strings from '../../../strings'
import ContentCard from '../../Elements/ContentCard'
import './styles.scss'

class ArticleList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			visibleCount: 2,
			limit: 21
		}

		this.showMore = this.showMore.bind(this);
	}

	showMore() {
		const { limit, visibleCount } = this.state
		this.setState({
			visibleCount: visibleCount + limit
		})
	}

	componentDidMount() {
		this.showMore()
	}	

	render() {
		const { articles } = this.props
		const { visibleCount } = this.state
		const visible = articles.slice(0, visibleCount)
		const articlesLength = articles.length
		const visibleLength = visible.length

		return (
			<div className="ArticleList">
				<div className='ArticleList__articles'>
					{
						visible.map((article, index) => {
							const { title, thumbUrl, date, shortDescription, uri } = article
							const isMore = visibleLength < articlesLength
							return (
								<div className="ArticleList__article " key={article.title + '-' + index} >
									<ContentCard img={thumbUrl} title={title} date={date} description={shortDescription} cardLink={`/news/articles/${uri}`}/>
									{isMore && index === visibleLength - 1 && <div className="ArticleList__load" onClick={this.showMore}>
										<div className="ArticleList__loadInner">{strings.getString("LOAD_MORE", "Load More")}</div>
									</div>}
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}
}

export default ArticleList;
