import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import connect from 'react-redux/es/connect/connect'
import _ from 'lodash'
import {api} from '../../../utilities/api'
import XsollaMethods from '../XsollaMethods'
import './styles.scss'

const mapStateToProps = state => {
    return {
      language: state.language
    }
  }

class TravianRightOfWithdrawal

    extends React.Component {

    state = {
        solution: null,
        cartSolution: null,
        erwa: null,
        accepted: false
    }

    static getDerivedStateFromProps(props, state) {
        state.solution = props.solution
        state.cartSolution = props.cartSolution
        return state
    }

    componentDidMount = async () => {

        this.setState({
            loading: true,
        })

        const categories = _.result(this.props, 'solution.item_details.categories', []);
        const isGamePack = !_.isNil(_.find(categories, {category_key: 'Game Packs'}));

        const agreementMap = (await api.get(`/public/agreement-definition/${isGamePack ? 'travian-erwa' : 'erwa-non-pack'}?lang=${this.props.language}`)).data

        const agreementRes = await api.get(`/user/agreement/travian-erwa`)
        const agreement = agreementRes.data
        const erwa = {
            requiredIfPresent: agreement.required_if_present,
            label: _.result(agreementMap, `display_strings.acceptance_string`, agreement.agreement_definition_name) || `I accept the ${agreement.name} agreement. `,
            accepted: agreement.accepted,
            externalId: agreement.external_id,
            immutable: _.result(agreementMap, `immutable`, false),
            disabled: _.result(agreementMap, `immutable`, false) && agreement.accepted
        }

        this.setState({
            erwa: erwa,
            loading: false,
        })
    }

    handleSelectMethod = (method) => async () => {
        this.setState({
            loading: true,
        })

        try {
            const res = await api.post(`/store/charge-with-solution/${this.state.checkoutSolutionId}`, {
                travian: {
                    method: method,
                },
            })

            const w = window.open('', 'paymentWindow', 'menubar=0,resizable=1,width=800,height=600')
            w.document.body.innerHTML = `<html><head><title>Loading...</title></head><body style="display: flex;justify-content: center;align-items: center;"><h1>Loading ${method}...Please wait</h1></body></html>`
            const paywallUrl = _.result(res, 'data.transaction.paywall')
            const transactionId = _.result(res, 'data.transaction.transaction_id')
            w.location = paywallUrl

            const check = setInterval(() => {
                if (w.closed) {
                    clearInterval(check)
                    this.setState({
                        loading: false,
                    })
                    this.state.handleTxn(transactionId).catch(console.error)
                }
            }, 500)
        } catch (err) {
            console.log('Error when attempting to charge-with-solution', err)
            this.state.handleTxn(null, {
                fraud: true,
                message: _.result(err, 'response.data.message', 'Unable to complete the transaction at this time')
            })
        }
    }

    handleMethodChange = (e, {name, value}) => {
        this.setState({
            selectedMethod: value,
        })
    }

    handleAgreementChange = () => {
        this.setState({
            accepted: true
        })
    }


    render() {

        const {loading, erwa, accepted, solution, cartSolution} = this.state
        const acceptanceStyle = {
            fontSize: '20px',
            textAlign:'left',
            padding: '50px 0',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
        }

        return (
            <div>

                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>


                {(erwa && !accepted) && (
                    <div style={acceptanceStyle}>
                        <input style={{flexShrink:0, width:'20px', height: '20px', marginRight: '10px'}} type='checkbox' checked={erwa.accepted} disabled={erwa.disabled} value={erwa.externalId}
                               id={erwa.externalId} onChange={this.handleAgreementChange.bind(this)}/>
                        <label htmlFor={erwa.externalId} dangerouslySetInnerHTML={{__html: erwa.label}}></label>
                    </div>
                )}

                {accepted && (
                    <XsollaMethods solution={solution} cartSolution={cartSolution} />
                )}



            </div>
        )
    }
}

export default connect(mapStateToProps, null)(TravianRightOfWithdrawal)
