import React from 'react'
import _ from 'lodash'
import strings from '../../../strings'
import WalletMethods from '../WalletMethods'
import XsollaMethods from '../XsollaMethods'
import TravianRightOfWithdrawal from '../TravianRightOfWithdrawal'
import './styles.scss'


class PaymentMethods extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArtcraft: false,
            isTravian: false,
            walletPurchase: null,
        }
    }

    walletSelectionValidation = async (status) => {
        this.props.walletSelectionValidation(status);
    }

    componentDidMount() {
        const { agreements } = this.props
        const reducedAgreements = !_.isEmpty(agreements) && agreements.reduce((acc, curr) => {
            acc[curr.external_id] = curr
            return acc
        }, {})

        const isArtcraft = _.result(reducedAgreements, 'artcraft-tc.accepted')
      const isTravian = _.result(reducedAgreements, 'travian-tc.accepted') || _.result(reducedAgreements, 'crowfall-eu-tc.accepted')

        this.setState({ isArtcraft, isTravian })
    }

    render() {

        const { isArtcraft, isTravian } = this.state
        const { availablePaymentMethods, solution, handleTxn, guild, guildWallet, guildRank, item, cartSolution, isSubscribe } = this.props
        const isRmt = availablePaymentMethods && availablePaymentMethods.rmt ? true : false
        const isWalletTransaction = availablePaymentMethods && availablePaymentMethods.wallet ? true : false

        const headerString = isRmt ? strings.getString('INPUT_PAYMENT_METHOD', 'Input Payment Method') : strings.getString('SELECT_PAYMENT_METHODS', 'Select Payment Methods')
        const subString = isRmt ?
            strings.getString("STORE_CONTINUE_TO_ENTER_PAYMENT", "Continue to checkout to input your payment method - You may be taken to a new window to complete the process.") :
            strings.getString('STORE_SELECT_PAYMENT_METHOD', 'Select a payment method to continue. You may be taken to a new window to complete the process.')

        return (
          <div className="PaymentMethods">
            <div className="PaymentMethods__header">{headerString}</div>
                {isSubscribe && <p>{strings.getString("SAVE_PAYMENT_FOR_SUBSCRIPTIONS", "To ensure continued membership in the Crowfall VIP program, be sure to save your preferred payment method in the payment provider's screen during checkout.")}</p>}

                {isRmt && !isTravian && isArtcraft && (
                    <XsollaMethods solution={solution} cartSolution={cartSolution} isSubscribe={isSubscribe}/>
                )}
                {isRmt && isTravian && (
                    <TravianRightOfWithdrawal solution={solution} cartSolution={cartSolution}/>
                )}
                {isWalletTransaction && (
                    <WalletMethods
                        item={item} guildRank={guildRank} solution={solution} cartSolution={cartSolution}
                        guild={guild} guildWallet={guildWallet} handleTxn={handleTxn} isSubscribe={isSubscribe} walletSelectionValidation={this.walletSelectionValidation}/>
                )}
            </div>
        )
    }
}

export default PaymentMethods
