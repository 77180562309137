import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const TermsAndConditions16 = () => (
  <Page contentId={assets.contentfulIds.TERMS_AND_CONDITIONS_1_6}>
  </Page>
)

export default TermsAndConditions16
