import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import _ from 'lodash'
import strings from '../../../../strings'
import LocLink from '../../../../componentLibrary/Elements/LocLink'
import Button from '../../../../componentLibrary/Fragments/Button'
import './styles.scss'
import swal from '@sweetalert/with-react'


const mapStateToProps = state => {
    return {
        language: state.language,
        user: state.user.userProfile,
    }
}

class UpgradeButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            upgradeOptions: [],
            selectedUpgrade: null,
            redirectTo: null,
        }
    }

    componentDidMount() {
        const { item } = this.props
        const upgradeOptions = item ? item.upgrades.map(upgrade => {
            return {
                value: upgrade.id,
                label: `${upgrade.name} - ${_.result(upgrade, 'display_strings.price_after_upgrade')} (value: ${_.result(upgrade, 'display_strings.upgrade_amount')})`,
            }
        }) : []
        this.setState({ upgradeOptions })
    }

    updateDropdown(value, name) {
        this.setState({ [name]: value })
    }

    async handleUpgrade(item) {
        const val = await swal({
            title: strings.getString("UPGRADE_OPTIONS", "Upgrade Options"),
            content: (
                <div className="UpgradeButton__modal">
                    <p dangerouslySetInnerHTML={{__html: strings.getString(
                        "UPGRADE_SELECT", 
                        `Choose an existing item on your account to use as an upgrade for ${item.name}. The existing item on your account will be consumed and removed from your account upon completion of the upgrade. The price shown will be the new purchase price.`, 
                        { name: item.name }
                    ) }}/>
                    <select id="UpgradeButton--upgradeSelect" className="UpgradeButton__dropdown">
                        <option value="">{strings.getString("SELECT_AN_UPGRADE", "Select an Item for upgrade")}</option>
                        {this.state.upgradeOptions.map((option, index) => {
                            const { label, value } = option
                            return (
                                <option key={`UpgradeButton--option${index}${value}`} value={value}>{label}</option>
                            )
                        })}
                    </select>
                </div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                submit: {
                    value: "submit",
                    text: strings.getString("SELECT_UPGRADE", "Select Upgrade"),
                }
            }
        })
        if (val === "submit") {
            const upgradeSelect = document.getElementById("UpgradeButton--upgradeSelect")
            const upgradeSelection = !_.isEmpty(upgradeSelect) ? upgradeSelect.value : ""
            if (_.isEmpty(upgradeSelection)) {
                await this.generateUpgradeErrorMessage()
                this.handleUpgrade(item)
            } else {
                this.setState({ redirectTo: `/${this.props.language}/checkout/${item.item_id}?upgrade=true&upgradeId=${upgradeSelection}` })
            }
        } else {
            return
        }
    }

    async generateUpgradeErrorMessage() {
        await swal({
            icon: 'error',
            title: strings.getString("UH_OH", "Uh Oh!"),
            content: ( <p>{strings.getString("ERROR_SELECT_OPTION_FROM_LIST", "Please select an option from the provided list.")}</p> )
        })
    }

    async generateGenericErrorMessage() {
        await swal({
            icon: 'error',
            title: strings.getString("UH_OH", "Uh Oh!"),
            content: ( <p>{strings.getString("ERROR_GENERIC_MESSAGE", "There was an error processing your request. Please try again.")}</p> )
        })
    }

    render() {
        const { user, medium, large, item } = this.props
        const returnPath = btoa(`${this.props.location.pathname}${this.props.location.search}`)
        const { redirectTo } = this.state

        return (
            <React.Fragment>

                {redirectTo && <Redirect to={redirectTo}/>}

                {user.username ? (
                    <>
                        {medium && <Button blue onclick={() => this.handleUpgrade(item)} medium>{strings.getString("UPGRADE", "Upgrade")}</Button>}
                        {large && <Button blue onclick={() => this.handleUpgrade(item)} fill>{strings.getString("UPGRADE", "Upgrade")}</Button>}
                    </>
                ) : (
                    <LocLink to={`/login?r=${returnPath}`}>
                        {medium && <Button blue medium>{strings.getString("UPGRADE", "Upgrade")}</Button>}
                        {large && <Button blue fill>{strings.getString("UPGRADE", "Upgrade")}</Button>}
                    </LocLink>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(UpgradeButton))