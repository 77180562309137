import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import { Button, Checkbox, Container, Form, Grid, Header, Select, TextArea } from 'semantic-ui-react'
import LocLink from '../../componentLibrary/Elements/LocLink'
import { api } from '../../utilities/api'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../strings'

class InfluencersPage extends React.Component {

  state = {
    channelUrl: '',
    twitterUrl: '',
    facebookUrl: '',
    phoneNumber: '',
    testAccess: false,
    acceptLegal: false,
    affiliateAccess: false,
    largestAudience: '',
    description: '',
    countryOptions: [],
    loading: false,
    accepted: null,
  }

  handleChange = (e, { name, value } = {}) => {
    this.setState({ [name]: value })
  }

  handleToggle = (e, { name }) => this.setState({ [name]: !this.state[name] })

  componentDidMount = async () => {

    const app = await api.get('/user/agreement/influencer-application')

    this.setState({ accepted: _.result(app, 'data.accepted', false) })

    const countryMap = (await api.get('/public/definition/country-iso')).data

    const countries = Object.keys(countryMap).map(c => {
      return {
        key: countryMap[c].country_iso_code,
        value: `${countryMap[c].country_name} (${countryMap[c].country_iso_code})`,
        text: countryMap[c].country_name,
        flag: _.lowerCase(countryMap[c].country_iso_code),
        region: countryMap[c].country_name,
      }
    }).filter(c => (!_.isNil(c.region) && !['aq', 'bq', 'cw', 'gg', 'im', 'je', 'bl', 'mf', 'sx', 'ss'].includes(c.flag)))

    this.setState({
      countryOptions: countries,
    })
  }

  sendApplication = async () => {
    this.setState({ loading: true })
    await api.post('/user/agreement/influencer-application/accept', {
      metadata: {
        channel_url: this.state.channelUrl,
        twitter_url: this.state.twitterUrl,
        facebook_url: this.state.facebookUrl,
        phone_number: this.state.phoneNumber,
        test_access: this.state.testAccess,
        accept_legal: this.state.acceptLegal,
        affiliate_access: this.state.affiliateAccess,
        largest_audience: this.state.largestAudience,
        description: this.state.description,
      },
    })

    swal(strings.getString("SUCCESSFULLY_SUBMITTED_APP", 'Successfully Submitted Application'), {
      icon: 'success',
    })

    this.setState({ accepted: true, loading: false })
  }

  render() {

    const { channelUrl, twitterUrl, facebookUrl, phoneNumber, testAccess, acceptLegal, affiliateAccess, largestAudience, description, countryOptions, loading, accepted } = this.state

    const disabled = _.isEmpty(channelUrl) || !acceptLegal

    return (
      <Page contentId={assets.contentfulIds.INFLUENCERS_PAGE}>
        <Container>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={2}></Grid.Column>
              <Grid.Column width={6}>
                <Header as='h2'>{strings.getString("REQUIREMENTS", "Requirements")}</Header>
                <ul>
                  <li>{strings.getString("INFLUENCERS_REQUIREMENTS_ONE", "Channel must be regularly active for the previous four months")}</li>
                  <li>{strings.getString("INFLUENCERS_REQUIREMENTS_TWO", "2500+ subscribers for YouTube OR")}</li>
                  <li>{strings.getString("INFLUENCERS_REQUIREMENTS_THREE", "350+ peak viewers for Twitch")}</li>
                  <li>{strings.getString("INFLUENCERS_REQUIREMENTS_FOUR", "Ability to provide constructive feedback")}</li>
                  <li>{strings.getString("INFLUENCERS_REQUIREMENTS_FIVE", "Behavior and content within our Terms and conditions")}</li>
                </ul>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h2'>{strings.getString("BENEFITS", "Benefits")}</Header>
                <ul>
                  <li>{strings.getString("INFLUENCERS_BENEFITS_ONE", "Early access to Crowfall")}</li>
                  <li>{strings.getString("INFLUENCERS_BENEFITS_TWO", "Features on Crowfall.com and on our social media channels")}</li>
                  <li>{strings.getString("INFLUENCERS_BENEFITS_THREE", "Access to training materials for basic video editing")}</li>
                  <li>{strings.getString("INFLUENCERS_BENEFITS_FOUR", "Dedicated support and feedback")}</li>
                </ul>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <Container text>
          {accepted === false ? (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Input required
                                autoComplete="off"
                                type="url"
                                label={strings.getString("CHANNEL_URL", "Channel URL")}
                                placeholder={strings.getString("CHANNEL_URL", "Channel URL")}
                                name='channelUrl'
                                value={channelUrl}
                                onChange={this.handleChange}/>
                    <Form.Input autoComplete="off"
                                type="url"
                                label={strings.getString("TWITTER_URL", "Twitter URL")}
                                placeholder={strings.getString("TWITTER_URL", "Twitter URL")}
                                name='twitterUrl'
                                value={twitterUrl}
                                onChange={this.handleChange}/>
                    <Form.Input autoComplete="off"
                                type="url"
                                label={strings.getString("FACEBOOK_URL", "Facebook URL")}
                                placeholder={strings.getString("FACEBOOK_URL", "Facebook URL")}
                                name='facebookUrl'
                                value={facebookUrl}
                                onChange={this.handleChange}/>
                    <Form.Input autoComplete="off"
                                type="phone"
                                label={strings.getString("PHONE_NUMBER", "Phone Number")}
                                placeholder={strings.getString("PHONE_NUMBER", "Phone Number")}
                                name='phoneNumber'
                                value={phoneNumber}
                                onChange={this.handleChange}/>

                    <Form.Field>
                      <label>{strings.getString("LARGEST_AUDIENCE", "Largest Audience")}</label>
                      <Select placeholder={strings.getString("LARGEST_AUDIENCE", "Largest Audience")} value={largestAudience}
                              name="largestAudience"
                              search
                              selection
                              options={countryOptions} onChange={this.handleChange}/>
                    </Form.Field>

                    <Form.Field>
                      <label>{strings.getString("TELL_US_ABOUT_YOU", "Tell us about you")}</label>
                      <TextArea placeholder={strings.getString("TELL_US_ABOUT_YOU", "Tell us about you")} name="description" value={description}
                                onChange={this.handleChange}/>
                    </Form.Field>

                    <Form.Field required>
                      <Checkbox label={(<label>{strings.getString("FORMS_ACCEPT_ACE_AGREEMENTS", "Largest Audience")} <LocLink
                        to="/monumental/terms-and-conditions"> {strings.getString("TERMS_AND_CONDITIONS", "Terms and conditions")}</LocLink>, <LocLink
                        to="/monumental/rules-of-conduct/">{strings.getString("RULES_OF_CONDUCT", "Rules of conduct")}</LocLink> {strings.getString("AND", 'and')} <LocLink
                        to="/monumental/privacy-policy">{strings.getString("PRIVACY_POLICY", "Privacy Policy")}</LocLink></label>)} checked={acceptLegal}
                                name="acceptLegal"
                                onChange={this.handleToggle}/>
                    </Form.Field>

                    <Form.Field>
                      <Checkbox label={(<label>{strings.getString("FORMS_APPLY_FREE_ACCESS", "I would like to apply for complementary Crowfall access.")}</label>)}
                                checked={testAccess}
                                name="testAccess"
                                onChange={this.handleToggle}/>
                    </Form.Field>

                    <Form.Field>
                      <Checkbox label={(
                        <label>{strings.getString("FORMS_AFFILIATE_REVENUE_SHARING", "I am interested in becoming a Crowfall affiliate for revenue sharing (requires larger audience reach).")}</label>)} checked={affiliateAccess}
                                name="affiliateAccess"
                                onChange={this.handleToggle}/>
                    </Form.Field>

                    <Container textAlign="right">
                      <Button loading={loading} className="Button__ACE" primary type='button'
                              disabled={disabled || loading}
                              onClick={this.sendApplication}>
                        {strings.getString("SUBMIT_APPLICATION", "Submit Application")}
                      </Button>
                    </Container>


                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>

          ) : null}
          {accepted === true ? (<Header as='h3' textAlign="center">{strings.getString("FORMS_ALREADY_SUBMITTED_APPLICATION", "You have already submitted your application.")}</Header>) : null}
        </Container>

      </Page>
    )
  }
}

export default InfluencersPage
