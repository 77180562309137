import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import strings from '../../../../strings'
import Menu from '../Menu'


const mapStateToProps = (state, ownProps) => {
    const user = state.user.userProfile
    const accountGuildInvites = state.user.guildInvites
    const accountGiftsAvailable = state.user.giftsAvailable
    
    let location = ownProps.location.pathname.split(/[\s/]/)
    const search = ownProps.location.search
    location.shift()
    location.shift()
    location = location.join('/')
    if (!_.isEmpty(search)) location += search
    let menuItems = []
    if (_.isNil(_.result(user, 'username'))) {
        menuItems.push({
            label: strings.getString('SIGN_IN', 'Sign In'),
            link: '/login'
        })
    } else {
        menuItems.push({
            label: user.username,
            icon: 'user',
            links: [
                {
                    links: [
                        {
                            label: strings.getString("ACCOUNT_PROFILE"),
                            link: '/account/profile',
                            icon: 'user'
                        },
                        {
                            label: strings.getString("ORDER_HISTORY"),
                            link: '/account/order-history',
                            icon: 'money-bill'
                        },
                        // {
                        //     label: strings.getString("SUPPORT_REQUEST"),
                        //     link: '/support',
                        //     icon: 'life-ring'
                        // },
                        {
                            label: strings.getString("SIGN_OUT"),
                            link: '/logout',
                            icon: 'sign-out-alt'
                        }
                    ]
                }
            ]
        })
        if (accountGuildInvites.length > 0 || accountGiftsAvailable.length > 0) {
            const invitesCount = accountGuildInvites.length
            const giftsCount = accountGiftsAvailable.length
            const notificationsLink = giftsCount >= 1 && invitesCount === 0 ? '/account/gifts-received' : '/account/guild-invites'
            menuItems.unshift({
                label: invitesCount + giftsCount,
                icon: 'bell',
                link: notificationsLink,
                notification: true,
            })
        }
    }


    return {
        menuItems,
        user,
    }
}

export default withRouter(connect(mapStateToProps)(Menu))
