import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../../../strings'
import FieldInput from '../../../../componentLibrary/Models/FieldInput'
import LocLink from '../../../../componentLibrary/Elements/LocLink'
import Button from '../../../../componentLibrary/Fragments/Button'
import { verifyUsernameAvailability, clearModalContent, setModalContent } from '../../../../redux/actions'
import './styles.scss'


const mapStateToProps = state => {
    return {
        user: state.user.userProfile,
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        verifyUsernameAvailability: (username) => dispatch(verifyUsernameAvailability(username)),
        clearModalContent: () => dispatch(clearModalContent()),
        setModalContent: (content) => dispatch(setModalContent(content)),
    }
}

class GiftToButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectTo: null,
            username: "",
        }
        this.updateInput = this.updateInput.bind(this)
        this.clearGiftingModal = this.clearGiftingModal.bind(this)
        this.submitTargetUserForGift = this.submitTargetUserForGift.bind(this)
    }

    recordProductClick(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "select_content",{
            "content_type": "product",
            "items": [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                }
            ]
        })
    }

    updateInput(value, name) {
        this.setState({ [name]: value })
    }

    async handleGiftToUser(item) {
        this.props.setModalContent(
            <GiftingModal
                item={item} inputValue={this.state.username} updateInput={this.updateInput}
                cancel={this.clearGiftingModal} submit={this.submitTargetUserForGift}/>
        )
    }

    clearGiftingModal() {
        this.setState({ username: null })
        this.props.clearModalContent()
    }

    async submitTargetUserForGift() {
        const { username } = this.state
        const { item, clearModalContent, verifyUsernameAvailability } = this.props
        if (_.isEmpty(username)) {
            this.generateUsernameErrorMessage()
            clearModalContent()
            this.handleGiftToUser(item)
        } else {
            try {
                const usernameStatus = await verifyUsernameAvailability(username)
                if (usernameStatus === 'taken') {
                    this.recordProductClick(item)
                    this.setState({ redirectTo: `/checkout/${item.item_id}?gift=true&targetUsername=${username}` })
                    clearModalContent()
                } else {
                    this.generateUsernameErrorMessage()
                    clearModalContent()
                    this.handleGiftToUser(item)
                }
            } catch (err) {
                this.generateGenericErrorMessage()
                clearModalContent()
                this.handleGiftToUser(item)
            }
        }
    }

    async generateGenericErrorMessage() {
        await swal({
            icon: 'error',
            title: strings.getString("UH_OH", "Uh Oh!"),
            content: ( <p>{strings.getString("ERROR_GENERIC_MESSAGE", "There was an error processing your request. Please try again.")}</p> )
        })
    }

    async generateUsernameErrorMessage() {
        await swal({
            icon: 'error',
            title: strings.getString("UH_OH", "Uh Oh!"),
            content: (
                <p>{strings.getString("GIFT_INVALID_USERNAME", "Please enter a valid Username to send your gift to.")}</p>
            )
        })
    }


    render() {
        const { item, user, medium, large } = this.props
        const returnPath = btoa(`${this.props.location.pathname}${this.props.location.search}`)

        return (
            <React.Fragment>

                {this.state.redirectTo && <Redirect to={this.state.redirectTo}/>}

                {user && user.username ? (
                    <>
                        {medium && <Button green onclick={() => this.handleGiftToUser(item)} medium label={strings.getString("GIFT_TO_PLAYER", "Gift to Player")}/>}
                        {large && <Button green onclick={() => this.handleGiftToUser(item)} fill label={strings.getString("GIFT_TO_PLAYER", "Gift to Player")}/>}
                    </>
                ) : (
                    <LocLink to={`/login?r=${returnPath}`}>
                        {medium && <Button green medium label={strings.getString("GIFT_TO_PLAYER", "Gift to Player")}/>}
                        {large && <Button green fill label={strings.getString("GIFT_TO_PLAYER", "Gift to Player")}/>}
                    </LocLink>
                )}
            </React.Fragment>
        )
    }
}

class GiftingModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = { loading: false }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit() {
        this.setState({ loading: true })
        this.props.submit()
    }

    render() {
        const { item, cancel, inputValue, updateInput } = this.props
        const { loading } = this.state

        return (
            <div className="GiftToButton__modal">
                <div className="GiftToButton__modalHeader">{strings.getString("GIFT_TO_PLAYER", "Gift to Player")}</div>

                <p dangerouslySetInnerHTML={{ __html: strings.getString("GIFT_INPUT_INSTRUCTIONS", `Enter the Player's Display Name for the friend receiving <strong>${item.name}</strong>.`, { name: item.name }) }}/>
                <FieldInput name="username" onChange={updateInput} value={inputValue}
                            label={strings.getString("USERNAME", "Username")} placeholder={strings.getString("USERNAME", "Username")} />

                <div className="GiftToButton__modalButtons">
                    <div className="GiftToButton__modalButton"><Button medium loading={loading} onclick={cancel} label={strings.getString("CANCEL", "Cancel")}/></div>
                    <div className="GiftToButton__modalButton"><Button medium loading={loading} green onclick={this.handleSubmit} label={strings.getString("PROCEED_TO_CHECKOUT", "Proceed to Checkout")}/></div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GiftToButton))
