/*

ELEMENT: BannerHeader


PROPERTY     DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

short        false    optional     Boolean   Shortens height of keyArt by almost 50%.
isMarketing  false    optional     Boolean   left-aligned text header used for Trials, Articles, etc.
addAccent    false    optional     Boolean   Adds accent graphic to bottom of banner image/video.

*/

import React from 'react'
import assets from '../../../assets'
import './styles.scss'


const BannerHeader = (props) => {
    let title = ''
    let backgroundStyle = {}
    if ( props.header.title ) {
        title = props.header.title;
    } else { title = props.title };
    const { header, isMarketing, addAccent, short, titleAsset } = props;
    const { banner, subtitle, ribbon } = header;
    if (banner) backgroundStyle.backgroundImage = `url(${banner})`

    let className = 'BannerHeader';
    if (isMarketing) className += ' BannerHeader--isMarketing'
    if (short) className += ' BannerHeader--short'

    return (
        <React.Fragment>
            <div className={className} style={backgroundStyle}>
                <div className='BannerHeader__content'>
                    {title && <div className='BannerHeader__title'>
                        <span>{title}</span>
                    </div>}
                    {titleAsset && <img className='BannerHeader__titleAsset' alt='Crowfall' src={titleAsset}/> }
                    {subtitle && <div className='BannerHeader__subtitle' dangerouslySetInnerHTML={{ __html: subtitle }}/>}
                </div>
                {addAccent && <img className='BannerHeader__accent' src={assets.staticImageUrls.HEADER_ACCENT} alt='Accent Graphic'/>}
            </div>
            {ribbon &&
                <div className="BannerHeader__ribbon">{ribbon}</div>
            }
        </React.Fragment>
    )
}

export default BannerHeader;
