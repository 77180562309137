import React from 'react'
import './styles.scss'

class AssetPicker extends React.Component {

  render() {
    const { label, children, selected } = this.props

    return (
      <div className="AssetPicker">
        <div className="AssetPicker__title">{label}</div>
        {children && children.map((asset, index) => {
          let className = 'AssetPicker__asset'
          if (asset.key === selected) className += ' AssetPicker__asset--selected'
          return (
            <div className={className} key={index}>
              {asset}
            </div>
          )
        })}
      </div>
    )
  }
}

export default AssetPicker
