import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const EuImprintPolicy = () => (
  <Page contentId={assets.contentfulIds.EU_IMPRINT}>
  </Page>
)

export default EuImprintPolicy

