

import React from 'react'
import './styles.scss'

const Radio = (props) => {

  const { label, isSelected, toggle } = props
  return (
    <div className="Radio" onClick={toggle}>
      <div className="Radio__button">
        {isSelected && <div className="Radio__check"/>}
      </div>
      <div className="Radio__label">{label}</div>
    </div>
  )
}

export default Radio
