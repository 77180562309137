import {
    ADD_PROMOTIONS,
    PAGINATE_PROMOTIONS,
    CLEAR_PROMOTIONS,
    SET_SELECTED_PROMOTION,
    CLEAR_SELECTED_PROMOTION,
} from '../actionTypes'

import contentful from '../../utilities/contentful'
import _ from 'lodash'


// API calls

export const fetchPromotions = () => {
    return async function (dispatch) {
        const add = (promos) => {
            const newPromos = promos.map(promo => {
                return promo
            })
            dispatch(addPromotions(modelPromotions(newPromos)))
        }
        const options = {
            contentType: 'promo',
            match: null,
            language: undefined,
            extra: 'order=-fields.endTime',
            skip: 0,
            limit: 1,
        }
        contentful.getPaginatedEntries(options, add)
    }
}

export const fetchSinglePromotion = (uri) => {
    return async function (dispatch) {
        const entries = await contentful.getEntries('promo', `fields.uri=${uri}`)
        const selected = modelPromotions(entries)[0]
        dispatch(setSelectedPromotion(selected))
    }
}


// Dispatch Action Types

export const addPromotions = promos => {
    return (dispatch) => {
        dispatch({
            type: ADD_PROMOTIONS,
            payload: promos
        })
    }
}

export const paginatePromotions = page => ({
    type: PAGINATE_PROMOTIONS,
    payload: page,
})

export const clearPromotions = () => ({
    type: CLEAR_PROMOTIONS
})

export const setSelectedPromotion = promo => ({
    type: SET_SELECTED_PROMOTION,
    payload: promo,
})

export const clearSelectedPromotion = () => ({
    type: CLEAR_SELECTED_PROMOTION
})


// Helper Functions

const modelPromotions = (promos) => {
    promos.map(promo => {
        promo.bannerAssetUrl = _.result(promo, 'bannerAsset.assetUrl')
        return promo
    })
    return promos
}