import React, { Fragment } from 'react'
import Cookies from 'js-cookie'
import connect from 'react-redux/es/connect/connect'
import _ from 'lodash'
import queryString from 'query-string'
import {Dimmer, Icon, Loader, Message, Container, Header, Form, Button} from 'semantic-ui-react'
import { api, setAuth } from '../../utilities/api'
import assets from '../../assets'
import strings from '../../strings'

import ProductGrid from '../../Store/ShopPage/ProductGrid'
import Title from '../../componentLibrary/Fragments/Title'
import SocialLink from '../../componentLibrary/Elements/SocialLink'
import RegionBlock from '../../componentLibrary/Models/RegionBlock'
import Page from '../../componentLibrary/Models/Page'

import { setSession } from '../../redux/actions'
import './styles.scss'


const mapStateToProps = state => {
  return {
    language: state.language,
    config: state.config,
    session: state.session
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: session => dispatch(setSession(session)),
  }
}

class ValidateEmail extends React.Component {

  state = {
    validated: false,
    error: false,
    username: null,
    newUsername: '',
    loading: true,
    token: null,
    usernameSet: false,
    email: null,
    catalog: [],
    password: '',
    confirmPassword: '',
    success: false,
    usernameValid: true,
    errorMessageUsername: null,
    errorUsername: null,
    checkingUsername: null,
    errorMessage: ''
  }

  componentDidMount = async () => {
    const q = _.result(this.props, 'location.search')
    const query = queryString.parse(q) || {}

    this.setState({
      token: query.token,
      loading: true,
    })

    try {
      const res = await api.get(`/user/validation/${query.token}`)
    const { validation_token_active: validationTokenActive, validation_set: validationTokenSet, username, username_set: usernameSet } = res.data
    this.setState({ username, token: query.token, usernameSet })
    if (validationTokenActive && !validationTokenSet && usernameSet) {
      await this.validateAccount()
    } else if (!validationTokenActive && !validationTokenSet) {
      this.setState({
        error: true,
      })
    } else if (validationTokenSet) {
      this.setState({
        validated: true,
      })
    }

    this.setState({
      loading: false
    })

  } catch (err) {
    this.setState({
      error: true,
      loading: false,
    })
  }
  this.getBackerPackDetails()

  window.gtag('event', 'conversion', {'send_to': 'AW-739661874/vD4-CICavt0BELKw2eAC'});
}

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleUsernameChange = async (e, { name, value }) => {
    this.setState({ [name]: value })
    if (!_.isEmpty(value) && value.length >= 3) {
      this.checkUsername(value)
    }
  }

  checkUsername = _.debounce(async (value) => {

    const usernameCheck = new RegExp(`^([a-zA-Z\\d]((?!(\\.|\\-|_)(\\.|\\-|_))[\\w\\d\\-\\.]){1,128}[a-zA-Z\\d])$`, 'i')
    if (!usernameCheck.test(value)) {
      this.setState({
        errorUsername: true,
        errorMessageUsername:  `${strings.getString('REGISTRATION_ERROR_USERNAME_FORMAT', 'The account name cannot contain any special characters or symbols, or start with a numeric digit, or contain multiple adjacent underscores and hyphens and must end with a character or digit.')}`
      })
      return;
    }

    this.setState({
      checkingUsername: true
    })
    const res = await api.get(`/user/username-available/${value}`)
    this.setState({ usernameValid: _.result(res, 'data.status') === 'available' })
    if (!this.state.usernameValid) {
      this.setState({
        errorUsername: true,
        errorMessageUsername:  strings.getString('REGISTRATION_ERROR_USERNAME_TAKEN', 'The account name is already taken')
      })
    } else {
      this.setState({ errorUsername: false, errorMessageUsername: '' })
    }

    this.setState({
      checkingUsername: false
    })

  }, 400)

validateAccount = async (options) => {
  const validationRes = await api.post(`/user/validate?token=${this.state.token}`, options)
  const session = validationRes.data

  this.setState({
    validated: true,
    loading: false,
    usernameSet: true
  })

  localStorage.setItem('userData', JSON.stringify(session))
  setAuth(_.result(session, 'token'))

  Cookies.set('crowfallInfo', {
    token: _.result(session, 'token'),
    firstName: _.result(session, 'user.first_name'),
    lastName: _.result(session, 'user.last_name'),
    email: _.result(session, 'user.email'),
    username: _.result(session, 'user.account_name'),
    user_id: _.result(session, 'user.user_id'),
    date_expires: _.result(session, 'date_expires'),
  }, { domain: '.crowfall.com', expires: 30, sameSite: 'lax' })

  await this.props.login(session)
}

  handleSubmit = async () => {

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        error: true,
        errorMessage: strings.getString('PASSWORDS_DO_NOT_MATCH', 'The password fields do not match.')
      })
      return
    } else {
      this.setState({
        error: false,
        errorMessage: '',
      })
    }

    this.setState({ loading: true })
    this.validateAccount({
      username: this.state.newUsername,
      password: this.state.password
    })
  }

getBackerPackDetails = async () => {
  const res = this.props.session ? await api.get(`/store/catalog-summary?lang=${this.props.language}`) : await api.get(`/public/store/catalog-summary?lang=${this.props.language}`)
  if (res.status === 200) {
    const items = _.orderBy((res.data || []).map(item => {
      item.heroImage = _.result(item, 'images.0') || '/images/no_item.png'
      item.hasUpgrade = _.isEmpty(item.upgrades)

      let categories = _.result(item, 'categories', []).map(category => {
        return category.category_key
      })

      item.categorySet = _.uniq(categories)
      return item
    }).filter(f => {
      return f.purchasable && f.categories.filter(category => {
        return category.inventory_category_definition_id === '064b6196-98fe-46c7-94d4-9485dad599de'
      }).length >= 1
    }), ['featured', 'weight', 'purchase_amount'], ['desc', 'desc', 'asc'])

    this.setState({
      catalog: items
    })
  }
}

render() {

  const { validated, errorMessage, username, newUsername, error, loading, catalog, usernameSet, password, confirmPassword, success, usernameValid, errorMessageUsername, errorUsername, checkingUsername } = this.state

    return (

      <Fragment>

          <Dimmer active={loading} inverted>
            <Loader/>
          </Dimmer>

        {!usernameSet && (
            <Page>
              <Container text>

                <Header as='h1'>Please select a name and password to complete your account.</Header>

                <Form onSubmit={this.handleSubmit} success={success} error={error}>
                  <Form.Input required error={!usernameValid} loading={checkingUsername} minLength="3" autoComplete="off"
                              label={strings.getString("ACCOUNT_NAME", "Account Name")}
                              placeholder={strings.getString("ACCOUNT_NAME", "Account Name")}
                              name='newUsername' value={newUsername}
                              onChange={this.handleUsernameChange}/>

                  {errorUsername ? (<Message color='red' header='Uh oh!' content={errorMessageUsername}/>) : null}
                  <Form.Input required minLength="8" autoComplete="off" label={strings.getString('PASSWORD', 'Password')}
                              placeholder={strings.getString('PASSWORD', 'Password')}
                              name='password' type='password' value={password}
                              onChange={this.handleChange}/>
                  <Form.Input required minLength="8" autoComplete="off" label={strings.getString('CONFIRM_PASSWORD', 'Confirm Password')}
                              placeholder={strings.getString('CONFIRM_PASSWORD', 'Confirm Password')}
                              name='confirmPassword' type='password' value={confirmPassword}
                              onChange={this.handleChange}/>

                  <Container textAlign="right">
                    <Button className="Button__ACE" primary type='submit' loading={loading} disabled={loading || errorUsername}>
                      {strings.getString('SIGN_UP', 'Sign Up')}
                    </Button>
                  </Container>

                  <Message success header={strings.getString('REGISTRATION_SIGN_UP_SUCCESS', 'Sign Up Completed')}
                           content={strings.getString('REGISTRATION_SIGN_UP_SUCCESS_INFO', 'You\'re all set! Check your email for next steps!')}/>
                  <Message error header="Uh Oh!" content={errorMessage}/>

                </Form>
              </Container>
            </Page>
        )}

          {validated === true ? (
            <div className='ValidateEmailPage'>

              <div className='ValidateEmailPage__banner' style={{backgroundImage: `url(${assets.staticImageUrls.VALIDATE_EMAIL_PAGE_HEADER})`}}>
                <div className='ValidateEmailPage__bannerContent'>
                  <RegionBlock marketable>
                    <h1><strong>{strings.getString( "VALIDATED_ACCOUNT")}</strong></h1>
                    <p><strong>{strings.getString( "WELCOME_TO_COMMUNITY")}</strong></p>
                    <p><strong>{strings.getString( "WHY_NOT_BECOME_A_BACKER")}</strong></p>
                    <p>{strings.getString( "WHAT_WERE_CROWING_ABOUT")}</p>
                  </RegionBlock>
                  <RegionBlock notMarketable>
                    <h1><strong>{strings.getString( "VALIDATED_ACCOUNT")}</strong></h1>
                    <p><strong>{strings.getString( "WELCOME_TO_COMMUNITY")}</strong></p>
                    <p>{strings.getString( "WHAT_WERE_CROWING_ABOUT_NON_GDPR")}</p>
                  </RegionBlock>
                </div>
              </div>

              <RegionBlock marketable>
                <Page removeTopMargin>

                  <Title title={strings.getString("BECOME_A_BACKER").toUpperCase()} center h1/>

                  <div className='ValidateEmailPage__storeCards'>
                    <ProductGrid products={catalog} />
                  </div>

                  <div className='ValidateEmailPage__thankBackersText'>{strings.getString("EMAIL_VALIDATION_THANKS_BACKER")}</div>

                  <div className='ValidateEmailPage__storePriceDisclaimer'>
                    <RegionBlock artcraft>
                      * {strings.getString('STORE_TAX_DISCLAIMER', 'All prices are in USD unless otherwise stated and do not include regional tax.')}
                    </RegionBlock>
                    <RegionBlock eu>
                      * {strings.getString('STORE_TRAVIAN_TAX_DISCLAIMER', 'Order Price VAT is included, if applicable.')}
                    </RegionBlock>
                  </div>

                  <h4 className='ValidateEmailPage__socialText'><strong >{strings.getString("JOIN_US_ON")}</strong></h4>
                  <div className='ValidateEmailPage__socialLinks'>
                    <div className="ValidateEmailPage__socialLink"><SocialLink facebook/></div>
                    <div className="ValidateEmailPage__socialLink"><SocialLink twitter/></div>
                    <div className="ValidateEmailPage__socialLink"><SocialLink twitch/></div>
                    <div className="ValidateEmailPage__socialLink"><SocialLink instagram/></div>
                    <div className="ValidateEmailPage__socialLink"><SocialLink youtube/></div>
                  </div>
                </Page>
              </RegionBlock>
              <RegionBlock notMarketable>
                <Page removeTopMargin>
                  <h4 className='ValidateEmailPage__socialText'><strong >{strings.getString("JOIN_US_ON")}</strong></h4>
                  <div className='ValidateEmailPage__socialLinks'>
                    <div className="ValidateEmailPage__socialLink"><SocialLink facebook/></div>
                    <div className="ValidateEmailPage__socialLink"><SocialLink twitter/></div>
                    <div className="ValidateEmailPage__socialLink"><SocialLink twitch/></div>
                    <div className="ValidateEmailPage__socialLink"><SocialLink instagram/></div>
                    <div className="ValidateEmailPage__socialLink"><SocialLink youtube/></div>
                  </div>
                </Page>
              </RegionBlock>

            </div>

          ) : ''}

          {usernameSet && error ? (
            <Page>

              <Message error icon>
                <Icon name='warning sign'/>
                <Message.Content>
                  <Message.Header>{strings.getString('VALIDATION_ACCOUNT_ERROR', 'Unable to validate account')} {username}</Message.Header>
                  {strings.getString('VALIDATION_ERROR', 'There was an issue when attempting to validate your account.')}
                </Message.Content>
              </Message>

            </Page>
          ) : ''}

      </Fragment>

    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEmail)
