import React from 'react'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import strings from '../../../../strings'
import FieldInput from '../../../../componentLibrary/Models/FieldInput'
import Button from '../../../../componentLibrary/Fragments/Button'
import './styles.scss'
import { clearModalContent } from '../../../../redux/actions'
import { joinGuild } from '../../../../redux/actions/guild-management'
import { fetchUserGuildApplications } from '../../../../redux/actions/user'
import _ from "lodash";


const mapDispatchToProps = dispatch => {
    return {
        clearModalContent: () => dispatch(clearModalContent()),
        joinGuild: (id, message) => dispatch(joinGuild(id, message)),
        fetchGuildApplications: () => dispatch(fetchUserGuildApplications()),
    }
}

class RequestToJoinGuildModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            applicationInput: null,
        }
        this.updateField = this.updateField.bind(this)
        this.handleRequestToJoin = this.handleRequestToJoin.bind(this)
    }

    updateField(value, name) {
        this.setState({ [name]: value })
    }

    async handleRequestToJoin() {
        const { joinGuild, fetchGuildApplications, guildId } = this.props
        const { applicationInput } = this.state

        this.setState({ loading: true })
        try {
            await joinGuild(guildId, applicationInput)
            swal(strings.getString("GUILD_REQUEST_SENT", "Guild request sent"), {
                icon: 'success'
            })
            await fetchGuildApplications()
            this.props.clearModalContent()
            return
        } catch (err) {
            swal(_.result(err.response.data, 'Message'), {
                icon: 'error',
            })
        }
        this.setState({ loading: false })
    }

    render() {

         const { guildName, clearModalContent } = this.props
         const { loading } = this.state

        return (
            <div className="RequestToJoinGuildModal">
                <div className="RequestToJoinGuildModal__header" dangerouslySetInnerHTML={{ __html: strings.getString("REQUEST_TO_JOIN_GUILD", `Request to Join the Guild: ${guildName}`, { guildName }) }} />

                <FieldInput 
                    label={strings.getString("GUILD_APPLICATION_FORM_MESSAGE_LABEL", "Why would you like to become a member of the Guild?")}
                    area xTall name="applicationInput" onChange={this.updateField}
                />

                <div className="RequestToJoinGuildModal__buttons">
                    <div className="RequestToJoinGuildModal__button">
                        <Button medium loading={loading} gray 
                                onclick={clearModalContent} label={strings.getString("CANCEL", "Cancel")}/>
                    </div>
                    <div className="RequestToJoinGuildModal__button">
                        <Button medium loading={loading} 
                                onclick={this.handleRequestToJoin} label={strings.getString("SUBMIT_APPLICATION", "Submit Application")}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(RequestToJoinGuildModal)