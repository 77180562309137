import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { fetchStoreDetails, clearStoreDetails } from '../../../redux/actions/crowfall-store'
import strings from '../../../strings'
import ProductGrid from '../../../Store/ShopPage/ProductGrid'
import RegionBlock from '../../../componentLibrary/Models/RegionBlock'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        language: state.language,
        catalog: state.crowfallStore.catalog,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchStoreDetails: (session, language) => dispatch(fetchStoreDetails(session, language)),
        clearStoreDetails: () => dispatch(clearStoreDetails()),
    }
}

class ChooseAPackPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            packs: []
        }
    }

    componentDidMount() {
        this.getGamePackDetails()
        this.recordToLiquidAdvertising()
    }

    async getGamePackDetails() {
        const { session, language, fetchStoreDetails } = this.props
        await fetchStoreDetails(session, language)
        const filteredCatalog = this.props.catalog.filter(product => product.categorySet.includes("Game Packs") && _.result(product, "external_ids.show_in_registration") === true)
        this.setState({ packs: filteredCatalog })
    }

    recordToLiquidAdvertising() {
        window.gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'session_id': Date.now() + Math.random().toString(36),
            'send_to': 'DC-11063492/crowfall/d_cro002+per_session'
          })
    }

    componentWillUnmount() {
        this.props.clearStoreDetails()
    }

    render() {
        const { packs } = this.state
        return (
            <div className="ChooseAPackPage">
                <p>
                    {strings.getString("ACCOUNT_REGISTRATION_COMPLETE", "You have completed your Account Registration.")}<br/>
                    {strings.getString("CHOOSE_A_GAME_PACK_TO_PLAY_NOW", "Choose one of our featured game packs below to start playing Crowfall now!")}
                </p>
                <p dangerouslySetInnerHTML={{ __html: strings.getString("VIEW_MORE_PACKS_IN_STORE", "Or visit the Crowfall Store to browse even more game pack bundles.") }}/>

                <div className="ChooseAPackPage__packs">
                    <ProductGrid products={packs} useRegisterRedirects={true} />
                </div>

                <div className="ChooseAPackPage__disclaimers">
                    <RegionBlock artcraft>
                        * {strings.getString('STORE_TAX_DISCLAIMER', 'All prices are in USD unless otherwise stated and do not include regional tax.')}
                    </RegionBlock>
                    <RegionBlock eu>
                        * {strings.getString('STORE_TRAVIAN_TAX_DISCLAIMER', 'Order Price VAT is included, if applicable.')}
                    </RegionBlock>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAPackPage)
