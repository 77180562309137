import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import strings from '../../../strings'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
import { api } from '../../../utilities/api'
import {fetchGuildDetails} from "../../../redux/actions/guild-management";
import {
    chargeWithSolution,
    generateBuyNowWithOverrideCheckout,
} from "../../../redux/actions/crowfall-store";
import swal from "@sweetalert/with-react";


const mapStateToProps = state => {
    return {
        user: state.user.userProfile,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchGuildDetails: () => dispatch(fetchGuildDetails()),
        generateBuyNowWithOverrideCheckout: (itemId, language, upgradeId, targetUsername, walletId, address, guildId) => dispatch(generateBuyNowWithOverrideCheckout(itemId, language, upgradeId, targetUsername, walletId, address, guildId)),
        chargeWithSolution: (id) => dispatch(chargeWithSolution(id))
    }
}

class InstantBuyNowButton extends React.Component {

    recordProductClick(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "select_content",{
            "content_type": "product",
            "items": [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                }
            ]
        })
    }
    
    async handleCheckout() {
        const { item, generateBuyNowWithOverrideCheckout, chargeWithSolution } = this.props
        const solution = await generateBuyNowWithOverrideCheckout(item.item_id, 'en-US', null, null, null)
        await chargeWithSolution(solution.checkout_solution_id)
        swal({
            content: <div className="external__message">
                        Claimed
                    </div>,
            icon: 'success'
        });
    }

    render() {
        const { item, user, medium, large, useRegisterRedirects } = this.props
        const returnPath = btoa(`${this.props.location.pathname}${this.props.location.search}`)
    
        return (
            <React.Fragment>
                {
                    user && user.username ? (
                        <div>
                            {medium && <Button green onclick={() => this.handleCheckout()} medium label="Get Now"/>}
                            {large && <Button green onclick={() => this.handleCheckout()} fill label="Get Now"/>}
                        </div>
                    ) : (
                        <LocLink to={`/login?r=${returnPath}`}>
                            {medium && <Button green onclick={() => this.recordProductClick(item)} medium label="Get Now"/>}
                            {large && <Button green onclick={() => this.recordProductClick(item)} fill label="Get Now"/>}
                        </LocLink>
                    )
                }
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstantBuyNowButton))