import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import queryString from 'query-string'
import _ from 'lodash'
import Cookies from 'js-cookie'
import { setAuth } from '../../../utilities/api'
import strings from '../../../strings'
import FieldInput from '../../../componentLibrary/Models/FieldInput'
import Button from '../../../componentLibrary/Fragments/Button'
import HelperLinks from '../HelperLinks'
import { setSession } from '../../../redux/actions'
import { getValidationTokenStatus, validateAccount } from '../../../redux/actions/user'
import './styles.scss'


const mapDispatchToProps = dispatch => {
    return {
        login: session => dispatch(setSession(session)),
        getValidationTokenStatus: (token) => dispatch(getValidationTokenStatus(token)),
        validateAccount: (token) => dispatch(validateAccount(token)),
    }
}

class ValidateEmailPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            code: null
        }
        this.updateInput = this.updateInput.bind(this)
        this.handleSubmitCode = this.handleSubmitCode.bind(this)
    }

    componentDidMount() {
        const search = _.result(this.props, 'location.search')
        const query = queryString.parse(search)
        if (query.code) {
            this.setState({ code: query.code })
            this.handleSubmitCode(query.code)
        }
    }

    recordToLiquidAdvertising() {
        window.gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'session_id': Date.now() + Math.random().toString(36),
            'send_to': 'DC-11063492/crowfall/d_cro002+per_session'
        })
    }

    updateInput(value, name) {
        this.setState({ [name]: value })
    }

    async handleSubmitCode(code) {
        this.setState({ loading: true })

        try {
            const codeStatus = await this.props.getValidationTokenStatus(code)
            const { validation_token_active: validationTokenActive, validation_set: validationTokenSet } = codeStatus
            if (validationTokenActive && !validationTokenSet) {
                this.handleValidateAccount(code)
            } else if (!validationTokenActive && !validationTokenSet) {
                this.generateErrorMessage(
                    strings.getString("ERROR_INVALID_EMAIL_VALIDATION_CODE", "Please enter a valid code to validate your account with.")
                )
            } else if (validationTokenSet) {
                const search = _.result(this.props, 'location.search')
                const query = queryString.parse(search)
                this.props.history.push(`/register?${query.promo ? '&download=true&orderId=0&promo=true' : 'packs=true'}`);
            }
        } catch(err) {
            this.generateErrorMessage(_.result(err, "response.data.message"))
        }

        this.setState({ loading: false })
    }

    generateErrorMessage(errorMessage) {
        swal({
            title: strings.getString("UH_OH", "Uh Oh!"),
            icon: 'error',
            content: ( <div>{errorMessage}</div> )
        })
    }

    async handleValidateAccount(code) {
        const res = await this.props.validateAccount(code)
        const session = res

        localStorage.setItem('userData', JSON.stringify(session))
        setAuth(_.result(session, 'token'))

        Cookies.set('crowfallInfo', {
          token: _.result(session, 'token'),
          firstName: _.result(session, 'user.first_name'),
          lastName: _.result(session, 'user.last_name'),
          email: _.result(session, 'user.email'),
          username: _.result(session, 'user.account_name'),
          user_id: _.result(session, 'user.user_id'),
          date_expires: _.result(session, 'date_expires'),
        }, { domain: '.crowfall.com', expires: 30, sameSite: 'lax' })

        await this.props.login(session)

        const search = _.result(this.props, 'location.search')
        const query = queryString.parse(search)
        this.props.history.push(`/register?${query.promo ? '&download=true&orderId=0&promo=true' : 'packs=true'}`);
        this.recordToLiquidAdvertising()
    }


    render() {
        const { code, loading } = this.state
        return (
            <div className="ValidateEmailPage">
                <p>{strings.getString("VALIDATE_EMAIL_CHECK_INBOX", "We've sent an email with your validation code to your inbox.")}</p>
                <p>{strings.getString("VALIDATE_EMAIL_ENTER_CODE", "Please enter your code below to verify your email and complete your registration.")}</p>
                <FieldInput
                    label={strings.getString("EMAIL_VERIFICATION_CODE", "Email Validation Code")} placeholder={strings.getString("EMAIL_VERIFICATION_CODE", "Email Validation Code")}
                    name="code" value={code} onChange={this.updateInput} autofocus/>

                <div className="ValidateEmailPage__button">
                    <Button label={strings.getString("NEXT", "Next")} loading={loading} medium onclick={() => this.handleSubmitCode(code)}/>
                </div>

                <HelperLinks showCodeHelp/>
            </div>
        )
    }
}

export default withRouter(connect(null, mapDispatchToProps)(ValidateEmailPage))
