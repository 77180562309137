/*

FRAGMENT: Button

Simple Button component

PROPERTY      DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

label                  optional     String    label for button. If provided, children will be ignored.
children               optional     Function  any jsx elements desired to be wrapped in the button
onclick       no-op    optional     Function  function to be passed to Button's onClick
disabled      false    optional     Boolean   disables onclick and lightens color
loading       false    optional     Boolean   prevents onclick from firing when true
small         false    optional     Boolean   makes button smaller
medium        false    optional     Boolena   decreases button size
green         false    optional     Boolean   green button background color

*/

import React from 'react'
import './styles.scss'

class Button extends React.Component {
  render() {
    const { 
      label, children, onclick, disabled, loading, 
      small, green, gray, lightGray, twitchPurple, large, fill, medium 
    } = this.props
    const click = onclick && !disabled && !loading ? onclick : null
    let className = 'Button'
    if (small) className += ' Button--small'
    if (medium) className += ' Button--medium'
    if (large) className += ' Button--large'
    if (green) className += ' Button--green'
    if (gray) className += ' Button--gray'
    if (lightGray) className += ' Button--gray-light'
    if (twitchPurple) className += ' Button--twitchPurple'
    if (fill) className += ' Button--fill'
    if (disabled) className += ' Button--disabled'
    if (loading) className += ' Button--loading'

    return (
      <div className={className} onClick={click}>
        {label || children}
        {loading && <div className="Button__loadingOverlay">
          <div className="Button__spinner"><div></div><div></div><div></div><div></div></div>
        </div>}
      </div>
    )
  }
}

export default Button
