import React from 'react'
import ContentCard from '../../Elements/ContentCard'
import Button from '../../Fragments/Button'
import StaticString from '../../Fragments/StaticString'
import './styles.scss'


const WarStoryCard = props => {

    const { story } = props
    let { username, thumbUrl, shortDescription, publishDate, storyName, uri, } = story

    const ctaButton = ( <Button><StaticString stringName="GET_THE_FULL_STORY"/></Button> )

    return (
        <div className='WarStoryCard'>
            <ContentCard 
                img={thumbUrl}
                title={`${username}: ${storyName}`}
                date={publishDate}
                description={shortDescription}
                cardLink={`/warstories/${uri}`}
                additionalContent={ctaButton}
            />
      </div>
    )
}

export default WarStoryCard