import React from 'react'
import strings from '../../strings'
import Page from '../../componentLibrary/Models/Page'
import LocLink from '../../componentLibrary/Elements/LocLink'
import Button from '../../componentLibrary/Fragments/Button'
import './styles.scss'


const Error404Page = () => {
    return (
        <Page>
            <div className="Error404Page">
                <div className="Error404Page__header">{strings.getString("UH_OH", "Uh Oh!")}</div>
                <div className="Error404Page__subheader">
                    <h3>{strings.getString("PAGE_DOES_NOT_EXIST", "The page you're looking for doesn't exist.")}</h3>
                    <h4>{strings.getString("WHILE_YOURE_HERE", "While you're here, why not learn about our game and community!")}</h4>
                </div>
                <div className="Error404Page__buttons">
                    <div className="Error404Page__button">
                        <LocLink to="/news/articles"><Button label={strings.getString("LATEST_NEWS", "Latest News")}/></LocLink>
                    </div>
                    {/*<div className="Error404Page__button">*/}
                    {/*    <a href="https://community.crowfall.com" rel="noopener noreferrer"><Button label={strings.getString("VIEW_OUR_FORUMS", "View Our Forums")}/></a>*/}
                    {/*</div>*/}
                    {/*<div className="Error404Page__button">*/}
                    {/*    <LocLink to="/store"><Button green label={strings.getString("VISIT_THE_STORE", "Visit the Store")}/></LocLink>*/}
                    {/*</div>*/}
                </div>
            </div>
        </Page>
    )
}

export default Error404Page