import React from 'react'
import { api } from '../utilities/api'
import { Button, Dimmer, Form, Loader, Pagination, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'
import queryString from 'query-string'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsRedeemCodes extends React.Component {

  state = {
    loading: false,
    codes: [],
    newCode: '',
    filteredCodes: [],
    filter: '',
    page: 1,
    totalPages: 1,
    pageSize: 5
  }

  async componentDidMount() {
    this.setState({ loading: true })

    await this.getContent()

    const q = _.result(this.props, 'location.search')
    const query = queryString.parse(q) || {}

    const hasCode = query.code ? true : false
    if (hasCode) {
      await this.setState({
        newCode: query.code
      });

      await this.redeemCode();
    }

    this.setState({ loading: false })
  }

  async getContent() {
    const codes = await api.get(`/user/ks`)
    this.setState({
      codes: _.reverse(_.sortBy(codes.data, 'date_claimed')),
    })

    this.setState({
      filteredCodes: this.state.codes,
      totalPages: Math.floor(this.state.codes.length / this.state.pageSize) + 1,
      page: 1,
    })
  }

  redeemCode = async () => {
    this.setState({ loading: true })
    try {
      await api.post(`/ks/${this.state.newCode}`)
      swal(`${strings.getString('ACCOUNT_REDEEM_CODE_SUCCESS', 'Successfully Redeemed Code')}`
          , {
        icon: 'success',
      }).then(okay => {
        if (okay) {
          window.location.href = "/account/download-client";
        }
      })

      this.setState({
        newCode: ''
      })
    } catch(err) {
      swal(`${_.result(err, 'response.data.message', strings.getString('ACCOUNT_REDEEM_CODE_ERROR', 'Uh Oh! Unable to Redeem Code'))}`, {
        icon: 'error',
      })
    }

    await this.getContent()
    this.setState({ loading: false })
  }

  handleFilter(e, { name, value }) {
    this.setState({ [name]: value })
    const search = _.lowerCase(value)
    const filtered = this.state.codes.filter(r => {
      return _.lowerCase(r.code).includes(search) || _.lowerCase(r.description).includes(search)
    })

    this.setState({
      filteredCodes: filtered,
      totalPages: Math.floor(filtered.length / this.state.pageSize) + 1,
      page: 1,
    })
  }

  handleNewCode(e, {name, value}) {
      this.setState({
        newCode: value
      })
  }

  handlePageChange(e, { activePage }) {
    this.setState({ page: activePage })
  }

  render() {

    const { loading, newCode, filteredCodes, filter, totalPages, page, pageSize } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('REDEEM_CODES', 'Redeem Codes')}</h1>

        <Form onSubmit={this.redeemCode}>
          <Form.Field >
            <label>{strings.getString('ACCOUNT_REDEEM_CODE_INPUT', 'Enter the code you received here and it will be applied to your account')}</label>
            <Form.Input autoComplete="off" type="text"
                        placeholder={strings.getString('ENTER_CODE', 'Enter Code')}
                        name='newCode'
                        value={newCode}
                        onChange={this.handleNewCode.bind(this)}>
            </Form.Input>
          </Form.Field>

          <Button className="Button__ACE" type='submit' primary>{strings.getString('CLAIM_CODE', 'Claim Code')}</Button>
        </Form>

        <h3>{strings.getString('CODE_REDEMPTION_HISTORY', 'Code Redemption History')}</h3>

        <Form>
          <Form.Input autoComplete="off" type="text"
                      placeholder={strings.getString('SEARCH_CODES', 'Search Codes')}
                      name='filter'
                      value={filter}
                      onChange={this.handleFilter.bind(this)}/>
        </Form>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('CODE', 'Code')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('DATE_CLAIMED', 'Date Claimed')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('DESCRIPTION', 'Description')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredCodes.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize).map(r => (
              <Table.Row key={r.code}>
                <Table.Cell singleLine>{r.code}</Table.Cell>
                <Table.Cell singleLine>{(new Date(r.date_claimed)).toLocaleString()}</Table.Cell>
                <Table.Cell>{r.description}</Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination activePage={page} totalPages={totalPages} onPageChange={this.handlePageChange.bind(this)}/>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsRedeemCodes)
