import {
    FETCH_AFFILIATE_DETAILS,
} from '../actionTypes'

const initialState = {
    details: null
}

const affiliateDetails = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_AFFILIATE_DETAILS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default affiliateDetails
