import React from 'react'
import Page from '../componentLibrary/Models/Page'
import connect from 'react-redux/es/connect/connect'
import _ from 'lodash'
import queryString from 'query-string'
import { api, removeAuth, setAuth } from '../utilities/api'
import { Container, Header, Icon, Message } from 'semantic-ui-react'
import { removeSession, setSession } from '../redux/actions'
import { clearGuildDetails } from '../redux/actions/guild-management'
import { clearUser } from '../redux/actions/user'
import strings from '../strings'

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSession: session => dispatch(setSession(session)),
    removeSession: () => dispatch(removeSession()),
    clearGuildDetails: () => dispatch(clearGuildDetails()),
    clearUser: () => dispatch(clearUser()),
  }
}

class PossessAccount extends React.Component {

  state = {
    error: false,
    success: false,
    session: null
  }

  componentDidMount = async () => {
    const q = _.result(this.props, 'location.search')
    const query = queryString.parse(q) || {}

    this.setState({
      token: query.token,
    })

    try {
      removeAuth()
      this.props.removeSession()
      this.props.clearGuildDetails()
      this.props.clearUser()
      const res = await api.get(`/user/session-context?token=${query.token}`)
      if (res.status === 200) {
        this.setState({ session: res.data })
        localStorage.setItem('userData', JSON.stringify(this.state.session))
        setAuth(_.result(this.state.session, 'token'))
        await this.props.setSession(this.state.session)

        this.setState({
          success: true,
        })
      }
    } catch (err) {
      this.setState({
        error: true,
      })
    }

  }

  render() {

    const { success, error } = this.state

    return (
      <div>
        <Page>
          <Container>

            <Header as='h1'>{strings.getString('POSSESS_ACCOUNT', 'Account Possession')}</Header>

            {success ? (
              <Message success icon>
                <Icon name='checkmark'/>
                <Message.Content>
                  <Message.Header>{strings.getString('POSSESS_ACCOUNT_SUCCESS', 'Account Successfully Possessed')}</Message.Header>
                  {strings.getString('POSSESS_ACCOUNT_INFO', 'You have successfully possessed the account. This session will expire within 15 minutes of creation.')}
                </Message.Content>
              </Message>
            ) : ''}

            {error ? (
              <Message error icon>
                <Icon name='warning sign'/>
                <Message.Content>
                  <Message.Header>Uh Oh!</Message.Header>
                  {strings.getString('POSSESS_ACCOUNT_ERROR', 'You were unable to possess this account. Please try again.')}
                </Message.Content>
              </Message>
            ) : ''}
          </Container>
        </Page>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PossessAccount)
