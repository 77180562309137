import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import strings from '../../../strings'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'


const mapStateToProps = state => {
    return {
        user: state.user.userProfile,
        language: state.language
    }
}

class BuyNowButton extends React.Component {

    recordProductClick(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "select_content",{
            "content_type": "product",
            "items": [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                }
            ]
        })
    }

    render() {
        const { item, user, medium, large, useRegisterRedirects } = this.props
        const returnPath = btoa(`${this.props.location.pathname}${this.props.location.search}`)
        const checkoutPath = useRegisterRedirects ? `/register?checkout=true&itemId=${item.item_id}` : `/checkout/${item.item_id}`
    
        return (
            <React.Fragment>
                {
                    user && user.username ? (
                        <LocLink to={checkoutPath}>
                            {medium && <Button green onclick={() => this.recordProductClick(item)} medium label={strings.getString('BUY_NOW', 'Buy Now')}/>}
                            {large && <Button green onclick={() => this.recordProductClick(item)} fill label={strings.getString('BUY_NOW', 'Buy Now')}/>}
                        </LocLink>
                    ) : (
                        <LocLink to={`/login?r=${returnPath}`}>
                            {medium && <Button green onclick={() => this.recordProductClick(item)} medium label={strings.getString('BUY_NOW', 'Buy Now')}/>}
                            {large && <Button green onclick={() => this.recordProductClick(item)} fill label={strings.getString('BUY_NOW', 'Buy Now')}/>}
                        </LocLink>
                    )
                }
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps)(BuyNowButton))