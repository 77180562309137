import React from 'react'
import './styles.scss'

class AreaScroll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldShowLeftArrow: false,
      shouldShowRightArrow: true
    }
    this.element = React.createRef()
    this.areaScrollEl = React.createRef()
    this.handleLeftArrow = this.handleLeftArrow.bind(this)
    this.handleRightArrow = this.handleRightArrow.bind(this)
    this.scrollRight = this.scrollRight.bind(this)
    this.scrollLeft = this.scrollLeft.bind(this)
  }

  handleLeftArrow(direction) {
    const clientWidth = this.element.current ? this.element.current.clientWidth : 0
    const scrollLeft = this.element.current ? this.element.current.scrollLeft : 0
    const currentScroll = clientWidth * direction + scrollLeft
    const shouldShowLeftArrow = currentScroll > 0
    this.setState({
      shouldShowLeftArrow
    })
  }

  handleRightArrow(direction) {
    const clientWidth = this.element.current ? this.element.current.clientWidth : 0
    const scrollWidth = this.element.current ? this.element.current.scrollWidth : 0
    const scrollLeft = this.element.current ? this.element.current.scrollLeft : 0
    const currentScroll = clientWidth * direction + scrollLeft
    const shouldShowRightArrow = !(Math.round(currentScroll) >= (scrollWidth - clientWidth))
    this.setState({
      shouldShowRightArrow
    })
  }

  scrollRight() {
    const scrollBy = this.element.current.clientWidth
    this.element.current.scrollLeft += scrollBy
    this.handleLeftArrow(1)
    this.handleRightArrow(1)
  }

  scrollLeft() {
    const scrollBy = this.element.current.clientWidth
    this.element.current.scrollLeft -= scrollBy
    this.handleLeftArrow(-1)
    this.handleRightArrow(-1)
  }

  render () {
    const { children } = this.props
    const { shouldShowLeftArrow, shouldShowRightArrow } = this.state
    return (
      <div className="AreaScroll" ref={this.areaScrollEl}>
        <div className="AreaScroll__movable">
          {shouldShowLeftArrow && <div className="AreaScroll__button" onClick={this.scrollLeft}></div>}
          <div className="AreaScroll__content" ref={this.element}>
            {children}
          </div>
          {shouldShowRightArrow && <div className="AreaScroll__button AreaScroll__button--right" onClick={this.scrollRight}></div>}
        </div>
      </div>
    )
  }
}

export default AreaScroll
