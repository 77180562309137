import React from 'react'
import _ from 'lodash'
import { Grid, Table, Label } from 'semantic-ui-react'
import { connect } from 'react-redux'
import strings from '../../../strings'
import assets from '../../../assets'
import queryString from 'query-string'
import RegionBlock from '../../../componentLibrary/Models/RegionBlock'
import Page from '../../../componentLibrary/Models/Page'
import SocialLink from '../../../componentLibrary/Elements/SocialLink'
import FixedTable from '../../../componentLibrary/Elements/FixedTable'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
import TitleBar from '../../../componentLibrary/Fragments/TitleBar'
import swal from '@sweetalert/with-react'

import { getSingleTransaction } from '../../../redux/actions/user'
import { getSingleStoreProduct } from '../../../redux/actions/crowfall-store'
import { fetchVIPSubscriptionDetails, createUsersVIPSubscription } from '../../../redux/actions/subscription'


import './styles.scss'


const mapStateToProps = state => {
    return {
        language: state.language,
        user: state.user.userProfile,
        cohorts: state.cohorts,
        hasGuild: state.guildManagement.hasGuild,
        userHasActiveRecurringPurchase: state.subscription.hasActiveRecurringPurchase
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleTransaction: (id) => dispatch(getSingleTransaction(id)),
        getSingleStoreProduct: (id, language) => dispatch(getSingleStoreProduct(id, language)),
        fetchVIPSubscriptionDetails: () => dispatch(fetchVIPSubscriptionDetails()),
        createUsersVIPSubscription: (id) => dispatch(createUsersVIPSubscription(id))
    }
}

class CheckoutReceipt extends React.Component {
    state = {
        solution: null,
        items: [],
        itemTypes: [],
        isBackerPack: false,
        isVIP: false,
        isGift: false,
    }

    recordToLiquidAdvertising() {
        window.gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'session_id': Date.now() + Math.random().toString(36),
            'send_to': 'DC-11063492/crowfall/d_cro006+per_session'
        })
    }

    async componentDidMount() {

        this.recordToLiquidAdvertising();
        const txnId = this.props.location.pathname.split('/').pop()
        await this.getTransactionDetails(txnId);
        if (this.state.solution.hasOwnProperty('gift_details')) {
            this.setState({ isGift: true })
        }

        const search = _.result(this.props, "location.search")
        const query = queryString.parse(search) || {}
        const isRecurringPurchase = !!query.subscribe
        if (isRecurringPurchase) this.handleRecurringPurchase()
    }

    async handleRecurringPurchase() {
        await this.props.fetchVIPSubscriptionDetails()

        if (!this.props.userHasActiveRecurringPurchase) {
            const itemId = _.result(this.state.items[0], "item_id")
            this.createUsersSubscription(itemId)
        }
    }

    async createUsersSubscription(itemId){
        try {
            await this.props.createUsersVIPSubscription(itemId);
        } catch (err){
            swal({
                icon: 'error',
                title: strings.getString("UH_OH", "Uh Oh!"),
                content: (<div>{strings.getString("VIP_ERROR_MESSAGE_CREATE_SUB", "There was an error creating your membership. Please try again.")}</div>),
            })
        }

    }

    getTransactionDetails = async (id) => {
        const solution = await this.props.getSingleTransaction(id)
        const lineItems = _.result(solution, 'line_items', [])
        const items = lineItems.length > 0 ? await this.getItemDetails(lineItems) : []
        if (solution.status !== 'failed') this.recordToGoogleApi(solution, items)
        this.setState({ solution, items })
    }

    getItemDetails = async (lineItems) => {
        const items = Promise.all(lineItems.map(async lineItem => {
            const item = await this.props.getSingleStoreProduct(lineItem.item_id, this.props.language)

            const itemTypes = item.categories.map(category => { return category.category_key })

            let updatedItemTypes = [ ...this.state.itemTypes ]
            itemTypes.forEach(type => {
                if (_.isEmpty(_.find(updatedItemTypes, type))) updatedItemTypes.push(type)
            })
            const isBackerPack = _.includes(itemTypes, 'Starter Packs' )
            const isVIP = _.includes(itemTypes, 'VIP' )

            this.setState({ isBackerPack, isVIP })
            if (updatedItemTypes.length !== this.state.itemTypes.length) this.setState({ itemTypes: updatedItemTypes })
            return item
        }))
        return items
    }

    recordToGoogleApi(solution, items) {
        window.gtag('event', 'purchase', {
            "transaction_id": solution.transaction_id,
            "affiliation": "Google online store",
            "value": solution.total,
            "currency": solution.currency,
            "tax": solution.tax_total,
            "shipping": solution.shipping_amount,
            "items": this.modelItemsForGoogle(items)
        });

        window.gtag('event', 'conversion', {
            'send_to': 'AW-739661874/mBogCM-l3csBELKw2eAC',
            'value': solution.total,
            'currency': solution.currency,
            'transaction_id': solution.transaction_id
        });
    }

    modelItemsForGoogle(items) {
        const modelled = items.map(item => {
            return {
                "id": item.item_id,
                "name": item.item_slug,
                "list_name": item.name,
                "brand": "Crowfall",
                "category": this.state.itemTypes[0],
                "quantity": item.quantity || 1,
                "price": item.purchase_amount / item.decimal_denominator,
            }
        })
        return modelled
    }

    getWalletType(solution) {
        //Subtitle needs to reflect who the items are purchased for and what wallet is being used.
        if (solution.wallet_type === "guild") return strings.getString("CHECKOUT_ITEMS_AND_CURRENCY_GUILD_GIFT");
        else if (solution.wallet_type === "user" && solution.currency === "usd") return strings.getString("CHECKOUT_ITEMS_AND_CURRENCY_PERSONAL_GIFT_XSOLLA_PAYMENT");
        return strings.getString("CHECKOUT_ITEMS_AND_CURRENCY_PERSONAL_GIFT");
    }

    render() {

        const{ solution, items, isBackerPack, isVIP, isGift } = this.state
        const { user } = this.props
        const { username } = user

        return(
            <Page>
                <div className='CheckoutReceipt'>
                    <h1 className='CheckoutReceipt__title'>{strings.getString("THANK_PURCHASE")}</h1>
                    <div className='CheckoutReceipt__subTitle'>{isGift ?  this.getWalletType(solution) : strings.getString("CHECKOUT_ITEMS_AND_CURRENCY") }</div>
                    {isBackerPack &&
                        <RegionBlock marketable>
                            <div className='CheckoutReceipt__marketingCTA' style={{backgroundImage: `url(${assets.staticImageUrls.CHECKOUT_MARKETING_CTA})`}}>
                                <div className='CheckoutReceipt__marketingCTAText'>
                                    <div className='CheckoutReceipt__marketingCTATextBlur' />
                                    <div>{strings.getString("REFER_A_FRIEND_MURDER_OF_CROWS")}</div>
                                </div>
                            </div>
                            <div className='CheckoutReceipt__marketingContent'>
                                <div className='CheckoutReceipt__marketingContentBox'>
                                    <div className='CheckoutReceipt__marketingContentImg' style={{backgroundImage: `url(${assets.staticImageUrls.CHECKOUT_MARKETING_THUMB_ONE})`}}/>
                                    <div className='CheckoutReceipt__marketingContentText'>
                                        <div className='CheckoutReceipt__marketingContentTitle'>{strings.getString("REFER_A_FRIEND_FUN_WITH_FRIENDS").toUpperCase()}</div>
                                        <div className='CheckoutReceipt__marketingContentDescription'>
                                        {strings.getString("REFER_A_FRIEND_REFER_FRIENDS")} {username}
                                        <p>crowfall.com/join/{username}</p>
                                        {strings.getString("REFER_A_FRIEND_RECEIVE_10_OFF")}
                                        </div>
                                    </div>
                                </div>
                                <div className='CheckoutReceipt__marketingContentBox CheckoutReceipt__marketingContentBox--reversed'>
                                    <div className='CheckoutReceipt__marketingContentImg' style={{backgroundImage: `url(${assets.staticImageUrls.CHECKOUT_MARKETING_THUMB_TWO})`}}/>
                                    <div className='CheckoutReceipt__marketingContentText'>
                                        <div className='CheckoutReceipt__marketingContentTitle'>{strings.getString("REFER_A_FRIEND_WARRIOR_WAR_STORY").toUpperCase()}</div>
                                        <div className='CheckoutReceipt__marketingContentDescription'>{strings.getString("REFER_A_FRIEND_SHARE_HASHTAGS")}</div>
                                    </div>
                                </div>
                            </div>
                        </RegionBlock>
                    }
                    <div className='CheckoutReceipt__orderSummary'>
                        <TitleBar title={strings.getString("ORDER_SUMMARY").toUpperCase()} />
                        { solution &&
                            <React.Fragment>
                                <div className='CheckoutReceipt__itemDetails'>
                                    {items.length > 0 && items.map((item, index) => (
                                        <div className="CheckoutReceipt__itemDetail" key={`${index}_${item.item_id}`}>
                                            <div className="CheckoutReceipt__item">
                                                <div className="CheckoutReceipt__itemName">{item.name}</div>
                                                <div className="CheckoutReceipt__itemDescription" dangerouslySetInnerHTML={{ __html: item.description }}/>
                                            </div>
                                            <div className="CheckoutReceipt__itemPrice">{_.result(item, 'display_strings.purchase_amount')}</div>
                                        </div>
                                    ))}
                                </div>

                                <div className="CheckoutReceipt__buttons">
                                    <div className='CheckoutReceipt__ctaButton'>
                                        <LocLink to='/store'>
                                                <Button green label={strings.getString("BACK_TO_STORE", "Back to Store")}/>
                                        </LocLink>
                                    </div>

                                    {isBackerPack &&
                                        <div className='CheckoutReceipt__ctaButton'>
                                            <LocLink to='/account/download-client'>
                                                <Button label={strings.getString("PLAY_NOW")}/>
                                            </LocLink>
                                        </div>
                                    }

                                    {isVIP &&
                                        <div className='CheckoutReceipt__ctaButton'>
                                            <LocLink to='/account/vip-status'>
                                                <Button label={strings.getString("MANAGE_VIP")}/>
                                            </LocLink>
                                        </div>
                                    }
                                </div>

                                <div className='CheckoutReceipt__orderDetails'>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                        <FixedTable>
                                            <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan={2}><span style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                }}>{strings.getString('ORDER_DETAILS', 'Order Details')} {solution.deferred_purchase_id ? (

                                                <LocLink to={`/account/layaway/${solution.deferred_purchase_id}`}><Label
                                                    color="blue">{strings.getString('LAYAWAY_DETAILS', 'Layaway Details')}</Label></LocLink>

                                                ) : null}</span></Table.HeaderCell>
                                            </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>{strings.getString('STATUS', 'Status')}</Table.Cell>
                                                <Table.Cell>{_.startCase(solution.status)}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{strings.getString('BILLING_DESCRIPTOR', 'Billing Descriptor')}</Table.Cell>
                                                <Table.Cell>{solution.billing_descriptor}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{strings.getString('ORDER_ID', 'Order ID')}</Table.Cell>
                                                <Table.Cell>{solution.transaction_id}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{strings.getString('PAYMENT_PROCESSOR_ID', 'Payment Processor ID')}</Table.Cell>
                                                <Table.Cell>{solution.external_id || solution.transaction_id}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{strings.getString('BILLING_DATE', 'Billing Date')}</Table.Cell>
                                                <Table.Cell>{(new Date(solution.date_created)).toLocaleString()}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{strings.getString('CHARGE_AMOUNT', 'Charge Amount')}</Table.Cell>
                                                <Table.Cell>{_.result(solution, 'display_strings.total')}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{strings.getString('WALLET_AMOUNT', 'Wallet Amount')}</Table.Cell>
                                                <Table.Cell>{_.result(solution, 'display_strings.wallet_total')}</Table.Cell>
                                            </Table.Row>
                                            { isGift &&
                                                <Table.Row>
                                                    <Table.Cell>Gift Recipient</Table.Cell>
                                                    <Table.Cell>{solution.gift_details.username}</Table.Cell>
                                                </Table.Row>
                                            }
                                            </Table.Body>
                                        </FixedTable>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <RegionBlock eu>
                                        <div className='CheckoutReceipt__rightToWithdraw'>
                                            <p className='CheckoutReceipt__rightToWithdrawText'>
                                                {strings.getString("RIGHT_TO_WITHDRAW")} <LocLink to='/crowfall-eu/terms-and-conditions'>{strings.getString("TERMS_AND_CONDITIONS")}</LocLink>
                                            </p>
                                        </div>
                                    </RegionBlock>
                                </div>
                                <h4 className='CheckoutReceipt__socialText'><strong >{strings.getString("JOIN_US_ON")}</strong></h4>
                                <div className='CheckoutReceipt__socialLinks'>
                                    <div className="CheckoutReceipt__socialLink"><SocialLink facebook/></div>
                                    <div className="CheckoutReceipt__socialLink"><SocialLink twitter/></div>
                                    <div className="CheckoutReceipt__socialLink"><SocialLink twitch/></div>
                                    <div className="CheckoutReceipt__socialLink"><SocialLink instagram/></div>
                                    <div className="CheckoutReceipt__socialLink"><SocialLink youtube/></div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    {isBackerPack &&
                        <RegionBlock marketable>
                            <div className='CheckoutReceipt__termsAndConditions'>
                                *{strings.getString("TERMS_CONDITIONS_APPLY")}<br/><br/>
                                <strong>{strings.getString("REFER_A_FRIEND_T&C_ONE")}</strong><br/>
                                {strings.getString("REFER_A_FRIEND_T&C_TWO")}<br/>
                                <strong>{strings.getString("REFER_A_FRIEND_T&C_THREE")}</strong><br/>
                                {strings.getString("REFER_A_FRIEND_T&C_FOUR")}<br/>
                                {strings.getString("REFER_A_FRIEND_T&C_FIVE")}
                            </div>
                        </RegionBlock>
                    }
                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutReceipt)
