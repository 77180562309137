/*

COMPONENT: Media
Used to display media content inside of a modal

PROPERTY     DEFAULT  REQUIREMENT TYPE    DESCRIPTION
img                   optional    String  image URL. Will be overridden IF a video URL is also provided
video                 optional    String  Video URL. Iframe that will override Image URL if both are provided
title                 optional    String  Title to be left-aligned underneath the image/video
description           optional    String  text to be displayed underneath the title

*/

import React from 'react'
import SocialButtons from '../SocialButtons'
import _ from 'lodash'
import './styles.scss'

const Media = (props) => {
  const { img, video, title, description } = props
  let className = 'Media'
  if (video) className += ' Media--video'
  return (
    <div className={className}>
      <div className="Media__img">
        {img && _.isEmpty(video) && <img alt={title} src={img}/>}
        {video && <iframe title={title} src={video} frameBorder="0" allowFullScreen/>}
      </div>
      <div className="Media__footer">

        <div className="Media__text">
          {title &&
            <div className="Media__title">
              {title}
            </div>
          }
          {description &&
            <div className="Media__description">
              {description}
            </div>
          }
        </div>

        <div className="Media__social">
          <SocialButtons facebook twitter mail title={title}/>
        </div>

      </div>
    </div>
  )
}

export default Media
