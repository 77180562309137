import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const EuDataTransferPage = () => (
  <Page contentId={assets.contentfulIds.EU_DATA_TRANSFER}>
  </Page>
)

export default EuDataTransferPage

