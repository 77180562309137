import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const TravianTermsAndConditions = () => (
  <Page contentId={assets.contentfulIds.EU_TERMS_AND_CONDITIONS}>
  </Page>
)

export default TravianTermsAndConditions

