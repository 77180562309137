import React from 'react'
import _ from 'lodash'
import queryString from 'query-string'
import swal from '@sweetalert/with-react'
import { Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import assets from '../../../../assets'
import strings from '../../../../strings'
import contentful from '../../../../utilities/contentful'
import Page from '../../../../componentLibrary/Models/Page'
import CrowfallPartnerRibbon from '../../../../componentLibrary/Fragments/CrowfallPartnerRibbon'
import Button from '../../../../componentLibrary/Fragments/Button'
import HelperLinks from '../../HelperLinks'
import RegistrationForm from '../../RegistrationForm'
import LoginForm from '../../LoginForm'
import { verifyValidationToken, validateAccount } from '../../../../redux/actions'
import { fetchTwitchAccountDetails, getTwitchConnectUrl, connectTwitchAccount, clearTwitchAccountDetails, disconnectTwitchAccount } from '../../../../redux/actions/external-accounts'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        hasTwitchConnected: state.externalAccounts.hasTwitchConnected,
        twitchAccountName: state.externalAccounts.twitchAccountName,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        verifyValidationToken: (token) => dispatch(verifyValidationToken(token)),
        validateAccount: (token) => dispatch(validateAccount(token)),
        fetchTwitchAccountDetails: () => dispatch(fetchTwitchAccountDetails()),
        getTwitchConnectUrl: (redirectUrl) => dispatch(getTwitchConnectUrl(redirectUrl)),
        connectTwitchAccount: (code, state) => dispatch(connectTwitchAccount(code, state)),
        disconnectTwitchAccount: () => dispatch(disconnectTwitchAccount()),
        clearTwitchAccountDetails: () => dispatch(clearTwitchAccountDetails()),
    }
}


class TwitchLander extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initialFetch: false,
            bannerLogo: null,
            tagline: null,
            bodyContent: null,
            footer: null,

            redirectToHome: false,
            redirectToLogin: false,
            redirectToRegister: false,
            showLoginForm: false,
        }
        this.getPageContent = this.getPageContent.bind(this)
        this.getQueryParameters = this.getQueryParameters.bind(this)
        this.handleRegistrationSuccess = this.handleRegistrationSuccess.bind(this)
        this.handleValidationError = this.handleValidationError.bind(this)
        this.handleGetTwitchConnection = this.handleGetTwitchConnection.bind(this)
        this.handleConnectTwitchAccount = this.handleConnectTwitchAccount.bind(this)
        this.handleDisconnectTwitch = this.handleDisconnectTwitch.bind(this)
    }

    async componentDidMount() {
        this.getQueryParameters()
        await this.getPageContent()
        try {
            await this.props.fetchTwitchAccountDetails()
        } catch(err) {}
        this.setState({ initialFetch: true })
    }

    componentDidUpdate(prevProps) {
        const { location: prevLocation } = prevProps
        const { location: currLocation } = this.props
        if (!_.isEqual(prevLocation.search, currLocation.search)) this.getQueryParameters()
    }

    async getPageContent() {
        const content = await contentful.getEntry(assets.contentfulIds.TWITCH_REGISTRATION_PAGE)
        this.setState({
            bannerLogo: _.result(content, "bannerLogo.assetUrl"),
            tagline: content.tagline,
            bodyContent: content.pageHeader,
            footer: content.pageFooter,
        })
    }

    getQueryParameters() {
        const { location } = this.props
        const query = queryString.parse(location.search)
        if (query.token) {
            this.validateAccountWithToken(query.token)
        } else if (query.code && query.state) {
            this.handleConnectTwitchAccount(query.code, query.state)
        } else {
            this.setState({
                showLoginForm: !!query.login,
            })
        }
    }

    async validateAccountWithToken(token) {
        const { verifyValidationToken, validateAccount } = this.props
        try {
            const status = await verifyValidationToken(token)
            const { validation_token_active: validationTokenActive, validation_set: validationTokenSet, username_set: usernameSet } = status
            if (validationTokenActive && !validationTokenSet && usernameSet) {
                try {
                    await validateAccount(token)
                    swal({
                        icon: "success",
                        title: strings.getString("VALIDATION_ACCOUNT_SUCCESS_MESSAGE", "Your email address has been successfully validated! You are now Signed In.")
                    })
                } catch (err) {
                    this.handleValidationError()
                }
            } else if (!validationTokenActive && !validationTokenSet) {
                this.handleValidationError()
            }
        } catch (err) {
            this.handleValidationError()
        }
    }

    handleValidationError() {
        swal({
            title: strings.getString("UH_OH", "Uh Oh!"),
            icon: 'error',
            content: ( <span>{strings.getString("VALIDATION_ACCOUNT_ERROR", "Unable to validate account")}</span> )
        })
        this.setState({ redirectToHome: true })
    }

    handleRegistrationSuccess() {
        this.setState({ redirectToHome: true })
    }

    async handleGetTwitchConnection() {
        const { getTwitchConnectUrl, session } = this.props
        if (session) {
            const redirectUrl = await getTwitchConnectUrl("https://crowfall.com/twitch")
            window.location.replace(redirectUrl)
        } else {
            const val = await swal({
                title: strings.getString("TWITCH_SIGN_IN_TO", "Please sign in to your Crowfall account before connecting your Twitch account."),
                buttons: {
                    cancel: strings.getString("CANCEL", "Cancel"),
                    register: {
                        text: strings.getString("REGISTER", "Register"),
                        value: "register",
                    },
                    login: {
                        text: strings.getString("SIGN_IN", "Sign In"),
                        value: "login",
                    },
                }
            })
            if (val === "login") this.setState({ redirectToLogin: true })
            if (val === "register") this.setState({ redirectToRegister: true })
        }
    }

    async handleConnectTwitchAccount(code, state) {
        try {
            await this.props.connectTwitchAccount(code, state)
            await this.props.fetchTwitchAccountDetails()
            swal({
                icon: "success",
                title: strings.getString("TWITCH_CONNECT_SUCCESS", "Successfully connected Twitch account")
            })
            window.history.replaceState(null, "", this.props.location.pathname)
        } catch (err) {
            swal(strings.getString("UH_OH", "Uh Oh!"), {
                icon: "error",
                content: (<div>{strings.getString("TWITCH_CONNECT_ERROR", "There was an issue when trying to connect your Twitch account.")}<br/>{_.result(err, "respons.data.message")}</div>)
            })
        }
    }

    async handleDisconnectTwitch() {
        try {
            await this.props.disconnectTwitchAccount()
            swal({
                icon: "success",
                title: strings.getString("TWITCH_DISCONNECT_SUCCESS", "Successfully disconnected Twitch account")
            })
        } catch (err) {
            swal(strings.getString("UH_OH", "Uh Oh!"), {
                icon: "error",
                content: (<div>{strings.getString("TWITCH_CONNECT_ERROR", "There was an issue when trying to disconnect your Twitch account.")}<br/>{_.result(err, "respons.data.message")}</div>)
            })
        }
    }

    componentWillUnmount() {
        this.props.clearTwitchAccountDetails()
    }
    

    render() {

        const { bannerLogo, initialFetch, tagline, bodyContent, footer, 
                redirectToHome, redirectToLogin, redirectToRegister, showLoginForm } = this.state
        const { session, hasTwitchConnected, twitchAccountName } = this.props


        return (
            <Page bannerRibbon={ <CrowfallPartnerRibbon partnerLogo={bannerLogo}/> }>

                {redirectToHome && <Redirect to="/"/>}
                {redirectToLogin && <Redirect to="/twitch?login=true"/>}
                {redirectToRegister && <Redirect to="/twitch"/>}

                <div className="TwitchLander">

                    {!_.isEmpty(tagline) && <div className="TwitchLander__tagline" dangerouslySetInnerHTML={{  __html: tagline }}/>}

                    <div className="TwitchLander__body">

                            <div className="TwitchLander__box">
                                <div className="TwitchLander__header">{strings.getString("GET_STARTED_HERE", "Get Started Here").toUpperCase()}</div>
                                <div className="TwitchLander__subheader">{strings.getString("TWITCH_LANDER_STEPS", "Follow these steps to receive epic Crowfall Twitch Drops.")}</div>
                                <div className="TwitchLander__step" style={{ color: session ? "green" : "black" }}>
                                    <div className="TwitchLander__text" dangerouslySetInnerHTML={{ __html: strings.getString("TWITCH_LANDER_STEP_1", "STEP 1: Sign in to your Crowfall account below or create a new one!") }}/>
                                    {session && <div className="TwitchLander__icon"><FontAwesomeIcon icon="check"/></div>}
                                </div>
                                {initialFetch && !session && showLoginForm &&
                                    <>
                                        <HelperLinks useTwitchRedirects showSignupHelp/>
                                        <br/>
                                        <LoginForm />
                                        <br/>
                                        <HelperLinks showAccountHelp/>
                                        <br/>
                                    </>
                                }
                                {initialFetch && !session && !showLoginForm &&
                                    <>
                                        <HelperLinks useTwitchRedirects showSignInHelp />
                                        <br/>
                                        {/*<RegistrationForm */}
                                        {/*    onSuccess={this.handleRegistrationSuccess}*/}
                                        {/*    registrationSource="twitch-lander"*/}
                                        {/*/>*/}
                                        <br/>
                                    </>
                                }

                                <div className="TwitchLander__step" style={{ color: hasTwitchConnected ? "green" : "black" }}>
                                    <div className="TwitchLander__text" dangerouslySetInnerHTML={{ __html: strings.getString("TWITCH_LANDER_STEP_2", "STEP 2: Sign in with your Twitch account and authorize Crowfall.") }}/>
                                    {hasTwitchConnected && <div className="TwitchLander__icon"><FontAwesomeIcon icon="check"/></div>}
                                </div>
                                {hasTwitchConnected && 
                                    <>
                                        <div className="TwitchLander__twitchAccountString" dangerouslySetInnerHTML={{ __html: strings.getString("TWITCH_ACCOUNT_CONNECTED_TO", `Your Crowfall Account is connected to your Twitch account ${twitchAccountName}`, {twitchAccount: twitchAccountName}) }}/>
                                        <div className="TwitchLander__button">
                                            <Button
                                                medium gray
                                                label={<div><Icon name="twitch" size="large"/>{strings.getString("DISCONNECT", "Disconnect")}</div>}
                                                onclick={this.handleDisconnectTwitch}
                                            />
                                        </div>
                                    </>
                                }
                                {!hasTwitchConnected && 
                                    <div className="TwitchLander__button">
                                        <Button 
                                            medium twitchPurple
                                            label={<div><Icon name="twitch" size="large"/>{strings.getString("SIGN_IN", "Sign In").toUpperCase()}</div>}
                                            onclick={this.handleGetTwitchConnection}
                                        />
                                    </div>
                                }

                                <div className="TwitchLander__step">
                                    <div className="TwitchLander__text" dangerouslySetInnerHTML={{ __html: strings.getString("TWITCH_LANDER_STEP_3", "STEP 3: Start watching <strong>CROWFALL</strong> Twitch streams <strong>NOW</strong> to get rewarded.") }}/>
                                </div>
                                <div className="TwitchLander__button">
                                    <a href="https://www.twitch.tv/directory/game/Crowfall" target="_blank" rel="noopener noreferrer"><Button 
                                        medium green
                                        label={strings.getString("START_WATCHING", "Start Watching").toUpperCase()}
                                    /></a>
                                </div>
                            </div>

                            <div className="TwitchLander__box">
                                <div className="TwitchLander__header">{strings.getString("NEW_TWITCH_DROPS", "New Twitch Drops")}</div>
                                <div className="TwitchLander__subheader">{strings.getString("START_WATCHING_GET_REWARDED", "Start WATCHING. Get REWARDED.")}</div>
                                <div dangerouslySetInnerHTML={{ __html: bodyContent }}/>
                            </div>
        
                            <div className="TwitchLander__box">
                                <div className="TwitchLander__header">{strings.getString("FAQS", "FAQs")}</div>
                                <div className="TwitchLander__subheader">{strings.getString("GOT_QUESTIONS_GET_ANSWERS", "Got Questions? Get Answers.")}</div>
                                <div dangerouslySetInnerHTML={{ __html: footer }} />
                            </div>

                    </div>

                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwitchLander)