import{
    SET_SUBSCRIPTION_DETAILS,
    CLEAR_SUBSCRIPTION_DETAILS,
    SET_SUBSCRIPTION_PAYMENT_METHOD
 } from '../actionTypes'

import _ from 'lodash'

const initialState = {
    subscriptionInitialFetch: false,
    hasActiveRecurringPurchase: false,
    subscriptionDetails: {},

    paymentInitialFetch: false,
    subscriptionPaymentDetails: {},
}

const subscription = (state = initialState, action) => {
    switch(action.type) {
        case SET_SUBSCRIPTION_DETAILS:
            return{
                ...state,
                subscriptionInitialFetch: true,
                hasActiveRecurringPurchase: !_.isEmpty(_.result(action.payload, "subscription")),
                subscriptionDetails: action.payload
            }
        case SET_SUBSCRIPTION_PAYMENT_METHOD:
            return{
                ...state,
                paymentInitialFetch: true,
                subscriptionPaymentDetails: action.payload || {}
            }  
        case CLEAR_SUBSCRIPTION_DETAILS:
         return initialState
        default:
            return state
    }
}


export default subscription