import {
    ADD_WAR_STORIES,
    SEARCH_WAR_STORIES,
    PAGINATE_WAR_STORIES,
    CLEAR_WAR_STORIES_SEARCH_CRITERIA,
    CLEAR_WAR_STORIES
} from '../actionTypes'

const initialState = {
    all: [],
    fetched: false,
    searchCriteria: null,
    searched: [],
    paginationCriteria: {
        limit: 10,
        page: 1
    },
    paginated: [],
}

const warStories = (state = initialState, action) => {
    switch (action.type) {
        case ADD_WAR_STORIES:
            return {
                ...state,
                all: modelWarStories([...state.all, ...action.payload]),
            }
        case SEARCH_WAR_STORIES:
            const searchCriteria = action.payload === undefined ? state.searchCriteria : action.payload
            const searched = searchCriteria ? state.all.filter(searchCriteria) : state.all
            return {
                ...state,
                fetched: true,
                ...{ searchCriteria, searched }
            }
        case CLEAR_WAR_STORIES_SEARCH_CRITERIA:
            return {
                ...state,
                searchCriteria: initialState.searchCriteria,
                searched: state.all
            }
        case PAGINATE_WAR_STORIES:
            const limit = state.paginationCriteria.limit
            const page = action.page ? action.page : state.paginationCriteria.page
            const paginated = paginate(state.searched, limit, page)
            return {
                ...state,
                paginationCriteria: {
                    limit,
                    page
                },
                paginated
            }
        case CLEAR_WAR_STORIES:
            return initialState
        default:
            return state
    }
}

function modelWarStories(stories) {
    const categoryObjects = stories.sort((a, b) => {
        return (new Date(b.publishDate).getTime()) - (new Date(a.publishDate).getTime())
    }).reduce((categories, story) =>  {
        const { categoryName, categoryDate } = story
        if (categories[categoryName]) { 
            categories[categoryName].stories.push(story)
        } else {
            categories[categoryName] = {
                name: categoryName,
                date: categoryDate,
                stories: [story]
            }
        }
        return categories
    }, {})
    const sortedStories = Object.keys(categoryObjects).map(category => categoryObjects[category]).sort((a, b) => {
        return (new Date(b.date).getTime()) - (new Date(a.date).getTime())
    }).reduce((stories, category) => {
        category.stories.map(story => stories.push(story))
        return stories
    }, [])
    return sortedStories
}

function paginate(list, limit, page) {
    if (limit === null) return list;
    const limitInt = parseInt(limit)
    const start = limitInt * (page - 1)
    const end = start + limitInt
    return list.slice(start, end)
}

export default warStories