import moment from "moment-timezone";
import strings from "../strings";

export const GuildCannotCreateJoinMsg = props => {
    const { latestGuildMembership, joinGuild, createGuild } = props
    let cannotCreateJoinGuildMsg = ''
    
    if(!latestGuildMembership) return
    const cannotCreateGuild = (latestGuildMembership && latestGuildMembership.denyGuildCreate)
    const cannotJoinGuild = (latestGuildMembership && latestGuildMembership.denyGuildJoin)
    if(cannotCreateGuild || cannotJoinGuild) {
        let leftGuildDate = (latestGuildMembership && moment(new Date(latestGuildMembership.leftGuildDate)).format('MMMM Do, YYYY'))
        let nextGuildCreateDate = (latestGuildMembership && moment(new Date(latestGuildMembership.nextCreateDate)).format('MMMM Do, YYYY'))
        let nextGuildJoinDate = (latestGuildMembership && moment(new Date(latestGuildMembership.nextJoinDate)).format('MMMM Do, YYYY'))
        if(cannotCreateGuild && cannotJoinGuild) {
            if(cannotCreateGuild && createGuild) {
                cannotCreateJoinGuildMsg = strings.getString("GUILDS_CANNOT_CREATE_GUILD", `You recently disbanded a guild on ${leftGuildDate}, you are unable to create a guild until ${nextGuildCreateDate}.`, {leftGuildDate, nextGuildCreateDate})
            } else if(cannotJoinGuild && joinGuild) {
                cannotCreateJoinGuildMsg = strings.getString("GUILDS_CANNOT_JOIN_GUILD", `You recently left a guild on ${leftGuildDate}, you are unable to join a guild until ${nextGuildJoinDate}.`, {leftGuildDate, nextGuildJoinDate})
            } else {
                cannotCreateJoinGuildMsg = strings.getString("GUILDS_CANNOT_CREATE_JOIN_GUILD", `You recently disbanded / left a guild on ${leftGuildDate}, you are unable to join until ${nextGuildJoinDate} or create a guild until ${nextGuildCreateDate}.`, {leftGuildDate, nextGuildJoinDate, nextGuildCreateDate})
            }
        } 
    }
    return (
            <div className="GuildCannotCreateJoinMsg__cannot_create_display">
                {cannotCreateJoinGuildMsg}
            </div>
    )
}
