import React from 'react';
import LocLink from '../../../../componentLibrary/Elements/LocLink'
import { Icon } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedIndex: null,
        }

        this.toggleItem = this.toggleItem.bind(this)
        this.deselect = this.deselect.bind(this)
    }

    toggleItem(index) {
        const { selectedIndex } = this.state
        let newIndex = null
        if (index !== selectedIndex) {
            newIndex = index
        }

        this.setState({
            selectedIndex: newIndex,
        })
    }

    deselect() {
        this.setState({
            selectedIndex: null,
        })
    }

    render() {

        const { menuItems } = this.props
        const { selectedIndex } = this.state

        return (
            <div className="MainMenu">
                {menuItems.filter(item =>{
                    return ((item.secured === false && !this.props.user.username) || (item.secured === undefined) || (item.secured && this.props.user.username))
                }).map((item, index) => {
                    const { label, link, links, icon, notification, mobileOnly, external : isExternal } = item
                    let className = 'Menu__item'
                    if (selectedIndex === index) className += ' Menu__item--selected'
                    if (notification === true) className += ' Menu__item--orange'
                    if (mobileOnly === true) className += ' Menu__item--mobile'
                    let menuItem = null
                    if (link && isExternal) {
                        menuItem = (<ExternalLinkMenuItem label={label} link={link} className={className} icon={icon}/>)
                    } else if (link && !isExternal) {
                        menuItem = (<LocLinkMenuItem label={label} link={link} className={className} icon={icon}/>)
                    } else if (links) {
                        menuItem = (
                            <ListMenuItem 
                                label={label} links={links} className={className} icon={icon} index={index} 
                                toggle={this.toggleItem} deselect={this.deselect} user={this.props.user}
                        />)
                    }
                    return (
                        <React.Fragment key={label}>
                            {menuItem}
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}
                        
export default Menu


const ExternalLinkMenuItem = props => {
    const { className, link, label, icon } = props
    return (
        <a className={className} href={link} rel="noopener noreferrer">
            <div className="Menu__label">
                {!icon && <div className="Menu__carrot"></div>}
                {icon && <FontAwesomeIcon icon={icon} fixedWidth className="Menu__icon"/>}
                {label}
            </div>
        </a>
    )
}

const LocLinkMenuItem = props => {
    const { className, link, icon, label } = props
    return (
        <LocLink to={link} className={className} >
            <div className="Menu__label">
                {!icon && <div className="Menu__carrot"></div>}
                {icon && <FontAwesomeIcon icon={icon} fixedWidth className="Menu__icon"/>}
                {label}
            </div>
        </LocLink>
    )
}

const ListMenuItem = props => {
    const { className, links, icon, label, index, user, toggle, deselect } = props
    return (
        <div className={className} key={label}>
            <div className="Menu__label" onClick={() => toggle(index)}>
                {!icon && <div className="Menu__carrot">
                    <Icon name="caret down"/>
                </div>}
                {icon && <FontAwesomeIcon icon={icon} fixedWidth className="Menu__icon"/>}
                <div>{label}</div>
            </div>

            <div className="Menu__subSections">
                {links.map((subSection, index) => {
                        const { label, links: subLinks } = subSection
                        return (
                            <div className="Menu__subSection" key={label || index}>
                                {label &&
                                    <div className="Menu__subLabel">{label}</div>
                                }
                                {
                                    subLinks && subLinks.filter(subLink => {
                                        return ((subLink.secured === false && !user.username) || (subLink.secured === undefined) || (subLink.secured && user.username))
                                    }).map((subLink) => {
                                        const { label, link, external, exactLink, icon } = subLink
                                        return (
                                            <div className="Menu__link" onClick={deselect} key={label}>
                                                {link && !external &&
                                                <LocLink to={link}>
                                                    <div className="Menu__linkLabel">
                                                        {icon && <FontAwesomeIcon icon={icon} fixedWidth className="Menu__icon"/>}
                                                        {label}
                                                    </div>
                                                </LocLink>
                                                }
                                                {link && external &&
                                                <a href={link} target="_blank" rel="noopener noreferrer">
                                                    <div className="Menu__linkLabel">
                                                        {icon && <FontAwesomeIcon icon={icon} fixedWidth className="Menu__icon"/>}
                                                        {label}
                                                    </div>
                                                </a>
                                                }
                                                {exactLink &&
                                                <a href={exactLink}>
                                                    <div className="Menu__linkLabel">
                                                        {icon && <FontAwesomeIcon icon={icon} fixedWidth className="Menu__icon"/>}
                                                        {label}
                                                    </div>
                                                </a>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {links.length > 1 && index !== links.length - 1 && <div className="Menu__divider"></div>}
                            </div>
                        )
                    },
                )}
            </div>
        </div>
    )
}