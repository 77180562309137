import React from 'react'
import { api } from '../utilities/api'
import { Dimmer, Form, Loader, Pagination, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import LocLink from '../componentLibrary/Elements/LocLink'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsOrderHistory extends React.Component {

  state = {
    loading: false,
    orders: [],
    filteredOrders: [],
    filter: '',
    page: 1,
    totalPages: 1,
    pageSize: 15
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    const orders = await api.get(`/user/transaction-history`)
    this.setState({
      orders: _.reverse(_.sortBy(orders.data, 'date_created')).filter(o=>{
        return o.status !== 'authorized' || (o.merchant === 'travian' && o.status === 'authorized' && o.date_created > Date.now() - (1000 * 60 * 60 * 24 * 1))
      }).map(o => {
        o.itemNames = _.result(o, 'line_items', []).map(l => l.name)
        return o
      }),
    })
    this.setState({
      filteredOrders: this.state.orders,
      totalPages: Math.floor(this.state.orders.length / this.state.pageSize) + 1,
      page: 1,
    })
  }

  handleFilter(e, { name, value }) {
    this.setState({ [name]: value })
    const search = _.lowerCase(value)
    const filtered = this.state.orders.filter(r => {
      return _.lowerCase(r.billing_descriptor).includes(search) || _.lowerCase(r.transaction_id).includes(search) || _.lowerCase(r.itemNames).includes(search)
    })

    this.setState({
      filteredOrders: filtered,
      totalPages: Math.floor(filtered.length / this.state.pageSize) + 1,
      page: 1,
    })
  }

  handlePageChange(e, { activePage }) {
    this.setState({ page: activePage })
  }

  render() {

    const { loading, filteredOrders, filter, totalPages, page, pageSize } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('ORDER_HISTORY', 'Order History')}</h1>

        <Form>
          <Form.Input autoComplete="off" type="text"
                      placeholder={strings.getString('SEARCH_ORDERS', 'Search Orders')}
                      name='filter'
                      value={filter}
                      onChange={this.handleFilter.bind(this)}/>
        </Form>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('CHARGED', 'Charged')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('WALLET', 'Wallet')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('PURCHASED', 'Purchased')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString("BOUGHT_FOR_A_FRIEND_RECIPIENT", "Bought for a Friend Recipient")}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('STATUS', 'Status')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">{strings.getString('RECEIPT', 'Receipt')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredOrders.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize).map(r => (
              <Table.Row key={r.transaction_id}>
                <Table.Cell singleLine>
                  {r.billing_descriptor}
                  <br/>
                  <span style={{ color: 'rgb(175,175,175)' }}>{(new Date(r.date_created)).toLocaleString()}</span>
                </Table.Cell>
                <Table.Cell singleLine>{_.result(r, 'display_strings.total', '')}</Table.Cell>
                <Table.Cell singleLine>{_.result(r, 'display_strings.wallet_total', '')}</Table.Cell>
                <Table.Cell>
                  <ul style={{ listStyle: 'none', padding: 0 }}>{_.result(r, 'line_items', []).map(l => (
                    <li key={l.item_id}>{l.name}{l.quantity > 1 ? (` (x${l.quantity})`) : ''}</li>))}</ul>
                </Table.Cell>
                <Table.Cell>
                  {r.gift_details ? r.gift_details.username : ''}
                </Table.Cell>
                <Table.Cell>
                  {_.startCase(r.status)}
                  {r.deferred_purchase_id ? (
                    <span>
                      <br/>
                      ({strings.getString('LAYAWAY_PAYMENT', 'Layaway Payment')})
                    </span>
                  ) : ''}
                  </Table.Cell>
                <Table.Cell textAlign="right" collapsing>
                  <LocLink to={`/account/order-history/${r.transaction_id}`}>{strings.getString('RECEIPT', 'Receipt')}</LocLink>
                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination defaultActivePage={page} totalPages={totalPages} onPageChange={this.handlePageChange.bind(this)}/>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsOrderHistory)
