/* 

FRAGMENT: Pill
to be used to display selected content such as items in a multi-select dropdown || categories for filtering content

PROPERTY   DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

label               Optional     String    label for button. If provided, children will be ignored.
children            Optional     Function  any jsx elements desired to be wrapped in the button  
onClick             Optional     Function  function to be passed when pill is clicked
disabled   false    Optional     Boolean   Disables onClick. Removes "X" symbol and adjusts styling.

large      false    Optional     Boolean   Increases font-size and padding
small      false    Optional     Boolean   Decreases font-size and padding

*/


import React from 'react'
import './styles.scss'


const Pill = (props) => {

    const { label, children, onClick, large, small, disabled } = props
    let className = "Pill"
    if (large) className += " Pill--large"
    if (small) className += " Pill--small"
    if (disabled) className += " Pill--disabled"

    return (
        <div className={className} onClick={onClick ? onClick : null}>
            {!disabled && <div className='Pill__symbol'>&#x2715;</div>}
            {label || children}
        </div>
    )
}

export default Pill