import React from 'react'
import { Button, Dimmer, Divider, Form, Header, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import contentful from '../../utilities/contentful'
import assets from '../../assets'
import LocLink from '../../componentLibrary/Elements/LocLink'
import strings from '../../strings'
import {
  fetchCohorts,
} from '../../redux/actions'
import RegionBlock from '../../componentLibrary/Models/RegionBlock';
import './styles.scss'
import {Redirect} from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    user: state.user.userProfile,
    language: state.language,
    cohorts: state.cohorts,
    config: state.config,
    session: state.session,
    acceptedRegionalAgreements: state.acceptedRegionalAgreements,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCohorts: () => dispatch(fetchCohorts()),
  }
}

class DownloadClient extends React.Component {

  state = {
    loading: false,
    content: {},
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.props.fetchCohorts()
    this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    const content = await contentful.getEntry(assets.contentfulIds.DOWNLOAD_CLIENT_PAGE, this.props.language)
    this.setState({ content })
  }

  render() {

    const { loading, content } = this.state
    const { cohorts, config, session } = this.props
    const { can_play_crowfall: canDownloadLive, can_play_crowfall_test: canDownloadTest } = cohorts || {}

    return (
      <div className="DownloadClient">
        <Redirect to="/account/profile" />
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>

        <Header as='h2'>{content.title}</Header>

        {canDownloadLive ? (
          <Form>
            <Button style={{backgroundColor:'green', color:'white'}} className="Button__ACE" type='a'
                    href={`${config.base_api_host}/user/asset-download/CFInstaller_Live.exe?token=${session.token}`}
                    primary>{strings.getString("PLAY_NOW", "Play Now")}
            </Button>
            <RegionBlock eu>
              <p>
                {strings.getString("RIGHT_TO_WITHDRAW_DOWNLOAD")} <LocLink to='/crowfall-eu/terms-and-conditions'>{strings.getString("TERMS_AND_CONDITIONS")}</LocLink>
              </p>
            </RegionBlock>
          </Form>
        ) : (
          <div>
            <p>
              <Button style={{backgroundColor:'green', color:'white'}} className="Button__ACE" disabled={true}><em>{strings.getString('PURCHASE_GAME_TO_ENABLE_DOWNLOAD', 'Purchase Game to enable download')}</em></Button>
              <LocLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString('BUY_GAME', 'Buy Game')}</LocLink>
            </p>
            <RegionBlock eu>
              <p>
                {strings.getString("RIGHT_TO_WITHDRAW_DOWNLOAD")} <LocLink to='/crowfall-eu/terms-and-conditions'>{strings.getString("TERMS_AND_CONDITIONS")}</LocLink>
              </p>
            </RegionBlock>
          </div>
        )}

        <div dangerouslySetInnerHTML={{ __html: content.header }} style={{ padding: "10px 0" }}/>
        <Divider/>

        <div dangerouslySetInnerHTML={{ __html: content.livePatcher }} style={{ padding: "10px 0" }}/>

        {/* <div dangerouslySetInnerHTML={{ __html: content.hungerDomePatcher }} style={{ padding: "10px 0" }}/>

        {canDownloadHungerDome ? (
          <Form>
            <Button style={{backgroundColor:'green', color:'white'}} className="Button__ACE" type='a'
                    href={`${config.base_api_host}/user/asset-download/Tournament_Installer.zip?token=${session.token}`}
                    primary>{strings.getString("DOWNLOAD_HUNGERDOME_TOURNAMENT", "Download HungerDome Tournament")}
            </Button>
            <RegionBlock eu>
              <p>
                {strings.getString("RIGHT_TO_WITHDRAW_DOWNLOAD")} <LocLink to='/crowfall-eu/terms-and-conditions'>{strings.getString("TERMS_AND_CONDITIONS")}</LocLink>
              </p>
            </RegionBlock>
          </Form>
        ) : (
          <div>
            <p>
              <Button style={{backgroundColor:'green', color:'white'}} className="Button__ACE" disabled={true}><em>{strings.getString('PURCHASE_GAME_TO_ENABLE_DOWNLOAD', 'Purchase Game to enable download')}</em></Button>
              <LocLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString('BUY_GAME', 'Buy Game')}</LocLink>
            </p>
            <RegionBlock eu>
              <p>
                {strings.getString("RIGHT_TO_WITHDRAW_DOWNLOAD")} <LocLink to='/crowfall-eu/terms-and-conditions'>{strings.getString("TERMS_AND_CONDITIONS")}</LocLink>
              </p>
            </RegionBlock>
          </div>
        )} */}

        {/*<Divider/>*/}

      {/*<div dangerouslySetInnerHTML={{ __html: content.testPatcher }} style={{ padding: "10px 0" }}/>*/}

      {/*{canDownloadTest ? (*/}
      {/*  <Form>*/}
      {/*    <Button style={{backgroundColor:'green', color:'white'}} className="Button__ACE" type='a'*/}
      {/*      href={`${config.base_api_host}/user/asset-download/CFInstaller_Test.exe?token=${session.token}`}*/}
      {/*      primary>{strings.getString("DOWNLOAD_TEST_PATCHER", "Download Test Patcher")}*/}
      {/*    </Button>*/}
      {/*    <RegionBlock eu>*/}
      {/*      <p>*/}
      {/*        {strings.getString("RIGHT_TO_WITHDRAW_DOWNLOAD")} <LocLink to='/crowfall-eu/terms-and-conditions'>{strings.getString("TERMS_AND_CONDITIONS")}</LocLink>*/}
      {/*      </p>*/}
      {/*    </RegionBlock>*/}
      {/*  </Form>*/}
      {/*) : (*/}
      {/*  <div>*/}
      {/*    <p>*/}
      {/*      <Button style={{backgroundColor:'green', color:'white'}} className="Button__ACE" color='blue' disabled={true}><em>{strings.getString('PURCHASE_GAME_TO_ENABLE_DOWNLOAD', 'Purchase Game to enable download')}</em></Button>*/}
      {/*      <LocLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString('BUY_GAME', 'Buy Game')}</LocLink>*/}
      {/*    </p>*/}
      {/*    <RegionBlock eu>*/}
      {/*      <p>*/}
      {/*        {strings.getString("RIGHT_TO_WITHDRAW_DOWNLOAD")} <LocLink to='/crowfall-eu/terms-and-conditions'>{strings.getString("TERMS_AND_CONDITIONS")}</LocLink>*/}
      {/*      </p>*/}
      {/*    </RegionBlock>*/}
      {/*  </div>*/}
      {/*)}*/}

      <Divider/>

      <div dangerouslySetInnerHTML={{ __html: content.optionalDownloads }} style={{ padding: "10px 0" }}/>

      {canDownloadLive || canDownloadTest ? (
        <Form>
          <Button className="Button__ACE Button_ACE-secondary" type='a'
                  href={`${config.base_api_host}/user/asset-download/vcredist_x64.zip?token=${session.token}`}
                  primary>{("DOWNLOAD_C_LIBRARIES", "Download Visual C++ Libraries")}</Button>
        </Form>
      ) : (
        <p><Button className="Button__ACE Button_ACE-secondary" color='blue' disabled={true}><em>{strings.getString('PURCHASE_GAME_TO_ENABLE_DOWNLOAD', 'Purchase Game to enable download')}</em></Button> <LocLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString('BUY_GAME', 'Buy Game')}</LocLink></p>
      )}

    </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadClient)
