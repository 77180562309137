import React from 'react'
import { Redirect } from 'react-router-dom'
import swal from '@sweetalert/with-react'
import Page from '../componentLibrary/Models/Page'
import connect from 'react-redux/es/connect/connect'
import _ from 'lodash'
import queryString from 'query-string'
import { api } from '../utilities/api'
import { Button, Container, Dimmer, Form, Header, Loader, Message } from 'semantic-ui-react'
import strings from '../strings'

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

class ConfirmPasswordReset extends React.Component {

  state = {
    password: '',
    confirmPassword: '',
    token: '',
    otp: '',
    loading: false,
    errorMessage: '',
    error: false,
    redirectToHome: false,
    disabled: false,
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  componentDidMount = async () => {
    const q = _.result(this.props, 'location.search')
    const query = queryString.parse(q) || {}

    this.setState({
      token: query.token,
    })

    try {
      await api.get(`/user/session?token=${query.token}`)
    } catch {
      this.setState({
        error: true,
        disabled: true,
        errorMessage: strings.getString('PASSWORD_RESET_ERROR', 'The reset token is invalid.  You must request a new one before you can reset your password.'),
      })
    }
  }

  handleSubmit = async () => {

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        error: true,
        errorMessage: strings.getString('PASSWORDS_DO_NOT_MATCH', 'The password fields do not match.'),
      })
      return
    } else {
      this.setState({
        error: false,
        errorMessage: '',
      })
    }

    this.setState({ loading: true })

    try {
      await api.put(`/user/password?token=${this.state.token}`, {
        password: this.state.password,
        totp: (this.state.otp && this.state.otp.length > 0) ? this.state.otp : null,
      })

      swal({
        icon: 'success',
        title: strings.getString("PASSWORD_RESET_CHANGE_SUCCESS", "Successfully changed password."),
        text: strings.getString("PASSWORD_RESET_CHANGE_INFO", "You have successfully changed your password.  You can now Sign In to access your account.")
      })
      this.setState({
        redirectToHome: true,
      })

    } catch (err) {

      if (err.response.data.message === `body param 'totp':{6-digit integer} code required`) {
        this.setState({
          error: true,
          errorMessage: strings.getString('2FA_CODE_REQUIRED', '2FA Device Code Required'),
        })
      } else if (err.response.data.message === `totp code invalid with status: Error: otp verify code does not match`) {
        this.setState({
          error: true,
          errorMessage: strings.getString('2FA_CODE_INVALID_TRY_AGAIN', '2FA Device Code Invalid.  Please try again.'),
        })
      } else {
        this.setState({
          error: true,
          errorMessage: err.response.data.message,
        })
      }
    }

    this.setState({ loading: false })
  }

  render() {

    const { otp, loading, confirmPassword, password, error, errorMessage, redirectToHome } = this.state

    return (
      <div>
        <Page>

          {redirectToHome && <Redirect to="/login"/>}

          <Container text>
            <Dimmer active={loading} inverted>
              <Loader/>
            </Dimmer>

            <Header as='h1'>{strings.getString('PASSWORD_RESET_TITLE', 'Reset Your Password')}</Header>
            <p>{strings.getString('PASSWORD_RESET_INFO', 'Resetting your password will remove all active sessions on all devices.')}</p>

            <Form onSubmit={this.handleSubmit} error={error}>
              <Form.Input required minLength="8" autoComplete="off"
                          label={strings.getString('PASSWORD', 'Password')}
                          placeholder={strings.getString('PASSWORD', 'Password')}
                          name='password' type='password' value={password}
                          onChange={this.handleChange}/>
              <Form.Input required minLength="8" autoComplete="off" label={strings.getString('CONFIRM_PASSWORD', 'Confirm Password')}
                          placeholder={strings.getString('CONFIRM_PASSWORD', 'Confirm Password')}
                          name='confirmPassword' type='password' value={confirmPassword}
                          onChange={this.handleChange}/>

              <Form.Input minLength="6" autoComplete="off"
                          label={strings.getString('2FA_DEVICE_CODE', '2FA Device Code')}
                          placeholder={strings.getString('2FA_DEVICE_CODE', '2FA Device Code')}
                          name='otp' value={otp}
                          onChange={this.handleChange}
                          type="number"/>


              <Container textAlign="right">
                <Button primary type='submit' loading={loading} disabled={loading}>{strings.getString('CHANGE_PASSWORD', 'Change Password')}</Button>
              </Container>

              <Message error header={strings.getString('UH_OH', 'Uh oh!')} content={errorMessage}/>

            </Form>
          </Container>
        </Page>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ConfirmPasswordReset)
