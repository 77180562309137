import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import queryString from 'query-string'
import PromoCodeForm from '../../PromoCodeForm'
import HelperLinks from '../../HelperLinks'
import RegistrationForm from '../../RegistrationForm'
import LoginForm from '../../LoginForm'
import assets from '../../../../assets'
import strings from '../../../../strings'
import contentful from '../../../../utilities/contentful'
import Page from '../../../../componentLibrary/Models/Page'
import Button from '../../../../componentLibrary/Fragments/Button'
import TextLink from '../../../../componentLibrary/Fragments/TextLink'
import GoldBar from '../../../../componentLibrary/Fragments/GoldBar'
import CrowfallPartnerRibbon from '../../../../componentLibrary/Fragments/CrowfallPartnerRibbon'
import { verifyValidationToken, validateAccount } from '../../../../redux/actions'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        config: state.config,
        cohorts: state.cohorts,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        verifyValidationToken: (token) => dispatch(verifyValidationToken(token)),
        validateAccount: (token) => dispatch(validateAccount(token)),
    }
}

class AlienwareLander extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initialFetch: false,
            bannerAsset: null,
            bannerLogo: null,
            featuredAsset: null,
            tagline: null,
            callout: null,
            body: null,
        
            alienwareCode: null,
            showLoginForm: false,
            redirectToHome: false,
        }
        this.getQueryParameters = this.getQueryParameters.bind(this)
        this.getPageContent = this.getPageContent.bind(this)
        this.handleRegistrationSuccess = this.handleRegistrationSuccess.bind(this)
        this.handleValidationError = this.handleValidationError.bind(this)
    }

    async componentDidMount() {
        this.getQueryParameters()
        await this.getPageContent()
        this.setState({ initialFetch: true })
    }

    componentDidUpdate(prevProps) {
        const { location: prevLocation } = prevProps
        const { location: currLocation } = this.props
        if (!_.isEqual(prevLocation.search, currLocation.search)) this.getQueryParameters()
    }

    async getPageContent() {
        const content = await contentful.getEntry(assets.contentfulIds.ALIENWARE_LANDER)
        this.setState({
            bannerAsset: _.result(content, "banner.assetUrl"),
            bannerLogo: _.result(content, "bannerLogo.assetUrl"),
            featuredAsset: _.result(content, "footerBannerAsset.assetUrl"),
            tagline: content.tagline,
            callout: content.pageHeader,
            body: content.pageFooter
        })
    }

    getQueryParameters() {
        const { location } = this.props
        const query = queryString.parse(location.search)
        if (query.token) {
            this.validateAccountWithToken(query.token)
        } else {
            this.setState({
                alienwareCode: query.code,
                showLoginForm: !!query.login,
            })
        }
    }

    async validateAccountWithToken(token) {
        const { verifyValidationToken, validateAccount } = this.props
        try {
            const status = await verifyValidationToken(token)
            const { validation_token_active: validationTokenActive, validation_set: validationTokenSet, username_set: usernameSet } = status
            if (validationTokenActive && !validationTokenSet && usernameSet) {
                try {
                    await validateAccount(token)
                    swal({
                        icon: "success",
                        title: strings.getString("VALIDATION_ACCOUNT_SUCCESS_MESSAGE", "Your email address has been successfully validated! You are now Signed In.")
                    })
                } catch (err) {
                    this.handleValidationError()
                }
            } else if (!validationTokenActive && !validationTokenSet) {
                this.handleValidationError()
            }
        } catch (err) {
            this.handleValidationError()
        }
    }

    handleValidationError() {
        swal({
            title: strings.getString("UH_OH", "Uh Oh!"),
            icon: 'error',
            content: ( <span>{strings.getString("VALIDATION_ACCOUNT_ERROR", "Unable to validate account")}</span> )
        })
        this.setState({ redirectToHome: true })
    }

    handleRegistrationSuccess() {
        this.setState({ redirectToHome: true })
    }

    render() {

        const { initialFetch, bannerAsset, bannerLogo, featuredAsset, tagline, callout, body, 
            alienwareCode, showLoginForm, redirectToHome } = this.state
        const { session, cohorts, config } = this.props
        const { can_play_crowfall: canDownloadGame, consumed_alienware_code: hasAlienwareCode } = cohorts
        const downloadLink = canDownloadGame ? `${_.result(config, "base_api_host")}/user/asset-download/CFInstaller_Live.exe?token=${_.result(session, "token")}` : null
        
        return (
                <Page bannerShort header={{ banner: bannerAsset }} bannerRibbon={ <CrowfallPartnerRibbon partnerLogo={bannerLogo} largePartnerLogo/> }>

                    {redirectToHome && <Redirect to="/"/>}

                    <div className="AlienwareLander">

                        {!_.isEmpty(tagline) && <div className="AlienwareLander__tagline" dangerouslySetInnerHTML={{  __html: tagline }}/>}

                        <div className="AlienwareLander__body">

                            {initialFetch && session && hasAlienwareCode &&
                                <div className="AlienwareLander__box">
                                    <div className="AlienwareLander__headerLeft">{strings.getString("CONGRATULATIONS", "Congratulations!")}</div>
                                    {/* <div className="AlienwareLander__text">{strings.getString("WELCOME_TO_ALIENWARE", "Welcome to the Alienware x Crowfall Closed Beta!")}</div> */}
                                    {/* <div className="AlienwareLander__text">{strings.getString("DOWNLOAD_BETA_CLICK_BELOW", "Download the Beta Now - Click the Button Below!")}</div> */}
                                    <br/><br/>

                                    <div className="AlienwareLander__title">{strings.getString("PVP_YOUR_WAY_EVERYDAY", "PVP Your Way, Everyday").toUpperCase()}</div>
                                    <div className="AlienwareLander__gameLinks">
                                        <a href={downloadLink}>
                                            <Button green medium disabled={!canDownloadGame} label={strings.getString("JOIN_THE_BATTLE", "Join the Battle")}/>
                                        </a>
                                        {!canDownloadGame && <TextLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString("BUY_CROWFALL", "Buy Crowfall")}</TextLink>}
                                    </div>
                                </div>
                            }

                            {initialFetch && session && !hasAlienwareCode &&
                                <div className="AlienwareLander__box">
                                    <PromoCodeForm 
                                        codeFieldLabel={strings.getString("ENTER_ALIENWARE_ALLIES_CODE", "Enter Your Alienware Allies Code")}
                                        codeFieldInput={alienwareCode}/>
                                    <div className="AlienwareLander__title">{strings.getString("PVP_YOUR_WAY_EVERYDAY", "PVP Your Way, Everyday").toUpperCase()}</div>
                                    <div className="AlienwareLander__gameLinks">
                                        <a href={downloadLink}>
                                            <Button green medium disabled={!canDownloadGame} label={strings.getString("JOIN_THE_BATTLE", "Join the Battle")}/>
                                        </a>
                                        {!canDownloadGame && <TextLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString("BUY_CROWFALL", "Buy Crowfall")}</TextLink>}
                                    </div>
                                </div>
                            }

                            {initialFetch && !session && !showLoginForm &&
                                <div className="AlienwareLander__box">
                                    <HelperLinks useAlienwareRedirects code={alienwareCode} showSignInHelp/>
                                    <br/>
                                    {/*<RegistrationForm */}
                                    {/*    onSuccess={this.handleRegistrationSuccess} */}
                                    {/*    codeFieldLabel={strings.getString("ENTER_ALIENWARE_ALLIES_CODE_TO_REGISTER", "Enter Your Alienware Allies Code to Create Your Crowfall Account")} */}
                                    {/*    codeFieldInput={alienwareCode} orderCodeFieldFirst*/}
                                    {/*    registrationSource="alienware-lander"/>*/}
                                </div>
                            }

                            {initialFetch && !session && showLoginForm &&
                                <div className="AlienwareLander__box">
                                    <HelperLinks useAlienwareRedirects code={alienwareCode} showSignupHelp/>
                                    <br/>
                                    <LoginForm 
                                        addCodeField
                                        codeFieldLabel={strings.getString("ENTER_ALIENWARE_ALLIES_CODE", "Enter Your Alienware Allies Code")} 
                                        codeFieldInput={alienwareCode}/>
                                    <br/>
                                    <HelperLinks showAccountHelp/>                                
                                </div>
                            }

                            <div className="AlienwareLander__box">
                                <img src={featuredAsset} className="AlienwareLander__asset" alt="Alienware Asset"/>
                                <GoldBar h3/>
                                <div className="AlienwareLander__callout" dangerouslySetInnerHTML={{ __html: callout }}/>
                                <GoldBar h3/>
                                <div style={{ marginTop: "20px" }} dangerouslySetInnerHTML={{ __html: body }}/>
                            </div>

                        </div>

                    </div>
                </Page>
            )
        }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlienwareLander)