/*

MediaTile: Component

PROPERTY      DEFAULT   REQUIREMENT  TYPE     DESCRIPTION
small         false     optional     Boolean  decreases height & font-sizes
thumb                   required     String   url for background image
title                   required     String   title displayed over image
date                    optional     String   utc date to be formatted and displayed over image
description             optional     String   additional text displayed over image
link                    optional     String   turns entire media tile into a link

*/

import React from 'react'
import LocLink from '../LocLink'
import moment from 'moment-timezone'
import './styles.scss'

const MediaTile = (props) => {
  const { small, thumb, title, date, description, link } = props;
  const formattedDate = moment(date).format('dddd, MMMM Do, YYYY');
  let className = 'MediaTile'
  if (description) className += ' MediaTile--showDescription'
  if (small) className += ' MediaTile--small'

  const mediaTile = () => (

    <div className={className} style={{backgroundImage: `url(${thumb})`}}>
      <div className="MediaTile__shadow"></div>
      <div className="MediaTile__footer">
        <div className="MediaTile__title">{title}</div>
        {date && <div className="MediaTile__date">{formattedDate}</div>}
        {description && <div className="MediaTile__description" dangerouslySetInnerHTML={{__html: description}}/>}
      </div>
    </div>
  )

  return (
      <div>
        {link && <LocLink to={link || ''}>
          {mediaTile()}
        </LocLink>}
        {!link && mediaTile()}
      </div>
  )
}

export default MediaTile