import React from 'react'
import { api } from '../utilities/api'
import { Dimmer, Loader, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'
import LocLink from '../componentLibrary/Elements/LocLink'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsLayaway extends React.Component {

  state = {
    loading: false,
    deferred: [],
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    const deferred = await api.get(`/store/deferred-purchase`)
    this.setState({
      deferred: _.orderBy(deferred.data, 'date_created'),
    })
  }

  render() {

    const { loading, deferred } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('LAYAWAY_MANAGEMENT', 'Layaway Management')}</h1>

        <p>{strings.getString('ACCOUNT_SEE_LAYAWAYS', 'Here you can see your layaways and purchases')}
        </p>
        <ul>
          <li>
            <strong>{strings.getString('TERMS', 'Terms')}</strong> - {strings.getString('ACCOUNT_LAYAWAY_TERMS', 'Available for items or bundles above 100$. Products can be downloaded as soon as the item is fully paid.')}
          </li>

          <li>
            <strong>{strings.getString('MINIMUM', 'Minimum')}</strong> - {strings.getString('ACCOUNT_MINIMUM_LAYAWAY_PRICE', 'To start a layaway you should pay 20% of the item price.')}
          </li>

          <li>
            <strong>{strings.getString('CANCELLATION', 'Cancellation')}</strong> - {strings.getString('ACCOUNT_LAYAWAY_CANCELLATION_DEFINITION', 'Purchased layaways can be canceled and will be refunded with stored credit.')}
          </li>
        </ul>


        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('DATE', 'Date')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('PURCHASED', 'Purchased')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('AMOUNT_APPLIED', 'Amount Applied')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('AMOUNT_REMAINING', 'Amount Remaining')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('PAYMENTS', 'Payments')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('STATUS', 'Status')}</Table.HeaderCell>
              <Table.HeaderCell
                textAlign="right">{strings.getString('DETAILS', 'Details')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {deferred.map(r => (
              <Table.Row key={r.deferred_purchase_id}>
                <Table.Cell singleLine>
                  {(new Date(r.date_created)).toLocaleString()}
                </Table.Cell>
                <Table.Cell singleLine>{_.result(r, 'item.name')}</Table.Cell>
                <Table.Cell>{_.result(r, 'display_strings.amount_applied')}</Table.Cell>
                <Table.Cell>{_.result(r, 'display_strings.amount_remaining')}</Table.Cell>
                <Table.Cell>{r.payment_count || 1}</Table.Cell>
                <Table.Cell>{r.status}</Table.Cell>
                <Table.Cell textAlign="right">
                  <LocLink to={`/account/layaway/${r.deferred_purchase_id}`}>{strings.getString('DETAILS', 'Details')}</LocLink>
                </Table.Cell>
              </Table.Row>
            ))}

            {!deferred || deferred.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={6} singleLine>
                  {strings.getString("NO_ACTIVE_LAYAWAYS", "Currently no active Layaways")}
                </Table.Cell>
              </Table.Row>
            ) : null}

          </Table.Body>
        </FixedTable>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsLayaway)
