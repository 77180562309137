import React, { Fragment } from 'react'
import strings from '../../../strings'
import './styles.scss'

const StaticString = (props) => {
	const { stringName } = props
	const string = strings.getString(stringName, 'FIX: ' + stringName)
	return(
		<Fragment>{string}</Fragment>
	)
}

export default StaticString
