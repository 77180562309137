import {
    SET_TWITCH_ACCOUNT_DETAILS,
    CLEAR_TWITCH_ACCOUNT_DETAILS,
} from '../actionTypes'

import { identityApi } from '../../utilities/api'


export const fetchTwitchAccountDetails = () => {
    return async function(dispatch) {
        const res = await identityApi.get('/user/external/twitch/is-account-connected')
        dispatch({
            type: SET_TWITCH_ACCOUNT_DETAILS,
            payload: res.data
        })
    }
}

export const getTwitchConnectUrl = (redirectUrl) => {
    return async function() {
        const res = await identityApi.post('/user/external/twitch/get-url-code', { redirectUrl })
        return res.data
    }
}

export const connectTwitchAccount = (code, state) => {
    return async function() {
        await identityApi.post(`/user/external/twitch/authorize/${code}/${state}`)
    }
}

export const disconnectTwitchAccount = () => {
    return async function(dispatch) {
        await identityApi.post('/user/external/twitch/disconnect-account')
        dispatch(clearTwitchAccountDetails())
    }
}

export const clearTwitchAccountDetails = () => {
    return {
        type: CLEAR_TWITCH_ACCOUNT_DETAILS,
    }
}