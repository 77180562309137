/*

COMPONENT: Content Card
Displays Crowfall content and related asset with the option of linking user's to another page via the entire card, or the image by itself.

PROPERTY           DEFAULT   REQUIREMENT  TYPE    DESCRIPTION

img                          Required     String       Asset related to image. Can be made linkable if the imgLink prop is included 
title                        Required     String       Title of the card's content
date                         Optional     String       formatted and displayed below the title.
subtitle                     Optional     String       When present, takes the place of the 'date' props position
capsules           []        Optional     Array        Ordered in top left corner positioned on top of the asset.
                                                       Array of Objects with properties "id", "label", & a Boolean for the desired color (See Capsule Component)
tags               []        Optional     Array        Displayed within the body content of the Card beneath the title/subtitle.
                                                       Array of Objects with properties 'id' & 'content'
description                  Optional     HTML || Str  Short description of content.
additionalContent            Optional     HTML || Str  Content to sit at the bottom left corner of the card's Footer. Used for CTA Buttons, etc.  
cardLink                     Optional     String       Makes the entire card a link to the desired webpage.
imgLink                      Optional     String       Adds a link ONLY to the image asset.
isExternalLink     False     Optional     Boolean      
isHorizontal       False     Optional     Boolean      Layout switches to horizontal view for Desktop: Image on the left, content on the right.

*/


import React from 'react'
import moment from 'moment-timezone'
import LocLink from '../LocLink'
import Capsule from '../../Fragments/Capsule'
import './styles.scss'

const ContentCard = (props) => {
  const { img, title, date, subtitle, tags = [], capsules = [], description, additionalContent, cardLink, imgLink, isExternalLink, isHorizontal } = props;
  let className = 'ContentCard'
  if (isHorizontal) className += ' ContentCard--horizontal'
  if (cardLink) className += ' ContentCard--cardIsALink'
  if (imgLink) className += ' ContentCard--imgIsALink'
  let formattedDate = date ? moment(date).format('dddd, MMMM D, YYYY') : null
  const sub = subtitle ? subtitle : formattedDate

  const ContentCard = () => (
    <div className={className}>

      <div className="ContentCard__capsules">{capsules.map(capsule => {
        const { id, label, red, orange, yellow, green, blue } = capsule
        return (
          <div className="ContentCard__capsule" key={id}><Capsule label={label} red={red} orange={orange} yellow={yellow} green={green} blue={blue}/></div>
        )
      })}</div>

      <div className="ContentCard__imgBody">
        {!imgLink && <div className="ContentCard__img" style={{backgroundImage: `url(${img})`}}/>}
        {imgLink && isExternalLink && <a href={imgLink} target="_blank" rel="noopener noreferrer"><div className="ContentCard__img" style={{backgroundImage: `url(${img})`}}/></a>}
        {imgLink && !isExternalLink && <div className="ContentCard__img" style={{backgroundImage: `url(${img})`}}><LocLink to={imgLink || ''}><div className='ContentCard__imgLink'/></LocLink></div>}
      </div>

      <div className="ContentCard__footer">
        <div className="ContentCard__footerContent">
          <div className="ContentCard__title">{title}</div>
          <div className="ContentCard__subtitle">{sub}</div>
          {tags.length > 0 && 
            <div className="ContentCard__tags">
              {tags.map(tag => {
                const { id, content } = tag
                return (
                  <div className='ContentCard__tag' key={id}>{content}</div>
                )
              })}
            </div>
          }
          {description && <div className="ContentCard__description" dangerouslySetInnerHTML={{__html: description}}/>}
        </div>

        {additionalContent && <div className="ContentCard__additionalContent">{additionalContent}</div>}
      </div>
    </div>
  )

  return (
    <>
      {cardLink && !isExternalLink && <LocLink to={cardLink || ''}>
        {ContentCard()}
      </LocLink>}
      
      {cardLink && isExternalLink && <a href={cardLink} target="_blank" rel="noopener noreferrer">
        {ContentCard()}  
      </a>}
      
      {!cardLink && ContentCard()}
    </>
  )
}

export default ContentCard
