import {
    FETCH_GUILD_CONFIGURATION,
    FETCH_GUILDS,
    SORT_GUILDS,
    PAGINATE_GUILDS,
    CLEAR_GUILDS_SORT_CRITERIA,
    CLEAR_GUILDS,
    SET_SELECTED_GUILD,
    CLEAR_SELECTED_GUILD,
} from '../actionTypes'

import {publicGuildApi} from '../../utilities/api'
import {camelCaseToSnakeCase} from "../../utilities/stringUtilities";
import _ from "lodash";


export const fetchGuildConfiguration = () => {
    return async function (dispatch) {
        let configuration = {}
        try {
            const res = await publicGuildApi.get('/public/guild-configuration')
            configuration = camelCaseToSnakeCase(res.data)
        } catch (err) {
            console.log('Error retrieving Guild Configuration', err)
        }
        dispatch({
            type: FETCH_GUILD_CONFIGURATION,
            payload: configuration
        })
    }
}

export const fetchGuildsSubset = (queries) => {
    return async function (dispatch) {
        let guilds = []
        const uri = '/public/guild-search?' + queries

        try {
            let guildsRes = await publicGuildApi.get(uri)
            guilds = camelCaseToSnakeCase(guildsRes.data)
        } catch (err) {
            console.log('Error retrieving Guilds', err)
        }
        dispatch({
            type: FETCH_GUILDS,
            payload: guilds
        })
        dispatch(sortGuilds())
    }
}

export const fetchSingleGuild = id => {
    return async function () {
        let guild = {}
        try {
            const res = await publicGuildApi.get(`/public/guild/${id}`)
            guild = camelCaseToSnakeCase(res.data)
        } catch (err) {
            console.log('Error retrieving Guild', err)
        }
        return guild
    }
}

export const sortGuilds = criteria => {
    return (dispatch) => {
        dispatch({
            type: SORT_GUILDS,
            payload: criteria
        })
        dispatch(paginateGuilds(1))
    }
}

export const paginateGuilds = page => {
    return (dispatch) => {
        dispatch({
            type: PAGINATE_GUILDS,
            payload: page
        })
    }
}

export const clearGuildsSortCriteria = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_GUILDS_SORT_CRITERIA
        })
        dispatch(paginateGuilds(1))
    }
}

export const clearGuilds = () => ({
    type: CLEAR_GUILDS
})

export const setSelectedGuild = (name) => ({
    type: SET_SELECTED_GUILD,
    payload: name
})

export const clearSelectedGuild = () => ({
    type: CLEAR_SELECTED_GUILD
})