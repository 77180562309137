import React from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'
import _ from 'lodash'

const mapStateToProps = state => {
  return {
    language: state.language,
    session: state.session,
  }
}

class PrivateRoute extends React.Component {
  render() {
    const { component: Component, session, language, ...rest } = this.props
    const returnPath = btoa(`${this.props.location.pathname}${this.props.location.search}`)
    let redirectLink = `/${language}/login`
    if (_.isEmpty(this.props.location.search)) {
      redirectLink += `?r=${returnPath}`
    } else {
      redirectLink += `${this.props.location.search}&r=${returnPath}`
    }
    return (
      <Route {...rest} render={(props) => (
        !_.isNil(_.result(session, 'user')) ? <Component {...props} /> : <Redirect to={redirectLink}/>
      )}/>
    )
  }
}

export default withRouter(connect(mapStateToProps)(PrivateRoute))
