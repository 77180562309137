import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import { Button, Container, Grid } from 'semantic-ui-react'
import contentful from '../../utilities/contentful'
import _ from 'lodash'
import LocLink from '../../componentLibrary/Elements/LocLink'
import strings from '../../strings'

class RaceDetailPage extends React.Component {

  state = {
    entry: {},
  }

  async componentDidMount() {
    await this.getContent()
  }

  getContent = async () => {
    const pantheonName = _.result(this.props, 'match.params.pantheonName')
    const entry = (await contentful.getEntries('raceClass', `fields.type[match]=Pantheon&fields.id[match]=${pantheonName}`)).map(e => {
      e.bannerAssetUrl = _.result(e, 'bannerAsset.assetUrl')
      e.title = _.upperCase(e.name)
      e.thumbnails = (e.imageAssets || []).map(a => {
        return { src: a.assetUrl, title: a.title }
      })
      return e
    })[0] || {}

    this.setState({ 'entry': entry })
  }

  render() {
    const { entry } = this.state
    return (
      <Page contentId={assets.contentfulIds.PANTHEON_DETAILS_PAGE} update={this.getContent.bind(this)} padding={'0em'}>
        <Container fluid>
          {entry.bannerAssetUrl ? (
            <div style={{
              backgroundImage: `url(${entry.bannerAssetUrl})`,
              maxHeight: '568px',
              backgroundSize: 'cover',
              backgroundPosition: 'right center',
            }}>


              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={2}>
                  </Grid.Column>
                  <Grid.Column width={8}>

                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                        <span style={{
                          textShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 1px',
                          color: 'white',
                          fontSize: '36px',
                          padding: '40px 0 20px 0',
                        }}>{entry.title}</span>

                        {(entry.iconList || []).map((i, l) => (
                          <img alt={i.title} style={{
                            display: 'block',
                            padding: '20px',
                            height: '200px',
                          }} key={l} src={i.assetUrl}/>
                        ))}
                      </div>
                    </div>

                  </Grid.Column>
                </Grid.Row>
              </Grid>


            </div>
          ) : ''}


          <div style={{
            padding: '40px 0',
            backgroundColor: 'rgb(249, 249, 249)',
          }}>

            <Container>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <div dangerouslySetInnerHTML={{ __html: entry.description }} style={{
                      fontSize: '14px',
                    }}></div>

                  </Grid.Column>
                  <Grid.Column width={6}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      {(entry.thumbnails || []).map((t, i) => (
                        <img alt={t.title} key={i} title={t.title} src={t.src} style={{
                          display: 'block',
                          width: '100%',
                          marginBottom: '10px',
                        }}/>
                      ))}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>

          {/*<div style={{*/}
          {/*  display: 'flex',*/}
          {/*  flexDirection: 'column',*/}
          {/*  alignItems: 'center',*/}
          {/*  padding: '40px 0',*/}
          {/*}}>*/}
          {/*  <Button as={LocLink} to='/store?category=064b6196-98fe-46c7-94d4-9485dad599de' className="Button__ACE Button__ACE-large">{strings.getString('PLAY_NOW', 'Play Now')}</Button>*/}
          {/*</div>*/}

        </Container>
      </Page>
    )
  }
}

export default RaceDetailPage

