import React from 'react'
import './style.scss'

const ColorSwatch = (props) => {
  const { rgb, metallic } = props
  const r = rgb[0]
  const g = rgb[1]
  const b = rgb[2]
  const style = { backgroundColor: `rgb(${r}, ${g}, ${b})` }
  return (
    <div className="ColorSwatch" style={style}>
	    {metallic && <img alt="color_swatch" className="ColorSwatch__metallicOverlay" src="https://media.crowfall.com/guild/GuildCrestEditor_MetallicOverlay.png"/>}
    </div>
  )
}

export default ColorSwatch
