import React from 'react'
import { api } from '../utilities/api'
import { Button, Dimmer, Form, Loader, Message, Pagination, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import {fetchUserGuildApplications, withdrawGuildApplicationRequest} from '../redux/actions/user'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = (state) => {
  return {
    user: state.user.userProfile,
    guildApplications: state.user.guildApplications,
    guildId: state.guildManagement.guildId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGuildApplications: () => dispatch(fetchUserGuildApplications()),
    withdrawGuildApplication: (guildInviteRequestId) => dispatch(withdrawGuildApplicationRequest(guildInviteRequestId))
  }
}

class AccountDetailsGifts extends React.Component {

  state = {
    loading: false,
    records: [],
    filteredRecords: [],
    filter: '',
    page: 1,
    totalPages: 1,
    pageSize: 15,
    targetUsername: '',
    quantity: 1
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    await this.props.fetchGuildApplications()
    const records = this.props.guildApplications
    this.setState({
      records,
      filteredRecords: records,
      totalPages: Math.floor(this.state.records.length / this.state.pageSize) + 1,
      page: 1,
      quantity: 1
    })
  }

  handleFilter(e, { name, value }) {
    this.setState({ [name]: value })
    const search = _.lowerCase(value)
    const filtered = this.state.records.filter(r => {
      return _.lowerCase(r.invited_by).includes(search) || _.lowerCase(r.display_name).includes(search) || _.lowerCase(r.name).includes(search)
    })
    this.setState({
      filteredRecords: filtered,
      totalPages: Math.floor(filtered.length / this.state.pageSize) + 1,
      page: 1,
    })
  }

  handlePageChange(e, { activePage }) {
    this.setState({ page: activePage })
  }

  handleApplication = (r) => {
    const { withdrawGuildApplication, fetchGuildApplications } = this.props
    return async () => {
      const val = await swal({
        title: strings.getString('WITHDRAW_APPLICATION', 'Withdraw Application'),
        buttons: {
          cancel: strings.getString('CANCEL', 'Cancel'),
          withdraw: {
            text: strings.getString('WITHDRAW', 'Withdraw'),
            value: 'withdraw',
            closeModal: false
          },
        },
        content: (
          <div style={{ textAlign: 'left' }}>
            <p>
              {strings.getString('ACCOUNT_GUILD_WITHDRAW_APP', 'Withdraw your application to')} <strong>{r.display_name}</strong>.
            </p>

            <Form>

            </Form>

          </div>
        ),
      })

      if (val === 'withdraw') {
        try {
          await withdrawGuildApplication(r.guild_invite_request_id)
          await swal(`${strings.getString('ACCOUNT_GUILD_WITHDRAW_APP_SUCCESS', 'Successfully withdrawn application to join')} ${r.display_name}.`, {
            icon: 'success',
          })
          await this.getContent()
        } catch (err) {
          const responseMessage = _.result(err.response.data, 'Message')
          await swal(`${responseMessage}`, {
            icon: 'error'
          })
          await this.getContent()
        }
      }
    }
  }

  render() {

    const { loading, filteredRecords, filter, totalPages, page, pageSize } = this.state
    const { guildId } = this.props

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('GUILD_APPLICATIONS', 'Guild Applications')}</h1>
        {!guildId ? '' : (<Message color="yellow">{strings.getString('ACCOUNT_GUILD_APPS_ALREADY_MEMBER', 'You are currently a member of a guild and will not be able to create new guild applications.')}</Message>)}

        <Form>
          <Form.Input autoComplete="off" type="text"
                      placeholder={strings.getString('SEARCH_APPLICATIONS', 'Search Applictaions')}
                      name='filter'
                      value={filter}
                      onChange={this.handleFilter.bind(this)}/>
        </Form>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('GUILD', 'Guild')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('STATUS', 'Status')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{strings.getString('ACTION', 'Action')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredRecords.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize).map(r => (
              <Table.Row key={r.guild_invite_request_id}>
                <Table.Cell>
                  <span style={{ fontSize: '16px' }}>{r.display_name}</span>
                  <br/>
                  <span style={{ color: 'rgb(150, 150,150)' }}>{(new Date(r.date_created)).toLocaleString()}</span>
                </Table.Cell>
                <Table.Cell singleLine>{_.startCase(r.status)}</Table.Cell>
                <Table.Cell collapsing textAlign="center">

                  {('active').includes(r.status)  ? (
                    <Button onClick={this.handleApplication(r)} size="mini" compact primary content={strings.getString('WITHDRAW', 'Withdraw')}/>
                  ) : (
                    <Button disabled size="mini" compact content={strings.getString('WITHDRAW', 'Withdraw')}/>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination defaultActivePage={page} totalPages={totalPages} onPageChange={this.handlePageChange.bind(this)}/>
        </div>


        {/*</Tab>*/}

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsGifts)
