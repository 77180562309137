/*

Pagination: Component

PROPERTY      DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

limit         5        optional     Integer   Sets the number of items to be displayed on each page
list                   required     Array     Items to be paginated
paginate               required     function  function to update store with paginated items

limitOptions  false    optional     Boolean   removes additional page selection methods. Only previous and next buttons will be rendered

*/

import React from 'react'

import './styles.scss'
import strings from '../../../strings';

class Pagination extends React.Component {

    componentDidMount() {
        this.paginate()
    }

    paginate(nextPage) {
        this.props.paginate(nextPage)
    }

    movePage(amount) {
        const limit = parseInt(this.props.limit)
        const { page } = this.props
        const length = this.props.list.length
        const pages = Math.ceil(length/limit)
        const nextPage = page + amount
        if (nextPage > pages || nextPage < 1) return
        this.paginate(nextPage)
    }

    render() {

        const { list, limit, page, limitOptions } = this.props
        const pages = Math.ceil(list.length/limit)

        return (
            <div className='Pagination'>
                <div className='Pagination__button--primary' onClick={ () => this.movePage(-1) }>{strings.getString("PREVIOUS", "Previous")}</div>
                { !limitOptions && page > 3 && <div className='Pagination__button' onClick={ () => this.movePage(-(page -1)) }>1</div>}
                { !limitOptions && page > 3 && <div className='Pagination__ellipses'>...</div>}
                { !limitOptions && page > 2 && <div className='Pagination__button' onClick={ () => this.movePage(-2) }>{ page - 2 }</div>}
                { !limitOptions && page > 1 && <div className='Pagination__button' onClick={ () => this.movePage(-1) }>{ page - 1 }</div>}
                <div className='Pagination__button--current'>{ page }</div>
                { !limitOptions && pages - page >= 1 && <div className='Pagination__button' onClick={ () => this.movePage(1) }>{ page + 1 }</div>}
                { !limitOptions && pages - page > 1 && <div className='Pagination__button' onClick={ () => this.movePage(2) }>{ page + 2 }</div>}
                { !limitOptions && pages - page > 2 && <div className='Pagination__ellipses'>...</div>}
                { !limitOptions && pages - page > 2 && <div className='Pagination__button' onClick={ () => this.movePage(pages - page) }>{pages}</div>}
                <div className='Pagination__button--primary' onClick={ () => this.movePage(1) }>{strings.getString("NEXT", "Next")}</div>
            </div>
        )
    }
}

export default Pagination