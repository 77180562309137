/*

AccordionItem: Component

PROPERTY    DEFAULT  REQUIREMENT  TYPE     DESCRIPTION
arrowLeft   false    optional     Boolean  Moves arrow from the right to the left of the label

*/

import React from 'react'
import './styles.scss'

const AccordionItem = (props) => {

  const { shouldShow, select, label, children, arrowLeft, gray } = props
  let className = 'AccordionItem'
  if (shouldShow) className += ' AccordionItem--show'
  if (arrowLeft) className += ' AccordionItem--arrowLeft'
  if (gray) className += ' AccordionItem--gray'

  return (
    <div className={className}>
      <div className="AccordionItem__handle" onClick={select}>{label}</div>
      <div className="AccordionItem__content">{children}</div>
    </div>
  )
}

export default AccordionItem