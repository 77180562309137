import React from 'react'
import { connect } from 'react-redux'
import { clearModalContent } from '../../../redux/actions'
import './styles.scss'

const mapStateToProps = state => {
  const { modal } = state
  return {
    content: modal.content
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearModalContent: () => dispatch(clearModalContent())
  }
}

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.content = React.createRef()

    this.handleModalClick = this.handleModalClick.bind(this)
    this.handleEscapeKey = this.handleEscapeKey.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleEscapeKey, true)
  }

  handleModalClick(event) {
    const { clearModalContent } = this.props
    const path = event.path || event.composedPath()
    if (!path.includes(this.content.current)) clearModalContent()
  }

  handleEscapeKey(event) {
    if (event.keyCode === 27) this.props.clearModalContent()
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleEscapeKey, true)
  }

  render() {
    const { content, clearModalContent } = this.props
    let className = 'Modal'
    return (
      <div className={className} onClick={(event) => this.handleModalClick(event.nativeEvent)}>
        {content &&
          <div className="Modal__content" ref={this.content}>
            {content}
            <div className="Modal__close" onClick={clearModalContent}>&#215;</div>
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)