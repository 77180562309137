import React from 'react'
import { Redirect } from 'react-router-dom'
import MediaTile from '../../Elements/MediaTile'
import './styles.scss'


class MediaList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: [],
      limit: 21,
      redirectUrl: null,
    }

    this.showMore = this.showMore.bind(this);
  }

  showMore() {
    const { list } = this.props
    const { limit, visible } = this.state
    const amountVisible = visible.length
    const newEnd = amountVisible + limit
    const newVisible = [...visible, ...list.slice(amountVisible, newEnd)]

    this.setState({
      visible: newVisible
    })
  }

  componentDidMount() {
    if (localStorage.getItem('amountVisible')) {
      this.setState({ limit: localStorage.getItem('amountVisible') });
      localStorage.removeItem('amountVisible');
    }
    this.showMore()
  }

  componentDidUpdate(prevState) {
    const { list: currentList } = this.props
    const { list: previousList } = prevState;
    if (currentList.length !== previousList.length) {
      this.showMore()
    }
  }

  handleThumbClick(slug) {
    this.maintainScrollY()
    this.setState({ redirectUrl: slug })
  }

  maintainScrollY() {
    localStorage.setItem('scrollPosition', window.scrollY)
    localStorage.setItem('amountVisible', this.state.visible.length);
  }

  render() {
    const { list } = this.props
    const { visible, redirectUrl } = this.state
    const listLength = list.length
    const visibleLength = visible.length
    return (
      <div className="MediaList">

        {redirectUrl && <Redirect to={`${redirectUrl}`}/>}

        {
          visible.map((media, index) => {
            const { title, thumb, siteSlug } = media
            const isMore = visibleLength < listLength

            return (
              <div className="MediaList__media" key={title + '-' + index} >
                <div className="MediaList__modalClick">
                  <div className="MediaList__thumb" onClick={() => this.handleThumbClick(siteSlug)}>
                    <MediaTile thumb={thumb} title={title}/>
                  </div>
                </div>
                {isMore && index === visibleLength - 1 && <div className="MediaList__load" onClick={this.showMore}>
                  <div className="MediaList__loadInner">Load More</div>
                </div>}

              </div>
            )
          })
        }
      </div>
    )
  }
}

export default MediaList
