/*

component: MarketingFooter
for marketing driven content pages. Provides links to return to related page & optional link to forums

PROPERTY      DEFAULT  REQUIREMENT  TYPE    DESCRIPTION

forumLink              optional     String  link for external Crowfall content.    
returnLink             required     String  Link to direct user to desired page on Crowfall.
returnString           required     String  string depicting where user will be directed.

*/


import React from 'react'
import Button from '../../Fragments/Button'
import ExternalLink from '../ExternalLink'
import LocLink from '../LocLink'
import StaticString from '../../Fragments/StaticString'
import './styles.scss'


const MarketingFooter = (props) => {

    const { forumLink, returnLink, returnString } = props

    return (
        <div className='MarketingFooter'>
            <LocLink to={returnLink}>
                <Button>
                    <StaticString stringName={returnString}/>
                </Button>
            </LocLink>

            {forumLink &&
                <ExternalLink to={forumLink}>
                    <Button>
                        <StaticString stringName="DISCUSS" />
                    </Button>
                </ExternalLink>
            } 
        </div>
    )
}

export default MarketingFooter