import React from 'react'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import strings from '../../strings'
import './styles.scss'


const mapStateToProps = state => {
  return {
    language: state.language,
    session: state.session,
  }
}

class SupportPage extends React.Component {

  state = {
    loading: false,

  }


  render() {

    const { loading } = this.state
    const supportContent = strings.getString(
      "SUPPORT_EMAIL_US_AT", 
      "Please email us at ..." + <a href='mailto:support@monumental.io'>support@monumental.io</a> + " with any questions you may have.",
      {
        email: 'support@monumental.io'
      } 
    )

    return (
      <Page contentId={assets.contentfulIds.CUSTOMER_SUPPORT}>

        <Container text>
          <Dimmer active={loading} inverted>
            <Loader/>
          </Dimmer>

          <div className='SupportPage__content' dangerouslySetInnerHTML={{__html: supportContent}}></div>

        </Container>
      </Page>
    )
  }
}

export default connect(mapStateToProps)(SupportPage)
