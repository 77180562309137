import * as React from 'react'
import './styles.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // You can also log the error to an error reporting service
    console.error(error, info)
    console.info(`There was an error: ${error}, ${JSON.stringify(info)}`)

    try {
      console.info('stack', info.stack, error.stack)
    } catch (err) {
      console.info('cant get stack')
    }

  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary">
          <div className="ErrorBoundary__content">
            <h1>Uh Oh!</h1>
            <h4>Looks like we're having some server issues.</h4>
            <h4>Please refresh the page, or come back later.</h4>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
