import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Page from '../../../../componentLibrary/Models/Page'
import assets from '../../../../assets'
import strings from '../../../../strings'
import contentful from '../../../../utilities/contentful'
import LoginForm from '../../LoginForm'
import HelperLinks from '../../HelperLinks'
import DownloadClientButton from '../../../../componentLibrary/Fragments/DownloadClientButton'
import _ from 'lodash'
import { fetchUserCodes } from '../../../../redux/actions/user'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        user: state.user.userProfile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUserCodes: () => dispatch(fetchUserCodes()),
    }
}

class CrowCodesLander extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: null,
            tagline: null,
            rightPanelContent: null,
            banner: null,
            code: null,
        }
    }

    async componentDidMount() {
        const content = await contentful.getEntry(assets.contentfulIds.CROW_REWARDS_PAGE)
        this.setState({
            title: content.pageTitle,
            tagline: content.tagline,
            rightPanelContent: content.pageHeader,
            loggedInContent: content.pageFooter,
            banner: _.result(content, 'banner.assetUrl')
        })

        if (this.props.session) this.getUsersCode()
    }

    componentDidUpdate(prevProps) {
        const { session: prevSession } = prevProps
        const { session: currSession } = this.props
        if (prevSession !== currSession && currSession) this.getUsersCode()
    }

    async getUsersCode() {
        const codes = await this.props.fetchUserCodes()
        let codeObj = _.find(codes, { "code_program_id": "9a6d01e0-42bf-45d8-b156-3ae755ea368e" }) || {}
        this.setState({ code: codeObj.code })
    }

    render() {
        const { session, user } = this.props
        const username = _.result(user, "username") || ""
        const { title, tagline, rightPanelContent, loggedInContent, banner, code } = this.state
        return (
            <Page header={{ title, banner }} bannerShort>
                <div className="CrowCodesLander">
                    <div className="CrowCodesLander__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>

                    <div className="CrowCodesLander__body">
                        {!session && <div className="CrowCodesLander__panel">
                            <LoginForm />
                            <br/>
                            <HelperLinks showAccountHelp/>
                        </div>}

                        {session && <div className="CrowCodesLander__panel">
                            <div className="CrowCodesLander__successMsg">
                                {strings.getString("SIGN_IN_SUCCESSFUL", "Sign in Successful")} 
                                <div className="CrowCodesLander__icon"><FontAwesomeIcon icon="check"/></div>
                            </div>
                            <div className="CrowCodesLander__code" dangerouslySetInnerHTML={{ __html: strings.getString("YOUR_DIGITAL_GAME_CODE_IS", `Your Digital Game Code is: ${code}`, { code }) }} />
                            
                            <p dangerouslySetInnerHTML={{ __html: strings.getString("CROW_CODES_FRIEND_TRIAL_LINK", "", { code: `friend-${username}` }) }}/>
                            <div className="CrowCodesLander__content" dangerouslySetInnerHTML={{ __html: loggedInContent }} />
                            <div className="CrowCodesLander__subheader">{strings.getString("DOWNLOAD_CROWFALL_NOW", "Download Crowfall Now")}</div>
                            <DownloadClientButton />
                        </div>}

                        <div className="CrowCodesLander__panel" dangerouslySetInnerHTML={{ __html: rightPanelContent }}/>
                    </div>

                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CrowCodesLander)