import {
    SET_USER,
    SET_USER_VIRTUAL_WALLET,
    SET_USER_GUILD_APPS,
    SET_USER_GUILD_INVITES,
    SET_USER_GIFTS_AVAILABLE,
    SET_ACCOUNT_DISCOUNTS,
    SET_USER_GIFTS,
    SET_USER_ENTITLEMENTS,
    SEARCH_GIFTABLE_ENTITLEMENTS,
    SORT_GIFTABLE_ENTITLEMENTS,
    PAGINATE_GIFTABLE_ENTITLEMENTS,
    CLEAR_USER_ENTITLEMENTS,
    SEARCH_GIFTS_GIVEN,
    SORT_GIFTS_GIVEN,
    PAGINATE_GIFTS_GIVEN,
    SEARCH_GIFTS_RECEIVED,
    SORT_GIFTS_RECEIVED,
    PAGINATE_GIFTS_RECEIVED,
    CLEAR_USER_GIFTS,
    CLEAR_USER,
} from '../actionTypes'
import _ from 'lodash'


const initialState = {
    userProfile: {},
    userId: null,
    accountRegions: [{
        region_name: 'Global',
    }],
    userVirtualWallet: {},
    guildApplications: [],
    guildInvites: [],
    giftsAvailable: [],
    accountDiscounts: [],

    gifts: [],
    
    giftsGiven: [],
    giftsGivenSearchCriterion: null,
    giftsGivenSearched: [],
    giftsGivenSortCriteria: {},
    giftsGivenSorted: [],
    giftsGivenPaginationCriteria: {
        page: 1,
        limit: 15,
    },
    giftsGivenPaginated: [],
    
    giftsReceived: [],
    giftsReceivedSearchCriterion: null,
    giftsReceivedSearched: [],
    giftsReceivedSortCriteria: {},
    giftsReceivedSorted: [],
    giftsReceivedPaginationCriteria: {
        page: 1,
        limit: 15,
    },
    giftsReceivedPaginated: [],

    entitlements: [],
    giftableEntitlements: [],
    giftableEntitlementsSearchCriterion: null,
    giftableEntitlementsSearched: [],
    giftableEntitlementsSortCriteria: {},
    giftableEntitlementsSorted: [],
    giftableEntitlementsPaginationCriteria: {
        page: 1,
        limit: 15,
    },
    giftableEntitlementsPaginated: [],
}

const user = (state = initialState, action) => {
    switch(action.type) {
        case SET_USER:
            const { user, userId, accountRegions } = action.payload
            return {
                ...state,
                userProfile: user,
                userId,
                accountRegions,
            }
        case SET_USER_VIRTUAL_WALLET:
            return {
                ...state,
                userVirtualWallet: action.payload,
            }
        case SET_USER_GUILD_APPS:
            return {
                ...state,
                guildApplications: action.payload
            }
        case SET_USER_GUILD_INVITES:
            return {
                ...state,
                guildInvites: action.payload
            }
        case SET_USER_GIFTS_AVAILABLE:
            return {
                ...state,
                giftsAvailable: action.payload,
            }
        case SET_ACCOUNT_DISCOUNTS:
            return {
                ...state,
                accountDiscounts: action.payload,
            }
        case SET_USER_GIFTS:
            const { gifts, giftsReceived, giftsGiven } = action.payload
            return {
                ...state,
                gifts,
                giftsGiven,
                giftsReceived,
            }
        case SEARCH_GIFTS_GIVEN:
            const giftsGivenSearchCriterion = action.payload === undefined ? state.giftsGivenSearchCriterion : action.payload
            const giftsGivenSearched = giftsGivenSearchCriterion ? state.giftsGiven.filter(giftsGivenSearchCriterion) : state.giftsGiven
            return {
                ...state,
                ...{ giftsGivenSearchCriterion, giftsGivenSearched }
            }
        case SORT_GIFTS_GIVEN:
            const giftsGivenSortCriteria = action.payload === undefined ? state.giftsGivenSortCriteria : action.payload
            const giftsGivenSorted = !_.isEmpty(giftsGivenSortCriteria) ? sort(state.giftsGivenSearched, giftsGivenSortCriteria) : state.giftsGivenSearched
            return {
                ...state,
                ...{ giftsGivenSortCriteria, giftsGivenSorted }
            }
        case PAGINATE_GIFTS_GIVEN:
            const giftsGivenPage = action.payload ? action.payload : state.giftsGivenPaginationCriteria.page
            const giftsGivenPaginated = paginate(state.giftsGivenSorted, state.giftsGivenPaginationCriteria.limit, giftsGivenPage)
            return {
                ...state,
                giftsGivenPaginationCriteria: {
                    limit: state.giftsGivenPaginationCriteria.limit,
                    page: giftsGivenPage,
                },
                giftsGivenPaginated
            }
        case SEARCH_GIFTS_RECEIVED:
            const giftsReceivedSearchCriterion = action.payload === undefined ? state.giftsReceivedSearchCriterion : action.payload
            const giftsReceivedSearched = giftsReceivedSearchCriterion ? state.giftsReceived.filter(giftsReceivedSearchCriterion) : state.giftsReceived 
            return {
                ...state,
                ...{ giftsReceivedSearchCriterion, giftsReceivedSearched }
            }
        case SORT_GIFTS_RECEIVED:
            const giftsReceivedSortCriteria = action.payload === undefined ? state.giftsReceivedSortCriteria : action.payload
            const giftsReceivedSorted = !_.isEmpty(giftsReceivedSortCriteria) ? sort(state.giftsReceivedSearched, giftsReceivedSortCriteria) : state.giftsReceivedSearched
            return {
                ...state,
                ...{ giftsReceivedSortCriteria, giftsReceivedSorted }
            }
        case PAGINATE_GIFTS_RECEIVED:
            const giftsReceivedPage = action.payload ? action.payload : state.giftsReceivedPaginationCriteria.page
            const giftsReceivedPaginated = paginate(state.giftsReceivedSorted, state.giftsReceivedPaginationCriteria.limit, giftsReceivedPage)
            return {
                ...state,
                giftsReceivedPaginationCriteria: {
                    limit: state.giftsReceivedPaginationCriteria.limit,
                    page: giftsReceivedPage,
                },
                giftsReceivedPaginated
            }
        case CLEAR_USER_GIFTS:
            return {
                ...state,
                gifts: [],
    
                giftsGiven: [],
                giftsGivenSearchCriterion: null,
                giftsGivenSearched: [],
                giftsGivenSortCriteria: {},
                giftsGivenSorted: [],
                giftsGivenPaginationCriteria: {
                    page: 1,
                    limit: 15,
                },
                giftsGivenPaginated: [],
                
                giftsReceived: [],
                giftsReceivedSearchCriterion: null,
                giftsReceivedSearched: [],
                giftsReceivedSortCriteria: {},
                giftsReceivedSorted: [],
                giftsReceivedPaginationCriteria: {
                    page: 1,
                    limit: 15,
                },
            }
        case SET_USER_ENTITLEMENTS:
            const { entitlements, giftableEntitlements } = action.payload
            return {
                ...state,
                entitlements,
                giftableEntitlements,
            }
        case SEARCH_GIFTABLE_ENTITLEMENTS:
            const giftableEntitlementsSearchCriterion = action.payload === undefined ? state.giftableEntitlementsSearchCriterion : action.payload
            const giftableEntitlementsSearched = giftableEntitlementsSearchCriterion ? state.giftableEntitlements.filter(giftableEntitlementsSearchCriterion) : state.giftableEntitlements
            return {
                ...state,
                giftableEntitlementsSearchCriterion,
                giftableEntitlementsSearched,
            }
        case SORT_GIFTABLE_ENTITLEMENTS:
            const giftableEntitlementsSortCriteria = action.payload === undefined ? state.giftableEntitlementsSortCriteria : action.payload
            const giftableEntitlementsSorted = !_.isEmpty(giftableEntitlementsSortCriteria) ? sort(state.giftableEntitlementsSearched, giftableEntitlementsSortCriteria) : state.giftableEntitlementsSearched
            return {
                ...state,
                giftableEntitlementsSortCriteria,
                giftableEntitlementsSorted,
            }
        case PAGINATE_GIFTABLE_ENTITLEMENTS:
            const giftableEntitlementsPage = action.payload ? action.payload : state.giftableEntitlementsPaginationCriteria.page
            const giftableEntitlementsPaginated = paginate(state.giftableEntitlementsSorted, state.giftableEntitlementsPaginationCriteria.limit, giftableEntitlementsPage)   
            return {
                ...state,
                giftableEntitlementsPaginationCriteria: {
                    limit: state.giftableEntitlementsPaginationCriteria.limit,
                    page: giftableEntitlementsPage
                },
                giftableEntitlementsPaginated
            }
        case CLEAR_USER_ENTITLEMENTS:
            return {
                ...state,
                entitlements: [],
                giftableEntitlements: [],
                giftableEntitlementsSearchCriterion: null,
                giftableEntitlementsSearched: [],
                giftableEntitlementsSortCriteria: {},
                giftableEntitlementsSorted: [],
                giftableEntitlementsPaginationCriteria: {
                    page: 1,
                    limit: 15,
                },
                giftableEntitlementsPaginated: [],
            }
        case CLEAR_USER:
            return initialState
        default:
            return state
    }
}

function sort(list, criterion) {
    const { value, direction } = criterion
    return _.orderBy(list, [value], [direction === "ascending" ? "asc" : "desc"])
}


function paginate(list, limit, page) {
    if (limit === null) return list
    const limitInt = parseInt(limit)
    const start = limitInt * (page - 1)
    const end = start + limitInt
    return list.slice(start, end)
}

export default user