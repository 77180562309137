/*

COMPONENT: ContentBox

PROPERTY    DEFAULT  REQUIREMENT  TYPE    DESCRIPTION
children             optional     JSX     content to be displayed inside of box
expContent           optional     JSX     Populates add'l content below children when a user has clicked the content 'on'
accent               optional     String  Sets accent styling. OPTIONS: 'stoneborn', 'goldBar', 'goldBorder'

*/


import React from 'react'
import assets from '../../../assets'
import strings from '../../../strings'
import GoldBar from '../../Fragments/GoldBar'
import './styles.scss'

class ContentBox extends React.Component {
    state = {
        expContentOn: false,
    }


    expandContent = () => {
        this.setState({ expContentOn: !this.state.expContentOn });
    }

    render() {

        const { children, expContent, accent } = this.props
        const { expContentOn } = this.state
        let className = 'ContentBox'
        if (expContentOn) className += ' ContentBox--expanded'
        if (accent === 'stoneborn') className += ' ContentBox--stoneborn'
        if (accent === 'goldBar') className += ' ContentBox--goldBar'
        if (accent === 'goldBorder') className += ' ContentBox--goldBorder'
        
        return (
            <div className={className}>
                {accent === 'stoneborn' && <img src={assets.staticImageUrls.CONTENT_BOX_TOP_ACCENT} className='ContentBox__topAccent--stoneborn' alt='Stoneborn accent'/>}
                {(accent === 'goldBar' || accent === 'goldBorder') && <div className='ContentBox__topAccent--goldBar'><GoldBar/></div>}
                {accent === 'goldBorder' && <div className='ContentBox__rightAccent--goldBorder'><GoldBar vertical/></div>}
                {accent === 'goldBorder' && <div className='ContentBox__bottomAccent--goldBorder'><GoldBar /></div>}
                {accent === 'goldBorder' && <div className='ContentBox__leftAccent--goldBorder'><GoldBar vertical/></div>}

                {children}
                {expContent && 
                    <div className='ContentBox__expandableContent'>
                        <div className='ContentBox__moreButton' onClick={() => this.expandContent()}>{strings.getString('MORE', 'More')}</div>
                        <div className='ContentBox__expandedSection'>
                            {accent === 'stoneborn' && <img src={assets.staticImageUrls.CONTENT_BOX_SECTION_ACCENT} className='ContentBox__sectionAccent--stoneborn' alt='Stoneborn accent'/>}
                            <div className='ContentBox__content'>{expContent}</div>
                            <div className='ContentBox__lessButton' onClick={() => this.expandContent()}>{strings.getString('LESS', 'Less')}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ContentBox