import {
    ADD_BLOGS,
    FILTER_BLOGS,
    SEARCH_BLOGS,
    PAGINATE_BLOGS,
    CLEAR_BLOGS_SEARCH_CRITERIA,
    CLEAR_BLOGS,
} from '../actionTypes'

import _ from 'lodash'

const initialState = {
    all: [],
    fetched: false,
    filterCriteria: null,
    filtered: [],
    searchCriteria: null,
    searched: [],
    paginationCriteria: {
        limit: 5,
        page: 1,
    },
    paginated: [],
}

const blogs = (state = initialState, action) => {
    switch(action.type) {
        case ADD_BLOGS:
            return {
                ...state,
                all: [...state.all, ...action.payload]
            }
        case FILTER_BLOGS:
            const filterCriteria = action.payload === undefined ? state.filterCriteria : action.payload
            const filtered = !_.isEmpty(filterCriteria) ? filterByTags(state.all, filterCriteria) : state.all
            return {
                ...state,
                fetched: true,
                ...{ filterCriteria, filtered }
            }
        case SEARCH_BLOGS:
            const searchCriteria = action.payload === undefined ? state.searchCriteria : action.payload
            const searched = searchCriteria ? state.filtered.filter(searchCriteria) : state.filtered
            return {
                ...state,
                ...{ searchCriteria, searched }
            }
        case PAGINATE_BLOGS:
            const limit = state.paginationCriteria.limit
            const page = action.payload ? action.payload : state.paginationCriteria.page
            const paginated = paginate(state.searched, limit, page)
            return {
                ...state,
                paginationCriteria: {
                    limit,
                    page
                }, 
                paginated
            }
        case CLEAR_BLOGS_SEARCH_CRITERIA:
            return {
                ...state,
                searchCriteria: initialState.searchCriteria,
                searched: state.all
            }
        case CLEAR_BLOGS:
            return initialState
        default: 
            return state
    }
}

function filterByTags(blogs, criterion) {
    let currentBlogs = [...blogs]
    const filteredBlogs = currentBlogs.filter(blog => {
        blog.tagNames = blog.tags ? blog.tags.map(tag => {return _.kebabCase(tag.name)}) : []
        return _.isEmpty(_.difference(criterion, blog.tagNames)) === true
    })
    return filteredBlogs
}

function paginate(list, limit, page) {
    if (limit === null) return list
    const limitInt = parseInt(limit)
    const start = limitInt * (page - 1)
    const end = start + limitInt
    return list.slice(start, end)
}

export default blogs