import React from 'react'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import connect from 'react-redux/es/connect/connect'

class InnovaPopup extends React.Component {

  componentDidMount() {
    swal({
      icon: 'info',
      title: 'Hello and Welcome to Crowfall!',
      content: (
        <div>
          <p>If you are one of our current backers, you can proceed to the log-in by closing this pop-up window.</p>
          <p>If you are a player from Russia or CIS, you can access the game via <a href="https://ru.4game.com/crowfall/">https://ru.4game.com/crowfall/</a> and register an account to learn more!</p>
          <p>Thank you and we look forward to seeing you in Crowfall!</p>
        </div>
      ),
    })
  }

  render() {
    return (<React.Fragment/>)
  }
}

const mapStateToProps = state => {
  return {
    regions: state.regions,
  }
}

class InnovaRedirect extends React.Component {

  state = {
    showPopup: false,
  }

  static getDerivedStateFromProps(props, state) {
    state.showPopup = !_.isEmpty(_.find(props.regions, { region_name: 'Innova' }))
    return state
  }

  render() {

    const { showPopup } = this.state

    return (
      <React.Fragment>
        {showPopup ? (<InnovaPopup/>) : null}
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(InnovaRedirect)
