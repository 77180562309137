import React from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'

import strings from '../../../strings'
import Page from '../../../componentLibrary/Models/Page'
import PageNotFound from '../../../componentLibrary/Models/PageNotFound'
import PageColumn from '../../../componentLibrary/Fragments/PageColumn'
import SocialButtons from '../../../componentLibrary/Elements/SocialButtons'
import MarketingFooter from '../../../componentLibrary/Elements/MarketingFooter'
import contentful from '../../../utilities/contentful'
import './styles.scss'


class WarStoryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fetched: false,
            story: {},
            noContentFound: false,
        }
    }

    async componentDidMount() {
        await this.getContent()
        this.setState({ fetched: true })
    }

    async getContent() {
        const { uri } = this.props.match.params
        const stories = await contentful.getEntries('warStory', `fields.uri=${uri}`)
        if (_.isEmpty(stories)) {
            this.setState({ noContentFound: true })
            return
        }
        const story = stories[0]
        story.bannerAssetUrl = _.result(story, 'category.bannerAsset.assetUrl')
        this.setState({ story })
    }

    render() {

        const { fetched, story, noContentFound } = this.state
        const { username, storyName, publishDate, bannerAssetUrl, forumLinkUrl, description } = story
        const formattedDate = moment(publishDate).format('dddd, MMMM D, YYYY')
        const title = `${username}: ${storyName}`

        return (
            <React.Fragment>
                {fetched && noContentFound &&
                    <PageNotFound returnLabel={strings.getString("BACK_TO_WAR_STORIES", "Back to War Stories")} returnLink="/warstories"/>
                }

                {fetched && !noContentFound &&
                    <Page isMarketing header={{ title, subtitle: formattedDate, banner: bannerAssetUrl }}>
                        <div className='WarStoryPage'>
                            <div className='WarStoryPage__socialButtons'>
                                <SocialButtons facebook twitter mail title={title} />
                            </div>
                            <PageColumn>
                                <div dangerouslySetInnerHTML={{__html: description}} />
                            </PageColumn>
                            <MarketingFooter forumLink={forumLinkUrl} returnLink="/warstories" returnString="BACK_TO_WAR_STORIES"/>
                        </div>
                    </Page>
                }

            </React.Fragment>
        )
    }
}

export default WarStoryPage