import React from 'react'
import RegionBlock from '../../componentLibrary/Models/RegionBlock'
import LocLink from '../../componentLibrary/Elements/LocLink'
import Button from '../../componentLibrary/Fragments/Button'
import './styles.scss'


const CookieNotice = (props) => {
    const { onAccept } = props
    return (
        <div className="CookieNotice">
            <div className="CookieNotice__body">
                <div className="CookieNotice__terms">
                    <p>
                        By using our website, you accept the use of cookies to make your visit more pleasant, to offer you
                        advertisements and content tailored to your interests, to allow you to share content on social
                        networks,
                        and to create visitor statistics for website optimization.
                    </p>
                    <RegionBlock artcraft><LocLink to='/monumental/privacy-policy'>More Information</LocLink></RegionBlock>
                    <RegionBlock eu><LocLink to='/crowfall-eu/privacy-policy'>More Information</LocLink></RegionBlock>
                </div>
                <div className="CookieNotice__button">
                    <Button label="Accept" onclick={() => onAccept()}/>
                </div>
            </div>
        </div>
    )
}

export default CookieNotice
