import axios from 'axios'
import _ from 'lodash'
import store from '../redux/store'
import { removeSession } from '../redux/actions'
import { clearGuildDetails } from '../redux/actions/guild-management'
import { clearUser } from '../redux/actions/user'


function getUserData() {
  const userData = localStorage.getItem("userData")
  try {
    return JSON.parse(userData)
  } catch {
    return null
  }
}

const userDataAtStart = getUserData()
const token = _.result(userDataAtStart, 'token')

export const api = axios.create({
  baseURL: store.getState().config.base_api_host,
})

export const affiliateApi = axios.create({
  baseURL: store.getState().config.affiliate_api_host,
})

export const guildApi = axios.create({
  baseURL: store.getState().config.guilds_api_host,
})

export const publicGuildApi = axios.create({
  baseURL: store.getState().config.guilds_api_host,
})

export const identityApi = axios.create({
  baseURL: store.getState().config.identity_api_host,
})

// Define for Non-Prod environments. Prod will define its own default_org_id correctly.
const default_org_id = store.getState().config.default_org_id;
if (default_org_id) {
  api.defaults.headers.common['org-id'] = default_org_id;
}

api.interceptors.response.use((response) => {
  return response
}, (error) => {
  const message = _.result(error, 'response.data.message')
  if (error.response && error.response.status === 401 && message === 'The provided user session was invalid or expired') {
    removeAuth()
    store.dispatch(removeSession())
    store.dispatch(clearGuildDetails())
    store.dispatch(clearUser())
    localStorage.removeItem('userData')
  }
  return Promise.reject(error)
})

export function setAuth(token) {
  api.defaults.headers.common['Authorization'] = `swsb-session ${token}`
  affiliateApi.defaults.headers.common['Authorization'] = `basic ${btoa(`artcraft:${token}`)}`
  identityApi.defaults.headers.common['Authorization'] = `basic ${btoa(`artcraft:${token}`)}`
  guildApi.defaults.headers.common['Authorization'] = `basic ${btoa(`artcraft:${token}`)}`
  guildApi.defaults.headers.common['org-id'] = store.getState().config.default_org_id
}

export function removeAuth() {
  api.defaults.headers.common['Authorization'] = ``
  affiliateApi.defaults.headers.common['Authorization'] = ``
  identityApi.defaults.headers.common['Authorization'] = ``
  guildApi.defaults.headers.common['Authorization'] = ``
}

if (!_.isEmpty(token)) {
  setAuth(token)
}
