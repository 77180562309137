import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import queryString from 'query-string'
import strings from '../../strings'
import Button from '../../componentLibrary/Fragments/Button'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        cohorts: state.cohorts,
    }
}

class AccountDownloadableRewardsPage extends React.Component {

    componentDidMount() {
        const { location } = this.props
        const query = queryString.parse(location.search)
        if (query.invalid) {
            this.generateInvalidModal()
            this.props.history.replace("/account/downloadable-rewards")
        }
    }

    generateInvalidModal() {
        swal({
            icon: "error",
            title: strings.getString("UH_OH", "Uh Oh!"),
            content: (<div dangerouslySetInnerHTML={{ __html: strings.getString("ERROR_DOWNLOADABLE_REWARD_INVALID", "There was an issue when attempting to download your reward. <br/>Please refresh the page and try again, or contact <a href='mailto:support@crowfall.com'>support@crowfall.com</a> if the issue persists.") }}/>)
        })
    }

    render() {

        const { cohorts, session } = this.props
        const { artbook_download, soundtrack_download } = cohorts

        return (
            <div className="AccountDownloadableRewardsPage">
                <h1>{strings.getString("ACCOUNT_DOWNLOADABLE_REWARDS", "Downloadable Rewards")}</h1>
                <br/>

                {artbook_download && <div className="AccountDownloadableRewardsPage__section">
                    <h3>{strings.getString("ACCOUNT_DOWNLOAD_ARTBOOK", "Download Digital ArtBook")}</h3>
                    <a href={`https://artcraft.epicdata.io/user/asset-download-limited/ArtofCrowfall.zip?token=${session.token}&asset_type=artbook&error_url=https://crowfall.com/account/downloadable-rewards?invalid=true`}>
                        <Button green label={strings.getString("DOWNLOAD_ARTBOOK")}/>
                    </a>
                </div>}

                {soundtrack_download && <div className="AccountDownloadableRewardsPage__section">
                    <h3>{strings.getString("ACCOUNT_DOWNLOAD_SOUNDTRACK", "Download Digital Soundtrack")}</h3>
                    <a href={`https://artcraft.epicdata.io/user/asset-download-limited/Soundtrack_MP3.zip?token=${session.token}&asset_type=soundtrack&error_url=https://crowfall.com/account/downloadable-rewards?invalid=true`}>
                        <Button green label={strings.getString("DOWNLOAD_SOUNDTRACK")}/>
                    </a>
                </div>}

                {/* {video_download && <div className="AccountDownloadableRewardsPage__section">
                    <h3>{strings.getString("ACCOUNT_DOWNLOAD_VIDEO", "Download Behind the Scenes Video")}</h3>
                    <a href={`https://artcraft.epicdata.io/user/asset-download-limited/ArtOfCrowfall.zip?token=${session.token}&asset_type=video&error_url=https://crowfall.com/account/downloadable-rewards?invalid=true`}>
                        <Button green label={strings.getString("DOWNLOAD_VIDEO")}/>
                    </a>
                </div>} */}
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(AccountDownloadableRewardsPage))