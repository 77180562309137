import React from 'react'
import { connect } from 'react-redux'
import strings from '../../../strings'

import Page from '../../../componentLibrary/Models/Page'
import Pagination from '../../../componentLibrary/Elements/Pagination'
import PromoBanner from '../../../componentLibrary/Elements/PromoBanner'
import PromoListItem from '../../../componentLibrary/Elements/PromoListItem'
import TitleBar from '../../../componentLibrary/Fragments/TitleBar'
import {
    fetchPromotions,
    paginatePromotions,
    clearPromotions,
} from '../../../redux/actions/promotions'

import './styles.scss'


const mapStateToProps = state => {
    return {
        featured: state.promotions.featured,
        archived: state.promotions.archived,
        limit: state.promotions.paginationCriteria.limit,
        page: state.promotions.paginationCriteria.page,
        paginated: state.promotions.paginated
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchPromotions: () => dispatch(fetchPromotions()),
        paginatePromotions: (page) => dispatch(paginatePromotions(page)),
        clearPromotions: () => dispatch(clearPromotions()),
    }
}

class PromotionsPage extends React.Component {

    async componentDidMount() {
        await this.props.fetchPromotions()
    }

    componentDidUpdate(prevProps) {
        const { archived: prevArchived } = prevProps
        const { archived: currArchived } = this.props
        if (prevArchived && currArchived && prevArchived.length !== currArchived.length) {
            this.props.paginatePromotions(1)
        }
    }

    componentWillUnmount() {
        this.props.clearPromotions()
    }

    render() {

        const { featured, paginated, archived, limit, page, paginatePromotions } = this.props

        return (
            <Page>
                <div className="PromotionsPage">
                    {featured && featured.length > 0 &&
                        <div className="PromotionsPage__featuredSection">
                            <TitleBar title={strings.getString("CURRENT_PROMOTIONS", "Current Promotions")}/>
                            {featured.map(promo => {
                                const { contentful_id: id } = promo
                                return (
                                    <PromoBanner promo={promo} key={id}/>
                                )
                            })}
                        </div>
                    }
                    {paginated && paginated.length > 0 &&
                        <div className="PromotionsPage__archivedSection">
                            <TitleBar title={strings.getString("ARCHIVED_PROMOTIONS", "Archived Promotions")}/>
                            {paginated.map(promo => {
                                const { contentful_id: id, promoTitle, bannerAssetUrl, uri } = promo
                                const link = `/promotions/${uri}`
                                return (
                                    <PromoListItem key={id} title={promoTitle} assetUrl={bannerAssetUrl} link={link}/>
                                )
                            })}
                            {archived.length > 10 &&
                                <div className="PromotionsPage__pagination"><Pagination paginate={paginatePromotions} list={archived} limit={limit} page={page}/></div>
                            }  
                        </div>
                    }
                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsPage)