import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import _ from 'lodash'

import CreateGuildPage from './CreateGuildPage'
import GuildNonMembersPage from './GuildNonMembersPage'
import GuildManagementInterface from './GuildManagementInterface'
import GuildCrestPage from './GuildCrestPage'

import {fetchGuildDetails, getLatestGuildMembership} from '../redux/actions/guild-management'

import './styles.scss'


const mapStateToProps = state => {
    return {
        cohorts: state.cohorts,
        guild: state.guildManagement.guild,
        guildRank: state.guildManagement.guildRank,
        hasGuild: state.guildManagement.hasGuild,
        guildFetched: state.guildManagement.guildFetched,
        session: state.session
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchGuildDetails: () => dispatch(fetchGuildDetails()),
        getLatestGuildMembership: () => dispatch(getLatestGuildMembership())
    }
}

class GuildPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            routes: null
        }
        this.constructRoutes = this.constructRoutes.bind(this)
        this.checkChangeInProps = this.checkChangeInProps.bind(this)
    }

    async componentDidMount() {
        const {session} = this.props
        if (session) {
            await this.props.getLatestGuildMembership()
        }
        this.constructRoutes()
    }

    async componentDidUpdate(prevProps) {
        if (this.checkChangeInProps(prevProps, this.props) === true) {
            const { hasGuild, guild, fetchGuildDetails, session } = this.props
            if (session) {
                if(hasGuild && _.isEmpty(guild)) await fetchGuildDetails()
            }
            this.constructRoutes()
        }
    }

    checkChangeInProps(previous, current) {
        return !_.isEqual(previous, current)
    }

    constructRoutes() {
        const { guild, guildRank, hasGuild, session, guildFetched } = this.props
        if (session && !guildFetched) return
        const { crest } = guild
        
        let routes = null
        if (session) {
            if (hasGuild) {
                if (!crest && guildRank === 1) {
                    routes = (
                        <Switch>
                            <Route path="/:lang/guild" component={GuildCrestPage} />
                        </Switch>
                    )
                } else {
                    routes = (
                        <Switch>
                            {guildRank === 1 && <Route exact path='/:lang/guild/crest' component={GuildCrestPage}/>}
                            <Route path='/:lang/guild' component={GuildManagementInterface}/>
                        </Switch>
                    )
                }
                
            } else {
                routes = (
                    <Switch>
                        <Route exact path='/:lang/guild/create' component={CreateGuildPage}/>
                        <Route path='/:lang/guild' component={GuildNonMembersPage}/>
                    </Switch>
                )
            }
        } else {
            routes = (
                <Switch>
                    <Route path='/:lang/guild' component={GuildNonMembersPage}/>
                </Switch>
            )
        }
        this.setState({ routes })
    }

    render() {

        const { routes } = this.state
        return (
            <React.Fragment>
                {routes}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildPage)