import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import EditMotdModal from './EditMotdModal'
import { clearModalContent, setModalContent } from '../../../redux/actions'
import { updateGuildDetails } from '../../../redux/actions/guild-management'
import './styles.scss'


const mapStateToProps = state => {
    return {
        guild: state.guildManagement.guild,
        guildId: state.guildManagement.guildId,
        guildRank: state.guildManagement.guildRank,
        configuration: state.guildManagement.guildConfiguration, 
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearModalContent: () => dispatch(clearModalContent()),
        setModalContent: (content) => dispatch(setModalContent(content)),
        updateGuildDetails: (id, updates) => dispatch(updateGuildDetails(id, updates)),
    }
}

class MotdBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            canModifyMotd: false,
            newMotd: null,
        }
        this.handleModifyMotd = this.handleModifyMotd.bind(this)
        this.updateMotd = this.updateMotd.bind(this)
        this.submitMotd = this.submitMotd.bind(this)
    }

    componentDidMount() {
        this.determineMemberEligibility()
    }

    componentDidUpdate(prevProps) {
        const { guildRank: prevRank } = prevProps
        const { guildRank: currRank } = this.props
        if (prevRank !== currRank) (this.determineMemberEligibility())
    }

    determineMemberEligibility() {
        const { guildRank, configuration } = this.props
        const { permission_display } = configuration
        const rank = _.find(permission_display, { 'value':  'message_of_the_day'}) || {}
        const rankRequired = rank.required_rank
        if ( rankRequired && guildRank <= rankRequired) this.setState({ canModifyMotd: true })
    }

    handleModifyMotd() {
        if (this.state.canModifyMotd) {
            const { guild, setModalContent, clearModalContent } = this.props
            setModalContent(<EditMotdModal guild={guild} clearModalContent={clearModalContent} updateMotd={this.updateMotd} submitMotd={this.submitMotd}/>)
        }
    }

    updateMotd(value, name) {
        this.setState({ [name]: value })
    }

    async submitMotd() {
        const { newMotd } = this.state
        const { guildId, updateGuildDetails, clearModalContent } = this.props
        try {
            await updateGuildDetails(guildId, { motd: newMotd })
            swal({
                icon: 'success',
                content: (
                    <div style={{ paddingTop: "20px", fontSize: "18px" }}>{strings.getString("GUILD_MOTD_EDIT_SUCCESS", "Successfully updated the Message of the Day")}</div>
                )
            })
            clearModalContent()
        } catch (err) {
            swal ({
                icon: 'error',
                title: strings.getString("UH_OH", "Uh Oh!"),
                content: (
                    <div>{_.result(err, "response.data.message")}</div>
                )
            })
        }
    }


    render() {

        const { guild } = this.props
        const emptyMotd = _.isEmpty(guild.motd)
        const { canModifyMotd } = this.state
        let className = "MotdBox"
        if (canModifyMotd) className += " MotdBox--canEdit"

        return (
            <div className={className} >
                <div className="MotdBox__header" onClick={() => this.handleModifyMotd()}>
                    {strings.getString("MESSAGE_OF_THE_DAY", "Message of the Day")}
                    {canModifyMotd && <div className="MotdBox__edit"><FontAwesomeIcon icon="edit"/></div>}
                </div>
                
                <div className="MotdBox__content">
                    {!emptyMotd && guild.motd}
                    {emptyMotd && <span className="MotdBox__emptyContentString">{strings.getString("GUILD_MOTD_NOT_PRESENT", "There is no current Message of the Day.")}</span>}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MotdBox)