import React from 'react'
import { api } from '../utilities/api'
import { Dimmer, Form, Loader, Pagination, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsRewardsRedeemed extends React.Component {

  state = {
    loading: false,
    rewards: [],
    filteredRewards: [],
    filter: '',
    page: 1,
    totalPages: 1,
    pageSize: 15
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    const rewards = await api.get(`/user/redeem-ledger`)
    this.setState({ rewards: _.reverse(_.sortBy(rewards.data, 'date_modified')) })
    this.setState({
      filteredRewards: this.state.rewards,
      totalPages: Math.floor(this.state.rewards.length / this.state.pageSize) + 1,
      page: 1
    })
  }

  handleFilter(e, { name, value }) {
    this.setState({ [name]: value })
    const search = _.lowerCase(value)
    this.setState({
      filteredRewards: this.state.rewards.filter(r => {
        return _.lowerCase(r.name).includes(search) || _.lowerCase(r.description).includes(search)
      }),
      totalPages: Math.floor(this.state.filteredRewards.length / this.state.pageSize) + 1,
      page: 1,
    })
  }

  handlePageChange(e, { activePage }) {
    this.setState({ page: activePage })
  }

  render() {

    const { loading, filteredRewards, filter, totalPages, page, pageSize } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('ACCOUNT_REWARDS_FOR_STORE_CREDIT', 'Rewards Redeemed for Store Credit')}</h1>

        <Form>
          <Form.Input autoComplete="off" type="text"
                      placeholder={strings.getString('SEARCH_REWARDS', 'Search Rewards')}
                      name='filter'
                      value={filter}
                      onChange={this.handleFilter.bind(this)}/>
        </Form>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('NAME', 'Name')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('CURRENCY', 'Currency')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">{strings.getString('AMOUNT_RECEIVED', 'Amount Received')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredRewards.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize).map(r => (
              <Table.Row key={r.entitlement_id + r.date_modified}>
                <Table.Cell verticalAlign="top">
                  <span style={{ fontSize: '16px' }}>{r.entitlement_name}</span>
                  <br/>
                  <span style={{ color: 'rgb(150, 150,150)' }}>{(new Date(r.date_modified)).toLocaleString()}</span>
                </Table.Cell>
                <Table.Cell>{_.result(r.currency, 'description')}</Table.Cell>
                <Table.Cell textAlign="right" singleLine>
                  {_.result(r.display_strings, 'redeem_amount')}
                </Table.Cell>
              </Table.Row>
            ))}

            {filteredRewards.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={3}>No rewards redeemed for Store Credit</Table.Cell>
              </Table.Row>
            ) : null}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination defaultActivePage={page} totalPages={totalPages} onPageChange={this.handlePageChange.bind(this)}/>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsRewardsRedeemed)
