/*

Label: Component

Displays a label

PROPERTY      DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

label                  required     String    string representing the label
date          false    optional     Boolean   when true, the label will be displayed as a localized date
monthDayYear  false    optional     Boolean   converts label to localized date with the MMM Do YYYY format 

*/

import React from 'react'
import './styles.scss'
import moment from 'moment-timezone'

const Label = (props) => {
	let { label, date, monthDayYear } = props
	label = date ? formatDate(label) : label
	label = monthDayYear ? formatMonthDayYear(label) : label

	return (
		<div className="Label" >{ label }</div>
	)
}

function formatDate (label) {
	const dateFormat = 'MMM Do YYYY HH:mm a z'
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	return moment(label).tz(timezone).format(dateFormat)
}

function formatMonthDayYear (label) {
	const dateFormat = 'MMM Do YYYY'
	return moment(label).format(dateFormat)
}

export default Label