import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import strings from '../../../strings'
import VerticalMenu from '../../../componentLibrary/Models/VerticalMenu'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        cohorts: state.cohorts,
        regions: state.regions,
        accountRegions: state.user.accountRegions,
        catalog: state.crowfallStore.catalog,
        categories: state.crowfallStore.categories,
        selectedCategory: state.crowfallStore.selectedCategory,
    }
}

class ShopMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuItems: [],
        }
    }

    componentDidMount() {
        this.modelMenuItems()
        this.modelMenuLabel()
    }

    componentDidUpdate(prevProps) {
        const { location: prevLocation, selectedCategory: prevSelected } = prevProps
        const { location: currLocation, selectedCategory: currSelected } = this.props
        if (prevLocation.search !== currLocation.search) this.modelMenuItems()
        if (!_.isEqual(prevSelected, currSelected)) this.modelMenuLabel()
    }

    modelMenuItems() {
        const { cohorts, catalog, categories, location, session, regions, accountRegions } = this.props
        const search = queryString.parse(location.search) || {}
        const categoryOrderOverride = {
            "Starter Packs": cohorts.can_play_crowfall ? 2 : 1,
            "Crowns": cohorts.can_play_crowfall ? 1 : 2,
        }
        const categoryKeys = Object.keys(categories).sort((a, b) => { return (categoryOrderOverride[a] || 10) - (categoryOrderOverride[b] || 10) })


        let menuItems = []

        let featuredMenuItem = {
            label: strings.getString("FEATURED", "Featured"),
            link: "/store?category=featured",
            isActive: (search.category || "").toLowerCase() === "featured",
            items: [],
        }
        if (_.find(catalog, { "limitedTime": true })) featuredMenuItem.items.push({
            label: strings.getString("LIMITED_TIME_OFFER", "Limited Time Offer"),
            link: "/store?category=featured&sub=limited-time",
            isActive: (search.category || "").toLowerCase() === "featured" && (search.sub || "").toLowerCase() === "limited-time" 
        }) 
        if (_.find(catalog, { "best_value": true })) featuredMenuItem.items.push({
            label: strings.getString("BEST_VALUE", "Best Value"),
            link: "/store?category=featured&sub=best-value",
            isActive: (search.category || "").toLowerCase() === "featured" && (search.sub || "").toLowerCase() === "best-value"
        })
        if (_.find(catalog, function(item) { return item.sale_percent > 0 })) featuredMenuItem.items.push({
            label: strings.getString("ON_SALE", "On Sale"),
            link: "/store?category=featured&sub=on-sale",
            isActive: (search.category || "").toLowerCase() === "featured" && (search.sub || "").toLowerCase() === "on-sale"
        })
        menuItems.push(featuredMenuItem)

        const categoryOverride = {
            "064b6196-98fe-46c7-94d4-9485dad599de": 1,
        }

        let categoryMenuItems = categoryKeys.map(categoryKey => {
            let category = categories[categoryKey]
            if (_.isEmpty(category)) return {}
            let categoryLink = `/store?category=${category.slug}`
            const subcategoryMenuItems = category.subcategories.map(sub => {
                return {
                    label: sub.display_strings.name,
                    link: categoryLink + `&sub=${_.kebabCase(sub.category_key)}`,
                    isActive: search.category === category.slug && (search.sub || "").toLowerCase() === _.kebabCase(sub.category_key)
                }
            })
            return {
                id: category.id,
                label: category.display_strings.name,
                link: categoryLink,
                isActive: search.category === category.slug,
                items: subcategoryMenuItems,
            }
        }).sort((a, b) => {
            return (categoryOverride[a.id] || 2) - (categoryOverride[b.id] || 2)
        })
        menuItems = [ ...menuItems, ...categoryMenuItems ]

        const giftingMenuItem = {
            label: strings.getString("BUY_FOR_A_FRIEND", "Buy for a Friend"),
            link: "/store?category=gifting",
            isActive: (search.category || "").toLowerCase() === "gifting",
            items: categoryKeys.map(categoryKey => {
                const category = categories[categoryKey]
                return {
                    label: category.display_strings.name,
                    link: `/store?category=gifting&sub=${category.slug}`,
                    isActive: (search.category || "").toLowerCase() === "gifting" && (search.sub || "").toLowerCase() === _.kebabCase(category.key)
                }
            })
        }

        let userCanGift
        if (session) {
            userCanGift = _.isEmpty(_.find(accountRegions, { region_name: "Brazil" }))
        } else {
            userCanGift = _.isEmpty(_.find(regions, { region_name: "Brazil" }))
        }

        // if (userCanGift) menuItems.push(giftingMenuItem)

        this.setState({ menuItems })
    }

    modelMenuLabel() {
        const { selectedCategory } = this.props
        let menuBarLabel = selectedCategory.categoryName
        if (selectedCategory.subcategoryName) menuBarLabel += ` - ${selectedCategory.subcategoryName}`
        this.setState({ menuBarLabel })
    }

    render() {

        const { menuBarLabel, menuItems } = this.state

        return (
            <VerticalMenu sections={menuItems} barLabel={menuBarLabel}/>
        )
    }
}

export default withRouter(connect(mapStateToProps)(ShopMenu))