import React from 'react'
import _ from 'lodash'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import Page from '../../../componentLibrary/Models/Page'
import ContentCard from '../../../componentLibrary/Elements/ContentCard'
import Grid from '../../../componentLibrary/Fragments/Grid'
import ECSFooter from '../ECSFooter'
import BackToECSButton from '../BackToECSButton'
import './styles.scss'


class ECSSponsorsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: null,
            bannerAsset: null,
            tagline: null,
            body: null,
            footer: null,
            sponsors: [],
        }
    }

    componentDidMount() {
        this.getContent()
    }

    async getContent() {
        const pageContent = await contentful.getEntry(assets.contentfulIds.ECS_SPONSORS_PAGE)
        const sponsors = _.orderBy(await contentful.getEntries("ecsSponsor"), "displayWeight")
        this.setState({
            title: pageContent.title,
            bannerAsset: _.result(pageContent, "bannerAsset.assetUrl"),
            tagline: pageContent.tagline,
            body: pageContent.body,
            footer: pageContent.footer,
            sponsors,
        })
    }

    modelSponsors(sponsors) {
        return sponsors.map(sponsor => {
            const { contentful_id: id, title, asset, subtitle } = sponsor
            const assetUrl = _.result(asset, "assetUrl")
            return (
                <ContentCard title={title} img={assetUrl} subtitle={subtitle} key={`ECSSponsor--${id}`}/>
            )
        })
    }


    render() {

        const { title, bannerAsset, tagline, body, footer, sponsors } = this.state

        return (
            <Page header={{ banner: bannerAsset, title }} pageFooter={<ECSFooter/>}>
                <div className="ECSSponsorsPage">

                    {/* <div className="ECSSponsorsPage__title">{title}</div> */}

                    {!_.isEmpty(tagline) && <div className="ECSSponsorsPage__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>}

                    {!_.isEmpty(body) && <div className="ECSSponsorsPage__body" dangerouslySetInnerHTML={{  __html: body }}/>}

                    <Grid list={this.modelSponsors(sponsors)} irregularColumns/>

                    {!_.isEmpty(footer) && <div className="ECSSponsorsPage__footer" dangerouslySetInnerHTML={{ __html: footer }}/>}
                
                    <br/>
                    <BackToECSButton/>
                </div>
            </Page>
        )
    }
}

export default ECSSponsorsPage