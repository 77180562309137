import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import strings from '../../strings'
import contentful from '../../utilities/contentful'
import _ from 'lodash'
import LocLink from '../../componentLibrary/Elements/LocLink'
import { Container, Header } from 'semantic-ui-react'

class PantheonPage extends React.Component {

  state = {
    entries: {
      Father: [],
      Sun: [],
      Earth: [],
      Moon: [],
    },
  }

  async componentDidMount() {
    await this.getContent()
  }

  async getContent() {
    const entries = _.groupBy(_.sortBy(await contentful.getEntries('raceClass', 'fields.type[match]=Pantheon'), 'displayWeight').map(e => {
      e.landingPageAssetUrl = _.result(e, 'landingPageAsset.assetUrl')
      e.title = e.name
      e.subType = e.subType || 'Father'
      return e
    }), 'subType') || {}

    this.setState({ entries: entries })
  }

  render() {

    const { entries } = this.state

    const itemStyle = (e) => ({
        backgroundImage: `url(${e.landingPageAssetUrl})`,
        backgroundPosition: 'left',
        width: '100%',
        height: '130px',
        marginBottom: '7px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    })

    const textStyle = {
      textShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 1px',
      color: 'white',
      paddingLeft: '140px',
      fontSize: 'calc(2em + 1vw)',
      wordBreak: 'break-all',
    }

    return (
      <Page titleName="PANTHEON">
        <Container>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            {entries.Father && entries.Father.map((e, i) => (
              <LocLink to={`/pantheon/${e.name}`} as={'div'} key={i} style={itemStyle(e)}>
                            <span style={textStyle}>{e.title}</span>
              </LocLink>
            ))}

            <Header as='h1'>{strings.getString("SUN", "Sun")}</Header>

            {entries.Sun && entries.Sun.map((e, i) => (
              <LocLink to={`/pantheon/${e.name}`} as={'div'} key={i} style={itemStyle(e)}>
                            <span style={textStyle}>{e.title}</span>
              </LocLink>
            ))}

            <Header as='h1'>{strings.getString("EARTH", "Earth")}</Header>

            {entries.Earth && entries.Earth.map((e, i) => (
              <LocLink to={`/pantheon/${e.name}`} as={'div'} key={i} style={itemStyle(e)}>
                            <span style={textStyle}>{e.title}</span>
              </LocLink>
            ))}

            <Header as='h1'>{strings.getString("MOON", "Moon")}</Header>

            {entries.Moon && entries.Moon.map((e, i) => (
              <LocLink to={`/pantheon/${e.name}`} as={'div'} key={i} style={itemStyle(e)}>
                            <span style={textStyle}>{e.title}</span>
              </LocLink>
            ))}


          </div>
        </Container>
      </Page>
    )
  }
}

export default PantheonPage

