import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const EuFairUsePage = () => (
  <Page contentId={assets.contentfulIds.EU_FAIR_USE_POLICY}>
  </Page>
)

export default EuFairUsePage

