import React from 'react'
import { connect } from 'react-redux'
import Masthead from './Masthead'
import Button from '../../componentLibrary/Fragments/Button'
import LocLink from '../../componentLibrary/Elements/LocLink'
import Carousel from '../../componentLibrary/Models/Carousel'
import PromoNewsSection from './PromoNewsSection'
import contentful from '../../utilities/contentful'
import _ from 'lodash'
import strings from '../../strings'
import './styles.scss'
import swal from "@sweetalert/with-react";
import {api} from "../../utilities/api";

const mapStateToProps = state => {
  const { session, cohorts, user } = state
  return {
    isLoggedIn: !!session,
    user,
    canPlayCrowfall: !!cohorts.can_play_crowfall,
    isPaidAccount: !!cohorts.paid_account,
    isTrialExpired: !!cohorts.game_trial_expired
  }
}

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      promos: [],
      articles: [],
      gallery: [],
      glory: [],
      power: [],
      wealth: []
    }

    this.getCarouselContent = this.getCarouselContent.bind(this)
    this.splitItems = this.splitItems.bind(this)
  }

  splitItems(items) {
    const glory = items.filter(item => item.type === 'GLORY')
    const power = items.filter(item => item.type === 'POWER')
    const wealth = items.filter(item => item.type === 'WEALTH')

    this.setState({
      glory,
      power,
      wealth
    })
  }

  async getPromos() {
    let featuredPromos = await contentful.getEntries('featuredPromos')
    if (featuredPromos[0]) {
      let promos = featuredPromos[0].featuredContent ? _.orderBy(featuredPromos[0].featuredContent.filter(promo => promo !== null), 'displayWeight', ['asc']) : []
      if (promos.length > 0) {
        promos = promos.map(promo => {
          promo.bannerAssetUrl = _.result(promo, 'bannerAsset.assetUrl')
          promo.promoAssetUrl = _.result(promo, 'promoAsset.assetUrl')
          return promo
        }).slice(0, 4)
      }
      this.setState({ promos })
    }
  }

  async getMedia() {
    const media = await Promise.all([
      contentful.getEntries('article', 'fields.featured=true', undefined, 'order=-fields.date'),
      contentful.getEntries('gallery', 'fields.group[match]=videos&fields.featured=true')
    ])

    const articles = _.orderBy(media[0], 'date', ['desc']).filter(entry => !!entry.featured).map(entry => {
      entry.featured = !!entry.featured
      entry.link = `/news/articles/${entry.uri}`
      entry.img = _.result(entry, 'thumb.assetUrl')
      return entry
    })

    const gallery = media[1].filter(entry => !!entry.featured).map(entry => {
      entry.featured = !!entry.featured
      entry.link = `/media/${entry.group}/${entry.slug}`
      entry.img = _.result(entry, 'thumbnail.assetUrl')
      return entry
    })

    this.setState({
      articles,
      gallery
    })
  }

  async getCarouselContent() {
    const entries = _.sortBy(await contentful.getEntries('carousel', null), 'displayWeight').map(entry => {
      const item = {
        img: _.result(entry, 'image.assetUrl'),
        icon: _.result(entry, 'icon.assetUrl'),
        carouselTitle: entry.carouselTitle,
        title: entry.title,
        date: entry.date,
        description: entry.description,
        type: entry.type
      }

      return item
    })


    this.splitItems(entries)
  }

  async handleTrialPopup() {
    try {
      const val = await swal({
        icon: "warning",
        title: strings.getString('TRIAL_ACTIVATE_TITLE', 'Activate Free Trial'),
        content: (
            <div>
              {strings.getString('TRIAL_ACTIVATE', 'Activate your free 10 day trial now?')},
            </div>
        ),
        buttons: {
          continue: 'Yes',
          cancel: 'No'
        }
      })
      if (val === "continue") {
        await api.post('/ks/artcraft')
        swal({
          icon:'success',
          title: strings.getString('TRIAL_HAS_BEEN_ACTIVATED', 'Your 10 day trial has been activated!')
        }).then(okay => {
          if (okay) {
            window.location.href = "/account/download-client"
          }
        })
      }
    } catch(err) {
      swal({
            icon: 'error',
            title: strings.getString('TRIAL_ACTIVATION_ERROR_OCCURRED', 'Error Activating Trial')
          }
      )
    }
  }

  componentDidMount() {
    this.getPromos()
    this.getMedia()
    this.getCarouselContent()
    this.recordToLiquidAdvertisingOnPageLoad()
  }

  recordToLiquidAdvertisingOnPageLoad() {
    window.gtag("event", "conversion", {
      "allow_custom_scripts": true,
      "send_to": "DC-11063492/crowfall/d_cro0+standard",
    })
  }

  render() {
    const { isLoggedIn, canPlayCrowfall, isPaidAccount, user, isTrialExpired } = this.props
    const { promos, articles, gallery, glory, power, wealth } = this.state
    const media = [...articles, ...gallery].slice(0, 3)
    const playerData = _.result(user, 'userProfile.player_data', null);
    const isTrial = _.result(playerData, 'type') === 'trial';
    const paidUser = _.result(playerData, 'type') === 'paid';
    const shouldCode = _.result(playerData, 'type') === 'unknown';
    const loggedInCta = {
      title: strings.getString('BE_PART_OF_NEXT_GREAT_MMO', 'Be part of shaping the next great MMO.'),
      subtitle: strings.getString('JOIN_THE_BATTLE', 'Join the Battle')
    }
    const loggedOutCta = {
      title: strings.getString('JOIN_COMMUNITY_FOR_GAME_INFO', 'Join the community, get the latest game information, sneak peeks and more!'),
    }
    let playNowText
    let playNowLink
    let tryPlayButton
    if (canPlayCrowfall) {
      playNowLink = "/account/download-client"
      playNowText = strings.getString("PLAY_NOW", "Play Now")
      tryPlayButton = <LocLink to={playNowLink}>
        <Button fill green>
          <div className="MainNav__buttonLabel">{playNowText}</div>
        </Button>
      </LocLink>
    } else if (!canPlayCrowfall && !isTrial && !isTrialExpired) {
      // playNowLink = "/account/redeem-codes?code=artcraft"
      // playNowText = strings.getString("TRY_FREE", "Try Free")
      // tryPlayButton = <div className="MainNav__buttonLabel" onClick={()=>this.handleTrialPopup()}>
      //                 <Button large fill green label={playNowText} />
      //               </div>
    } else {
      playNowLink = "/store?category=064b6196-98fe-46c7-94d4-9485dad599de&record-to-liquid=true"
      playNowText = strings.getString("PLAY_NOW", "Play Now")
      // tryPlayButton = <LocLink to={playNowLink}>
      //             <Button fill green>
      //               <div className="MainNav__buttonLabel">{playNowText}</div>
      //             </Button>
      //           </LocLink>
    }
    
    return (
      <div className="HomePage">
        <div className="HomePage__masthead">
          <Masthead playerData={playerData} isTrialExpired={isTrialExpired} isLoggedIn={isLoggedIn} canPlayCrowfall={canPlayCrowfall} isPaidAccount={isPaidAccount}/>
        </div>

        <PromoNewsSection media={media} promos={promos}/>

        {/*<div className="HomePage__body">*/}
        {/*  <div className="HomePage__carousel">*/}
        {/*    <Carousel header={glory[0] ? glory[0].carouselTitle : ''} items={glory}/>*/}
        {/*  </div>*/}
        {/*  <div className="HomePage__carousel">*/}
        {/*    <Carousel header={wealth[0] ? wealth[0].carouselTitle : ''} items={wealth} right/>*/}
        {/*  </div>*/}
        {/*  <div className="HomePage__carousel">*/}
        {/*    <Carousel header={power[0] ? power[0].carouselTitle : ''} items={power}/>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="HomePage__footer">
          <div className="HomePage__footer-logo"/>
          {/*<h3 className="HomePage__footer-title">{isLoggedIn ? loggedInCta.title : loggedOutCta.title}</h3>*/}
          {/*<h3 className="HomePage__footer-subtitle">{isLoggedIn ? loggedInCta.subtitle : loggedOutCta.subtitle}</h3>*/}
          <div className="HomePage__footer-buttons">
            <div className="Masthead__buttons--single">
              {/*{isLoggedIn && tryPlayButton}*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(HomePage)
