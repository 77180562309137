import React from 'react';
import {api} from '../utilities/api';
import {Button, Dimmer, Form, Loader, Table} from 'semantic-ui-react';
import {connect} from 'react-redux';
import _ from 'lodash';
import swal from '@sweetalert/with-react';
import FixedTable from '../componentLibrary/Elements/FixedTable';
import strings from '../strings';
import {NavLink} from 'react-router-dom';

const mapStateToProps = () => {
    return {};
};

class AccountDetailsTeams extends React.Component {

    state = {
        loading: false,
        team: {},
        invites: [],
        inviteName: '',
        teamName: '',
        teamDisplay: '',
        targetUsername: ''
    };

    async componentDidMount() {
        await this.getContent();
    }

    async getContent() {
        this.setState({loading: true});
            const team = (await api.get(`/user/team`)).data;

            if (!_.isEmpty(team.members)) {

            team.roster = [...(team.members || []).map(m => {
                return {
                    id: m.team_member_id,
                    username: m.username,
                    rank: m.rank,
                    pos: m.rank === 'Leader' ? 1 : 2,
                    status: 'Active',
                    showCancel: false,
                    showKick: m.rank !== 'Leader' && team.is_leader
                };
            }), ...(team.invites || []).map(i => {
                return {
                    id: i.team_invite_id,
                    username: i.target_username,
                    pos: 3,
                    rank: 'Invitee',
                    status: _.startCase(i.status),
                    showCancel: team.is_leader,
                    showKick: false
                };
            })];

            this.setState({
                team,
                teamDisplay: team.team_display
            });
        } else {
            this.setState({
                team: {},
                inviteName: '',
                teamName: '',
                teamDisplay: '',
                targetUsername: ''
            });

            const invites = (await api.get(`/user/team-invite`)).data;

            this.setState({
                invites
            });
        }
        this.setState({loading: false});
    }

    handleChange(e, {name, value}) {
        this.setState({
            [name]: value
        });
    }

    createTeam = async () => {
        this.setState({loading: true});

        if (this.state.teamDisplay.length >= 6 && this.state.teamName.length >= 6 && this.state.teamDisplay.length <= 30 && this.state.teamName.length <= 30) {
            try {
                await api.post(`/user/team`, {team_display: this.state.teamDisplay, team_name: this.state.teamName});
                swal(`Successfully Created Team`, {
                    icon: 'success',
                });
                this.getContent();
            } catch (err) {
                swal(`${_.result(err, 'response.data.message', 'Unable to Create a Team')}`, {
                    icon: 'error',
                });
                this.setState({loading: false});
            }
        } else {
            swal(`Team Tag and Display Name must be between 6 and 30 characters.`, {
                icon: 'error',
            });
            this.setState({loading: false});
        }
    };

    inviteMember = async () => {
        this.setState({loading: true});
        try {
            await api.post(`/user/team-invite`, {target_user: this.state.inviteName});
            swal(`Successfully Invited ${this.state.inviteName}`, {
                icon: 'success',
            });
            this.getContent();
        } catch (err) {
            swal(`${_.result(err, 'response.data.message', 'Unable to Invite Member')}`, {
                icon: 'error',
            });
            this.setState({loading: false});
        }
    };

    handleAccept = (invite) => {
        return async () => {
            this.setState({loading: true});
            console.log(invite);
            try {
                await api.post(`/user/team-invite/${invite.team_invite_id}/accept`);
                swal(`Successfully Accepted Invite to ${invite.team_display}`, {
                    icon: 'success',
                });
                this.getContent();
            } catch (err) {
                swal(`${_.result(err, 'response.data.message', 'Unable to Accept Invite')}`, {
                    icon: 'error',
                });
                this.setState({loading: false});
            }
        };
    };

    handleDecline = (invite) => {
        return async () => {
            this.setState({loading: true});
            console.log(invite);
            try {
                await api.post(`/user/team-invite/${invite.team_invite_id}/decline`);
                swal(`Successfully Declined Invite to ${invite.team_display}`, {
                    icon: 'success',
                });
                this.getContent();
            } catch (err) {
                swal(`${_.result(err, 'response.data.message', 'Unable to Decline Invite')}`, {
                    icon: 'error',
                });
                this.setState({loading: false});
            }
        };
    };


    handleCancel = (invite) => {
        console.log('out');
        return async () => {
            console.log('in', invite);
            this.setState({loading: true});
            try {
                await api.post(`/user/team-invite/${invite.id}/cancel`);
                swal(`You have canceled the invite for ${invite.username}.`, {
                    icon: 'success',
                });
                this.getContent();
            } catch (err) {
                swal(`${_.result(err, 'response.data.message', 'Unable to Cancel Invite')}`, {
                    icon: 'error',
                });
                this.setState({loading: false});
            }
        };
    };

    handleKick = (invite) => {
        return async () => {
            this.setState({loading: true});
            try {
                await api.delete(`/user/team-member/${invite.id}`);
                swal(`You have removed ${invite.username} from your team`, {
                    icon: 'success',
                });
                this.getContent();
            } catch (err) {
                swal(`${_.result(err, 'response.data.message', 'Unable to Kick Member')}`, {
                    icon: 'error',
                });
                this.setState({loading: false});
            }
        };
    };

    handleLeave = async () => {
        this.setState({loading: true});
        try {
            await api.post(`/user/team/leave`);
            swal(`You have left your team`, {
                icon: 'success',
            });
            this.getContent();
        } catch (err) {
            swal(`${_.result(err, 'response.data.message', 'Unable to Leave Team')}`, {
                icon: 'error',
            });
            this.setState({loading: false});
        }
    };

    updateTeam = async () => {
        this.setState({loading: true});
        try {
            await api.put(`/user/team`, {team_display: this.state.teamDisplay});
            swal(`Successfully Updated Team`, {
                icon: 'success',
            });
            this.getContent();
        } catch (err) {
            swal(`${_.result(err, 'response.data.message', 'Unable to Update Team')}`, {
                icon: 'error',
            });
            this.setState({loading: false});
        }
    };

    handleCancelApplication = async () => {

        this.setState({loading: true});
        try {
            await api.delete(`/user/team-application`);
            swal(`You have canceled your application.`, {
                icon: 'success',
            });
            this.getContent();
        } catch (err) {
            swal(`${_.result(err, 'response.data.message', 'Unable to Cancel Application')}`, {
                icon: 'error',
            });
            this.setState({loading: false});
        }

    };

    handleTeamDisband = async () => {
        this.setState({loading: true});
        try {
            await api.delete(`/user/team`);
            swal(`You have disbanded your team`, {
                icon: 'success',
            });
            this.getContent();
        } catch (err) {
            swal(`${_.result(err, 'response.data.message', 'Unable to Disband Team')}`, {
                icon: 'error',
            });
            this.setState({loading: false});
        }
    };

    render() {

        const {loading, inviteName, team, teamName, teamDisplay, invites} = this.state;

        return (
            <div>
                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>


                {!team.members ? (
                    <React.Fragment>
                        <h4>Create a new Team</h4>
                        <Form onSubmit={this.createTeam}>
                            <Form.Field>
                                <label>Enter Unique Team Tag (This cannot be changed)</label>
                                <Form.Input autoComplete="off" type="text"
                                            minLength="6"
                                            maxLength="30"
                                            placeholder="Enter Unique Team Tag (This cannot be changed)"
                                            name='teamName' value={teamName}
                                            onChange={this.handleChange.bind(this)}></Form.Input>
                            </Form.Field>

                            <Form.Field>
                                <label>Enter Team Display Name (This can be changed)</label>
                                <Form.Input autoComplete="off" type="text"
                                            minLength="6"
                                            maxLength="30"
                                            placeholder="Enter Team Display Name (This can be changed)"
                                            name='teamDisplay' value={teamDisplay}
                                            onChange={this.handleChange.bind(this)}></Form.Input>
                            </Form.Field>

                            <Button className="Button__ACE" type='submit' primary>Create Team</Button>
                        </Form>


                        <h4>Pending Team Invites</h4>
                        <p>
                            IMPORTANT INFORMATION - PLEASE READ BEFORE ACCEPTING YOUR TEAM INVITE
                        </p>
                        <p>
                            POSTPONEMENT & PUNCTUALITY - Policy
                        </p>
                        <ul>
                            <li>Participants are expected to be in the queue for the match 10 minutes prior to the
                                official Start Time, any Participant not in the Match queue 5 minutes prior to the
                                official Start Time, is disqualified.
                            </li>

                            <li>If two or more players on a Team are not in the Queue, 5 minutes prior to the
                                official Start Time, the Team is Disqualified from Participating.
                            </li>
                        </ul>
                        <p>
                            ELIGIBILITY REQUIREMENTS - Minimum of 10 Matches Played in Hunger Dome
                        </p>
                        <ul>
                            <li>Note: Eligible Teams are required to play a minimum of 10 Hunger Dome Matches to
                                qualify for entry into the Series
                            </li>
                            <li>Display Names: The Display name of the player Accounts associated with the Team must
                                reflect the actual account which will be played in the ECS Event
                            </li>
                            <li>HungerDome Matches: ACE reserves the right to review your Team’s performance in
                                Hunger Dome matches to validate the Team’s competitive profile and suitability for
                                the ECS with other criteria on this form alongside other applicants.
                            </li>
                            <li>Suitability: Final invitations to Teams to compete is based on the above along with
                                application criteria and suitability as determined by Monumental; final competitor
                                invitations and determination of suitability is at the sole discretion of ACE.
                            </li>
                            <li>For questions about your Application, the process of applying, or the ECS Event join
                                us on the Official Crowfall Discord on the ECS-Questions channel. We will be
                                watching over the ECS-Questions channel and responding as quickly as possible.
                            </li>
                        </ul>
                        <p>
                            By accepting these Invites you agree to be bound by and have “ACCEPTED” the <NavLink to="/eternal-champions/official-rules">Official
                            Rules</NavLink>, have validated <NavLink to="/eternal-champions/eligibility">Eligibility</NavLink> requirements for the Team and have agreed to abide by
                            decisions of the competition officials and administrators.
                            Monumental reserves the right to cancel, suspend and/or modify the ECS ’21
                            series, or any part of it, as determined by ACE in its sole discretion.
                            All invited Teams must be logged in to the appropriate ECS Tournament Event server queue no
                            later than 10-minutes before the start of the ECS Event.
                        </p>


                        <FixedTable padded>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Team Tag</Table.HeaderCell>
                                    <Table.HeaderCell>Team Display Name</Table.HeaderCell>
                                    <Table.HeaderCell>Team Leader</Table.HeaderCell>
                                    <Table.HeaderCell>Action</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {invites.map(r => (
                                    <Table.Row key={r.team_invite_id}>
                                        <Table.Cell singleLine>{r.team_name}</Table.Cell>
                                        <Table.Cell singleLine>{r.team_display}</Table.Cell>
                                        <Table.Cell singleLine>{r.source_username}</Table.Cell>
                                        <Table.Cell>
                                            <Button size='mini' compact primary onClick={this.handleAccept(r)}
                                                    content={'Accept'}/>
                                            <Button size='mini' compact primary onClick={this.handleDecline(r)}
                                                    content={'Decline'}/>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>

                            {invites.length === 0 ? (
                                <Table.Body>
                                <Table.Row>
                                    <Table.Cell colSpan="4">No Pending Invites at this time.</Table.Cell>
                                </Table.Row>
                                </Table.Body>
                            ) : null}

                        </FixedTable>


                    </React.Fragment>
                ) : (
                    <React.Fragment>

                        <h4>{team.team_display} Team Details</h4>

                        <Form onSubmit={this.updateTeam.bind(this)}>

                            <Form.Field>
                                <label>Team Tag</label>
                                <input type="text" disabled={true} value={this.state.team.team_name || ''}
                                       style={{opacity: 1, color: 'rgb(196, 196, 196)'}}/>
                            </Form.Field>

                            <Form.Field>
                                <label>{strings.getString('DISPLAY_NAME', 'Display Name')}</label>
                                <Form.Input disabled={!team.is_leader} type="text" autoComplete="off"
                                            placeholder="Enter Team Display Name (This can be changed)"
                                            name='teamDisplay' value={teamDisplay}
                                            style={{opacity: 1, color: 'rgb(196, 196, 196)'}}
                                            onChange={this.handleChange.bind(this)}/>
                            </Form.Field>


                            {team.is_leader ? (<Button className="Button__ACE" type='submit' primary>Update Team</Button>) : null }
                        </Form>

                        <h4>Application Status</h4>

                        {team.application_submitted ? (
                            <React.Fragment>
                                <p>This team has already submitted their application. We will contact you if your team
                                    is accepted.</p>
                                {team.is_leader ? (<Button className="Button__ACE" onClick={this.handleCancelApplication}
                                        content={'Cancel Application'}/>) : null }
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <p>This team has not submitted their application. Please go to the <NavLink
                                    to="/eternal-champions/application">Application Page</NavLink> to submit your
                                    application.</p>
                            </React.Fragment>
                        )}

                        <h4>Roster</h4>

                        {team.is_leader ? (<Form onSubmit={this.inviteMember}>
                            <Form.Field>
                                <label>Enter the Display Name of your teammate to invite them to your team.</label>
                                <Form.Input autoComplete="off" type="text"
                                            placeholder="Enter Display Name" name='inviteName' value={inviteName}
                                            onChange={this.handleChange.bind(this)}>
                                </Form.Input>
                            </Form.Field>

                            <Button disabled={team.members.length >=5} className="Button__ACE" type='submit' primary>Invite Team Member</Button>
                        </Form>) : null }

                        <FixedTable padded>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Display Name</Table.HeaderCell>
                                    <Table.HeaderCell>Rank</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Action</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {team.roster.map(r => (
                                    <Table.Row key={r.id}>
                                        <Table.Cell singleLine>{r.username}</Table.Cell>
                                        <Table.Cell singleLine>{r.rank}</Table.Cell>
                                        <Table.Cell>{r.status}</Table.Cell>
                                        <Table.Cell>
                                            {r.showCancel ? (
                                                <Button size='mini' compact primary onClick={this.handleCancel(r)}
                                                        content={'Cancel'}/>) : null}
                                            {r.showKick ? (
                                                <Button size='mini' compact primary onClick={this.handleKick(r)}
                                                        content={'Kick'}/>) : null}
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </FixedTable>


                        {team.is_leader ? (
                            <React.Fragment>
                                <h4>Team Leader Tools</h4>
                                <p>Warning! Any action taken here is not reversible!</p>
                                <Button onClick={this.handleTeamDisband} className="Button__ACE" type='submit' primary>Disband
                                    Team</Button>
                            </React.Fragment>
                        ) : null}


                        {!team.is_leader ? (
                            <React.Fragment>
                                <h4>Team Member Tools</h4>
                                <p>Warning! Any action taken here is not reversible!</p>
                                <Button onClick={this.handleLeave} className="Button__ACE" type='submit' primary>Leave
                                    Team</Button>
                            </React.Fragment>
                        ) : null}


                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps)(AccountDetailsTeams);
