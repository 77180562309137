import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
import './styles.scss'


const InnovaPage = () => {
    
    return (
        <Page>
            <div className="InnovaPage">
                <h1>I'm sorry, the Crowfall store payment methods are not currently available to users from Russia or CIS.</h1>
                <LocLink to="/store"><Button>Return to Store</Button></LocLink>
            </div>
        </Page>
    )
}

export default InnovaPage