import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import strings from '../../../strings'
import FaqContent from '../Components/FaqContent'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import _ from 'lodash'
import './styles.scss'

const queryString = require('query-string')

class FaqPage extends React.Component {

  state= {
    categories: [],
    selected: null,
  }

  async componentDidMount() {
    await this.getContent()
  }

  async getContent() {
    let categories = _.sortBy(await contentful.getEntries('faqCategory'), 'displayWeight').map(e => {
      return e
    })
    let selected = this.setSelectedSubCategory(categories)

    this.setState({ categories, selected })
  }

  setSelectedSubCategory(categories) {
    const parsed = queryString.parsed = queryString.parse(this.props.location.search)
    let selected = null
    if ( parsed.category ) {
      categories.forEach(category => {
        let arr = category.subCategories.filter( subCategory => subCategory && subCategory.slug === parsed.category)
        if( arr.length > 0 ) { selected = arr[0] }
      })
    }
    return selected
  }

  render() {

    const { categories, selected } = this.state

    return (
      <Page addAccent header={{title: strings.getString('FAQ', 'FAQs'), banner: assets.staticImageUrls.FAQ_PAGE_HEADER}}>
        <div className='FaqPage'>
          {categories.length > 0 && <FaqContent categories={categories} selected={selected} />}
        </div>
      </Page>
    )
  }
}

export default FaqPage
