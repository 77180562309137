import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import contentful from '../../utilities/contentful'
import assets from '../../assets'
import { Container, Grid, Header } from 'semantic-ui-react'
import RegionBlock from '../../componentLibrary/Models/RegionBlock'
import strings from '../../strings'


class CorporatePage extends React.Component {
  state = {
    mntlLogo: {},
  }

  async componentDidMount() {
    const mntlLogo = await contentful.getImage(assets.staticImageIds.MNTL_LOGO)
    this.setState({ mntlLogo: mntlLogo })
  }

  render() {
    return (
      <Page contentId={assets.contentfulIds.CORPORATE_CONTACT}>
        <Container>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={4}>
                <img src={this.state.mntlLogo.assetUrl} alt={this.state.mntlLogo.title} />
              </Grid.Column>
              <Grid.Column width={12}>
                <Header as='h2'>Monumental, LLC</Header>
                <address>1600 Rio Grande St</address>
                <address>Austin, TX 78701, USA</address>
                <a href='mailto:support@monumental.io?subject=Corporate%20Contact'>support@monumental.io</a>
                {/*<RegionBlock eu>*/}
                {/*  <ul style={{listStyle: 'none', padding: 0}}>*/}
                {/*    <li>{("DATA_PROTECTION_OFFICER", "Data Protection Officer: Jesse Greenwald", {name: 'Jesse Greenwald'} )}</li>*/}
                {/*  </ul>*/}
                {/*</RegionBlock>*/}

              </Grid.Column>
            </Grid.Row>
          </Grid>
g
        </Container>
      </Page>
    )
  }
}

export default CorporatePage
