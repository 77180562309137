import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
import { updateUsersSubscription } from '../../../redux/actions/subscription'


const mapStateToProps = state => {
    return {
        user: state.user.userProfile,
        language: state.language,
        hasActiveRecurringPurchase: state.subscription.hasActiveRecurringPurchase,
        subscriptionDetails: state.subscription.subscriptionDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUsersSubscription: (type) => dispatch(updateUsersSubscription(type)),
    }
}

class RecurringPurchaseButton extends React.Component {

    recordProductClick(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "select_content",{
            "content_type": "product",
            "items": [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                }
            ]
        })
    }

    async handleUpdateSubscription(item, subscription) {
        this.recordProductClick(item)
        const currSubscriptionName = _.result(subscription, "subscription.subscription_definition.name")
        const val = await swal({
            icon: "warning",
            title: strings.getString("UPGRADE_MEMBERSHIP", "Upgrade Membership"),
            content: (
                <div style={{ textAlign: "left" }}>
                    <p>{strings.getString("UPDATE_MEMBERSHIP_FROM_TO", `Update Membership from ${currSubscriptionName} to ${item.name}`, {from: currSubscriptionName, to: item.name})}</p>
                    <p>{strings.getString("WARNING_UPDATE_TO_MEMBERSHIP_TYPE", "Please note that changes to your membership type will not come into effect until after your next membership billing date.")}</p>
                </div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                upgrade: strings.getString("UPGRADE_MEMBERSHIP", "Upgrade Membership")
            }
        })
        if (val === "upgrade") {
            try {
                await this.props.updateUsersSubscription(item.item_id)
                swal({
                    icon: "success",
                    title: strings.getString("SUCCESSFULLY_UPDATED_MEMBERSHIP", "Membership Successfully Updated.")
                })
            } catch (err) {
                swal({
                    icon: "error",
                    title: strings.getString("UH_OH", "Uh Oh!"),
                    content: (<div>{strings.getString("ERROR_GENERIC_MESSAGE", "There was an error processing your request. Please try again.")}</div>),
                })
            }
        }
    }

    render() {
        const { item, user, medium, large, hasActiveRecurringPurchase, subscriptionDetails } = this.props
        const returnPath = btoa(`${this.props.location.pathname}${this.props.location.search}`)
        const buttonString = hasActiveRecurringPurchase ? strings.getString("UPGRADE", "Upgrade") : strings.getString("BUY_NOW", "Buy Now")

        return (
            <React.Fragment>
                 {user.username ? (
                     <>
                        {hasActiveRecurringPurchase ? (
                            <>
                                {medium && <Button green onclick={() => this.handleUpdateSubscription(item, subscriptionDetails)} medium label={buttonString}/>}
                                {large && <Button green onclick={() => this.handleUpdateSubscription(item, subscriptionDetails)} fill label={buttonString}/>}
                            </>
                        ) : (
                            <LocLink to={`/checkout/${item.item_id}?subscribe=true`}>
                                {medium && <Button green onclick={() => this.recordProductClick(item)} medium label={buttonString}/>}
                                {large && <Button green onclick={() => this.recordProductClick(item)} fill label={buttonString}/>}
                            </LocLink>
                        )}
                    </>
                ) : (
                    <LocLink to={`/login?r=${returnPath}`}>
                        {medium && <Button green onclick={() => this.recordProductClick(item)} medium label={buttonString}/>}
                        {large && <Button green onclick={() => this.recordProductClick(item)} fill label={buttonString}/>}
                    </LocLink>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecurringPurchaseButton))