/*

SearchResultsDisplay: Fragment
informs users of search result counts, and provides informative descriptions on status of search results


PROPERTY       DEFAULT  REQUIREMENT  TYPE     DESCRIPTION

resultsCount            required     Integer  Results count displayed to the user when Component is not disabled
disabled       false    optional     Boolean  hides resultsCount and noResultsText.
noResultsText           optional     String   displayed when count equals zero, and intennded to inform users to modify their search criteria
disabledText            optional     String   Intended for server-side searches when the user has yet to initiate a request for data.

*/


import React from 'react'
import strings from '../../../strings'
import './styles.scss'


const SearchResultsDisplay = (props) => {

    const { noResultsText, resultsCount, disabled, disabledText } = props
    let className = 'SearchResultsDisplay'
    if (disabled) className = className + ' SearchResultsDisplay--disabled'

    return (
        <div className={className}>
            <div className='SearchResultsDisplay__resultsCount'>{strings.getString("SEARCH_RESULTS")}: {resultsCount}</div>
            {resultsCount === 0 && <div className='SearchResultsDisplay__noResultsText'>{noResultsText}</div>}
            
            {disabled && resultsCount === 0 && disabledText && <div className="SearchResultsDisplay__disabledText">{disabledText}</div>}
        </div>
    )
}

export default SearchResultsDisplay