/* eslint-disable no-undef */
import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import Button from '../../../componentLibrary/Fragments/Button'
import { generatePaymentTokenVars, addItemToUserCart } from '../../../redux/actions/crowfall-store'
import './styles.scss'


const mapStateToProps = state => {
    return {
        user: state.user.userProfile,
        cartSolutions: state.crowfallStore.cartSolutions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addItemToUserCart: (id) => dispatch(addItemToUserCart(id)),
        generatePaymentTokenVars: (id, isSubscribe) => dispatch(generatePaymentTokenVars(id, isSubscribe))
    }
}

class XsollaMethods extends React.Component {
    state = {
        loading: false,
    }

    async initPaymentModal() {
        this.setState({ loading: true })

        const { token, sandbox } = await this.generatePaymentOptions()
        var options = {
            access_token: token,
            sandbox,
            lightbox: {
                spinner: 'round',
                height: '70%',
            },
        };

        let xsollaScript = document.createElement('script');
        xsollaScript.type = "text/javascript";
        xsollaScript.async = true;
        xsollaScript.src = "https://static.xsolla.com/embed/paystation/1.0.7/widget.min.js";
        xsollaScript.addEventListener('load', function (e) {
            XPayStationWidget.init(options);
            XPayStationWidget.open()
        }, false);
        var head = document.getElementsByTagName('head')[0];
        head.appendChild(xsollaScript);
        this.maintainUserCart()

        this.setState({ loading: false })
    }

    async generatePaymentOptions() {
        const { generatePaymentTokenVars, isSubscribe } = this.props
        try {
            const solutionId = this.setSolutionId()
            const res = await generatePaymentTokenVars(solutionId, isSubscribe)
            return res
        } catch {
            swal({
                icon: 'error',
                title: strings.getString("ACCOUNT_ERROR", "There seems to be an issue with your account."),
                content: (
                    <div dangerouslySetInnerHTML={{ __html: strings.getString("CONTACT_US_FOR_HELP", "Please contact support@crowfall.com for assistance.") }} />
                )
            })
            return {}
        }
    }

    setSolutionId() {
        let solutionId = null
        if (!_.isEmpty(this.props.solution)) solutionId = _.result(this.props.solution, 'checkout_solution_id')
        if (!_.isEmpty(this.props.cartSolution)) solutionId = _.result(this.props.cartSolution, 'checkout_solution_id')
        return solutionId
    }

    maintainUserCart() {
        const { cartSolutions, addItemToUserCart, cartSolution } = this.props
        if (!_.isEmpty(cartSolutions) && _.isEmpty(cartSolution)) cartSolutions.forEach(async (item) => {
            await addItemToUserCart(item.itemId)
        })
    }

    render() {

        const { loading } = this.state

        return (
            <React.Fragment>
                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>

                <div>
                    <Button medium onclick={ () => this.initPaymentModal() } >
                        {strings.getString("CHECKOUT", "Checkout")}
                    </Button>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(XsollaMethods)
