/*

Element: PromoBanner
provides a link to the provided promotion's url. Handles the following content models: Promo: Campaigns, Trials, Decapathon, Link, and Promotional Content

PROPERTY  DEFAULT  REQUIRED  TYPE    DESCRIPTION
promo     null     Required  Object  JSON Object returned from Contentful.

*/

import React from 'react'
import strings from '../../../strings'
import LocLink from '../LocLink'
import Button from '../../Fragments/Button'
import './styles.scss'

class PromoBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasBeenViewed: false,
    }
    this.handlePromotionImpression = this.handlePromotionImpression.bind(this)
  }

  componentDidMount() {
    const { promo } = this.props
    const hasBeenViewed = this.checkIfPromotionInView(promo)
    if (hasBeenViewed) this.recordPromotionImpression(promo)
    this.setState({
      hasBeenViewed
    }, window.addEventListener('scroll', this.handlePromotionImpression), false)
  }

  handlePromotionImpression() {
    const { hasBeenViewed } = this.state
    const { promo } = this.props
    if (!hasBeenViewed) {
      const viewedStatus = this.checkIfPromotionInView(promo)
      if (viewedStatus) {
        this.recordPromotionImpression(promo)
        this.setState({ hasBeenViewed: viewedStatus })
      }
    }
  }

  checkIfPromotionInView(promo) {
    const promoElement = document.getElementById(`promo-${promo.contentful_id}`)
    const bounds = promoElement ? promoElement.getBoundingClientRect() : {}
    const boundsCenterY = bounds.top + (bounds.height / 2)
    const isInView = boundsCenterY > 0 && boundsCenterY < window.innerHeight && bounds.bottom > 0
    return isInView
  }
  
  recordPromotionImpression(promo) {
    window.gtag("event", "view_promotion", {
        "promotions": [
            {
                "id": promo.contentful_id,
                "name": promo.promoTitle,
            }
        ]
    })
  }

  recordPromotionClick(promo) {
    window.gtag("event", "select_content", {
      "promotions": [
        {
          "id": promo.contentful_id,
          "name": promo.promoTitle,
        }
      ]
    })
  }

  render () {

    const { contentful_id, promoTitle, promoSubtitle, bannerAssetUrl, promoAssetUrl, content_type: contentType, link: contentLink, uri } = this.props.promo
    const assetUrl = promoAssetUrl ? promoAssetUrl : bannerAssetUrl
    let link = ''
    if ( contentType === 'promoCampaigns' ) {
      link = '/campaigns'
    } else if ( contentType === 'promoTrials' ) {
      link = '/trials'
    } else if ( contentType === 'promoDecapathon' ) {
      link = '/decapathon'
    } else if ( contentType === 'promoLink' ) {
      link = `${contentLink}`
    } else if ( contentType === 'promo' ) {
      link = `/promotions/${uri}`
    }

    return (
      <div className="PromoBanner" id={`promo-${contentful_id}`} style={{backgroundImage: `url(${assetUrl})`}}>
        <div className="PromoBanner__title">
          <div className="PromoBanner__titleBlur"/>
          {promoTitle}
        </div>
        <div className="PromoBanner__description">{promoSubtitle}</div>
        <div className="PromoBanner__buttons">
          <div className="PromoBanner__button">
            <LocLink to={link}><Button onclick={() => this.recordPromotionClick(this.props.promo)} lightGray label={strings.getString("LEARN_MORE", "Learn More")}/></LocLink>
          </div>
        </div>
      </div>
    )
  }
}

export default PromoBanner