import React from 'react'
import ReactDOM from 'react-dom'
import 'semantic-ui-css/semantic.min.css'
import MainWindow from './MainWindow'
import { Provider } from 'react-redux'
import store from './redux/store'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import strings from './strings'
import _ from 'lodash'
import ScrollToTop from './ScrollToTop'
import ErrorBoundary from './ErrorBoundary'
import { library } from '@fortawesome/fontawesome-svg-core'
import './styles.scss'

import {
  faGlobe, faUser, faSignOutAlt, faMoneyBill, faLifeRing, faSearch, faBell, faSortDown, faPlusCircle, faPlus, faMinus, faInfoCircle,
  faTimes, faTimesCircle, faBars, faCartPlus, faShoppingCart, faCheck, faEdit, faChevronDown, faChevronLeft, faChevronRight, faArrowUp,
} from '@fortawesome/free-solid-svg-icons'

library.add(faGlobe)
library.add(faUser)
library.add(faSignOutAlt)
library.add(faMoneyBill)
library.add(faLifeRing)
library.add(faSearch)
library.add(faBell)
library.add(faSortDown)
library.add(faPlusCircle)
library.add(faPlus)
library.add(faMinus)
library.add(faInfoCircle)
library.add(faTimes)
library.add(faTimesCircle)
library.add(faBars)
library.add(faCartPlus)
library.add(faShoppingCart)
library.add(faCheck)
library.add(faEdit)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faChevronDown)
library.add(faArrowUp)


ReactDOM.render((
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>

            {/*<Route*/}
            {/*  path="/forgot/"*/}
            {/*  component={({ location }) => (*/}
            {/*    <Redirect*/}
            {/*      to={{*/}
            {/*        ...location,*/}
            {/*        pathname: `/${store.getState().language}/reset-password`,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
            
            <Route
              path="/validate-email"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/validate-email-change`,
                  }}
                />
              )}
            />
            
            {/*<Route*/}
            {/*  path="/:lang/validate/"*/}
            {/*  component={({ location }) => (*/}
            {/*    <Redirect*/}
            {/*      to={{*/}
            {/*        ...location,*/}
            {/*        pathname: `/${store.getState().language}/email-validation`,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
            
            {/*<Route*/}
            {/*  path="/validate/"*/}
            {/*  component={({ location }) => (*/}
            {/*    <Redirect*/}
            {/*      to={{*/}
            {/*        ...location,*/}
            {/*        pathname: `/${store.getState().language}/email-validation`,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}

            <Route
              path="/possess/"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/possess-account`,
                  }}
                />
              )}
            />

            {/*<Route*/}
            {/*    path="/trials"*/}
            {/*    component={({ location }) => (*/}
            {/*        <Redirect*/}
            {/*            to={{*/}
            {/*                ...location,*/}
            {/*                pathname: `/${store.getState().language}/trials`,*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    )}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  path="/:lang/siege-schedule"*/}
            {/*  component={({ location }) => (*/}
            {/*    <Redirect*/}
            {/*      to={{*/}
            {/*        ...location,*/}
            {/*        pathname: `/${store.getState().language}/campaigns`*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}

              {/*<Route*/}
              {/*    path="/:lang/client/"*/}
              {/*    component={({ location }) => (*/}
              {/*        <Redirect*/}
              {/*            to={{*/}
              {/*                ...location,*/}
              {/*                pathname: `/${store.getState().language}/account/download-client`,*/}
              {/*            }}*/}
              {/*        />*/}
              {/*    )}*/}
              {/*/>*/}

            {/*<Route*/}
            {/*  path="/en/client/"*/}
            {/*  component={({ location }) => (*/}
            {/*    <Redirect*/}
            {/*      to={{*/}
            {/*        ...location,*/}
            {/*        pathname: `/${store.getState().language}/account/download-client`,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  path="/store"*/}
            {/*  component={({ location }) => (*/}
            {/*    <Redirect*/}
            {/*      to={{*/}
            {/*        ...location,*/}
            {/*        pathname: `/${store.getState().language}/store`,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}

            <Route
              path={['/artcraft/terms-and-conditions', `/${store.getState().language}/artcraft/terms-and-conditions`, '/monumental/terms-and-conditions']}
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/monumental/terms-and-conditions`,
                  }}
                />
              )}
            />

            <Route
              path="/travian/terms-and-conditions"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/crowfall-eu/terms-and-conditions`,
                  }}
                />
              )}
            />

              <Route
                  path="/login"
                  component={({ location }) => (
                      <Redirect
                          to={{
                              ...location,
                              pathname: `/${store.getState().language}/login`,
                          }}
                      />
                  )}
              />

              <Route
                  path="/travian/right-of-withdrawal"
                  component={({ location }) => (
                      <Redirect
                          to={{
                              ...location,
                              pathname: `/${store.getState().language}/crowfall-eu/right-of-withdrawal`,
                          }}
                      />
                  )}
              />

              <Route
                  path="/travian/data-sharing"
                  component={({ location }) => (
                      <Redirect
                          to={{
                              ...location,
                              pathname: `/${store.getState().language}/crowfall-eu/data-sharing`,
                          }}
                      />
                  )}
              />

            <Route
              path="/travian/privacy-policy"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/crowfall-eu/privacy-policy`,
                  }}
                />
              )}
            />

            <Route
              path="/crowfall-eu/terms-and-conditions"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/crowfall-eu/terms-and-conditions`,
                  }}
                />
              )}
            />

              <Route
                  path="/crowfall-eu/right-of-withdrawal"
                  component={({ location }) => (
                      <Redirect
                          to={{
                              ...location,
                              pathname: `/${store.getState().language}/crowfall-eu/right-of-withdrawal`,
                          }}
                      />
                  )}
              />

            <Route
              path="/crowfall-eu/privacy-policy"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/crowfall-eu/privacy-policy`,
                  }}
                />
              )}
            />

            <Route
              path={['/artcraft/privacy-policy', `/${store.getState().language}/artcraft/privacy-policy`, '/monumental/privacy-policy']}
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/monumental/privacy-policy`,
                  }}
                />
              )}
            />

            {/*<Route*/}
            {/*  path="/guild"*/}
            {/*  component={({ location }) => (*/}
            {/*    <Redirect*/}
            {/*      to={{*/}
            {/*        ...location,*/}
            {/*        pathname: `/${store.getState().language}/guild`,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}

              {/*<Route*/}
              {/*    path="/promotions/crow-recruits"*/}
              {/*    component={({ location }) => (*/}
              {/*        <Redirect*/}
              {/*            to={{*/}
              {/*                ...location,*/}
              {/*                pathname: `/${store.getState().language}/promotions/crow-recruits`,*/}
              {/*            }}*/}
              {/*        />*/}
              {/*    )}*/}
              {/*/>*/}

              {/*<Route*/}
              {/*    path="/decapathon"*/}
              {/*    component={({ location }) => (*/}
              {/*        <Redirect*/}
              {/*            to={{*/}
              {/*                ...location,*/}
              {/*                pathname: `/${store.getState().language}/decapathon`,*/}
              {/*            }}*/}
              {/*        />*/}
              {/*    )}*/}
              {/*/>*/}
              
              {/*<Route*/}
              {/*    path="/promotions/vip-rewards"*/}
              {/*    component={({ location }) => (*/}
              {/*        <Redirect*/}
              {/*            to={{*/}
              {/*                ...location,*/}
              {/*                pathname: `/${store.getState().language}/promotions/vip-rewards`,*/}
              {/*            }}*/}
              {/*        />*/}
              {/*    )}*/}
              {/*/>*/}

              {/*<Route*/}
              {/*    path="/promotions/crowmas"*/}
              {/*    component={({ location }) => (*/}
              {/*        <Redirect*/}
              {/*            to={{*/}
              {/*                ...location,*/}
              {/*                pathname: `/${store.getState().language}/promotions/crowmas`,*/}
              {/*            }}*/}
              {/*        />*/}
              {/*    )}*/}
              {/*/>*/}

            <Route
              exact
              path="/:lang/account/"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/register`,
                  }}
                />
              )}
            />

            <Route
              path="/app/oauth2/"
              component={({ location }) => (
                <Redirect
                  to={{
                    ...location,
                    pathname: `/${store.getState().language}/link-external`,
                  }}
                />
              )}
            />

            {/*<Route*/}
            {/*  path="/join/:code"*/}
            {/*  component={({ location, match }) => {*/}
            {/*    location.search = `?friend=${match.params.code}`*/}
            {/*    return (*/}
            {/*      <Redirect*/}
            {/*        to={{*/}
            {/*          ...location,*/}
            {/*          pathname: `/${store.getState().language}/register`,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    )*/}
            {/*  }}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  path="/friendtrial/:code"*/}
            {/*  component={({ location, match }) => {*/}
            {/*    location.search = `?friend=${match.params.code}`*/}
            {/*    return (*/}
            {/*      <Redirect*/}
            {/*        to={{*/}
            {/*          ...location,*/}
            {/*          pathname: `/${store.getState().language}/register`,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    )*/}
            {/*  }}*/}
            {/*/>*/}

              {/*<Route*/}
              {/*    path="/beta/specialoffers"*/}
              {/*    component={({ location, match }) => {*/}
              {/*        location.search = `?category=Starter%20Packs`*/}
              {/*        return (*/}
              {/*            <Redirect*/}
              {/*                to={{*/}
              {/*                    ...location,*/}
              {/*                    pathname: `/${store.getState().language}/store`,*/}
              {/*                }}*/}
              {/*            />*/}
              {/*        )*/}
              {/*    }}*/}
              {/*/>*/}
              
              {/*<Route*/}
              {/*    path="/collectorsedition"*/}
              {/*    component={({ location, match }) => {*/}
              {/*        location.search = `?category=Collector%27s%20Edition`*/}
              {/*        return (*/}
              {/*            <Redirect*/}
              {/*                to={{*/}
              {/*                    ...location,*/}
              {/*                    pathname: `/${store.getState().language}/store`,*/}
              {/*                }}*/}
              {/*            />*/}
              {/*        )*/}
              {/*    }}*/}
              {/*/>*/}
              
              {/*<Route*/}
              {/*    path="/:lang/eternalchampions"*/}
              {/*    component={({ location, match }) => {*/}
              {/*        return (*/}
              {/*            <Redirect*/}
              {/*                to={{*/}
              {/*                    ...location,*/}
              {/*                    pathname: `/${store.getState().language}/eternal-champions`,*/}
              {/*                }}*/}
              {/*            />*/}
              {/*        )*/}
              {/*   }}*/}
              {/*/>*/}

              {/*<Route*/}
              {/*    path="/:lang/teams"*/}
              {/*    component={({ location, match }) => {*/}
              {/*        return (*/}
              {/*            <Redirect*/}
              {/*                to={{*/}
              {/*                    ...location,*/}
              {/*                    pathname: `/${store.getState().language}/account/teams`,*/}
              {/*                }}*/}
              {/*            />*/}
              {/*        )*/}
              {/*   }}*/}
              {/*/>*/}

              {/*<Route*/}
              {/*    path="/code/:code"*/}
              {/*    component={({ location, match }) => {*/}
              {/*        location.search = `?code=${match.params.code}`*/}
              {/*        return (*/}
              {/*            <Redirect*/}
              {/*                to={{*/}
              {/*                    ...location,*/}
              {/*                    pathname: `/${store.getState().language}/account/redeem-codes`,*/}
              {/*                }}*/}
              {/*            />*/}
              {/*        )*/}
              {/*    }}*/}
              {/*/>*/}

              <Route
                path="/:lang/index.html"
                component={({ location, match }) => {
                    return (
                        <Redirect
                            to={{
                                ...location,
                                pathname: `/`,
                            }}
                        />
                    )
                }}
              />

              {/*<Route*/}
              {/*    path="/twitch-management"*/}
              {/*    component={({ location, match }) => {*/}
              {/*        return (*/}
              {/*            <Redirect*/}
              {/*                to={{*/}
              {/*                    ...location,*/}
              {/*                    pathname: `/${store.getState().language}/account/twitch-management`,*/}
              {/*                }}*/}
              {/*            />*/}
              {/*        )*/}
              {/*    }}*/}
              
              {/*/>*/}

              <Route
                path={['/artcraft/fair-use-policy', `/${store.getState().language}/artcraft/fair-use-policy`, '/monumental/fair-use-policy']}
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: `/${store.getState().language}/monumental/fair-use-policy`,
                    }}
                  />
                )}
              />

              <Route
                path={['/artcraft/rules-of-conduct', `/${store.getState().language}/artcraft/rules-of-conduct`, '/monumental/rules-of-conduct']}
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: `/${store.getState().language}/monumental/rules-of-conduct`,
                    }}
                  />
                )}
              />

              <Route
                path={['/artcraft/shipping-policy', `/${store.getState().language}/artcraft/shipping-policy`, '/monumental/shipping-policy']}
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: `/${store.getState().language}/monumental/shipping-policy`,
                    }}
                  />
                )}
              />

              <Route
                path={['/artcraft/contact-corporate', `/${store.getState().language}/artcraft/contact-corporate`, '/monumental/contact-corporate']}
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: `/${store.getState().language}/monumental/contact-corporate`,
                    }}
                  />
                )}
              />

              <Route
                path={['/artcraft/data-sharing', `/${store.getState().language}/artcraft/data-sharing`, '/monumental/data-sharing']}
                component={({ location }) => (
                  <Redirect
                    to={{
                      ...location,
                      pathname: `/${store.getState().language}/monumental/data-sharing`,
                    }}
                  />
                )}
              />

              <Route
                path={['/jobs', `/${store.getState().language}/jobs`]}
                component={() => { window.location = 'https://monumental.catsone.com/careers/'; return null; }
                }
              />

            {/**/}

            {/*en/account/?affiliateCode=PR-CROWFALL-STREAM-DEC-PROMO#tab-registration*/}


            <Route path='/:lang(en-US|fr-FR|de-DE|pt-PT|es-ES)' component={MainWindow}/>

            <Route render={(props) => {
                const location = _.result(props.location, 'pathname') || '';
                const q = _.result(props.location, 'search')
                let redirect = null;
                if(/\/(en|fr|de|es|pt)\//.test(location)) {
                    redirect = `/${location.split('/').slice(2).join('/')}`
                }
                const newUrl = `/${strings.currentLanguage()}${redirect || location}${q}`
              return (
                <Redirect to={newUrl}/>
              )
            }}/>

          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
), document.getElementById('root'))
