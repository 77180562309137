import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import MainNav from './MainNav'
import Modal from './Modal'
import Footer from './Footer'
import ShoppingCart from './ShoppingCart'
import AnnouncementBanner from './AnnouncementBanner'

import './styles.scss'

const mapStateToProps = (state) => {
  return {
    openNav: state.openNav,
    initialFetched: state.initialFetch,
    modal: state.modal,
  }
}

class SiteFrame extends React.Component {

  render() {

    const { openNav, initialFetched, modal } = this.props

    return (
      <div className='SiteFrame'>
        { initialFetched &&
          <React.Fragment>
            {!_.isEmpty(modal.content) && <Modal/>}
            {/*<ShoppingCart />*/}
            <MainNav/>
            {openNav === true && <div className="SiteFrame__overlay" />}
            <div className="SiteFrame__content">
              <AnnouncementBanner />
              {this.props.children}
              <Footer/>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(SiteFrame)
