import React from 'react'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../../strings'
import FieldDropdown from '../../../componentLibrary/Models/FieldDropdown'
import FieldInput from '../../../componentLibrary/Models/FieldInput'
// import SearchBar from '../../../componentLibrary/Elements/Searchbar'
import SectionHeader from '../../../componentLibrary/Fragments/SectionHeader'
import DisplayField from '../../../componentLibrary/Fragments/DisplayField'
import FormMessage from '../../../componentLibrary/Fragments/FormMessage'
import Button from '../../../componentLibrary/Fragments/Button'
import GoldBar from '../../../componentLibrary/Fragments/GoldBar'
// import GuildChatFeed from './GuildChatFeed'

import { 
    updateGuildDetails, fetchGuildChat, sendMessageToGuildChat,
    searchGuildChat, clearGuildChatSearchCriteria 
} from '../../../redux/actions/guild-management'
import './styles.scss'


const mapStateToProps = state => {
    return {
        languages: state.languages,
        configuration: state.guildManagement.guildConfiguration,
        guild: state.guildManagement.guild,
        guildRank: state.guildManagement.guildRank,
        guildId: state.guildManagement.guildId,
        chatSearched: state.guildManagement.chatSearched,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateGuildDetails: (guildId, updates) => dispatch(updateGuildDetails(guildId, updates)),
        fetchChat: () => dispatch(fetchGuildChat()),
        sendMessageToChat: () => dispatch(sendMessageToGuildChat()),
        searchChat: () => dispatch(searchGuildChat()),
        clearChatSearchCriteria: () => dispatch(clearGuildChatSearchCriteria()),
    }
}


class GuildOverviewPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            languageOptions: [],
            allegianceOptions: [],
            statusOptions: [],
            editing: false,
            canModifyGuild: false,

            settings: {
                description: null,
                recruiting_status: null,
                mission_statement: null,
                website_url: null,
                allegiance_id: null,
                preferred_languages: [],
            },
            updateInProgress: false,

            showMessageForm: false,
            messageInput: null,
        }
        this.setGuildSettings = this.setGuildSettings.bind(this)
        this.toggleEditing = this.toggleEditing.bind(this)
        this.clearSettingsFields = this.clearSettingsFields.bind(this)
        this.updateSettingsField = this.updateSettingsField.bind(this)
        this.handleSubmitSettings = this.handleSubmitSettings.bind(this)
        this.selectLanguage = this.selectLanguage.bind(this)
        this.deselectLanguage = this.deselectLanguage.bind(this)
        this.toggleChatForm = this.toggleChatForm.bind(this)
        this.updateMessageInput = this.updateMessageInput.bind(this)
        this.closeChatForm = this.closeChatForm.bind(this)
        this.submitChatMessage = this.submitChatMessage.bind(this)
    }

    async componentDidMount() {
        await this.props.fetchChat()
        this.setGuildSettings()
        this.verifyUserPermissions()
    }

    componentDidUpdate(prevProps) {
        const { configuration: prevConfigs, languages: prevLanguages, guild: prevGuild } = prevProps
        const { configuration: currConfigs, languages: currLanguages, guild: currGuild } = this.props
        if (
            !_.isEqual(prevConfigs, currConfigs) || !_.isEqual(prevLanguages, currLanguages) || !_.isEqual(prevGuild, currGuild)
        ) {
            this.setGuildSettings()
            this.verifyUserPermissions()
        }
    }

    setGuildSettings() {
        const { configuration, languages, guild } = this.props
        const { description, missionStatement, websiteUrl, allegianceId, recruitingStatus, preferredLanguages } = guild

        const languageOptions = languages ? languages.map(language => {
            return Object.assign({}, null, {
                label: language.display_name,
                value: language.culture_code,
                selected: preferredLanguages.includes(language.culture_code)
            })
        }) : []
        const allegianceOptions = configuration.guild_allegiances ? configuration.guild_allegiances.map(pantheon => {
            return Object.assign({}, null, {
                label: pantheon.display_name,
                value: pantheon.guild_allegiance_id,
                selected: _.isEqual(pantheon.guild_allegiance_id, allegianceId)
            })
        }) : []
        const statusOptions = configuration.recruiting_status ? configuration.recruiting_status.map(status => {
            return Object.assign({}, null, {
                label: status === "accepting_applications" ? "Accepting Applications" : _.capitalize(status),
                value: status,
                selected: _.isEqual(status, recruitingStatus)
            })
        }) : []
        this.setState({ 
            languageOptions, allegianceOptions, statusOptions,
            settings: {
                description, recruiting_status: recruitingStatus, mission_statement: missionStatement, 
                website_url: websiteUrl, allegiance_id: allegianceId, preferred_languages: preferredLanguages,
            }
        })
    }

    verifyUserPermissions() {
        const { configuration, guildRank } = this.props
        const modifyGuildRankRequirement = _.result(_.find(configuration.permission_display, { "value": "modify_guild" }), "required_rank")
        this.setState({
            canModifyGuild: guildRank && guildRank <= modifyGuildRankRequirement ? true : false,
        })
    }
    
    toggleEditing() {
        if (this.state.editing === false) {
            this.setState({ editing: true })
        } else {
            this.clearSettingsFields()
        }
    }

    clearSettingsFields() {
        this.setState({
            editing: false,
        }, this.setGuildSettings())
    }

    updateSettingsField(value, name) {
        const { settings } = this.state
        let newSettings = { ...settings, [name]: value}
        this.setState({ settings: newSettings })
    }
    async handleSubmitSettings() {
        const { settings } = this.state
        const { guildId } = this.props
        this.setState({ updateInProgress: true })
        try {
            await this.props.updateGuildDetails(guildId, { ...settings })
            swal({
                title: strings.getString("GUILD_SETTINGS_UPDATED_SUCCESS", "Successfully updated Guild settings."),
                icon: "success",
            })
            this.setState({ editing: false })
        } catch (err) {
            if (err.response.data.message === "Cannot change Allegiance due to recent Allegiance changes") {
                swal({
                    icon: "error",
                    title: strings.getString("GUILD_ALLEGIANCE_ERROR", "Uh Oh! You recently changed your Guild's Allegiance (Pantheon)."),
                    content: <div>{strings.getString("GUILD_ALLEGIANCE_COOL_DOWN_ERROR")}</div>,
                })
            } else {
                swal(err.response.data.message, {
                    icon: "error"
                })
            }

        }
        this.setState({ updateInProgress: false })
    }

    selectLanguage(value, name) {
        const { settings } = this.state
        const newSettings = { ...settings, [name]: [ ...settings[name], value] }
        this.setState({ settings: newSettings })
    }

    deselectLanguage(value, name) {
        const { settings } = this.state
        const newSettings = { ...settings, [name]: settings[name].filter(item => !_.isEqual(item, value)) }
        this.setState({ settings: newSettings })
    }

    toggleChatForm(currFormStatus) {
        if ( currFormStatus ) this.closeChatForm()
        if ( !currFormStatus ) this.setState({ showMessageForm: true })
    }

    updateMessageInput(value) {
        this.setState({ messageInput: value })
    }

    closeChatForm() {
        this.setState({ showMessageForm: false, messageInput: null })
    }

    submitChatMessage() {
        
    }

    render() {

        const { 
            guild,
            // searchChat, clearChatSearchCriteria, chatSearched 
        } = this.props
        const { name, displayName, leader } = guild
        const { 
            languageOptions, statusOptions, allegianceOptions, editing, settings, canModifyGuild, updateInProgress,
            // showMessageForm, messageInput 
        } = this.state
        const { description, recruiting_status, mission_statement, website_url, preferred_languages } = settings
        let settingsFormIcon = null
        if (canModifyGuild) {
            settingsFormIcon = editing ? "times-circle" : "edit"
        }
        // const chatFeedIcon = showMessageForm ? "times-circle" : "plus-circle"
        const errorLanguages = _.isEmpty(preferred_languages)
        const allegiance = _.find(allegianceOptions, ["selected", true]) || {}

        return (
            <div className="GuildOverviewPage">
                <SectionHeader title={displayName}/>
                <div className="GuildOverviewPage__headerContent">
                    <div className="GuildOverviewPage__block">
                        <div className="GuildOverviewPage__label">{strings.getString("UNIQUE_NAME", "Unique Name")}:</div>
                        <div className="GuildOverviewPage__value">{name}</div>
                    </div>
                    <div className="GuildOverviewPage__centerBlock">
                        <div className="GuildOverviewPage__label">{strings.getString("GUILD_LEADER", "Guild Leader")}:</div>
                        <div className="GuildOverviewPage__value">{leader}</div>
                    </div>
                    {/* <div className="GuildOverviewPage__block">
                        <div className="GuildOverviewPage__label">{strings.getString("CONNECT_WITH_GUILD_ON_DISCORD", "Connect with us on Discord!")}</div>
                        <div className="GuildOverviewPage__value">{discord}</div>
                    </div> */}
                </div>
                <div className="GuildOverviewPage__divider"><GoldBar h3/></div>

                <SectionHeader title={strings.getString("GUILD_DETAILS", "Guild Details")} h3 icon={settingsFormIcon} click={this.toggleEditing}/>
                {!editing && <div>
                    <div className="GuildOverviewPage__field">
                        <DisplayField label={strings.getString("DESCRIPTION", "Description")} value={description} maxHeight={350}/>
                        </div>
                    <div className="GuildOverviewPage__field">
                        <DisplayField label={strings.getString("RECRUITMENT_STATUS", "Recruitment Status")} value={recruiting_status === "accepting_applications" ? "Accepting Applications" : _.capitalize(recruiting_status)} columnal/>
                        </div>
                    <div className="GuildOverviewPage__field">
                        <DisplayField label={strings.getString("MISSION_STATEMENT", "Mission Statement")} value={mission_statement} columnal/>
                        </div>
                    <div className="GuildOverviewPage__field">
                        <DisplayField label={strings.getString("WEBSITE", "Website")} value={website_url} columnal/>
                        </div>
                    <div className="GuildOverviewPage__field">
                        <DisplayField label={strings.getString("PANTHEON", "Pantheon")} value={allegiance.label} columnal/>
                        </div>
                    <div className="GuildOverviewPage__field">
                        <DisplayField
                            label={strings.getString("WE_SPEAK", "We Speak")} columnal
                            value={preferred_languages.length > 0 && preferred_languages.map((language, index) => {
                                const languageObject = _.find(languageOptions, ['value', language])
                                const languageLabel = !!languageObject ? languageObject.label : ""
                                let value = index === 0 ? languageLabel : ` ${languageLabel}`
                                return value
                            })}
                        />
                    </div>
                </div>}

                {editing && <div>
                    <div className="GuildOverviewPage__field">
                        <FieldInput 
                            label={strings.getString("DESCRIPTION", "Description")} area xTall
                            name="description" value={description} onChange={this.updateSettingsField}
                        />
                    </div>
                    <div className="GuildOverviewPage__field">
                        <FieldDropdown
                            label={strings.getString("RECRUITMENT_STATUS", "Recruitment Status")} columnal
                            name="recruiting_status" above options={statusOptions} selectOne={this.updateSettingsField}
                        />
                    </div>
                    <div className="GuildOverviewPage__field">
                        <FieldInput
                            label={strings.getString("MISSION_STATEMENT", "Mission Statement")} columnal area short 
                            name="mission_statement" value={mission_statement} onChange={this.updateSettingsField} 
                        />
                    </div>
                    <div className="GuildOverviewPage__field">
                        <FieldInput
                            label={strings.getString("WEBSITE", "Website")} columnal
                            name="website_url" value={website_url} onChange={this.updateSettingsField}
                        />
                    </div>
                    <div className="GuildOverviewPage__field">
                        <FieldDropdown
                            label={strings.getString("PANTHEON", "Pantheon")} columnal
                            name="allegiance_id" above options={allegianceOptions} 
                            selectOne={this.updateSettingsField}
                        />
                    </div>
                    <div className="GuildOverviewPage__field">
                        <FieldDropdown
                            label={strings.getString("WE_SPEAK", "We Speak")} columnal
                            placeholder={strings.getString("LANGUAGES", "Languages")}
                            name="preferred_languages" above options={languageOptions} 
                            selectMany={this.selectLanguage} deselect={this.deselectLanguage}
                        />
                    </div>
                    {errorLanguages && <div className="GuildOverviewPage__formError">
                        <FormMessage error title={strings.getString("UH_OH", "Uh Oh!")} content={strings.getString("GUILD_ERROR_LANGUAGE_REQUIRED", "Please select one or more preferred languages.")} columnal/>
                    </div>}

                    <div className="GuildOverviewPage__formButtons">
                        <div className="GuildOverviewPage__button"><Button label={strings.getString("CANCEL", "Cancel")} onclick={this.clearSettingsFields} loading={updateInProgress} gray medium/></div>
                        <div className="GuildOverviewPage__button"><Button label={strings.getString("SUBMIT_CHANGES", "Submit Changes")} disabled={errorLanguages} loading={updateInProgress} onclick={this.handleSubmitSettings} medium/></div>
                    </div>
                </div>}


                {/* <div className="GuildOverviewPage__chatFeed">
                    <SectionHeader icon={chatFeedIcon} title={strings.getString("CHAT_FEED", "Chat Feed")} click={() => this.toggleChatForm(showMessageForm)}/>
                    
                    {showMessageForm && <div className="GuildOverviewPage__chatForm">
                        <div className="GuildOverviewPage__chatDisclaimer">{strings.getString("GUILD_CHAT_MESSAGE_DISCLAIMER", "Please note that this message board also serves as the in-game message board, and can be viewed by all Guild members.")}</div>
                        <FieldInput 
                            placeholder={strings.getString("GUILD_SEND_A_MESSAGE", "Send a message to the Guild")}
                            name="messageInput" value={messageInput} onChange={this.updateMessageInput} area
                        />
                        <div className="GuildOverviewPage__formButtons">
                            <div className="GuildOverviewPage__button"><Button label={strings.getString("CANCEL", "Cancel")} onclick={this.closeChatForm} gray medium/></div>
                            <div className="GuildOverviewPage__button"><Button label={strings.getString("SEND_MESSAGE", "Send Message")} onclick={this.submitChatMessage} medium/></div>
                        </div>
                    </div>}
                    
                    <div className="GuildOverviewPage__search">
                        <SearchBar search={searchChat} clear={clearChatSearchCriteria}/>
                    </div>

                    <GuildChatFeed items={chatSearched} />
                </div> */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildOverviewPage)