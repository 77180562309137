/*


FRAGMENT: Tag
clickable description intended to be used as categorization features for content.

PROPERTY    DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

label                Optional     String    label for Tag. will override children if provided
children             Optional     Function  any jsx element to be displayed as the tag's label.
onClick              Optional     Function  function to be passed when tag is clicked. Only enabled when selectable is true
selectable  false    Optional     Boolean   enabled onClick, changes cursor on hover, de-emphasizes styling.

*/


import React from 'react'

import './styles.scss'


const Tag = (props) => {

    const { label, children, onClick, selectable } = props
    const click = onClick && selectable ? onClick : null

    let className = 'Tag'
    if (selectable) className += ' Tag--selectable'

    return (
        <div className={className} onClick={click}>
            {label || children}
        </div>
    )
}

export default Tag