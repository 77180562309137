import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import { Container, Grid } from 'semantic-ui-react'
import contentful from '../../utilities/contentful'
import _ from 'lodash'
import strings from '../../strings'
import {Redirect} from "react-router-dom";

class AboutTeamPage extends React.Component {

  state = {
    entries: [],
    board: []
  }

  async componentDidMount() {
    await this.getContent()
  }

  async getContent() {
    const entries = _.sortBy(await contentful.getEntries('teamMember'), 'displayWeight').map(e => {
      e.photo_url = _.result(e, 'photo.assetUrl')
      e.name = _.upperCase(e.name)
      e.title = _.upperCase(e.title)
      return e
    })

    const board = _.sortBy(await contentful.getEntries('advisoryBoard'), 'displayWeight').map(e => {
      e.photo_url = _.result(e, 'photo.assetUrl')
      e.name = _.upperCase(e.name)
      e.title = _.upperCase(e.title)
      return e
    })

    this.setState({ 'entries': entries, 'board': board })
  }

  render() {

    const { entries, board } = this.state

    return (
      <Page contentId={assets.contentfulIds.ABOUT_TEAM_PAGE} update={this.getContent.bind(this)}>

        {/*Redirecting to root, (Disabling page)*/}
        <Redirect to="/"/>
        
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>


          <Container>
            <Grid>

              <Grid.Row style={{ borderBottom: '1px solid black' }}>
                <Grid.Column>
                  <h1>{strings.getString('TEAM_MEMBERS', 'Team Members')}</h1>
                </Grid.Column>
              </Grid.Row>

              {entries.map((e, i) => (

                <Grid.Row key={i}>
                  <Grid.Column width={4} style={{
                    textAlign: 'center',
                  }}>
                    <img alt={e.name} src={e.photo_url} style={{ width: '200px' }}/>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <div style={{ fontSize: '24px', lineHeight: '24px', paddingBottom: '18px' }}>
                      <strong>{e.name}</strong> | {e.title}</div>

                    <div dangerouslySetInnerHTML={{ __html: e.description }} style={{
                      fontSize: '16px',
                    }}>
                    </div>
                  </Grid.Column>
                </Grid.Row>

              ))}




              <Grid.Row style={{ borderBottom: '1px solid black' }}>
                <Grid.Column>
                  <h1>{strings.getString('ADVISORY_BOARD', 'Advisory Board')}</h1>
                </Grid.Column>
              </Grid.Row>

              {board.map((e, i) => (

                <Grid.Row key={i}>
                  <Grid.Column width={4} style={{
                    textAlign: 'center',
                  }}>
                    <img alt={e.name} src={e.photo_url} style={{ width: '200px' }}/>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <div style={{ fontSize: '24px', lineHeight: '24px', paddingBottom: '18px' }}>
                      <strong>{e.name}</strong> | {e.title}</div>

                    <div dangerouslySetInnerHTML={{ __html: e.description }} style={{
                      fontSize: '16px',
                    }}>
                    </div>
                  </Grid.Column>
                </Grid.Row>

              ))}

            </Grid>
          </Container>
        </div>
      </Page>
    )
  }
}

export default AboutTeamPage

