import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import strings from '../../../../strings'
import Menu from '../Menu'



const mapStateToProps = (state) => {
  const user = state.user.userProfile ? state.user.userProfile : {}
  const menuItems = [
    // {
    //   label: strings.getString("SHOP", "Shop"),
    //   link: '/store'
    // },
    {
      label: strings.getString('NEWS'),
      links: [
        {
          label: strings.getString('NEWS'),
          links: [
            {
              label: strings.getString('NEWS'),
              link: '/news/articles'
            }]
        }
      //   , {
      //     label: 'Promotions',
      //     links:
      //       [
      //         {
      //           label: 'Current Promotions',
      //           link: '/promotions'
      //         },
      //         // {
      //         //   label: 'Crow Recruits',
      //         //   link: '/promotions/crow-recruits'
      //         // },
      //         {
      //           label: 'Crowmas',
      //           link: '/promotions/crowmas'
      //         },
      //         {
      //           label: 'Decapathon',
      //           link: '/decapathon'
      //         },
      //         {
      //           label: 'VIP Rewards',
      //           link: '/promotions/vip-rewards'
      //         }
      //     ]
      // }
      ]
    },
    {
      label: strings.getString('GAME'),
      links: [
        {
          label: strings.getString('CROWS'),
          links: [
            {
              label: strings.getString('CLASSES'),
              link: '/classes'
            },
            {
              label: strings.getString('RACES'),
              link: '/races'
            },
            {
              label: strings.getString('COMBINATIONS'),
              link: '/combinations'
            }
          ]
        },
        {
          label: strings.getString('LORE', 'Lore'),
          links: [
            {
              label: strings.getString('BESTIARY'),
              link: '/bestiary'
            },
            {
              label: strings.getString('PANTHEON'),
              link: '/pantheon'
            }
          ]
        },
        // {
        //   label: null,
        //   links: [
        //     // {
        //     //   label: strings.getString('DOWNLOAD_CLIENT'),
        //     //   link: '/account/download-client',
        //     //   secured: true
        //     // },
        //     // {
        //     //   label: strings.getString('CAMPAIGNS'),
        //     //   link: '/campaigns'
        //     // },
        //     // {
        //     //   label: strings.getString('HOW_TO_PLAY'),
        //     //   link: '/how-to-play'
        //     // },
        //     // {
        //     //   label: strings.getString('TROUBLESHOOTING'),
        //     //   link: '/troubleshooting'
        //     // },
        //     // {
        //     //   label: strings.getString('FAQ'),
        //     //   link: '/faq'
        //     // },
        //     // {
        //     //   label: strings.getString('VIP'),
        //     //   link: '/vip',
        //     // },
        //   ]
        // }
      ]
    },
    {
      label: strings.getString('COMMUNITY'),
      links: [
        {
          label: null,
          links: [

            {
              label: 'Discord',
              link: 'https://discord.gg/crowfall',
              external: true
            },
            // {
            //   label: strings.getString('FORUM'),
            //   link: 'https://community.crowfall.com',
            //   external: true
            // },
            // {
            //   label: strings.getString('INFLUENCERS'),
            //   link: '/influencer',
            //   secured: true
            // },
            // {
            //   label: 'Twitch',
            //   link: 'https://www.twitch.tv/crowfallgame',
            //   external: true
            // },
            // {
            //   label: strings.getString('WAR_STORIES'),
            //   link: '/warstories'
            // },
          ]
        }
      ]
    },
    // {
    //   label: strings.getString("GUILDS"),
    //   links: [
    //     {
    //       label: null,
    //       links: [
    //         {
    //           label: strings.getString("MY_GUILD"),
    //           link: "/guild"
    //         },
    //         {
    //           label: strings.getString("SEARCH_GUILDS"),
    //           link: "/guilds/search"
    //         },
    //       ]
    //     }
    //   ]
    // },
    {
      label: strings.getString('ABOUT'),
      links: [
        {
          label: null,
          links: [
            // {
            //   label: strings.getString("BLOG"),
            //   link: '/blog'
            // },
            /*
            {
              label: strings.getString('TEAM'),
              link: '/team'
            },
           */
            // {
            //   label: strings.getString('PARTNERS'),
            //   link: '/partners'
            // },
            {
              label: strings.getString('JOBS'),
              link: 'https://monumental.catsone.com/careers/',
              external: true,
            },
            {
              label: strings.getString('PRESS'),
              link: '/press'
            },

          ]
        },
        {
          label: strings.getString('MEDIA'),
          links: [
            {
              label: strings.getString('SCREENS'),
              link: '/media/screens'
            },
            {
              label: strings.getString('VIDEOS'),
              link: '/media/videos'
            },
            {
              label: strings.getString('CONCEPT_ART'),
              link: '/media/conceptarts'
            },
          ]
        }
      ]
    }
  ]
  return {
    menuItems,
    user,
  }
}

export default withRouter(connect(mapStateToProps, null)(Menu))
