import React from 'react'
import strings from '../../../strings'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
import './styles.scss'

const BackToECSButton = () => {
    return (
        <div className="BackToECSButton">
            <LocLink to="/eternal-champions">
                <Button label={strings.getString("DIRECTION_BACK", "Back")}/>
            </LocLink>
        </div>
    )
}

export default BackToECSButton