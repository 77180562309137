import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './reducers'
import strings from '../strings'
import _ from 'lodash'
import thunk from 'redux-thunk'
import Cookies from 'js-cookie'

let location = _.result(window.location, 'pathname', '').split('/')
let loadedLang = null
if (!_.isEmpty(location) && location.length > 1) {
  location.shift()
  loadedLang = location.shift()
  loadedLang = strings.convertLanguage(loadedLang) || loadedLang
  localStorage.setItem('site_language', loadedLang)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ALL ENV VARS NEED TO HAVE REACT_APP_ prefix in order for React to pick them up.
export default createStore(rootReducer, {
    initialFetch: false,
    language: loadedLang,
    session: getUserData(),
    cookieNotice: JSON.parse(localStorage.getItem('cookieNotice')),
    availableLanguages: [],
    accountRegionalAgreements: [],
    acceptedRegionalAgreements: [],
    availableAgreementDefinitions: [], //deprecated
    location: {},
    languages: [],
    affiliateDetails: {},
    languageMap: {},
    countryList: [],
    cohorts: {},
    regions: [{
      region_name: 'Global',
    }],
    modal: {},
    openNav: false,
    config: _.merge({
      default_registration_source: 'organic-default',
      base_api_host: process.env.REACT_APP_API_HOST || 'https://artcraft.epicdata.io',
      affiliate_api_host: process.env.REACT_APP_AFFILIATE_API_HOST || 'https://aff-acc.artcraftapps.com',
      identity_api_host: process.env.REACT_APP_IDENTITY_API_HOST || 'https://identity.artcraftapps.com', 
      guilds_api_host: process.env.REACT_APP_GUILD_API_HOST || 'https://guilds.artcraftapps.com',
      default_org_id: process.env.REACT_APP_DEFAULT_ORG_ID,
      contentful_access_token: 'dfba9df39a9e11ef05f9128960755b387232d71686581cc887654c2b0ffef058',
      contentful_host_url: 'https://cdn.contentful.com'
    }, getConfig()),
  },
  composeEnhancers(
    applyMiddleware(thunk),
  ),
)

function getConfig() {
  let localConfig
  try {
    localConfig = require('../local-config.json')
  } catch {

  }
  return localConfig || Cookies.getJSON('crowfall_env')
}

function getUserData() {
  const userData = localStorage.getItem("userData")
  try {
    return JSON.parse(userData)
  } catch {
    return null
  }
}