import React from 'react'
import strings from '../../strings'
import Button from '../../componentLibrary/Fragments/Button'
import './styles.scss'
import {Redirect} from "react-router-dom";


const AccountAdditionalDownloadsPage = props => {
    return (
        <div className="AccountAdditionalDownloadsPage">
            <Redirect to="/account/profile" />
            <h1>{strings.getString("ADDITIONAL_DOWNLOADS", "Additional Downloads")}</h1>

            <h3>{strings.getString("RAZER_DOWNLOADS", "Razer X Crowfall Downloads")}</h3>
            <p>Razer Naga Pro Crowfall mappings.</p>
            <div className="AccountAdditionalDownloadsPage__razerButtons">
                <div className="AccountAdditionalDownloadsPage__razerBlock">
                    <img alt="Razer Naga Pro Mapping" className="AccountAdditionalDownloadsPage__razerImage" src="https://artcraft-website-cdn.s3.amazonaws.com/Crowfall+2-Button.png"/>
                    <a href="https://artcraft-website-cdn.s3.amazonaws.com/Crowfall+2-Button.synapse3">
                        <Button green label={strings.getString("DOWNLOAD_2-BUTTON", "2-button mappings")}/>
                    </a>
                </div>
                <div className="AccountAdditionalDownloadsPage__razerBlock">
                    <img alt="Razer Naga Pro Mapping" className="AccountAdditionalDownloadsPage__razerImage" src="https://artcraft-website-cdn.s3.amazonaws.com/Crowfall+6-Button.png"/>
                    <a href="https://artcraft-website-cdn.s3.amazonaws.com/Crowfall+6-Button.synapse3">
                        <Button green label={strings.getString("DOWNLOAD_6-BUTTON", "6-button mappings")}/>
                    </a>
                </div>
                <div className="AccountAdditionalDownloadsPage__razerBlock">
                    <img alt="Razer Naga Pro Mapping" className="AccountAdditionalDownloadsPage__razerImage" src="https://artcraft-website-cdn.s3.amazonaws.com/Crowfall+12-Button.png"/>
                    <a href="https://artcraft-website-cdn.s3.amazonaws.com/Crowfall+12-Button.synapse3">
                        <Button green label={strings.getString("DOWNLOAD_12-BUTTON", "12-button mappings")}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AccountAdditionalDownloadsPage