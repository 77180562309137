import Page from '../componentLibrary/Models/Page'
import AccountDetailsMenu from './AccountDetailsMenu'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'
import PrivateRoute from '../componentLibrary/Elements/PrivateRoute'
import AccountProfilePage from './AccountProfilePage'
import AccountShippingPage from './AccountShippingPage'
import AccountDetailsRewards from './AccountDetailsRewards'
import AccountDetailsForumBadge from './AccountDetailsForumBadge'
import AccountDetailsSecurity from './AccountDetailsSecurity'
import AccountDetails2FA from './AccountDetails2FA'
import AccountDetailsOrderHistory from './AccountDetailsOrderHistory'
import AccountDetailsWallets from './AccountDetailsWallets'
import AccountDetailsLayaway from './AccountDetailsLayaway'
import AccountDetailsLayawayDetail from './AccountDetailsLayawayDetail'
import AccountDetailsRedeemCodes from './AccountDetailsRedeemCodes'
import AccountGiftsPage from './AccountGiftsPage'
import AccountDetailsGiftsGiven from './AccountDetailsGiftsGiven'
import AccountDetailsGiftsReceived from './AccountDetailsGiftsReceived'
import AccountDetailsRewardsConsumed from './AccountDetailsRewardsConsumed'
import AccountDetailsRewardsRedeemed from './AccountDetailsRewardsRedeemed'
import AccountDetailsOrderHistoryDetail from './AccountDetailsOrderHistoryDetail'
import AccountDetailsWalletsDetail from './AccountDetailsWalletsDetail'
import AccountDetailsGuildInvites from './AccountDetailsGuildInvites'
import AccountDetailsGuildApplications from './AccountDetailsGuildApplications'
import AccountDetailsDiscounts from './AccountDetailsDiscounts'
import AccountDetailsAffiliateDetails from './AccountDetailsAffiliateDetails';
import AccountDetailsCodes from './AccountDetailsCodes';
import AccountTwitchManagement from './AccountTwitchManagement';
import AccountDiscordManagement from './AccountDiscordManagement';
import AccountDownloadableRewardsPage from './AccountDownloadableRewardsPage'
import AccountAdditionalDownloadsPage from './AccountAddtionalDownloadsPage'
import DownloadClient from './DownloadClient'
import './styles.scss'
import AccountDetailsTeams from './AccountDetailsTeams';
import AccountVipPage from './AccountVipPage';
import _ from 'lodash'


const AccountRoutes = props => {
  const { cohorts, accountRegions } = props
  const showDownloadableRewards = cohorts.artbook_download || cohorts.soundtrack_download
  const userCanGift = _.isEmpty(_.find(accountRegions, { region_name: "Brazil" }))
  return (
    <Switch>
      <PrivateRoute exact path='/:lang/account/profile' component={AccountProfilePage}/>
      {cohorts.collectors_edition && <PrivateRoute exact path='/:lang/account/shipping-address' component={AccountShippingPage}/>}
      <PrivateRoute exact path='/:lang/account/rewards' component={AccountDetailsRewards}/>
      {showDownloadableRewards && <PrivateRoute exact path='/:lang/account/downloadable-rewards' component={AccountDownloadableRewardsPage}/>}
      <PrivateRoute exact path='/:lang/account/rewards-consumed' component={AccountDetailsRewardsConsumed}/>
      <PrivateRoute exact path='/:lang/account/rewards-redeemed' component={AccountDetailsRewardsRedeemed}/>
      {userCanGift && <PrivateRoute exact path='/:lang/account/gifts' component={AccountGiftsPage}/>}
      {userCanGift && <PrivateRoute exact path='/:lang/account/gifts-given' component={AccountDetailsGiftsGiven}/>}
      <PrivateRoute exact path='/:lang/account/gifts-received' component={AccountDetailsGiftsReceived}/>
      {/*<PrivateRoute exact path='/:lang/account/download-client' component={DownloadClient}/>*/}
      {/*<PrivateRoute exact path='/:lang/account/forum-badges' component={AccountDetailsForumBadge}/>*/}
      <PrivateRoute exact path='/:lang/account/security-settings' component={AccountDetailsSecurity}/>
      <PrivateRoute exact path='/:lang/account/2fa-device' component={AccountDetails2FA}/>
      <PrivateRoute exact path='/:lang/account/order-history' component={AccountDetailsOrderHistory}/>
      <PrivateRoute exact path='/:lang/account/discounts' component={AccountDetailsDiscounts}/>
      <PrivateRoute exact path='/:lang/account/order-history/:id' component={AccountDetailsOrderHistoryDetail}/>
      <PrivateRoute exact path='/:lang/account/wallets' component={AccountDetailsWallets}/>
      <PrivateRoute exact path='/:lang/account/wallets/:id' component={AccountDetailsWalletsDetail}/>
      {/*<PrivateRoute exact path='/:lang/account/layaway' component={AccountDetailsLayaway}/>*/}
      {/*<PrivateRoute exact path='/:lang/account/layaway/:id' component={AccountDetailsLayawayDetail}/>*/}
      <PrivateRoute exact path='/:lang/account/redeem-codes' component={AccountDetailsRedeemCodes}/>
      {/*<PrivateRoute exact path='/:lang/account/guild-invites' component={AccountDetailsGuildInvites}/>*/}
      {/*<PrivateRoute exact path='/:lang/account/guild-applications' component={AccountDetailsGuildApplications}/>*/}
      {/*<PrivateRoute exact path='/:lang/account/affiliate-details' component={AccountDetailsAffiliateDetails}/>*/}
      {/*<PrivateRoute exact path='/:lang/account/twitch-management' component={AccountTwitchManagement}/>*/}
      {/*  <PrivateRoute exact path='/:lang/account/discord-management' component={AccountDiscordManagement}/>*/}
      {/*<PrivateRoute exact path='/:lang/account/teams' component={AccountDetailsTeams}/>*/}
      <PrivateRoute exact path='/:lang/account/codes' component={AccountDetailsCodes}/>
      <PrivateRoute exact path='/:lang/account/vip-status' component={AccountVipPage}/>
      <PrivateRoute exact path='/:lang/account/additional-downloads' component={AccountAdditionalDownloadsPage}/>
      <Redirect to='/:lang/account/profile'/>
    </Switch>
  )
}

const mapStateToProps = state => {
  return {
    cohorts: state.cohorts,
    accountRegions: state.user.accountRegions,
  }
}


class AccountDetails extends React.Component {

  render() {

    const { cohorts, accountRegions } = this.props

    return (

        <Page>

          <div className="AccountDetails">
            <div className="AccountDetails__menu">
              <AccountDetailsMenu/>
            </div>

            <div className="AccountDetails__page">
              <AccountRoutes cohorts={cohorts} accountRegions={accountRegions}/>
            </div>
          </div>

        </Page>

    )
  }
}

export default connect(mapStateToProps)(AccountDetails)
