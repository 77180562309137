import React from 'react'
import { api } from '../utilities/api'
import { Button, Dimmer, Form, Loader, Message, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = state => {
  return {
    session: state.session,
    user: state.user.userProfile
  }
}

class AccountDetailsSecurity extends React.Component {

  state = {
    loading: false,
    newPassword: '',
    confirmPassword: '',
    sessions: [],
    message: '',
    otp: ''
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    const sessions = await api.get(`/user/sessions`)
    this.setState({
      sessions: _.sortBy(sessions.data, 'date_created').map(s => {
        s.date = (new Date(s.date_created)).toDateString()
        s.expiration = (new Date(s.date_expires)).toDateString()
        return s
      }),
    })
  }

  handleChange = (e, { name, value } = {}) => {
    this.setState({ [name]: value, message: '' })
  }

  handlePasswordChange = async () => {
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        message: strings.getString('PASSWORDS_MUST_MATCH', 'Passwords must match'),
      })
    } else if (this.state.newPassword.length < 8) {
      this.setState({
        message: strings.getString("PASSWORDS_NOT_LONG_ENOUGH", 'Your password must be at least 8 characters long.')
      })
    } else {
      this.setState({
        message: '',
      })

      try {
        await api.put('/user/password', {
          password: this.state.newPassword,
          totp: _.isEmpty(this.state.otp) ? null : this.state.otp
        })
        await swal(`${strings.getString('ACCOUNT_CHANGE_PASSWORD_SUCCESS', 'Successfully Updated Password')}`, {
          icon: 'success',
        })
        this.setState({
          newPassword: '',
          confirmPassword: '',
          otp: ''
        })
      } catch {
        swal(`${strings.getString('ACCOUNT_UPDATE_PASSWORD_ERROR', 'Uh Oh! Unable to Update Password')}`, {
          icon: 'error',
        })
      }
    }
  }


  removeSession = (r) => {
    return async () => {
      const val = await swal({
        title: strings.getString('ACCOUNT_REMOVE_SESSION', 'Remove Session'),
        buttons: {
          cancel: strings.getString('CANCEL', 'Cancel'),
          consume: {
            text: strings.getString('REMOVE', 'Remove'),
            value: 'remove',
            closeModal: false
          },
        },
        content: (
          <div style={{ textAlign: 'left' }}>
            <p>
              {strings.getString('ACCOUNT_REMOVE_SESSION_INFO', 'Removing the session with prevent any signed in device using the session from continuing.')}
            </p>
          </div>
        ),
      })

      if (val === 'remove') {
        try {
          await api.delete(`/user/sessions/${r.session_id}`)
          await swal(`${strings.getString('ACCOUNT_REMOVE_SESSION_SUCCESS', 'Successfully Removed Session.')}`, {
            icon: 'success',
          })
          await this.getContent()
        } catch (err) {
          swal(`${strings.getString('ACCOUNT_DETAILS_SECURITY_REMOVED_ERROR', 'Uh Oh! Unable to remove session.')}`, {
            icon: 'error',
          })
        }
      }
    }
  }

  render() {

    const { loading, sessions, message } = this.state
    const { session, user } = this.props

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('CHANGE_PASSWORD', 'Change Password')}</h1>

        <Form onSubmit={this.handlePasswordChange}>
          <Form.Field>
            <Form.Input required autoComplete="off" type="password"
                        label={strings.getString('NEW_PASSWORD', 'New Password')}
                        name='newPassword' value={this.state.newPassword}
                        onChange={this.handleChange.bind(this)}/>
          </Form.Field>
          <Form.Field>
            <Form.Input required autoComplete="off" type="password"
                        label={strings.getString('CONFIRM_PASSWORD', 'Confirm Password')}
                        name='confirmPassword' value={this.state.confirmPassword}
                        onChange={this.handleChange.bind(this)}/>
          </Form.Field>

          {user.has_otp &&
            <Form.Field>
              <Form.Input required autoComplete="off" type="number"
                        label={strings.getString('2FA_VERIFICATION_CODE', '2-Step Verification Code')}
                        name='otp' value={this.state.otp}
                        onChange={this.handleChange.bind(this)}/>
            </Form.Field>
          }

          {!_.isEmpty(message) ? (<Message color="yellow">{message}</Message>) : ''}

          <Button className="Button__ACE" type='submit' primary>{strings.getString('CHANGE_PASSWORD', 'Change Password')}</Button>
        </Form>

        <h1>{strings.getString('ACTIVE_SESSIONS', 'Active Sessions')}</h1>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('DATE', 'Date')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('EXPIRATION', 'Expiration')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('NETWORK', 'Network')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('ASSOCIATED_AFFILIATE', 'Associated Affiliate')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('ACTIONS', 'Actions')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {sessions.map(r => (
              <Table.Row key={r.session_id}>
                <Table.Cell>{r.date}</Table.Cell>
                <Table.Cell>{r.expiration}</Table.Cell>
                <Table.Cell>{r.network}</Table.Cell>
                <Table.Cell>{r.affiliate_name ? r.affiliate_name : 'None'}</Table.Cell>
                <Table.Cell>
                  <Button size='mini' compact primary content={strings.getString('REMOVE', 'Remove')}
                          onClick={this.removeSession(r)}
                          disabled={_.result(session, 'token') === r.session_id}/>
                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
            </FixedTable>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsSecurity)
