import React from 'react'
import Button from '../../../componentLibrary/Fragments/Button'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import assets from '../../../assets'
import './styles.scss'
import strings from '../../../strings'
import moment from 'moment';
import _ from 'lodash';
import swal from '@sweetalert/with-react'
import {api} from "../../../utilities/api";


class Masthead extends React.Component {

  // async componentDidMount() {

  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';

  //     window.onYouTubeIframeAPIReady = this.loadVideo;

  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  
  //   } else {
  //     this.loadVideo();
  //   }

  // }

  // loadVideo = () => {
  //   const videoId = "yWJnS8ZiOsA"

  //   this.player = new window.YT.Player(`youtube-player-${videoId}`, {
  //     videoId,
  //     playerVars: {
  //       controls: 0,
  //       start: 6,
  //       cc_load_policy: 0,
  //       iv_load_policy: 3,
  //     },
  //     events: {
  //       onReady: this.onPlayerReady,
  //       onStateChange: (e) => {
  //         if (e.data === window.YT.PlayerState.ENDED) e.target.loadVideoById({ videoId, startSeconds: 6, endSeconds: 112 })
  //         if (e.data === window.YT.PlayerState.PLAYING) e.target.setVolume(0)
  //       }
  //     },

  //   });
  // };

  // onPlayerReady = event => {
  //   event.target.mute()
  //   event.target.playVideo()
  // };

  
  async handleTrialPopup() {
    try {
      const val = await swal({
        icon: "warning",
        title: strings.getString('TRIAL_ACTIVATE_TITLE', 'Activate Free Trial'),
        content: (
            <div>
              {strings.getString('TRIAL_ACTIVATE', 'Activate your free 10 day trial now?')},
            </div>
        ),
        buttons: {
          continue: 'Yes',
          cancel: 'No'
        }
      })
      if (val === "continue") {
        await api.post('/ks/artcraft')
        swal({
            icon:'success',
            title: strings.getString('TRIAL_HAS_BEEN_ACTIVATED', 'Your 10 day trial has been activated!')
        }).then(okay => {
          if (okay) {
            window.location.href = "/account/download-client"
          }
        })
      }
    } catch(err) {
      swal({
            icon: 'error',
            title: strings.getString('TRIAL_ACTIVATION_ERROR_OCCURRED', 'Error Activating Trial')
          }
      )
    }
  }

  render() {
    const { isLoggedIn, canPlayCrowfall, playerData, isTrialExpired } = this.props

    // const isTrial = _.result(playerData, 'type') === 'trial';
    // const shouldCode = _.result(playerData, 'type') === 'unknown';
    const paidUser = _.result(playerData, 'type') === 'paid';
    // const trialRemainingCalc = isTrial ? moment.duration(playerData.time_remaining_seconds, 'seconds').humanize() : '';
    // const trialRemaining = `${trialRemainingCalc} remaining in your Trial!`;

      let playNowText
      let playNowLink
      let tryPlayButton
      if (canPlayCrowfall) {
          playNowLink = "/account/download-client"
          playNowText = strings.getString("PLAY_NOW", "Play Now")
          tryPlayButton = <LocLink to={playNowLink}>
              <Button large fill green>
                  <div>{playNowText}</div>
              </Button>
          </LocLink>
      } 
      else if (!canPlayCrowfall  && !isTrialExpired) {
          // playNowLink = "/account/redeem-codes?code=artcraft"
          // playNowText = strings.getString("TRY_FREE", "Try Free")
          // tryPlayButton = <div onClick={()=>this.handleTrialPopup()}>
          //     <Button large fill green label={playNowText} />
          // </div>
      } else {
          playNowLink = "/store?category=064b6196-98fe-46c7-94d4-9485dad599de&record-to-liquid=true"
          playNowText = strings.getString("PLAY_NOW", "Play Now")
          // tryPlayButton = <LocLink to={playNowLink}>
          //     <Button large fill green>
          //         <div>{playNowText}</div>
          //     </Button>
          // </LocLink>
      }
    
    let starBurstLink = 'New message page link'
    
    return (
      <div className="Masthead">
        {/*<div className="Masthead__starburst_mobile_display">*/}
        {/*    <LocLink to={starBurstLink}>*/}
        {/*      <div className="Masthead__starburst">*/}
        {/*          <img src={assets.staticImageUrls.STAR_MOBILE_HEADER}/> */}
        {/*        <div className="Masthead__starburst_header">*/}
        {/*          <span className="Masthead__starburst_phrase">New Messaging</span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </LocLink> */}
        {/*</div>*/}
        
        {/*<div className="Masthead__starburst_display">*/}
        {/*    <LocLink to={starBurstLink}>*/}
        {/*      <div className="Masthead__starburst">*/}
        {/*        <div className="Masthead__starburst_header">*/}
        {/*          <span className="Masthead__starburst_phrase">New Messaging</span>*/}
        {/*          <img src={assets.staticImageUrls.STAR_LEFT}/>*/}
        {/*          <img className="Masthead__starburst_header--grow" src={assets.staticImageUrls.STAR_MIDDLE}/>*/}
        {/*          <img src={assets.staticImageUrls.STAR_RIGHT}/>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        
        {/*      <div className="Masthead__starburstBody">*/}
        {/*        <span className="Masthead__starburst_body_phrase"></span>*/}
        {/*        <img src={assets.staticImageUrls.STAR_BODY_LEFT}/>*/}
        {/*        <img className="Masthead__starburst_body--grow" src={assets.staticImageUrls.STAR_BODY_MIDDLE}/>*/}
        {/*        <img className="Masthead__starburst_body--small" src={assets.staticImageUrls.STAR_BODY_RIGHT}/>*/}
        {/*      </div>*/}
        
        {/*      <div className="Masthead__starburstFooter">*/}
        {/*        <img src={assets.staticImageUrls.STAR_FOOTER_LEFT}/>*/}
        {/*        <img className="Masthead__starburst_footer--grow" src={assets.staticImageUrls.STAR_FOOTER_MIDDLE}/>*/}
        {/*        <img className="Masthead__starburst_footer--small" src={assets.staticImageUrls.STAR_FOOTER_RIGHT}/>*/}
        {/*      </div>*/}
        {/*    </LocLink>*/}
        {/*</div>*/}
        
        <div className="Masthead__overlay">

          <div className="Masthead__overlayTop">

            <div className="Masthead__logo">
              <img alt="crowfall_logo" src={assets.staticImageUrls.LOGO_CROWFALL}/>
            </div>

            <div className="Masthead__description">

              <div className="Masthead__description-blur"/>

              <div className="Masthead__subheader" dangerouslySetInnerHTML={{ __html: strings.getString("CROWFALL_TAGLINE_TWO", "MASSIVE SIEGE WARS. FACTIONAL BATTLES. WORLD DOMINATION. PVP YOUR WAY. EVERYDAY. CROWFALL.") }}/>
            </div>

              {/*{!isLoggedIn &&*/}
              {/*  <div className="Masthead__buttons--single">*/}
              {/*    <LocLink to="/register?t=sign_up&p=masthead_cta"><Button onclick={this.recordToLiquidAdvertising} fill large green label={strings.getString("TRY_FREE", "Try Free")}/></LocLink>*/}
              {/*  </div>*/}
              {/*}*/}
              {/*{isLoggedIn &&*/}
              {/*    <div className="Masthead__buttons--single">*/}
              {/*        {tryPlayButton}*/}
              {/*    </div>*/}
              {/*}*/}
          </div>

        </div>

        <div className="Masthead__media">

          {/* <div id="youtube-player-yWJnS8ZiOsA"></div> */}
          {/*<iframe*/}
          {/*  loading="lazy"*/}
          {/*  title="Crowfall-MastHead-Brand-Video"*/}
          {/*  src="https://www.youtube.com/embed/yWJnS8ZiOsA?autoplay=1&end=67&controls=0&mute=1&loop=1&playlist=yWJnS8ZiOsA&modestbranding=1"*/}
          {/*  frameBorder="0"*/}
          {/*/>*/}
          <img alt="masthead" src={assets.staticImageUrls.MASTHEAD_IMG}/>

        </div>

      </div>
    )
  }
}

export default Masthead
