import React from 'react'
import _ from 'lodash'
import strings from '../../../strings'
import contentful from '../../../utilities/contentful'
import PageNotFound from '../../../componentLibrary/Models/PageNotFound'
import Page from '../../../componentLibrary/Models/Page'
import ECSFooter from '../ECSFooter'
import BackToECSButton from '../BackToECSButton'
import './styles.scss'


class ECSContentPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fetched: false,
            page: {},
            noContentFound: false,
        }
    }

    componentDidMount() {
        this.getContent()
    }

    async getContent() {
        const { slug } = this.props.match.params
        const content = await contentful.getEntries("eternalChampionsPage", `fields.slug=${slug}`)
        if (_.isEmpty(content)) {
            this.setState({ noContentFound: true })
        } else {
            let page = content[0]
            page.bannerAssetUrl = _.result(page, 'bannerAsset.assetUrl')
            this.setState({ page })
        }
        this.setState({ fetched: true })
    }


    render() {

        const { fetched, page, noContentFound } = this.state
        const { bannerAssetUrl, title, tagline, body, footer } = page
        const header = { banner: bannerAssetUrl, title }

        return (
            <React.Fragment>
                {fetched && noContentFound &&
                    <PageNotFound returnLabel={strings.getString("BACK_TO_ETERNAL_CHAMPIONS", "Back to Eternal Champions")} returnLink="/eternal-champions"/>
                }

                {fetched && !noContentFound &&
                    <Page header={header} pageFooter={<ECSFooter/>}>
                        <div className="ECSContentPage">
                            {/* <div className="ECSContentPage__title">{title}</div> */}
                            {!_.isEmpty(tagline) && <div className="ECSContentPage__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>}
                            {!_.isEmpty(body) && <div className="ECSContentPage__body" dangerouslySetInnerHTML={{  __html: body }}/>}
                            {!_.isEmpty(footer) && <div className="ECSContentPage__footer" dangerouslySetInnerHTML={{ __html: footer }}/>}
                        
                            <br/>
                            <BackToECSButton/>
                        </div>
                    </Page>
                }
            </React.Fragment>
        )
    }
}

export default ECSContentPage