import React from 'react'
import assets from '../../../assets'
import './styles.scss'

const CrowfallPartnerRibbon = (props) => {

    const { partnerLogo, largePartnerLogo } = props
    let className = "CrowfallPartnerRibbon"
    if (largePartnerLogo) className += " CrowfallPartnerLogo--largePartnerLogo"

    return (
        <div className={className}>
            <img className="CrowfallPartnerRibbon__partnerLogo" src={partnerLogo} alt="Crowfall-partner-logo"/>
            <img className="CrowfallPartnerRibbon__divider" src={assets.staticImageUrls.PARTNER_IMAGE_SPACER_X} alt="X"/>
            <img className="CrowfallPartnerRibbon__crowfallLogo" src={assets.staticImageUrls.LOGO_CROWFALL} alt="Crowfall-logo"/>
        </div>
    )
}

export default CrowfallPartnerRibbon