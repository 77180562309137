import {
    ADD_WAR_STORIES,
    SEARCH_WAR_STORIES,
    PAGINATE_WAR_STORIES,
    CLEAR_WAR_STORIES_SEARCH_CRITERIA,
    CLEAR_WAR_STORIES,
} from '../actionTypes'

import contentful from '../../utilities/contentful'
import _ from 'lodash'
import moment from 'moment'


export const fetchWarStories = () => {
    return async function (dispatch) {
        const add = (warStories) => {
            const newWarStories = warStories.map(warStory => {
                warStory.formattedPublishDate = moment(warStory.publishDate).format('dddd, MMMM D, YYYY')
                warStory.monthYear = moment(warStory.publishDate).format('MMMM YYYY')
                warStory.thumbUrl = _.result(warStory, 'thumbnail.assetUrl')
                warStory.imageUrl = _.result(warStory, 'imageAsset.assetUrl')
                warStory.categoryName = _.result(warStory, 'category.name')
                warStory.categoryDate = _.result(warStory, 'category.publishDate')
                return warStory
            })
            dispatch(addWarStories(newWarStories))
        }
        const options = {
            contentType: 'warStory',
            match: null,
            language: undefined,
            extra: null,
            skip: 0,
            limit: 50,
        }
        contentful.getPaginatedEntries(options, add)
    }
}

const addWarStories = warStories => {
    return (dispatch) => {
        dispatch({
            type: ADD_WAR_STORIES,
            payload: warStories
        })
        dispatch(searchWarStories())
    }
}

export const searchWarStories = criteria => {
    return (dispatch) => {
        dispatch({
            type: SEARCH_WAR_STORIES,
            payload: criteria
        })
        dispatch(paginateWarStories(1))
    }
}

export const clearWarStoriesSearchCriteria = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_WAR_STORIES_SEARCH_CRITERIA
        })
        dispatch(paginateWarStories(1))
    }
}

export const paginateWarStories = (page) => ({
            type: PAGINATE_WAR_STORIES,
            page
})

export const clearWarStories = () => ({
    type: CLEAR_WAR_STORIES
})