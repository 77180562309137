import {
    FETCH_GUILD_CONFIGURATION,
    FETCH_GUILDS,
    SORT_GUILDS,
    PAGINATE_GUILDS,
    CLEAR_GUILDS_SORT_CRITERIA,
    CLEAR_GUILDS,
    SET_SELECTED_GUILD,
    CLEAR_SELECTED_GUILD,
} from '../actionTypes'

const initialState = {
    configuration: {},
    isFetched: false,
    guildsSubset: [],
    sortCriteria: null,
    sorted: [],
    paginationCriteria: {
        limit: 10,
        page: 1
    },
    paginated: [],
    selectedGuild: null,
}

const guilds = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_GUILD_CONFIGURATION:
            return {
                ...state,
                configuration: action.payload,
            }
        case FETCH_GUILDS:
            return {
                ...state,
                guildsSubset: action.payload,
                isFetched: true,
            }
        case SORT_GUILDS:
            const sortCriteria = action.payload === undefined ? state.sortCriteria : action.payload
            const sorted = sortCriteria ? sortGuilds([ ...state.guildsSubset ], sortCriteria) : state.guildsSubset
            return {
                ...state,
                ...{ sortCriteria, sorted }
            }
        case PAGINATE_GUILDS:
            const limit = state.paginationCriteria.limit
            const page = action.payload ? action.payload : state.paginationCriteria.page
            const paginated = paginate(state.sorted, limit, page)
            return {
                ...state,
                paginationCriteria: {
                    limit,
                    page
                }, 
                paginated
            }
        case CLEAR_GUILDS_SORT_CRITERIA:
            return {
                ...state,
                sortCriteria: initialState.sortCriteria,
                sorted: state.guildsSubset
            }
        case SET_SELECTED_GUILD:
            return {
                ...state,
                selectedGuild: action.payload
            }
        case CLEAR_SELECTED_GUILD:
            return {
                ...state,
                selectedGuild: null
            }
        case CLEAR_GUILDS:
            return {
                ...initialState,
                configuration: state.configuration,
            }
        default:
            return state
    }
}

function sortGuilds(guilds, criterion) {
    const { type, method } = criterion
    function compare(a, b) {
        if (parseInt(a[`${type}`]) < parseInt(b[`${type}`])) {
            return method === 'ascending' ? -1 : 1
        } else if (parseInt(a[`${type}`]) > parseInt(b[`${type}`])) {
            return method === 'ascending' ? 1 : -1
        } else {
            return 0
        }
    }
    return guilds.sort(compare)

}

function paginate(list, limit, page) {
    if (limit === null) return list
    const limitInt = parseInt(limit)
    const start = limitInt * (page -1)
    const end = start + limitInt
    return list.slice(start, end)
}

export default guilds