/*

TitleBar: FRAGMENT
PROPERTY  DEFAULT  REQUIREMENT  TYPE     DESCRIPTION
title              required     String   Title to be displayed above graphic.
h3        false    optional     Boolean  Font size decreases.
small     false    optional     Boolean  decreases bar height to 60% of its original size.

*/



import React from 'react'
import GoldBar from '../GoldBar'
import './styles.scss'


const TitleBar = (props) => {

    const { title, h3, small } = props
    let className = 'TitleBar'
    if (h3) className += ' TitleBar--h3'

    return (
        <div className={className}>
            <div className='TitleBar__title'>{title}</div>
            <GoldBar h3={small}/>
        </div>
    )
}

export default TitleBar