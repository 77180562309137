import React from 'react'
import assets from '../../../assets'
import './styles.scss'


const GoldBar = (props) => {
    const { h3, vertical } = props
    let className = 'GoldBar'
    let backgroundImg = null

    if (!h3 && !vertical) {
        backgroundImg = assets.staticImageUrls.TITLE_BAR_CENTER
    } else if (h3 && !vertical) {
        className += ' GoldBar--h3'
        backgroundImg = assets.staticImageUrls.TITLE_BAR_CENTER_120X10
    } else if (!h3 && vertical) {
        className += ' GoldBar--vertical'
        backgroundImg = assets.staticImageUrls.TITLE_BAR_CENTER_VERTICAL
    } else if (h3 && vertical) {
        className += ' GoldBar--h3Vertical'
        backgroundImg = assets.staticImageUrls.TITLE_BAR_CENTER_VERTICAL120X10
    }
    
    let imgAssets = {
        left: vertical ? assets.staticImageUrls.TITLE_BAR_LEFT_VERTICAL : assets.staticImageUrls.TITLE_BAR_LEFT,
        right: vertical ? assets.staticImageUrls.TITLE_BAR_RIGHT_VERTICAL : assets.staticImageUrls.TITLE_BAR_RIGHT,
        accent: assets.staticImageUrls.TITLE_BAR_ACCENT,
    }

    return (
        <div className={className}>
            <img src={imgAssets.left} alt='Gold Bar graphic' className='GoldBar__img' />
            <img src={imgAssets.accent} alt='Gold Bar graphic' className='GoldBar__centerImg' />
            <div className='GoldBar__bar' style={{backgroundImage: `url(${backgroundImg})`}}></div>
            <img src={imgAssets.right} alt='Gold Bar graphic' className='GoldBar__img' />
        </div>
    )
}

export default GoldBar