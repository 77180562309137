import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const RulesOfConduct = () => (
  <Page contentId={assets.contentfulIds.RULES_OF_CONDUCT}>
  </Page>
)

export default RulesOfConduct

