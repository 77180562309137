import React from 'react'
import LocLink from '../../../../componentLibrary/Elements/LocLink'
import Accordion from '../../../../componentLibrary/Elements/Accordion'
import AccordionItem from '../../../../componentLibrary/Elements/AccordionItem'
import TitleBar from '../../../../componentLibrary/Fragments/TitleBar'
import ContentBox from '../../../../componentLibrary/Elements/ContentBox'

import './styles.scss'

class FaqContent extends React.Component {

    componentDidMount() {
        const { selected } = this.props
        if (selected) {
            if ('scrollRestoration' in window.history) {
                window.history.scrollRestoration = 'manual';
            }
            window.requestAnimationFrame(() => {
                const { contentful_id } = selected
                const element = document.getElementById(contentful_id)
                element.scrollIntoView()
                window.scrollBy(0, -110)
            })
        }
    }

    render() {

        let { categories, selected: selectedSubCategory } = this.props
        selectedSubCategory = selectedSubCategory || {}

        return (
            <div className='FaqContent'>
                {categories.map(category => {
                    const subCategories = category.subCategories.filter(subCategory => { return subCategory !== null }) || []
                    const selectedIndex = subCategories.reduce(( acc, subCategory, i) => {
                        if (subCategory.contentful_id ===  selectedSubCategory.contentful_id) {
                            acc = i
                        } return acc
                    }, -1)
                    const { title, contentful_id } = category
                    return (
                        <div key={contentful_id}>
                            <TitleBar title={title.toUpperCase()} />
                            <div className='FaqContent__categoryBox'>
                                <ContentBox accent='stoneborn'>
                                    <Accordion selectedIndex={selectedIndex}>
                                        {subCategories.map((subCategory) => {
                                            const questions = subCategory.relatedQuestions.filter(question => { return question !== null}) || []
                                            const { contentful_id, title, slug } = subCategory
                                            let selectedId = null
                                            if (contentful_id === selectedSubCategory.contentful_id) selectedId = contentful_id
                                            return (
                                                <AccordionItem key={contentful_id} label={title} arrowLeft selectedId={selectedId}>
                                                    <div id={contentful_id} className='FaqContent__accordionItem'>
                                                        {questions && questions.map(questionRelated => {
                                                            const { contentful_id, question, description } = questionRelated || ''
                                                            return (
                                                                <div className='FaqContent__questionAnswer' key={contentful_id}>
                                                                    <LocLink to={`/faq/${slug}/${contentful_id}`}>
                                                                        <div className='FaqContent__question'>{question}</div>
                                                                        <div className='FaqContent__answer' dangerouslySetInnerHTML={{ __html: description }}/>
                                                                    </LocLink>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </AccordionItem>
                                            )
                                        })}
                                    </Accordion>
                                </ContentBox>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default FaqContent
