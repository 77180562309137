import React from 'react'
import { Redirect } from 'react-router-dom'
import { api } from '../utilities/api'
import { Button, Dimmer, Form, Loader, Message, Pagination, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import { acceptUserGuildInvite, declineUserGuildInvite, fetchUserGuildInvites } from '../redux/actions/user'
import { fetchGuildDetails } from '../redux/actions/guild-management'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = (state) => {
  return {
    language: state.language,
    user: state.user.userProfile,
    guildId: state.guildManagement.guildId,
    guildRank: state.guildManagement.guildRank,
    guildName: state.guildManagement.guild.displayName,
    accountGuildInvites: state.user.guildInvites
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchAccountGuildInvites: () => dispatch(fetchUserGuildInvites()),
    fetchGuild: () => dispatch(fetchGuildDetails()),
    acceptGuildInvite: (guildInviteId) => dispatch(acceptUserGuildInvite(guildInviteId)),
    declineGuildInvite: (guildInviteId) => dispatch(declineUserGuildInvite(guildInviteId))
  }
}

class AccountDetailsGifts extends React.Component {

  state = {
    loading: false,
    records: [],
    filteredRecords: [],
    filter: '',
    page: 1,
    totalPages: 1,
    pageSize: 15,
    targetUsername: '',
    quantity: 1,
    redirect: null,
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    await this.props.fetchAccountGuildInvites()
    const records = this.props.accountGuildInvites
    this.setState({
      records,
      filteredRecords: records,
      totalPages: Math.floor(this.state.records.length / this.state.pageSize) + 1,
      page: 1,
      quantity: 1
    })
  }

  handleFilter(e, { name, value }) {
    this.setState({ [name]: value })
    const search = _.lowerCase(value)
    const filtered = this.state.records.filter(r => {
      return _.lowerCase(r.invited_by).includes(search) || _.lowerCase(r.guild.display_name).includes(search) || _.lowerCase(r.guild.name).includes(search)
    })
    this.setState({
      filteredRecords: filtered,
      totalPages: Math.floor(filtered.length / this.state.pageSize) + 1,
      page: 1,
    })
  }

  handlePageChange(e, { activePage }) {
    this.setState({ page: activePage })
  }

  async handleInvite(invite) {
    const { guildId: currentGuildId, guildName: currentGuildName, guildRank, declineGuildInvite } = this.props

    const val = await swal({
      title: strings.getString('RESPOND_TO_INVITE', 'Respond to Invite'),
      buttons: {
        cancel: strings.getString('CANCEL', 'Cancel'),
        decline: {
          color: 'red',
          text: strings.getString('DECLINE', 'Decline'),
          value: 'decline',
          closeModal: false
        },
        accept: {
          text: currentGuildId ? strings.getString('ACCOUNT_GUILD_INVITES_LEAVE_AND_ACCEPT', 'Leave Guild and Accept') : strings.getString('ACCEPT', 'Accept'),
          value: 'accept',
          closeModal: false
        },
      },
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            {strings.getString('ACCOUNT_GUILD_INVITES_PRE', 'Accepting the invite from')} <strong>{invite.invited_by}</strong> {strings.getString('ACCOUNT_GUILD_INVITES_POST', 'will make you a member of')}: <strong>{_.result(invite, 'guild.display_name')}</strong>.
          </p>
        </div>
      ),
    })

    if (val === 'decline') {
      try {
        await declineGuildInvite(invite.guild_invite_id)
        await swal(`${strings.getString('ACCOUNT_GUILD_INVITE_REJECTED', 'Successfully declined invite to join')} ${_.result(invite, 'guild.display_name')}.`, {
          icon: 'success',
        })
        await this.getContent()
      } catch (err) {
        const responseMessage = _.result(err.response.data, 'Message')
        await swal(`${responseMessage}`, {
          icon: 'error'
        })
        await this.getContent()
      }
    } else if (val === 'accept') {
      if (currentGuildId && guildRank === 1) {
        await this.handleGuildLeaderInviteAccept(invite, currentGuildName)
      } else {
        await this.handleNonLeaderInviteAccept(invite, currentGuildId)
      }
    }
  }

  async handleGuildLeaderInviteAccept(invite, currentGuildName) {
    const val = await swal({
      icon: 'warning',
      content: (
        <div>
          <div 
            dangerouslySetInnerHTML={{ __html: strings.getString("ACCOUNT_GUILD_CURRENTLY_A_LEADER", `You are currently the leader of the Guild, ${currentGuildName}`, {guildName: currentGuildName}) }}
            style={{ fontSize: '27px', lineHeight: '32px', fontWeight: 'bold', textAlign: 'center', color: 'rgba(0,0,0,.65)', paddingBottom: '20px' }}
          />
          <p style={{ textAlign: 'left' }}>{strings.getString("GUILD_TRANSFER_LEADERSHIP_BEFORE_LEAVING", "You must transfer the leadership of your Guild before you can join another.")}</p>
        </div>
      ),
      buttons: {
        cancel: strings.getString('CANCEL', 'Cancel'),
        transfer: {
          color: 'red',
          text: strings.getString('TRANSFER_LEADERSHIP', 'Transfer Leadership'),
          value: 'transferLeadership',
        },
      }
    })
    if (val === 'transferLeadership') {
      this.setState({ redirect: `/${this.props.language}/guild/settings`})
    }
  }

  async handleNonLeaderInviteAccept(invite, currentGuildId) {
    const { acceptGuildInvite } = this.props
    if (currentGuildId) {
      await api.post(`/user/guild/${currentGuildId}/leave`)
    }

    try {
      await acceptGuildInvite(invite.guild_invite_id)
      await swal(`${strings.getString('ACCOUNT_GUILD_INVITE_ACCEPTED', 'Successfully accepted invite to join')} ${_.result(invite, 'guild.display_name')}.`, {
        icon: 'success',
      })
      await this.getContent()
      await this.props.fetchGuild()
    } catch (err) {
      console.log(err, err.response)
      swal(`${strings.getString('ACCOUNT_DETAILS_GUILD_INVITES_ACCEPT_ERROR', 'Uh Oh! Unable to accept invite.')} ${_.result(err, 'response.data.message', 'Unable to accept invite')}.`, {
        icon: 'error',
      })
    }
  }

  render() {

    const { loading, filteredRecords, filter, totalPages, page, pageSize, redirect } = this.state
    const { guildId } = this.props

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>

        {redirect && <Redirect to={redirect}/>}

        <h1>{strings.getString('GUILD_INVITES', 'Guild Invites')}</h1>
        {!guildId ? '' : (
          <Message color="yellow">{strings.getString('ACCOUNT_GUILD_INVITES_ALREADY_MEMBER', 'You are currently a member of a guild and will only be able to decline invites at this time.')}</Message>
        )}

        <Form>
          <Form.Input autoComplete="off" type="text"
                      placeholder={strings.getString('SEARCH_INVITES', 'Search Invites')}
                      name='filter'
                      value={filter}
                      onChange={this.handleFilter.bind(this)}/>
        </Form>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('GUILD', 'Guild')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('INVITED_BY', 'Invited By')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{strings.getString('ACTION', 'Action')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredRecords.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize).map(r => (
              <Table.Row key={r.guild_invite_id}>
                <Table.Cell>
                  <span style={{ fontSize: '16px' }}>{_.result(r, 'guild.display_name')}</span>
                  <br/>
                  <span style={{ color: 'rgb(150, 150,150)' }}>{(new Date(r.date_created)).toLocaleString()}</span>
                </Table.Cell>
                <Table.Cell singleLine>
                  {r.invited_by}
                </Table.Cell>
                <Table.Cell collapsing textAlign="center">
                  <Button onClick={() => this.handleInvite(r)} size="mini" compact primary content={strings.getString('RESPOND', 'Respond')}/>
                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination defaultActivePage={page} totalPages={totalPages} onPageChange={this.handlePageChange.bind(this)}/>
        </div>


        {/*</Tab>*/}

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsGifts)
