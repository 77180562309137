import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Page from '../../componentLibrary/Models/Page'
import GuildCrest from '../../componentLibrary/Elements/GuildCrest'
import Icon from '../../componentLibrary/Fragments/Icon'
import Button from '../../componentLibrary/Fragments/Button'
import Accordion from '../../componentLibrary/Elements/Accordion'
import AccordionItem from '../../componentLibrary/Elements/AccordionItem'
import AssetPicker from '../../componentLibrary/Fragments/AssetPicker'
import ColorSwatch from '../../componentLibrary/Fragments/ColorSwatch'
import options from '../../assets/guildcrest'
import { fetchGuildDetails } from '../../redux/actions/guild-management'
import { api } from '../../utilities/api'
import swal from '@sweetalert/with-react'
import './styles.scss'
import strings from '../../strings'
import ReactTooltip from "react-tooltip";

const mapStateToProps = state => {
  return {
    guild: state.guildManagement.guild,
    cohorts: state.cohorts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGuild: () => dispatch(fetchGuildDetails())
  }
}

class GuildCrestPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pattern_color: [0, 0, 0],
      pattern_color_id: 'crw',
      background_color: [255, 255, 255],
      background_color_id: 'snw',
      icon_color:  [249, 236, 55],
      icon_color_id: 'yllw',
      base_pattern: 'UI_CustomCrest_Background_001',
      base_icon: 'UI_Skill_Icon_001_002',
      icon_rotate: 0,
      icon_size: 'L',
      icon_flip: false,
      icon_pos: 'C',
      isPremiumBackground: false,
      isPremiumMainColor: false,
      isPremiumSecondaryColor: false,
      isPremiumEmblem: false,
      isPremiumEmblemColor: false,
    }

    this.resize = this.resize.bind(this)
    this.rotate = this.rotate.bind(this)
    this.mirror = this.mirror.bind(this)
    this.center = this.center.bind(this)
    this.selectPattern = this.selectPattern.bind(this)
    this.selectPatternColor = this.selectPatternColor.bind(this)
    this.selectBackgroundColor = this.selectBackgroundColor.bind(this)
    this.selectEmblem = this.selectEmblem.bind(this)
    this.selectEmblemColor = this.selectEmblemColor.bind(this)
    this.submit = this.submit.bind(this)
  }

  cycleOption(current, options) {
    const currentIndex = options.indexOf(current)
    let nextIndex = currentIndex + 1
    if (currentIndex < 0) {
      nextIndex = 1
    } else if (currentIndex >= options.length - 1) {
      nextIndex = 0
    }
    return options[nextIndex]
  }

  resize() {
    const { icon_size: current } = this.state
    const options = ['XL', 'L', 'M', 'S']
    this.setState({
      ...this.state,
      icon_size: this.cycleOption(current, options)
    })
  }

  rotate() {
    const { icon_rotate: current } = this.state
    const options = [0, 45, 90, 135, 180, 225, 270, 315]
    this.setState({
      ...this.state,
      icon_rotate: this.cycleOption(current, options)
    })
  }

  mirror() {
    const { icon_flip } = this.state
    this.setState({
      ...this.state,
      icon_flip: !icon_flip
    })
  }

  center() {
    const { icon_pos: current } = this.state
    const options = ['C', 'TC', 'TL', 'TR', 'BC']
    this.setState({
      ...this.state,
      icon_pos: this.cycleOption(current, options)
    })
  }

  selectPattern(imgName, isPremium) {
    this.setState({
      ...this.state,
      base_pattern: imgName,
      isPremiumBackground: isPremium
    })
  }

  selectPatternColor(rgb, id, isPremium) {
    this.setState({
      ...this.state,
      pattern_color: rgb,
      pattern_color_id: id,
      isPremiumMainColor: isPremium
    })
  }

  selectBackgroundColor(rgb, id, isPremium) {
    this.setState({
      ...this.state,
      background_color: rgb,
      background_color_id: id,
      isPremiumSecondaryColor: isPremium
    })
  }

  selectEmblem(imgName, isPremium) {
    this.setState({
      ...this.state,
      base_icon: imgName,
      isPremiumEmblem: isPremium
    })
  }

  selectEmblemColor(rgb, id, isPremium) {
    this.setState({
      ...this.state,
      icon_color: rgb,
      icon_color_id: id,
      isPremiumEmblemColor: isPremium
    })
  }

  async submit() {
    const { cohorts, history, guild, fetchGuild } = this.props
    const { guild_expanded_colors: hasExpandedColors, guild_expanded_symbols: hasExpandedSymbols } = cohorts
    const {
      pattern_color,
      pattern_color_id,
      background_color,
      background_color_id,
      icon_color,
      icon_color_id,
      base_pattern,
      base_icon,
      icon_rotate,
      icon_size,
      icon_flip,
      icon_pos,
      isPremiumBackground,
      isPremiumMainColor,
      isPremiumSecondaryColor,
      isPremiumEmblem,
      isPremiumEmblemColor,
    } = this.state

    const crest = {
      pattern_color,
      pattern_color_id,
      background_color,
      background_color_id,
      icon_color,
      icon_color_id,
      base_pattern,
      base_icon,
      icon_rotate,
      icon_size,
      icon_flip,
      icon_pos
    }

    const hasSelectedPremiumColor = isPremiumMainColor || isPremiumSecondaryColor || isPremiumEmblemColor
    const hasSelectedPremiumSymbol = isPremiumBackground || isPremiumEmblem

    if ((!hasExpandedColors && hasSelectedPremiumColor) || (!hasExpandedSymbols && hasSelectedPremiumSymbol)) {
      const val = await swal({
        title: strings.getString('GUILD_CREST_NEED_PREMIUM_ENTITLEMENT', 'To use these premium patterns or colors, you have to purchase the premium crest bundle first.'),
        buttons: {
          cancel: strings.getString('CANCEL', 'Cancel'),
          continue: {
            text: strings.getString('VISIT_SHOP', 'Visit Shop'),
            value: 'shopTillYouDrop',
            closeModal: true
          },
        },
      })
      if (val === 'shopTillYouDrop') {
        history.push('/store?category=242307c5-63be-4815-b3e7-2521180082c1')
      }
    } else {
      try {
        await api.put(`/user/guild/${guild.guildId}/custom-field-by-key/guild-crest`, crest)
        history.push('/guild')
        swal(strings.getString('GUILD_CREST_SUCCESS_TITLE', 'Successfully Saved Guild Crest'), { icon: 'success' })
      } catch (err) {
        swal(`${err.response ? err.response.data.message : err}`, {
          icon: 'error',
        })
      }
      fetchGuild()
    }
  }

  componentDidUpdate(prevState) {
    const { crest: prevCrest } = prevState
    const { crest: currCrest } = this.props
    if (prevCrest !== currCrest && currCrest) {
      this.setState({
        ...this.state,
        ...currCrest
      })
    }
  }

  componentDidMount() {
    const { guild } = this.props
    const { crest } = guild
    if (crest) {
      this.setState(crest)
    }
  }

  render() {
    const { guild } = this.props
    const { displayName, name } = guild
    let shortenedDisplayName = displayName.substring(0, 15)
    let hideDisplayNameTooltip = displayName.length <= 15
    const { standard_colors, pattern: patterns, icon: emblems } = options
    const basicPatterns = patterns.filter(pattern => !pattern.extended)
    const premiumPatterns = patterns.filter(pattern => pattern.extended === true)
    const basicColors = standard_colors.filter(color => !color.extended)
    const premiumColors = standard_colors.filter(color => color.extended === true)
    const basicEmblems = emblems.filter(emblem => !emblem.extended)
    const premiumEmblems = emblems.filter(emblem => emblem.extended === true)

    if(!hideDisplayNameTooltip) {
      shortenedDisplayName = shortenedDisplayName + '...'
    }
    
    const { 
      base_pattern, pattern_color_id, background_color_id, base_icon, icon_color_id, 
      icon_size, icon_flip, icon_pos, icon_rotate, 
    } = this.state

    const patternPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Crest Patterns" selected={base_pattern}>
          {basicPatterns.map(pattern => {
            const imgName = Object.keys(pattern)[0]
            const imgUrl = pattern[imgName]
            return (
              <div className="GuildCrestPage__asset GuildCrestPage__asset--icon" onClick={() => this.selectPattern(imgName, false)} key={imgName}>
                <img alt="patterns_bg" className="GuildCrestPage__patternBg" src="https://media.crowfall.com/guild/UI_CustomCrest_Backdrop_default.png"/>
                <img alt="pattern" className="GuildCrestPage__pattern" src={imgUrl}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const premiumPatternPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Premium Crest Patterns" selected={base_pattern}>
          {premiumPatterns.map(pattern => {
            const imgName = Object.keys(pattern)[0]
            const imgUrl = pattern[imgName]
            return (
              <div className="GuildCrestPage__asset GuildCrestPage__asset--icon" onClick={() => this.selectPattern(imgName, true)} key={imgName}>
                <img alt="patterns_bg" className="GuildCrestPage__patternBg" src="https://media.crowfall.com/guild/UI_CustomCrest_Backdrop_default.png"/>
                <img alt="pattern" className="GuildCrestPage__pattern" src={imgUrl}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const mainColorPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Main Color" selected={pattern_color_id}>
          {basicColors.map(color => {
            const { rgb_values, display_name} = color
            return (
              <div className="GuildCrestPage__asset" onClick={() => this.selectPatternColor(rgb_values, Object.keys(color)[0], false)} key={Object.keys(color)[0]}>
                <ColorSwatch rgb={rgb_values} key={display_name}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const premiumMainColorPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Premium Main Color" selected={pattern_color_id}>
          {premiumColors.map(color => {
            const { rgb_values, display_name, metallic } = color
            return (
              <div className="GuildCrestPage__asset" onClick={() => this.selectPatternColor(rgb_values, Object.keys(color)[0], true)} key={Object.keys(color)[0]}>
                <ColorSwatch rgb={rgb_values} key={display_name} metallic={metallic}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const secondaryColorPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Secondary Color" selected={background_color_id}>
          {basicColors.map(color => {
            const { rgb_values, display_name} = color
            return (
              <div className="GuildCrestPage__asset" onClick={() => this.selectBackgroundColor(rgb_values, Object.keys(color)[0], false)} key={Object.keys(color)[0]}>
                <ColorSwatch rgb={rgb_values} key={display_name}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const premiumSecondaryColorPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Premium Secondary Color" selected={background_color_id}>
          {premiumColors.map(color => {
            const { rgb_values, display_name, metallic } = color
            return (
              <div className="GuildCrestPage__asset" onClick={() => this.selectBackgroundColor(rgb_values, Object.keys(color)[0], true)} key={Object.keys(color)[0]}>
                <ColorSwatch rgb={rgb_values} key={display_name} metallic={metallic}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const emblemPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Emblems" selected={base_icon}>
          {basicEmblems.map(emblem => {
            const imgName = Object.keys(emblem)[0]
            const imgUrl = emblem[imgName]
            return (
              <div className="GuildCrestPage__asset GuildCrestPage__asset--icon" onClick={() => this.selectEmblem(imgName, false)} key={imgName}>
                <img alt="pattern_bg" className="GuildCrestPage__patternBg" src="https://media.crowfall.com/guild/UI_CustomCrest_Backdrop_default.png"/>
                <img alt="pattern" className="GuildCrestPage__pattern" src={imgUrl}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const premiumEmblemPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Premium Emblems" selected={base_icon}>
          {premiumEmblems.map(emblem => {
            const imgName = Object.keys(emblem)[0]
            const imgUrl = emblem[imgName]
            return (
              <div className="GuildCrestPage__asset GuildCrestPage__asset--icon" onClick={() => this.selectEmblem(imgName, true)} key={imgName}>
                <img alt="pattern_bg" className="GuildCrestPage__patternBg" src="https://media.crowfall.com/guild/UI_CustomCrest_Backdrop_default.png"/>
                <img alt="pattern" className="GuildCrestPage__pattern" src={imgUrl}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const emblemColorPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Emblem Color" selected={icon_color_id}>
          {basicColors.map(color => {
            const { rgb_values, display_name} = color
            return (
              <div className="GuildCrestPage__asset" onClick={() => this.selectEmblemColor(rgb_values, Object.keys(color)[0], false)} key={Object.keys(color)[0]}>
                <ColorSwatch rgb={rgb_values} key={display_name}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    const premiumEmblemColorPicker = (
      <div className="GuildCrestPage__picker">
        <AssetPicker label="Premium Emblem Color" selected={icon_color_id}>
          {premiumColors.map(color => {
            const { rgb_values, display_name, metallic } = color
            return (
              <div className="GuildCrestPage__asset" onClick={() => this.selectEmblemColor(rgb_values, Object.keys(color)[0], true)} key={Object.keys(color)[0]}>
                <ColorSwatch rgb={rgb_values} key={display_name} metallic={metallic}/>
              </div>
            )
          })}
        </AssetPicker>
      </div>
    )

    return (
      <Page titleName="GUILD_CREATE_CREST">
        <ReactTooltip id="registerTip" place="top" effect="solid" disable={hideDisplayNameTooltip}>
          {displayName}
        </ReactTooltip>
        <div className="GuildCrestPage">
          <div className="GuildCrestPage__preview">
            <h3 id="crestTitle" className="GuildCrestPage__guildTitle" data-tip data-for="registerTip">{shortenedDisplayName}</h3>
            <h3 className="GuildCrestPage__guildSubtitle">{name}</h3>
            <div className="GuildCrestPage__crest">
              <GuildCrest crest={this.state} />
              <GuildCrest small crest={this.state} />
            </div>
            <div className="GuildCrestPage__controls">
              <div className="GuildCrestPage__control GuildCrestPage__resize" onClick={this.resize} title={`Size: ${icon_size}`}><Icon resize/></div>
              <div className="GuildCrestPage__control GuildCrestPage__rotate" onClick={this.rotate} title={`Rotate: ${icon_rotate}`}><Icon rotate/></div>
              <div className="GuildCrestPage__control GuildCrestPage__mirror" onClick={this.mirror} title={`Mirror: ${icon_flip}`}><Icon mirror/></div>
              <div className="GuildCrestPage__control GuildCrestPage__position" onClick={this.center} title={`Position: ${icon_pos}`}><Icon position/></div>
            </div>
            <div className="GuildCrestPage__submig" onClick={this.submit}>
              <Button label="Continue" />
            </div>
          </div>
          <div className="GuildCrestPage__mobile">
            <Accordion>
              <AccordionItem label="Crest Patterns">
                {patternPicker}
                {premiumPatternPicker}
              </AccordionItem>
              <AccordionItem label="Pattern Main Color">
                {mainColorPicker}
                {premiumMainColorPicker}
              </AccordionItem>

              <AccordionItem label="Pattern Secondary Color">
                {secondaryColorPicker}
                {premiumSecondaryColorPicker}
              </AccordionItem>

              <AccordionItem label="Emblems">
                {emblemPicker}
                {premiumEmblemPicker}
              </AccordionItem>

              <AccordionItem label="Emblem Color">
                {emblemColorPicker}
                {premiumEmblemColorPicker}
              </AccordionItem>
            </Accordion>
            <div className="GuildCrestPage__scroll" onClick={() => window.scrollTo(0,0)}>
              <Button label="Back to Top"/>
            </div>
          </div>

          <div className="GuildCrestPage__column GuildCrestPage__column1">
            {patternPicker}
            {mainColorPicker}
            {secondaryColorPicker}
            {emblemPicker}
            {emblemColorPicker}
          </div>
          <div className="GuildCrestPage__column GuildCrestPage__column2">
            {premiumPatternPicker}
            {premiumMainColorPicker}
            {premiumSecondaryColorPicker}
            {premiumEmblemPicker}
            {premiumEmblemColorPicker}
          </div>
        </div>
      </Page>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuildCrestPage))
