/*

ELEMENT: ScrollToPositionButton

PROPERTY   DEFAULT        REQUIREMENT  TYPE    DESCRIPTION
label      "Back to Top"  Optional     String  
elementId  null           Required     String  Id of Element to scroll to.

*/


import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import strings from '../../../strings'
import './styles.scss'


class ScrollToPositionButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.scrollToPosition = this.scrollToPosition.bind(this)
    }
 
    scrollToPosition() { 
         const { elementId } = this.props
         document.getElementById(elementId).scrollIntoView({ behavior: "smooth" })
    }

    render() {

        const { label } = this.props

        return (
            <div className="ScrollToPositionButton" onClick={this.scrollToPosition}>
                <div className="ScrollToPositionButton__label">
                    {label ? label : strings.getString("BACK_TO_TOP", "Back To Top")}
                </div>
                <div className="ScrollToPositionButton__arrow"><FontAwesomeIcon icon="arrow-up"/></div>
            </div>
        )
    }
}

export default ScrollToPositionButton