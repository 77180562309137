/*

FRAGMENT: CrownAmount
displays Crowfall Virtual Currency amount with Crowns icon.

PROPERTY    DEFAULT  REQUIREMENT  TYPE     DESCRIPTION
amount      ""       Required     String   Crown amount's display string
flipIcon    false    Optional     Boolean  Moves Icon to sit to the left of 'amount' (default is right positioning)
isNegative  false    Optional     Boolean  Changes font color to Red
orange      false    Optional     Boolean  changes font color to orange

*/


import React from 'react'
import Icon from '../Icon'
import strings from '../../../strings'
import './styles.scss'


const CrownAmount = (props) => {
    const { amount, isNegative, flipIcon, orange, guild } = props
    const amountString = amount ? amount : '0 CRO';

    let className = "CrownAmount"
    if (flipIcon) className += " CrownAmount--flipped"
    if (isNegative) className += " CrownAmount--negative"
    if (orange) className += " CrownAmount--orange"
    if (guild) className += " CrownAmount--guild"

    let title = strings.getString("CURRENT_PERSONAL_FUNDS", `Current Personal Funds`);
    if (guild) title = strings.getString("CURRENT_GUILD_FUNDS", `Current Guild Funds`);


    return (
      <div className={className} title={`${title}: ${amountString}`}>
        <div className="CrownAmount__label">{amountString}</div>

        <div className="CrownAmount__icon">
          <Icon crownCoin/>
        </div>

      </div>
    )
  }

  export default CrownAmount
