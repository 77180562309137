import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Icon } from 'semantic-ui-react'
import './styles.scss'

const SocialButton = (props) => {
  const { twitter, facebook, mail, title, location } = props
  const { pathname } = location
  const crowfallUrl = `https://crowfall.com${pathname}`
  let type = null
  let href = null
  let className = 'SocialButton'
  if (twitter) {
    type = 'twitter'
    href = `https://twitter.com/intent/tweet?text=${title}&url=${crowfallUrl}&via=CrowfallGame`
  } else if (facebook) {
    type = 'facebook f'
    href = `https://www.facebook.com/sharer.php?u=${crowfallUrl}`
  } else if (mail) {
    type = 'mail'
    href = `mailto:?subject=${title}&body=${title} ${crowfallUrl}`
  }

  className += ` SocialButton--${type.split(' ')[0]}`

  return (
    <a href={href} target={type === 'mail' ? '' : '_blank'} rel="noreferrer">
      <div className={className}>
        {type && <Icon name={type} size="large" />}
      </div>
    </a>
  )
}

export default withRouter(connect(null, null)(SocialButton))