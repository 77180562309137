import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const EURulesOfConduct = () => (
  <Page contentId={assets.contentfulIds.EU_RULES_OF_CONDUCT}>
  </Page>
)

export default EURulesOfConduct

