import React from 'react'
import { Button, Dimmer, Form, Loader, Pagination, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import { fetchUserGifts, searchGiftsGiven, sortGiftsGiven, paginateGiftsGiven, cancelGiftGiven, clearUserGifts } from '../redux/actions/user'
import strings from '../strings'

const mapStateToProps = (state) => {
  return {
    sortCriteria: state.user.giftsGivenSortCriteria,
    sorted: state.user.giftsGivenSorted,
    paginationCriteria: state.user.giftsGivenPaginationCriteria,
    paginated: state.user.giftsGivenPaginated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGifts: () => dispatch(fetchUserGifts()),
    searchGifts: (criteria) => dispatch(searchGiftsGiven(criteria)),
    sortGifts: (criteria) => dispatch(sortGiftsGiven(criteria)),
    paginateGifts: (page) => dispatch(paginateGiftsGiven(page)),
    cancelGift: (id) => dispatch(cancelGiftGiven(id)),
    clearGifts: () => dispatch(clearUserGifts())
  }
}

class AccountDetailsGiftsGiven extends React.Component {

  state = {
    loading: false,
    searchValue: ''
  }
  handlePageChange = this.handlePageChange.bind(this)

  async componentDidMount() {
    this.setState({ loading: true })

    await this.props.fetchGifts()

    this.setState({ loading: false })
  }

  handleSearch(e, { name, value }) {
    this.setState({ [name]: value })
    const { searchGifts } = this.props
    searchGifts(this.criteria(value))
  }

  criteria(value) {
    const lowerCaseValue = _.lowerCase(value)
    return (item) => {
      if (
        _.lowerCase(item.name).includes(lowerCaseValue) ||
        _.lowerCase(item.description).includes(lowerCaseValue) ||
        _.lowerCase(item.username).includes(lowerCaseValue) ||
        _.lowerCase(item.status).includes(lowerCaseValue)
      ) {
        return true
      } else {
        return false
      }
    }
  }

  handleSort(columnName) {
    const { sortGifts, sortCriteria } = this.props
    const { value, direction } = sortCriteria

    if (value !== columnName) {
      sortGifts({ value: columnName, direction: 'ascending' })
    } else if (value === columnName) {
      const newDirection = direction === 'ascending' ? 'descending' : 'ascending'
      sortGifts({ value, direction: newDirection })
    }
  }

  handlePageChange(e, { activePage }) {
    this.props.paginateGifts(activePage)
  }

  handleCancelGift = (r) => {
    return async () => {
      const val = await swal({
        title: strings.getString('RECLAIM_GIFT', 'Reclaim Gift'),
        buttons: {
          cancel: strings.getString('CANCEL', 'Cancel'),
          consume: {
            text: strings.getString('RECLAIM', 'Reclaim'),
            value: 'reclaim',
            closeModal: false
          },
        },
        content: (
          <div style={{ textAlign: 'left' }}>
            <p>
              {strings.getString('ACCOUNT_GIFTS_RECLAIM_INFO', 'Reclaiming a gift will cancel an unclaimed gift and return the reward to your account.')}
            </p>
          </div>
        ),
      })

      if (val === 'reclaim') {
        try {
          await this.props.cancelGift(r.request_id)
          await swal(strings.getString('ACCOUNT_GIFTS_RECLAIM_SUCCESS', 'Successfully Reclaimed ' + r.name, { gift: r.name }), {
            icon: 'success',
          })
          await this.props.fetchGifts()
        } catch (err) {
          swal(strings.getString('ACCOUNT_GIFTS_RECLAIM_ERROR', 'Uh Oh! Unable to reclaim ' + r.name, { gift: r.name } ), {
            icon: 'error',
          })
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearGifts()
  }

  render() {

    const { loading, searchValue } = this.state
    const { sortCriteria, sorted, paginationCriteria, paginated } = this.props
    const totalPages = Math.ceil(sorted.length / paginationCriteria.limit)

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('GIFTS_GIVEN', 'Gifts Given')}</h1>

        <Form>
          <Form.Input autoComplete="off" type="text"
                      placeholder={strings.getString('SEARCH_GIFTS', 'Search Gifts')}
                      name='searchValue'
                      value={searchValue}
                      onChange={this.handleSearch.bind(this)}/>
        </Form>

        <FixedTable padded sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={sortCriteria.value === "username" ? sortCriteria.direction : null} onClick={() => this.handleSort("username")} >
                {strings.getString('RECIPIENT', 'Recipient')}
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sortCriteria.value === "name" ? sortCriteria.direction : null} onClick={() => this.handleSort("name")} >
                {strings.getString('RECEIVED', 'Received')}
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sortCriteria.value === "quantity" ? sortCriteria.direction : null} onClick={() => this.handleSort("quantity")} textAlign="center">
                {strings.getString('QUANTITY', 'Quantity')}
              </Table.HeaderCell>
              <Table.HeaderCell sorted={sortCriteria.value === "status" ? sortCriteria.direction : null} onClick={() => this.handleSort("status")} textAlign="center">
                {strings.getString('STATUS', 'Status')}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {paginated.map(gift => (
              <Table.Row key={gift.request_id}>
                <Table.Cell>
                  <span style={{ fontSize: '16px' }}>{gift.username}</span>
                  <br/>
                  <span style={{ color: 'rgb(150, 150,150)' }}>{(new Date(gift.date_created)).toLocaleString()}</span>
                </Table.Cell>
                <Table.Cell singleLine>{gift.name}</Table.Cell>
                <Table.Cell singleLine collapsing textAlign="center">{gift.quantity}</Table.Cell>
                <Table.Cell singleLine collapsing textAlign="center">{_.startCase(gift.status)}</Table.Cell>
                <Table.Cell collapsing textAlign="center">
                    <Button size='mini' compact primary={gift.status === 'pending'} onClick={this.handleCancelGift(gift)} content={strings.getString('RECLAIM', 'Reclaim')} disabled={gift.status !== 'pending'}/>
                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination activePage={paginationCriteria.page} totalPages={totalPages} onPageChange={this.handlePageChange}/>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsGiftsGiven)
