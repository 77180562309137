import React from 'react'
import StaticString from '../../../componentLibrary/Fragments/StaticString'
import options from '../../../assets/guildcrest'
import assets from '../../../assets.js'
import './styles.scss'

class GuildCrest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      crestImg: null
    }
    this.element = React.createRef()

    this.imgListToDict = this.imgListToDict.bind(this)
    this.buildCrest = this.buildCrest.bind(this)
  }

  imgListToDict (list) {
    return list.reduce((dict, img) => {
      const keys = Object.keys(img)
      const imgKey = keys[0]
      const imgUrl = img[imgKey]
      const extended = !!img.extended
      dict[imgKey] = {
        imgUrl,
        extended
      }
      return dict
    }, {})
  }

  buildCrest() {
    let { standard_colors } = options
    const { crest } = this.props
    const el = this.element.current
    const sv_class = el.className
    const sv_id = el.id
    const width = el.offsetWidth
    const half_width = width / 2
    const half_height = el.offsetHeight / 2
    let scale_str = ''
    const flipped = crest.icon_flip ? true : false
    let offset_x = 0
    let offset_y = 0
    if (crest.icon_pos === 'TC') {
        offset_y = -half_height / 3
    } else if (crest.icon_pos === 'BC') {
        offset_y = half_height / 3
    } else if (crest.icon_pos === 'TL') {
        offset_x = -half_width / 3
        offset_y = -half_height / 3
    } else if (crest.icon_pos === 'TR') {
        offset_x = half_width / 3
        offset_y = -half_height / 3
    }
    const center_str = " translate(" + offset_x + "," + offset_y + ") "
    if (crest.icon_size === 'L') {
        if (flipped) {
            scale_str = ' scale(-1, 1) translate(' + -width + ',0)'
        } else {
            scale_str = ' '
        }
    } else if (crest.icon_size === 'XL') {
        if (flipped) {
            scale_str = ' scale(-1.25, 1.25) translate(' + -(width - half_width / 5) + ',' + -half_height / 5 + ')'
        } else {
            scale_str = ' scale(1.25) translate(' + -half_width / 5 + ',' + -half_height / 5 + ')'
        }
    } else if (crest.icon_size === 'M') {
        if (flipped) {
            scale_str = ' scale(-0.75, 0.75) translate(' + -(width + half_width / 3) + ',' + half_height / 3 + ')'
        } else {
            scale_str = ' scale(0.75) translate(' + half_width / 3 + ',' + half_height / 3 + ')'
        }
    } else if (crest.icon_size === 'S') {
        if (flipped) {
            scale_str = ' scale(-0.5, 0.5) translate(' + -(width + half_width) + ',' + half_height + ')'
        } else {
            scale_str = ' scale(0.5) translate(' + half_width + ',' + half_height + ')'
        }
    }
    const rotate_str = 'rotate(' + (flipped ? -crest.icon_rotate : crest.icon_rotate) + ',' + half_width + ',' + half_height + ')'
    let svg_block = '<svg class="' + sv_class + '" id="' + sv_id + '" style="background-color: rgb('
        + crest.background_color[0] + ',' + crest.background_color[1] + ',' + crest.background_color[2] + ')"><defs>'
    for (let i = 0; i < standard_colors.length; i++) {
        const color_id = Object.getOwnPropertyNames(standard_colors[i])[0]
        const rgb_string = standard_colors[i][color_id]
        const svg_color_filter_block = '<filter id="' + color_id + '-colorize">'
            + '<feFlood flood-color="' + rgb_string + '" result="colorfield"></feFlood>'
            + '<feBlend mode="multiply" in="SourceGraphic" in2="colorfield"></feBlend>'
            + '<feComposite operator="in" in2="SourceGraphic"></feComposite></filter>'
        svg_block += svg_color_filter_block
    }
    const is_metal_base = ((crest.background_color_id || "").substring(0,4) === "mtl_")
    const is_metal_pattern = ((crest.pattern_color_id || "").substring(0,4) === "mtl_")
    const is_metal_icon = ((crest.icon_color_id || "").substring(0,4) === "mtl_")

    svg_block += '</defs>'
        + '<image width="100%" class="base_metallic_overlay" xlink:href="https://media.crowfall.com/guild/crestgradientbase_720.png" height="'
        + (is_metal_base ? '100' : '0')
        + '%"></image>'
        + '<image filter="url(#' + crest.pattern_color_id + '-colorize)" height="100%" width="100%" class="crest_image" id="crest_pattern" '
        + ' xlink:href="https://media.crowfall.com/guild/pattern/' + crest.base_pattern + '.png"></image>'
        + '<image width="100%" class="pattern_metallic_overlay" xlink:href="https://media.crowfall.com/guild/crestgradientpattern_720.png" height="'
        + (is_metal_pattern ? '100' : '0')
        + '%"></image>'
        + '<image filter="url(#' + crest.icon_color_id + '-colorize)" height="100%" width="100%" class="crest_image" id="crest_icon" '
        + ' transform="' + center_str + rotate_str + scale_str + '"'
        + ' xlink:href="https://media.crowfall.com/guild/icon/' + crest.base_icon + '.png"></image>'
        + '<image width="100%" class="icon_metallic_overlay" xlink:href="https://media.crowfall.com/guild/crestgradientemblem_720.png" height="'
        + (is_metal_icon ? '100' : '0')
        + '%"></image>'
        + '<image height="100%" width="100%" class="crest_image" id="crest_overlay" '
        + ' xlink:href="https://s3.amazonaws.com/artcraft-web-crowfall-media/guild/pattern/Crest_Mask.png"></image></svg>'
    return svg_block
  }

  componentDidUpdate(prevProps) {
    const { crest: prevCrest } = prevProps
    const { crest: currCrest } = this.props
    if (prevCrest !== currCrest) {
      this.setState({
        crestImg: this.buildCrest()
      })
    }
  }

  componentDidMount() {
    if (this.props.crest) {
      this.setState({
      crestImg: this.buildCrest()
    })
  }
  }

  render() {
    const { large, small, isRecruiting } = this.props
    const { crestImg } = this.state
    let className = 'GuildCrest'
    if (large) className += ' GuildCrest--large'
    if (small) className += ' GuildCrest--small'
    if (isRecruiting) className += ' GuildCrest--recruiting'
    return (
      <div className={className} ref={this.element}>
        {crestImg && <div className="GuildCrest__svg" dangerouslySetInnerHTML={{__html: crestImg}}>
        </div>}
        {isRecruiting &&
            <div className="GuildCrest__recruiting">
                <img alt="GuildCrest__recruiting-ribbon" className="GuildCrest__recruiting-ribbon" src={assets.staticImageUrls.WE_ARE_RECRUITING}/>
                <div className="GuildCrest__recruiting-label"><StaticString stringName="WE_ARE_RECRUITING"/></div>
            </div>
        }
      </div>
    )
  }
}

export default GuildCrest
