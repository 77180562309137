import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddToCartButton from './AddToCartButton'
import BuyNowButton from './BuyNowButton'
import RecurringPurchaseButton from './RecurringPurchaseButton'
import UpgradeButton from './UpgradeButton'
import GiftToButton from './GiftToButton'
import GuildBuyNowButton from './GuildBuyNowButton'
import ProductModal from './ProductModal'
import strings from '../../../strings'
import Capsule from '../../../componentLibrary/Fragments/Capsule'
import { setModalContent } from '../../../redux/actions'
import './styles.scss'
import InstantBuyNowButton from "./InstantBuyNowButton";


const mapStateToProps = state => {
    return {
        hasGuild: state.guildManagement.hasGuild,
        guildRank: state.guildManagement.guildRank,
        guildConfiguration: state.guildManagement.guildConfiguration,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModalContent: (content) => dispatch(setModalContent(content))
    }
}

class ProductCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isGiftable: false,
            isPurchasable: false,
            isVIP: false,
            isUpgradeable: false,
            isEligibleForCart: false,
            isEligibleForGuildPurchase: false,
        }
        this.openProductModal = this.openProductModal.bind(this)
    }

    componentDidMount() {
        const { location, product, hasGuild, guildConfiguration, guildRank } = this.props
        const query = queryString.parse(location.search) || {}
        if (_.isEmpty(product)) return
        
        const isVirtualCurrency = product.currency_is_virtual
        const guildPermissions = _.result(guildConfiguration, "permission_display") || []
        const meetsRankRequirements = (_.find(guildPermissions, { 'value': 'use_guild_bank' }) || {}).required_rank >= guildRank
        
        // if (query.category && query.category.toLowerCase() === "gifting") {
        //     this.setState({
        //         isGiftable: true,
        //         isEligibleForGuildPurchase: hasGuild && isVirtualCurrency && meetsRankRequirements,
        //     })
        // } else {
            const showUpgradeButton = !!product.hasUpgrade && !_.isEmpty(product.upgrades)
            const isPhysicalGood = product.inventory_type === "physical"
            const isRecurringPurchase = product.categorySet.includes("VIP") && _.result(product, "external_ids.is_subscription")
            this.setState({ 
                isRecurringPurchase,
                isPurchasable: !showUpgradeButton && !isRecurringPurchase,
                isUpgradeable: showUpgradeButton,
                isEligibleForCart: !isPhysicalGood && !product.hasUpgrade && !isRecurringPurchase && !location.pathname.includes("/register"),
                isEligibleForGuildPurchase: hasGuild && isVirtualCurrency && meetsRankRequirements,
            })
        // }
    
    }

    openProductModal() {
        const { product, setModalContent } = this.props
        const { heroImage, name, base_amount , purchase_amount, display_strings, limitedTime, best_value, sale_percent, description } = product
        const { isGiftable, isPurchasable, isUpgradeable, isEligibleForCart, isEligibleForGuildPurchase, isRecurringPurchase} = this.state

        setModalContent(<ProductModal 
            product={product} heroImage={heroImage} name={name} baseAmt={base_amount} purchaseAmt={purchase_amount} 
            displayStrings={display_strings} limitedTime={limitedTime} bestValue={best_value} salePercent={sale_percent} 
            description={description} isGiftable={isGiftable} isPurchasable={isPurchasable} isUpgradeable={isUpgradeable} 
            isEligibleForCart={isEligibleForCart} isEligibleForGuildPurchase={isEligibleForGuildPurchase} isRecurringPurchase={isRecurringPurchase}
        />)
    }


    render() {

        const { product, hideLtoCapsule, hideBestValueCapsule, hideOnSaleCapsule, useRegisterRedirects } = this.props
        const { heroImage, name, base_amount, purchase_amount, display_strings, limitedTime, best_value, sale_percent } = product
        const { isGiftable, isPurchasable, isUpgradeable, isEligibleForCart, isEligibleForGuildPurchase, isRecurringPurchase } = this.state
        const showLtoCapsule = !hideLtoCapsule && limitedTime
        const showBestValueCapsule = !hideBestValueCapsule && best_value
        const showOnSaleCapsule = !hideOnSaleCapsule && sale_percent > 0

        return (
            <div className="ProductCard">
                {(showLtoCapsule || showBestValueCapsule || showOnSaleCapsule) && <div className="ProductCard__capsules">
                    {showLtoCapsule && <div className="ProductCard__capsule"><Capsule orange label={strings.getString("LIMITED_TIME_OFFER", "Limited Time Offer")}/></div>}    
                    {showBestValueCapsule && <div className="ProductCard__capsule"><Capsule red label={strings.getString("BEST_VALUE", "Best Value")}/></div>}
                    {showOnSaleCapsule && <div className="ProductCard__capsule"><Capsule yellow label={strings.getString("ON_SALE", "On Sale")}/></div>}
                </div>}

                <div className="ProductCard__content">
                    <div className="ProductCard__imgContainer" onClick={ this.openProductModal }>
                        <div className="ProductCard__img" style={{ backgroundImage: `url(${heroImage})` }} />
                    </div>
                    <div className="ProductCard__infoBlock" onClick={ this.openProductModal }><FontAwesomeIcon icon="info-circle"/></div>
                    {/*{isEligibleForCart && <div className="ProductCard__floatingButton"><AddToCartButton item={product} large/></div>} */}

                    <div className="ProductCard__body">
                        <div className="ProductCard__bodyHeader">
                            <div className="ProductCard__name">{name}</div>
                            <div className="ProductCard__price">
                                {base_amount !== purchase_amount &&
                                    <span className="ProductCard__basePrice">{_.result(display_strings, "base_amount")}</span>
                                }
                                {_.result(display_strings, "purchase_amount")}*
                            </div>
                        </div>
                        <div>
                            <div className="ProductCard__button">
                                {/*{isGiftable && <GiftToButton item={product} large/>}*/}
                                {isPurchasable && <InstantBuyNowButton item={product} large useRegisterRedirects={useRegisterRedirects}/>}
                                {/*{isRecurringPurchase && <RecurringPurchaseButton item={product} large />}*/}
                                {/*{isUpgradeable && <UpgradeButton item={product} large/>}*/}
                            </div>
                            {isEligibleForGuildPurchase && <div className="ProductCard__button">
                                <GuildBuyNowButton item={product} large/>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductCard))