import React from 'react'
import { api } from '../utilities/api'
import { Button, Dimmer, Form, Loader, Pagination, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsRewards extends React.Component {

  state = {
    loading: false,
    rewards: [],
    filteredRewards: [],
    filter: '',
    page: 1,
    totalPages: 1,
    pageSize: 15,
  }

  async componentDidMount() {
    await this.getContent()
  }

  async getContent() {
    this.setState({ loading: true })
    const rewards = await api.get(`/user/entitlement`)
    this.setState({ rewards: _.sortBy(rewards.data, 'name') })
    this.setState({
      filteredRewards: this.state.rewards,
      totalPages: Math.floor(this.state.rewards.length / this.state.pageSize) + 1,
      page: 1,
    })
    this.setState({ loading: false })
  }

  handleFilter(e, { name, value }) {
    this.setState({ [name]: value })
    const search = _.lowerCase(value)
    this.setState({
      filteredRewards: this.state.rewards.filter(r => {
        return _.lowerCase(r.name).includes(search) || _.lowerCase(r.description).includes(search)
      }),
      totalPages: Math.floor(this.state.filteredRewards.length / this.state.pageSize) + 1,
      page: 1,
    })
  }

  handlePageChange(e, { activePage }) {
    this.setState({ page: activePage })
  }

  handleConsume = (r) => {
    return async () => {
      const val =await swal({
        title: strings.getString('UNBUNDLE_REWARD', 'Unbundle Reward'),
        buttons: {
          cancel: strings.getString('CANCEL', 'Cancel'),
          consume: {
            text: strings.getString('UNBUNDLE', 'Unbundle'),
            value: 'consume',
            closeModal: false
          }
        },
        content: (
          <div style={{ textAlign: 'left' }}>
            <p>
              {strings.getString("UNBUNDLE_REWARD_DESCRIPTION", `Unbundling this reward will consume ${r.name} and grant you the following rewards:`, { reward: r.name  })}:
            </p>
            <ul>
              {(r.consumption_details || []).map(c => (
                <li key={c.entitlement_id}>{c.name} (x{c.quantity})</li>))}
            </ul>

            <p>{strings.getString("UNBUNDLE_REWARD_WARNING", `NOTE: Do not unbundle if you intend to upgrade your package. Unbundled packages are no longer eligible for upgrades. You will not be able to recover your reward ${r.name} once you click on unbundle.`, { reward: r.name })}</p>
          </div>
        ),
      })

      if (val === 'consume') {
        try {
          await api.post(`/user/entitlement/${r.id}/consume`)
          await swal(strings.getString('UNBUNDLE_REWARD_SUCCESSFUL', 'Successfully unbundled ' + r.name, {reward: r.name} ), {
            icon: 'success',
          })
          await this.getContent()
        } catch {
          swal(strings.getString("UNBUNDLE_REWARD_ERROR", `Uh Oh! Unable to unbundle the reward ${r.name}.`, { reward: r.name }), {
            icon: 'error',
          })
        }
      }
    }
  }

  render() {

    const { loading, filteredRewards, filter, totalPages, page, pageSize } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>Account Rewards</h1>

        <Form>
          <Form.Input autoComplete="off" type="text"
                      placeholder={strings.getString('SEARCH_REWARDS', 'Search Rewards')}
                      name='filter'
                      value={filter}
                      onChange={this.handleFilter.bind(this)}/>
        </Form>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('NAME', 'Name')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{strings.getString('QUANTITY', 'Quantity')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{strings.getString('ACTIONS', 'Actions')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredRewards.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize).map(r => (
              <Table.Row key={r.id}>
                <Table.Cell>
                  <span style={{ fontSize: '16px' }}>{r.name}</span>
                  <br/>
                  <span style={{ color: 'rgb(150, 150,150)' }}>{r.description !== r.name ? r.description : ''}</span>
                </Table.Cell>
                <Table.Cell collapsing singleLine textAlign="center">{r.count}</Table.Cell>
                <Table.Cell collapsing textAlign="center">
                  {r.consumable && <Button onClick={this.handleConsume(r)} size="mini" compact primary={r.consumable} content={strings.getString('UNBUNDLE', 'Unbundle')}/>}
                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination size='mini' prevItem={null} nextItem={null} activePage={page} totalPages={totalPages} onPageChange={this.handlePageChange.bind(this)}/>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsRewards)
