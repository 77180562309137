import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
import { addItemToUserCart } from '../../../redux/actions/crowfall-store'
import './styles.scss'


const mapStateToProps = state => {
    return {
        user: state.user.userProfile,
        cart: state.crowfallStore.cartSolutions,
        cartCurrency: state.crowfallStore.cartCurrency,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addItemToUserCart: (itemId) => dispatch(addItemToUserCart(itemId)),
    }
}

class AddToCartButton extends React.Component {
    state = {
        redirectToCrowns: false,
    }

    async handleAddToCart() {
        const { item, cartCurrency, cart } = this.props
        this.recordProductClick(item)
        const { item_id: itemId, name, discountable } = item
    
        if (cartCurrency && item.purchase_currency !== cartCurrency) {
            swal({
                icon: 'error',
                title: strings.getString("STORE_ERROR_TITLE_MIXED_CART", "This item's currency does not match the item(s) in your cart"),
                content: (
                    <div dangerouslySetInnerHTML={{ __html: strings.getString("STORE_ERROR_CONTENT_MIXED_CART", "The shopping cart can only complete purchases for one type of currency per transaction. <br/><br/>Please revisit your cart to pair items by currency type: virtual in-game currency (Crowns) vs. your local currency. <br/><br/>If you need assistance, please contact support@crowfall.com") }} />
                )
            })
        } else if (cart[0] && cart[0].discountable !== discountable) {
            swal({
                icon: 'error',
                title: strings.getString("STORE_ERROR_APPLYING_DISCOUNT", "There was an error when applying a discount to your cart."),
                content: (
                    <div>
                    {strings.getString("STORE_ERROR_ITEM_NOT_ELIGIBLE", "One or more of the items in your cart may not be eligible for promotional discounts.")}
                    <div dangerouslySetInnerHTML={{ __html: strings.getString("CONTACT_US_FOR_HELP", "Please contact support@crowfall.com for assistance." ) }} />
                    </div>
                )
            })
        } else {
            try { 
                await this.props.addItemToUserCart(itemId)
                this.recordAddToCart(item)
                swal ({
                    icon: 'success',
                    title: strings.getString("STORE_SUCCESS_ADD_TO_CART", {name} + " was successfully added to your cart.", { itemName: name })
                })
            } catch (err) {
                if (err.response.data.message === "Tax percent differs between solution and cart") {
                    swal({
                        icon: 'error',
                        content: ( <div>To complete your purchase, please contact <a href='mailto:support@crowfall.com'>support@crowfall.com</a> for assistance. There is an error in our system regarding the tax on this product.<br/><br/>We appreciate your patience and thank you for your support.</div> )
                    })
                } else if (err.response.data.message.includes("Checkout solution cannot be generated with virtual currency due to insufficient funds in wallet")) {
                    const val = await swal({
                        icon: 'error',
                        content: ( <div>{strings.getString("CHECKOUT_INVALID_CROWNS", "You don’t own enough crowns to purchase this item. You can buy additional crowns in our store.")}</div> ),
                        buttons: {
                            cancel: strings.getString("CANCEL", "Cancel"),
                            redirectToCrowns: {
                                text: strings.getString("PURCHASE_CROWNS", "Purchase Crowns"),
                                value: 'redirectToCrowns'
                            }
                        },
                    })
                    if (val === 'redirectToCrowns') this.setState({ redirectToCrowns: true })
                } else {
                    swal({
                        icon: 'error',
                        title: strings.getString("UH_OH", "Uh Oh!"),
                        content: ( <div>{err.response.data.message}</div> )
                    })
                }
            }
        }
    }
    
    recordProductClick(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "select_content",{
            "content_type": "product",
            "items": [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                }
            ]
        })
    }
    
    recordAddToCart(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "add_to_cart",{
            "items": [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                }
            ]
        })
    }
    

    render() {

        const { redirectToCrowns } = this.state
        const { user, medium, large, item } = this.props
        const returnPath = btoa(`${this.props.location.pathname}${this.props.location.search}`)
    
        return (
            <React.Fragment>
                {redirectToCrowns && <Redirect to="/store?category=ab40dfa4-c3a4-4442-a0fe-a44ebb2f277c"/>}

                {user.username ? (
                    <>
                        {medium && <Button blue medium onclick={() => this.handleAddToCart()}><FontAwesomeIcon icon='cart-plus'/></Button>}
                        {large && <Button blue fill onclick={() => this.handleAddToCart()}><FontAwesomeIcon icon='cart-plus'/></Button>}
                    </>
                ) : (
                    <LocLink to={`/login?r=${returnPath}`}>
                        {medium && <Button blue medium onclick={() => this.recordProductClick(item)}><FontAwesomeIcon icon='cart-plus'/></Button>}
                        {large && <Button blue fill onclick={() => this.recordProductClick(item)}><FontAwesomeIcon icon='cart-plus'/></Button>}
                    </LocLink>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddToCartButton))