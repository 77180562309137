import React from 'react'
import assets from '../../../../assets'
import strings from '../../../../strings'
import './styles.scss'

const TeamMemberAsset = (props) => {

    const { position, asset } = props
    const showBanner = position === "captain"
    let modeledUsername = props.username
    if (modeledUsername.length >= 17) {
        modeledUsername = modeledUsername.slice(0, 15)
        modeledUsername += "..."
    }

    return (
        <div className="TeamMemberAsset">
            {showBanner && <img className="TeamMemberAsset__bannerImg" src={assets.staticImageUrls.BLACK_BANNER} alt="banner"/>}
            {showBanner && <span className="TeamMemberAsset__bannerText">{strings.getString("CAPTAIN", "Captain").toUpperCase()}</span>}

            <img src={asset} className="TeamMemberAsset__asset" alt={`${modeledUsername} Player Icon`}/>

            <div className="TeamMemberAsset__username">{modeledUsername}</div>
        </div>
    )
}

export default TeamMemberAsset