import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'
import _ from 'lodash'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import TitleBar from '../../../componentLibrary/Fragments/TitleBar'
import TitleBlur from '../../../componentLibrary/Fragments/TitleBlur'
import ProductGrid from '../ProductGrid'
import './styles.scss'
import strings from "../../../strings";


const mapStateToProps = state => {
    const { user } = state
    return {
        user,
        catalogSearched: state.crowfallStore.catalogSearched,
        storeCategories: state.crowfallStore.categories,
        selectedCategory: state.crowfallStore.selectedCategory,
    }
}


class CategoryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            categoryName: null,
            modeledCatalog: {},
            initialScrollToChecked: false,
            vipTerms: null
        }
    }

    async componentDidMount() {
        this.modelCatalog()
        this.renderTerms()
    }

    async renderTerms() {
        const query = queryString.parse(this.props.location.search)
        if (query.category === "c70700fd-75df-4ea5-ab1c-e05eec3c6b1a") {
            const terms = await contentful.getEntry(assets.contentfulIds.VIP_TERMS_AND_CONDITIONS)
            this.setState({ vipTerms: terms.richText })
        } else {
            this.setState({ vipTerms: null })
        }
    }

    componentDidUpdate(prevProps) {
        const { selectedCategory: prevSelected, catalogSearched: prevCatalog } = prevProps
        const { selectedCategory: currSelected, catalogSearched: currCatalog } = this.props
        if (!_.isEqual(prevSelected, currSelected) || !_.isEqual(prevCatalog, currCatalog) ) {
            this.modelCatalog()
            this.renderTerms()
        }
        if ((!this.state.initialScrollToChecked && currSelected.subcategoryName) || prevSelected.subcategoryName !== currSelected.subcategoryName) {
            this.setState({ initialScrollToChecked: false })
            this.scrollToSubcategory()
        }
    }

    modelCatalog() {
        const { catalogSearched, selectedCategory } = this.props
        const { categoryName, categoryKey, subcategoryName } = selectedCategory
        const filteredCatalog = catalogSearched ? catalogSearched.filter(product => !_.isEmpty(_.find(product.primary_categories, ["category_key", categoryKey]))) : []
        let modeledCatalog = { missingSubcategory: [] }
        filteredCatalog.forEach(product => {
            const { secondary_categories } = product
            if (!_.isEmpty(secondary_categories)) {
                secondary_categories.forEach(subcategory => {
                    const { category_key } = subcategory
                    if (modeledCatalog[category_key]) {
                        modeledCatalog[category_key].push(product)
                    } else {
                        modeledCatalog[category_key] = [ product ]
                    }
                })
            } else {
                modeledCatalog.missingSubcategory.push(product)
            }
        })
        this.setState({ categoryName, modeledCatalog },
            subcategoryName ? this.scrollToSubcategory() : null
        )
    }

    scrollToSubcategory() {
        const { selectedCategory } = this.props
        const element = document.getElementById(`section--${selectedCategory.subcategoryName}`)
        if (element) element.scrollIntoView({ behavior: "smooth", block: "start" })
        if (element && this.state.initialScrollToChecked === false) this.setState({ initialScrollToChecked: true })
    }


    render() {
        const { categoryName, modeledCatalog, vipTerms } = this.state
        const { user } = this.props
        const playerData = _.result(user, 'userProfile.player_data', null);
        const isTrial = _.result(playerData, 'type') === 'trial';
        const catalogSections = Object.keys(modeledCatalog)
        let no_products_display
        if(isTrial && categoryName === "Crowns") {
            no_products_display = <span dangerouslySetInnerHTML={{ __html: strings.getString("PRODUCT_NOT_AVAILABLE", "You must purchase the game before being able to buy {{categoryName}}", {categoryName: categoryName} )}} />
        }

        return (
            <div className="CategoryPage">
                <div className="CategoryPage__header">
                    <TitleBar title={categoryName} small/>
                </div>

                {catalogSections.map((section, index) => (
                    <div className="CategoryPage__section" key={`section-${section}-${index}`}>
                        {section !== 'missingSubcategory' && <div className="CategoryPage__subheader" id={`section--${section}`}><TitleBlur title={section} h3/></div>}
                        <ProductGrid products={modeledCatalog[section]} noItemsFoundString={no_products_display}/>
                    </div>
                ))}

                {vipTerms && <div dangerouslySetInnerHTML={{ __html: vipTerms }} className="CategoryPage__terms"/>}
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(CategoryPage))
