/*

MODEL: VerticalMenu
Orders menu sections and subsections vertically. Mobile version Collapses menu into bar that can be toggled on/off

PROPERTY     DEFAULT  REQUIREMENT  TYPE      DESCRIPTION
barLabel     ""       Required     String    displayed on Menu Toggle bar for Mobile version.
isAccordion  false    Optional     Boolean   Converts Table Menu's subcategories to be toggleable.


PROPERTY: sections  
DEFAULT: []  
REQUIREMENT: Required  
TYPE: Array   
DESCRIPTION: Array of Objects with the following properties: 
                Label: required;
                Link: optional, String, local URL;
                count: optional, Integer indicates notification count;
                isActive: optional, Boolean, indicates menu item is active via styling
                items: optional Array of objects to serve as subsections. Each can include the same same keys as listed above w/ the exception of "items".

*/

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import LocLink from '../../Elements/LocLink'
import './styles.scss'


class VerticalMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            openSection: null,
        }
        this.toggleSection = this.toggleSection.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
    }

    componentDidMount() {
        this.setOpenSection()
    }

    componentDidUpdate(prevProps) {
        const { sections: prevSections } = prevProps
        const { sections: currSections } = this.props
        if ( !_.isEqual(prevSections, currSections) ) this.setOpenSection()
    }

    setOpenSection() {
        const { sections } = this.props
        const activeSection = _.find(sections, { isActive: true })
        this.setState({ openSection: activeSection ? activeSection.label : null })
    }

    toggleSection(section) {
        const { openSection } = this.state
        if (!openSection) {
            this.setState({ openSection: section })
        } else {
            this.setState({ openSection: openSection !== section ? section : null })
        }
    }

    toggleMenu() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    render() {

        const { barLabel, sections, isAccordion } = this.props
        const { isOpen, openSection } = this.state
        let className = "VerticalMenu"
        if (isOpen) className += " VerticalMenu--isOpen"

        return (
            <div className={className}>
                <div className="VerticalMenu__header" onClick={this.toggleMenu}>
                    <FontAwesomeIcon icon="bars"/>
                    <div className="VerticalMenu__label">{barLabel}</div>
                </div>

                <div className="VerticalMenu__overlay" onClick={this.toggleMenu}/>

                <div className="VerticalMenu__menu">
                    {sections && sections.map((section, index) => {
                        if (_.isEmpty(section)) return null
                        const { label, link, count, isActive, items } = section
                        const countString = count && count > 9 ? '9+' : count
                        const accordionIsClosed = openSection !== label && isAccordion
                        let sectionClassName = "VerticalMenu__section"
                        if (isActive) sectionClassName += " VerticalMenu__section--active"
                        if (accordionIsClosed) sectionClassName += " VerticalMenu__section--closed"
                        if (!_.isEmpty(link)) sectionClassName += " VerticalMenu__section--clickable"
                        return (
                            <div className={sectionClassName} key={`verticalMenu-section-${index}`}>
                                <div className="VerticalMenu__row">
                                    <LocLink to={link}>
                                        <div className="VerticalMenu__rowLabel" onClick={this.toggleMenu}>
                                            {countString && <div className="VerticalMenu__rowCount">{countString}</div>}
                                            {label}
                                        </div>
                                    </LocLink>
                                    {!_.isEmpty(items) && isAccordion && <div className="VerticalMenu__rowToggle" onClick={() => this.toggleSection(label)}>
                                        <FontAwesomeIcon icon={accordionIsClosed ? "plus" : "minus"}/>
                                    </div>}
                                </div>

                                {!_.isEmpty(items) && (!accordionIsClosed || !isAccordion) && <div className="VerticalMenu__subs">
                                    {items.map((item, index) => {
                                        if (_.isEmpty(item)) return null
                                        const { label, link, count, isActive: subIsActive } = item
                                        const countString = count && count > 9 ? '9+' : count
                                        let subsectionClassName = "VerticalMenu__subrow"
                                        if (subIsActive) subsectionClassName += " VerticalMenu__subrow--active"
                                        return (
                                            <LocLink to={link} key={`menu-subsection-${index}`}><div className={subsectionClassName} onClick={this.toggleMenu}>
                                                {countString && <div className="VerticalMenu__subrowCount">{countString}</div>}
                                                {label}
                                            </div></LocLink>
                                        )
                                    })}
                                </div>}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default VerticalMenu