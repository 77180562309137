import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import { Button, Container, Grid } from 'semantic-ui-react'
import contentful from '../../utilities/contentful'
import _ from 'lodash'
import LocLink from '../../componentLibrary/Elements/LocLink'
import strings from '../../strings'

class RaceDetailPage extends React.Component {

  state = {
    entry: {},
  }

  async componentDidMount() {
    await this.getContent()
  }

  getContent = async () => {
    const raceName = _.result(this.props, 'match.params.raceName')
    const entry = (await contentful.getEntries('raceClass', `fields.type[match]=Race&fields.id[match]=${raceName}`)).map(e => {
      e.bannerAssetUrl = _.result(e, 'bannerAsset.assetUrl')
      e.title = _.upperCase(e.pluralName)
      e.thumbnails = (e.imageAssets || []).map(a => {
        return { src: a.assetUrl, title: a.title }
      })
      return e
    })[0] || {}

    this.setState({ 'entry': entry })
  }

  render() {
    const { entry } = this.state
    return (
      <Page contentId={assets.contentfulIds.RACE_DETAILS_PAGE} update={this.getContent.bind(this)} padding={'0em'}>
        <Container fluid>
          {entry.bannerAssetUrl ? (
            <div style={{
              backgroundImage: `url(${entry.bannerAssetUrl})`,
              height: '448px',
              backgroundSize: 'cover',
              backgroundPosition: 'right center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <h1 className="h1__large" style={{
                textShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 1px',
                color: 'white',
              }}>{entry.title}</h1>

              {(entry.iconList || []).map((i, l) => (
                <img alt={i.title} style={{
                  width: 'auto',
                  height: '80px',
                }} key={l} src={i.assetUrl}/>
              ))}
            </div>
          ) : ''}


          <div style={{
            padding: '20px 0',
          }}>
            <ul style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0',
              flexWrap: 'wrap'
            }}>
              <Attribute title={'Strength'} value={entry.attributeStrength}/>
              <Attribute title={'Dexterity'} value={entry.attributeDexterity}/>
              <Attribute title={'Intellect'} value={entry.attributeIntelligence}/>
              <Attribute title={'Spirit'} value={entry.attributeSpirit}/>
              <Attribute title={'Constitution'} value={entry.attributeConstitution}/>
            </ul>
          </div>

          <div style={{
            padding: '40px 0',
            backgroundColor: 'rgb(249, 249, 249)',
          }}>

            <Container>
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={10} tablet={16}>

                    <div dangerouslySetInnerHTML={{ __html: entry.story }} style={{
                      textAlign: 'center',
                      fontSize: '19px',
                      marginBottom: '50px',
                    }}></div>

                    <div dangerouslySetInnerHTML={{ __html: entry.lore }} style={{
                      padding: '0 50px',
                      fontSize: '14px',
                      color: 'rgb(48, 76, 102)',
                      marginBottom: '50px',
                    }}></div>

                    <div dangerouslySetInnerHTML={{ __html: entry.description }} style={{
                      fontSize: '14px',
                    }}></div>

                  </Grid.Column>
                  <Grid.Column computer={6} tablet={16}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      {(entry.thumbnails || []).map((t, i) => (
                        <img alt={t.title} key={i} title={t.title} src={t.src} style={{
                          display: 'block',
                          width: '100%',
                          marginBottom: '10px',
                        }}/>
                      ))}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>


          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 0',
          }}>
            <span style={{ fontSize: '21px', marginBottom: '30px' }}>AVAILABLE CLASSES</span>
            <ul style={{
              padding: 0,
              listStyle: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              {entry.classKnight ? (
                <LocLink to={'/classes/knight'}><Classes clazz={'knight'} title={'Knight'}/></LocLink>) : ''}
              {entry.classConfessor ? (<LocLink to={'/classes/confessor'}><Classes clazz={'confessor'} title={'Confessor'}/></LocLink>) : ''}
              {entry.classTemplar ? (<LocLink to={'/classes/templar'}><Classes clazz={'templar'} title={'Templar'}/></LocLink>) : ''}
              {entry.classRanger ? (<LocLink to={'/classes/ranger'}><Classes clazz={'ranger'} title={'Ranger'}/></LocLink>) : ''}
              {entry.classAssassin ? (<LocLink to={'/classes/assassin'}><Classes clazz={'assassin'} title={'Assassin'}/></LocLink>) : ''}
              {entry.classDruid ? (<LocLink to={'/classes/druid'}><Classes clazz={'druid'} title={'Druid'}/></LocLink>) : ''}
              {entry.classFrostweaver ? (<LocLink to={'/classes/frostweaver'}><Classes clazz={'frostweaver'} title={'Frostweaver'}/></LocLink>) : ''}
              {entry.classChampion ? (<LocLink to={'/classes/champion'}><Classes clazz={'champion'} title={'Champion'}/></LocLink>) : ''}
              {entry.classMyrmidon ? (<LocLink to={'/classes/myrmidon'}><Classes clazz={'myrmidon'} title={'Myrmidon'}/></LocLink>) : ''}
              {entry.classCleric ? (<LocLink to={'/classes/cleric'}><Classes clazz={'cleric'} title={'Cleric'}/></LocLink>) : ''}
              {entry.classDuelist ? (<LocLink to={'/classes/duelist'}><Classes clazz={'duelist'} title={'Duelist'}/></LocLink>) : ''}

            </ul>
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 0',
          }}>
            {/*<Button as={LocLink} to='/store?category=064b6196-98fe-46c7-94d4-9485dad599de' className="Button__ACE Button__ACE-large">{strings.getString('PLAY_NOW', 'Play Now')}</Button>*/}
          </div>

        </Container>
      </Page>
    )
  }
}

const Attribute = ({ title, value }) => (
  <li style={{
    display: 'flex',
    listStyle: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px',
  }}>
        <span style={{
          fontSize: '14px',
          marginBottom: '5px',
        }}>{_.upperCase(title)}</span>
    <span style={{
      fontSize: '20px',
    }}>
            {[...Array(value)].map(() => (
              '•'
            ))}
        </span>
  </li>
)

class Classes extends React.Component {

  state = {}
  url = {
    knight: assets.staticImageIds.ICON_CLASS_KNIGHT,
    champion: assets.staticImageIds.ICON_CLASS_CHAMPION,
    assassin: assets.staticImageIds.ICON_CLASS_ASSASSIN,
    druid: assets.staticImageIds.ICON_CLASS_DRUID,
    duelist: assets.staticImageIds.ICON_CLASS_DUELIST,
    cleric: assets.staticImageIds.ICON_CLASS_CLERIC,
    myrmidon: assets.staticImageIds.ICON_CLASS_MYRMIDON,
    frostweaver: assets.staticImageIds.ICON_CLASS_FROSTWEAVER,
    ranger: assets.staticImageIds.ICON_CLASS_RANGER,
    confessor: assets.staticImageIds.ICON_CLASS_CONFESSOR,
    templar: assets.staticImageIds.ICON_CLASS_TEMPLAR,

  }[this.props.clazz]

  async componentDidMount() {
    this.setState({ ...(await contentful.getImage(this.url)) })
  }

  render() {
    const { assetUrl } = this.state
    const { title } = this.props

    return (
      <li style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 20px',
      }}>
        {assetUrl ? (<img alt={title} style={{
          height: '90px',
        }} src={assetUrl}/>) : ''}
        <span style={{
          color: 'black',
        }}>{_.upperCase(title)}</span>
      </li>
    )
  }
}

export default RaceDetailPage

