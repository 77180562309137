import React from 'react'
import { Button } from 'semantic-ui-react'
import LocLink from '../Elements/LocLink'

class TextLink extends React.Component {

  render() {

    const style = {
      background: 'none',
      padding: 0,
      color: 'rgb(50,50,50)',
      fontWeight: 'normal',
      fontSize: '16px'
    }

    const { to, children } = this.props
    return (
      <Button className="text-only" as={LocLink} compact to={to} style={style}>{children}</Button>
    )
  }
}

export default TextLink
