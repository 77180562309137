import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import strings from '../../../strings'
import FormMessage from '../../../componentLibrary/Fragments/FormMessage'
import './styles.scss'


const mapStateToProps = state => {
    return {
        hasGuild: state.guildManagement.hasGuild,
    }
}

const ReviewItems = (props) => {

    const { hasGuild, isACart, cartItems, item, targetUsername, grantsGuildCreation } = props
    const showGuildWarning = grantsGuildCreation && hasGuild && _.isEmpty(targetUsername)

    return (

        <div className="ReviewItems">
            <div className="ReviewItems__header">{strings.getString('REVIEW_ITEMS', 'Review Items')}</div>

            {showGuildWarning && <div className="ReviewItems__warningMessage">
                <FormMessage warning
                    title={strings.getString("STORE_CART_CONTAINS_RESERVE_GUILD_NAME", "One or more of the items in your cart grants the 'Reserve Guild Name' entitlement.")}
                    content={strings.getString("ACCOUNT_CANNOT_CREATE_GUILD_WITH_ACTIVE_MEMBERSHIP", "Users may only be a member of one active Guild at a time. Before you can create another Guild, you must leave your current one.")}
                />
            </div>}

            <div className="ReviewItems__list">
                {!isACart && item &&
                    <div className="ReviewItems__itemDetail">
                        <div className="ReviewItems__itemName">{item.name}</div>
                        <div className="ReviewItems__itemPrice">{_.result(item, 'display_strings.purchase_amount', '')}</div>
                    </div>
                }
                {isACart && cartItems.length > 0 && cartItems.map((item, index) =>
                    <div className="ReviewItems__itemDetail" key={`${index}_${item.item_id}`}>
                        <div className="ReviewItems__itemName">{item.name}</div>
                        <div className="ReviewItems__itemPrice">{_.result(item, 'display_strings.purchase_amount', '')}</div>
                    </div>)
                }
            </div>

            {targetUsername && <div className="ReviewItems__paragraph">
                <p dangerouslySetInnerHTML={{  __html: strings.getString("REVIEW_ITEMS_GIFT_PURCHASE",  `Upon completion of the purchase, the item(s) listed above will be gifted to <strong>${targetUsername}</strong>.`, { targetUsername }) }}/>
            </div>}
        </div>
    )
}

export default connect(mapStateToProps)(ReviewItems)
