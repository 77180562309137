import React from 'react'
import { api } from '../utilities/api'
import { Card, Dimmer, Image, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import contentful from '../utilities/contentful'
import assets from '../assets'
import swal from '@sweetalert/with-react'
import strings from '../strings'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsForumBadge extends React.Component {

  state = {
    loading: false,
    badges: [],
    positionImage: {},
    forumBadge: null,
  }

  async componentDidMount() {

    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })

    const positionImage = await contentful.getImage(assets.staticImageIds.ACCOUNT_FORUM_BADGE_POSITION)
    this.setState({ positionImage: positionImage })
  }

  async getContent() {
    const userRes = await api.get(`/user`)
    const badges = await api.get(`/user/badge`)
    this.setState({
      forumBadge: JSON.parse(userRes.data.forum_badge),
      badges: _.sortBy(badges.data.map(b => {
        b.level = parseInt(b.level)
        return b
      }), 'level'),
    })
  }

  async selectBadge(e, { value }) {
    this.setState({ loading: true })
    try {
      await api.put(`/user/badge`, {
        badge: value,
      })
      swal(strings.getString('ACCOUNT_FORUM_BADGE_SUCCESS', 'Successfully Updated Forum Badge'), {
        icon: 'success',
      })
    } catch {
      swal(strings.getString('ACCOUNT_FORUM_BADGE_ERROR', 'Uh Oh! Unable to Update Account Profile'), {
        icon: 'error',
      })
    }

    await this.getContent()
    this.setState({ loading: false })
  }

  render() {

    const { loading, badges, positionImage, forumBadge } = this.state
    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('FORUM_BADGES', 'Forum Badges')}</h1>

        <div style={{
          width: '137px',
          height: '257px',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '50px',
          alignItems: 'flex-end',
          position: 'relative'
        }}>
          <Image src={positionImage.assetUrl} alt={positionImage.title} bordered/>
          {forumBadge ? (<Image style={{
            width: '45px', position: 'absolute', bottom: '0', left: '50%', marginLeft: '-22px'
          }} src={forumBadge.src} alt={forumBadge.title}/>) : ''}
        </div>

        <Card.Group itemsPerRow={6}>
          {badges.map(b => (

            <Card key={b.id} onClick={this.selectBadge.bind(this)} value={b.id} raised
                  color={_.result(forumBadge, 'src') === b.src ? 'red' : null}>
              <Image alt={b.id} src={b.src}/>
            </Card>

          ))}
        </Card.Group>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsForumBadge)
