import axios from 'axios'
import strings from '../strings'
import _ from 'lodash'
import store from '../redux/store'

const spaceId = '0z0tkhisc6oz'
const client = axios.create({
  baseURL: `${store.getState().config.contentful_host_url}/spaces/${spaceId}`,
})



let languageMap = {
  'en-US': 'en-US',
  'fr-FR': 'fr-FR',
  'de-DE': 'de-DE',
  'pt-PT': 'pt-PT',
  'es-ES': 'es-ES'
}

const assetCache = {}

async function getEntry(id, language = store.getState().language, cancel) {

  if (_.isNil(languageMap[language])) {
    language = 'en-US'
  }

  const cacheKey = `entry:${id}:${language}`
  if (assetCache[cacheKey]) {
    return assetCache[cacheKey]
  }
  const lang = languageMap[language] || language
  const response = await client.get(`/entries?sys.id=${id}&locale=${lang}&include=10&limit=1000&access_token=${store.getState().config.contentful_access_token}`)
  if (response.status === 200) {
    const details = extractItem(response.data, _.result(response.data, 'items.0'))
    assetCache[cacheKey] = details
    return details
  }
  return {}
}

async function getImage(id, language = strings.currentLanguage()) {
  const cacheKey = `asset:${id}:${language}`
  if (assetCache[cacheKey]) {
    return assetCache[cacheKey]
  }
  const lang = languageMap[language] || language
  const response = await client.get(`/assets/${id}?locale=${lang}&access_token=${store.getState().config.contentful_access_token}`)
  if (response.status === 200) {
    const details = extractImage(response.data)
    assetCache[cacheKey] = details
    return details
  }
  return {}
}

async function getPaginatedEntries(options, cb) {
  const { contentType, match, language, extra, skip, limit } = options

  let queries = extra ? `${extra}&` : ''
  queries += `skip=${skip}&limit=${limit}`
  const rawData = await getRawEntries(contentType, match, language || strings.currentLanguage(), queries)
  const { total, entries } = rawData
  cb(entries)
  const nextSkip = skip + limit
  if (nextSkip < total) {
    getPaginatedEntries({...options, skip: nextSkip}, cb)
  }
}

async function getEntries(contentType, match, language = strings.currentLanguage(), extra) {
  const raw = await getRawEntries(contentType, match, language, extra)
  return raw.entries
}

async function getRawEntries(contentType, match, language = strings.currentLanguage(), extra) {
  const cacheKey = `entries:${contentType}:${language}:${JSON.stringify(match)}:${JSON.stringify(extra)}`
  if (assetCache[cacheKey]) {
    return assetCache[cacheKey]
  }
  const lang = languageMap[language] || language
  const response = await client.get(`/entries?content_type=${contentType}&include=10&locale=${lang}&access_token=${store.getState().config.contentful_access_token}${match ? `&${match}` : ''}${extra ? `&${extra}` : ''}`)
  if (response.status === 200) {
    const entries = _.result(response.data, 'items', []).map(i => {
        return extractItem(response.data, i)
      })
      const data = {
        entries,
        limit: response.data.limit,
        total: response.data.total,
        skip: response.data.skip
      }
      assetCache[cacheKey] = data
    return data
  }
  return []
}

function extractItem(data, item) {

  if (_.isNil(item)) {
    return {}
  }
  const fields = _.result(item, 'fields')
  const details = {
    contentful_id: _.result(item, 'sys.id'),
    content_type: _.result(item, 'sys.contentType.sys.id')
  }
  for (let k in fields) {
    const entry = fields[k]
    if (_.isArray(entry)) {
      details[k] = entry.map(e => {
        return extractObject(data, e)
      })
    } else if (_.isObject(entry)) {
      details[k] = extractObject(data, entry)
    } else {
      details[k] = entry
    }
  }
  return details
}

function extractObject(result, entry) {
  if (entry.nodeType === 'document') {
    return extractDocument(result, entry)
  } else {
    const type = _.result(entry, 'sys.type')
    const linkType = _.result(entry, 'sys.linkType')
    const assets = _.result(result, `includes.${linkType}`, [])

    switch (linkType) {
      case 'Asset':
        return extractLink(assets, entry)
      case 'Entry':
        return extractLink(assets, entry, result)
      default:
        console.log('Unknown type', type, entry)
        return null
    }
  }
}

function extractLink(assets, entry, result) {

    if (_.isEmpty(assets)) {
      return null
    }

    const link = _.find(assets, {
      sys: {
        id: _.result(entry, 'sys.id'),
      },
    })

    if (_.isEmpty(link)) {
      return null
    }

    let field = _.result(link, 'fields')
    const fileUrl = _.result(field, 'file.url')

    if (result) {
      field = _.merge(field, extractItem(result, link))
    }

    if (fileUrl) {
      field.assetUrl = fileUrl
    }
    return field
}

function extractDocument(result, entry) {
  return entry.content.map(e => {
    switch (e.nodeType) {
      case 'paragraph':
        return `<p>${extractParagraph(result, e).join('')}</p>`
      case 'heading-1':
        return `<h1>${extractParagraph(result, e).join('')}</h1>`
      case 'heading-2':
        return `<h2>${extractParagraph(result, e).join('')}</h2>`
      case 'heading-3':
        return `<h3>${extractParagraph(result, e).join('')}</h3>`
      case 'heading-4':
        return `<h4>${extractParagraph(result, e).join('')}</h4>`
      case 'heading-5':
        return `<h5>${extractParagraph(result, e).join('')}</h5>`
      case 'ordered-list':
        return `<ol>${extractParagraph(result, e).join('')}</ol>`
      case 'unordered-list':
        return `<ul>${extractParagraph(result, e).join('')}</ul>`
      case 'blockquote':
        return `<blockquote>${extractParagraph(result, e).join('')}</blockquote>`
      case 'embedded-asset-block':
        return extractEmbeddedAsset(result, e)
      case 'hr':
        return `<hr/>`
      default:
        console.log('did not render rt document value', e)
        return null
    }
  }).filter(e => {
    return !_.isEmpty(e)
  }).join('')
}

function extractParagraph(result, paragraph) {
  return paragraph.content.map(p => {
    switch (p.nodeType) {
      case 'text':
        return extractText(p)
      case 'hyperlink':
        return extractHyperlink(result, p)
      case 'list-item':
        return `<li>${extractParagraph(result, p).join('')}</li>`
      case 'paragraph':
        return `<p>${extractParagraph(result, p).join('')}</p>`
      case 'unordered-list':
        return `<ul>${extractParagraph(result, p).join('')}</ul>`
      case 'embedded-entry-inline':
        return extractEmbeddedEntry(result, p)
      case 'embedded-asset-block':
        return extractEmbeddedAsset(result, p)
      default:
        console.log('did not render rt paragraph value', p)
        return null
    }
  }).filter(p => {
    return !_.isEmpty(p)
  })
}

function extractHyperlink(result, paragraph) {
  let uri = _.result(paragraph, 'data.uri')

  if (uri.startsWith("https://crowfall.com/en-US/")) {
    uri = uri.replace("https://crowfall.com/en-US/", `/${store.getState().language}/`)
  }

  return `<a href="${uri}">${extractParagraph(result, paragraph).join('')}</a>`
}

function extractText(p) {
  let text = (p.value || '').replace(/(\r\n|\n|\r)/gm, '<br/>')
  _.result(p, 'marks', []).forEach(m => {
    text = applyMarks(m, text)
  })
  return text
}

function extractEmbeddedEntry(result, entry) {

  const asset = extractLink(result.includes.Entry, entry.data.target)
  let link = extractLink(result.includes.Asset, asset.asset)
  link = !_.isEmpty(link) ? link : {}

  const tag = `<div style="display:flex;align-items:center;justify-content:center; padding: 20px"><a target="_blank" href="${asset.url}"><img title="${link.title}" src="${link.assetUrl}"/></a></div>`
  return tag
}

function extractEmbeddedAsset(result, entry) {
  if (result && result.includes && result.includes.Asset) {
    const asset = extractLink(result.includes.Asset, entry.data.target)
    const tag = `<div style="display:flex; align-items:center; justify-content:center; padding: 20px"><img style="max-width:100%" title="${asset ? asset.title : ''}" src="${asset ? asset.assetUrl : ''}"/></div>`
    return tag
  }
    return ""
}

function extractImage(image) {
  let f = _.result(image, 'fields')
  f.assetUrl = _.result(f, 'file.url')
  return f
}

function applyMarks(mark, text) {
  switch (_.result(mark, 'type')) {
    case 'bold':
      return `<strong>${text}</strong>`
    case 'italic':
      return `<em>${text}</em>`
    case 'underline':
      return `<u>${text}</u>`
    case 'code':
      return `<code>${text}</code>`
    default:
      console.log('Unknown mark', mark)
      return text
  }
}

const contentful = {
  getEntry, 
  getPaginatedEntries, 
  getRawEntries, 
  getEntries, 
  getImage
}

export default contentful