import React from 'react'
import { Button, Dimmer, Form, Loader, Message, Pagination, Popup, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import GiftToModal from './GiftToModal'
import FixedTable from '../../componentLibrary/Elements/FixedTable'
import { clearModalContent, setModalContent } from '../../redux/actions'
import { 
  sendGiftToUser, fetchUserEntitlements, searchGiftableEntitlements, sortGiftableEntitlements, 
  paginateGiftableEntitlements, clearUserEntitlements 
} from '../../redux/actions/user'
import strings from '../../strings'


const mapStateToProps = (state) => {
  return {
    user: state.user.userProfile,
    gifts: state.user.giftableEntitlements,
    giftsSearched: state.user.giftableEntitlementsSearched,
    sortCriteria: state.user.giftableEntitlementsSortCriteria,
    giftsSorted: state.user.giftableEntitlementsSorted,
    paginationCriteria: state.user.giftableEntitlementsPaginationCriteria,
    giftsPaginated: state.user.giftableEntitlementsPaginated,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearModalContent: () => dispatch(clearModalContent()),
    setModalContent: (content) => dispatch(setModalContent(content)),
    sendGiftToUser: (obj) => dispatch(sendGiftToUser(obj)),
    fetchUserEntitlements: () => dispatch(fetchUserEntitlements()),
    searchGiftableEntitlements: (criteria) => dispatch(searchGiftableEntitlements(criteria)),
    sortGiftableEntitlements: (criteria) => dispatch(sortGiftableEntitlements(criteria)),
    paginateGiftableEntitlements: (page) => dispatch(paginateGiftableEntitlements(page)),
    clearUserEntitlements: () => dispatch(clearUserEntitlements()),
  }
}

class AccountGiftsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      totalPages: 1,
      searchValue: "",
      targetUsername: "",
      quantity: 1,
    }
    this.updateField = this.updateField.bind(this)
    this.handleClearGift = this.handleClearGift.bind(this)
    this.handleSendGift = this.handleSendGift.bind(this)
    this.attemptToSendGift = this.attemptToSendGift.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    await this.props.fetchUserEntitlements()
    const { gifts, paginationCriteria } = this.props
    const totalPages = Math.floor(gifts.length / paginationCriteria.limit) + 1
    this.setState({ 
      totalPages, 
      targetUsername: "",
      quantity: 1, 
    })
  }

  handleSearch(e, { name, value }) {
    this.setState({ [name]: value })
    const { searchGiftableEntitlements } = this.props
    searchGiftableEntitlements(this.criteria(value))
  }

  criteria(value) {
    const lowerCaseValue = _.lowerCase(value)
    return (item) => {
      if (
        _.lowerCase(item.name).includes(lowerCaseValue) || 
        _.lowerCase(item.description).includes(lowerCaseValue) || 
        _.lowerCase(item.source_username).includes(lowerCaseValue) ||
        _.lowerCase(item.status).includes(lowerCaseValue)
      ) {
        return true
      } else {
        return false
      }
    }
  }

  handleSort(columnName) {
    const { sortGiftableEntitlements, sortCriteria } = this.props
    const { value, direction } = sortCriteria
    
    if (value !== columnName) {
      sortGiftableEntitlements({ value: columnName, direction: 'ascending' })
    } else if (value === columnName) {
      const newDirection = direction === 'ascending' ? 'descending' : 'ascending'
      sortGiftableEntitlements({ value, direction: newDirection })
    }
  }

  handlePageChange(e, { activePage }) {
    this.props.paginateGiftableEntitlements(activePage)
  }

  updateField(e, {value, name}) {
    this.setState({ [name]: value })
  }

  async handleGift(reward) {
    this.props.setModalContent(
      <GiftToModal reward={reward} updateField={this.updateField} handleCancelGift={this.handleClearGift} 
                   handleSendGift={this.handleSendGift}/>
    )
  }

  handleClearGift() {
    this.setState({ targetUsername: '', quantity: 1 })
    this.props.clearModalContent()
  }

  async handleSendGift(reward) {
    const { targetUsername, quantity } = this.state
    if (_.isEmpty(targetUsername)) {
      this.generateInvalidUsernameModal()
      return
    }

    const isAGameCopy = _.result(reward, "id") === "KS_23"
    const isLastGameCopy = isAGameCopy && quantity >= _.result(reward, "count")
    if (isLastGameCopy) {
      const val = await swal({
        icon: "warning",
        title: strings.getString("USER_WILL_LOSE_GAME_ACCESS", "You are about to lose access to Crowfall."),
        content: ( <div>{strings.getString("WARNING_PROCEED_WITH_GIFT", `If you would like to proceed with your gift to ${targetUsername}, select 'Send Gift' below.`, { username: targetUsername })}</div> ),
        buttons: {
          cancel: strings.getString("CANCEL", "Cancel"),
          send: strings.getString("SEND_GIFT", "Send Gift"),
        }
      })

      if (val === "cancel") {
        this.props.clearModalContent()
      } else if (val === "send") {
        this.attemptToSendGift(reward)
      }
    } else {
      this.attemptToSendGift(reward)
    }
  }

  async attemptToSendGift(reward) {
    const { targetUsername, quantity } = this.state
    try {
      await this.props.sendGiftToUser({
        target_username: targetUsername,
        entitlement_id: reward.id,
        quantity,
      })
      swal(strings.getString('ACCOUNT_GIFTS_SUCCESS', 'Successfully gifted ' + reward.name + ' to ' + targetUsername, { gift: reward.name, name: targetUsername } ), {
        icon: 'success',
      })
      this.props.clearModalContent()
      await this.getContent()

    } catch (err) {
      swal(strings.getString('ACCOUNT_GIFTS_ERROR', 'Uh Oh! Unable to gift ' + reward.name + ' to ' + targetUsername, { gift: reward.name, name: targetUsername } ), {
        icon: 'error',
      })
      this.handleClearGift()
    }
  }

  generateInvalidUsernameModal() {
    swal({
      title: strings.getString("UH_OH", "Uh Oh!"),
      icon: 'error',
      content: (<div>{strings.getString("GIFT_INVALID_USERNAME", "Please enter a valid Username to send your gift to.")}</div>)
    })
  }

  componentWillUnmount() {
    this.props.clearUserEntitlements()
  }

  render() {

    const { loading, totalPages, searchValue } = this.state
    const { user, giftsPaginated, paginationCriteria } = this.props

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString("GIFTS","Gifts")}</h1>
        {user.has_otp ? '' : (<Message color="yellow">{strings.getString('ACCOUNT_UNABLE_TO_GIFT', 'Unable to Gift without first setting up 2-Step Verification.')}</Message>)}

        <Form>
          <Form.Input autoComplete="off" type="text"
                      placeholder={strings.getString('SEARCH_GIFTS', 'Search Gifts')}
                      name='searchValue'
                      value={searchValue}
                      onChange={this.handleSearch}/>
        </Form>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('NAME', 'Name')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{strings.getString('QUANTITY', 'Quantity')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{strings.getString('ACTION', 'Action')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {giftsPaginated.map(reward => (
              <Table.Row key={reward.id}>
                <Table.Cell>
                  <span style={{ fontSize: '16px' }}>{reward.name}</span>
                  <br/>
                  <span style={{ color: 'rgb(150, 150,150)' }}>{reward.description !== reward.name ? reward.description : ''}</span>
                </Table.Cell>
                <Table.Cell singleLine collapsing textAlign="center">{reward.count}</Table.Cell>
                <Table.Cell collapsing textAlign="center">

                  {_.result(user, "has_otp") ? (
                    <Button onClick={() => this.handleGift(reward)} size="mini" compact primary content="Gift"/>
                  ) : (
                    <Popup trigger={<Button size="mini" compact content="Gift"/>}
                           content={strings.getString('ACCOUNT_GIFTS_2FA_REQUIRED', 'Must have 2FA enabled to gift.')}/>
                  )}


                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

        <div style={{ textAlign: 'center' }}>
          <Pagination defaultActivePage={paginationCriteria.page} totalPages={totalPages} onPageChange={this.handlePageChange}/>
        </div>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountGiftsPage)
