import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import strings from '../../../strings'
import DataTable from '../../../componentLibrary/Models/DataTable'
import Pagination from '../../../componentLibrary/Elements/Pagination'
import Searchbar from '../../../componentLibrary/Elements/Searchbar'
import SectionHeader from '../../../componentLibrary/Fragments/SectionHeader'
import Label from '../../../componentLibrary/Fragments/Label'
import { searchGuildLog, paginateGuildLog, clearLogSearchCriteria } from '../../../redux/actions/guild-management'
import './styles.scss'


const mapStateToProps = state => {
    return {
        guildLog: state.guildManagement.guildLog,
        logSearched: state.guildManagement.logSearched,
        paginationCriteria: state.guildManagement.logPaginationCriteria,
        logPaginated: state.guildManagement.logPaginated,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        searchGuildLog: (criteria) => dispatch(searchGuildLog(criteria)),
        paginateGuildLog: (page) => dispatch(paginateGuildLog(page)),
        clearLogSearchCriteria: () => dispatch(clearLogSearchCriteria()),
    }
}

class GuildNotificationsPage extends React.Component {

    modelLog(log) {
        return log.map(notification => {
            const { id, note, source, target, date } = notification
            const targetLabel = !_.isEmpty(target) ? target : "---"
            return {
                id,
                elements: [
                    <Label label={note}/>,
                    <Label label={source}/>,
                    <Label label={targetLabel}/>,
                    <Label label={date}/>,
                ],
            }
        })
    }

    render() {

        const { logSearched, paginationCriteria, logPaginated, searchGuildLog, paginateGuildLog, clearLogSearchCriteria } = this.props
        const logLabels = [
            {label: strings.getString("TYPE", "Type")},
            {label: strings.getString("BY", "By")},
            {label: strings.getString("TO", "To")},
            {label: strings.getString("DATE", "Date")},
        ]
        const logRows = this.modelLog(logPaginated)

        return (
            <div className="GuildNotificationsPage">
                <SectionHeader title={strings.getString("NOTIFICATION_LOG", "Notification Log")} />
                <div className="GuildNotificationsPage__search">
                    <Searchbar search={searchGuildLog} clear={clearLogSearchCriteria} />
                </div>
                <div className="GuildNotificationsPage__table">
                    <DataTable labels={logLabels} rows={logRows} />
                </div>
                <div className="GuildNotificationsPage__pagination">
                    <Pagination paginate={paginateGuildLog} list={logSearched} page={paginationCriteria.page} limit={paginationCriteria.limit} />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildNotificationsPage)