import React from 'react'
import _ from 'lodash'
import assets from '../../../assets'
import strings from '../../../strings'
import contentful from '../../../utilities/contentful'
import Page from '../../../componentLibrary/Models/Page'
import Grid from '../../../componentLibrary/Fragments/Grid'
import ContentCard from '../../../componentLibrary/Elements/ContentCard'
import ECSFooter from '../ECSFooter'
import BackToECSButton from '../BackToECSButton'
import './styles.scss'


class ECSEventsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: null,
            bannerAsset: null,
            tagline: null,
            body: null,
            footer: null,

            events: [],
        }
    }

    componentDidMount() {
        this.getContent()
    }

    async getContent() {
        const content = await contentful.getEntry(assets.contentfulIds.ECS_EVENTS_AND_DATES_PAGE)
        const events = _.orderBy(await contentful.getEntries("ecsEventdate"), "displayWeight")

        this.setState({
            title: content.title,
            bannerAsset: _.result(content, "bannerAsset.assetUrl"),
            tagline: content.tagline,
            body: content.body,
            footer: content.footer,
            events,
        })
    }

    modelEvents(events) {
        return events.map(event => {
            const { title, cardAsset, date, subtitle, status, shortDescription, voDUrl } = event
            const assetUrl = _.result(cardAsset, "assetUrl")
            let capsules = []
            if (status.toLowerCase() === "completed") capsules.push({ id: `event-capsule-${title}`, label: "VoD", blue: true })
            if (status.toLowerCase() === "open") capsules.push({ id: `event-capsule-${title}`, label: strings.getString("OPEN", "Open"), green: true })
            return (
                <ContentCard 
                    title={title} img={assetUrl} date={date} subtitle={subtitle} description={shortDescription} capsules={capsules}
                    cardLink={voDUrl} isExternalLink={!!voDUrl}
                />
            )
        })
    }


    render() {

        const { title, bannerAsset, tagline, body, footer, events } = this.state
        const header = { banner: bannerAsset, title }

        return (
            <Page header={header} pageFooter={<ECSFooter/>}>
                <div className="ECSEventsPage">

                    {!_.isEmpty(tagline) && <div className="ECSEventsPage__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>}

                    {!_.isEmpty(body) && <div className="ECSEventsPage__body" dangerouslySetInnerHTML={{  __html: body }}/>}

                    <div className="ECSEventsPage__list"><Grid list={this.modelEvents(events)} gridGap30 fillBottomRow/></div>

                    {!_.isEmpty(footer) && <div className="ECSEventsPage__footer" dangerouslySetInnerHTML={{ __html: footer }}/>}

                    <br/>
                    <BackToECSButton/>
                </div>
            </Page>
        )
    }
}

export default ECSEventsPage