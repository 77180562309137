import React from 'react'
import strings from '../../../strings'
import DownloadClientButton from '../../../componentLibrary/Fragments/DownloadClientButton'
import './styles.scss'


const DownloadPackPage = props => {
    return (
        <div className="DownloadPackPage">
            <DownloadClientButton />
            <br/>
            <div className="DownloadPackPage__header">{strings.getString("DOWNLOAD_THE_CROWFALL_INSTALLER", "Download the Crowfall Installer")}:</div>
            <div className="DownloadPackPage__instructions">
                <div className="DownloadPackPage__line">{strings.getString("REGISTRATION_DOWNLOAD_INSTRUCTIONS_1", "Click the green Play Now button to download the Crowfall Installer.")}</div>
                <div className="DownloadPackPage__line">{strings.getString("REGISTRATION_DOWNLOAD_INSTRUCTIONS_2", "Click on the file to start the installation process.")}</div>
            </div>
            <div className="DownloadPackPage__header">{strings.getString("REGISTRATION_AFTER_INSTALLATION_COMPLETE", "After Installation Is Complete")}:</div>
            <div className="DownloadPackPage__instructions">
                <div className="DownloadPackPage__line">{strings.getString("REGISTRATION_AFTER_INSTALLATION_COMPLETE_INSTRUCTIONS_1", "Run the launcher \"Crowfall Live\".")}</div>
                <div className="DownloadPackPage__line">{strings.getString("REGISTRATION_AFTER_INSTALLATION_COMPLETE_INSTRUCTIONS_2", "Click on the file to start the installation process.")}</div>
                <div className="DownloadPackPage__line">{strings.getString("REGISTRATION_AFTER_INSTALLATION_COMPLETE_INSTRUCTIONS_3", "The patcher will update your download to our latest client.")}</div>
            </div>

            <a href="/faq/playtest-support/1jd4rAi1nKE2uWqQGeEaoe" target="_blank" rel="noopener noreferrer">{strings.getString("MINIMUM_SYSTEM_REQUIREMENTS", "Minimum System Requirements")}</a>
        </div>
    )
}

export default DownloadPackPage