import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import connect from 'react-redux/es/connect/connect'
import { closeMobileNav } from '../../redux/actions'

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeMobileNav: () => dispatch(closeMobileNav())
  }
}

class LocLink extends React.Component {
  render() {
    const { children, language, to, exact, closeMobileNav, redirect, ...rest } = this.props
    const returnPath = btoa(`${window.location.pathname}${window.location.search}`)
    delete rest.staticContext
    delete rest.dispatch
    let go = `/${language}${to}` || '/'
    go = !!redirect ? `${go}?r=${returnPath}` : go
    return (
      <React.Fragment>
        {!_.isEmpty(to) ? <Link {...rest} to={go} onClick={closeMobileNav}>{children}</Link> : children}
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocLink)
