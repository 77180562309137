import React from 'react'
import { Dimmer, Loader, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'
import { fetchAccountDiscounts } from '../redux/actions/user'


const mapStateToProps = state => {
  return {
    discounts: state.user.accountDiscounts,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDiscounts: () => dispatch(fetchAccountDiscounts()),
  }
}

class AccountDetailsDiscounts extends React.Component {

  state = {
    loading: false,
    discounts: [],
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.props.fetchDiscounts()
    this.filterExpiredDiscounts()
    this.setState({ loading: false })
  }

  filterExpiredDiscounts() {
    const { discounts } = this.props

    const today = Date.now()
    const filtered = discounts.filter(code => 
      !!code.active && code.date_expires > today
    )
    this.setState({ discounts: filtered })
  }

  render() {

    const { loading, discounts } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('STORE_DISCOUNTS', 'Store Discounts')}</h1>

        <p>{strings.getString('ACCOUNT_DISCOUNTS_INFO', 'Here you can see your active Store Discounts.  Only a single discount is active at any time, with the highest discount percent automatically selected.')}</p>


        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('NAME', 'Name')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('EXPIRATION', 'Expiration')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('DISCOUNT', 'Discount')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('REMAINING_USES', 'Remaining Uses')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('TIMES_USED', 'Times Used')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {discounts.map(r => (
              <Table.Row key={r.discount_id}>
                <Table.Cell singleLine>
                  {r.name}
                  <br/>
                  <span style={{ color: 'rgb(175,175,175)' }}>{(new Date(r.date_created)).toLocaleString()}</span>
                </Table.Cell>
                <Table.Cell singleLine>{r.date_expires ? (new Date(r.date_expires)).toLocaleString(): strings.getString('ACCOUNT_DETAILS_DISCOUNTS_NEVER', 'Never')}</Table.Cell>
                <Table.Cell>{r.percentage}%</Table.Cell>
                <Table.Cell>{r.remaining_uses}</Table.Cell>
                <Table.Cell>{r.times_used}</Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsDiscounts)
