import React, { Fragment } from 'react'
import Page from '../../../componentLibrary/Models/Page'
import Button from '../../../componentLibrary/Fragments/Button'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import assets from '../../../assets'
import strings from '../../../strings'
import contentful from '../../../utilities/contentful'
import './styles.scss'

class PressPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      kit: {},
      releases: [],
    }
  }

  async componentDidMount() {
    const kit = Array.from(await contentful.getEntries('contentBlock', 'fields.location[match]=media-press-kit'))[0]
    const releases = Array.from(await contentful.getEntries('pressRelease', null, undefined, 'order=-fields.date&limit=1000')).map(release => {
      return release
    })

    this.setState({
      kit,
      releases,
    })
  }

  render() {
    const { kit, releases } = this.state
    return (
      <Page contentId={assets.contentfulIds.PRESS_PAGE}>
        <div className="PressPage__kit">
          <h2 className="PressPage__subtitle">{strings.getString("INTEREST_IN_CROWFALL")}</h2>
          <div className="PressPage__kitBody">
            <div className="PressPage__download">
              <img alt="crowfall_logo" src={assets.staticImageUrls.LOGO_CROWFALL}/>
              <a href="https://web-cdn.crowfall.com/MediaKit_Summer2021.zip">
                <div className="PressPage__downloadButton"><Button green label="Download Press Kit"/></div>
              </a>
              <div>{strings.getString("CONTACT_US_AT")}</div>
              <a className="PressPage__email" href="mailto:press@crowfall.com">press@crowfall.com</a>
              <LocLink to="/monumental/fair-use-policy">{strings.getString("ACE_USE_POLICY")}</LocLink>
            </div>
            <div className="PressPage__kitContent" dangerouslySetInnerHTML={{ __html: kit.content }}/>
          </div>
        </div>

        <div className="PressPage__releases">
          <h1 className="PressPage__title">{strings.getString("PRESS_RELEASES")}</h1>
          {releases.map((release, index) => {
            return (
              <Fragment key={index}>
                <div className="PressPage__release_title">{release.title}</div>
                <div className="PressPage__release_tagline" dangerouslySetInnerHTML={{ __html: release.tagline }}></div>
                <div className="PressPage__release" dangerouslySetInnerHTML={{ __html: release.description }}/>
              </Fragment>
            )
          })}
        </div>
      </Page>
    )
  }
}

export default PressPage
