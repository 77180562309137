import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../strings'

import Page from '../../componentLibrary/Models/Page'
import FieldInput from '../../componentLibrary/Models/FieldInput'
import FieldDropdown from '../../componentLibrary/Models/FieldDropdown'
import Button from '../../componentLibrary/Fragments/Button'
import FormMessage from '../../componentLibrary/Fragments/FormMessage'

import { fetchCohorts } from '../../redux/actions'
import { fetchGuildConfiguration } from '../../redux/actions/guilds'
import {createGuild, getLatestGuildMembership, setHasGuild} from '../../redux/actions/guild-management'

import './styles.scss'
import {stringify} from "query-string";


const mapStateToProps = state => {
    return {
        languages: state.languages,
        configuration: state.guilds.configuration,
        cohorts: state.cohorts,
        latestGuildMembership: state.guildManagement.latestGuildMembership,
        session: state.session,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchGuildConfiguration: () => dispatch(fetchGuildConfiguration()),
        getLatestGuildMembership: () => dispatch(getLatestGuildMembership()),
        createGuild: (guild) => dispatch(createGuild(guild)),
        updateUserDetails: () => {
            dispatch(setHasGuild(true))
            dispatch(fetchCohorts())
        }
    }
}

class CreateGuildPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            redirectToBackerPacks: false,

            languageOptions: [],
            pantheon: [],

            errorDisplayName: null,
            errorName: null,
            errors: [],

            displayName: null,
            name: null,
            allegiance: null,
            languages: [],
            url: null,
            motto: null,
            description: null,
        }
        this.updateField = this.updateField.bind(this)
        this.addToMultiSelect = this.addToMultiSelect.bind(this)
        this.removeFromMultiSelect = this.removeFromMultiSelect.bind(this)
        this.isValidForm = this.isValidForm.bind(this)
        this.submitGuild = this.submitGuild.bind(this)
    }

    async componentDidMount() {
        await this.props.fetchGuildConfiguration()
        this.getConfigs()
        await this.props.getLatestGuildMembership()
        if (!_.isEmpty(_.result(this.props.cohorts, "is_trial_account"))) this.generateModalForTrialAccount()
    }

    componentDidUpdate(prevProps) {
        const { languages: prevLangauges } = prevProps
        const { languages: currLanguages } = this.props
        if (!_.isEqual(currLanguages, prevLangauges)) this.getConfigs()
    }

    updateField(value, name) {
        this.setState({ [name]: value })
    }

    addToMultiSelect(value, name) {
        this.setState({ [name]: [...this.state[name], value] })
    }

    removeFromMultiSelect(value, name) {
        this.setState({ [name]: this.state[name].filter(item => !_.isEqual(item, value)) })
    }

    getConfigs() {
        const { languages, configuration } = this.props

        const languageOptions = languages ? languages.map(language => {
            return Object.assign({}, null, {
                label: language.display_name,
                value: language.culture_code,
                selected: false,
            })
        }) : []
        const pantheon = configuration.guild_allegiances ? configuration.guild_allegiances.map(allegiance => {
            return Object.assign({}, null, {
                label: allegiance.display_name,
                value: allegiance.guild_allegiance_id,
                selected: false,
            })
        }) : []

        this.setState({ languageOptions, pantheon })
    }

    async generateModalForTrialAccount() {
        await swal({
            icon: 'error',
            title: strings.getString("UH_OH", "Uh Oh!"),
            text: strings.getString("TRIAL_ACCOUNT_CREATE_GUILD_ERROR", "You must be an active Backer of Crowfall before you can create a Guild. You can purchase a Game Pack from our store.")
        })
        this.setState({ redirectToBackerPacks: true })
    }

    isValidForm() {
        const { displayName, name, allegiance, languages } = this.state
        return (
            displayName !== null &&
            name !== null &&
            allegiance !== null &&
            languages.length > 0
        )
    }

    async submitGuild() {
        this.setState({ loading: true })
        const { createGuild, updateUserDetails } = this.props
        try {
            await createGuild({
                display_name: this.state.displayName,
                name: this.state.name,
                allegiance_id: this.state.allegiance,
                preferred_languages: this.state.languages,
                website_url: this.state.url,
                mission_statement: this.state.motto,
                description: this.state.description,
            })
            await updateUserDetails()
        } catch(err) {
            swal({
                icon: 'error',
                title: strings.getString("UH_OH", "Uh Oh!"),
                content: (
                    <div dangerouslySetInnerHTML={{ __html: strings.getString("ERROR_CREATING_GUILD", "There was an error when attempting to create your Guild.<br/>Please address the errors noted on the form and try again.") }}></div>
                )
            })
            const data = err.response.data
            const errorMessages = data.status && data.status.errors ? [data.message, ...data.status.errors] : [data.message]
            const errorState = {
                errorDisplayName: null,
                errorName: null,
                errors: [],
            }
            errorMessages.forEach(error => {
                if (error === "Unable To Create Guild, Guild Name Currently In Use Within Same Shard Space") {
                  errorState.errorName = strings.getString("GUILD_ERROR_NAME_IN_USE", "The Guild name is already in use. Please select another and try again.")
                } else if (error.includes("The name provided does not meet the validation pattern for a guild name:") && error.includes("{3,12}")) {
                  errorState.errorName = strings.getString("GUILD_ERROR_NAME_INVALID", "The Guild Name does not meet the requirements. Names should be 3 to 12 alpha numeric characters. Non-consecutive hyphens and underscores are permitted in the middle of the name.")
                } else if (error.includes("The name provided does not meet the validation pattern for a guild name:") && error.includes("{0,34}")) {
                  errorState.errorDisplayName = strings.getString("GUILD_ERROR_DISPLAY_NAME_INVALID", "The Guild Display Name does not meet the requirements. Display names should be 3 to 35 alpha numeric characters and cant start or end with a number. Non-consecutive hyphens and underscores are permitted in the middle of the name.")
                } else {
                  errorState.errors.push(error)
                }
            })
            this.setState({
                loading: false,
                ...errorState
            })
        }
    }

    render() {
        const { latestGuildMembership, session } = this.props
        const { loading, redirectToBackerPacks, languageOptions, pantheon, errorName, errorDisplayName, errors } = this.state
        const placeholder = strings.getString("PLEASE_SELECT", "Please Select")
        let cannotCreateGuild = (latestGuildMembership && latestGuildMembership.denyGuildCreate) || session.account_status.type === 'trial'
        return (
            <Page titleName="CREATE_YOUR_GUILD">

                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>

                {cannotCreateGuild && <Redirect to="/guild" />}
                {redirectToBackerPacks && <Redirect to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de" />}

                <div className="CreateGuildPage">

                    <div className="CreateGuildPage__form">

                        <div className="CreateGuildPage__field">
                            <FieldInput
                                label={strings.getString("GUILD_DISPLAY_NAME_LABEL", "Guild Display Name <b>(displayed In-Game)</b>")}
                                name="displayName" onChange={this.updateField}
                            />
                        </div>
                        {errorDisplayName && <div className="CreateGuildPage__formMessage">
                            <FormMessage error content={errorDisplayName}/>
                        </div>}

                        <div className="CreateGuildPage__field">
                            <FieldInput
                                label={strings.getString("GUILD_UNIQUE_NAME_LABEL", "Guild Short Name (serves as a unique identifier for your Guild)")}
                                name="name" onChange={this.updateField}
                            />
                        </div>
                        {errorName && <div className="CreateGuildPage__formMessage">
                            <FormMessage error content={errorName}/>
                        </div>}

                        <div className="CreateGuildPage__field">
                            <FieldDropdown
                                label={strings.getString("GUILD_GOD_PATRON", "Guild God / Patron")}
                                name="allegiance" above placeholder={placeholder} options={pantheon}
                                selectOne={this.updateField}
                            />
                        </div>

                        <div className="CreateGuildPage__field">
                            <FieldDropdown
                                label={strings.getString("LANGUAGE", "language")}
                                name="languages" type="dropdown" above placeholder={placeholder} options={languageOptions}
                                selectMany={this.addToMultiSelect} deselect={this.removeFromMultiSelect}
                            />
                        </div>

                        <div className="CreateGuildPage__field">
                            <FieldInput
                                label={strings.getString("WEBSITE_URL", "Website URL") + " " + strings.getString("OPTIONAL", "(Optional)")}
                                name="url" onChange={this.updateField}
                            />
                        </div>

                        <div className="CreateGuildPage__field CreateGuildPage__field--areaShort">
                            <FieldInput
                                label={strings.getString("MISSION_STATEMENT", "Mission Statement") + " " + strings.getString("OPTIONAL", "(Optional)")}
                                name="motto" onChange={this.updateField}
                            />
                        </div>

                        <div className="CreateGuildPage__field CreateGuildPage__field--areaTall">
                            <FieldInput
                                label={strings.getString("GUILD_DESCRIPTION", "Guild Description") + " " + strings.getString("OPTIONAL", "(Optional)")}
                                name="description" area tall height="100px" onChange={this.updateField}
                            />
                        </div>

                        {errors.length > 0 && errors.map((error, index) => (
                            <div className="CreateGuildPage__formMessage" key={`generalError--${index}`}>
                                <FormMessage error content={error}/>
                            </div>
                        ))}

                        <div className="CreateGuildPage__buttons">
                            <Button label={strings.getString("CREATE_GUILD", "Create Guild")} onclick={this.submitGuild} disabled={!this.isValidForm()}/>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGuildPage)
