import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'

import FieldInput from '../../../componentLibrary/Models/FieldInput'
// import Toggle from '../../../componentLibrary/Elements/Toggle'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import FormMessage from '../../../componentLibrary/Fragments/FormMessage'
import Button from '../../../componentLibrary/Fragments/Button'
import SectionHeader from '../../../componentLibrary/Fragments/SectionHeader'

import { setHasGuild, clearGuildDetails, disbandGuild, transferGuildLeadership, updateGuildDetails } from '../../../redux/actions/guild-management'
import './styles.scss'


const mapStateToProps = state => {
    return {
        guildId: state.guildManagement.guildId,
        guild: state.guildManagement.guild,
        members: state.guildManagement.members,
        configuration: state.guilds.configuration
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setHasGuild: (status) => dispatch(setHasGuild(status)),
        clearGuildDetails: () => dispatch(clearGuildDetails()),
        disband: (guildId) => dispatch(disbandGuild(guildId)),
        transferGuildLeadership: (memberId) => dispatch(transferGuildLeadership(memberId)),
        updateGuildDetails: (guildId, updates) => dispatch(updateGuildDetails(guildId, updates)),
    }
}

class GuildLeaderToolsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editingNotifications: false,

            editingSettings: false,
            displayNameInput: null,
            errorDisplayName: null,
            settingsErrors: [],

            eligibleMemberOptions: [],

            discordNotifications: {
                typeOne: true,
                typeTwo: false,
                typeThree: false,
                typeFour: false,
            }
        }
        this.toggleEditingNotifications = this.toggleEditingNotifications.bind(this)
        this.toggleEditingSettings = this.toggleEditingSettings.bind(this)
        this.setInitialState = this.setInitialState.bind(this)
        this.toggleNotificationType = this.toggleNotificationType.bind(this)
        this.updateField = this.updateField.bind(this)
        this.clearNotificationsForm = this.clearNotificationsForm.bind(this)
        this.clearSettingsForm = this.clearSettingsForm.bind(this)
        this.submitGuildSettings = this.submitGuildSettings.bind(this)
        this.disbandGuild = this.disbandGuild.bind(this)
        this.handleTransferGuild = this.handleTransferGuild.bind(this)
    }

    componentDidMount() {
        this.setInitialState()
    }

    toggleEditingNotifications() {
        if (this.state.editingNotifications === false) {
            this.setState({ editingNotifications: true })
        } else {
            this.setState({ editingNotifications: false })
            this.clearNotificationsForm()
        }
    }

    toggleEditingSettings() {
        if (this.state.editingSettings === false) {
            this.setState({ editingSettings: true })
        } else {
            this.setState({ editingSettings: false })
            this.clearSettingsForm()
        }
    }

    setInitialState() {
        const { guild, members } = this.props
        const eligibleMembers = members ? members.filter(member => {
            return member.rank < 5 && member.rank !== 1
        }) : []
        const eligibleMemberOptions = eligibleMembers.map(member => ({
            label: `${member.username} (${member.display_name})`,
            value: member.guild_membership_id,
        }))
        const discordNotifications = {
            typeOne: _.get(guild, 'notificationTypes.typeOne', false),
            typeTwo: _.get(guild, 'notificationTypes.typeTwo', false),
            typeThree: _.get(guild, 'notificationTypes.typeThree', false),
            typeFour: _.get(guild, 'notificationTypes.typeFour', false),
        }

        this.setState({ eligibleMemberOptions, displayNameInput: guild.displayName, discordNotifications })
    }

    toggleNotificationType(value, name) {
        console.log('toggling', value)
    }

    updateField(value, name) {
        this.setState({ [name]: value })
    }

    clearNotificationsForm() {
        const { guild } = this.props
        this.setState({
            discordNotifications: {
                typeOne: _.get(guild, 'notificationTypes.typeOne', false),
                typeTwo: _.get(guild, 'notificationTypes.typeTwo', false),
                typeThree: _.get(guild, 'notificationTypes.typeThree', false),
                typeFour: _.get(guild, 'notificationTypes.typeFour', false),
            }
        })
    }

    clearSettingsForm() {
        this.setState({
            displayNameInput: this.props.guild.displayName,
            errorDisplayName: null,
            settingsErrors: [],
        })
    }

    async submitGuildSettings() {
        const { displayNameInput } = this.state
        const { guildId, updateGuildDetails } = this.props
        try {
            await updateGuildDetails(guildId, { display_name: displayNameInput })
            await swal(strings.getString("GUILD_SETTINGS_UPDATED_SUCCESS", "Successfully updated Guild settings"), {
                icon: 'success',
            })
            this.toggleEditingSettings()
            this.clearSettingsForm()
        } catch (err) {
            const data = err.response.data
            const errorMessages = data.status && data.status.errors ? [data.message, ...data.status.errors] : [data.message]
            let errorState = {
                errorDisplayName: null,
                settingsErrors: [],
            }
            errorMessages.forEach(error => {
                if (error.includes("The name provided does not meet the validation pattern for a guild name:") && error.includes("{0,37}")) {
                    errorState.errorDisplayName = strings.getString("GUILD_ERROR_DISPLAY_NAME_INVALID", "The Guild Display Name does not meet the requirements. Display names should be 3 to 35 alpha numeric characters and cant start or end with a number. Non-consecutive hyphens and underscores are permitted in the middle of the name.")
                } else {
                    errorState.settingsErrors.push(error)
                }
            })
            this.setState({ ...errorState })
        }
    }

    async disbandGuild() {
        const { guildId, disband, setHasGuild, clearGuildDetails, configuration } = this.props
        const val = await swal({
            title: strings.getString("GUILD_DISBAND_IN_PROGRESS", "You're About to Disband the Guild"),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                disband: {
                    text: strings.getString("GUILD_DISBAND", "Disband Guild"),
                    value: 'disband', closeModal: false,
                },
            },
            content: (
                <div>
                    {strings.getString("GUILD_DISBAND_WARNING", "This action can't be undone, and your Guild will no longer be available for use.")}
                    <br/>
                    {strings.getString("GUILD_DISBAND_CANNOT_CREATE_ANOTHER", `After disbanding this guild you will not be able to create another guild for ${configuration.guild_create_cooldown_days} days.`, {cooldownDays:configuration.guild_create_cooldown_days})}
                </div>
            )
        })
        if (val === 'disband') {
            try {
                await disband(guildId)
                setHasGuild(false)
                clearGuildDetails()
                swal({
                    title: strings.getString("GUILD_DISBAND_SUCCESS", "You've successfully disbanded the guild"),
                    icon: 'success'
                })
            } catch (err) {
                swal(`${err.response.data.message}`, {
                    icon: 'error',
                })
            }
        }
    }

    async handleTransferGuild() {
        const { eligibleMemberOptions } = this.state
        if (eligibleMemberOptions.length === 0) {
            swal({
                title: strings.getString("UH_OH", "Uh Oh!"),
                content: (
                    <div>
                        <p>{strings.getString("GUILD_NO_MEMBERS_ELIGIBLE", "There are no current Members of the Guild eligible to be Guild Leader.")}</p>
                    </div>
                )
            })
        } else {
            const val = await swal({
                title: strings.getString("TRANSFER_LEADERSHIP", "Transfer Leadership"),
                content: (
                    <div >
                        <select id="LeadershipTransfer--memberSelection" className="GuildLeaderToolsPage__transferDropdown">
                            <option value="">{strings.getString("GUILD_SELECT_NEW_LEADER", "Select a new leader")}</option>
                            {eligibleMemberOptions.map((member, index) => {
                                const { label, value } = member
                                return (
                                    <option key={`TransferLeadership--eligibleMember${index}`} value={value}>{label}</option>
                                )
                            })}
                        </select>
                    </div>
                ),
                buttons: {
                    cancel: strings.getString("CANCEL", "Cancel"),
                    transfer: {
                        text: strings.getString("TRANSFER_LEADERSHIP", "Transfer Leadership"),
                        value: 'transfer', closeModal: false,
                    }
                }
            })

            if (val === 'transfer') {
                const transferSelection = document.getElementById("LeadershipTransfer--memberSelection")
                const selectedMemberId = !_.isEmpty(transferSelection) ? transferSelection.value : ""
                const selectedUser = _.find(this.props.members, ['guild_membership_id', selectedMemberId])
                if (_.isEmpty(selectedMemberId)) {
                    await swal({
                        title: strings.getString("UH_OH", "Uh Oh!"),
                        icon: 'warning',
                        content: (
                            <div>{strings.getString("ERROR_MAKE_A_SELECTION", "Please make a selection before submitting.")}</div>
                        )
                    })
                    this.handleTransferGuild()
                } else {
                    try {
                        await this.props.transferGuildLeadership(selectedMemberId)
                        await swal({
                            title: strings.getString("GUILD_TRANSFER_LEADERSHIP_SUCCESS", "Successfully transferred leadership!", { username: selectedUser ? selectedUser.username : "" }),
                            icon: 'success',
                        })
                    } catch (err) {
                        swal(`${err.response.data.message}`, {
                            icon: 'error',
                        })
                    }
                }
            }
        }
    }

    render() {

        const {
            // editingNotifications, discordNotifications,
            editingSettings, displayNameInput, errorDisplayName, settingsErrors,
        } = this.state
        // const { typeOne, typeTwo, typeThree, typeFour } = discordNotifications
        // const { guild } = this.props
        // const notificationsFormIcon = editingNotifications ? "times-circle" : "edit"
        const settingsFormIcon = editingSettings ? "times-circle" : "edit"

        return (
            <div className="GuildLeaderToolsPage">

                <SectionHeader title={strings.getString("GUILD_MANAGEMENT", "Guild Management")} />
                <div className="GuildLeaderToolsPage__sectionSpacer"/>
                <SectionHeader h3 title={strings.getString("MANAGE_SETTINGS", "Manage Settings")} icon={settingsFormIcon} click={this.toggleEditingSettings}/>

                <div className="GuildLeaderToolsPage__settingsForm">
                    <div className="GuildLeaderToolsPage__field">
                        <FieldInput
                            label={strings.getString("DISPLAY_NAME", "Display Name")} columnal disabled={!editingSettings}
                            name="displayNameInput" value={displayNameInput} onChange={this.updateField}
                        />
                    </div>

                    {errorDisplayName && <div className="GuildLeaderToolsPage__formMessage"><FormMessage error columnal content={errorDisplayName}/></div>}

                    {!_.isEmpty(settingsErrors) && settingsErrors.map((error, index) => {
                        return (
                            <div className="GuildLeaderToolsPage__formMessage" key={`GuildSettingsError--${index}`}><FormMessage error columnal content={error}/></div>
                        )
                    })}

                    {editingSettings && <div className="GuildLeaderToolsPage__formButtons">
                        <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("CANCEL", "Cancel")} onclick={this.toggleEditingSettings} gray medium/></div>
                        <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("SUBMIT_CHANGES", "Submit Changes")} onclick={this.submitGuildSettings} medium/></div>
                    </div>}
                </div>

                <SectionHeader h3 title={strings.getString("MODIFY_GUILD_CREST", "Modify Guild Crest")}/>
                <div className="GuildLeaderToolsPage__modifyCrest">
                    <LocLink to="/guild/crest"><Button medium label={strings.getString("MODIFY_CREST", "Modify Crest")} /></LocLink>
                </div>

                <SectionHeader title={strings.getString("SUCCESSION_RULES", "Succession Rules")} h3/>
                <div className="GuildLeaderToolsPage__successionRules">
                    <p>{strings.getString("GUILD_SETTINGS_DESCRIPTION", "If the Guild Leader deletes their account, or fails to log in for 90 days, the ownership of the guild will be transferred to the longest-serving officer in the Guild.")}</p>
                    <p>{strings.getString("GUILD_SETTINGS_RULES_ONE", "If there is no player applicable, the guild will be disbanded, effectively dead and can be removed.")}</p>
                    <p>{strings.getString("GUILD_SETTINGS_RULES_TWO", "By disbanding the guild you will remove the Guild.")}</p>
                    <p>{strings.getString("GUILD_SETTINGS_RULES_THREE", "The Guild Leader can also transfer the leadership to another player and be automatically relieved from duties.")}</p>
                    <div className="GuildLeaderToolsPage__formButtons">
                        <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("GUILD_DISBAND", "Disband Guild")} medium gray onclick={this.disbandGuild}/></div>
                        <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("TRANSFER_LEADERSHIP", "Transfer Leadership")} onclick={this.handleTransferGuild} medium/></div>
                    </div>
                </div>

                {/* <SectionHeader title={strings.getString("DISCORD_MANAGEMENT", "Discord Management")} />
                <div className="GuildLeaderToolsPage__discordManagement">
                    {!_.isEmpty(guild.discord) && <div className="GuildLeaderToolsPage__discordNotConnected">
                        <p>{strings.getString("GUILD_DISCORD_CONNECT", "Connect your Discord account with Crowfall to get immediate updates from your Guild sent to your preferred channel.")}</p>
                        <div className="GuildLeaderToolsPage__buttons">
                            <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("CONNECT_DISCORD", "Connect Discord")} medium/></div>
                        </div>
                    </div>}
                    {_.isEmpty(guild.discord) && <div className="GuildLeaderToolsPage__discordConnected">
                        <p>{strings.getString("GUILD_DISCORD_CHANNEL_CONNECTED", `Your Guild is connected to the ${guild.channel} channel on Discord.`, { channelName: guild.channel})}</p>
                        <div className="GuildLeaderToolsPage__buttons">
                            <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("REMOVE_ACCOUNT", "Remove Account")} medium gray/></div>
                            <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("UPDATE_CHANNEL", "Update Channel")} medium/></div>
                        </div>

                        <SectionHeader h3 title={strings.getString("CHANNEL_NOTIFICATIONS", "Channel Notifications")} icon={notificationsFormIcon} click={this.toggleEditingNotifications}/>
                        <p>{strings.getString("GUILD_DISCORD_MANAGE_NOTIFICATIONS", "Manage which types of notifications you want sent to your Discord by selecting or deselecting from the list below.")}</p>
                        <div className="GuildLeaderToolsPage__notificationForm">
                            <div className="GuildLeaderToolsPage__field">
                                <Toggle
                                    label={strings.getString("GUILD_DISCORD_NOTIFICATION_TYPE1", "Received application to join the Guild")}
                                    name="typeOne" value={typeOne} disabled={!editingNotifications} toggle={this.toggleNotificationType}
                                />
                            </div>
                            <div className="GuildLeaderToolsPage__field">
                                <Toggle
                                    label={strings.getString("GUILD_DISCORD_NOTIFICATION_TYPE2", "Changes to Guild memberships")}
                                    name="typeTwo" value={typeTwo} disabled={!editingNotifications} toggle={this.toggleNotificationType}
                                />
                            </div>
                            <div className="GuildLeaderToolsPage__field">
                                <Toggle
                                    label={strings.getString("GUILD_DISCORD_NOTIFICATION_TYPE3", "Modifications to the Guild Bank")}
                                    name="typeThree" value={typeThree} disabled={!editingNotifications} toggle={this.toggleNotificationType}
                                />
                            </div>
                            <div className="GuildLeaderToolsPage__field">
                                <Toggle
                                    label={strings.getString("GUILD_DISCORD_NOTIFICATION_TYPE4", "Guild Settings or Crest updated")}
                                    name="typeFour" value={typeFour} disabled={!editingNotifications} toggle={this.toggleNotificationType}
                                />
                            </div>
                            {editingNotifications && <div className="GuildLeaderToolsPage__formButtons">
                                <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("CANCEL", "Cancel")} onclick={this.toggleEditingNotifications} gray medium/></div>
                                <div className="GuildLeaderToolsPage__button"><Button label={strings.getString("SUBMIT_CHANGES", "Submit Changes")} medium/></div>
                            </div>}
                        </div>
                    </div>}
                </div> */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildLeaderToolsPage)
