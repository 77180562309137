import React from 'react'
import { connect } from 'react-redux'
import strings from '../../../strings'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'

import './styles.scss'

const mapStateToProps = state => {
  return {
    user: state.user.userProfile,
  }
}

class AnnouncementBanner extends React.Component {
  render() {
    const { user } = this.props

    return (
      <div className={`announcement-banner ${'user_id' in user ? `announcement-banner-size__small` : ``}`}>
        <h2>{strings.getString('OFFLINE_MODE', `Crowfall is offline.`)}</h2>
        <LocLink to='/news/articles/Crowfall-Update'><Button label="Learn more" /></LocLink>
      </div>
    )
  }
}

export default connect(mapStateToProps)(AnnouncementBanner)
