import React from 'react'
import { Table } from 'semantic-ui-react'

class FixedTable extends React.Component {

  render() {

    const { children, ...rest } = this.props
    return (
      <div style={{overflowX: 'auto', margin: '10px 0'}}>
        <Table {...rest} unstackable style={{minWidth: '600px'}}>
          {children}
        </Table>
      </div>
    )
  }
}

export default FixedTable
