import React from 'react'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import SectionHeader from '../../../componentLibrary/Fragments/SectionHeader'
import Button from '../../../componentLibrary/Fragments/Button'
import {getLatestGuildMembership, leaveGuild} from '../../../redux/actions/guild-management'

import './styles.scss'


const mapStateToProps = state => {
    return {
        guild: state.guildManagement.guild,
        guildId: state.guildManagement.guildId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        leaveGuild: (guildId) => dispatch(leaveGuild(guildId)),
        getLatestGuildMembership: () => dispatch(getLatestGuildMembership())
    }
}


class GuildManageMembershipPage extends React.Component {

    async handleLeaveGuild() {
        const { guildId, guild, leaveGuild, getLatestGuildMembership } = this.props
        const val = await swal({
            content: (
                <div>
                    <div style={{ fontWeight: 'bold', fontSize: "28px", lineHeight: "32px", color: "#595959", paddingBottom: "15px" }}
                        dangerouslySetInnerHTML={{ __html: strings.getString("GUILD_ABOUT_TO_LEAVE", `You're about to leave your Guild, ${guild.displayName}`, { guildName: guild.displayName }) }}
                    />
                    {strings.getString("GUILD_WARNING_LEAVING_COOLDOWN", "After leaving this guild you will not be able to join another guild for 24 hours.")}
                </div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                continue: {
                    text: strings.getString("GUILD_LEAVE", "Leave Guild"),
                    value: 'leave', closeModal: false
                }
            }
        })

        if (val === 'leave') {
            try {
                await leaveGuild(guildId)
                await getLatestGuildMembership()
                swal({
                    title: strings.getString("GUILD_LEFT_GUILD_SUCCESS", "You've successfully left the Guild"),
                    icon: 'success'
                })
            } catch (err) {
                swal(`${err.response.data.message}`, {
                    icon: 'error',
                })
            }
        }
    }

    render() {
        return (
            <div className="GuildManageMembershipPage">

                <SectionHeader title={strings.getString("MANAGE_MEMBERSHIP", "Manage Membership")} />
                <div className="GuildManageMembershipPage__section">
                    <p>{strings.getString("GUILD_MEMBER_LEAVING_TERMS", "Upon successfully leaving the Guild, you will forfeit any achieved Member Rank and will no longer have access to the Guild's online portal or in-game features.")}</p>
                    <Button label={strings.getString("GUILD_LEAVE", "Leave Guild")} medium gray onclick={() => this.handleLeaveGuild()}/>
                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildManageMembershipPage)