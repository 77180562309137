import React from 'react'
import './styles.scss'

class Accordion extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}

        this.getInitialState = this.getInitialState.bind(this)
        this.select = this.select.bind(this)
        this.theRef = React.createRef()
    }

    select(index) {
        let state = this.getInitialState()
        state[index] = !this.state[index]
        this.setState(state)
    }

    getInitialState(selectedIndex) {
        const {children} = this.props
        const initialState = children.reduce((state, item, index) => {
            state[index] = false
            return state
        }, {})
        if (selectedIndex !== -1) initialState[selectedIndex] = true
        return initialState
    }

    componentDidMount() {
        this.setState(this.getInitialState(this.props.selectedIndex))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedIndex !== this.props.selectedIndex) {
            this.setState(this.getInitialState(this.props.selectedIndex))
        }

    }

    render() {
        const {children} = this.props
        return (
            <div className="Accordion" ref={this.theRef}>
                {children.map((item, index) => React.cloneElement(item, {
                    shouldShow: this.state[index],
                    select: () => this.select(index),
                    key: index
                }))}
            </div>
        )
    }
}

export default Accordion
