import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const TermsAndConditions = () => (
  <Page contentId={assets.contentfulIds.TERMS_AND_CONDITIONS}>
  </Page>
)

export default TermsAndConditions
