import React from 'react'
import assets from '../../assets'
import Page from '../../componentLibrary/Models/Page'
import DataTable from '../../componentLibrary/Models/DataTable'
import Label from '../../componentLibrary/Fragments/Label'
import strings from '../../strings'
import Button from '../../componentLibrary/Fragments/Button'
import Papa from 'papaparse'
// import { importingStrings } from './ImportingStrings'
import './styles.scss'


class StringTranslations extends React.Component {
    componentDidMount() {
        // console.log("Merged Strings", importingStrings())
    }

    modelStrings = (strings) => {
        let arr = Object.keys(strings).map(key => {
            return strings[key]
        })
    
    return arr.map((string, i) => ({id: i, elements: [<Label label={string.id}/>, <Label label={string["en-US"]}/>, <Label label={string["fr-FR"] || '***MISSING***'}/>, <Label label={string["de-DE"] || '***MISSING***'}/>, <Label label={string["pt-PT"] || '***MISSING***'}/>, <Label label={string["es-ES"] || '***MISSING***'}/>]}))
    }
    
    convertAllStrings = () => {
        const strings = this.getFormattedStrings()
        const csvStrings = Papa.unparse(strings)
        this.downloadStrings(csvStrings, 'all')
    }
    
    convertMissingStrings = () => {
        let strings = this.getFormattedStrings()
        strings.data = strings.data.filter(translations => {
            const isMissingTranslation = translations.includes(undefined)
            return isMissingTranslation === true
        })
        const csvStrings = Papa.unparse(strings)
        this.downloadStrings(csvStrings, 'missing')
    }

    getFormattedStrings = () => {
        const allStrings = strings.getStringsTable()
        const stringData = Object.values(allStrings).map(stringObject => {
            return Object.values(stringObject)
        })
        const formattedStrings = { 
            "fields": ["Description", "English", "French", "German", "Portuguese", "Spanish"],
            "data": stringData
        }
        return formattedStrings
    }
    
    downloadStrings = (strings, dataType) => {
        const element = document.createElement('a')
        element.setAttribute('href', `data:text/plain;charset=utf-8,${String.fromCharCode(0xFEFF)}${encodeURIComponent(strings)}`)
        element.setAttribute('download', `${dataType}_strings_export_${Date.now()}.csv`)
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click();
        document.body.removeChild(element)
    }
    
    render() {

        const rows = this.modelStrings(strings.getStringsTable())
        const labels = [{label: 'Description' },  {label: 'English'},  {label: 'French'},  {label: 'German'},  {label: 'Portuguese'},  {label: 'Spanish'}]

        return (
            <Page header={{ title: `Website String Translations`, banner: assets.staticImageUrls.HERO_BANNER_ASSET }}>

                <div className='StringTranslations__buttons'>
                <Button onclick={this.convertAllStrings}>Download All Strings</Button>
                <Button onclick={this.convertMissingStrings}>Download Missing Strings</Button>
                </div>

                <DataTable labels={labels} rows={rows}/>
            </Page>
        )
    }
}

export default StringTranslations