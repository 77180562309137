import React from 'react'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import strings from '../../../strings'
import { connect } from 'react-redux'
import { 
  fetchWarStories,
  searchWarStories, 
  paginateWarStories, 
  clearWarStoriesSearchCriteria, 
  clearWarStories
} from '../../../redux/actions/war-stories'

import Page from '../../../componentLibrary/Models/Page'
import TitleBar from '../../../componentLibrary/Fragments/TitleBar'
import Searchbar from '../../../componentLibrary/Elements/Searchbar'
import SearchResultsDisplay from '../../../componentLibrary/Fragments/SearchResultsDisplay'
import Pagination from '../../../componentLibrary/Elements/Pagination'
import WarStoryCard from '../../../componentLibrary/Models/WarStoryCard'

import _ from 'lodash'
import './styles.scss'


const mapStateToProps = state => {
  const { warStories } = state
  const { searched, fetched, searchCriteria, paginated, paginationCriteria } = warStories
  return {
    fetched, 
    searched,
    searchCriteria,
    paginationCriteria,
    paginated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchWarStories: () => dispatch(fetchWarStories()),
    searchWarStories: (criteria) => dispatch(searchWarStories(criteria)),
    paginateWarStories: (pagination) => dispatch(paginateWarStories(pagination)),
    clearSearchCriteria: () => dispatch(clearWarStoriesSearchCriteria()),
    clearWarStories: () => dispatch(clearWarStories())
  }
}

class WarStoriesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      subtitle: null,
      banner: null,
      tagline: null,
      currentCategory: null,
    }
  }

   async componentDidMount() {
    const content = await contentful.getEntry(assets.contentfulIds.WARSTORIES_PAGE)
    this.setState({
      title: content.title,
      subtitle: content.pageTitle,
      banner: _.result(content, 'banner.assetUrl'),
      tagline: content.tagline
    })
    await this.props.fetchWarStories()
  }

  componentWillUnmount() {
    this.props.clearWarStories()
  }

  render() {

    const { title, banner, tagline } = this.state
    const { searched, fetched, searchCriteria, paginated, paginationCriteria, searchWarStories, paginateWarStories, clearSearchCriteria } = this.props
    const { limit, page } = paginationCriteria

    const disableResultsDisplay = !!searchCriteria && fetched ? false : true

    let categorizedStories = [];
    let storiesPlaceholder = []

    for (let i = 0; i < paginated.length; i++) {
      const { categoryName } = paginated[i]
      if (paginated[i + 1]) {
        if (categoryName === paginated[i+1].categoryName) {
          storiesPlaceholder.push(paginated[i])
        } else {
          storiesPlaceholder.push(paginated[i])
          categorizedStories.push({ categoryName, stories: storiesPlaceholder })
          storiesPlaceholder = []
        }
      } else {
        storiesPlaceholder.push(paginated[i])
        categorizedStories.push({ categoryName, stories: storiesPlaceholder })
      }
    }

    return (
      <Page header={{title: title, banner: banner}}>
        <div className="WarStoriesPage">
          <div className='WarStoriesPage__description' dangerouslySetInnerHTML={{__html: tagline}}/>
          <div className='WarStoriesPage__search'>
            <div className='WarStoriesPage__searchBar'>
              <Searchbar search={searchWarStories} clear={clearSearchCriteria}/>
            </div>
            <SearchResultsDisplay resultsCount={searched.length} disabled={disableResultsDisplay} noResultsText={strings.getString("NO_MATCHING_WAR_STORIES", "I'm sorry, there are no War Stories matching your criteria. Please refine your search.")}/>
          </div>

          <div className='WarStoriesPage__content'>
            {categorizedStories.length > 0 && categorizedStories.map((category, index) => {
              const { categoryName, stories } = category
              let className = 'WarStoriesPage__stories '
              if (stories.length % 2 === 1) className += 'WarStoriesPage__stories--odd'

              return (
                <div key={`${categoryName}-${index}`} className='WarStoriesPage__storiesGrid'>
                  <TitleBar title={categoryName}/>
                  <div className={className}>
                    {stories.map(story => {
                      const { contentful_id: id } = story
                      return (
                        <div key={id} className='WarStoriesPage__story'>
                          <WarStoryCard  story={story}/>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>

          {searched.length > 0 && <Pagination paginate={paginateWarStories} limit={limit} list={searched} page={page}/>}
        </div>
      </Page>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarStoriesPage)
