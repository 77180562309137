import {
    SET_HAS_GUILD,
    SET_GUILD_FETCHED,
    SET_GUILD_APPLICATIONS,
    SET_GUILD_MEMBERS,
    SEARCH_GUILD_MEMBERS,
    CLEAR_MEMBERS_SEARCH_CRITERIA,
    PAGINATE_GUILD_MEMBERS,
    SET_GUILD_MANAGEMENT_DETAILS,
    SET_GUILD_INVITES,
    CLEAR_GUILD_DETAILS,
    SET_GUILD_CHAT,
    SEARCH_GUILD_CHAT,
    CLEAR_GUILD_CHAT_SEARCH_CRITERIA,
    SET_GUILD_LOG,
    SEARCH_GUILD_LOG,
    CLEAR_GUILD_LOG_SEARCH_CRITERIA,
    PAGINATE_GUILD_LOG, 
    SET_GUILD_MEMBERSHIP_RECORD_INFO,
} from '../actionTypes'


const initialState = {
    hasGuild: false,
    guildFetched: false,

    guild: {},
    guildId: null,
    guildWallet: {},
    guildNotifications: [],
    guildConfiguration: {},
    guildInvites: [],
    guildApplications: [],
    guildRank: null,

    members: [],
    membersSearchCriteria: null,
    membersSearched: [],
    membersPaginationCriteria: {
        limit: 10,
        page: 1,
    },
    membersPaginated: [],

    chat: [],
    chatSearchCriteria: null,
    chatSearched: [],

    guildLog: [],
    logSearchCriteria: null,
    logSearched: [],
    logPaginationCriteria: {
        limit: 20,
        page: 1,
    },
    logPaginated: [],
    latestGuildMembership: {}
}

const guildManagement = (state = initialState, action) => {
    switch(action.type) {
        case SET_HAS_GUILD:
            return {
                ...state,
                hasGuild: action.payload
            }
        case SET_GUILD_FETCHED:
            return {
                ...state,
                guildFetched: action.payload,
            }
        case SET_GUILD_MANAGEMENT_DETAILS:
            return {
                ...state,
                ...action.payload
            }
        case SET_GUILD_APPLICATIONS:
            return {
                ...state,
                guildApplications: action.payload 
            }
        case SET_GUILD_MEMBERS:
            return {
               ...state,
               members: action.payload 
            }
        case SEARCH_GUILD_MEMBERS:
            const membersSearchCriteria = action.payload === undefined ? state.membersSearchCriteria : action.payload
            const membersSearched = membersSearchCriteria ? state.members.filter(membersSearchCriteria) : state.members
            return {
                ...state,
                ...{ membersSearchCriteria, membersSearched }
            }
        case CLEAR_MEMBERS_SEARCH_CRITERIA:
            return {
                ...state,
                membersSearchCriteria: initialState.membersSearchCriteria,
                membersSearched: state.members,
            }
        case PAGINATE_GUILD_MEMBERS:
            const membersLimit = state.membersPaginationCriteria.limit
            const membersPage = action.payload ? action.payload : state.membersPaginationCriteria.page
            const membersPaginated = paginate(state.membersSearched, membersLimit, membersPage)
            return {
                ...state,
                membersPaginationCriteria: {
                    limit: membersLimit,
                    page: membersPage,
                },
                membersPaginated,
            }
        case SET_GUILD_INVITES:
            return {
                ...state,
                guildInvites: action.payload
            }
        case SET_GUILD_CHAT:
            return {
                ...state,
                chat: action.payload,
            }
        case SEARCH_GUILD_CHAT:
            const chatSearchCriteria = action.payload === undefined ? state.chatSearchCriteria : action.payload
            const chatSearched = chatSearchCriteria ? state.chat.filter(chatSearchCriteria) : state.chat
            return {
                ...state,
                chatSearchCriteria,
                chatSearched
            }
        case CLEAR_GUILD_CHAT_SEARCH_CRITERIA:
            return {
                ...state,
                chatSearchCriteria: initialState.chatSearchCriteria,
                chatSearched: state.chatSearched,
            }
        case SET_GUILD_LOG:
            return {
                ...state,
                guildLog: action.payload,
            }
        case SEARCH_GUILD_LOG:
            const logSearchCriteria = action.payload === undefined ? state.logSearchCriteria : action.payload
            const logSearched = logSearchCriteria ? state.guildLog.filter(logSearchCriteria) : state.guildLog
            return {
                ...state,
                ...{ logSearchCriteria, logSearched }
            }
        case CLEAR_GUILD_LOG_SEARCH_CRITERIA:
            return {
                ...state,
                logSearchCriteria: initialState.logSearchCriteria,
                logSearched: state.guildLog,
            }
        case PAGINATE_GUILD_LOG:
            const logLimit = state.logPaginationCriteria.limit
            const logPage = action.payload ? action.payload : state.logPaginationCriteria.page
            const logPaginated = paginate(state.logSearched, logLimit, logPage)
            return {
                ...state,
                logPaginationCriteria: {
                    limit: logLimit,
                    page: logPage,
                },
                logPaginated,
            }
        case CLEAR_GUILD_DETAILS:
            return {
                ...initialState,
                hasGuild: state.hasGuild
            }
        case SET_GUILD_MEMBERSHIP_RECORD_INFO:
            return {
                ...state,
                latestGuildMembership: action.payload
            }
        default:
            return state
    }
}

function paginate(list, limit, page) {
    if (limit === null) return list
    const limitInt = parseInt(limit)
    const start = limitInt * (page - 1)
    const end = start + limitInt
    return list.slice(start, end)
}

export default guildManagement