import React from 'react'
import contentful from '../../../utilities/contentful'
import Page from '../../../componentLibrary/Models/Page'
import SearchBar from '../../../componentLibrary/Elements/Searchbar'
import ArticleList from '../../../componentLibrary/Models/ArticleList'
import MarketingCTA from '../../../componentLibrary/Elements/MarketingCTA'
import SearchResultsDisplay from '../../../componentLibrary/Fragments/SearchResultsDisplay'
import strings from '../../../strings'
import { connect } from 'react-redux'
import { fetchArticles, searchArticles, clearArticles, clearArticlesSearchCriteria } from '../../../redux/actions/articles'
import assets from '../../../assets'
import './styles.scss'



const mapDispatchToProps = dispatch => {
  return {
    fetchArticles: () => dispatch(fetchArticles()),
    searchArticles: (criteria) => dispatch(searchArticles(criteria)),
    clearArticles: () => dispatch(clearArticles()),
    clearArticlesSearchCriteria: () => dispatch(clearArticlesSearchCriteria())
  }
}

const mapStateToProps = (state) => {
  const { session, articles } = state
  const { searched, fetched, searchCriteria } = articles
  return {
    session,
    isLoggedIn: !!session,
    fetched,
    searched,
    searchCriteria
  }
}

class ArticlesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callout: null
    }
  }

  async componentDidMount() {
    await this.props.fetchArticles()
    // const callout = await contentful.getEntry(assets.contentfulIds.NEWS_MKTG_CALLOUT)
    // this.setState({ callout: callout.richText })
  }

  componentWillUnmount() {
    this.props.clearArticles()
  }

  render() {

    const { isLoggedIn, searchArticles, fetched, searched, searchCriteria, clearArticlesSearchCriteria } = this.props
    const { callout } = this.state

    const disableResultsDisplay = !!searchCriteria && fetched ? false : true

    return (
      <Page titleName="NEWS" addAccent='true' header={{banner: assets.staticImageUrls.HERO_BANNER_ASSET}}>
        <div className='ArticlesPage'>

          {/*{(!isLoggedIn) &&*/}
          {/*  <MarketingCTA to='/register?t=sign_up&p=articles' buttonLabel={strings.getString("SIGN_UP", "Sign UP")}>*/}
          {/*    <span dangerouslySetInnerHTML={{ __html: callout }}/>*/}
          {/*  </MarketingCTA>*/}
          {/*}*/}
          <div className='ArticlesPage__searchBar'>
            <div className='ArticlesPage__searchBarField'>
              <SearchBar search={searchArticles} clear={clearArticlesSearchCriteria}/>
            </div>
          </div>
          <div className='ArticlesPage__searchResultsDisplay'>
            <SearchResultsDisplay resultsCount={searched.length} disabled={disableResultsDisplay} noResultsText={strings.getString("NO_MATCHING_ARTICLES", "I'm sorry, there are no articles matching your criteria. Please refine your search.")}/>
          </div>

          <ArticleList articles={searched} />
        </div>
      </Page>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesPage)

