/*

MODEL: ProductTile
accepts a store product and appropriately directs the user on clicking

PROPERTY  DEFAULT  REQUIREMENT  TYPE    DESCRIPTION
img                Required     URL     Used as the background image for the tle
title              Required     String  Descriptive title for the product
subtitle           Required     String  Intended to be displayed as the price for the product
link               Optional     String  serves as an Internal LocLink.
click              Optional     String  Executed onClick.

*/

import React from 'react'
import LocLink from '../LocLink'
import './styles.scss'


const ProductTile = (props) => {
    
    const { img, title, subtitle, link, click } = props

    return (
        <LocLink to={link}>
            <div className="ProductTile" style={{ backgroundImage: `url(${img})` }} onClick={ click ? () => click() : null }>
                <div className="ProductTile__productInfo">
                    <div className="ProductTile__title">{title}</div>
                    <div className="ProductTile__subtitle">{subtitle}</div>
                </div>
            </div>
        </LocLink>
    )
}

export default ProductTile