import React from 'react'
import strings from '../../../../strings'
import FieldInput from '../../../../componentLibrary/Models/FieldInput'
import Button from '../../../../componentLibrary/Fragments/Button'
import './styles.scss'


class GuildInvitePlayerModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = { loading: false }
    }

    async submit() {
        this.setState({ loading: true })
        await this.props.handleInvitePlayer()
        this.setState({ loading: false })
    }

    render() {

        const { clearInviteModal, handleInvitePlayer, updateInput } = this.props
        const { loading } = this.state

        return (
            <div className="GuildInvitePlayerModal">
                <div className="GuildInvitePlayerModal__header">{strings.getString("INVITE_PLAYER", 'Invite Player')}</div>
                <FieldInput
                    placeholder={strings.getString("USERNAME", "Username")}
                    onChange={updateInput} name="inviteInput"
                />
                <div className="GuildInvitePlayerModal__buttons">
                    <div className="GuildInvitePlayerModal__button"><Button medium loading={loading} onclick={clearInviteModal} label={strings.getString("CANCEL", 'Cancel')}/></div>
                    <div className="GuildInvitePlayerModal__button"><Button medium loading={loading} green onclick={handleInvitePlayer} label={strings.getString("SEND_INVITATION", 'Send Invitation')}/></div>
                </div>
            </div>
        )
    }
}

export default GuildInvitePlayerModal