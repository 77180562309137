import React from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import { Helmet } from 'react-helmet'

import strings from '../../../strings'
import contentful from '../../../utilities/contentful'
import Page from '../../../componentLibrary/Models/Page'
import PageNotFound from '../../../componentLibrary/Models/PageNotFound'
import SocialButtons from '../../../componentLibrary/Elements/SocialButtons'
import MarketingFooter from '../../../componentLibrary/Elements/MarketingFooter'
import PageColumn from '../../../componentLibrary/Fragments/PageColumn'
import Tag from '../../../componentLibrary/Fragments/Tag'

import './styles.scss'


class BlogPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fetched: false,
            post: {},
            noContentFound: false,
        }
    }

    async componentDidMount() {
        this.getContent()
        this.setState({ fetched: true })
    }

    async getContent() {
        const { uri } = this.props.match.params
        const posts = await contentful.getEntries('blogPost', `fields.uri=${uri}`)
        if (_.isEmpty(posts)) {
            this.setState({ noContentFound: true })
            return
        }
        const post = posts[0]
        post.bannerAssetUrl = _.result(post, 'bannerImage.assetUrl')
        post.tagsString = (_.result(post, 'tags', [])).reduce((string, tag, index) => {
            return index === 0 ? tag.name : string + ', ' + tag.name
        }, '')
        this.setState({ post: post || {} })
    }

    render() {

        const { fetched, post, noContentFound } = this.state
        const { title, author, publishDate, blogContent, tags, bannerAssetUrl, tagsString  } = post
        const pageTitle = `${title} - ${author}`
        const formattedDate = moment(publishDate).format('dddd, MMMM D, YYYY')
        return (
            <React.Fragment>
                {fetched && noContentFound &&
                    <PageNotFound returnLabel={strings.getString("BACK_TO_BLOG", "Back to Blog")} returnLink="/blog"/>
                }

                {fetched && !noContentFound &&
                    <Page isMarketing header={{ title: pageTitle, subtitle: formattedDate, banner: bannerAssetUrl }}>

                        {!_.isEmpty(tagsString) &&
                            <Helmet>
                                <meta name="keywords" content={`crowfall throne war mmo, Monumental, LLC - Kick-Starter Game, Guild versus Guild (GvG) / Realm versus Realm (RvR), Player versus Player - Competitive Gaming, Campaign Worlds and Siege Strategy, Open World PvP MMORPG, Crafting and Merchant Based-Economy, ${tagsString}`}/>
                            </Helmet>
                        }

                        <div className='BlogPage'>
                            <div className='BlogPage__socialButtons'>
                                <SocialButtons facebook twitter mail title={pageTitle} />
                            </div>
                            <PageColumn>
                                {tags &&
                                    <div className="BlogPage__tags">
                                        {tags.map(tag => {
                                            const { name, contentful_id: id } = tag
                                            const tagLink = () => {
                                                this.props.history.push(`/blog?tags=${_.kebabCase(name)}`)
                                            }
                                            return (
                                                <div className='BlogPage__tag' key={id}>
                                                    <Tag selectable label={name} onClick={tagLink}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                <div dangerouslySetInnerHTML={{__html: blogContent}}/>
                            </PageColumn>
                            <MarketingFooter returnLink="/blog" returnString="BACK_TO_BLOG"/>
                        </div>

                    </Page>
                }
            </React.Fragment>
        )
    }
}

export default BlogPage
