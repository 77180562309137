import React from 'react'
import _ from 'lodash'
import strings from '../../../strings'
import FixedTable from '../../../componentLibrary/Elements/FixedTable'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import { Grid, Table, Label } from 'semantic-ui-react'
import './styles.scss'


const OrderDetails = (props) => {

    const { order } = props

    return (
        <div className="OrderDetails">
            <div className="OrderDetails__header">{strings.getString('ORDER_DETAILS', 'Order Details')}</div>

            <Grid>

                  <Grid.Row>
                    <Grid.Column width={16}>
                      <FixedTable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan={2}><span style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>{strings.getString('ORDER_DETAILS', 'Order Details')} {order.deferred_purchase_id ? (

                              <LocLink to={`/account/layaway/${order.deferred_purchase_id}`}><Label
                                color="blue">{strings.getString('LAYAWAY_DETAILS', 'Layaway Details')}</Label></LocLink>

                            ) : null}</span></Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>{strings.getString('STATUS', 'Status')}</Table.Cell>
                            <Table.Cell>{_.startCase(order.status)}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{strings.getString('BILLING_DESCRIPTOR', 'Billing Descriptor')}</Table.Cell>
                            <Table.Cell>{order.billing_descriptor}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{strings.getString('ORDER_ID', 'Order ID')}</Table.Cell>
                            <Table.Cell>{order.transaction_id}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{strings.getString('PAYMENT_PROCESSOR_ID', 'Payment Processor ID')}</Table.Cell>
                            <Table.Cell>{order.external_id || order.transaction_id}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{strings.getString('BILLING_DATE', 'Billing Date')}</Table.Cell>
                            <Table.Cell>{(new Date(order.date_created)).toLocaleString()}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{strings.getString('CHARGE_AMOUNT', 'Charge Amount')}</Table.Cell>
                            <Table.Cell>{_.result(order, 'display_strings.total')}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>{strings.getString('WALLET_AMOUNT', 'Wallet Amount')}</Table.Cell>
                            <Table.Cell>{_.result(order, 'display_strings.wallet_total')}</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </FixedTable>
                    </Grid.Column>
                  </Grid.Row>
            </Grid>
        </div>
    )
}

export default OrderDetails
