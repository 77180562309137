import React from 'react'
import CrownAmount from '../../../componentLibrary/Fragments/CrownAmount'
import './styles.scss'


const CrownsDisplay = props => {

    const { balance, guild } = props
    return (
        <div className="CrownsDisplay">
            <CrownAmount amount={balance} flipIcon orange guild={guild}/>
        </div>
    )
}

export default CrownsDisplay
