/*

Model: PageNotFound
Intended to be displayed in place of Page Content when no content can be found matching the URL's ID parameter.

PROPERTY     DEFAULT  REQUIREMENT  TYPE    DESCRIPTION
returnLink            required     String  return link relevant to the URL.
returnLabel           required     String  label for button intended to redirect user to relevan local URL

*/


import React from 'react'
import strings from '../../../strings'
import Page from '../Page'
import './styles.scss'
import LocLink from '../../Elements/LocLink'
import Button from '../../Fragments/Button'


class PageNotFound extends React.Component {
    render() {

        const { returnLink, returnLabel } = this.props

        return (
            <Page>
                <div className="PageNotFound">
                    <div className="PageNotFound__header">{strings.getString("UH_OH", "Uh Oh!")}</div>
                    <div className="PageNotFound__subheader">{strings.getString("PAGE_DOES_NOT_EXIST", "The page you're looking for doesn't exist.")}</div>
                    <LocLink to={returnLink}>
                        <Button label={returnLabel}/>
                    </LocLink>
                </div>
            </Page>
        )
    }
}

export default PageNotFound