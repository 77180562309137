import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'semantic-ui-react'
import swal from '@sweetalert/with-react'
import Button from '../../../../componentLibrary/Fragments/Button'
import _ from 'lodash'
import strings from '../../../../strings'
import { clearModalContent } from '../../../../redux/actions'
import { recoverEmail } from '../../../../redux/actions/user'
import './styles.scss'


const mapDispatchToProps = dispatch => {
  return {
    clearModalContent: () => dispatch(clearModalContent()),
    recoverEmail: (username) => dispatch(recoverEmail(username)),
  }
}

class ForgotEmail extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      username: '',
      loading: false,
    }
    this.clearModal = this.clearModal.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }


  handleUsername = (e, { value }) => {
    this.setState({
      username: value,
    })
  }

  clearModal() {
    this.props.clearModalContent()
    this.setState({ username: '' })
  }

  async submitForm() {
    if (_.isEmpty(this.state.username)) {
      this.generateErrorModal()
      return
    }
    this.setState({ loading: true })
    try {
      await this.props.recoverEmail(this.state.username)
      swal(`${strings.getString('CHECK_EMAIL_FOR_NEXT_STEPS', 'Please check your email for next steps')}`, {
        icon: 'success',
      })
      this.clearModal()
    } catch (err) {
      this.generateErrorModal()
    }
    this.setState({ loading: false })
  }

  generateErrorModal() {
    swal(`${strings.getString("ERROR_FORGOT_EMAIL", "Uh Oh! Unable to Recover Email Address.")}`, {
      icon: 'error',
    })
  }


  render() {
    const { loading } = this.state
    
    return (
      <div className="ForgotEmail">
        <div className="ForgotEmail__header">{strings.getString('ACCOUNT_FORGOT_EMAIL', 'I Forgot my Email Address')}</div>

        <p>
          {strings.getString('FORGOT_EMAIL_HELP_INFO', 'Enter your account name and we will send the email address associated with the account.')}
        </p>

        <Form>
          <Form.Input required autoComplete="off" type="text" placeholder={strings.getString("ACCOUNT_NAME", "Account Name")} name='username' onChange={this.handleUsername}/>
        </Form>

        <div className="ForgotEmail__buttons">
          <div className="ForgotEmail__button"><Button medium gray onclick={this.clearModal} loading={loading} label={strings.getString("CANCEL", "Cancel")}/></div>
          <div className="ForgotEmail__button"><Button medium onclick={this.submitForm} loading={loading} label={strings.getString("SEND", "send")}/></div>
        </div>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(ForgotEmail)