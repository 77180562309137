import React from 'react'
import GalleryRow from '../../../componentLibrary/Models/GalleryRow'
import PromoBanner from '../../../componentLibrary/Elements/PromoBanner'
import MediaTile from '../../../componentLibrary/Elements/MediaTile'
import AreaScroll from '../../../componentLibrary/Elements/AreaScroll'
import GoldBar from '../../../componentLibrary/Fragments/GoldBar'
import './styles.scss'
import strings from '../../../strings'


const PromoNewsSection = props => {

    let { media, promos } = props

    let className = 'PromoNewsSection'
    if (promos.length === 0) className += ' PromoNewsSection--noPromos'
    if (promos.length === 1) className += ' PromoNewsSection--singlePromo'

    let promoClassName = 'PromoNewsSection__promos'
    if (promos.length === 2) promoClassName += ' PromoNewsSection__promos--2'
    if (promos.length === 3) promoClassName += ' PromoNewsSection__promos--3'
    if (promos.length === 4) promoClassName += ' PromoNewsSection__promos--4'

    return (
        <div className={className}>
            <div className={promoClassName}>
                {promos && promos.length > 0 && promos.map((promo, index) => (
                    <div className='PromoNewsSection__promo' key={`${promo.contenful_id}_${index}`}>
                        <PromoBanner promo={promo}/>
                    </div>
                ))}
            </div>

            {/*<div className='PromoNewsSection__mediaColumn'>*/}
            {/*    <div className='PromoNewsSection__mediaTitle'>*/}
            {/*        <div className="PromoNewsSection__mediaLabel">{strings.getString("LATEST_NEWS", "Latest News")}</div>*/}
            {/*        <GoldBar h3/>*/}
            {/*    </div>*/}
            {/*    {media && media.length > 0 && media.map((media, index) => {*/}
            {/*        const { img, title, link, date } = media*/}
            {/*        return (*/}
            {/*            <div className='PromoNewsSection__media' key={`${title}--${index}`}>*/}
            {/*                <MediaTile thumb={img} title={title} link={link} date={date}/>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</div>*/}

            {/*<div className='PromoNewsSection__mediaRow'>*/}
            {/*    <div className='PromoNewsSection__mediaTitle'>*/}
            {/*        <div className="PromoNewsSection__mediaLabel">{strings.getString("LATEST_NEWS", "Latest News")}</div>*/}
            {/*        <GoldBar h3/>*/}
            {/*    </div>*/}
            {/*    <AreaScroll>*/}
            {/*        <GalleryRow gallery={media}/>*/}
            {/*    </AreaScroll>*/}
            {/*</div>*/}
        </div>
    )
}

export default PromoNewsSection