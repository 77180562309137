import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import _ from 'lodash'
import strings from '../../../strings'
import './styles.scss'

const DISTRIBUTION = 'DISTRIBUTION'
const VENDORS      = 'VENDORS'
const TRADERS      = 'TRADERS'

class PartnersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      distributors: [],
      vendors: [],
      traders: []
    }
  }

  async componentDidMount() {
    const partners = _.sortBy(await contentful.getEntries('partner', null), 'displayWeight').map(partner => {
      partner.logoUrl = _.result(partner, 'logo.assetUrl')
      return partner
    })

    const distributors = partners.filter(partner => partner.type === DISTRIBUTION)
    const vendors      = partners.filter(partner => partner.type === VENDORS)
    const traders      = partners.filter(partner => partner.type === TRADERS)

    this.setState({
      distributors,
      vendors,
      traders
    })
  }

  render() {
    const { distributors, vendors, traders } = this.state

    return (
      <div className="PartnersPage">
        <Page contentId={assets.contentfulIds.PARTNERS_PAGE}>

          <div className="PartnersPage__section">
            <h1 className="PartnersPage__title">{strings.getString("DISTRIBUTORS", "Distributors")}</h1>
            <div className="PartnersPage__logos">
              {distributors.map(distributor => {
                return <a rel="noopener noreferrer" target="_blank" href={distributor.url} key={distributor.alt}><img className="PartnersPage__logo" src={distributor.logoUrl} alt={distributor.alt} /></a>
              })}
            </div>
          </div>

          <div className="PartnersPage__section">
            <h1 className="PartnersPage__title">{strings.getString("TRUSTED_TRADERS", "Trusted Traders")}</h1>
            <div className="PartnersPage__logos">
              {traders.map(trader => {
                return <a rel="noopener noreferrer" target="_blank" href={trader.url} key={trader.alt}><img className="PartnersPage__logo" src={trader.logoUrl} alt={trader.alt}/></a>
              })}
            </div>
          </div>

          <div className="PartnersPage__section">
            <h1 className="PartnersPage__title">{strings.getString("VENDORS", "Vendors")}</h1>
            <div className="PartnersPage__logos">
              {vendors.map(vendor => {
                return <a rel="noopener noreferrer" target="_blank" href={vendor.url} key={vendor.alt}><img className="PartnersPage__logo" src={vendor.logoUrl} alt={vendor.alt}/></a>
              })}
            </div>
          </div>

        </Page>
      </div>
    )
  }
}

export default PartnersPage
