import React from 'react'
import _ from 'lodash'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import Page from '../../../componentLibrary/Models/Page'
import TitleBar from '../../../componentLibrary/Fragments/TitleBar'
import TeamAsset from './TeamAsset'
import TeamMemberAsset from './TeamMemberAsset'
import ECSFooter from '../ECSFooter'
import BackToECSButton from '../BackToECSButton'
import './styles.scss'


class ECSTeamsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: null,
            bannerAsset: null,
            tagline: null,
            body: null,
            footer: null,
            events: [],
        }
    }

    componentDidMount() {
        this.getContent()
    }

    async getContent() {
        const content = await contentful.getEntry(assets.contentfulIds.ECS_TEAMS_PAGE)
        const events = _.orderBy(await contentful.getEntries("ecsEventdate"), "displayWeight").filter(event => !_.isEmpty(event.associatedTeams))        

        this.setState({
            title: content.title,
            bannerAsset: _.result(content, "bannerAsset.assetUrl"),
            tagline: content.tagline,
            body: content.body,
            footer: content.footer,
            events,
        })
    }


    render() {

        const { title, bannerAsset, tagline, body, footer, events } = this.state

        return (
            <Page header={{ title, banner: bannerAsset }} pageFooter={<ECSFooter/>}>
                <div className="ECSTeamsPage">

                    {!_.isEmpty(tagline) && <div className="ECSTeamsPage__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>}
                    
                    {!_.isEmpty(body) && <div className="ECSTeamsPage__body" dangerouslySetInnerHTML={{  __html: body }}/>}

                    {!_.isEmpty(events) &&
                        <div className="ECSTeamsPage__list">
                            {events.map(event => {
                                const { title: eventTitle, associatedTeams, contentful_id: eventId } = event
                                return (
                                    <div className="ECSTeamsPage__event" key={`event-${eventId}`}>
                                        <TitleBar title={eventTitle} small/>

                                        <div className="ECSTeamsPage__teams">
                                            {associatedTeams.map(team => {
                                                const { asset, teamName, teamMembers, contentful_id: teamId } = team
                                                return (
                                                    <div className="ECSTeamsPage__team" key={`team-${teamId}`}>
                                                        <TeamAsset name={teamName} asset={_.result(asset, "assetUrl")}/>
                                                        <div className="ECSTeamsPage__teamMembers">
                                                            {!_.isEmpty(teamMembers) && teamMembers.map(member => {
                                                                const { contentful_id: memberId, username, memberPosition, asset } = member
                                                                return (
                                                                    <div style={{ order: memberPosition === "captain" ? -1 : null }}>
                                                                        <TeamMemberAsset username={username} position={memberPosition} asset={_.result(asset, "assetUrl")} key={`member-${memberId}`}/>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }

                    {!_.isEmpty(footer) && <div className="ECSTeamsPage__footer" dangerouslySetInnerHTML={{ __html: footer }}/>}

                    <br/>
                    <BackToECSButton/>
                </div>
            </Page>
        )
    }
}

export default ECSTeamsPage