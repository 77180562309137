import React from 'react'
import moment from 'moment-timezone'
import strings from '../../../strings'
import Page from '../../../componentLibrary/Models/Page'
import PageNotFound from '../../../componentLibrary/Models/PageNotFound'
import PageColumn from '../../../componentLibrary/Fragments/PageColumn'
import MarketingFooter from '../../../componentLibrary/Elements/MarketingFooter'
import SocialButtons from '../../../componentLibrary/Elements/SocialButtons'
import contentful from '../../../utilities/contentful'
import _ from 'lodash'
import './styles.scss'


class ArticlePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fetched: false,
      entry: {},
      noContentFound: false,
    }
    this.getContent = this.getContent.bind(this)
  }

  async componentDidMount() {
    await this.getContent().catch(console.error)
    this.setState({ fetched: true })
  }

  async getContent() {
    const { article } = this.props.match.params
    const entries = await contentful.getEntries('article', `fields.uri=${article}`)
    if (_.isEmpty(entries)) {
      this.setState({ noContentFound: true })
      return
    }
    const entry = entries[0]
    entry.bannerAssetUrl = _.result(entry, 'bannerAsset.assetUrl')
    entry.thumbAssetUrl = _.result(entry, 'thumb.assetUrl')
    entry.forumLink = entry.forumLinkUrl
    this.setState({ entry })
  }

  render() {

    const { fetched, entry, noContentFound } = this.state
    const { title, date, bannerAssetUrl, description, forumLink } = entry
    const formattedDate = moment(date).format('dddd, MMMM D, YYYY')

    return(
      <React.Fragment>
        {fetched && noContentFound &&
          <PageNotFound returnLabel={strings.getString("BACK_TO_NEWS", "Back to News")} returnLink="/news/articles"/>
        }

        {fetched && !noContentFound &&
          <Page isMarketing='true' header={{title: title, subtitle: formattedDate, banner: bannerAssetUrl}}>
            <div className="ArticlePage">
              <div className="ArticlePage__socialButtons">
                <SocialButtons facebook twitter mail title={title}/>
              </div>
              <PageColumn>
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                <MarketingFooter forumLink={forumLink} returnLink="/news/articles" returnString="BACK_TO_NEWS"/>
              </PageColumn>
            </div>
          </Page>
        }
      </React.Fragment>
    )
  }
}

export default ArticlePage
