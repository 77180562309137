import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import { Button, Container, Grid } from 'semantic-ui-react'
import LocLink from '../../componentLibrary/Elements/LocLink'
import strings from '../../strings'

const Combinations = () => (
  <Page contentId={assets.contentfulIds.COMBINATIONS_PAGE}>
    <Container>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 0 0 0',
      }}>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8} textAlign='center'>
              <Button as={LocLink} to='/races' className="Button__ACE Button__ACE-large">{strings.getString("MORE_ABOUT_RACES", "More about Races")}</Button>
            </Grid.Column>
            <Grid.Column width={8} textAlign='center'>
              <Button as={LocLink} to='/classes' className="Button__ACE Button__ACE-large">{("MORE_ABOUT_CLASSES", "More about Classes")}</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Container>
  </Page>
)

export default Combinations

