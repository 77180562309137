import React from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setModalContent, clearModalContent } from '../../../redux/actions'
import CartView from '../CartView'
import './styles.scss'


const mapStateToProps = state => {
    return {
        cart: state.crowfallStore.cartSolutions,
        modal: state.modal,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModalContent: (content) => dispatch(setModalContent(content)),
        clearModalContent: () => dispatch(clearModalContent()),
    }
}

class ShoppingCart extends React.Component {
    constructor(props) {
        super(props)
        this.onCartClick = this.onCartClick.bind(this)
    }


    onCartClick() {
        const { modal, setModalContent, clearModalContent } = this.props
        if (!_.isEmpty(modal.content)) {
            clearModalContent()
        } else {
            setModalContent(<CartView />)
        }
    }

    render() {

        const { cart, location } = this.props
        let splitPath = location.pathname.split("/")
        let className = "ShoppingCart"
        if (!_.isEmpty(cart) && !splitPath.includes("checkout-cart") && !splitPath.includes("checkout")) className += " ShoppingCart--show"
        if (splitPath.length === 3 && splitPath[2] === "") className += " ShoppingCart--homePage"
        const count = cart && cart.length > 9 ? "9+" : cart.length

        return (
            <div className={className} onClick={this.onCartClick}>
                <div className="ShoppingCart__icon"><FontAwesomeIcon icon="shopping-cart"/></div>
                <div className="ShoppingCart__count">{count}</div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCart))
