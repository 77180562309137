import React from 'react'
import { Form } from 'semantic-ui-react'
import strings from '../../../strings'
import Button from '../../../componentLibrary/Fragments/Button'
import './styles.scss'


class ChangeEmailModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  preventAutofillOnEmailChangeInputs = () => {
      const emailInput = document.getElementById("emailChangeInput__accountDetailsPage")
      if (emailInput) emailInput.removeAttribute("readOnly")
  }

  async handleSubmit() {
    this.setState({ loading: true })
    await this.props.submit()
    this.setState({ loading: false })
  }

  render() {

      const { updateField, has2FA, cancel } = this.props
      const { loading } = this.state

      return (
          <div className="ChangeEmailModal">
              <div className="ChangeEmailModal__header">{strings.getString("CHANGE_EMAIL", "Change Email")}</div>
              {!has2FA ? (
                  <p>
                      {strings.getString('VERIFY_BEFORE_EMAIL_CHANGE', "An email has been sent to your old email address to verify. Please contact customer support with any issues.")}
                  </p>
              ) : null}
              <p>
                {strings.getString('ACCOUNT_EMAIL_CHANGE_INFO', 'Enter the new Email Address for your account.  You will be sent a verification email to the new address to confirm access.  Your new email address will be used for your account sign in once you confirm access.')}
              </p>
              


              <Form onFocus={this.preventAutofillOnEmailChangeInputs}>
                <Form.Input label={strings.getString('NEW_EMAIL_ADDRESS', 'New Email Address')} placeholder={strings.getString('NEW_EMAIL_ADDRESS', 'New Email Address')} 
                            autoComplete="off" name='email' type='email' readOnly id="emailChangeInput__accountDetailsPage"
                            onChange={updateField}/>
                <Form.Input label={strings.getString('CURRENT_PASSWORD', 'Current Password')} placeholder={strings.getString('CURRENT_PASSWORD', 'Current Password')} 
                            autoComplete="off" name='password' type='password'
                            onChange={updateField}/>

                {has2FA ? (
                  <Form.Input autoComplete="off" type="number" label="2FA Device Code" name='otp'
                              placeholder={strings.getString('2FA_DEVICE_CODE', '2FA Device Code')}
                              onChange={updateField}/>
                ) : 
                  <Form.Input minLength="6" autoComplete="off"
                              label={strings.getString("LOGIN_ERROR_STEP_UP_CODE", "Email Verification Code")}
                              placeholder={strings.getString("LOGIN_ERROR_STEP_UP_CODE_HELP", "Please check your email and enter the code provided")}
                              onChange={updateField}
                              name='stepUpCode'
                              type="number"/>
                }

              </Form>

              <div className="ChangeEmailModal__buttons">
                  <div className="ChangeEmailModal__button"><Button medium loading={loading} gray label={strings.getString("CANCEL", "Cancel")} onclick={cancel}/></div>
                  <div className="ChangeEmailModal__button"><Button medium loading={loading} label={strings.getString("CHANGE_EMAIL", "Change Email")} onclick={this.handleSubmit}/></div>
              </div>
          </div>
      )
  }
}

export default ChangeEmailModal