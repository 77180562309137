import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import { Button, Container, Grid } from 'semantic-ui-react'
import contentful from '../../utilities/contentful'
import _ from 'lodash'
import LocLink from '../../componentLibrary/Elements/LocLink'
import strings from '../../strings'

class BestiaryDetailPage extends React.Component {

  state = {
    entry: {},
  }

  async componentDidMount() {
    await this.getContent()
  }

  getContent = async () => {
    const bestiaryName = _.result(this.props, 'match.params.bestiaryName')
    const entry = (await contentful.getEntries('card', `fields.type[match]=Bestiary&fields.id[match]=${bestiaryName}`)).map(e => {
      e.bannerAssetUrl = _.result(e, 'bannerAsset.assetUrl')
      e.title = _.upperCase(e.name)
      e.thumbnails = (e.imageAssets || []).map(a => {
        return { src: a.assetUrl, title: a.title }
      })
      return e
    })[0] || {}

    this.setState({ 'entry': entry })
  }

  render() {
    const { entry } = this.state
    return (
      <Page contentId={assets.contentfulIds.BESTIARY_DETAILS_PAGE} update={this.getContent.bind(this)} padding={'0em'}>
        <Container fluid>
          {entry.bannerAssetUrl ? (
            <div style={{
              backgroundImage: `url(${entry.bannerAssetUrl})`,
              height: '568px',
              backgroundSize: 'cover',
              backgroundPosition: 'right center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>

              <h1 className="h1__large" style={{
                textShadow: '3px 2px 3px #08090f',
                color: 'white',
              }}>{entry.title}</h1>

              {(entry.iconList || []).map((i, l) => (
                <img alt={i.title} style={{
                  display: 'block',
                  height: '300px',
                }} key={l} src={i.assetUrl}/>
              ))}


            </div>
          ) : ''}


          <div style={{
            padding: '40px 0',
            backgroundColor: 'rgb(249, 249, 249)',
          }}>

            <Container>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column computer={8} tablet={16}>
                    <div dangerouslySetInnerHTML={{ __html: entry.description }} style={{
                      fontSize: '14px',
                    }}></div>

                  </Grid.Column>
                  <Grid.Column computer={8} tablet={16}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      {(entry.thumbnails || []).map((t, i) => (
                        <img alt={t.title} key={i} title={t.title} src={t.src} style={{
                          display: 'block',
                          width: '100%',
                          marginBottom: '10px',
                        }}/>
                      ))}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 0',
          }}>
            {/*<Button as={LocLink} to='/store?category=064b6196-98fe-46c7-94d4-9485dad599de' className="Button__ACE Button__ACE-large">{strings.getString('PLAY_NOW', 'Play Now')}</Button>*/}
          </div>


        </Container>
      </Page>
    )
  }
}

export default BestiaryDetailPage

