import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import assets from '../../assets'
import { Button, Container, Grid } from 'semantic-ui-react'
import contentful from '../../utilities/contentful'
import _ from 'lodash'
import LocLink from '../../componentLibrary/Elements/LocLink'
import strings from '../../strings'

class ClassDetailPage extends React.Component {

  state = {
    entry: {},
  }

  async componentDidMount() {
    await this.getContent()
  }

  async getContent() {
    const className = _.result(this.props, 'match.params.className')
    const entry = (await contentful.getEntries('raceClass', `fields.type[match]=Class&fields.id[match]=${className}`)).map(e => {
      e.bannerAssetUrl = _.result(e, 'bannerAsset.assetUrl')
      e.title = _.upperCase(e.pluralName)
      e.thumbnails = (e.imageAssets || []).map(a => {
        return { src: a.assetUrl, title: a.title }
      })
      return e
    })[0]

    this.setState({ 'entry': entry })
  }

  render() {

    const { entry } = this.state

    return (
      <Page contentId={assets.contentfulIds.CLASSES_DETAILS_PAGE} update={this.getContent.bind(this)} padding={'0em'}>
        <Container fluid>
          {entry.bannerAssetUrl ? (
            <div style={{
              backgroundImage: `url(${entry.bannerAssetUrl})`,
              height: '448px',
              backgroundSize: 'cover',
              backgroundPosition: 'right center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              <h1 className="h1__large" style={{
                textShadow: '3px 2px 3px #08090f',
                color: 'white'
              }}>{entry.title}</h1>

              {(entry.iconList || []).map((i, l) => (
                <img alt={i.title} style={{
                  width: 'auto',
                  height: '80px',
                }} key={l} src={i.assetUrl}/>
              ))}
            </div>
          ) : ''}


          <div style={{
            padding: '20px 0',
          }}>
            <ul style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0',
              flexWrap: 'wrap'
            }}>
              <Attribute title={'Strength'} value={entry.attributeStrength}/>
              <Attribute title={'Dexterity'} value={entry.attributeDexterity}/>
              <Attribute title={'Intellect'} value={entry.attributeIntelligence}/>
              <Attribute title={'Spirit'} value={entry.attributeSpirit}/>
              <Attribute title={'Constitution'} value={entry.attributeConstitution}/>
            </ul>
          </div>

          <div style={{
            padding: '40px 0',
            backgroundColor: 'rgb(249, 249, 249)',
          }}>

            <Container>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={10}>

                    <div dangerouslySetInnerHTML={{ __html: entry.story }} style={{
                      textAlign: 'center',
                      fontSize: '19px',
                      marginBottom: '50px',
                    }}></div>

                    <div dangerouslySetInnerHTML={{ __html: entry.lore }} style={{
                      padding: '0 50px',
                      fontSize: '14px',
                      color: 'rgb(48, 76, 102)',
                      marginBottom: '50px',
                    }}></div>

                    <div dangerouslySetInnerHTML={{ __html: entry.description }} style={{
                      fontSize: '14px',
                    }}></div>

                  </Grid.Column>

                  <Grid.Column width={6}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      {(entry.thumbnails || []).map((t, i) => (
                        <img alt={t.title} key={i} title={t.title} src={t.src} style={{
                          display: 'block',
                          width: '100%',
                          marginBottom: '10px',
                        }}/>
                      ))}
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </div>


          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 0',

          }}>
            <span style={{ fontSize: '21px', marginBottom: '30px' }}>AVAILABLE RACES</span>
            <ul style={{
              padding: 0,
              listStyle: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              {entry.raceHuman ? (<LocLink to={'/races/human'}><Races race={'human'} title={'Human'}/></LocLink>) : ''}
              {entry.raceHalfElf ? (<LocLink to={'/races/half-elf'}><Races race={'halfElf'} title={'Half Elf'}/></LocLink>) : ''}
              {entry.raceNethari ? (<LocLink to={'/races/nethari'}><Races race={'nethari'} title={'Nethari'}/></LocLink>) : ''}
              {entry.raceFae ? (<LocLink to={'/races/fae'}><Races race={'fae'} title={'Fae'}/></LocLink>) : ''}
              {entry.raceWoodElf ? (<LocLink to={'/races/wood-elf'}><Races race={'woodElf'} title={'Wood Elf'}/></LocLink>) : ''}
              {entry.raceHighElf ? (<LocLink to={'/races/high-elf'}><Races race={'highElf'} title={'High Elf'}/></LocLink>) : ''}
              {entry.raceHalfGiant ? (<LocLink to={'/races/half-giant'}><Races race={'halfGiant'} title={'Half Giant'}/></LocLink>) : ''}
              {entry.raceStoneborn ? (<LocLink to={'/races/stoneborn'}><Races race={'stoneborn'} title={'Stoneborn'}/></LocLink>) : ''}
              {entry.raceElken ? (<LocLink to={'/races/elken'}><Races race={'elken'} title={'Elken'}/></LocLink>) : ''}
              {entry.raceMinotaur ? (<LocLink to={'/races/minotaur'}><Races race={'minotaur'} title={'Minotaur'}/></LocLink>) : ''}
              {entry.raceCentaur ? (<LocLink to={'/races/centaur'}><Races race={'centaur'} title={'Centaur'}/></LocLink>) : ''}
              {entry.raceGuinecean ? (<LocLink to={'/races/guinecean'}><Races race={'guinecean'} title={'Guinecean'}/></LocLink>) : ''}

            </ul>
          </div>

          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 0',
          }}>
            {/*<Button as={LocLink} to='/store?category=064b6196-98fe-46c7-94d4-9485dad599de' className="Button__ACE Button__ACE-large">{strings.getString('PLAY_NOW', 'Play Now')}</Button>*/}
          </div>


        </Container>
      </Page>
    )
  }
}

const Attribute = ({ title, value }) => (
  <li style={{
    display: 'flex',
    listStyle: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px',
  }}>
        <span style={{
          fontSize: '14px',
          marginBottom: '5px',
        }}>{_.upperCase(title)}</span>
    <span style={{
      fontSize: '20px',
    }}>
            {[...Array(value)].map(() => (
              '•'
            ))}
        </span>
  </li>
)

class Races extends React.Component {

  state = {}
  url = {
    human: assets.staticImageIds.ICON_RACE_HUMAN,
    halfElf: assets.staticImageIds.ICON_RACE_HALFELF,
    nethari: assets.staticImageIds.ICON_RACE_NETHARI,
    fae: assets.staticImageIds.ICON_RACE_FAE,
    woodElf: assets.staticImageIds.ICON_RACE_WOODELF,
    highElf: assets.staticImageIds.ICON_RACE_HIGHELF,
    halfGiant: assets.staticImageIds.ICON_RACE_HALFGIANT,
    stoneborn: assets.staticImageIds.ICON_RACE_STONEBORN,
    elken: assets.staticImageIds.ICON_RACE_ELKIN,
    minotaur: assets.staticImageIds.ICON_RACE_MINOTAUR,
    centaur: assets.staticImageIds.ICON_RACE_CENTAUR,
    guinecean: assets.staticImageIds.ICON_RACE_GUINECEAN,
  }[this.props.race]

  async componentDidMount() {
    this.setState({ ...(await contentful.getImage(this.url)) })
  }

  render() {
    const { assetUrl } = this.state
    const { title } = this.props

    return (
      <li style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 20px',
      }}>
        {assetUrl ? (<img alt={title} style={{
          height: '90px',
        }} src={assetUrl}/>) : ''}
        <span style={{
          color: 'black',
        }}>{_.upperCase(title)}</span>
      </li>
    )
  }
}

export default ClassDetailPage

