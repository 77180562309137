import React from 'react'
import { Grid, Header, Table } from 'semantic-ui-react'
import _ from 'lodash'
import FixedTable from '../../componentLibrary/Elements/FixedTable'
import GuildDetail from '../../componentLibrary/Elements/GuildDetail'
import strings from '../../strings'

class OrderSummary
  extends React.Component {

  render() {

    const { solution, solutionCart, isSubscribe } = this.props
    const userSolution = !_.isEmpty(solutionCart) ? solutionCart : solution
    const isACart = !_.isEmpty(solutionCart)

    const solutionDisplayStrings = _.result(userSolution, 'display_strings', {})
    const storePrice = isACart ?
      _.result(userSolution, "display_strings.gross_total", "-")
      : _.result(userSolution, 'item_details.display_strings.purchase_amount', '-')

    const itemListAmount = isACart ?
      storePrice
      : _.result(userSolution, 'item_details.display_strings.amount', '-')


    return (

      <Grid style={{ marginBottom: "50px" }}>

        {!_.isEmpty(userSolution.gift_target) ? (
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as='h2'>{("GIFT_TO_GUILD","Gift to Guild")}</Header>
              <GuildDetail
                displayName={_.result(userSolution.gift_target, 'guild.display_name')}
                name={_.result(userSolution.gift_target, 'guild.name')}
                crest={_.result(userSolution.gift_target, 'guild.custom_fields.0.value')}/>
            </Grid.Column>
          </Grid.Row>
        ) : null}


        <Grid.Row>
          <Grid.Column width={16}>
            {userSolution.deferred_purchase_id ?
              (<Header as='h2'>{strings.getString('LAYAWAY_ORDER_SUMMARY', 'Order Summary for Layaway Payment')}</Header>) :
              (<Header as='h2'>{strings.getString('ORDER_SUMMARY', 'Order Summary')}</Header>)}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <FixedTable definition>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell/>
                  <Table.HeaderCell>{strings.getString('COSTS', 'Costs')}</Table.HeaderCell>
                  <Table.HeaderCell>{strings.getString('CREDITS', 'Credits')}</Table.HeaderCell>
                  <Table.HeaderCell>{strings.getString('NOTES', 'Notes')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{strings.getString('STORE_PRICE', 'Store Price')}</Table.Cell>
                  <Table.Cell>{storePrice}</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>{storePrice !== itemListAmount ? strings.getString("STORE_ON_SALE_PRICE", "On Sale! Standard Price: " + itemListAmount, { itemListAmount }) : null}</Table.Cell>
                </Table.Row>
              </Table.Body>

              {userSolution.deferred_purchase_id ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Amount Remaining for Payoff</Table.Cell>
                    <Table.Cell>{_.result(userSolution, 'display_strings.amount_remaining')}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : null}

              {userSolution.fee_total > 0 ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{strings.getString('ORDER_SUMMARY_PROCESSING_FEE', 'Processing Fee')}: {solutionDisplayStrings.tax_total}</Table.Cell>
                    <Table.Cell>{solutionDisplayStrings.fee_total}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : null}

              <Table.Body>
                <Table.Row>
                  <Table.Cell>{strings.getString('SHIPPING_COST', 'Shipping Cost')}</Table.Cell>
                  <Table.Cell>{solutionDisplayStrings.shipping_total}</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              </Table.Body>

              {userSolution.upgrade_amount > 0 ? (

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{strings.getString('UPGRADE_CREDITS', 'Upgrade Credits')}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{solutionDisplayStrings.upgrade_total}</Table.Cell>
                    <Table.Cell>{userSolution.upgrade_name}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : null}

              {userSolution.discount_amount > 0 ? (

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{strings.getString('ACCOUNT_DISCOUNT_CREDIT', 'Account Discount Credit')}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{solutionDisplayStrings.discount_total}</Table.Cell>
                    <Table.Cell>{userSolution.discount_name}</Table.Cell>
                  </Table.Row>
                </Table.Body>

              ) : null}


              {userSolution.wallet_amount > 0 ? (
                <Table.Body>
                  <Table.Row positive>
                    <Table.Cell><strong style={{
                      color: 'rgb(177, 39, 4)',
                      fontSize: '18px',
                    }}>{strings.getString('WALLET_AMOUNT_USED', 'Wallet Amount Used')}</strong></Table.Cell>
                    <Table.Cell>
                      <strong style={{
                        color: 'rgb(177, 39, 4)',
                        fontSize: '18px',
                      }}>{solutionDisplayStrings.wallet_total}</strong></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>

                      {userSolution.total > 0 ? (strings.getString('STORE_AUTOMATICALLY_DEDUCT_FROM_WALLET', 'The amount shown will automatically be deducted from your wallet.')) : (
                        strings.getString('ORDER_SUMMARY_WALLET_INFO', 'The amount shown will be deducted from the wallet you select above.')
                      )}

                    </Table.Cell>


                  </Table.Row>
                </Table.Body>

              ) : null}


              {userSolution.total > 0 ? (
                <Table.Body>
                  <Table.Row positive>
                    <Table.Cell><strong style={{
                      color: 'rgb(177, 39, 4)',
                      fontSize: '18px',
                    }}>{strings.getString('ORDER_TOTAL', 'Order Total')}*</strong></Table.Cell>
                    <Table.Cell color='red'><strong style={{
                      color: 'rgb(177, 39, 4)',
                      fontSize: '18px',
                    }}>{solutionDisplayStrings.total}</strong></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : null}
            </FixedTable>
          </Grid.Column>
        </Grid.Row>

        {isSubscribe && <div>{strings.getString("WARNING_PURCHASE_IS_RECURRING", "* Note that you have selected recurring payments for this purchase.")}</div>}

      </Grid>
    )
  }
}

export default OrderSummary
