import React from 'react'
import { api } from '../utilities/api'
import { Button, Dimmer, Form, Grid, Image, Loader, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import { fetchUser, send2faEmail } from '../redux/actions/user'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = state => {
  return {
    user: state.user.userProfile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => dispatch(fetchUser()),
    send2faEmail: () => dispatch(send2faEmail()),
  }
}

class AccountDetailsSecurity extends React.Component {

  state = {
    loading: false,
    totp: {},
    verifyCode: '',
    password: '',
    emailSent: false,
    emailCode: null,
  }

  async componentDidMount() {
    await this.getContent()
  }

  async getContent() {
    this.setState({ loading: true })
    const totp = await api.get('/user/totp?name=Crowfall')
    this.setState({
      totp: totp.data,
    })
    this.setState({ loading: false })
  }

  handleChange = (e, { name, value } = {}) => {
    let newValue = value
    if (name === 'verifyCode' && newValue.charAt(3) === " ") {
      newValue = newValue.slice(0,3) + newValue.slice(4)
    }
    this.setState({ [name]: newValue })
  }

  add2FA = async () => {
    try {
      await api.post('/user/totp', {
        secret: this.state.totp.ascii,
        verify_code: this.state.verifyCode,
        email_code: this.state.emailCode,
      })
      this.setState({ verifyCode: '', emailCode: "", emailSent: false, })

      await swal(strings.getString('ACCOUNT_2FA_SUCCESS_ADD', `Successfully added 2-Step Verification.`), {
        icon: 'success',
      })

      await this.props.fetchUser()
    } catch (err) {
      swal(`${strings.getString('ACCOUNT_2FA_ERROR_ADD', `Uh Oh! Unable to add 2-Step Verification.`)} ${_.startCase(_.result(err, 'response.data.message', ''))}`, {
        icon: 'error',
      })
    }
  }

  remove2FA = async () => {
    try {
      await api.post('/user/totp/remove', {
        password: this.state.password,
        totp: this.state.verifyCode,
      })
      this.setState({ password: '', verifyCode: '' })
      await swal(strings.getString('ACCOUNT_2FA_SUCCESS_REMOVE', `Successfully removed 2-Step Verification.`), {
        icon: 'success',
      })

      await this.props.fetchUser()
    } catch (err) {
      swal(strings.getString('ACCOUNT_2FA_REMOVAL_ERROR', `Uh Oh! Unable to remove 2-Step Verification.`), {
        icon: 'error',
      })
    }
  }

  async sendEmailCode() {
    const { send2faEmail } = this.props
    try {
      await send2faEmail()
      this.setState({ emailSent: true })
      swal({
        icon: 'success',
        title: strings.getString("2FA_EMAIL_CODE_SUCCESS", "Success! Check your email to retrieve your code."),
        content: (<div dangerouslySetInnerHTML={{ __html: strings.getString("2FA_EMAIL_CODE_INFO", "Your code will expire in 10 minutes.<br/>Additionally, Be sure to complete the rest of the steps on this page to ensure that your account is secured with 2FA.") }}/>)
      })
    } catch (err) {
      swal({
        icon: 'error',
        title: strings.getString("UH_OH", "Uh Oh!"),
        content: (<div>{strings.getString("ERROR_GENERIC_MESSAGE", "There was an error processing your request. Please try again.")}</div>),
      })
    }
  }

  render() {

    const { loading, totp, verifyCode, emailSent, emailCode } = this.state
    const { user } = this.props
    const has2fa = user.has_otp

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>

        {!has2fa && !emailSent &&
          <div>
            <h2>{strings.getString('2FA_SETUP', `2-Step Verification (2FA Device) Setup`)}</h2>
            <p>{strings.getString("2FA_DESCRIPTION", "2FA provides additional security for your Crowfall account.")}</p>
            <p>{strings.getString("2FA_INITIALIZE_SETUP", "To start the process for adding 2FA to your account, click the button below to get a verification code sent to your email.")}</p>
            <Button className="Button__ACE" onClick={this.sendEmailCode.bind(this)} primary>{strings.getString("GET_VERIFICATION_CODE", "Get Verification Code")}</Button>
          </div>
        }

        {!has2fa && !_.isEmpty(totp) && totp.data_url && emailSent &&
          <div>
            <h2>{strings.getString('2FA_SETUP', `2-Step Verification (2FA Device) Setup`)}</h2>
            <h2>{strings.getString("STEP_1", "Step 1")}</h2>
            <p>{strings.getString("2FA_ENTER_EMAIL_CODE", "Enter the verification code sent to your email. If you haven't received a code within a few minutes, refresh the page and try again.")}</p>
            <Form>
              <Form.Field>
                <Form.Input autoComplete="off" name="emailCode" label={strings.getString("EMAIL_VERIFICATION_CODE", "Email Verification Code")}
                            value={emailCode} onChange={this.handleChange} type="text"></Form.Input>
              </Form.Field>
            </Form>

            <h2>{strings.getString('STEP_2', `Step 2`)}</h2>
            <p>{strings.getString('DOWNLOAD_AN_AUTHENTICATOR', `Download a mobile authenticator application on your device. You are allowed to use any RFC 6238 Time-Based One-Time Password Application.`)}</p>

            <FixedTable>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Google </Table.Cell>
                  <Table.Cell><a
                    href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">iOS</a></Table.Cell>
                  <Table.Cell><a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">Android</a></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Authy</Table.Cell>
                  <Table.Cell><a href="https://itunes.apple.com/us/app/authy/id494168017?mt=8">iOS</a></Table.Cell>
                  <Table.Cell><a href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en">Android</a></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>1Password:</Table.Cell>
                  <Table.Cell><a
                    href="https://itunes.apple.com/us/app/1password-password-manager/id568903335?mt=8">iOS</a></Table.Cell>
                  <Table.Cell><a
                    href="https://play.google.com/store/apps/details?id=com.agilebits.onepassword&hl=en">Android</a></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Microsoft Authenticator</Table.Cell>
                  <Table.Cell><a
                    href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">iOS</a></Table.Cell>
                  <Table.Cell><a
                    href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US">Android</a></Table.Cell>
                </Table.Row>
              </Table.Body>

            </FixedTable>

            <h2>{strings.getString('STEP_3', `Step 3`)}</h2>
            <p>{strings.getString('2FA_STEP_2_INFO', `Within your application either enter the secret code or using the built in QR Code reader scan the image below.`)}</p>


            <Grid stackable>
              <Grid.Column width={8} verticalAlign="middle">
                <strong>{totp.base32}</strong>
                <p>{strings.getString('2FA_STEP_2_EXTRA', `If you are entering the code manually, please be sure to select "Time Based" rather than "Counter Based"`)}</p>
              </Grid.Column>
              <Grid.Column width={8}>
                <Image centered src={totp.data_url}/>
              </Grid.Column>
            </Grid>

            <h2>{strings.getString('STEP_4', `Step 4`) + ' - ' + strings.getString('ENTER_CODE_TO_CONFIRM', 'Enter your code below to confirm everything is setup correctly.')}</h2>
            <Form>
              <Form.Field>
                <Form.Input autoComplete="off" type="number" label={strings.getString('VERIFY_2FA_CODE', `Verify 2FA Code`)} name='verifyCode'
                            value={verifyCode} onChange={this.handleChange}></Form.Input>
              </Form.Field>
              <Button className="Button__ACE" primary type='submit' onClick={this.add2FA}>{strings.getString('2FA_ADD_VERIFICATIONS', `Add 2-Step Verification`)}</Button>
            </Form>

          </div>
        }

        {has2fa && 
          <React.Fragment>
            <h2>{strings.getString('2FA_REMOVE_VERIFICATION', `Remove 2-Step Verification (2FA Device)`)}</h2>
            <p>{strings.getString('2FA_ALREADY_SECURE', `Account is already secured with a 2-Step Verification`)}</p>
            <Form onSubmit={this.remove2FA}>
              <Form.Input autoComplete="off" type="password" label={strings.getString('CURRENT_ACCOUNT_PASSWORD', `Current Account Password`)}
                          placeholder={strings.getString('CURRENT_ACCOUNT_PASSWORD', `Current Account Password`)} name='password'
                          onChange={this.handleChange.bind(this)}/>

              <Form.Input autoComplete="off" type="number" label={strings.getString('2FA_DEVICE_CODE', `2FA Device Code`)} placeholder={strings.getString('2FA_DEVICE_CODE', `2FA Device Code`)}
                          name='verifyCode'
                          onChange={this.handleChange.bind(this)}/>
              <Button className="Button__ACE" type='submit' primary>{strings.getString('REMOVE_2FA_DEVICE', `Remove 2FA Device`)}</Button>
            </Form>

          </React.Fragment>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsSecurity)
