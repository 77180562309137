import React from 'react'
import Page from '../../componentLibrary/Models/Page'
import contentful from '../../utilities/contentful'
import _ from 'lodash'
import LocLink from '../../componentLibrary/Elements/LocLink'

class RacesPage extends React.Component {

  state = {
    entries: [],
  }

  async componentDidMount() {
    await this.getContent()
  }

  async getContent() {
    const entries = _.sortBy(await contentful.getEntries('raceClass', 'fields.type[match]=Race'), 'displayWeight').map(e => {
      e.landingPageAssetUrl = _.result(e, 'landingPageAsset.assetUrl')
      e.title = _.upperCase(e.name)
      return e
    }) || {}

    this.setState({ entries: entries })
  }

  render() {

    const { entries } = this.state

    return (
      <Page titleName="RACES">
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0 15px',
        }}>
          {entries.map((e, i) => (
            <LocLink to={`/races/${e.id}`} as={'div'} key={i} style={{
              backgroundImage: `url(${e.landingPageAssetUrl})`,
              backgroundPosition: 'left center',
              backgroundSize: 'cover',
              maxWidth: '1152px',
              width: '100%',
              paddingLeft: '140px',
              height: '130px',
              marginBottom: '7px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <span style={{
              textShadow: 'rgba(0, 0, 0, 0.5) 1px 1px 1px',
              color: 'white',
              fontSize: 'calc(2em + 1vw)',
              lineHeight: '42px', 
              wordBreak: 'break-all',
            }}>{e.title}</span>
            </LocLink>
          ))}
        </div>
      </Page>
    )
  }
}

export default RacesPage

