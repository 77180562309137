/*

MODEL: ComparisonTable

PROPERTY  DEFAULT  TYPE    REQUIREMENT  DESCRIPTION
rowLabel  ""       String  optional     Descriptive label for the rows beneath it.
columns  
rows     

*/

import React from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'


class ComparisonTable extends React.Component {

    render() {

        const { rowLabel, columns, rows } = this.props
        let className = "ComparisonTable"
        className += ` ComparisonTable--columns${columns.length + 1}`
        className += ` ComparisonTable--rows${rows.length + 1}`

        return (
            <div className={className}>
                <div className="ComparisonTable__headerCell">
                    <div className="ComparisonTable__label" >{!_.isEmpty(rowLabel) ? rowLabel : "" }</div>
                </div>
                {columns.map((column, index) => (
                    <div className="ComparisonTable__headerCell" key={`ComparisonTable--labelCell${index}`}>
                        <div className="ComparisonTable__label">{column.label}</div>
                    </div>
                ))}
                
                {rows.map((row, index) => {
                    const { label, value } = row
                    return (
                        <React.Fragment key={`ComparisonTable--row${index}`}>
                            <div className="ComparisonTable__labelCell"><div className="ComparisonTable__rowLabel">{label}</div></div>
                            {columns.map((column, columnIndex) => (
                                <div className="ComparisonTable__bodyCell" key={`ComparisonTable--row${index}cell${columnIndex}`}>
                                    {_.isFunction(column.compare) && column.compare(value) ? <FontAwesomeIcon icon="check" /> : "-"}
                                </div>
                            ))}
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}

export default ComparisonTable