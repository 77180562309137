import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import _ from 'lodash'
import './styles.scss'

class GatesOfGallooPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null,
      subtitle: null,
      body: null,
      banner: null,
      logo: null,
    }
  }

   async componentDidMount() {
    const content = await contentful.getEntry(assets.contentfulIds.GATES_OF_GALLOO_PAGE)
    this.setState({
      title: content.title,
      subtitle: content.pageTitle,
      body: content.pageHeader,
      logo: _.result(content, "bannerLogo.assetUrl"),
      banner: _.result(content, 'banner.assetUrl')
    })
  }

  render() {
    const { logo, subtitle, body, banner } = this.state
    const header = {subtitle, banner};
    return (
      <Page header={header} titleAsset={logo} isMarketing>
        <div className="GatesOfGallooPage">
          <div className="GatesOfGallooPage__body" dangerouslySetInnerHTML={{__html: body}}></div>
        </div>
      </Page>
    )
  }
}

export default GatesOfGallooPage
