import {
  CLEAR_GUILD_CHAT_SEARCH_CRITERIA,
  CLEAR_GUILD_DETAILS,
  CLEAR_GUILD_LOG_SEARCH_CRITERIA,
  CLEAR_MEMBERS_SEARCH_CRITERIA,
  PAGINATE_GUILD_LOG,
  PAGINATE_GUILD_MEMBERS,
  SEARCH_GUILD_CHAT,
  SEARCH_GUILD_LOG,
  SEARCH_GUILD_MEMBERS,
  SET_GUILD_APPLICATIONS,
  SET_GUILD_CHAT,
  SET_GUILD_FETCHED,
  SET_GUILD_INVITES,
  SET_GUILD_LOG,
  SET_GUILD_MANAGEMENT_DETAILS,
  SET_GUILD_MEMBERS,
  SET_GUILD_MEMBERSHIP_RECORD_INFO,
  SET_HAS_GUILD,
} from '../actionTypes'

import {api, guildApi} from '../../utilities/api'
import _ from 'lodash'
import moment from 'moment-timezone'
import {camelCaseToSnakeCase} from "../../utilities/stringUtilities";


// API CALLS
  
  export const fetchGuildDetails = () => {
    return async function (dispatch) {
      const guildRes = await api.get('/user/primary-guild-details')
      const guildDetails = guildRes.data
      const {
        guild,
        wallet: guildWallet,
        notifications: guildNotifications,
        configuration: guildConfiguration,
        invites: guildInvites,
        requests: guildApplications,
        guild_id: guildId,
        guild_rank: guildRank,
      } = guildDetails
  
      const hasGuild = !!guildId
      dispatch(setHasGuild(hasGuild))
      dispatch(setGuildFetched(true))
      if (hasGuild) {
        const details = {
          guild: modelGuild(guild),
          guildWallet: modelWallet(guildWallet),
          guildNotifications,
          guildConfiguration,
          guildInvites: modelGuildInvites(guildInvites),
          guildApplications: modelGuildApplications(guildApplications),
          guildId,
          guildRank,
        }
        dispatch(setGuildDetails(details))
        dispatch(setGuildMembers(guild.members))
        const modelledGuildLog = modelGuildLog(guildNotifications)
        dispatch(setGuildLog(modelledGuildLog))
      }
    }
  }

  export const createGuild = (guild) => {
    return async function() {
      await api.post('/user/guild', { ...guild })
    }
  }

  export const updateGuildDetails = (guildId, updates) => {
    return async function(dispatch) {
      await api.put(`user/guild/${guildId}`, updates)
      dispatch(fetchGuildDetails())
    }
  }
  
  export const joinGuild = (guildId, message) => {
    return async function() {
      await guildApi.post(`/guild/user-join-requests`, { guildId, message })
    }
  }
  
  export const fetchGuildInvites = guildId => {
    return async function(dispatch) {
      const invites = await guildApi.get(`/guild/${guildId}/invite`)
      dispatch(setGuildInvites(modelGuildInvites(invites.data)))
    }
  }

  export const inviteUserToGuild = (guildId, username) => {
    return async function() {
      await guildApi.post(`/guild/${guildId}/invite`, { username })
    }
  }

  export const revokeInviteToGuild = (inviteId) => {
    return async function() {
      await guildApi.delete(`/guild-invite/${inviteId}`)
    }
  }

  export const modifyMemberRank = (membershipId, rankId) => {
    return async function() {
      await api.put(`/user/guild-member/${membershipId}/modify-rank`, { guild_rank_id: rankId })
    }
  }

  export const kickMemberFromGuild = (guildId, membershipId) => {
    return async function() {
      await api.post(`/user/guild/${guildId}/kick/${membershipId}`)
    }
  }

  export const fetchGuildApplications = (guildId) => {
    return async function(dispatch) {
      const requests = await api.get(`/user/guild/${guildId}/guild-invite-request`)
      dispatch(setGuildApplications(modelGuildApplications(requests.data)))
    }
  }

  export const acceptApplication = (guildId, applicationId) => {
    return async function() {
      await guildApi.post(`/guild/${guildId}/invite-request/${applicationId}/approve`)
    }
  }

  export const declineApplication = (guildId, applicationId) => {
    return async function() {
      await guildApi.post(`/guild/${guildId}/invite-request/${applicationId}/decline`)
    }
  }

  export const transferGuildLeadership = (memberId) => {
    return async function(dispatch) {
      await api.post(`/user/guild-member/${memberId}/transfer-guild`)
      dispatch(fetchGuildDetails())
    }
  }

  export const disbandGuild = (guildId) => {
    return async function() {
      await api.post(`/user/guild/${guildId}/disband`)
    }
  }

  export const leaveGuild = (guildId) => {
    return async function (dispatch) {
      await api.post(`/user/guild/${guildId}/leave`)
      dispatch(setHasGuild(false))
      dispatch(clearGuildDetails())
    }
  }
  
  export const getLatestGuildMembership = () => {
    return async function(dispatch) {
        let guildMemberRecordInfoData = {}
        try {
            let guildRes = await api.get('/user/guild/latest-guild-membership')
            const data = guildRes.data[0]
            const {
              guild_id: guildId, 
              status,
              rank,
              left_guild_date: leftGuildDate,
              next_join_date: nextJoinDate,
              next_create_date: nextCreateDate,
              deny_guild_join: denyGuildJoin,
              deny_guild_create: denyGuildCreate
            } = guildMemberRecordInfoData

            guildMemberRecordInfoData = {
              guildId: data.guild_id,
              status: data.status,
              rank: data.rank,
              leftGuildDate: data.left_guild_date,
              nextJoinDate: data.next_join_date,
              nextCreateDate: data.next_create_date,
              denyGuildJoin: data.deny_guild_join,
              denyGuildCreate: data.deny_guild_create
            }
      } catch (err) {
        console.log('Error retrieving guild membership record information', err)
      }
      dispatch(setGuildMemberRecordInfo(guildMemberRecordInfoData))
    }
  }

  export const fetchGuildChat = () => {
    return async function (dispatch) {
      const temporaryChatFeed = [
        {
          id: "abc123",
          date_created: 1592956800000,
          username: 'blalah',
          message: 'blahblah blah blkasd fp pef piufpbqauei pubpq plcpoquer poefpaounv qprqbnef  qfvnepur'
        },
        {
          id: "ab1234",
          date_created: 1592962300000,
          username: 'blah_helll_lasdlg',
          message: 'blahblah blah blkasd fp pef piufpbqauei pubpq plcpoquer poefpaounv qprqbnef  qfvnepur blahd ;lab;lkar ;efkgpoer neqeotnq eotnoetingefaas ad asdf'
        },
        {
          id: "a12345",
          date_created: 1592938000000,
          username: 'blah__blah',
          message: 'blahblah blah blah blahhhh'
        },
      ]
      const formatted = modelGuildChatFeed(temporaryChatFeed)
      dispatch(setGuildChat(formatted))
    }
  }

  export const sendMessageToGuildChat = (message) => {
    return async function (dispatch) {

      dispatch(fetchGuildChat())
    }
  }


  // DISPATCH ACTION METHODS

  const setGuildFetched = fetched => {
    return {
      type: SET_GUILD_FETCHED,
      payload: fetched,
    }
  }
  
  const setGuildDetails = guildDetails => {
    return {
      type: SET_GUILD_MANAGEMENT_DETAILS,
      payload: guildDetails,
    }
  }

  const setGuildApplications = applications => {
    return {
      type: SET_GUILD_APPLICATIONS,
      payload: applications
    }
  }

  const setGuildMembers = members => {
    return (dispatch) => {
      dispatch({
        type: SET_GUILD_MEMBERS,
        payload: members,
      })
      dispatch(searchGuildMembers())
    }
  }

  export const searchGuildMembers = criteria => {
    return (dispatch) => {
      dispatch({
        type: SEARCH_GUILD_MEMBERS,
        payload: criteria
      })
      dispatch(paginateGuildMembers(1))
    }
  }

  export const clearMembersSearchCriteria = () => {
    return (dispatch) => {
      dispatch({
        type: CLEAR_MEMBERS_SEARCH_CRITERIA,
      })
      dispatch(paginateGuildMembers(1))
    }
  }

  export const paginateGuildMembers = page => {
    return {
      type: PAGINATE_GUILD_MEMBERS,
      payload: page
    }
  }
  
  export const setHasGuild = hasGuild => {
    return {
      type: SET_HAS_GUILD,
      payload: hasGuild
    }
  }

  export const setGuildInvites = invites => ({
    type: SET_GUILD_INVITES,
    payload: invites
  })

  export const setGuildChat = feed => {
    return (dispatch) => {
      dispatch({
        type: SET_GUILD_CHAT,
        payload: feed,
      })
      dispatch(searchGuildChat())
    }
  }

  export const searchGuildChat = criteria => ({
    type: SEARCH_GUILD_CHAT,
    payload: criteria,
  })

  export const clearGuildChatSearchCriteria = () => ({
    type: CLEAR_GUILD_CHAT_SEARCH_CRITERIA,
  })

  export const setGuildLog = log => {
    return (dispatch) => {
      dispatch({
        type: SET_GUILD_LOG,
        payload: log,
      })
      dispatch(searchGuildLog())
    }
  }

  export const searchGuildLog = criteria => {
    return (dispatch) => {
      dispatch({
        type: SEARCH_GUILD_LOG,
        payload: criteria
      })
      dispatch(paginateGuildLog(1))
    }
  }

  export const clearLogSearchCriteria = () => {
    return (dispatch) => {
      dispatch({
        type: CLEAR_GUILD_LOG_SEARCH_CRITERIA,
      })
      dispatch(paginateGuildLog(1))
    }
  }

  export const paginateGuildLog = page => {
    return {
      type: PAGINATE_GUILD_LOG,
      payload: page
    }
  }
  
  export const clearGuildDetails = () => ({
    type: CLEAR_GUILD_DETAILS,
  })

export const setGuildMemberRecordInfo = data => ({
  type: SET_GUILD_MEMBERSHIP_RECORD_INFO,
  payload: data
})
  
  
  // Helper Functions
  
  const modelGuild = guildData => {
    let {
      guild_id, name, display_name,tags, allegiance, allegiance_id, preferred_languages, 
      members, wallet, motd, website_url, recruiting_status, description, guild_leader_username, 
      custom_fields, recruiting, guild_ranks, date_created, mission_statement
    } = guildData
    const balance = _.result(wallet, 'display_strings.balance')
    const ledger = _.result(wallet, 'wallet_ledger')
    const crest = _.result(custom_fields, '[0].value')
    const dateCreated = moment(date_created).format('MMMM Do, YYYY')
    const preferredLanguages = preferred_languages || []
    allegiance = _.result(allegiance, 'display_name')
    members = members || []
    const guild = {
      guildId: guild_id,
      name,
      displayName: display_name,
      tags,
      allegiance,
      allegianceId: allegiance_id,
      preferredLanguages,
      motd,
      missionStatement: mission_statement,
      description,
      members,
      balance,
      wallet,
      ledger,
      leader: guild_leader_username,
      crest,
      recruiting,
      guildRanks: guild_ranks,
      dateCreated,
      websiteUrl: website_url,
      recruitingStatus: recruiting_status,
    }
    return guild
  }

  const modelGuildLog = log => {
    const modelled = log.map(item => {
      return {
        id: item.guild_notification_id,
        note: item.note,
        source: item.source_username,
        target: item.target_username,
        date: moment(item.date_created).format("MMM Do, YYYY"),
        rawDate: item.date_created,
      }
    })
    return _.orderBy(modelled, ['date_created'], ['desc'])
  }
  
  const modelWallet = walletData => {
    const {
      display_strings,
      wallet_ledger,
      wallet_id,
      description,
      balance,
    } = walletData
    const wallet = {
      balance: display_strings.balance,
      ledger: wallet_ledger,
      walletId: wallet_id,
      description: description,
      amount: balance,
    }
    return wallet
  }

  const modelGuildInvites = invites => {
    return _.orderBy(camelCaseToSnakeCase(invites), ['date_created'], ['desc'])
  }

  const modelGuildApplications = applications => {
    return _.orderBy(camelCaseToSnakeCase(applications), ['date_created'], ['desc'])
  }

  const modelGuildChatFeed = feed => {
    const ordered = _.orderBy(feed, ['date_created'], ['desc'])
    return ordered.map(item => {
      const dateFormat = 'MMM Do, YYYY, hh:mm a'
	    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
	    const dateCreated = moment(item.date_created).tz(timezone).format(dateFormat)
      return {
        ...item,
        dateCreated,
      }
    })
  }
  
