import {
    SET_STORE_DETAILS,
    SET_SELECTED_STORE_CATEGORY,
    SEARCH_CATALOG,
    CLEAR_CATALOG_SEARCH_CRITERIA,
    CLEAR_CROWFALL_STORE,
    SET_USER_CART,
    SET_CART_SOLUTION,
    SET_SHIPPING_DATA,
    CLEAR_USER,
    EDIT_SHIPPING_DATA,
    CLEAR_USER_SOLUTIONS,
} from '../actionTypes'


const initialState = {
    catalog: [],
    catalogSearchCriteria: null,
    catalogSearched: [],
    featuredCatalog: [],
    ltoCatalog: [],
    bestValueCatalog: [],
    onSaleCatalog: [],
    categories: {},
    tags: [],
    currencies: [],
    selectedCategory: {},

    cartSolutions: [],
    cartSubtotal: {},
    cartCurrency: null,
    solutionCart: {},
    solutionIdCart: null,
    shippingAddress: {},
    editingAddress: false,
}

const crowfallStore = ( state = initialState, action) => {
    switch(action.type) {
        case SET_STORE_DETAILS:
            const { catalog, featuredCatalog, ltoCatalog, bestValueCatalog, onSaleCatalog, categories, tags, currencies } = action.payload
            return {
                ...state,
                catalog,
                featuredCatalog,
                ltoCatalog, 
                bestValueCatalog,
                onSaleCatalog,
                categories,
                tags,
                currencies,
            }
        case SET_SELECTED_STORE_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload
            }
        case SEARCH_CATALOG:
            const catalogSearchCriteria = action.payload === undefined ? state.catalogSearchCriteria : action.payload
            const catalogSearched = catalogSearchCriteria ? state.catalog.filter(catalogSearchCriteria) : state.catalog
            return {
                ...state,
                catalogSearchCriteria,
                catalogSearched,
            }
        case CLEAR_CATALOG_SEARCH_CRITERIA:
            return {
                ...state,
                catalogSearchCriteria: initialState.catalogSearchCriteria,
                catalogSearched: state.catalog
            }
        case CLEAR_CROWFALL_STORE:
            return {
                ...state,
                catalog: initialState.catalog,
                catalogSearchCriteria: initialState.catalogSearchCriteria,
                catalogSearched: initialState.catalogSearched,
                featuredCatalog: initialState.featuredCatalog,
                ltoCatalog: initialState.ltoCatalog,
                bestValueCatalog: initialState.bestValueCatalog,
                onSaleCatalog: initialState.onSaleCatalog,
                categories: initialState.categories,
                tags: initialState.tags,
                currencies: initialState.currencies,
                selectedCategory: initialState.selectedCategory,
            }
        case SET_USER_CART:
            return {
                ...state,
                cartSolutions: action.payload.cartSolutions,
                cartSubtotal: action.payload.cartSubtotal,
                cartCurrency: action.payload.cartCurrency,
            }
        case SET_CART_SOLUTION:
            return {
                ...state,
                solutionCart: action.payload,
                solutionIdCart: action.payload.checkout_solution_id,
            }
        case SET_SHIPPING_DATA:
            return {
                ...state,
                shippingAddress: action.payload,
                editingAddress: false,
            }
        case CLEAR_USER:
            return {
                ...state,
                cartSolutions: initialState.cartSolutions,
                cartSubtotal: initialState.cartSubtotal,
                cartCurrency: initialState.cartCurrency,
                solutionCart: initialState.solutionCart,
                solutionIdCart: initialState.solutionIdCart,
                shippingAddress: initialState.shippingAddress,
                editingAddress: initialState.editingAddress,
            }
        case EDIT_SHIPPING_DATA:
            return {
                ...state,
                editingAddress: true,
            }
        case CLEAR_USER_SOLUTIONS:
            return {
                ...state,
                cartSolutions: initialState.cartSolutions,
                cartSubtotal: initialState.cartSubtotal,
                cartCurrency: initialState.cartCurrency,
                solutionCart: initialState.solutionCart,
                solutionIdCart: initialState.solutionIdCart,
                shippingAddress: initialState.shippingAddress,
                editingAddress: initialState.editingAddress,
            }
        default:
            return state
    } 
}

export default crowfallStore