import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import _ from 'lodash'

import Page from '../../componentLibrary/Models/Page'
import GuildCrest from '../../componentLibrary/Elements/GuildCrest'
import GuildNavigationMenu from './GuildNavigationMenu'
import MotdBox from './MotdBox'

import GuildBankPage from './GuildBankPage'
import GuildMembersPage from './GuildMembersPage'
import GuildOverviewPage from './GuildOverviewPage'
import GuildLeaderToolsPage from './GuildLeaderToolsPage'
import GuildApplicationsPage from './GuildApplicationsPage'
import GuildManageMembershipPage from './GuildManageMembershipPage'
import GuildNotificationsPage from './GuildNotificationsPage'

import { fetchGuildConfiguration } from '../../redux/actions/guilds'
import './styles.scss'


const mapStateToProps = state => {
    return {
        guild: state.guildManagement.guild,
        guildRank: state.guildManagement.guildRank,
        guildConfiguration: state.guildManagement.guildConfiguration,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchGuildConfiguration: () => dispatch(fetchGuildConfiguration())
    }
}

class GuildManagementInterface extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            routes: null
        }
        this.setGuildManagementRoutes = this.setGuildManagementRoutes.bind(this)
    }

    async componentDidMount() {
        await this.props.fetchGuildConfiguration()
        this.setGuildManagementRoutes()
    }

    componentDidUpdate(prevProps) {
        if (this.checkChangeInProps(prevProps, this.props) === true) this.setGuildManagementRoutes()
    }

    checkChangeInProps(previous, current) {
        return !_.isEqual(previous, current)
    }

    setGuildManagementRoutes = () => {
        const { guildRank, guildConfiguration } = this.props
        const { permission_display } = guildConfiguration
        const rankRequiredToViewGuildBank = _.result(_.find(permission_display, { 'value':  'view_guild_bank'}), "required_rank")
        const rankRequiredToViewApps = _.result(_.find(permission_display, { 'value':  'view_pending_invites'}), "required_rank")
        const rankRequiredToViewNotifications = _.result(_.find(permission_display, { "value": "view_notifications" }), "required_rank")
        const canViewGuildBank = rankRequiredToViewGuildBank && guildRank && guildRank <= rankRequiredToViewGuildBank ? true : false
        const canViewApplications = rankRequiredToViewApps && guildRank && guildRank <= rankRequiredToViewApps ? true : false
        const canViewNotifications = rankRequiredToViewNotifications && guildRank && guildRank <= rankRequiredToViewNotifications ? true : false

        const routes = (
            <Switch>
                <Route exact path='/:lang/guild/overview' component={GuildOverviewPage}/>
                <Route exact path='/:lang/guild/members' component={GuildMembersPage}/>
                {canViewApplications && <Route exact path='/:lang/guild/applications' component={GuildApplicationsPage}/>}
                {canViewGuildBank && <Route exact path='/:lang/guild/bank' component={GuildBankPage}/>}
                {canViewNotifications && <Route exact path='/:lang/guild/notifications' component={GuildNotificationsPage}/>}
                {guildRank === 1 && <Route exact path='/:lang/guild/leader-tools' component={GuildLeaderToolsPage}/>}
                {guildRank && guildRank !== 1 && <Route exact path='/:lang/guild/manage-membership' component={GuildManageMembershipPage}/>}
                <Redirect to='/:lang/guild/overview'/>
            </Switch>
        )
        this.setState({ routes })
    }

    render() {

        const { guild, location } = this.props
        const { routes } = this.state
        const guildIsRecruiting = guild.recruitingStatus === "open" || guild.recruitingStatus === "accepting_applications" ? true : false

        return (
            <Page>
                <div className="GuildManagementInterface">
                    <div className="GuildManagementInterface__motd">
                        <MotdBox />
                    </div>

                    {guild.crest && <div className="GuildManagementInterface__crest">
                        <GuildCrest crest={guild.crest} isRecruiting={guildIsRecruiting}/>
                    </div>}
                    
                    <div className="GuildManagementInterface__navigation">
                        <GuildNavigationMenu currPath={location.pathname}/>
                    </div>

                    <div className="GuildManagementInterface__pageContent">
                        {routes}
                    </div>
                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuildManagementInterface)