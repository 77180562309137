/*

FormMessage: FRAGMENT
displays a warning/error message for form fields.

PROPERTY  DEFAULT  REQUIREMENT  TYPE     DESCRIPTION
warning   false    optional     Boolean  updates styling to display as a warning message
error     false    optional     Boolean  updates styling to display as an error message
columnal  false    optional     Boolean  styling mimics the "columnal" styling prop seen in the FieldInput and FieldDropdown components
title     null     optional     String
content   ''       required     HTML          

*/

import React from 'react'
import './styles.scss'


const FormMessage = (props) => {

    const { warning, error, columnal, title, content } = props
    let className = "FormMessage"
    if (warning && !error) className += " FormMessage--warning"
    if (error && !warning) className += " FormMessage--error"
    if (columnal) className += " FormMessage--columnal"

    return (
        <div className={className}>
            {title && <div className="FormMessage__title">{title}</div>}
            <div dangerouslySetInnerHTML={{ __html: content }} className="FormMessage__content"/>
        </div>
    )
}

export default FormMessage