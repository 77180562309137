import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import FieldInput from '../../../componentLibrary/Models/FieldInput'
import Button from '../../../componentLibrary/Fragments/Button'
import { fetchCohorts } from '../../../redux/actions'
import { claimKickstarterPromoCode } from '../../../redux/actions/user'
import './styles.scss'


const mapDispatchToProps = dispatch => {
    return {
        fetchCohorts: () => dispatch(fetchCohorts()),
        claimKickstarterPromoCode: (code) => dispatch(claimKickstarterPromoCode(code)),
    }
}

class PromoCodeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            codeInput: null,
        }
        this.updateField = this.updateField.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }


    componentDidMount() {
        if (this.props.codeFieldInput) this.setState({ codeInput: this.props.codeFieldInput })
    }

    componentDidUpdate(prevProps) {
        const { codeFieldInput: prevCode } = prevProps
        const { codeFieldInput: currCode } = this.props
        if (!_.isEqual(prevCode, currCode)) this.setState({ codeInput: currCode })
    }

    updateField(value) {
        this.setState({ codeInput: value })
    }

    async handleSubmit() {
        this.setState({ loading: true })

        const { fetchCohorts, claimKickstarterPromoCode, handleSuccess } = this.props
        const { codeInput } = this.state
            
        try {
            await claimKickstarterPromoCode(!_.isEmpty(codeInput) ? codeInput.trim() : null)
            await fetchCohorts()
            swal(`${strings.getString("ACCOUNT_REDEEM_CODE_SUCCESS", "Successfully Redeemed Code")}`, {
                icon: 'success'
            })
            this.setState({ codeInput: null })
            if (!_.isEmpty(handleSuccess)) handleSuccess()
        } catch (err) {
            swal(`${_.result(err, 'response.data.message', strings.getString('ACCOUNT_REDEEM_CODE_ERROR', 'Uh Oh! Unable to Redeem Code'))}`, {
                icon: 'error',
            })
        }

        this.setState({ loading: false })
    }
    
    render () {

        const { loading, codeInput } = this.state
        const { codeFieldLabel } = this.props

        return (
            <div className="PromoCodeForm">
                
                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>

                <React.Fragment>
                    <FieldInput 
                        onChange={this.updateField} value={codeInput}
                        label={codeFieldLabel} placeholder={codeFieldLabel}/>
                    <div className="PromoCodeForm__buttons">
                        <Button medium label={strings.getString("SUBMIT", "Submit")} onclick={this.handleSubmit}/>
                    </div>
                </React.Fragment>
            
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(PromoCodeForm)