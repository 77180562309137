import React from 'react'
import { Button } from 'semantic-ui-react'

class TextLink extends React.Component {

  render() {

    const style = {
      background: 'none',
      padding: 0,
      color: 'rgb(50,50,50)',
      fontWeight: 'normal',
      fontSize: '16px',
      textAlign: 'left',
    }

    const { children, onClick } = this.props
    return (
      <Button className="text-only" compact style={style} onClick={onClick}>{children}</Button>
    )
  }
}

export default TextLink
