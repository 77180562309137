import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import OrderSummary from '../../../Store/checkout/OrderSummary'
import ReviewItems from '../../../Store/checkout/ReviewItems'
import PaymentMethods from '../../../Store/checkout/PaymentMethods'
import OrderDetails from '../../../Store/checkout/OrderDetails'
import CheckoutPromoCodeForm from '../../../Store/checkout/CheckoutPromoCodeForm'
import RegionBlock from '../../../componentLibrary/Models/RegionBlock'
import FormMessage from '../../../componentLibrary/Fragments/FormMessage'
import { fetchCohorts } from '../../../redux/actions'
import { generateBuyNowCheckout, getSingleStoreProduct } from '../../../redux/actions/crowfall-store'
import { getSingleTransaction } from '../../../redux/actions/user'
import './styles.scss'


const mapStateToProps = state => {
    return {
        language: state.language,
        acceptedRegionalAgreements: state.acceptedRegionalAgreements,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCohorts: () => dispatch(fetchCohorts()),
        generateBuyNowCheckout: (itemId, language, upgradeId, targetUsername, walletId, address) => dispatch(generateBuyNowCheckout(itemId, language, upgradeId, targetUsername, walletId, address)),
        getSingleStoreProduct: (id, language) => dispatch(getSingleStoreProduct(id, language)),
        getSingleTransaction: (id) => dispatch(getSingleTransaction(id)),
    }
}

class RegistrationCheckoutPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            success: false,
            warning: false,
            error: false,
            redirectToPacks: false,
            redirectToDownload: false,

            promoCode: null,

            itemId: null,
            item: {},

            order: null,
            orderId: null,

            solution: {},
            solutionDisplayStrings: null,
            availablePaymentMethods: null,
            itemIsDiscountable: false,
            itemGrantsGuildCreation: false,
            isSusbscribe: false
        }
        this.setInitialState = this.setInitialState.bind(this)
    }

    async componentDidMount() {
        this.setState({ loading: true })
        await this.setInitialState()
        this.setState({ loading: false })
    }

    async setInitialState() {
        const query = queryString.parse(this.props.location.search)
        await this.getItemDetails(query.itemId)
        await this.generateSolution(query.itemId)
    }

    async getItemDetails(itemId) {
        const item = await this.props.getSingleStoreProduct(itemId, this.props.language)
        this.setState ({ item })
    }

    async generateSolution(itemId) {
        try {
            const solution = await this.props.generateBuyNowCheckout(itemId, null, null, null, null)
            this.recordBuyNowCheckoutToGoogle(solution.item_details)
            this.handleSolution(solution)
        } catch (err) {
            this.catchSolutionError()
        }
    }

    recordBuyNowCheckoutToGoogle(item) {
        const category = _.isArray(item.categories) ? item.categories[0].category_key : null
        window.gtag("event", "begin_checkout", {
            items: [
                {
                    "id": item.item_id,
                    "name": item.item_slug,
                    "list_name": item.name,
                    "brand": "Crowfall",
                    "category": category,
                    "quantity": item.quantity || 1,
                    "price": item.purchase_amount / item.decimal_denominator
                  }
            ]
        })
    }

    handleSolution(solution) {
        const itemGrants = _.result(solution, 'item_details.grant_details')
        const itemGuildCreationGrants = !_.isEmpty(itemGrants) ? itemGrants.filter(grant => grant && grant.id === "KS_43") : null
        const methods = _.result(solution, 'allowed_methods', []).reduce((acc, curr) => {
            acc[curr] = true
            return acc
        }, {})
        this.setState({
            solution: solution,
            solutionDisplayStrings: _.result(solution, 'display_strings'),
            availablePaymentMethods: methods,
            itemIsDiscountable: _.result(solution, "item_details.discountable", false),
            itemGrantsGuildCreation: !_.isEmpty(itemGuildCreationGrants)
        })
    }

    catchSolutionError(err) {
        swal({
            icon: 'error',
            title: strings.getString('UH_OH', 'Uh Oh'),
            text: strings.getString('CHECKOUT_ERROR_GENERATED', 'There was an error when attempting to generate a checkout.  Please try again later.'),
        }).then(() => {
            this.setState({
                redirectToPacks: true,
            })
        })
    }

    handleLoadTransactionDetails = async (txnId, forceStatus) => {
        if (!forceStatus) {
            this.setState({
                loading: true,
            })

            try {
                const txn = await this.props.getSingleTransaction(txnId)
                const related = _.result(txn, 'related_transactions')

                this.setState({
                    order: txn,
                    orderId: txnId,
                })

                if (_.isNil(related)) {
                    if (txn.status === 'paid') {
                        this.handleSuccessTxn(txnId)
                    } else {
                        this.handleWarningTxn()
                    }

                } else {
                    const failedTransaction = _.find(related, {
                        status: 'failed',
                    })

                    const successTransaction = _.find(related, {
                        status: 'paid',
                    })

                    if (failedTransaction) {
                        this.handleFailedTxn()
                    } else if (successTransaction) {
                        this.handleSuccessTxn(txnId)
                    }
                }
            } catch (err) {
                console.log('Error: ', err)
            }
        } else {
            if (forceStatus.success) {
                this.handleSuccessTxn(txnId)
            } else if (forceStatus.error) {
                this.handleFailedTxn()
            } else if (forceStatus.fraud) {
                this.handleFailedTxn(forceStatus.message)
            }
        }
    }

    handleWarningTxn = () => {
        this.setState({
            warning: true,
            loading: false,
        })

        swal({
            icon: 'warning',
            title: strings.getString('UH_OH', 'Uh Oh'),
            text: strings.getString('CHECKOUT_ORDER_TAKING_TOO_LONG', 'The order is taking longer to process than expected.  If you aborted your order you can safely ignore this message, otherwise please check your order history in a few minutes.'),
        })
    }

    handleFailedTxn = (messageOverride) => {
        this.setState({
            error: true,
            loading: false,
        })

        swal({
            icon: 'error',
            title: strings.getString('UH_OH', 'Uh Oh'),
            text: messageOverride || strings.getString('STORE_PAYMENT_ISSUE_ERROR', 'There was an issue with the payment! Please check your payment information and try again.'),
        })
    }

    handleSuccessTxn = (txnId) => {
        const { fetchCohorts, } = this.props
        this.setState({
            success: true,
            loading: false,
            orderId: txnId,
        })

        fetchCohorts()

        this.setState({ redirectToDownload: true })
    }


    handlePromoCode(value, name) {
        this.setState({ [name]: value })
    }

    async submitPromoCode() {
        this.setState({ loading: true })

        const { getPromoCodeStatus, claimKickstarterPromoCode } = this.props
        try {
            const promoCode = await getPromoCodeStatus(this.state.promoCode)
            const { current_account_discount, max_code_discount } = promoCode

            try {
              await claimKickstarterPromoCode(this.state.promoCode)
              if ( current_account_discount > max_code_discount ) {
                swal({
                  icon: 'success',
                  content: (
                    <div>
                      <div className="RegistrationCheckoutPage__header">{strings.getString("ACCOUNT_REDEEM_CODE_SUCCESS", "Successfully Redeemed Code")}</div>
                      <div>
                        {strings.getString("CURRENT_CODE_TO_BE_APPLIED_PRE", "You currently have a discount being applied to this purchase that is greater than the discount being claimed.")}
                        <br/>
                        <br/>
                        {strings.getString("CURRENT_CODE_TO_BE_APPLIED_POST", `Instead, the ${this.state.promoCode} code will be added to your account to be redeemed at a later time, and the current ${this.state.solution.discount_name} discount will be applied to the purchase.`, { newCode: this.state.promoCode, currentDiscountName: this.state.solution.discount_name })}
                      </div>
                    </div>
                  )
                })
              } else if ( current_account_discount <= max_code_discount ) {
                swal(`${strings.getString("ACCOUNT_REDEEM_CODE_SUCCESS", "Successfully Redeemed Code")}`, {
                  icon: 'success'
                })
              }
              this.setState({ promoCode: '' })
              await this.setInitialState()

            } catch(err) {
              swal(`${_.result(err, 'response.data.message', strings.getString('ACCOUNT_REDEEM_CODE_ERROR', 'Uh Oh! Unable to Redeem Code'))}`, {
                icon: 'error',
              })
            }

          } catch(err) {
              swal(`${_.result(err, 'response.data.message', strings.getString('ACCOUNT_REDEEM_CODE_ERROR', 'Uh Oh! Unable to Redeem Code'))}`, {
              icon: 'error',
            })
          }

          this.setState({ loading: false })
    }

    render() {

        const { acceptedRegionalAgreements } = this.props
        const { loading, item, solution, availablePaymentMethods, itemIsDiscountable, itemGrantsGuildCreation, 
                order, orderId, success, warning, error, redirectToPacks, redirectToDownload, isSusbscribe } = this.state

        return (
            <div className="RegistrationCheckoutPage">

                {redirectToPacks && <Redirect to="/register?packs=true"/>}
                {redirectToDownload && <Redirect to={`/register?download=true&&orderId=${orderId}`}/>}

                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>


                <p>{strings.getString("FINALIZE_PURCHASE_BELOW", "Finalize your purchase through the checkout below.")}</p><br/>

                <ReviewItems item={item} grantsGuildCreation={itemGrantsGuildCreation}/>
       
                <div className="RegistrationCheckoutPage__orderSummary"><OrderSummary solution={solution}/></div>

                <CheckoutPromoCodeForm isDiscountable={itemIsDiscountable} onSuccess={this.setInitialState}/>

                {!success && !error && !warning &&
                    <PaymentMethods
                        item={item}
                        availablePaymentMethods={availablePaymentMethods}
                        solution={solution}
                        handleTxn={this.handleLoadTransactionDetails}
                        agreements={acceptedRegionalAgreements}
                        isSusbscribe={isSusbscribe}
                    />
                }

                {success && <FormMessage
                    title={strings.getString('PURCHASE_SUCCESSFUL', 'Purchase Successful!')}
                    content={strings.getString('CHECKOUT_PURCHASE_SUCCESSFUL_INFO', 'The purchase was successful! It may take a few moments before the purchased Items and Currency appear on your account.')}
                />}
                {warning && <FormMessage
                    title={strings.getString('STATUS_UNKNOWN', 'Status Unknown!')} warning
                    content={strings.getString('CHECKOUT_PURCHASE_STATUS_UNKNOWN', 'The purchase status is currently unknown. If you canceled the checkout process you can safely ignore this message and chose another payment method, otherwise please check your order history in a few minutes.')}
                />}
                {error && <FormMessage
                    title={strings.getString('UH_OH', 'Uh Oh!')} error
                    content={strings.getString('STORE_PAYMENT_ISSUE_ERROR', 'There was an issue with the payment! Please check your payment information and try again.')}
                />}


                {order && <OrderDetails order={order} />}


                <RegionBlock artcraft>
                    * {strings.getString('STORE_TAX_DISCLAIMER', 'All prices are in USD unless otherwise stated and do not include regional tax.')}
                </RegionBlock>
                <RegionBlock eu>
                    * {strings.getString('STORE_TRAVIAN_TAX_DISCLAIMER', 'Order Price VAT is included, if applicable.')}
                </RegionBlock>
            </div>
        )
    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistrationCheckoutPage))