import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import assets from '../../../assets'
import _ from 'lodash'
import strings from '../../../strings'
import queryString from 'query-string'
import RegistrationForm from '../RegistrationForm'
import './styles.scss'


class RegistrationPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAffiliateCode: false,
      isFriendCode: false,
    }
    this.handleSuccess = this.handleSuccess.bind(this)
  }

  componentDidMount = async () => {
    this.recordToLiquidAdvertising()
    const q = _.result(this.props, 'location.search')
    const query = queryString.parse(q) || {}

    let referralCodeInput = ""
    if (query.friend) referralCodeInput = query.friend
    if (query.affiliateCode) referralCodeInput = query.affiliateCode
    if (query.code) referralCodeInput = query.code

    this.setState({
      isAffiliateCode: !!query.affiliateCode,
      isFriendCode: !!query.friend,
      referralCodeInput,
    })
  }

  recordToLiquidAdvertising() {
    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'session_id': Date.now() + Math.random().toString(36),
      'send_to': 'DC-11063492/crowfall/d_cro001+per_session'
    })
  }

  handleSuccess() {
    this.props.history.push("/register?validate=true")
  }

  render() {

    const { isFriendCode, isAffiliateCode, referralCodeInput } = this.state
    const codeFieldLabel = isAffiliateCode ? strings.getString("AFFILIATE_REFERRAL_CODE", "Affiliate Referral Code") : 'Buddy Code'

    return (
      <div className='RegistrationPage'>
        {isFriendCode &&
          <div className='RegistrationPage__marketing' style={{backgroundImage: `url(${assets.staticImageUrls.CHECKOUT_MARKETING_CTA})`}}>
            <div className='RegistrationPage__marketingText'>
                <div className='RegistrationPage__marketingTextBlur' />
                <div>{strings.getString("REFER_A_FRIEND_FUN_WITH_FRIENDS").toUpperCase()}!</div>
            </div>
          </div>
        }

          <RegistrationForm codeFieldInput={referralCodeInput} codeFieldLabel={codeFieldLabel} onSuccess={this.handleSuccess}/>

          <br/>
          <p>{strings.getString("REGISTRATION_UNDERAGE_LEGAL_TEXT", "Our services are not intended for and should not be used by children under the age of 13.")}</p>
          <br/>

      </div>
    )
  }
}

export default withRouter(RegistrationPage)
