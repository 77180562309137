import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import queryString from 'query-string'
import strings from '../../../../strings'
import assets from '../../../../assets'
import contentful from '../../../../utilities/contentful'
import Page from '../../../../componentLibrary/Models/Page'
import Button from '../../../../componentLibrary/Fragments/Button'
import TextLink from '../../../../componentLibrary/Fragments/TextLink'
import RegistrationForm from '../../RegistrationForm'
import LoginForm from '../../LoginForm'
import PromoCodeForm from '../../PromoCodeForm'
import HelperLinks from '../../HelperLinks'
import { verifyValidationToken, validateAccount } from '../../../../redux/actions'
import './styles.scss'


const mapStateToProps = state => {
    return {
        cohorts: state.cohorts,
        session: state.session,
        config: state.config,
        acceptedRegionalAgreements: state.acceptedRegionalAgreements,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        verifyValidationToken: (token) => dispatch(verifyValidationToken(token)),
        validateAccount: (token) => dispatch(validateAccount(token)),
    }
}

class RazerRegistration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initialFetch: false,
            title: null,
            bannerAssetUrl: null,
            featuredAsset: null,
            tagline: null,
            content: null,
            razerCode: null,
            showLoginForm: false,

            codeRedeemed: false,
            redirectToHome: false,
        }
        this.getPageContent = this.getPageContent.bind(this)
        this.getQueryParameters = this.getQueryParameters.bind(this)
        this.handleRegistrationSuccess = this.handleRegistrationSuccess.bind(this)
        this.handleValidationError = this.handleValidationError.bind(this)
    }

    async componentDidMount() {
        this.getQueryParameters()
        await this.getPageContent()
        this.setState({ initialFetch: true })
    }

    componentDidUpdate(prevProps) {
        const { location: prevLocation } = prevProps
        const { location: currLocation } = this.props
        if (!_.isEqual(prevLocation.search, currLocation.search)) this.getQueryParameters()
    }

    async getPageContent() {
        const content = await contentful.getEntry(assets.contentfulIds.RAZER_REGISTRATION_PAGE)
        this.setState({
            title: content.pageTitle,
            bannerAssetUrl: _.result(content, "banner.assetUrl"),
            featuredAsset: _.result(content, "footerBannerAsset.assetUrl"),
            tagline: content.tagline,
            content: content.pageFooter,
        })
    }

    getQueryParameters() {
        const { location } = this.props
        const query = queryString.parse(location.search)
        if (query.token) {
            this.validateAccountWithToken(query.token)
        } else {
            this.setState({ 
                razerCode: query.code ? query.code : null,
                showLoginForm: !!query.login,
            })
        }
    }

    async validateAccountWithToken(token) {
        const { verifyValidationToken, validateAccount } = this.props
        try {
            const status = await verifyValidationToken(token)
            const { validation_token_active: validationTokenActive, validation_set: validationTokenSet, username_set: usernameSet } = status
            if (validationTokenActive && !validationTokenSet && usernameSet) {
                try {
                    validateAccount(token)
                } catch (err) {
                    this.handleValidationError()
                }
            } else if (!validationTokenActive && !validationTokenSet) {
                this.handleValidationError()
            }
        } catch (err) {
            this.handleValidationError()
        }
    }

    handleValidationError() {
        swal({
            title: strings.getString("UH_OH", "Uh Oh!"),
            icon: 'error',
            content: ( <span>{strings.getString("VALIDATION_ACCOUNT_ERROR", "Unable to validate account")}</span> )
        })
        this.setState({ redirectToHome: true })
    }

    handleRegistrationSuccess() {
        this.setState({ redirectToHome: true })
    }

    render() {

        const { initialFetch, title, bannerAssetUrl, featuredAsset, tagline, content, razerCode, showLoginForm, redirectToHome } = this.state
        const { config, session, cohorts } = this.props
        const { can_play_crowfall: canDownloadGame, consumed_razer_code: hasRazerCode } = cohorts
        const downloadLink = canDownloadGame ? `${_.result(config, "base_api_host")}/user/asset-download/CFInstaller_Live.exe?token=${_.result(session, "token")}` : null
        const bannerRibbon =
            <div className="RazerRegistration__ribbon">
                <img className="RazerRegistration__ribbonAsset" src={assets.staticImageUrls.LOGO_RAZER_X_CROWFALL} alt="Razer X Crowfall Logo"/>
            </div>
        

        return (
            <Page header={{ title, banner: bannerAssetUrl, ribbon: bannerRibbon }} bannerShort mobileBannerHidden>

                {redirectToHome && <Redirect to="/"/>}

                <div className="RazerRegistration">
                    <div className="RazerRegistration__mobileRibbon">{bannerRibbon}</div>

                    {!_.isEmpty(tagline) && !hasRazerCode && <div className="RazerRegistration__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>}

                    <div className="RazerRegistration__content">

                        {initialFetch && session && !hasRazerCode &&
                            <div className="RazerRegistration__box">
                                <PromoCodeForm 
                                    codeFieldLabel={strings.getString('ENTER_YOUR_BETA_CODE', 'Enter Your Beta Code')}
                                    codeFieldInput={razerCode}/>
                                <div className="RazerRegistration__title">{strings.getString("DOWNLOAD_BETA_NOW", "Download Beta Now")}</div>
                                <div className="RazerRegistration__gameLinks">
                                    <a href={downloadLink}>
                                        <Button green medium disabled={!canDownloadGame} label={strings.getString("DOWNLOAD_BETA_NOW", "Download Beta Now")}/>
                                    </a>
                                    {!canDownloadGame && <TextLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString("BUY_CROWFALL", "Buy Crowfall")}</TextLink>}
                                </div>
                            </div>
                        }

                        {initialFetch && !session && !showLoginForm &&
                            <div className="RazerRegistration__box">
                                <HelperLinks useRazerRedirects code={razerCode} showSignInHelp/>
                                <br/>
                                {/*<RegistrationForm */}
                                {/*    onSuccess={this.handleRegistrationSuccess} */}
                                {/*    codeFieldLabel={strings.getString('ENTER_BETA_CODE_FOR_REGISTRATION', 'Enter Your Beta Code to Create Your Crowfall Account')} */}
                                {/*    codeFieldInput={razerCode} orderCodeFieldFirst*/}
                                {/*    registrationSource="razer-lander"/>*/}
                            </div>
                        }

                        {initialFetch && !session && showLoginForm &&
                            <div className="RazerRegistration__box">
                                <HelperLinks useRazerRedirects code={razerCode} showSignupHelp/>
                                <br/>
                                <LoginForm 
                                    addCodeField
                                    codeFieldLabel={strings.getString('ENTER_BETA_CODE_FOR_REGISTRATION', 'Enter Your Beta Code to Create Your Crowfall Account')} 
                                    codeFieldInput={razerCode}/>
                                <br/>
                                <HelperLinks showAccountHelp/>
                            </div>
                        }

                        {initialFetch && session && hasRazerCode &&
                            <div className="RazerRegistration__box">
                                <div className="RazerRegistration__header">{strings.getString("CONGRATULATIONS", "Congratulations!")}</div>
                                <div className="RazerRegistration__text">{strings.getString("WELCOME_TO_RAZER", "Welcome to the Razer x Crowfall Closed Beta!")}</div>
                                <div className="RazerRegistration__text">{strings.getString("DOWNLOAD_BETA_CLICK_BELOW", "Download the Beta Now - Click the Button Below!")}</div>
                                <br/><br/>

                                <div className="RazerRegistration__title">{strings.getString("DOWNLOAD_BETA_NOW", "Download Beta Now")}</div>
                                <div className="RazerRegistration__gameLinks">
                                    <a href={downloadLink}>
                                        <Button green medium disabled={!canDownloadGame} label={strings.getString("DOWNLOAD_BETA_NOW", "Download Beta Now")}/>
                                    </a>
                                    {!canDownloadGame && <TextLink to="/store?category=064b6196-98fe-46c7-94d4-9485dad599de">{strings.getString("BUY_CROWFALL", "Buy Crowfall")}</TextLink>}
                                </div>
                            </div>
                        }


                        <div className="RazerRegistration__box">
                            <img src={featuredAsset} className="RazerRegistration__asset" alt="Razer Sigil"/>
                            {(!hasRazerCode || !showLoginForm) && <div className="RazerRegistration__callout" dangerouslySetInnerHTML={{ __html: strings.getString("RAZER_REGISTRATION_CALLOUT") }}/>}
                            <div className="RazerRegistration__header">{strings.getString("REDEEM_CODE_CREATE_ACCOUNT", "Redeem *Code / Create Your Crowfall Account")}</div>
                            <div dangerouslySetInnerHTML={{ __html: content }}/>
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RazerRegistration))