import React from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import strings from '../../../strings'
import './styles.scss'


class DataTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: null,
			hover: {
				id: null,
				index: null
			},
			sort: {
				index: null,
				category: null,
				direction: -1
			}
		}

		this.selectHover = this.selectHover.bind(this);
		this.clearHover = this.clearHover.bind(this);
		this.selectRow = this.selectRow.bind(this);
		this.route = this.route.bind(this);
		this.select = this.select.bind(this);
		this.selectSort = this.selectSort.bind(this);
		this.sort = this.sort.bind(this);
	}

	selectHover(id, index) {
		this.setState({
			hover: {
				id,
				index
			}
		})
	}

	clearHover() {
		this.setState({
			hover: {
				id: null,
				index: null
			}
		})
	}

	selectRow(id) {
		this.setState({
			selected: id === this.state.selected ? null : id
		}, this.select);
	}

	route(path) {
		if (!path) return;
		this.props.history.push(path);
	}

	select() {
		const { select } = this.props
		if (!_.isEmpty(select)) select(this.state.selected);
	}

	selectSort(sortable, index, category) {
		if (!sortable) return;

		if (index === this.state.sort.index) {
			this.setState({
				sort: {
					index: this.state.sort.index,
					category: this.state.sort.category,
					direction: this.state.sort.direction * -1
				}
			}, this.sort)
		} else {
			this.setState({
				sort: {
					index,
					category,
					direction: -1
				}
			}, this.sort);
		}
	}

	sort() {
		const { sort } = this.props;
		const { category, direction } = this.state.sort;
		if (!sort) return;
		sort(criterion);

		function criterion(a, b) {
			if (a === null || a === undefined) {
				if (b === null || b === undefined) return 0;
				return direction * -1;
			}
			return a[category] >  b[category] ? direction * -1 : direction;
		}
		
	}

	render() {

		const { rows, gold, labels, select } = this.props;
		let classNameHorizontal = "DataTable--horizontal";
		if (gold) classNameHorizontal += " DataTable--horizontalGold"
		let classNameVertical = "DataTable--vertical"
		if (gold) classNameVertical += " DataTable--verticalGold"

		let classNameHorizontalRow = "DataTableHorizontal__row"
		const hasAccordionElements = !_.isEmpty(_.find(rows, 'accordionElement'))
		classNameHorizontalRow += hasAccordionElements ? ` DataTable__grid--accordion${ labels.length }` : ` DataTable__grid--${ labels.length }`

		const tableLabels = (
			<div className={classNameHorizontalRow} key="DataTableHorizontal--labels">
				{this.props.labels.map((column, index) => {
					let className = "DataTableHorizontal__label";
					if (hasAccordionElements && index === (labels.length - 1) ) className += " DataTableHorizontal__label--accordionLabel"
					if (column.sortable) className += " DataTableHorizontal__label--sortable";
					if (index === this.state.sort.index) {
						if (this.state.sort.direction === -1) {
							className += " DataTableHorizontal__label--sortAsc";	
						} else {
							className += " DataTableHorizontal__label--sortDesc";
						}
					}
			
					return (
						<div className={ className } key={ "label-" + index } onClick={ () => this.selectSort(column.sortable, index, column.category) }>
							{ column.label }
							<div className="DataTableHorizontal__labelArrow">&#8250;</div>
						</div>
					)
				})}
			</div>
		)

		const rowElements = rows.map((row, rowIndex) => {
			let { id, elements, accordionElement } = row
			const isHovered = id === this.state.hover.id ? true : false
			const isSelected = id === this.state.selected ? true : false
			const isAccordion = !_.isEmpty(accordionElement)
			let classNameIcon = "DataTableHorizontal__accordionIcon"
			if ((isHovered && select) || (isHovered && isAccordion)) classNameIcon += ' DataTableHorizontal--highlightedRow'
			return (
				<div className={classNameHorizontalRow} key={`Datatable-horizontal-row-${id}-${rowIndex}`}>
					{elements.map((element, elementIndex) => {
						let squareClassName = 'DataTableHorizontal__square';
						if ((isHovered && select) || (isHovered && isAccordion)) squareClassName += ' DataTableHorizontal--highlightedRow'
						return (
							<div
								className={ squareClassName }
								onMouseEnter={ () => this.selectHover(id, rowIndex) }
								onMouseLeave={ this.clearHover }
								onClick={ () => this.selectRow(id) }
								key={ 'square-' + rowIndex + "-" + elementIndex }
							>
								{ element }
							</div>
						)
					})}
					<div 
						className={classNameIcon}
						onMouseEnter={ () => this.selectHover(id, rowIndex) }
						onMouseLeave={ this.clearHover }
						onClick={ () => this.selectRow(id) }
					>
						{isAccordion && <FontAwesomeIcon icon={isSelected ? "chevron-down" : "chevron-right"}/>}
					</div>
					{isAccordion && isSelected && <div className="DataTableHorizontal__accordionItem">{accordionElement}</div>}
				</div>
			)
		})

		const horizontalGridElements = [ tableLabels, ...rowElements ]
    	horizontalGridElements.push(<div className="DataTableHorizontal__end" key="DatatableHorizontal-end"></div>)
    
		const verticalGridElements = this.props.rows.map((row, rowIndex) => {
			let { id, elements, accordionElement } = row
			let classNameVerticalRow = "DataTableVertical__row"
			if (hasAccordionElements) classNameVerticalRow += " DataTableVertical--accordion"
			const isSelected = id === this.state.selected ? true : false
			return (
				<div 
					className={ classNameVerticalRow }
					onClick={ () => this.selectRow(id) }
					key={ id + rowIndex }
				>
					{!hasAccordionElements && elements.map((element, index) => {
						const key = labels[index] && labels[index].label ? labels[index].label + " :" : null
							return (
								<div className="DataTableVertical__field" key={ id + "row-element" + index}>
									<div className='DataTableVertical__fieldKey'>{key}</div>
									<div className='DataTableVertical__fieldValue'>{element}</div>
								</div>
							)
						})
					}
					{hasAccordionElements && <div className="DataTableVertical__accordion">
						<div className="DataTableVertical__accordionActor">
							<div className="DataTableVertical__accordionLabel">{elements[0]}</div>
							<div className="DataTableVertical__accordionIcon"><FontAwesomeIcon icon={isSelected ? "chevron-down" : "chevron-right"}/></div>
						</div>
						{isSelected && <div className="DataTableVertical__accordionContent">
							{elements.slice(1).map((element, index) => {
								const key = labels[index] && labels[index].label ? labels[index].label + " :" : null
								return (
									<div className="DataTableVertical__field" key={ id + "row-element" + index}>
										<div className='DataTableVertical__fieldKey'>{key}</div>
										<div className='DataTableVertical__fieldValue'>{element}</div>
									</div>
								)
							})}
							{!_.isEmpty(accordionElement) && <React.Fragment>
								{accordionElement}	
							</React.Fragment>}
						</div>}
					</div>}
				</div>
			)
		})


		return (
			<>

				{ this.props.rows.length < 1 &&
					<div>{strings.getString("NO_RESULTS", "No results")}</div>
				}

				{ this.props.rows.length >= 1 &&
					<>
						<div className={classNameHorizontal}>
							{ horizontalGridElements }
						</div>
						<div className={classNameVertical}>
							{ verticalGridElements }
					</div>
					</>
				}
			</>
		);
	}
}

export default DataTable