import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import strings from '../strings'
import VerticalMenu from '../componentLibrary/Models/VerticalMenu'

const mapStateToProps = state => {
  return {
    accountRegions: state.user.accountRegions,
    giftsAvailable: state.user.giftsAvailable || [],
    cohorts: state.cohorts,
  }
}

class AccountDetailsMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuBarLabel: null,
      menuSections: [],
    }
    this.setMenuSections = this.setMenuSections.bind(this)
  }

  componentDidMount() {
    this.setMenuSections()
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps
    const { location: currLocation } = this.props
    if (currLocation.pathname !== prevLocation.pathname) this.setMenuSections()
  }

  setMenuSections() {
    const { giftsAvailable, cohorts, location, accountRegions } = this.props
    const showShippingPage = !!cohorts.collectors_edition
    const showDownloadableRewardsPage = cohorts.artbook_download || cohorts.soundtrack_download
    const pathSplit = location.pathname.split('/')
    const activePage = pathSplit[pathSplit.length - 1]
    const userCanGift = _.isEmpty(_.find(accountRegions, { region_name: "Brazil" }))


    const menuSections = [
      {
        label: strings.getString("GENERAL", "General"),
        items: [
          {
            label: strings.getString("ACCOUNT_PROFILE", "Account Profile"),
            link: "/account/profile",
            isActive: activePage === "profile",
          },
          // {
          //   label: strings.getString("FORUM_BADGES", "Forum Badges"),
          //   link: "/account/forum-badges",
          //   isActive: activePage === "forum-badges",
          // },
          showShippingPage ? {
            label: strings.getString("SHIPPING_ADDRESS", "Shipping Address"),
            link: "/account/shipping-address",
            isActive: activePage === "shipping-address"
          } : null
        ]
      },
      {
        label: strings.getString("VIP", "Vip"),
        items: [
          {
            label: strings.getString("VIP_STATUS", "VIP Status"),
            link: "/account/vip-status",
            isActive: activePage === "vip-status",
          },
        ]
      },
      {
        label: strings.getString("REWARDS", "Rewards"),
        items: [
          {
            label: strings.getString("REWARDS", "Rewards"),
            link: "/account/rewards",
            isActive: activePage === "rewards",
          },
          showDownloadableRewardsPage ?{
            label: strings.getString("ACCOUNT_DOWNLOADABLE_REWARDS", "Downloadable Rewards"),
            link: "/account/downloadable-rewards",
            isActive: activePage === "downloadable-rewards",
          } : null,
          {
            label: strings.getString("CONSUMED_REWARDS", "Consumed Rewards"),
            link: "/account/rewards-consumed",
            isActive: activePage === "rewards-consumed",
          },
          {
            label: strings.getString("REDEEMED_REWARDS", "Redeemed Rewards"),
            link: "/account/rewards-redeemed",
            isActive: activePage === "rewards-redeemed",
          },
          {
            label: strings.getString("CODES", "Codes"),
            link: "/account/codes",
            isActive: activePage === "codes",
          },
        ]
      },
      // {
      //   label: strings.getString('DOWNLOADS', 'Downloads'),
      //   items: [
      //     {
      //       label: strings.getString("GAME_CLIENT", "Game Client"),
      //       link: "/account/download-client",
      //       isActive: activePage === "download-client",
      //     },
      //     {
      //       label: strings.getString("ADDITIONAL_DOWNLOADS", "Additional Downloads"),
      //       link: "/account/additional-downloads",
      //       isActive: activePage === "additional-downloads"
      //     },
      //   ]
      // },
      {
        label: strings.getString("ACCOUNT_TO_ACCOUNT_GIFTING", "Account-to-account Gifting"),
        items: [
          userCanGift && {
            label: strings.getString("GIFTS", "Gifts"),
            link: "/account/gifts",
            isActive: activePage === "gifts",
          },
          userCanGift && {
            label: strings.getString("GIFTS_GIVEN", "Gifts Given"),
            link: "/account/gifts-given",
            isActive: activePage === "gifts-given",
          },
          {
            label: strings.getString("GIFTS_RECEIVED", "Gifts Received"),
            count: giftsAvailable.length > 0 ? giftsAvailable.length : null,
            link: "/account/gifts-received",
            isActive: activePage === "gifts-received",
          },
        ]
      },
      {
        label: strings.getString("SECURITY", "Security"),
        items: [
          {
            label: strings.getString("SECURITY_SETTINGS", "Security Settings"),
            link: "/account/security-settings",
            isActive: activePage === "security-settings",
          },
          {
            label: strings.getString('2FA_2_STEP_VERTIFICATION', '2-Step Verification'),
            link: "/account/2fa-device",
            isActive: activePage === "2fa-device",
          },
        ]
      },
      {
        label: strings.getString("STORE", "Store"),
        items: [
          {
            label: strings.getString("ORDER_HISTORY", "Order History"),
            link: "/account/order-history",
            isActive: activePage === "order-history",
          },
          {
            label: strings.getString("WALLETS", "Wallets"),
            link: "/account/wallets",
            isActive: activePage === "wallets",
          },
          // {
          //   label: strings.getString("LAYAWAY", "Layaway"),
          //   link: "/account/layaway",
          //   isActive: activePage === "layaway",
          // },
          {
            label: strings.getString("DISCOUNTS", "Discounts"),
            link: "/account/discounts",
            isActive: activePage === "discounts",
          },
          // {
          //   label: strings.getString("REDEEM_CODES", "Redeem Codes"),
          //   link: "/account/redeem-codes",
          //   isActive: activePage === "redeem-codes",
          // },
        ]
      },
      // {
      //   label: strings.getString("GUILD_MEMBERSHIP", "Guild Membership"),
      //   items: [
      //     {
      //       label: strings.getString("GUILD_INVITES", "Guild Invites"),
      //       link: "/account/guild-invites",
      //       isActive: activePage === "guild-invites",
      //     },
      //     {
      //       label: strings.getString("GUILD_APPLICATIONS", "Guild Applications"),
      //       link: "/account/guild-applications",
      //       isActive: activePage === "guild-applications",
      //     },
      //   ]
      // },
      // {
      //   label: strings.getString("TEAMS", "Teams"),
      //   items: [
      //     {
      //       label: strings.getString("TEAM_DETAILS", "Team Details"),
      //       link: "/account/teams",
      //       isActive: activePage === "teams",
      //     },
      //   ]
      // },
      // {
      //   label: strings.getString("AFFILIATES", "Affiliates"),
      //   items: [
      //     {
      //       label: strings.getString("AFFILIATE_PROFILE", "Affiliate Profile"),
      //       link: "/account/affiliate-details",
      //       isActive: activePage === "affiliate-details",
      //     },
      //   ]
      // },
      // {
      //   label: strings.getString("EXTERNAL_ACCOUNTS", "External Accounts"),
      //   items: [
      //     {
      //       label: "Twitch",
      //       link: "/account/twitch-management",
      //       isActive: activePage === "twitch-management",
      //     }
      //     // {
      //     //   label: "Discord",
      //     //   link: "/account/discord-management",
      //     //   isActive: activePage === "discord-management",
      //     // },
      //   ]
      // },
    ]
    this.setState({ menuSections })
  }

  render() {
    return (
      <VerticalMenu
        barLabel={strings.getString("ACCOUNT_MENU", "Account Menu")}
        sections={this.state.menuSections}
      />
    )
  }
}

export default withRouter(connect(mapStateToProps)(AccountDetailsMenu))
