/*

Fragment: OutsideClickCheck
Functions as a wrapper that fires function when the user clicks outside of the wrapper

PROPERTY        DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

onClickOutside  no-op    Required     Function  Function to be enacted when user clicks outside of the ref

*/


import React, { useRef, useEffect } from 'react'


function useOutsideClickCheck(ref, onclick) {

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            onclick()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    })
}


const OutsideClickCheck = (props) => {

    const { onClickOutside, children } = props
    const wrapperRef = useRef(null)
    useOutsideClickCheck(wrapperRef, onClickOutside)

    return (
        <div ref={wrapperRef}>
            {children}
        </div>
    )
}

export default OutsideClickCheck