import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import strings from '../../../strings'
import VerticalMenu from '../../../componentLibrary/Models/VerticalMenu'
import './styles.scss'


const mapStateToProps = state => {
    return {
        guildRank: state.guildManagement.guildRank,
        guildConfiguration: state.guildManagement.guildConfiguration,
        applications: state.guildManagement.guildApplications
    }
}

class GuildNavigationMenu extends React.Component {

    render() {

        const { guildRank, guildConfiguration, applications, currPath } = this.props
        const pathSplit = currPath ? currPath.split('/') : []
        const activePage = pathSplit[pathSplit.length - 1]
        const applicationCount = applications && applications.length > 0 ? applications.length : null
        const rankRequiredToViewGuildBank = _.result(_.find(guildConfiguration.permission_display, { "value": "view_guild_bank" }), "required_rank")
        const rankRequiredToViewApps = _.result(_.find(guildConfiguration.permission_display, { "value": "view_pending_invites" }), "required_rank")
        const rankRequiredToViewNotifications = _.result(_.find(guildConfiguration.permission_display, { "value": "view_notifications" }), "required_rank")
        let menuBarLabel = `${strings.getString("GUILD_MENU", "Guild Menu")}: `
        let menuSections = [
            {
                label: strings.getString("OVERVIEW", "Overview"),
                link: "/guild/overview",
                isActive: activePage === "overview",
            },
            {
                label: strings.getString("GUILD_MEMBERS", "Guild Members"),
                link: "/guild/members",
                isActive: activePage === "members",
            },
        ]
        if (rankRequiredToViewApps && guildRank && guildRank <= rankRequiredToViewApps) menuSections.push({
            label: strings.getString("APPLICATIONS", "Applications"),
            link: "/guild/applications",
            isActive: activePage === "applications",
            count: applicationCount,
        })
        if (rankRequiredToViewGuildBank && guildRank && guildRank <= rankRequiredToViewGuildBank) menuSections.push({
            label: strings.getString("GUILD_BANK", "Guild Bank"),
            link: "/guild/bank",
            isActive: activePage === "bank",
        })
        if (rankRequiredToViewNotifications && guildRank && guildRank <= rankRequiredToViewNotifications) menuSections.push({
            label: strings.getString("NOTIFICATION_LOG", "Notification Log"),
            link: "/guild/notifications",
            isActive: activePage === "notifications",
        })
        if (guildRank === 1) menuSections.push({
            label: strings.getString("GUILD_LEADER_TOOLS", "Guild Leader Tools"),
            link: "/guild/leader-tools",
            isActive: activePage === "leader-tools",
        })
        if (guildRank && guildRank !== 1) menuSections.push({
            label: strings.getString("MANAGE_MEMBERSHIP", "Manage Membership"),
            link: "/guild/manage-membership",
            isActive: activePage === "manage-membership"
        })
        menuSections.forEach(page => {
            if (page.isActive) menuBarLabel += page.label
        })

        return (
            <div className="GuildNavigationMenu">
                <VerticalMenu sections={menuSections} barLabel={menuBarLabel}/>
            </div>
        )
    }
}

export default connect(mapStateToProps)(GuildNavigationMenu)