import React from 'react'
import { connect } from 'react-redux'
import strings from '../../../strings'
import TextLink from '../../../componentLibrary/Fragments/TextLink'
import TextButton from '../../../componentLibrary/Fragments/TextButton'
import ForgotEmail from './ForgotEmail'
import ResetPassword from './ResetPassword'
import ResendEmail from './ResendEmail'
import { setModalContent } from '../../../redux/actions'
import './styles.scss'


const mapDispatchToProps = dispatch => {
    return {
        setModalContent: (content) => dispatch(setModalContent(content)),
    }
}

const HelperLinks = (props) => {
    
    const { useRazerRedirects, useIntelRedirects, useTwitchRedirects, useAlienwareRedirects, 
            code, showAccountHelp, showSignupHelp, showSignInHelp, setModalContent, showCodeHelp } = props
    let registerLink = "/register"
    let loginLink = "/login"
    
    if (useRazerRedirects) {
        registerLink = "/beta-key-giveaway"
        loginLink = "/beta-key-giveaway?login=true"
    } else if (useIntelRedirects) {
        registerLink = "/eternal-heroes-wanted"
        loginLink = "/eternal-heroes-wanted?login=true"
    } else if (useTwitchRedirects) {
        registerLink = "/twitch"
        loginLink = "/twitch?login=true"
    } else if (useAlienwareRedirects) {
        registerLink = "/join-the-battle"
        loginLink = "/join-the-battle?login=true"
    }

    if (code) {
        registerLink += `?code=${code}`
        loginLink += `&code=${code}`
    }


    return (
        <div className="HelperLinks">

            {showAccountHelp && <div className="HelperLinks__section">
                <div className="HelperLinks__header">{strings.getString('SELF_HELP_TITLE', 'Trouble signing in?')}</div>
                <div className="HelperLinks__link"><TextButton onClick={() => setModalContent(<ForgotEmail/>)}>
                    {strings.getString('ACCOUNT_FORGOT_EMAIL', 'I forgot my Email Address')}</TextButton></div>
                <div className="HelperLinks__link"><TextButton onClick={() => setModalContent(<ResetPassword/>)}>
                    {strings.getString('ACCOUNT_FORGOT_PASSWORD', 'I forgot my Password')}</TextButton></div>
                <div className="HelperLinks__link"><TextButton onClick={() => setModalContent(<ResendEmail/>)}>
                    {strings.getString("ACCOUNT_RESEND_EMAIL_VALIDATION", "I didn't receive an email to validate my Account")}</TextButton></div>
            </div>}

            {showCodeHelp && <div className="HelperLinks__section">
                <div className="HelperLinks__header">{strings.getString("TROUBLE_WITH_YOUR_CODE", "Trouble with your code?")}</div>
                <div className="HelperLinks__link"><TextButton onClick={() => setModalContent(<ResendEmail/>)}>
                    {strings.getString("ACCOUNT_RESEND_EMAIL_VALIDATION", "I didn't receive an email to validate my Account")}</TextButton></div>
            </div>}

            {/*{showSignupHelp && <div className="HelperLinks__section">*/}
            {/*    <div className="HelperLinks__header">{strings.getString('SELF_HELP_REGISTER', 'Need an Account?')}</div>*/}
            {/*    <div className="HelperLinks__link"><TextLink to={registerLink}>{strings.getString('SIGN_UP_NOW', 'Sign up now!')}</TextLink></div>*/}
            {/*</div>}*/}

            {showSignInHelp && <div className="HelperLinks__section">
                {/*<div className="HelperLinks__header">{strings.getString('SELF_HELP_REGISTERED', 'Already Registered?')}</div>*/}
                <div className="HelperLinks__link"><TextLink to={loginLink}>{strings.getString('REGISTRATION_SELF_HELP_LOGIN', 'Sign In to access your existing account.')}</TextLink></div>
            </div>}
        </div>
    )
}

export default connect(null, mapDispatchToProps)(HelperLinks)