import React from 'react'
import strings from '../../../../strings'
import FieldInput from '../../../../componentLibrary/Models/FieldInput'
import Button from '../../../../componentLibrary/Fragments/Button'
import './styles.scss'


class EditMotdModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            motd: this.props.guild.motd,
            motdCharLimit: 400,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.motdFieldChange = this.motdFieldChange.bind(this)
    }

    async handleSubmit() {
        this.setState({ loading: true })
        await this.props.submitMotd()
        this.setState({ loading: false })
    }

    motdFieldChange(value, name) {
        this.setState({ motd: value })
        this.props.updateMotd(value, name);
    }

    render() {
        const { guild, clearModalContent } = this.props
        const { loading, motd, motdCharLimit } = this.state

        return (
            <div className="EditMotdModal">
                <div className="EditMotdModal__header">{strings.getString("EDIT_MESSAGE_OF_DAY", "Edit Message of the Day")}</div>
                <div className={`EditMotdModal__editField ${motd.length > motdCharLimit ? "limitReached" : ""}`}>
                    <FieldInput
                        name="newMotd" value={guild.motd} onChange={this.motdFieldChange} area
                    />
                </div>
                <span className="EditMotdModal__char-count">{motd.length} / {motdCharLimit}</span>
                <div className="EditMotdModal__buttons">
                    <div className="EditMotdModal__button"><Button medium loading={loading} gray onclick={clearModalContent} label={strings.getString("CANCEL", "Cancel")}/></div>
                    <div className="EditMotdModal__button"><Button medium loading={loading} onclick={this.handleSubmit} label={strings.getString("UPDATE_MESSAGE", "Update Message")}/></div>
                </div>
            </div>
        )
    }
}

export default EditMotdModal
