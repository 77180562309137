/*

Checkbox: Element

PROPERTY   DEFAULT  REQUIREMENT  TYPE      DESCRIPTION

label      ''       required     String    descriptive name for field. Sits to the right of the checkbox field.
toggle              required     Function  Enacted on click, will receive the new toggle state as its input.
name                optional     String    returned as the second parameter to the Select method. 
isChecked           optional     Boolean   sets the initial toggle state.
flipLabel  false    optional     Boolean   changes label position from the right of the checkbox to the left.
required   false    optional     Boolean   Adds a red asterisk to the label
disabled   false    optional     Boolean   Prevents user interaction

*/

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.scss'


class Checkbox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isChecked: false
        }
    }

    componentDidMount() {
        const { isChecked } = this.props
        if (isChecked) this.setState({ isChecked })
    }

    componentDidUpdate(prevProps) {
        const { isChecked: prevChecked } = prevProps
        const { isChecked: currChecked } = this.props
        const { isChecked: checkedState } = this.state
        if (prevChecked !== currChecked && currChecked !== checkedState) this.setState({ isChecked: currChecked })
    }

    toggleCheck() {
        const { name, toggle } = this.props
        const { isChecked } = this.state
        this.setState({ isChecked: !isChecked }, toggle(!isChecked, name))
    }

    render() {

        const { isChecked } = this.state
        const { label, required, flipLabel, disabled } = this.props
        let className = "Checkbox"
        if (flipLabel) className += " Checkbox--labelLeft"
        if (disabled) className += " Checkbox--disabled"

        return (
            <div className={className}>

                <div className="Checkbox__box" onClick={() => this.toggleCheck()}>
                    {isChecked && <div className="Checkbox__checkMark"><FontAwesomeIcon icon="check"/></div>}
                </div>

                <div className="Checkbox__label" >
                    <span dangerouslySetInnerHTML={{ __html: label }}/>
                    {required && <span className="Checkbox__required">*</span>}
                </div>
                
            </div>
        )
    }
} 

export default Checkbox