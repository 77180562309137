import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader, Form, Checkbox, Message, Button } from 'semantic-ui-react'
import _ from 'lodash'
import {api} from '../../../utilities/api';
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import { fetchAgreements, verifyUsernameAvailability, verifyEmailAddressAvailability, registerUser } from '../../../redux/actions'
import './styles.scss'


const mapStateToProps = state => {
    return {
        language: state.language,
        config: state.config,
        regions: state.regions,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAgreements: (language) => dispatch(fetchAgreements(language)),
        verifyUsernameAvailability: (username) => dispatch(verifyUsernameAvailability(username)),
        verifyEmailAddressAvailability: (username) => dispatch(verifyEmailAddressAvailability(username)),
        registerUser: (user) => dispatch(registerUser(user)),
    }
}

class RegistrationForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      agreements: [],
      requiredAgreements: [],

      email: '',
      username: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmedPassword: '',
      codeInput: '',

      loading: false,
      error: false,
      success: false,
      errorMessage: false,
      errorEmail: false,
      errorMessageEmail: '',
      checkingEmail: false,
      errorUsername: false,
      errorMessageUsername: '',
      errorPassword: false,
      errorMessagePassword: '',
      checkingUsername: false,
      usernameValid: true,
      errorCode: false,
      errorMessageCode: '',
      checkingCode: false,
      codeValid: true,
      passwordsDoNotMatch: false,
      isInnovaRegion: false,
    }
    this.loadAgreements = this.loadAgreements.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
    this.checkUsername = this.checkUsername.bind(this)
    this.checkEmailAddress = this.checkEmailAddress.bind(this)
    this.checkPassword = this.checkPassword.bind(this)
    this.checkBuddyCode= this.checkBuddyCode.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentDidMount() {
      await this.loadAgreements()

    const { codeFieldInput } = this.props
    if (!_.isEmpty(codeFieldInput)) {
      this.setState({ codeInput: codeFieldInput })
      let agreements = [ ...this.state.agreements ]
      const mktgNewsletterIndex = agreements.findIndex(agreement => {
        const id = agreement.agreement_definition_id
        return id === "936b0100-725a-4163-b1c1-880905b81e12" || id === "2dba0bd1-d542-44da-91e1-caff3e6ea846"
      })
      if (mktgNewsletterIndex >= 0) agreements[mktgNewsletterIndex].checked = true
      this.setState({ agreements })
    }
    this.regionCheck()
  }

  componentDidUpdate(prevProps) {
    const { codeFieldInput: prevCode } = prevProps
    const { codeFieldInput: currCode } = this.props
    if (!_.isEqual(prevCode, currCode)) this.setState({ codeInput: currCode })
  }

  async loadAgreements() {
      const { language, fetchAgreements } = this.props
      const agreements = await fetchAgreements(language)
      const modeledAgreements = _.orderBy(agreements.map(agreement => {
          agreement.name = agreement.agreement_definition_name
          agreement.id = agreement.external_id
          agreement.checked = false
          agreement.display_label = _.result(agreement, 'display_strings.acceptance_string', agreement.agreement_definition_name)
          return agreement
      }), ['display_weight'], ['desc'])
      const requiredAgreements = agreements.filter(agreement => {
          return agreement.required_if_present
      }).map(agreement => {
          return agreement.external_id
      })
      this.setState({ agreements: modeledAgreements, requiredAgreements })
  }

  regionCheck() {
      const { regions } = this.props;
      if (!_.isEmpty(_.find(regions, { region_name: 'Innova' })))
        {
            this.setState({ isInnovaRegion: true })
        }
  }

  handleChange(e, { name, value }) {
      this.setState({ [name]: value })
  }

  handleUsernameChange(e, { name, value }) {
      this.setState({ username: value })
      if (!_.isEmpty(value) && 3 <= value.length) {
        this.checkUsername(value)
      }
  }

handleCodeChange(e, { name, value }) {
    this.setState({ codeInput: value })
    this.checkBuddyCode(value)
}

    checkEmailAddress = _.debounce(async (value) => {
        this.setState({
            checkingEmail: true
        })
        const emailAddressAvailability = await this.props.verifyEmailAddressAvailability(value)
        this.setState({ emailAddressValid: emailAddressAvailability === 'available' })
        if (!this.state.emailAddressValid) {
            this.setState({
                errorEmail: true,
                errorMessageEmail:  strings.getString('REGISTRATION_ERROR_EMAIL_TAKEN', 'That email address is already used.')
            })
        } else {
            this.setState({ errorEmail: false, errorMessageEmail: '' })
        }

        this.setState({ checkingEmail: false })
    }, 1000)
    
  checkBuddyCode = _.debounce(async (value) => {

      console.log('value', value);
      if (_.isEmpty(value)) {
          this.setState({
              errorCode: false,
              errorMessageCode: ''
          });
          return;
      }

      this.setState({
          checkingCode: true
      })

      try {
          const res = await api.get(`/user/code-check/${value}`)
          const codeStatus = _.result(res, 'data.status')

          this.setState({codeValid: codeStatus === 'valid'})
          if (!this.state.codeValid) {
              this.setState({
                  errorCode: true,
                  errorMessageCode: res.data.message || 'The code is not valid'
              })
          } else {
              this.setState({errorCode: false, errorMessageCode: ''})
          }
      } catch (err) {
          this.setState({
              errorCode: true,
              errorMessageCode: _.result(err, 'response.data.message', 'The code is not valid')
          })
      }

      this.setState({ checkingCode: false })
  }, 1000)

  checkUsername = _.debounce(async (value) => {

      //Username RULES
      //No dash, period, special characters except understore and hyphen.
      //No underscore or hyphen at the beginning or end of your name (no _xxx_ )
      //No two consecutive combination of hyphens or understores or periods ( xxx__xxx or xxx--xxx or xxx_-xxx )
      //No digit to begin. (1Dancer)
      //Max limit to a name is 28 characters
      //Names must have at least 4 characters
      //No more than 3 number digits

      const usernameCheck = new RegExp(`^([A-Za-z]((?=(?:\\D*\\d){0,3}\\D*$)((?!(\\.|\\-|_)(\\.|\\-|_))[\\w\\d\\-\\.])){2,26}[a-zA-Z0-9])$`, 'i')
      const usernameRules = ['LENGTH', 'CHARACTERS', 'DIGIT', 'START', 'CONSECUTIVE', 'THREE_DIGITS']
      const errorMessageUsernameRules = () => {
        return (
          <div>
            <p>{strings.getString('REGISTRATION_ERROR_USERNAME_FORMAT_ACCOUNT_NAME_RULES', 'The account name:')}</p>
            <ul>
              {usernameRules.map((n) => {
                return (
                  <li>{strings.getString(`REGISTRATION_ERROR_USERNAME_FORMAT_${n}`)}</li>
                 )
              })}
            </ul>
          </div>
        )
      }
      if (!usernameCheck.test(value)) {
          this.setState({
            errorUsername: true,
            errorMessageUsername: errorMessageUsernameRules(),
          })
          return
      }

      this.setState({
        checkingUsername: true
      })
      const usernameAvailability = await this.props.verifyUsernameAvailability(value)
      this.setState({ usernameValid: usernameAvailability === 'available' })
      if (!this.state.usernameValid) {
      this.setState({
          errorUsername: true,
          errorMessageUsername:  strings.getString('REGISTRATION_ERROR_USERNAME_TAKEN', 'The account name is already taken')
      })
      } else {
      this.setState({ errorUsername: false, errorMessageUsername: '' })
      }

      this.setState({ checkingUsername: false })
  }, 1000)

  checkPassword = _.debounce(async (value) => {
        //Overkill for checking length, but is here for when we want to add
        //more requirements to the while-typing password requirements checking.
        const passwordCheck = new RegExp(`^.{8,32}$`, 'i')
        if (!passwordCheck.test(value)) {
            this.setState({
                errorPassword: true,
                errorMessagePassword:  `${strings.getString('REGISTRATION_ERROR_PASSWORD_FORMAT', 'Password must be between 8 and 32 characters.')}`
            })
        } else {
            this.setState({errorPassword: false, errorMessagePassword: ''})
        }
    }, 1000)

  handleEmailChange(e, { name, value }) {
      this.setState({ [name]: value })
      if (value && ( value.includes('@', 1) === false || value.length <= (value.indexOf('@') + 1))) {
        this.setState({
          errorEmail: true,
          errorMessageEmail: `${strings.getString("WARNING_EMAIL_INVALID", "It looks like you haven't entered a valid email address")}`
        })
      } else {
        this.setState({
          errorEmail: false,
          errorMessageEmail: '',
        })
      }
      if (!_.isEmpty(value) && value.length >= 8) {
          this.checkEmailAddress(value)
      }
  }

  handlePasswordChange(e, {name, value}) {
      const { password: currPassword, confirmedPassword: currConfirmed } = this.state
      const password = name === "password" ? value : currPassword
      const confirmedPassword = name === "confirmedPassword" ? value : currConfirmed
      const passwordsDoNotMatch = !_.isEmpty(password) && !_.isEmpty(confirmedPassword) && password !== confirmedPassword
      this.setState({ password, confirmedPassword, passwordsDoNotMatch })
      if (name === "password" && !_.isEmpty(value) && value.length >= 4) {
          this.checkPassword(value)
      }
  }

  handleToggle(agreement) {
      let agreements = this.state.agreements
      let agreementToChange = _.find(agreements, { id: agreement.id })
      if (!_.isNil(agreementToChange)) {
        agreementToChange.checked = !agreementToChange.checked
      }
      this.setState({ agreements })
  }

  handleSubmit = async () => {
      const { email, username, password, firstName, lastName, codeInput } = this.state

      let acceptedAgreements = this.state.agreements.filter(a => a.checked).map(a => a.id)
      if (!_.isEmpty(_.difference(this.state.requiredAgreements, acceptedAgreements))) {
        this.setState({
          error: true,
          errorMessage: strings.getString('REGISTRATION_ERROR_REQUIRED_AGREEMENTS', 'You must accept all required agreements before signing up')
        })
        return
      } else {
        this.setState({
          error: false,
          errorMessage: '',
        })
      }

      this.setState({ loading: true })

      try {
        const { config, language, registrationSource } = this.props
        let source = null
        if (!_.isEmpty(registrationSource)) {
          source = registrationSource
        } else if (!_.isEmpty(codeInput)) {
          source = "organic-code"
        } else {
          source = config.default_registration_source
        }

        await this.props.registerUser({
          email: email,
          username: username,
          password: password,
          first_name: firstName,
          last_name: lastName,
          accepted_agreements: acceptedAgreements,
          registration_source: source,
          preferred_language: language,
          friend_code: codeInput ? codeInput.trim() : 'artcraft'
        })
        this.setState({
          error: false,
          loading: false
        })

        if (this.props.onSuccess) this.props.onSuccess()
        await swal({
          title: strings.getString('REGISTRATION_SUCCESS', 'Registration Successful'),
          text: strings.getString('CHECK_EMAIL_TO_GET_CODE', 'Please check your email to retrieve your validation code and complete your account verification.'),
          icon: 'success',
        })

      } catch(err) {
        console.log("ERROR", err)
        let message = _.result(err, 'response.data.message')
        if (message === 'This is an invalid link') {
          message = 'The Friend Referral Code used is not valid.  Please remove the code and try again.'
        } else if(message === 'This is an invalid code') {
          message = strings.getString("THIS_IS_AN_INVALID_CODE", "This is an invalid code")
        }

        swal({
          title: strings.getString('REGISTRATION_UNSUCCESSFUL', 'Registration was not Successful!'),
          text: message,
          icon: 'error',
        })

        this.setState({
          success: false,
          error: true,
          loading: false,
          errorMessage: message
        })
      }
    }


  render() {

    const { codeFieldLabel, orderCodeFieldFirst } = this.props
    const { agreements, requiredAgreements, email, username, firstName, lastName, password, confirmedPassword,
            codeInput, errorEmail, errorMessageEmail, checkingEmail, errorUsername, errorMessageUsername, errorPassword, errorMessagePassword, usernameValid, checkingUsername, codeValid, checkingCode, errorCode, errorMessageCode,
            passwordsDoNotMatch, loading, success, error, errorMessage, isInnovaRegion} = this.state
    const agreementDiff = _.difference(requiredAgreements, agreements.filter(agreement=>agreement.checked).map(agreement=>agreement.external_id))
    const needsRequired = loading || !_.isEmpty(agreementDiff) || _.isEmpty(email) || _.isEmpty(username) ||
                          _.isEmpty(firstName) || _.isEmpty(lastName) || _.isEmpty(password) || _.isEmpty(confirmedPassword)
                          || errorCode || errorUsername || errorPassword || errorEmail || passwordsDoNotMatch ? true : false

    return (
      <div className="RegistrationForm">

        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>

        <Form onSubmit={this.handleSubmit} success={success} error={error}>

          {isInnovaRegion && <Message color="red" header={strings.getString("UH_OH", "Uh Oh!!")} content={strings.getString("INNOVA_REGION_WARN", "If you are a player from Russia or CIS, you can access the game via https://ru.4game.com/crowfall/ and register an account to learn more!")}/>}

          <Form.Input required autoComplete="email" loading={checkingEmail} type="email" label={strings.getString('EMAIL', 'Email')}
                      placeholder={strings.getString('EMAIL', 'Email')}
                      name='email' value={email}
                      onChange={this.handleEmailChange}/>

          {errorEmail && <Message color="red" header={strings.getString("UH_OH", "Uh Oh!")} content={errorMessageEmail}/>}

          <Form.Input required error={!usernameValid} loading={checkingUsername} minLength="3" autoComplete="off"
                      label={strings.getString("DISPLAY_NAME_VISIBLE_IN_GAME", "Please note this name will be visible to other players and how you'll be referred to in-game and our forums")}
                      placeholder={strings.getString("DISPLAY_NAME", "Display Name")}
                      value={username} onChange={this.handleUsernameChange}/>

          {errorUsername ? (<Message color='red' header='Uh oh!' content={errorMessageUsername}/>) : null}


          <Form.Input required autoComplete="off" type="text" label={strings.getString("FIRST_NAME", "First Name")}
                      placeholder={strings.getString("FIRST_NAME", "First Name")}
                      name="firstName" value={firstName}
                      onChange={this.handleChange}/>

          <Form.Input required autoComplete="off" type="text" label={strings.getString("LAST_NAME", "Last Name")}
                      placeholder={strings.getString("LAST_NAME", "Last Name")}
                      name="lastName" value={lastName}
                      onChange={this.handleChange}/>

          <Form.Input required minLength="8" autoComplete="off" label={strings.getString("PASSWORD", "Password")}
                      placeholder={strings.getString("PASSWORD", "Password")}
                      name="password" type="password" value={password}
                      onChange={this.handlePasswordChange}/>

          {errorPassword && <Message color="red" header={strings.getString("UH_OH", "Uh Oh!")} content={errorMessagePassword}/>}

          <Form.Input required minLength="8" autoComplete="off" label={strings.getString("CONFIRM_PASSWORD", "Confirm Password")}
                      placeholder={strings.getString("CONFIRM_PASSWORD", "Confirm Password")}
                      name="confirmedPassword" type="password" value={confirmedPassword}
                      onChange={this.handlePasswordChange}/>

          {passwordsDoNotMatch && <Message color="red" header={strings.getString("UH_OH", "Uh Oh!")} content={strings.getString("PASSWORDS_DO_NOT_MATCH", "The password fields do not match.")}/>}

            <Form.Input error={!codeValid} loading={checkingCode} minLength="3" autoComplete="off" label={codeFieldLabel}
                        placeholder={codeFieldLabel}
                        name="codeInput" value={codeInput}
                        onChange={this.handleCodeChange}/>

            {errorCode ? (<Message color='red' header='Uh oh!' content={errorMessageCode}/>) : null}

          <div className="RegistrationForm__section">
            {agreements.filter(a => a.required_if_present).map(a => (
              <Form.Field key={a.id} required={a.required_if_present}>
                <Checkbox label={<label dangerouslySetInnerHTML={{ __html: a.display_label }}></label>} onChange={() => this.handleToggle(a)} checked={a.checked}/>
              </Form.Field>
            ))}

            {agreements.filter(a => !a.required_if_present && a.category === null).map(a => (
              <Form.Field key={a.id} required={a.required_if_present}>
                <Checkbox label={<label dangerouslySetInnerHTML={{ __html: a.display_label }}></label>} onChange={() => this.handleToggle(a)} checked={a.checked}/>
              </Form.Field>
            ))}
          </div>

          {/* <div className="RegistrationForm__section">
            <div className="RegistrationForm__h3">{strings.getString("RECOMMENDED_AGREEMENTS", 'Recommended Agreements')}</div>
            {agreements.filter(a => !a.required_if_present && a.category === "preferred").map(a => (
            <Form.Field key={a.id} required={a.required_if_present}>
                <Checkbox label={<label dangerouslySetInnerHTML={{ __html: a.display_label }}></label>} onChange={() => this.handleToggle(a)} checked={a.checked}/>
            </Form.Field>
            ))}
          </div> */}


          <div className="RegistrationForm__buttons">
            <Button className="Button__ACE" primary type='submit' loading={loading} disabled={needsRequired}>
              {strings.getString('NEXT', 'Next')}
            </Button>
          </div>

          <Message success header={strings.getString('REGISTRATION_SIGN_UP_SUCCESS', 'Sign Up Completed')}
                  content={strings.getString('REGISTRATION_SIGN_UP_SUCCESS_INFO', 'You\'re all set! Check your email for next steps!')}/>
          <Message error header={strings.getString('UH_OH', 'Uh oh!')} content={errorMessage}/>

        </Form>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm)
