import React from 'react'
import { api } from '../utilities/api'
import { Button, Dimmer, Form, Header, Loader, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import LocLink from '../componentLibrary/Elements/LocLink'
import swal from '@sweetalert/with-react'
import { Redirect } from 'react-router-dom'
import { clearModalContent, setModalContent } from '../redux/actions'


const mapDispatchToProps = (dispatch) => {
  return {
    clearModalContent: () => dispatch(clearModalContent()),
    setModalContent: (content) => dispatch(setModalContent(content)),
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  }
}

class AccountDetailsLayawayDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      layawayId: null,
      layaway: {},
      amount: 0,
      checkout: false,
    }
    this.getContent = this.getContent.bind(this)
    this.handlePaymentChange = this.handlePaymentChange.bind(this)
    this.makePayment = this.makePayment.bind(this)
    this.goToCheckout = this.goToCheckout.bind(this)
    this.cancelLayaway = this.cancelLayaway.bind(this)  
  }

  
  async componentDidMount() {
    const layawayId = _.result(this.props, 'match.params.id')
    this.setState({
      layawayId: layawayId,
    })
    await this.getContent()

  }

  async getContent() {
    this.setState({ loading: true })
    const layaway = await api.get(`/store/deferred-purchase/${_.result(this.props, 'match.params.id')}`)

    this.setState({
      layaway: layaway.data,
      amount: Math.min(layaway.data.amount_remaining, 5)
    })

    this.setState({ loading: false })
  }

  handlePaymentChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    })
  }

  makePayment = async () => {
    const { setModalContent, clearModalContent } = this.props
    setModalContent(
      <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "40px 20px 20px", maxWidth: "600px", minWidth: "280px", fontSize: "16px" }}>
        <div style={{ textAlign: "center", fontWeight: "bold", paddingBottom: "25px", fontSize: "26px" }}>Make Payment</div>
        
        <p>Select the amount you want to apply to your layaway.</p>

        <p>The payoff amount is: {_.result(this.state.layaway, 'display_strings.amount_remaining')}.</p>

        <Form>
          <Form.Input min={Math.min(this.state.layaway.amount_remaining, 5)}
                      max={this.state.layaway.amount_remaining}
                      autoComplete="off"
                      onChange={this.handlePaymentChange}
                      type="number" placeholder="Amount to Apply" name='amount'/>
        </Form>

        <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "20px" }}>
          <div><Button onClick={clearModalContent()} className="Button__ACE Button_ACE-secondary" type='button' primary>Cancel</Button></div>
          <div onClick={this.goToCheckout} style={{ paddingLeft: "10px" }}><Button className="Button__ACE" type='button' primary>Make Payment</Button></div>
        </div>
      </div>
    )
  }

  goToCheckout() {
    this.props.clearModalContent() 
    this.setState({
      checkout: true,
    })
  }

  cancelLayaway = async () => {
    const val = await swal({
      title: 'Cancel Layaway',
      buttons: {
        cancel: 'Keep Layaway',
        consume: {
          text: 'Cancel Layaway',
          value: 'cancel',
          closeModal: false,
        },
      },
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            Canceling your layaway will return all applied funds to your Store Credit wallet. Any Upgrades used will be
            returned to your Account Rewards. Be careful when canceling since items you have on layaway may no longer be
            available in the store.
          </p>
        </div>
      ),
    })

    if (val === 'cancel') {

      try {
        await api.delete(`/store/deferred-purchase/${this.state.layaway.deferred_purchase_id}`)
        await swal(`Layaway Canceled`, {
          icon: 'success',
        })
      } catch (err) {
        swal(`Unable to cancel Layaway`, {
          icon: 'error',
        })
      }
      await this.getContent()
    }
  }

  render() {

    const { loading, layaway, checkout, amount } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>


        {checkout ? (
          <Redirect
            to={`/${this.props.language}/checkout/${layaway.deferred_purchase_id}?layaway=true&amount=${amount}`}/>
        ) : null}
        <h1>Layaway Details</h1>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2}><span style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}></span>Layaway Purchase Details for {_.result(layaway, 'item.name')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Layaway Date</Table.Cell>
              <Table.Cell>{(new Date(layaway.date_created)).toLocaleString()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Layaway ID</Table.Cell>
              <Table.Cell>{_.result(layaway, 'deferred_purchase_id')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>{_.startCase(_.result(layaway, 'status'))}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Store Item Name</Table.Cell>
              <Table.Cell>{_.result(layaway, 'item.name')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Amount Applied</Table.Cell>
              <Table.Cell>{_.result(layaway, 'display_strings.amount_applied')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Amount Remaining</Table.Cell>
              <Table.Cell>{_.result(layaway, 'display_strings.amount_remaining')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Currency</Table.Cell>
              <Table.Cell>{_.upperCase(_.result(layaway, 'currency'))}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Discount Used</Table.Cell>
              <Table.Cell>{_.result(layaway, 'discount.name', 'None')}</Table.Cell>
            </Table.Row>
            {_.result(layaway, 'discount.percent') ? (
              <Table.Row>
                <Table.Cell>Discount Percent Applied</Table.Cell>
                <Table.Cell>{_.result(layaway, 'discount.percentage')}%</Table.Cell>
              </Table.Row>
            ) : null}
            {_.result(layaway, 'upgrade_entitlement_ids.0') ? (
              <Table.Row>
                <Table.Cell>Upgrade Applied</Table.Cell>
                <Table.Cell>{_.result(layaway, `upgrades.${_.result(layaway, 'upgrade_entitlement_ids.0')}.name`)}%</Table.Cell>
              </Table.Row>
            ) : null}
          </Table.Body>
        </FixedTable>


        <Header as='h3'>Payments Made</Header>

        <FixedTable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Order ID</Table.HeaderCell>
              <Table.HeaderCell>Payment Date</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Method</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(layaway.payments || []).map((l, i) => (
              <Table.Row key={i}>
                <Table.Cell><LocLink
                  to={`/account/order-history/${l.transaction_id}`}>{_.result(l, 'transaction_id')}</LocLink></Table.Cell>
                <Table.Cell>{(new Date(l.date_created)).toLocaleString()}</Table.Cell>
                <Table.Cell>{_.result(l, 'display_strings.amount')}</Table.Cell>
                <Table.Cell>{_.startCase(_.result(l, 'payment_method'))}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </FixedTable>

        {layaway.status === 'in_progress' ? (
          <Form>
            <Button onClick={this.makePayment} className="Button__ACE" type='button' primary>Make Payment</Button>
            <Button onClick={this.cancelLayaway} className="Button__ACE Button_ACE-secondary" type='button' primary>Cancel
              layaway</Button>
          </Form>
        ) : null}

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsLayawayDetail)
