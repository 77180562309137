import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import queryString from 'query-string'
import { api } from '../../../utilities/api'
import strings from '../../../strings'

import { setModalContent } from '../../../redux/actions'
import { fetchSingleGuild } from '../../../redux/actions/guilds'
import { fetchUserGuildApplications, cancelRequestToJoinGuild } from '../../../redux/actions/user'
import { joinGuild, setHasGuild, clearGuildDetails, fetchGuildDetails } from '../../../redux/actions/guild-management'

import DataTable from '../../../componentLibrary/Models/DataTable'
import GuildCrest from '../../../componentLibrary/Elements/GuildCrest'
import Pagination from '../../../componentLibrary/Elements/Pagination'
import Button from '../../../componentLibrary/Fragments/Button'
import RequestToJoinGuildModal from './RequestToJoinGuildModal'
import {GuildCannotCreateJoinMsg} from "../../GuildCannotCreateJoinMsg";
import './styles.scss'


const mapStateToProps = state => {
    return {
        languageOptions: state.languages,
        hasGuild: state.guildManagement.hasGuild,
        session: state.session,
        userGuildApplications: state.user.guildApplications,
        userGuild: state.guildManagement.guild,
        userGuildId: state.guildManagement.guildId,
        userRank: state.guildManagement.guildRank,
        configuration: state.guilds.configuration,
        latestGuildMembership: state.guildManagement.latestGuildMembership
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setModalContent: (content) => dispatch(setModalContent(content)),
        fetchSingleGuild: (id) => dispatch(fetchSingleGuild(id)),
        fetchGuildApplications: () => dispatch(fetchUserGuildApplications()),
        cancelRequestToJoinGuild: (requestId) => dispatch(cancelRequestToJoinGuild(requestId)),
        joinGuild: (id, message) => dispatch(joinGuild(id, message)),
        setHasGuild: (status) => dispatch(setHasGuild(status)),
        clearGuild: () => dispatch(clearGuildDetails()),
        fetchGuildDetails: () => dispatch(fetchGuildDetails()),
    }
}

class PublicGuildOverview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: null,
            displayName: null,
            name: null,
            motto: null,
            leader: null,
            description: null,
            recruitingStatus: false,
            languages: [],
            allegiance: null,
            dateCreated: null,
            websiteUrl: null,
            members: [],
            memberRows: [],
            paginatedMembers: [],
            crest: null,
            joinButtonString: null,
            applicationMessage: null,

            page: 1,
        }
        this.paginateMembers = this.paginateMembers.bind(this)
        this.handleJoinButton = this.handleJoinButton.bind(this)
        this.cancelRequestToJoin = this.cancelRequestToJoin.bind(this)
        this.disbandGuild = this.disbandGuild.bind(this)
        this.leaveGuild = this.leaveGuild.bind(this)
    }

    async componentDidMount() {
        const { fetchSingleGuild, id } = this.props
        const guild = await fetchSingleGuild(id)

        if (_.isEmpty(guild) === true) return
        this.setInitialState(guild)
    }

    setInitialState(guild) {
        const { languageOptions, userGuildId } = this.props
        const { 
            guild_id, custom_fields, display_name, name, mission_statement, description, recruiting_status, 
            preferred_languages, allegiance, date_created, members, websiteUrl, 
        } = guild
        const crest = custom_fields ? custom_fields[0].value : null
        const dateCreated = moment(date_created).format("MMMM Do, YYYY")
        const languages = preferred_languages && preferred_languages.length > 0 ? preferred_languages.map(language => {
            const match = _.find(languageOptions, {'culture_code': language})
            if (!match) return null
            return match.display_name
        }) : []
        const memberRows = this.modelMemberRows(members)
        const leader = _.find(members, ['rank', 1]) || {}
        const paginatedMembers = memberRows.slice(0, 4)
        const allegianceName = allegiance ? allegiance.display_name : null
        let joinButtonString = null
        if (userGuildId === guild_id) {
            strings.getString("LEAVE_GUILD", "Leave Guild")
        } else {
            
        }


        this.setState({
            id: guild_id,
            displayName: display_name,
            name,
            motto: mission_statement,
            description,
            recruitingStatus: recruiting_status,
            languages,
            allegiance: allegianceName,
            dateCreated,
            websiteUrl,
            members,
            memberRows,
            paginatedMembers,
            crest,
            leader: leader.username,
            joinButtonString,
        })
    }

    async handleJoinButton() {
        const { hasGuild, session, userGuildId, userGuildApplications } = this.props
        const { id, displayName, recruitingStatus } = this.state
        const activeRequests = userGuildApplications.filter(application => application.status === "active")
        const matchingActiveRequest = _.find(activeRequests, ["guild_id", id])
        if (!session) {
            this.handleNotLoggedInUser()
        
        } else if (session) {
            
            if (recruitingStatus === "open") {
                
                if (hasGuild === false) {

                    if (_.isEmpty(activeRequests)) {
                        const val = await swal({
                            content: (
                                <div>
                                    <div className="PublicGuildOverview__swalTitle" dangerouslySetInnerHTML={{ __html: strings.getString("JOIN_GUILD_IN_PROGRESS", `You are about to join the Guild ${displayName}`, { guildName: displayName }) }} />
                                </div>
                            ),
                            buttons: {
                                cancel: strings.getString("CANCEL", "Cancel"),
                                confirm: {
                                    text: strings.getString("JOIN_GUILD", "Join Guild"),
                                    value: "confirm"
                                }
                            }
                        })
                        if (val === "confirm") this.joinOpenGuild(id, displayName)

                    } else if (!_.isEmpty(activeRequests)) {
                        this.handleUserHasAnActiveRequest(
                            strings.getString("GUILD_ERROR_OPEN_APPLICATION_PREVENTS_JOINING", "To join an Open Guild, you must first cancel your open application to join another.")
                        )
                    }
                
                } else if (hasGuild === true) {

                    if (userGuildId === id) {
                        this.handleUserAlreadyAMember(displayName)
                    } else {
                        this.handleUserMemberOfAnotherGuild()
                    }
                }
            
            } else if (recruitingStatus === "accepting_applications") {

                if (hasGuild === false) {

                    if (_.isEmpty(activeRequests)) {
                        this.generateApplicationModal(displayName, id)
                    
                    } else if (!_.isEmpty(activeRequests)) {

                        if (!_.isEmpty(matchingActiveRequest)) {
                            this.cancelRequestToJoin(matchingActiveRequest)
                        
                        } else {
                            this.handleUserHasAnActiveRequest(
                                strings.getString("GUILD_ERROR_ONLY_ONE_ACTIVE_APPLICATION", "You can only have one active request open at a time. You may cancel your open request in order to submit another.")
                            )
                        
                        }
                    }

                } else if (hasGuild === true) {

                    if (userGuildId === id) {
                        this.handleUserAlreadyAMember(displayName)
                    } else {
                        this.handleUserMemberOfAnotherGuild()
                    }
                }
            }
        }
    }

    async handleNotLoggedInUser() {
        const { pathname, search, hash } = this.props.history.location

        const val = await swal({
            title: strings.getString("LOGIN_BEFORE_JOINING_A_GUILD", "Please sign in before attempting to join a Guild."),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                login: {
                    text: strings.getString("SIGN_IN", "Sign In"),
                    value: 'login'
                }
            }
        })
        if (val === 'login') {
            this.props.history.push(`/login?r=${btoa(`${pathname}${search}${hash}`)}`)
        } 
    }

    async joinOpenGuild(id, name) {
        const { joinGuild, fetchGuildDetails, history } = this.props
        try {
            await joinGuild(id)
            await fetchGuildDetails()
            const val = await swal({
                icon: 'success',
                content: (
                    <div>
                        <div className="PublicGuildOverview__swalTitle" dangerouslySetInnerHTML={{ __html: strings.getString("GUILD_JOIN_SUCCESS", `You have successfully joined the Guild, ${name}`, { guildName: name}) }} />
                    </div>
                ),
                buttons: {
                    cancel: strings.getString("CLOSE", "Close"),
                    redirect: {
                        text: strings.getString("VIEW_MY_GUILD", "View My Guild"),
                        value: 'redirect'
                    }
                }
            })
            if (val === 'redirect') history.push('/guild')
        } catch (err) {
            swal(err.response.data.message, {
                icon: 'error'
            })
        }
    }

    async generateApplicationModal(guildName, id) {
        this.props.setModalContent(
            <RequestToJoinGuildModal guildName={guildName} guildId={id}/>
        )
    }

    async handleUserAlreadyAMember(guildName) {
        const val = await swal({
            icon: 'warning',
            content: (
                <div>
                    <div className="PublicGuildOverview__swalTitle" dangerouslySetInnerHTML={{ __html: strings.getString("GUILD_ALREADY_A_MEMBER", "You are already a member of " + guildName, { guildName }) }} />
                </div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                ok: {
                    text: strings.getString("VIEW_MY_GUILD", "View My Guild"),
                    value: 'viewGuild'
                }
            }
        })
        if (val === 'viewGuild') {
            this.props.history.push('/guild')
        }
    }

    async handleUserMemberOfAnotherGuild() {
        const { userGuild, userGuildId, userRank } = this.props

        const val = await swal({
            icon: 'warning',
            content: (
                <div>
                    <div className="PublicGuildOverview__swalTitle" dangerouslySetInnerHTML={{ __html: strings.getString("GUILD_ALREADY_A_MEMBER", "You are already a member of " + userGuild.displayName, { guildName: userGuild.displayName }) }}/>
                    {strings.getString("GUILD_LEAVE_CURRENT_TO_JOIN_NEW", "Before you can request to join a new Guild, you must first leave your current one.")}
                </div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                ok: {
                    text: strings.getString("GUILD_LEAVE", "Leave Guild"),
                    value: 'leave'
                }
            }
        })
        if (val === 'leave') {
            if (userRank === 1) {
                await this.disbandGuild(userGuild)
                this.handleJoinButton()
            } else {
                await this.leaveGuild(userGuildId)
                this.handleJoinButton()
            }
        }
    }

    async handleUserHasAnActiveRequest(modalContent) {
        const val = await swal({
            icon: 'error',
            title: strings.getString("GUILD_OPEN_APPLICATION_WITH_ANOTHER_GUILD", "You already have an open application to join a different Guild."),
            content: (
                <div>{modalContent}</div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                redirect: {
                    text: strings.getString("VIEW_MY_APPLICATIONS", "View My Applications"),
                    value: 'redirect',
                }
            }
        })

        if (val === 'redirect') this.props.history.push('/account/guild-applications')
    }

    async cancelRequestToJoin(request) {
        const { cancelRequestToJoinGuild, fetchGuildApplications, fetchGuildDetails } = this.props
        const val = await swal({
            title: strings.getString('WITHDRAW_APPLICATION', 'Withdraw Application'),
            buttons: {
                cancel: strings.getString('CANCEL', 'Cancel'),
                withdraw: {
                    text: strings.getString('WITHDRAW', 'Withdraw'),
                    value: 'withdraw',
                },
            },
            content: (
                <div>{strings.getString('ACCOUNT_GUILD_WITHDRAW_APP', 'Withdraw your application to')} <strong>{request.display_name}</strong>.</div>
            ),
        })

        if (val === 'withdraw') {
            try {
              await cancelRequestToJoinGuild(request.guild_invite_request_id)
              await fetchGuildApplications()
              swal(`${strings.getString('ACCOUNT_GUILD_WITHDRAW_APP_SUCCESS', 'Successfully withdrawn application to join')} ${request.display_name}.`, {
                icon: 'success',
              })
            } catch (err) {
                const responseMessage = _.result(err.response.data, 'Message')
                  await swal(`${responseMessage}`, {
                      icon: 'error'
                  })
                await fetchGuildApplications()
                await fetchGuildDetails()
            }
        }
    }

    async disbandGuild(guild) {
        const val = await swal({
            title: strings.getString("GUILD_ABOUT_TO_DISBAND", "You're About to disband your Guild " + guild.display_name, { guildName: guild.display_name }),
            content: (
                <div>{strings.getString("GUILD_DISBAND_WARNING", "Before disbanding, you may want to transfer leadership of your guild to another member. If not, the guild will effectively be dead and removed.")}</div>
            ),
            buttons: {
                cancel: strings.getString("CANCEL", "Cancel"),
                continue: {
                    text: strings.getString("GUILD_DISBAND", "Disband Guild"),
                    value: 'disband'
                }
            }
        })

        if (val === 'disband') {
            try {
                await api.post(`/user/guild/${this.props.userGuildId}/disband`)
                await swal({
                    title: strings.getString("GUILD_DISBAND_SUCCESS", "You've successfully disbanded the Guild"),
                    icon: 'success'
                })
                this.props.clearGuild()
                this.props.setHasGuild(false)
            } catch (err) {
                swal(`${err.response.data.message}`, {
                    icon: 'error',
                })
            }
        }
    }

    async leaveGuild(guildId) {
        try {
            await api.post(`/user/guild/${guildId}/leave`)
            await swal({
                title: strings.getString("GUILD_LEFT_GUILD_SUCCESS", "You've successfully left the Guild."),
                icon: 'success',
            })
            this.props.clearGuild()
            this.props.setHasGuild(false)
        } catch (err) {
            swal(`${err.response.data.message}`, {
                icon: 'error',
            })
        }
    }

    modelMemberRows(members) {
        let memberRows = _.sortBy(members, ['rank'])
        return memberRows.map(member => ({
            id: member.guild_membership_id,
            elements: [
                <div className="PublicGuildOverview__tableLabel">{member.username}</div>,
                <div className="PublicGuildOverview__tableLabel">{member.display_name}</div>,
            ]
        }))
    }

    paginateMembers(page) {
        if (!page) return
        const { memberRows } = this.state
        const start = 5 * (page - 1)
        const end = start + 5
        this.setState({
            page,
            paginatedMembers: memberRows.slice(start, end)
        })
    }

    componentWillUnmount() {
        const q = _.result(this.props, 'location.search')
        const query = queryString.parse(q) || {}
        if (query.guild) {
            this.props.history.push(`${this.props.location.pathname}`)
        }
    }

    render() {
        // const result = GuildHelper()
        // console.log('result ' + result)
        const { 
            id, displayName, name, motto, description, recruitingStatus, languages, allegiance, 
            dateCreated, members, memberRows, paginatedMembers, crest, page, websiteUrl, leader
        } = this.state
        const { configuration, userGuildApplications, userGuildId, latestGuildMembership } = this.props
        const isRecruiting = recruitingStatus === "accepting_applications" || recruitingStatus === "open" ? true : false
        const pendingRequest = !_.isEmpty(_.find(userGuildApplications, {'guild_id': id, 'status': 'active'}))
        let recruitmentBtnString = ""
        if (recruitingStatus === "accepting_applications") {
            recruitmentBtnString = pendingRequest ? strings.getString("CANCEL_REQUEST", "Cancel Request") : strings.getString("REQUEST_TO_JOIN", "Request to Join")
        }
        if (recruitingStatus === "open") recruitmentBtnString = strings.getString("JOIN_GUILD", "Join Guild")

        let displayJoinCreateMsg = (latestGuildMembership && latestGuildMembership.denyGuildJoin)
        
        return (
            <div className="PublicGuildOverview">
                <div className="PublicGuildOverview__header">
                    {crest && <GuildCrest crest={crest} isRecruiting={isRecruiting} large/>}
                    <div className="PublicGuildOverview__guildTitles">
                        <div className="PublicGuildOverview__displayName">{displayName}</div>
                        <div className="PublicGuildOverview__name">{'// '}{name}</div>
                        {!_.isEmpty(motto) && <div className="PublicGuildOverview__motto">"{motto}"</div>}
                    </div>
                </div>

                {!_.isEmpty(description) && <div className="PublicGuildOverview__descriptionBox">
                    <div className="PublicGuildOverview__description">{description}</div>
                </div>}

                {displayJoinCreateMsg && 
                    <div className="PublicGuildOverview__cannot_create_display">
                        <GuildCannotCreateJoinMsg latestGuildMembership = {latestGuildMembership} joinGuild />
                    </div>
                }
                
                {isRecruiting && 
                    <div className="PublicGuildOverview__joinButton">
                        <Button disabled={ userGuildId && userGuildId === id || displayJoinCreateMsg} label={recruitmentBtnString} onclick={this.handleJoinButton} />
                    </div>
                }

                <div className="PublicGuildOverview__content">
                    <div className="PublicGuildOverview__leftContent">
                        <div className="PublicGuildOverview__guildInfo">
                            <div className="PublicGuildOverview__field">
                                <div className="PublicGuildOverview__label">{strings.getString("RECRUITMENT_STATUS", "Recruitment Status")}:</div>
                                <div className="PublicGuildOverview__value">{recruitingStatus === "accepting_applications" ? "Accepting Applications" : _.capitalize(recruitingStatus)}</div>
                            </div>
                            <div className="PublicGuildOverview__field">
                                <div className="PublicGuildOverview__label">{strings.getString("LEADER", "Leader")}:</div>
                                <div className="PublicGuildOverview__value">{leader}</div>
                            </div>
                            <div className="PublicGuildOverview__field">
                                <div className="PublicGuildOverview__label">{strings.getString("ESTABLISHED", "Established")}:</div>
                                <div className="PublicGuildOverview__value">{dateCreated}</div>
                            </div>
                            <div className="PublicGuildOverview__field">
                                <div className="PublicGuildOverview__label">{strings.getString("WE_SPEAK", "We Speak")}:</div>
                                <div className="PublicGuildOverview__value">
                                    {languages && languages.length > 0 && languages.map((language, index) => {
                                        if ( language === null ) return (<Fragment key={`language-${index}`} />)
                                        return (
                                        <div key={`${index}-${language}`} className="PublicGuildOverview__subValue">
                                            {language}
                                            {index !== languages.length - 1 && <div className="PublicGuildOverview__listSpacer">|</div>}
                                        </div>
                                    )})}
                                </div>
                            </div>
                            <div className="PublicGuildOverview__field">
                                <div className="PublicGuildOverview__label">{strings.getString("PANTHEON", "Pantheon")}:</div>
                                <div className="PublicGuildOverview__value">{allegiance}</div>
                            </div>
                            {!_.isEmpty(websiteUrl) && <div className="PublicGuildOverview__field">
                                <div className="PublicGuildOverview__label">{strings.getString("WEBSITE", "Website")}:</div>
                                <div className="PublicGuildOverview__value">{websiteUrl}</div>
                            </div>}
                        </div>
                    </div>
                    <div className="PublicGuildOverview__rightContent">
                        <div className="PublicGuildOverview__field">
                            <div className="PublicGuildOverview__label">{strings.getString("GUILD_MEMBERS", "Guild Members")}:</div>
                            <div className="PublicGuildOverview__value">{members.length} / {configuration.max_guild_members}</div>
                        </div>
                        <div className="PublicGuildOverview__membersTable">
                            <DataTable 
                                labels={[{label: strings.getString("NAME", "Name")}, {label: strings.getString("RANK", "Rank")}]}
                                rows={paginatedMembers} gold
                            />
                        </div>
                        {members.length > 5 && 
                            <div className="PublicGuildOverview__pagination">
                                <Pagination page={page} limit={5} list={memberRows} paginate={this.paginateMembers} limitOptions/>
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicGuildOverview))