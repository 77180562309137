import React from 'react'
import LocLink from '../../Elements/LocLink'
import Button from '../../Fragments/Button'
import './styles.scss'


const MarketingCTA = (props) => {

    const { children, to, buttonLabel } = props

    return (
        <div className='MarketingCTA'>
            <div className='MarketingCTA__children'>{children}</div>
            <div className='MarketingCTA__button'>
                <LocLink to={to}>
                    <Button label={buttonLabel}/>
                </LocLink>
            </div>
        </div>
    )
}

export default MarketingCTA