import React from 'react'
import { api, setAuth } from '../../../utilities/api'
import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Loader,
  Message,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { setSession } from '../../../redux/actions'
import strings from '../../../strings'
import Page from '../../../componentLibrary/Models/Page'
import { Redirect } from 'react-router-dom'
import HelperLinks from '../HelperLinks'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import './styles.scss'

const mapStateToProps = state => {
  return {
    language: state.language,
    session: state.session,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: session => dispatch(setSession(session)),
  }
}

class Login extends React.Component {

  state = {
    loading: false,
    code: '',
    email: '',
    password: '',
    otp: '',
    stepUpCode: '',
    errorMessage: '',
    error: false,
    success: false,
    captcha: false,
    captchaValue: null,
    clientId: null,
    returnPath: null,
    hide2fa: true,
    showStepUp: false
  }

  performCaptcha = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(this.state.clientId, {
        action: 'login',
      }).then((token) => {
        this.setState({
          captchaValue: token,
        })
      })
    })
  }

  componentDidMount = () => {
    const clientId = window.grecaptcha ? window.grecaptcha.ready(() => {
      window.grecaptcha.render('Captcha_container', {
        'sitekey': '6Lfgq4oUAAAAAM21EiBBZeM7OHgrhBShUyiavb7H',
        'size': 'invisible',
      })
    }) : null

    const q = _.result(this.props, 'location.search')
    const query = queryString.parse(q) || {}

    if (query.r) {
      try {
        this.setState({
          returnPath: atob(query.r)
        })
      } catch {
        console.error('Invalid return path')
      }
    }
    if (query.code) this.setState({ code: query.code })

    this.setState({
      clientId: clientId
    })

    if (clientId) this.performCaptcha()
  }

  handleChange = (e, { name, value } = {}) => {
    this.setState({ [name]: value })
  }

  handleSubmit = async () => {
    this.setState({ loading: true })
    let res = {
      status: 0,
    }
    try {
      res = await api.post(`/user/authenticate`, {
        username: this.state.email,
        password: this.state.password,
        totp: (this.state.otp && this.state.otp.length > 0) ? this.state.otp : null,
        captcha: this.state.captchaValue,
        // Disabling step_up to reduce Customer Service work CRW-256
        //step_up: true,
        //step_up_code: (this.state.stepUpCode && this.state.stepUpCode.length > 0) ? this.state.stepUpCode : null
      })

      this.setState({ session: res.data })
      localStorage.setItem('userData', JSON.stringify(this.state.session))
      setAuth(_.result(this.state.session, 'token'))
      this.setState({ loading: false })


      Cookies.set('crowfallInfo', {
        token: _.result(this.state.session, 'token'),
        firstName: _.result(this.state.session, 'user.first_name'),
        lastName: _.result(this.state.session, 'user.last_name'),
        email: _.result(this.state.session, 'user.email'),
        username: _.result(this.state.session, 'user.account_name'),
        user_id: _.result(this.state.session, 'user.user_id'),
        date_expires: _.result(this.state.session, 'date_expires'),
      }, { domain: '.crowfall.com', expires: 30, sameSite: 'lax' })

      return await this.props.login(this.state.session)
    } catch (err) {
      if (this.state.clientId) this.performCaptcha()
      if (err.response.status === 400) {
        const errCode = _.result(err, 'response.data.code')
        if (errCode === 'captcha_required') {
          this.setState({
            error: true,
            loading: false,
            captcha: true,
            errorMessage: strings.getString('LOGIN_ERROR_CAPTCHA', 'Captcha is required to authenticate'),
          })
        }
      } else if (err.response.status === 401 && err.response.data.status === 'totp required') {
        this.setState({
          errorMessage: strings.getString('LOGIN_ERROR_2FA', '2FA device code is required for this account.'),
          error: false,
          loading: false,
          hide2fa: false,
        })
      } else if (err.response.status === 401 && err.response.data.status === "totp invalid") {
        this.setState({
          errorMessage: strings.getString('LOGIN_ERROR_2FA_INVALID', '2FA device code invalid.'),
          error: true,
          loading: false
        })
      } else if (err.response.status === 401 && err.response.data.status === "missing step_up") {
        this.setState({
          errorMessage: strings.getString('LOGIN_ERROR_STEP_UP', 'This is an unusual login. Please check your email and enter the code provided to sign in.'),
          error: true,
          loading: false,
          showStepUp: true
        })
      } else if (err.response.status === 401 && err.response.data.message === "no totp associated with account") {
        this.setState({
          errorMessage: strings.getString('LOGIN_ERROR_NO_2FA', 'There is no 2FA associated with this account.'),
          error: true,
          loading: false,
        })
      } else if (err.response.status === 401 && err.response.data.message === "The user is currently not allowed to use the service") {
        this.setState({
          errorMessage: (<div dangerouslySetInnerHTML={{ __html: `${strings.getString("BANNED_ACCOUNT", "Your account is not currently eligible to access the Crowfall website.")}<br/>${strings.getString("CONTACT_US_WITH_QUESTIONS", "Please contact support@crowfall.com if you have any questions or concerns.")}` }}/>),
          error: true,
          loading: false,
        })
      } else if (err.response.status === 401) {
        this.setState({
          errorMessage: strings.getString("USERNAME_PASSWORD_INVALID", "Username or password invalid"),
          error: true,
          loading: false,
        })
      } else if (err.response.status === 429) {
        this.setState({
          errorMessage: strings.getString('LOGIN_ERROR_TOO_MANY_ATTEMPTS', 'Too many failed attempts.  Please wait and try again later.  To prevent being forced to wait in the future, please add a 2FA Device to your account.  Please contact support@crowfall.com for further assistance.'),
          error: true,
          loading: false,
        })
      } else {
        this.setState({
          errorMessage: `${strings.getString('LOGIN_UNABLE_TO_LOGIN', 'Unable to authenticate at this time')}: ${_.result(err.response, 'data.message')}`,
          error: true,
          loading: false,
        })
      }

    }
  }

  render() {

    const { loading, code, email, password, otp, success, error, errorMessage, returnPath, hide2fa, showStepUp, stepUpCode } = this.state
    const { language } = this.props
    const hasSession = !_.isNil(this.props.session)
    return (
      <Page>
        {hasSession ? (<Redirect to={returnPath || `/${language}`}/>) : null}
        <Container text>
          <Dimmer active={loading} inverted>
            <Loader/>
          </Dimmer>

          <Header as='h1'>{strings.getString('WELCOME_BACK', 'Welcome Back')}</Header>
          <p>{strings.getString('ACCOUNT_LOGIN_INFO', 'Please Sign In to access your account information and to make store purchases.')}</p>

          <Form onSubmit={this.handleSubmit} success={success} error={error}>
            <Form.Input required autoComplete="off" type="email"
                        label={strings.getString('EMAIL', 'Email')}
                        placeholder={strings.getString('EMAIL', 'Email')}
                        name='email' value={email}
                        onChange={this.handleChange}/>
            <Form.Input required minLength="8" autoComplete="off"
                        label={strings.getString('PASSWORD', 'Password')}
                        placeholder={strings.getString('PASSWORD', 'Password')}
                        type='password'
                        name='password' value={password}
                        onChange={this.handleChange}/>
            {hide2fa ? null : (
                <Form.Input required minLength="6" autoComplete="off"
                            label={strings.getString("2-FACTOR_AUTHENTICATION", "2-Factor Authentication")}
                            placeholder={strings.getString("ENTER_2FA_CODE", "Please enter your 2FA code.")}
                            //placeholder={strings.getString("2FA_LEAVE_BLANK_IF_NOT_APPLICABLE", "Please enter your 2FA code.")}
                            name='otp' value={otp}
                            onChange={this.handleChange}
                            type="number"/>
            )}
            {showStepUp ? (
                <Form.Input minLength="6" autoComplete="off"
                            label={strings.getString("LOGIN_ERROR_STEP_UP_CODE", "Email Verification Code")}
                            placeholder={strings.getString("LOGIN_ERROR_STEP_UP_CODE_HELP", "Please check your email and enter the code provided")}
                            name='stepUpCode' value={stepUpCode}
                            onChange={this.handleChange}
                            type="number"/>
            ) : null}



            <Container textAlign="right">
              <Button className="Button__ACE" primary type='submit'>
                {strings.getString('SIGN_IN', 'Sign In')}
              </Button>
            </Container>

            <Message success header={strings.getString('LOGIN_SUCCESS', 'Successfully Signed In')}
                     content={strings.getString('LOGIN_SUCCESS_INFO', 'You\'re all set!  You can close this dialog safely.')}/>
            <Message error header={strings.getString('UH_OH', 'Uh oh!')}
                     content={errorMessage}/>


          </Form>

          <br/>

          {/*<HelperLinks showAccountHelp showSignupHelp code={code}/>*/}

          <div id="Captcha_container"/>

        </Container>
      </Page>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
