import {
  CLEAR_MODAL_CONTENT,
  CLOSE_MOBILE_NAV,
  OPEN_MOBILE_NAV,
  REMOVE_SESSION,
  TOGGLE_INITIAL_FETCH,
  SET_ACCEPTED_REGIONAL_AGREEMENTS,
  SET_AVAILABLE_AGREEMENT_DEFINITIONS,
  SET_AVAILABLE_LANGUAGES,
  SET_LANGUAGE_MAP,
  SET_COHORTS,
  SET_COOKIE_NOTICE,
  SET_GUILD,
  SET_GUILD_MEMBERSHIP,
  SET_GUILD_NOTIFICATIONS,
  SET_GUILD_WALLET,
  SET_LANGUAGES,
  SET_LOCATION,
  SET_MODAL_CONTENT,
  SET_REGIONS,
  SET_SESSION,
} from '../actionTypes'

import { api, setAuth } from '../../utilities/api'
import Cookies from 'js-cookie'
import _ from 'lodash'


const modelWallet = (walletData) => {
  const { display_strings, wallet_ledger, wallet_id, description, balance } = walletData
  const wallet = {
    balance: display_strings.balance,
    ledger: wallet_ledger,
    walletId: wallet_id,
    description: description,
    amount: balance
  }
  return wallet
}

export const setSession = session => ({
  type: SET_SESSION,
  payload: {
    session,
  },
})

export const removeSession = () => ({
  type: REMOVE_SESSION,
})

export const toggleInitialFetch = (status) => ({
  type: TOGGLE_INITIAL_FETCH,
  payload: {
    initialFetch: status,
  }
})

export const setLocation = location => ({
  type: SET_LOCATION,
  payload: {
    locations: location,
  },
})

export const setLanguages = languages => ({
  type: SET_LANGUAGES,
  payload: {
    languages
  }
})

export const setLanguageMap = languages => {
  const languageMap = languages.reduce((map, language)=>{
    map[language.culture_code] = language.display_name
    return map
  }, {})
  return {
    type: SET_LANGUAGE_MAP,
    payload: {
      languageMap
    }
  }
}

export const setAvailableLanguages = languages => ({
  type: SET_AVAILABLE_LANGUAGES,
  payload: {
    availableLanguages: languages,
  },
})

export const setAgreementDefinitions = agreements => ({
  type: SET_AVAILABLE_AGREEMENT_DEFINITIONS,
  payload: {
    availableAgreementDefinitions: agreements,
  },
})

export const setCohorts = cohorts => ({
  type: SET_COHORTS,
  payload: {
    cohorts,
  },
})

export const setCookieNotice = cookieNotice => ({
  type: SET_COOKIE_NOTICE,
  payload: {
    cookieNotice
  }
})

export const setGuildMembership = membership => ({
  type: SET_GUILD_MEMBERSHIP,
  payload: {
    ...membership,
  },
})

export const setGuild = guild => ({
  type: SET_GUILD,
  payload: {
    guild
  },
})

export const setGuildWallet = wallet => ({
  type: SET_GUILD_WALLET,
  payload: {
    guildWallet: wallet,
  },
})

export const setGuildNotifications = notifications => ({
  type: SET_GUILD_NOTIFICATIONS,
  payload: {
    guildNotifications: notifications,
  },
})

export const setModalContent = (content) => ({
  type: SET_MODAL_CONTENT,
  payload: {
    modal: { content }
  }
})

export const clearModalContent = () => ({
  type: CLEAR_MODAL_CONTENT,
  payload: {
    modal: {
      content: null
    }
  }
})

export const openMobileNav = () => {
  return (dispatch) => {
    document.body.className = "Body--overflowHidden"
    dispatch ({
      type: OPEN_MOBILE_NAV,
      payload: {
        openNav: true
      },
    })
  }
}

export const closeMobileNav = () => {
  return (dispatch) => {
    document.body.className = "Body--overflowVisible"
    dispatch ({
      type: CLOSE_MOBILE_NAV,
      payload: {
        openNav: false
      },
    })
  }
}

export const setRegions = regions => ({
  type: SET_REGIONS,
  payload: {
    regions
  }
})

export const setAcceptedRegionalAgreements = agreements => ({
  type: SET_ACCEPTED_REGIONAL_AGREEMENTS,
  payload: {
    acceptedRegionalAgreements: agreements
  }
})

//**********
//API CALLS
//**********

export const fetchLanguages = () => {
  return async function(dispatch) {
    let languages = await api.get('/public/language?all=true')
    languages = languages.data.map(language => {
      const culture_code = language.culture_code.split('-')[0]
      const display_name = language.display_name.split(' - ')[0]
      return {
        culture_code,
        display_name,
      }
    })
    languages = _.uniqBy(languages, 'culture_code')
    languages = languages.sort((a, b) => {
      if (a.display_name < b.display_name) return -1
      if (a.display_name > b.display_name) return 1
      return 0
    })
    dispatch(setLanguages(languages))
    dispatch(setLanguageMap(languages))
  }
}

export const fetchRegions = () => {
  return async function(dispatch) {
    const regions = await api.get('/public/current-region')
    dispatch(setRegions(regions.data))
  }
}

export const fetchCohorts = () => {
  return async function(dispatch) {
    const res = await api.get('/user/cohort')
    const cohorts = res.data.reduce((acc, curr) => {
        acc[curr.external_id] = curr
        return acc
      }, {})
    dispatch(setCohorts(cohorts))
  }
}

export const fetchAcceptedRegionalAgreements = () => {
  return async function(dispatch) {
    const agreements = (await api.get(`/user/agreement-status`)).data
    dispatch(setAcceptedRegionalAgreements(agreements))
  }
}

export const fetchGuildMembership = () => {
  return async function(dispatch) {
    const membership = await api.get('/user/guild-membership')
    const guildId = _.result(membership, 'data[0].guild.guild_id')
    const guildRank = _.result(membership, 'data[0].guild_rank.rank')
    dispatch(setGuildMembership({guildId, guildRank}))
    return {guildId, guildRank}
  }
}

export const fetchGuildNotifications = guildId => {
  return async function(dispatch) {
    const notificationsRes = await api.get(`user/guild/${guildId}/notification`)
    dispatch(setGuildNotifications(notificationsRes.data))
  }
}

export const fetchGuildWallet = guildId => {
  return async function(dispatch) {
    const walletRes = await api.get(`/user/guild/${guildId}/wallet-ledger`)
    const wallet = modelWallet(walletRes.data)
    dispatch(setGuildWallet(wallet))
  }
}

export const fetchBetaCohorts = () => {
  return async function() {
    const cohorts = await api.get(`/public/beta-groups`)
    return cohorts.data
  }
}

export const fetchCountryMap = () => {
  return async function() {
    const countryRes = await api.get(`/public/definition/country-iso`)
    return countryRes.data
  }
}

export const resendEmailVerification = (email) => {
  return async function() {
    await api.post("/user/validate/resend", { email })
  }
}

export const fetchAgreements = (language) => {
  return async function() {
    const res = await api.get(`/public/agreement-definition?lang=${language}`)
    return res.data
  }
}

export const updateAgreementStatus = (agreement) => {
  return async function() {
    const { external_id, accepted } = agreement
    await api.post(`/user/agreement/${external_id}/${accepted ? "accept" : "decline"}`)
  }
}

export const verifyUsernameAvailability = (username) => {
  return async function() {
    const res = await api.get(`/user/username-available/${username}`)
    const usernameStatus = _.result(res, 'data.status')
    return usernameStatus
  }
}

export const verifyEmailAddressAvailability = (email) => {
  return async function() {
    const res = await api.get(`/user/email-available/${email}`)
    const emailStatus = _.result(res, 'data.status')
    return emailStatus
  }
}

export const registerUser = (userForm) => {
  return async function() {
    await api.post('/user', userForm)
  }
}

export const authenticateSession = (form) => {
  return async function() {
    const res = await api.post(`/user/authenticate`, form)
    return res.data
  }
}

export const verifyValidationToken = (token) => {
  return async function() {
    const res = await api.get(`user/validation/${token}`)
    return res.data
  }
}

export const validateAccount = (token, options) => {
  return async function(dispatch) {
    const res = await api.post(`user/validate?token=${token}`, options)
    const session = res.data

    localStorage.setItem('userData', JSON.stringify(session))
    setAuth(_.result(session, 'token'))
    Cookies.set('crowfallInfo', {
      token: _.result(session, 'token'),
      firstName: _.result(session, 'user.first_name'),
      lastName: _.result(session, 'user.last_name'),
      email: _.result(session, 'user.email'),
      username: _.result(session, 'user.account_name'),
      user_id: _.result(session, 'user.user_id'),
      date_expires: _.result(session, 'date_expires'),
    }, { domain: '.crowfall.com', expires: 30, sameSite: 'lax' })

    dispatch(setSession(session))
  }
}