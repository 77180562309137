/*

FRAGMENT: Capsule

PROPERTY  DEFAULT  REQUIREMENT  TYPE     DESCRIPTION
label              required     String   centered label within capsule box
red       false    optional     Boolean  updated background color to red
orange    false    optional     Boolean  updated background color to orange  
yellow    false    optional     Boolean  updated background color to yellow
green     false    optional     Boolean  updated background color to green
blue      false    optional     Boolean  updated background color to blue

*/

import React from 'react'
import './styles.scss'


const Capsule = (props) => {

    const { label, red, orange, yellow, green, blue } = props
    let className = "Capsule"
    if (red) className += " Capsule--red"
    if (orange) className += " Capsule--orange"
    if (yellow) className += " Capsule--yellow"
    if (green) className += " Capsule--green"
    if (blue) className += " Capsule--blue"

    return (
        <div className={className}>
            {label}
        </div>
    )
}

export default Capsule