import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import strings from '../../../strings'
import Page from '../../../componentLibrary/Models/Page'
import PageNotFound from '../../../componentLibrary/Models/PageNotFound'
import PageColumn from '../../../componentLibrary/Fragments/PageColumn'
import MarketingFooter from '../../../componentLibrary/Elements/MarketingFooter'

import { 
    fetchSinglePromotion,
    clearSelectedPromotion,
} from '../../../redux/actions/promotions'

import './styles.scss'


const mapStateToProps = state => {
    return {
        selected: state.promotions.selected,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchSinglePromotion: (uri) => dispatch(fetchSinglePromotion(uri)),
        clearSelectedPromotion: () => dispatch(clearSelectedPromotion()),
    }
}

class PromotionPage extends React.Component {
    state = {
        fetched: false,
        noContentFound: false,
    }

    async componentDidMount() {
        const { uri } = this.props.match.params
        await this.props.fetchSinglePromotion(uri)
        this.setState({ fetched: true })
    }

    componentWillUnmount() {
        this.props.clearSelectedPromotion()
    }

    render() {

        const { selected = {} } = this.props
        const noContentFound = _.isEmpty(selected) ? true : false
        const { promoTitle: title, bannerAssetUrl, body } = selected
        const { fetched } = this.state

        return (
            <React.Fragment>
                {fetched && noContentFound &&
                    <PageNotFound returnLabel={strings.getString("BACK_TO_PROMOTIONS", "Back to Promotions")} returnLink="/promotions"/>
                }

                {fetched && !noContentFound &&
                    <Page header={{ title, banner: bannerAssetUrl }}>
                        <div className="PromotionPage">
                            <PageColumn>
                                <div dangerouslySetInnerHTML={{ __html: body }}/>
                            </PageColumn>
                            <MarketingFooter returnLink="/promotions" returnString="BACK_TO_PROMOTIONS" />
                        </div>
                    </Page>
                }
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionPage)