import React from 'react'
import { api } from '../utilities/api'
import { Dimmer, Loader, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import LocLink from '../componentLibrary/Elements/LocLink'
import FixedTable from '../componentLibrary/Elements/FixedTable'
import strings from '../strings'

const mapStateToProps = () => {
  return {}
}

class AccountDetailsWallets extends React.Component {

  state = {
    loading: false,
    wallets: []
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getContent()
    this.setState({ loading: false })
  }

  async getContent() {
    const wallets = await api.get(`/user/currency-wallet`)
    this.setState({
      wallets: _.sortBy(wallets.data.filter(w=>w.wallet_user_visible && w.wallet_created), 'currency_code')
    })
  }

  render() {

    const { loading, wallets } = this.state

    return (
      <div>
        <Dimmer active={loading} inverted>
          <Loader/>
        </Dimmer>
        <h1>{strings.getString('CURRENCY_WALLETS', 'Currency Wallets')}</h1>

        <FixedTable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{strings.getString('CURRENCY_CODE', 'Currency Code')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('DESCRIPTION', 'Description')}</Table.HeaderCell>
              <Table.HeaderCell>{strings.getString('BALANCE', 'Balance')}</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">{strings.getString('LEDGER', 'Ledger')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {wallets.map(r => (
              <Table.Row key={r.wallet_id}>
                <Table.Cell singleLine>
                  {r.currency_code}
                </Table.Cell>
                <Table.Cell>{r.description}</Table.Cell>
                <Table.Cell>{_.result(r, 'display_strings.balance')}</Table.Cell>
                <Table.Cell textAlign="right">
                  <LocLink to={`/account/wallets/${r.currency_code}`}>{strings.getString('LEDGER', 'Ledger')}</LocLink>
                </Table.Cell>
              </Table.Row>
            ))}

          </Table.Body>
        </FixedTable>

      </div>
    )
  }
}

export default connect(mapStateToProps)(AccountDetailsWallets)
