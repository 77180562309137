/*

Element: PromoListItem
acts as a link to the provided promotion

PROPERTY  DEFAULT  REQUIRED  TYPE    DESCRIPTION
link               required  String  Local link to crowfall.com. Wraps the entire component  
assetUrl           required  String  used as the background image and covers the entire component
title              required  String  descriptive content for promo.

*/


import React from 'react'
import LocLink from '../LocLink'
import './styles.scss'

const PromoListItem = (props) => {

    const { link, assetUrl, title } = props

    return (
        <LocLink to={link}>
            <div className="PromoListItem" style={{backgroundImage: `url(${assetUrl})`}}>
                <div className="PromoListItem__title">
                    {title}
                    <div className="PromoListItem__titleBlur"/>
                </div>
            </div>
        </LocLink>
    )
}

export default PromoListItem