/*

FRAGMENT: Title
Syncs styling for page titles, subtitles, ETC...

PROPERTY  DEFAULT  REQUIREMENT  TYPE     DESCRIPTION

title     ''       required     String   Label. Will wrap if extends past viewport width  
bold      false    optional     Boolean  Bolding increased to max: 700
center    false    optional     Boolean  Label takes up 100% width of alotted space and centers
h1        false    optional     Boolean  Enlarges Font size. 
h5        false    optional     Boolean  Decreases Font size.

*/

import React from 'react'
import './styles.scss'

const Title = (props) => {
  const { title, center, bold, h1, h5 } = props
  let className = 'Title'
  if (center) className += ' Title--center'
  if (bold) className += ' Title--bold'
  if (h1) className += ' Title--h1'
  if (h5) className += ' Title--h5'
  return (
    <div className={className}>{title}</div>
  )
}

export default Title