import React from 'react'
import { connect } from 'react-redux'
import contentful from '../../../utilities/contentful'
import assets from '../../../assets'
import BannerHeader from '../../Elements/BannerHeader'
import './styles.scss'
import StaticString from '../../Fragments/StaticString'
import _ from 'lodash'

const mapStateToProps = (state) => {
  const { language } = state
  return {
    language,
  }
}

class Page extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      title: null,
      pageHeader: null,
    }

    this.getContent = this.getContent.bind(this)
  }

  async getContent() {

    const { contentId, language } = this.props

    if (!contentId || !language) return
    const content = await contentful.getEntry(contentId, language)
    this.setState({
      title: content.pageTitle || content.title,
      pageHeader: content.pageHeader,
    })
  }

  async anchoredPageLoad() {
    if (window.location.hash) {
      const hashString = window.location.hash.replace('#', '')
      setTimeout(() => {
        const anchorElement = document.getElementById(hashString)
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' })
        }
        return
      }, 500);
    }
  }

  async componentDidMount() {
    await this.getContent();

    // Call anchoredPageLoad only after page content has been loaded from Contentful
    this.anchoredPageLoad();
  }

  render() {
    let { titleName, children, header, bannerRibbon, isMarketing, removeTopMargin, titleAsset, bannerShort, mobileBannerHidden, pageFooter } = this.props
    let title = ''
    if ( titleName ) { title= <StaticString stringName={titleName} /> }
    if ( this.state.title ) title = this.state.title
    const { pageHeader } = this.state
    let className = 'Page'
    if ( removeTopMargin ) className += ' Page--removeTopMargin'
    if ( mobileBannerHidden ) className += ' Page--mobileBannerHidden'

    return (
      <React.Fragment>
        <div className={ className }>

          { header ? (
            <div className='Page__header'>
              <BannerHeader isMarketing={isMarketing} title={title} titleAsset={titleAsset} header={header} short={bannerShort} mobileBannerHidden={mobileBannerHidden}/>
            </div>
          ) : null }

          { bannerRibbon &&
            <div className="Page__desktopRibbon">
              {bannerRibbon}
            </div>
          }

          <div className='Page__RadialImgFooter' style={{backgroundImage: `url(${assets.staticImageUrls.SITE_BACKGROUND_ACCENT})`}} />
          <div className='Page__stonebornFooter' style={{backgroundImage: `url(${assets.staticImageUrls.SITE_BACKGROUND_ACCENT_FOOTER})`}} />

          <div className='Page__body'>

            <div className='Page__RadialImgHeader' style={{backgroundImage: `url(${assets.staticImageUrls.SITE_BACKGROUND_ACCENT})`}} />

            <div className='Page__content'>

              { bannerRibbon &&
                <div className="Page__mobileRibbon">
                  {bannerRibbon}
                </div>
              }

              {!header && title  &&
                <h1 className="Page__title">
                  {title}
                </h1>
              }
              {pageHeader && <div className="Page__contentHeader" dangerouslySetInnerHTML={{ __html: pageHeader }}></div>}
              {children}
            </div>
          </div>
        </div>

        {!_.isEmpty(pageFooter) && <div className="Page__footer">{pageFooter}</div>}
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps, null)(Page)
