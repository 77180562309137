import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import swal from '@sweetalert/with-react'
import strings from '../../strings'
import Page from '../../componentLibrary/Models/Page'
import Checkbox from '../../componentLibrary/Elements/Checkbox'
import Button from '../../componentLibrary/Fragments/Button'
import SectionHeader from '../../componentLibrary/Fragments/SectionHeader'
import { fetchAcceptedRegionalAgreements, updateAgreementStatus } from '../../redux/actions'
import './styles.scss'


const mapStateToProps = state => {
    return {
        acceptedRegionalAgreements: state.acceptedRegionalAgreements,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAcceptedAccountAgreements: () => dispatch(fetchAcceptedRegionalAgreements()),
        updateAgreementStatus: (agreement) => dispatch(updateAgreementStatus(agreement)),
    }
}

class AcceptTermsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            requiredAgreementList: [],
            additionalAgreementList: [],
            redirectToHome: false,
        }
        this.modelAgreements = this.modelAgreements.bind(this)
        this.toggleRequiredAgreement = this.toggleRequiredAgreement.bind(this)
        this.toggleAdditionalAgreement = this.toggleAdditionalAgreement.bind(this)
        this.submitAcceptedAgreements = this.submitAcceptedAgreements.bind(this)
    }

    componentDidMount() {
        this.modelAgreements()
    }

    modelAgreements() {
        const agreementList = _.orderBy(this.props.acceptedRegionalAgreements.filter(a => !a.accepted).map(a => ({
            requiredIfPresent: a.required_if_present,
            label: _.result(a, `display_strings.acceptance_string`, a.agreement_definition_name) || `I accept the ${a.name} agreement. `,
            accepted: a.accepted,
            externalId: a.external_id,
            external_id: a.external_id,
            immutable: a.immutable,
            disabled: a.immutable && a.accepted,
        })), 'label')

        const requiredAgreementList = agreementList.filter(a => (a.requiredIfPresent))
        if (_.isEmpty(requiredAgreementList)) {
            this.setState({ redirectToHome: true })
            return
        }
        const additionalAgreementList = agreementList.filter(a => (!a.requiredIfPresent))
        this.setState({ requiredAgreementList, additionalAgreementList })
    }

    toggleRequiredAgreement(id) {
        const { requiredAgreementList } = this.state
        let newList = [ ...requiredAgreementList ]
        const agreementIndex = newList.findIndex(agreement => agreement.externalId === id)
        if (agreementIndex !== -1) newList[agreementIndex].accepted = !newList[agreementIndex].accepted
        this.setState({ requiredAgreementList: newList })
    }

    toggleAdditionalAgreement(id) {
        const { additionalAgreementList } = this.state
        let newList = [ ...additionalAgreementList ]
        const agreementIndex = newList.findIndex(agreement => agreement.externalId === id)
        if (agreementIndex !== -1) newList[agreementIndex].accepted = !newList[agreementIndex].accepted
        this.setState({ additionalAgreementList: newList })
    }

    async submitAcceptedAgreements() {
        const { requiredAgreementList, additionalAgreementList } = this.state
        const agreementList = [ ...requiredAgreementList, ...additionalAgreementList ]
        try {
            await Promise.all(agreementList.map(agreement => {
                return this.props.updateAgreementStatus(agreement)
            }))
            await this.props.fetchAcceptedAccountAgreements()
            swal({
                icon: "success",
                title: strings.getString("UPDATED_AGREEMENTS_SUCCESS", "Successfully Updated Agreements")
            })
            this.setState({ redirectToHome: true })
        } catch (err) {
            swal({
                icon: "error",
                title: strings.getString("UH_OH", "Uh Oh!"),
                text: strings.getString("UPDATED_AGREEMENTS_ERROR", "There was an error when attempting to update your account agreements. Please try again.")
            })
        }
    }

    checkRequiredAgreements() {
        const { requiredAgreementList } = this.state
        return !_.isEmpty(requiredAgreementList.filter(agreement => agreement.accepted === false))
    }

    render() {

        const { requiredAgreementList, additionalAgreementList, redirectToHome } = this.state

        return (
            <Page titleName="MUST_ACCEPT_AGREEMENTS">

                {redirectToHome && <Redirect to="/"/>}

                <div className="AcceptTermsPage">
                    <div className="AcceptTermsPage__form">
                        
                        <div className="AcceptTermsPage__header">
                            <SectionHeader h5 title={strings.getString("MUST_ACCEPT_AGREEMENTS_TO_CONTINUE", "You must accept all required agreements before continuing.")}/>
                        </div>
                        {requiredAgreementList.map(agreement => {
                            const { externalId, label, disabled } = agreement
                            return (
                                <div className="AcceptTermsPage__field" key={externalId}>
                                    <Checkbox label={label} required disabled={disabled} toggle={() => this.toggleRequiredAgreement(externalId)}/>
                                </div>
                            )
                        })}
                        {!_.isEmpty(additionalAgreementList) && <div className="AcceptTermsPage__additionalAgreements">
                            <div className="AcceptTermsPage__header">
                                <SectionHeader h5 title={strings.getString("ADDITIONAL_AGREEMENTS", "Additional Agreements")}/>
                            </div>
                            {additionalAgreementList.map(agreement => {
                                const { externalId, label, disabled } = agreement
                                return (
                                    <div className="AcceptTermsPage__field" key={externalId}>
                                        <Checkbox label={label} disabled={disabled} toggle={() => this.toggleAdditionalAgreement(externalId)}/>
                                    </div>
                                )
                            })}  
                        </div>}
                        
                        <div className="AcceptTermsPage__button">
                            <Button 
                                medium label={strings.getString("ACCEPT_AGREEMENTS", "Accept Agreements")} 
                                disabled={this.checkRequiredAgreements()} onclick={this.submitAcceptedAgreements}
                            />
                        </div>
                    
                    </div>
                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTermsPage)