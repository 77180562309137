import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import strings from '../../../strings'
import TitleBar from '../../../componentLibrary/Fragments/TitleBar'
import TitleBlur from '../../../componentLibrary/Fragments/TitleBlur'
import ProductGrid from '../ProductGrid'
import './styles.scss'


const mapStateToProps = state => {
    return {
        featuredCatalog: state.crowfallStore.featuredCatalog,
        ltoCatalog: state.crowfallStore.ltoCatalog,
        bestValueCatalog: state.crowfallStore.bestValueCatalog,
        onSaleCatalog: state.crowfallStore.onSaleCatalog,
        catalogSearched: state.crowfallStore.catalogSearched,
        storeCategories: state.crowfallStore.categories,
        selectedCategory: state.crowfallStore.selectedCategory,
    }
}

class FeaturedPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasFeatured: false,
            featuredList: [],
            hasLTO: false,
            limitedTimeOfferList: [],
            hasBestValue: false,
            bestValueList: [],
            hasOnSale: false,
            onSaleList: [],
            initialScrollToChecked: false,
        }
    }

    componentDidMount() {
        this.setAvailableSections()
        this.modelCatalogs()
    }

    componentDidUpdate(prevProps) {
        const { selectedCategory: prevSelected, catalogSearched: prevCatalog } = prevProps
        const { selectedCategory: currSelected, catalogSearched: currCatalog } = this.props
        const { initialScrollToChecked } = this.state
        if (!_.isEqual(prevSelected, currSelected) || !_.isEqual(prevCatalog, currCatalog)) this.modelCatalogs()
        if ((!initialScrollToChecked && currSelected.subcategoryName) || prevSelected.subcategoryName !== currSelected.subcategoryName) this.scrollToSubcategory()
    }

    setAvailableSections() {
        const { featuredCatalog, ltoCatalog, bestValueCatalog, onSaleCatalog, selectedCategory } = this.props
        const hasFeatured = !_.isEmpty(featuredCatalog)
        const hasLTO = !_.isEmpty(ltoCatalog)
        const hasBestValue = !_.isEmpty(bestValueCatalog)
        const hasSaleCatalog = !_.isEmpty(onSaleCatalog)
        this.setState({ hasFeatured, hasLTO, hasBestValue, hasSaleCatalog }, 
            selectedCategory.subcategoryName ? this.scrollToSubcategory() : null
        )
    }

    modelCatalogs() {
        const { featuredCatalog, ltoCatalog, bestValueCatalog, onSaleCatalog, catalogSearched } = this.props
        const featuredList = featuredCatalog.filter(item => !_.isEmpty(_.find(catalogSearched, ["item_id", item.item_id])))
        const limitedTimeOfferList = ltoCatalog.filter(item => !_.isEmpty(_.find(catalogSearched, ["item_id", item.item_id])))
        const bestValueList = bestValueCatalog.filter(item => !_.isEmpty(_.find(catalogSearched, ["item_id", item.item_id])))
        const onSaleList = onSaleCatalog.filter(item => !_.isEmpty(_.find(catalogSearched, ["item_id", item.item_id])))
        this.setState({ featuredList, limitedTimeOfferList, bestValueList, onSaleList })
    }

    scrollToSubcategory() {
        const { selectedCategory } = this.props
        const element = document.getElementById(`section--${selectedCategory.subcategoryKey}`)
        if (element) element.scrollIntoView({ behavior: "smooth", block: "start" })
        if (element && this.state.initialScrollToChecked === false) this.setState({ initialScrollToChecked: true })
    }


    render() {

        const { hasFeatured, featuredList, hasLTO, limitedTimeOfferList, hasBestValue, bestValueList, hasOnSale, onSaleList } = this.state

        return (
            <div className="FeaturedPage">
                <div className="FeaturedPage__header">
                    <TitleBar title={strings.getString("FEATURED", "Featured")} small/>
                </div>
                {hasFeatured && <div className="FeaturedPage__section">
                    <ProductGrid products={featuredList}/>
                </div>}

                {hasLTO && <div className="FeaturedPage__section">
                    <div className="FeaturedPage__subheader" id="section--limited-time">
                        <TitleBlur title={strings.getString("LIMITED_TIME_OFFER", "Limited Time Offer")} h3/>
                    </div>
                    <ProductGrid products={limitedTimeOfferList} hideLtoCapsule/>
                </div>}

                {hasBestValue && <div className="FeaturedPage__section">
                    <div className="FeaturedPage__subheader" id="section--best-value">
                        <TitleBlur title={strings.getString("BEST_VALUE", "Best Value")} h3/>
                    </div>
                    <ProductGrid products={bestValueList} hideBestValueCapsule/>
                </div>}

                {hasOnSale && <div className="FeaturedPage__section">
                    <div className="FeaturedPage__subheader" id="section--on-sale">
                        <TitleBlur title={strings.getString("ON_SALE", "On Sale")} h3/>
                    </div>
                    <ProductGrid products={onSaleList} hideOnSaleCapsule/>
                </div>}
            </div>
        )
    }
}

export default connect(mapStateToProps)(FeaturedPage)