import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import _ from 'lodash'
import queryString from 'query-string'
import strings from '../../strings'
import CrownsDisplay from './CrownsDisplay'
import CategoryPage from './CategoryPage'
import FeaturedPage from './FeaturedPage'
import GiftingPage from './GiftingPage'
import contentful from '../../utilities/contentful'
import assets from '../../assets'
import Page from '../../componentLibrary/Models/Page'
import Searchbar from '../../componentLibrary/Elements/Searchbar'
import ScrollToPositionButton from '../../componentLibrary/Elements/ScrollToPositionButton'
import ShopMenu from './ShopMenu'
import { fetchStoreDetails, setSelectedCategory, searchCatalog, clearCatalogSearchCriteria } from '../../redux/actions/crowfall-store'
import { getWalletBalance } from '../../redux/actions/user'
import { clearSubscriptionDetails, fetchVIPSubscriptionDetails } from '../../redux/actions/subscription'
import './styles.scss'


const mapStateToProps = state => {
    return {
        session: state.session,
        language: state.language,
        categories: state.crowfallStore.categories,
        selectedCategory: state.crowfallStore.selectedCategory,
        subscriptionDetails: state.subscription.subscriptionDetails,
        guildManagement: state.guildManagement,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchStoreDetails: (session, language, skusToFilter) => dispatch(fetchStoreDetails(session, language, skusToFilter)),
        setSelectedCategory: (category) => dispatch(setSelectedCategory(category)),
        searchCatalog: (criteria) => dispatch(searchCatalog(criteria)),
        clearCatalogSearchCriteria: () => dispatch(clearCatalogSearchCriteria()),
        getWalletBalance: (currCode) => dispatch(getWalletBalance(currCode)),
        fetchVIPSubscriptionDetails: () => dispatch(fetchVIPSubscriptionDetails()),
        clearSubscriptionDetails: () => dispatch(clearSubscriptionDetails()),
    }
}


const ShopProductPage = (props) => {
    // if (props.category === "Gifting") {
        // return (<GiftingPage />)
    if (props.category === "Featured") {
        return (<FeaturedPage />)
    } else {
        return (<CategoryPage />)
    }
}

class ShopPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fetched: false,
            title: null,
            banner: null,
            pageHeader: null,

            walletCount: 0,
            walletString: "0 CRO",

            redirectTo: null,
        }
        this.getPageContent = this.getPageContent.bind(this)
        this.fetchVirtualWallet = this.fetchVirtualWallet.bind(this)
        this.getStoreDetails = this.getStoreDetails.bind(this)
    }

    async componentDidMount() {
        const { location, history } = this.props
        this.recordToLiquidAdvertising()
        if (_.isEmpty(location.search)) {
            this.setState({ redirectTo: "/store?category=featured" })
        } else {
            const search = queryString.parse(location.search)
            if (!_.isEmpty(_.result(search, "record-to-liquid"))) {
                history.replace(`${location.pathname}?category=${search.category}`)
            }

            const { session, fetchVIPSubscriptionDetails } = this.props
            if (this.props.session) await fetchVIPSubscriptionDetails()
            await this.getStoreDetails()
            this.getPageContent()
            if (session) this.fetchVirtualWallet()
            this.findSelectedCategory()

            this.setState({ fetched: true })
        }
    }

    async getStoreDetails() {
        const subscriptionSku = _.result(this.props.subscriptionDetails, "subscription.subscription_definition.item_id")
        await this.props.fetchStoreDetails(!!this.props.session, this.props.language, subscriptionSku ? [ subscriptionSku ] : null)
    }

    recordToLiquidAdvertising() {
        window.gtag('event', 'conversion', {
            'allow_custom_scripts': true,
            'session_id': Date.now() + Math.random().toString(36),
            'send_to': 'DC-11063492/crowfall/d_cro003+per_session'
        })
    }

    componentDidUpdate(prevProps) {
        const { location: prevLocation, subscriptionDetails: prevSubscription } = prevProps
        const { location: currLocation, subscriptionDetails: currSubscription } = this.props
        if (!_.isEqual(prevSubscription, currSubscription)) this.getStoreDetails()
        if (prevLocation.pathname !== currLocation.pathname || prevLocation.search !== currLocation.search) {
            if (_.isEmpty(currLocation.search)) {
                this.setState({ redirectTo: "/store?category=featured" })
                return
            }
            this.findSelectedCategory()
        }
    }

    async getPageContent() {
        const { language } = this.props
        if ( !language ) return
        const content = await contentful.getEntry(assets.contentfulIds.STORE_PAGE) || {}
        this.setState({
            title: content.pageTitle,
            banner: _.result(content, "banner.assetUrl"),
            pageHeader: content.pageHeader,
        })
    }

    async fetchVirtualWallet() {
        const { getWalletBalance } = this.props
        const wallet = await getWalletBalance("cro") || {}
        this.setState({
            walletCount: wallet.currentWalletBalance,
            walletString: wallet.walletBalance
        })
    }

    findSelectedCategory() {
        const { location, categories, setSelectedCategory } = this.props

        const search = queryString.parse(location.search) || {}
        const categoryKebab = (search.category || "").toLowerCase()
        const subcategoryKebab = (search.sub || "").toLowerCase()
        let categoryName, categoryKey, subcategoryName, subcategoryKey

        if (categoryKebab === "featured") {
            categoryName = strings.getString("FEATURED", "Featured")
            categoryKey = "Featured"
            subcategoryName = _.startCase(subcategoryKebab)
            subcategoryKey = subcategoryKebab
        // } else if (categoryKebab === "gifting") {
        //     categoryName = strings.getString("GIFTING", "Gifting")
        //     categoryKey = "Gifting"
        //     let categoryValues = Object.values(categories)
        //     const subcategory = _.find(categoryValues, { slug: search.sub })
        //     subcategoryName = _.result(subcategory, "display_strings.name")
        //     subcategoryKey = _.result(subcategory, "key")
        } else {
            const category = categories[categoryKebab] || {}
            categoryName = _.result(category, "display_strings.name")
            categoryKey = _.result(category, "key")
            const subcategory = subcategoryKebab && !_.isEmpty(category) ? _.find(category.subcategories, { slug: subcategoryKebab }) : {}
            subcategoryName = _.result(subcategory, "display_strings.name")
            subcategoryKey = subcategory.key
        }

        setSelectedCategory({ categoryName, categoryKey, subcategoryName, subcategoryKey })
    }

    componentWillUnmount() {
        this.props.clearSubscriptionDetails()
    }

    render() {

        const { fetched, title, banner, pageHeader, walletString, redirectTo } = this.state
        const { selectedCategory, searchCatalog, clearCatalogSearchCriteria, guildManagement } = this.props
        const searchPlaceholder = strings.getString("SEARCH_CATEGORY", `Search ${selectedCategory.categoryName}...`, { category: selectedCategory.categoryName })
        
        return (
            <Page header={{ title, banner }} bannerShort>

                {redirectTo && <Redirect to={redirectTo}/>}

                {pageHeader && <div className="ShopPage__header" dangerouslySetInnerHTML={{ __html: pageHeader }}/>}

                {fetched && <div className="ShopPage__frame" id="ShopPage--frameTop">
                    <div className="ShopPage__menu"><ShopMenu/></div>
                    <div className="ShopPage__crowns_wrapper">
                        <div className="ShopPage__crowns"><CrownsDisplay balance={walletString}/></div>
                        {guildManagement.guildConfiguration.default_permissions && (guildManagement.guildRank <= guildManagement.guildConfiguration.default_permissions.use_guild_bank) && <div className="ShopPage__crowns ShopPage__crowns-guild"><CrownsDisplay balance={guildManagement.guildWallet.balance} guild={true} /></div>}
                    </div>
                    <div className="ShopPage__search"><Searchbar search={searchCatalog} clear={clearCatalogSearchCriteria} placeholder={searchPlaceholder}/></div>
                    <div className="ShopPage__body">
                        <ShopProductPage category={selectedCategory.categoryName}/>
                        <div className="ShopPage__scrollTo"><ScrollToPositionButton elementId="ShopPage--frameTop"/></div>
                    </div>
                </div>}

            </Page>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopPage))
