import React from 'react'
import { connect } from 'react-redux'
import swal from '@sweetalert/with-react'
import strings from '../../../strings'
import Page from '../../../componentLibrary/Models/Page'
import Media from '../../../componentLibrary/Elements/Media'
import MediaList from '../../../componentLibrary/Models/MediaList'
import contentful from '../../../utilities/contentful'
import { setModalContent } from '../../../redux/actions'
import _ from 'lodash'
import './styles.scss'

const mapDispatchToProps = dispatch => {
  return {
    setModalContent: (content) => dispatch(setModalContent(content))
  }
}

class VideosPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      entries: []
    }

    this.getContent = this.getContent.bind(this)
    this.removeMediaIdFromUrl = this.removeMediaIdFromUrl.bind(this)
  }

  async componentDidMount() {
    const { match } = this.props
    const slug = _.result(match, 'params.media')
    await this.getContent()
    if (slug) {
      this.scrollToStoredPosition()
      this.openModal(slug)
    }
  }

  async getContent() {
    const entries = (await contentful.getEntries('gallery', 'fields.group[match]=Videos', undefined, 'order=-fields.date')).map(e => {
      e.video = e.videoUrl
      e.thumb = _.result(e, 'thumbnail.assetUrl')
      e.siteSlug = `/media/videos/${e.slug}`
      return e
    })
    this.setState({ 'entries': entries })
  }

  async openModal(slug) {
    const { setModalContent } = this.props
    const assets = await contentful.getEntries('gallery', `fields.slug[match]=${slug}`)
    const asset = _.isEmpty(assets) ? undefined : assets.filter(asset => asset.slug.toLowerCase() === slug.toLowerCase())[0]

    if (asset) {
      const { title, videoUrl } = asset
      const img = _.result(asset, 'thumb.assetUrl')
      setModalContent(
        <Media img={img} title={title} video={videoUrl}/>
      )
    } else {
      await swal({
        title: strings.getString("UH_OH", "Uh Oh!"),
        icon: 'error',
        content: (
          <div style={{ textAlign: 'center' }}>{strings.getString("MEDIA_DOES_NOT_EXIST", "The requested Crowfall Media does not exist.")}</div>
        )
      })
      this.removeMediaIdFromUrl()
    }
  }

  removeMediaIdFromUrl() {
    const { history, location } = this.props
    const urlParams = location.pathname.split('/')
    const newLocation = urlParams.splice(0, urlParams.length - 1).join("/")
    history.replace(newLocation)
  }

  scrollToStoredPosition() {
    if (localStorage.getItem('scrollPosition') !== null) {
      window.scrollTo(0, localStorage.getItem('scrollPosition'))
      localStorage.removeItem('scrollPosition')
    }
  }

  render() {
    const { entries } = this.state
    return (
      <Page titleName="MEDIA_VIDEOS">
        <MediaList list={entries}/>
      </Page>
    )
  }
}

export default connect(null, mapDispatchToProps)(VideosPage)
