import React from 'react'
import Page from '../componentLibrary/Models/Page'
import assets from '../assets'
import connect from 'react-redux/es/connect/connect'
import _ from 'lodash'
import queryString from 'query-string'
import { api } from '../utilities/api'
import { Container, Icon, Message } from 'semantic-ui-react'
import strings from '../strings'

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

class ValidateEmail extends React.Component {

  state = {
    validated: false,
    error: false,
    username: null,
  }

  componentDidMount = async () => {
    const q = _.result(this.props, 'location.search')
    const query = queryString.parse(q) || {}

    this.setState({
      token: query.token,
    })

    try {
      await api.put(`/user/email?token=${query.token}`)
      this.setState({
        validated: true,
      })
    } catch {
      this.setState({
        error: true,
      })
    }

  }

  render() {

    const { validated, error } = this.state

    return (
      <div>
        <Page contentId={assets.contentfulIds.EMAIL_VALIDATION}>
          <Container>
            {validated ? (
              <Message success icon>
                <Icon name='checkmark'/>
                <Message.Content>
                  <Message.Header>{strings.getString('VALIDATION_EMAIL_SUCCESS', 'Successfully Validated Email')} </Message.Header>
                  {strings.getString("VALIDATION_EMAIL_CHANGE_SUCCESS", 'Your email address has been successfully validated! You can now Sign In and use your account.')}
                </Message.Content>
              </Message>
            ) : ''}

            {error ? (
              <Message error icon>
                <Icon name='warning sign'/>
                <Message.Content>
                  <Message.Header>{strings.getString('VALIDATION_EMAIL_CHANGE_ERROR', 'Uh Oh!  Unable to validate Email Change.')} </Message.Header>
                  {strings.getString("VALIDATION_ERROR", 'There was an issue when attempting to validate your account.')}
                </Message.Content>
              </Message>
            ) : ''}
          </Container>
        </Page>
      </div>
    )
  }
}

export default connect(mapStateToProps)(ValidateEmail)
