import React from 'react'
import Label from '../../Fragments/Label'
import GuildCrest from '../GuildCrest'
import './styles.scss'

const GuildDetail = (props) => {
  const { displayName, name, crest } = props
  return (
    <div className="GuildDetail">

      {crest && (<GuildCrest crest={crest} small/>)}

      <div className="GuildDetail__info">
        <div className="GuildDetail__names">
          <div className="GuildDetail__displayName"><Label label={displayName}/></div>
          <div className="GuildDetail__name"><Label label={`/ ${name}`}/></div>
        </div>

      </div>
    </div>
  )
}

export default GuildDetail
