/*

FRAGMENT: TitleBox

PROPERTY  DEFAULT  REQUIREMENT  TYPE     DESCRIPTION
title              Required     String   label centered within the box
h3        false    Optional     Boolean  decreases font size

*/

import React from 'react'
import './styles.scss'


const TitleBlur = (props) => {

    const { title, h3 } = props
    let className = "TitleBlur"
    if (h3) className += " TitleBlur"

    return (
        <div className={className}>
            <div className="TitleBlur__title">
                {title}
                <div className="TitleBlur__blur"/>
            </div>
        </div>
    )
}

export default TitleBlur