import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader, Form, Select, Button, Message } from 'semantic-ui-react'
import _ from 'lodash'
import swal from '@sweetalert/with-react'

import { fetchCountryMap } from '../../redux/actions'
import { fetchUser, updateUserProfile } from '../../redux/actions/user'
import './styles.scss'
import strings from '../../strings'


const mapStateToProps = state => {
    return {
        user: state.user.userProfile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUser: () => dispatch(fetchUser()),
        updateUserProfile: (user) => dispatch(updateUserProfile(user)),
        fetchCountryMap: () => dispatch(fetchCountryMap()),
    }
}

class AccountShippingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            countryOptions: [],
            warning: false,

            addressFetched: false,
            userAddress: {
                first_name: null,
                last_name: null,
                address: null,
                extended_address: null,
                city: null,
                state: null,
                country_iso: null,
                postal_code: null,
            }
        }
        this.submitAddress = this.submitAddress.bind(this)
    }

    async componentDidMount() {
        await this.fetchCountryOptions()
        this.setUserLocationData()
    }

    async fetchCountryOptions() {
        const countryMap = await this.props.fetchCountryMap()
        const countryOptions = !_.isEmpty(countryMap) ? Object.keys(countryMap).map((country, index) => {
            return {
                key: `${countryMap[country].country_iso_code}-${index}`,
                text: `${countryMap[country].country_name} (${countryMap[country].country_iso_code})`,
                value: countryMap[country].country_iso_code,
            }
        }) : null
        this.setState({ countryOptions })
    }

    setUserLocationData() {
        const { first_name, last_name, address, extended_address, city, state, country_iso, postal_code } = this.props.user
        this.setState({ 
            addressFetched: true, 
            userAddress: { first_name, last_name, address, extended_address, city, state, country_iso, postal_code }
        })
    }

    verifyIncompleteAddress() {
        const { addressFetched, userAddress } = this.state
        const { first_name, last_name, address, city, state, country_iso, postal_code } = userAddress
        const isIncomplete = !(!!first_name && !!last_name && !!address && !!city && !!state && !!country_iso && !!postal_code)
        return addressFetched && isIncomplete
    }

    updateFieldInput = (e, { name, value } = {}) => {
        let updatedAddress = { ...this.state.userAddress }
        updatedAddress[name] = value
        this.setState({ userAddress: updatedAddress })
    }

    async submitAddress() {
        this.setState({ loading: true })

        const { userAddress } = this.state
        try {
            await this.props.updateUserProfile(userAddress)
            swal({
                icon: 'success',
                text: strings.getString("UPDATE_SHIPPING_SUCCESS", "Successfully updated Shipping Address.")
            })
            this.props.fetchUser()
        } catch (err) {
            swal({
                icon: 'error',
                text: err.response.data.message
            })
        }

        this.setState({ loading: false })
    }

    render() {

        const { loading, countryOptions, userAddress, } = this.state
        const { first_name, last_name, address, extended_address, city, state, country_iso, postal_code } = userAddress

        return (
            <div>
                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>

                <h1>{strings.getString("SHIPPING_ADDRESS", "Shipping Address")}</h1>
                <p>{strings.getString("SHIPPING_FOR_COLLECTORS", "The following shipping address will be used when your Crowfall Collector's Edition purchase is ready to be shipped.")}</p>
                <p>{strings.getString("ENSURE_SHIPPING_IS_ACCURATE", "Please ensure that your shipping address is accurate and up-to-date.")}</p>
                <br/>
                <Form onSubmit={this.submitAddress} warning={this.verifyIncompleteAddress()}>

                    <Message warning 
                        header={strings.getString("ACCOUNT_ADDRESS_INCOMPLETE", "The Address provided appears incomplete.")}
                        content={strings.getString("ACCOUNT_ADDRESS_HELPER_TEXT", "Please ensure that all applicable/required fields are filled in.")}
                    />

                    <Form.Field>
                        <Form.Input label={strings.getString("FIRST_NAME", "First Name")} placeholder={strings.getString("FIRST_NAME", "First Name")}
                                    name="first_name" autoComplete="off" type="text" value={first_name || ""} onChange={this.updateFieldInput}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label={strings.getString("LAST_NAME", "Last Name")} placeholder={strings.getString("LAST_NAME", "Last Name")}
                                    name="last_name" autoComplete="off" type="text" value={last_name || ""} onChange={this.updateFieldInput}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label={strings.getString("ADDRESS_LINE_1", "Address Line 1")} placeholder={strings.getString("ADDRESS_LINE_1", "Address Line 1")}
                                    name="address" autoComplete="off" type="text" value={address || ""} onChange={this.updateFieldInput}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label={strings.getString("ADDRESS_LINE_2_OPTIONAL", "Address Line 2 (optional)")} placeholder={strings.getString("ADDRESS_LINE_2", "Address Line 2")}
                                    name="extended_address" autoComplete="off" type="text" value={extended_address || ""} onChange={this.updateFieldInput}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label={strings.getString("CITY", "City")} placeholder={strings.getString("CITY", "City")}
                                    name="city" autoComplete="off" type="text" value={city || ""} onChange={this.updateFieldInput}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label={strings.getString("STATE_REGION", "State/Region")} placeholder={strings.getString("STATE_REGION", "State/Region")}
                                    name="state" autoComplete="off" type="text" value={state || ""} onChange={this.updateFieldInput}/>
                    </Form.Field>
                    <Form.Field>
                        <label>{strings.getString("COUNTRY", "Country")}</label>
                        <Select search placeholder={strings.getString("COUNTRY", "Country")} autoComplete="off"
                                name="country_iso" value={country_iso || ""} onChange={this.updateFieldInput} options={countryOptions}/>
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label={strings.getString("POSTAL_CODE", "Postal Code")} placeholder={strings.getString("POSTAL_CODE", "Postal Code")}
                                    name="postal_code" autoComplete="off" type="text" value={postal_code || ""} onChange={this.updateFieldInput}/>
                    </Form.Field>

                    <Button className="Button__ACE" type="submit" primary>
                        {strings.getString("UPDATE_SHIPPING_ADDRESS", "Update Shipping Address")}
                    </Button>

                </Form>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountShippingPage)