import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'

const ShippingPolicy = () => (
  <Page contentId={assets.contentfulIds.SHIPPING_POLICY}>
  </Page>
)

export default ShippingPolicy

