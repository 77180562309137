import React from 'react'
import _ from 'lodash'
import { api } from '../../../utilities/api'
import contentful from '../../../utilities/contentful'
import strings from '../../../strings'
import Page from '../../../componentLibrary/Models/Page'
import './styles.scss'


class DecapathonPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            promo: {},
            factionKills: []
        }
    }

    componentDidMount() {
        this.getContent()
    }

    async getContent() {
        const promos = await contentful.getEntries('promoDecapathon')
        const promo = promos[0] ? promos[0] : {}
        promo.bannerAssetUrl = _.result(promo, 'bannerAsset.assetUrl')
        promo.sunBannerUrl = _.result(promo, 'sunBanner.assetUrl')
        promo.sunSkullUrl = _.result(promo, 'sunSkull.assetUrl')
        promo.earthBannerUrl = _.result(promo, 'earthBanner.assetUrl')
        promo.earthSkullUrl = _.result(promo, 'earthSkull.assetUrl')
        promo.moonBannerUrl = _.result(promo, 'moonBanner.assetUrl')
        promo.moonSkullUrl = _.result(promo, 'moonSkull.assetUrl')
        const kills = await api.get(
            '/events/sacrifice/aggregate'
        )
        const factionKills = kills.data
        this.setState({ promo, factionKills })
    }

    render() {

        const { promo, factionKills } = this.state
        const { pageTitle, promoTitle, bannerAssetUrl, tagline, pageContent, sunBannerUrl, sunSkullUrl, earthBannerUrl, earthSkullUrl, moonBannerUrl, moonSkullUrl } = promo
        const title = pageTitle ? pageTitle : promoTitle

        const orderKills = factionKills.length > 0 ? _.find(factionKills, ['faction', 'Sun']).count.toLocaleString() : 0
        const balanceKills = factionKills.length > 0 ? _.find(factionKills, ['faction', 'Earth']).count.toLocaleString() : 0
        const chaosKills = factionKills.length > 0 ? _.find(factionKills, ['faction', 'Moon']).count.toLocaleString() : 0

        return (
            <Page header={{ title, banner: bannerAssetUrl }}>
                <div className='DecapathonPage'>
                    {tagline && <div className="DecapathonPage__tagline" dangerouslySetInnerHTML={{ __html: tagline }}/>}

                    <div className="DecapathonPage__banners">
                        <div className='DecapathonPage__banner' style={{ backgroundImage: `url(${sunBannerUrl})`}}>
                            <div className='DecapathonPage__bannerTitle'>{strings.getString('SUN', 'Sun').toUpperCase()}</div>
                            <div className='DecapathonPage__kills'>{orderKills}%</div>
                            <img className='DecapathonPage__skull' src={sunSkullUrl} alt='Sun Faction Skull'/>
                        </div>
                        <div className='DecapathonPage__banner' style={{ backgroundImage: `url(${earthBannerUrl})`}}>
                            <div className='DecapathonPage__bannerTitle'>{strings.getString('EARTH', 'Earth').toUpperCase()}</div>
                            <div className='DecapathonPage__kills'>{balanceKills}%</div>
                            <img className='DecapathonPage__skull' src={earthSkullUrl} alt='Eath Faction Skull'/>
                        </div>
                        <div className='DecapathonPage__banner' style={{ backgroundImage: `url(${moonBannerUrl})`}}>
                            <div className='DecapathonPage__bannerTitle'>{strings.getString('MOON', 'Moon').toUpperCase()}</div>
                            <div className='DecapathonPage__kills'>{chaosKills}%</div>
                            <img className='DecapathonPage__skull' src={moonSkullUrl} alt='Moon Faction Skull'/>
                        </div>
                    </div>

                    {pageContent && <div className="DecapathonPage__content" dangerouslySetInnerHTML={{ __html: pageContent }}/>}
                </div>
            </Page>
        )
    }
}

export default DecapathonPage
