import {
    ADD_PROMOTIONS,
    PAGINATE_PROMOTIONS,
    CLEAR_PROMOTIONS,
    SET_SELECTED_PROMOTION,
    CLEAR_SELECTED_PROMOTION,
} from '../actionTypes'


const initialState = {
    all: [],
    featured: [],
    archived: [], 
    paginationCriteria: {
        limit: 10,
        page: 1,
    },
    paginated: [],

    selected: {},
}

const promotions = ( state = initialState, action ) => {
    switch (action.type) {
        case ADD_PROMOTIONS:
            const grouped = groupPromosByFeatured(action.payload)
            return {
                ...state,
                all: [ ...state.all, ...action.payload ],
                featured: [ ...state.featured, ...grouped.featured ],
                archived: [ ...state.archived, ...grouped.archived ]
            }
        case PAGINATE_PROMOTIONS:
            const limit = state.paginationCriteria.limit
            const page = action.payload ? action.payload : state.paginationCriteria.page
            const paginated = paginate(state.archived, limit, page)
            return {
                ...state,
                paginationCriteria: {
                    limit,
                    page
                },
                paginated
            }
        case CLEAR_PROMOTIONS:
            return {
                ...state,
                all: [],
                featured: [],
                archived: [],
                paginationCriteria: {
                    limit: 10,
                    page: 1,
                },
                paginated: [],
            }
        case SET_SELECTED_PROMOTION:
            return {
                ...state,
                selected: action.payload
            }
        case CLEAR_SELECTED_PROMOTION:
            return {
                ...state,
                selected: {},
            }
        default:
            return state
    }
}

function paginate(list, limit, page) {
    if (limit === null) return list
    const limitInt = parseInt(limit)
    const start = limitInt * (page - 1)
    const end = start + limitInt
    return list.slice(start, end)
}

function groupPromosByFeatured(promos) {
    let featured = []
    let archived = []
    promos.forEach(promo => {
        if (promo.isFeatured === true) {
            featured.push(promo)
        } else {
            archived.push(promo)
        }
    })
    return { featured, archived }
}

export default promotions