import React from 'react'
import './styles.scss'

const TeamAsset = (props) => {

    const { name, asset } = props

    return (
        <div className="TeamAsset">
            <img className="TeamAsset__crest" src={asset} alt={`${name} Team Crest`}/>
            <div className="TeamAsset__name">{name}</div>
        </div>
    )
}

export default TeamAsset