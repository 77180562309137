import React from 'react'
import GoldBar from '../../../componentLibrary/Fragments/GoldBar'
import './styles.scss'

const DisplayTable = (props) => {
    
    const { columns, columnThemes, content, } = props

    let horizontalGridClassName = ` DisplayTable__grid--${columns.length}`

    const horizontalTableLabels = (
        <div className={`DisplayTableHorizontal__labels` + horizontalGridClassName} key={"DisplayTableHorizontal__labels"}>{columns.map((column, index) => {
            const { label } = column
            return (
                <div className="DisplayTableHorizontal__label" key={`DisplayTableLabel-${label ? label : "Empty-"}${index}`}>{label}</div>
            )
        })}</div>
    )

    const horizontalTableContent = content.map((section, sectionIndex) => {
        const { id: sectionId, rows } = section
        let sectionClassName = "DisplayTableHorizontal__section"
        return (
            <div className={sectionClassName} key={`DisplayTableHorizontal-section${sectionIndex}-${sectionId}`}>
                {rows.map((row, rowIndex) => {
                    const { id: rowId, elements, isSubRow } = row
                    let rowClassName = "DisplayTableHorizontal__row" + horizontalGridClassName
                    return (
                        <div className={rowClassName} key={`DisplayTableHorizontal-row${rowIndex}-${rowId}`}>
                            {elements.map((element, elementIndex) => {
                                let elementClassName = "DisplayTableHorizontal__element"
                                if (columnThemes[elementIndex]) elementClassName += ` DisplayTableHorizontal__element--${columnThemes[elementIndex]}`
                                if (elementIndex === 0) {
                                    if (!isSubRow) elementClassName += " DisplayTableHorizontal__rowHeader"
                                    if (isSubRow) elementClassName += " DisplayTableHorizontal__subRowHeader"
                                }
                                return (
                                    <div className={elementClassName} key={`DisplayTableHorizontal-row${rowId}-element${elementIndex}}`}>{element}</div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    })


    return (
            <div className="DisplayTableHorizontal">
                {horizontalTableLabels}
                <div className="DisplayTableHorizontal__tableContent">
                    <div className="DisplayTable__topDivider"><GoldBar h3/></div>
                    {horizontalTableContent}
                    <div className="DisplayTable__bottomDivider"><GoldBar h3/></div>
                </div>
            </div>
    )
}

export default DisplayTable