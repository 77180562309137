import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import _ from 'lodash'
import assets from '../../../assets'
import strings from '../../../strings'
import contentful from '../../../utilities/contentful'
import Page from '../../../componentLibrary/Models/Page'
import RegionBlock from '../../../componentLibrary/Models/RegionBlock'
import DisplayTable from '../../../componentLibrary/Elements/DisplayTable'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
// import ProductCard from '../../../Store/ShopPage/ProductCard'
import { fetchStoreDetails } from '../../../redux/actions/crowfall-store'
import './styles.scss'


const mapStateToProps = (state) => {
    const { user } = state
    return {
        session: state.session,
        language: state.language,
        catalog: state.crowfallStore.catalog,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchStoreDetails: (session, language) => dispatch(fetchStoreDetails(session, language)),
    }
}

class VipPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            bannerAssetUrl: null,
            title: null,
            tagline: null,
            packsBodyContent: null,
            termsConditionsContent: null,
            vipCatalog: [],
            benefitsTableContent: [],
        }
        this.getVipPacks = this.getVipPacks.bind(this)
    }

    async componentDidMount() {
        this.setState({ loading: true })
        await this.getPageContent()
        await this.getVipPacks()
        await this.getVipBenefitsTableContent()
        this.setState({ loading: false })
    }

    async getPageContent() {
        const page = await contentful.getEntry(assets.contentfulIds.VIP_LANDER)
        this.setState({
            bannerAssetUrl: _.result(page, 'banner.assetUrl'),
            title: page.pageTitle,
            tagline: page.tagline,
            packsBodyContent: page.pageHeader,
            termsConditionsContent: page.pageFooter 
        })
    }

    async getVipPacks() {
        await this.props.fetchStoreDetails(this.props.session, this.props.language)
        const vipCatalog = this.props.catalog.filter(product => product.categorySet.includes("VIP"))
        this.setState({ vipCatalog })
    }

    async getVipBenefitsTableContent() {
        const benefitTypes = await contentful.getEntries("vipMemberBenefitType")
        this.modelVipTableRows(_.orderBy(benefitTypes, ["displayWeight"]))
    }

    modelVipTableRows(benefitTypes) {
        const benefitsTableContent = benefitTypes.map(type => {
            const { contentful_id: typeId, title: typeTitle, vipBenefits } = type
            let benefitTypeRow = {
                id: `row--${typeId}`,
                elements: [ <span>{typeTitle}</span>, null, null]
            }
            const benefitRows = vipBenefits.map(benefit => ({
                id: `${typeId}--${benefit.contentful_id}`,
                isSubRow: true,
                elements: [ 
                    <span>{benefit.title}</span>,
                    <div dangerouslySetInnerHTML={{ __html: benefit.nonMemberDescription }}/>,
                    <div dangerouslySetInnerHTML={{ __html: benefit.vipMemberDescription }}/>,
                ]
            }))
            return {
                id: typeId,
                rows: [ benefitTypeRow, ...benefitRows ],
            }
        })
        this.setState({ benefitsTableContent })
    }

    render() {
        const { user } = this.props
        const { loading, bannerAssetUrl, title, tagline, packsBodyContent, termsConditionsContent, benefitsTableContent } = this.state
        const tableColumns = [
            {},
            {label: strings.getString("NON-MEMBER", "Non-Member").toUpperCase()},
            {label: strings.getString("CROWFALL_VIP_MEMBER", "Crowfall VIP Member").toUpperCase()} 
        ]
        const playerData = _.result(user, 'userProfile.player_data', null)
        const isTrial = _.result(playerData, 'type') === 'trial'
        
        let gamePackUrl
        if(isTrial) {
            gamePackUrl = '/store?category=064b6196-98fe-46c7-94d4-9485dad599de'
        } else {
            gamePackUrl = '/store?category=c70700fd-75df-4ea5-ab1c-e05eec3c6b1a'
        }

        return (
            <Page header={{ title, banner: bannerAssetUrl }}>

                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>

                <div className="VipPage">
                    <div className="VipPage__tagline">
                        <img src={assets.staticImageUrls.VIP_LOGO} className="VipPage__logo" alt="VIP Logo"/>
                        <div dangerouslySetInnerHTML={{ __html: tagline }} />
                    </div>

                    {/*<div className="VipPage__header">*/}
                    {/*    <img src={assets.staticImageUrls.VIP_ICON_GOLD} className="VipPage__icon" alt="VIP Icon"/>*/}
                    {/*    {strings.getString("VIP_MEMBERSHIP_PACKS", "VIP Membership Packs").toUpperCase()}*/}
                    {/*    <img src={assets.staticImageUrls.VIP_ICON_GOLD} className="VipPage__icon" alt="VIP Icon"/>*/}
                    {/*</div>*/}
                        {isTrial && <p><span className="Vip_Page__header_trial" dangerouslySetInnerHTML={{ __html:strings.getString("VIP_NOT_PURCHASABLE", "VIP is only purchasable when owning a Crowfall game pack. Select a VIP below will direct to the Crowfall game packs.") }} /></p> }
                        {/* {vipCatalog.map(product => (
                            <ProductCard product={product} key={`VIP-Product-${product.item_id}`}/>
                        ))} */}

                        {/*<RegionBlock artcraft>*/}
                        {/*    <div className="VipPage__packs">*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_12_MONTH_USD} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_6_MONTH_USD} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_3_MONTH_USD} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_1_MONTH_USD} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*    </div>*/}
                        {/*</RegionBlock>*/}
                        {/*<RegionBlock uk>*/}
                        {/*    <div className="VipPage__packs">*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_12_MONTH_GBP} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_6_MONTH_GBP} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_3_MONTH_GBP} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_1_MONTH_GBP} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*    </div>*/}
                        {/*</RegionBlock>*/}
                        {/*<RegionBlock eu>*/}
                        {/*    <div className="VipPage__packs">*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_12_MONTH_EUR} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_6_MONTH_EUR} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_3_MONTH_EUR} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_1_MONTH_EUR} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*    </div>*/}
                        {/*</RegionBlock>*/}
                        {/*<RegionBlock brazil>*/}
                        {/*    <div className="VipPage__packs">*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_12_MONTH_BRL} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_6_MONTH_BRL} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_3_MONTH_BRL} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*        <LocLink to={gamePackUrl}>*/}
                        {/*            <img className="VipPage__packCard" src={assets.staticImageUrls.VIP_1_MONTH_BRL} alt="VIP STore Product"></img>*/}
                        {/*        </LocLink>*/}
                        {/*    </div>*/}
                        {/*</RegionBlock>*/}

                    <div className="VipPage__packsContent" dangerouslySetInnerHTML={{ __html: packsBodyContent }}/>

                    <div className="VipPage__header">
                        <img src={assets.staticImageUrls.VIP_ICON_GOLD} className="VipPage__icon" alt="VIP Icon"/>
                        <span>{strings.getString("VIP_MEMBER_BENEFITS", "VIP Member Benefits",).toUpperCase()}</span>
                        <img src={assets.staticImageUrls.VIP_ICON_GOLD} className="VipPage__icon" alt="VIP Icon"/>
                    </div>
                    <div className="VipPage__table">
                        <DisplayTable columns={tableColumns} columnThemes={[ "gray", "lightGray", "gold" ]} content={benefitsTableContent}/>
                    </div>

                    {/*<div className="VipPage__buttons">*/}
                    {/*    <LocLink to="/store?category=c70700fd-75df-4ea5-ab1c-e05eec3c6b1a">*/}
                    {/*        <Button green label={strings.getString("PURCHASE_VIP_NOW", "Purchase VIP Now").toUpperCase()}/>*/}
                    {/*    </LocLink>*/}
                    {/*</div>*/}

                    <div className="VipPage__footer" dangerouslySetInnerHTML={{ __html: termsConditionsContent }}/>
                </div>
            </Page>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VipPage)