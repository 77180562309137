import React from 'react'
import _ from 'lodash'
import { Form } from 'semantic-ui-react'
import strings from '../../../strings'
import Button from '../../../componentLibrary/Fragments/Button'
import './styles.scss'


class GiftToModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            isAGameCopy: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const { reward } = this.props
        const isAGameCopy = _.result(reward, "id") === "KS_23"
        this.setState({ isAGameCopy })
    }

    async handleSubmit(reward) {
        this.setState({ loading: true })
        await this.props.handleSendGift(reward)
        this.setState({ loading: false })
    }


    render() {
        const { reward, updateField, handleCancelGift } = this.props
        const { loading, isAGameCopy } = this.state
    
        return (
            <div className="GiftToModal">
                <div className="GiftToModal__header">{strings.getString("SEND_GIFT", "Send Gift")}</div>
    
                <p>
                    {strings.getString('ACCOUNT_GIFTS_INFO', 'Choose the account name of the account you wish to send gift to. A user will not be able to send the gift until they enable 2 Factor Authentication (2FA).')}
                </p>
                {isAGameCopy && <p>
                    {strings.getString("ACCOUNT_GIFTS_PACKS_WARNING", "Note: if you gift your only Starter Pack, you will lose game access.")}
                </p>}
    
                <Form>
                    <Form.Input autoComplete="off" type="number" min="1" max={reward.count} placeholder={strings.getString('QUANTITY', 'Quantity')} name='quantity'
                                onChange={updateField}/>
                    <Form.Input autoComplete="off" type="text" placeholder={strings.getString("ACCOUNT_NAME", 'Account Name')} name='targetUsername'
                                onChange={updateField}/>
                </Form>
    
                <div className="GiftToModal__buttons">
                    <div className="GiftToModal__button"><Button medium loading={loading} gray label={strings.getString("CANCEL", "Cancel")} onclick={handleCancelGift}/></div>
                    <div className="GiftToModal__button"><Button medium loading={loading} label={strings.getString("SEND", "Send")} onclick={() => this.handleSubmit(reward)}/></div>
                </div>
            </div>
        )
    }
}

export default GiftToModal