import React from 'react'
import { connect } from 'react-redux'
import MainMenu from './MainMenu'
import UserMenu from './UserMenu'
import _ from 'lodash'
import moment from 'moment';
import LocLink from '../../../componentLibrary/Elements/LocLink'
import Button from '../../../componentLibrary/Fragments/Button'
import assets from '../../../assets'
import strings from '../../../strings'
import { closeMobileNav, openMobileNav } from '../../../redux/actions'
import './styles.scss'
import { withRouter } from 'react-router-dom'
import swal from "@sweetalert/with-react";
import {api} from "../../../utilities/api";


const mapStateToProps = (state) => {
  const { openNav, language, session, cohorts, user } = state
  return {
    openNav,
    language,
    user,
    session,
    isLoggedIn: !!session,
    canPlayCrowfall: !!cohorts.can_play_crowfall,
    isPaidAccount: !!cohorts.paid_account,
    isTrialExpired: !!cohorts.game_trial_expired
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openMobileNav: () => dispatch(openMobileNav()),
    closeMobileNav: () => dispatch(closeMobileNav())
  }
}

class MainNav extends React.Component {
  constructor(props) {
    super(props)
    this.element = React.createRef()

    this.handleAppClick = this.handleAppClick.bind(this)
    this.activate = this.activate.bind(this)
    this.deactivate = this.deactivate.bind(this)
    this.toggleActive = this.toggleActive.bind(this)
  }

  handleAppClick(event) {
    if (event.path.indexOf(this.element.current) < 0) this.deactivate()
  }

  activate() {
    const { openMobileNav } = this.props
    openMobileNav()
    document.getElementById('root').addEventListener('mousedown', this.handleAppClick, true)
  }

  deactivate() {
    const { closeMobileNav } = this.props
    closeMobileNav()
    document.getElementById('root').removeEventListener('mousedown', this.handleAppClick, true)
  }

  toggleActive() {
    const { openNav } = this.props
    if (openNav) {
      this.deactivate()
    } else {
      this.activate()
    }
  }

  async handleTrialPopup() {
    try {
      const val = await swal({
        icon: "warning",
        title: strings.getString('TRIAL_ACTIVATE_TITLE', 'Activate Free Trial'),
        content: (
            <div>
              {strings.getString('TRIAL_ACTIVATE', 'Activate your free 10 day trial now?')},
            </div>
        ),
        buttons: {
          continue: 'Yes',
          cancel: 'No'
        }
      })
      if (val === "continue") {
        await api.post('/ks/artcraft')
        swal({
          icon:'success',
          title: strings.getString('TRIAL_HAS_BEEN_ACTIVATED', 'Your 10 day trial has been activated!')
        }).then(okay => {
          if (okay) {
            window.location.href = "/account/download-client"
          }
        })
      }
    } catch(err) {
      swal({
            icon: 'error',
            title: strings.getString('TRIAL_ACTIVATION_ERROR_OCCURRED', 'Error Activating Trial')
          }
      )
    }
  }

  render() {
    const { openNav, session, isLoggedIn, canPlayCrowfall, user, isTrialExpired } = this.props
    const playerData = _.result(user, 'userProfile.player_data', null);
    const isTrial = _.result(playerData, 'type') === 'trial';
    const trialRemainingCalc = isTrial ? moment.duration(playerData.time_remaining_seconds, 'seconds').humanize() : '';
    const trialRemaining = `${trialRemainingCalc} remaining in your Trial!`;
    const shouldCode = _.result(playerData, 'type') === 'unknown';
    
    let className = 'MainNav'
    let playNowText
    let playNowLink
    let tryPlayButton
    if (canPlayCrowfall) {
      playNowLink = "/account/download-client"
      playNowText = strings.getString("PLAY_NOW", "Play Now")
      tryPlayButton = <LocLink to={playNowLink}>
                      <Button fill green>
                        <div className="MainNav__buttonLabel">{playNowText}</div>
                      </Button>
                    </LocLink>
    } else if (!canPlayCrowfall && !isTrial && !isTrialExpired) {
      // playNowLink = "/account/redeem-codes?code=artcraft"
      // playNowText = strings.getString("TRY_FREE", "Try Free")
      // tryPlayButton = <div className="MainNav__buttonLabel" onClick={()=>this.handleTrialPopup()}>
      //                   <Button medium fill green label={playNowText} />
      //                 </div>
    } else {
      playNowLink = "/store?category=064b6196-98fe-46c7-94d4-9485dad599de&record-to-liquid=true"
      playNowText = strings.getString("PLAY_NOW", "Play Now")
      tryPlayButton = <LocLink to={playNowLink}>
                      <Button fill green>
                        <div className="MainNav__buttonLabel">{playNowText}</div>
                      </Button>
                    </LocLink>
    }

    if (openNav) className += ' MainNav--active'
    return (
      <div className={className} ref={this.element}>
        <div className="MainNav__content">

          <div className="MainNav__mobileOverlay" >
            <div className="MainNav__overlayButton" onClick={this.toggleActive}>
              <div></div>
              <div></div>
              <div></div>
            </div>


              <div className="MainNav__overlayLogo">
                <LocLink to="/">
                  <img alt="crowfall_logo" src={assets.staticImageUrls.LOGO_CROWFALL}/>
                </LocLink>
              </div>
          </div>

          <LocLink to="/">
            <div className="MainNav__logo">
              <img alt="crowfall_logo" src={assets.staticImageUrls.LOGO_CROWFALL}/>
            </div>
          </LocLink>

          <div className="MainNav__menu">
            <MainMenu />
          </div>



          <div className="MainNav__buttons">

            <div className="MainNav__button">
                {/*{isLoggedIn && tryPlayButton}*/}
            </div>

            <div className="MainNav__menu">
              <UserMenu />
            </div>

            {/*{isTrial ? (*/}
            {/*<div className="MainNav__trial">*/}
            {/*  <LocLink to={isLoggedIn && playNowLink}>{trialRemaining}</LocLink>*/}
            {/*</div>*/}
            {/*) : null}*/}

          </div>

        </div>

      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNav))
