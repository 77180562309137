import React from 'react';
import {Button, Dimmer, Form, Loader, Message, Table} from 'semantic-ui-react';
import { connect } from 'react-redux';
import FixedTable from '../componentLibrary/Elements/FixedTable';
import strings from '../strings';
import _ from 'lodash';
import { affiliateApi } from '../utilities/api';
import swal from '@sweetalert/with-react';
import LocLink from '../componentLibrary/Elements/LocLink';
import {Redirect} from "react-router-dom";


const mapStateToProps = state => {
    return {
        affiliateDetails: state.affiliateDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

class AccountDetailsAffiliateDetails extends React.Component {

    state = {
        paymentProfileEmail: '',
        loading: false,
        discounts: [],
    };

    async componentDidMount() {
        this.setState({loading: true});
        this.setState({loading: false});
    }

    handlePayoutEmailChange = (e, { value } = {}) => {
        this.setState({
            paymentProfileEmail: value
        })
    }

    saveProfile = async () => {
        try {
            await affiliateApi.put('/user/payout-profile', {
                affiliateId: this.props.affiliateDetails.affiliateId,
                email: this.state.paymentProfileEmail
            });
            swal(`Successfully Updated Payout Profile`, {
                icon: 'success',
            })
        } catch {
            swal(`Uh Oh! Unable to Update Payout Profile`, {
                icon: 'error',
            })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.affiliateDetails.payoutProfile && this.props.affiliateDetails.payoutProfile.email && this.state.paymentProfileEmail === '')
        {
            this.setState({
                paymentProfileEmail: this.props.affiliateDetails.payoutProfile.email
            })
        }
    }

    render() {

        const {loading} = this.state;
        const details = this.props.affiliateDetails;

        return (
            <div>
                <Redirect to="/account/profile" />
                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>
                <h1>{strings.getString('AFFILIATE_DETAILS', 'Affiliate Details')}</h1>

                {details.affiliateId ? (
                    <React.Fragment>

                        <Message info header="Affiliate Code" content={`Your personal code is: ${details.code}. This code should be used by all your users.`}/>

                        <FixedTable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={2}><span style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}>Summary</span></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Affiliate Name</Table.Cell>
                                    <Table.Cell>{details.name}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Affiliate ID</Table.Cell>
                                    <Table.Cell>{details.affiliateId}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Status</Table.Cell>
                                    <Table.Cell>{_.startCase(details.status.toLowerCase())}</Table.Cell>
                                </Table.Row>

                                <Table.Row style={{backgroundColor: '#f9fafb'}}>
                                    <Table.Cell colSpan={2}><span
                                        style={{display: 'flex', justifyContent: 'space-between'}}><strong>Program Details</strong></span></Table.Cell>
                                </Table.Row>


                                <Table.Row>
                                    <Table.Cell>Program Name</Table.Cell>
                                    <Table.Cell>{details.programName}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Program Description</Table.Cell>
                                    <Table.Cell>{details.programDescription}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Program Status</Table.Cell>
                                    <Table.Cell>{_.startCase(details.programStatus.toLowerCase())}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Program Type</Table.Cell>
                                    <Table.Cell>{details.programType}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Program Expiration</Table.Cell>
                                    <Table.Cell>{details.expirationDate ? details.expirationDate : 'Not Set'}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Program Code</Table.Cell>
                                    <Table.Cell>{details.code}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>New User Link</Table.Cell>
                                    <Table.Cell>https://crowfall.com/join/{details.code}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Existing User Link</Table.Cell>
                                    <Table.Cell>https://crowfall.com/code/{details.code}</Table.Cell>
                                </Table.Row>


                                <Table.Row>
                                    <Table.Cell>Code Description</Table.Cell>
                                    <Table.Cell>{details.codeProgramDescription}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Player Exclusivity Term</Table.Cell>
                                    <Table.Cell>{details.playerExclusivityTermSec / 60 / 60 / 24} Days</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Program Max Usages</Table.Cell>
                                    <Table.Cell>{details.codeProgramMaxUsages === 99999 ? 'None' : details.codeProgramMaxUsages || 0}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Minimum Spend Required</Table.Cell>
                                    <Table.Cell>{details.minSpend}</Table.Cell>
                                </Table.Row>

                                <Table.Row style={{backgroundColor: '#f9fafb'}}>
                                    <Table.Cell colSpan={2}><span style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}><strong>Performance</strong></span></Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Code Usages</Table.Cell>
                                    <Table.Cell>{details.usedCount}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </FixedTable>


                <h4>Paypal Payout Profile</h4>
                <p>All Payout Amounts will be sent to the following Email Address. Please ensure you have correctly entered your Paypal Email Address. All Payout amounts are in USD unless otherwise stated.</p>
                <Form onSubmit={this.saveProfile.bind(this)}>
                    <Form.Input value={this.state.paymentProfileEmail} label="Paypal Email Address" placeholder="Paypal Email Address" autoComplete="off" name='email' type='email' onChange={this.handlePayoutEmailChange.bind(this)}/>

                    <Button className="Button__ACE" type='submit' primary>{strings.getString('UPDATE_PROFILE', 'update profile')}</Button>
                </Form>

                <h4>Monthly Invoice Summary</h4>
                <p>All amounts are in USD unless otherwise stated.</p>
                <FixedTable padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Invoice Month</Table.HeaderCell>
                            <Table.HeaderCell>Payout Amount</Table.HeaderCell>
                            <Table.HeaderCell>Total Buyer Spend</Table.HeaderCell>
                            <Table.HeaderCell>Total Transactions</Table.HeaderCell>
                            <Table.HeaderCell>Invoice Status</Table.HeaderCell>
                            <Table.HeaderCell>Paypal ID</Table.HeaderCell>
                            <Table.HeaderCell>Paypal Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {(details.monthlySummary || []).map(r => (
                            <Table.Row key={r.invoice_month}>
                                <Table.Cell>{r.invoice_month}</Table.Cell>
                                <Table.Cell>${r.payout_amount}</Table.Cell>
                                <Table.Cell>${r.txn_amount}</Table.Cell>
                                <Table.Cell>{r.txn_count}</Table.Cell>
                                <Table.Cell>{_.startCase(r.invoice_status.toLowerCase())}</Table.Cell>
                                <Table.Cell>{r.external_payout_id ? r.external_payout_id : '-'}</Table.Cell>
                                <Table.Cell>{r.external_payout_status ? r.external_payout_status : 'Pending'}</Table.Cell>
                            </Table.Row>
                        ))}

                    </Table.Body>
                </FixedTable>
                    </React.Fragment>) : (
                    <React.Fragment>
                        <p>You are currently not an Affiliate. Please submit an <LocLink to="/influencer">Application</LocLink> to be be considered for the affiliate program.</p>
                        <p>Note: Ad Blockers may prevent your Affiliate information from appearing correctly. You may need to pause or disable your Ad Blocker in order to review your Affiliate Details.</p>
                    </React.Fragment>
                )}


            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsAffiliateDetails);
