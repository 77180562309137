import React from 'react'
import Page from '../../../componentLibrary/Models/Page'
import assets from '../../../assets'
import ContentBox from '../../../componentLibrary/Elements/ContentBox'
import TitleBar from '../../../componentLibrary/Fragments/TitleBar'
import LocLink from '../../../componentLibrary/Elements/LocLink'
import contentful from '../../../utilities/contentful'
import strings from '../../../strings'
import _ from 'lodash'
import './styles.scss'


class FaqQuestionPage extends React.Component {

    state = {
        entry: {},
        url: '',
    }

    async componentDidMount() {
        await this.getContent()
        this.setState({ url: this.props.location.pathname })
    }

    async getContent() {
        const className = _.result(this.props, 'match.params.questionId')
        const entry = (await contentful.getEntries('faq', `sys.id[match]=${className}`)).map(e => {
            return e
        })[0]
        this.setState({entry})
    }

    render() {

        const description = this.state.entry.description || '' 
        const question = this.state.entry.question || '' 

        return (
            <Page addAccent header={{banner: assets.staticImageUrls.FAQ_PAGE_HEADER}}>
                <div className='FaqQuestionPage'>
                    <div className='FaqQuestionPage__backButton'>
                        <LocLink to='/faq'>
                            <div className='FaqQuestionPage__backButtonText'><div className='FaqQuestionPage__backButtonArrow'/>{strings.getString('RETURN_TO_FAQ', 'Return to FAQs')}</div>
                        </LocLink>
                    </div>
                    <div className='FaqQuestionPage__content'>
                        <TitleBar title={strings.getString('Q&A', 'QUESTION & ANSWER').toUpperCase()} />
                        <div className='FaqQuestionPage__contentBox'>
                            <ContentBox accent='stoneborn'>
                                <div className='FaqQuestionPage__questionAndAnswer'>
                                    <div className='FaqQuestionPage__question'>{question}</div>
                                    <div className='FaqQuestionPage__answer' dangerouslySetInnerHTML={{__html: description}}/>
                                </div>
                            </ContentBox>
                        </div>
                        <div className='FaqQuestionPage__emailUs'>{strings.getString('SUPPORT_STILL_QUESTIONS', 'Still have questions? Email us at ')}<a href={`mailto:support@crowfall.com?subject=Question about: '${question}'`}>support@crowfall.com</a></div>
                    </div>
                </div>
            </Page>
        )
    }
}

export default FaqQuestionPage