import React from 'react'
import { connect } from 'react-redux'
import { Dimmer, Loader } from 'semantic-ui-react'
import swal from '@sweetalert/with-react'
import _ from 'lodash'
import strings from '../../../strings'
import FieldInput from '../../../componentLibrary/Models/FieldInput'
import Button from '../../../componentLibrary/Fragments/Button'
import { getPromoCodeStatus, claimKickstarterPromoCode } from '../../../redux/actions/user'
import './styles.scss'


const mapDispatchToProps = dispatch => {
    return {
        getPromoCodeStatus: (code) => dispatch(getPromoCodeStatus(code)),
        claimKickstarterPromoCode: (code) => dispatch(claimKickstarterPromoCode(code)),
    }
}


class CheckoutPromoCodeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            promoCode: null,
        }
        this.updateField = this.updateField.bind(this)
        this.submitPromoCode = this.submitPromoCode.bind(this)
    }

    updateField(value, name) {
        this.setState({ [name]: value })
    }

    async submitPromoCode() {
        this.setState({ loading: true })

        const { getPromoCodeStatus, claimKickstarterPromoCode, onSuccess } = this.props
        try {
            const promoCode = await getPromoCodeStatus(this.state.promoCode)
            const { current_account_discount, max_code_discount } = promoCode

            try {
              await claimKickstarterPromoCode(this.state.promoCode)
              if ( current_account_discount > max_code_discount ) {
                swal({
                  icon: 'success',
                  content: (
                    <div>
                      <div className="CheckoutSolutionDisplay__header">{strings.getString("ACCOUNT_REDEEM_CODE_SUCCESS", "Successfully Redeemed Code")}</div>
                      <div>
                        {strings.getString("CURRENT_CODE_TO_BE_APPLIED_PRE", "You currently have a discount being applied to this purchase that is greater than the discount being claimed.")}
                        <br/>
                        <br/>
                        {strings.getString("CURRENT_CODE_TO_BE_APPLIED_POST", `Instead, the ${this.state.promoCode} code will be added to your account to be redeemed at a later time, and the current ${this.state.solution.discount_name} discount will be applied to the purchase.`, { newCode: this.state.promoCode, currentDiscountName: this.state.solution.discount_name })}
                      </div>
                    </div>
                  )
                })
              } else if ( current_account_discount <= max_code_discount ) {
                swal(`${strings.getString("ACCOUNT_REDEEM_CODE_SUCCESS", "Successfully Redeemed Code")}`, {
                  icon: 'success'
                })
              }
              this.setState({ promoCode: '' })

            } catch(err) {
              swal(`${_.result(err, 'response.data.message', strings.getString('ACCOUNT_REDEEM_CODE_ERROR', 'Uh Oh! Unable to Redeem Code'))}`, {
                icon: 'error',
              })
            }

          } catch(err) {
              swal(`${_.result(err, 'response.data.message', strings.getString('ACCOUNT_REDEEM_CODE_ERROR', 'Uh Oh! Unable to Redeem Code'))}`, {
              icon: 'error',
            })
          }

          await onSuccess()
          this.setState({ loading: false })
    }

    render() {

        const { isDiscountable } = this.props
        const { loading } = this.state

        return (
            <div className="CheckoutPromoCodeForm">
                <Dimmer active={loading} inverted>
                    <Loader/>
                </Dimmer>

                <div className="CheckoutPromoCodeForm__header">{strings.getString('PROMO_CODES', 'Promo Codes')}</div>

                {isDiscountable && <React.Fragment>
                    <div className="CheckoutPromoCodeForm__field">
                      <FieldInput
                          label={strings.getString("APPLY_DISCOUNT_TO_PURCHASE", "Apply a discount to your purchase")}
                          name="promoCode" placeholder={strings.getString('ENTER_CODE', 'Enter Code')} onChange={this.updateField}
                      />
                    </div>
                    <Button medium label={strings.getString("CLAIM_CODE", "Claim Code")} onclick={this.submitPromoCode}/>
                </React.Fragment>}
                
                {!isDiscountable &&
                    <div>{strings.getString("STORE_ITEM_INELIGIBLE_FOR_DISCOUNTS", "The selected item(s) for purchase are not eligible for promotional discounts.")}</div>
                }
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(CheckoutPromoCodeForm)