import React from 'react'
import _ from 'lodash'
import assets from '../../../assets'
import contentful from '../../../utilities/contentful'
import './styles.scss'

class ECSFooter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            sponsorLogos: [],
        }
    }

    async componentDidMount() {
        const content = await contentful.getEntry(assets.contentfulIds.ECS_SPONSOR_LOGOS)
        const sponsorLogos = !_.isEmpty(content.multipleAssets) ? content.multipleAssets : []
        this.setState({ sponsorLogos })
    }

    render() {

        const { sponsorLogos } = this.state

        return (
            <div className="ECSFooter">
                {sponsorLogos.map(logo => (
                    <img src={logo.assetUrl} className="ECSFooter__img" key={`ECSFooterLogo-${logo.title}`} alt="ECS Sponsor"/>
                ))}
            </div>
        )
    }
}

export default ECSFooter